<template>
  <!-- 排行榜 -->
  <div class="page">
    <div class="ranking" :style="{background:`url(${rankingBg})`}">
      <!-- <img src="../../static/54.png" alt="" class="ranking_topImg" /> -->
      <div class="quitPopup" @click.stop="closeRanking()"></div>
      <img src="../../image/ranking/5.png" alt="" class="rankingLogo">
      <!-- 榜单切换 -->
      <div class="tabList flex">
        <div
          class="tab_item flex-content"
          v-for="(item, index) in listName"
          :key="index"
          :class="listType == index + 1 ? 'pitch_item' : ''"
          @click="listSwitchover(index + 1)"
          style="padding-bottom: 12px"
        >
          {{ item }}
        </div>
      </div>
      <div v-if="listType != 4">
        <!-- 榜单日期切换 -->
        <div class="timeList_box">
          <template v-for="(item, key1) in timeList" >
            <div :key="key1 + 'a'" 
              v-if="timeShow == key1" 
              class="timeList_item timeList_pitch" 
              @click="listDateSwitchover(key1)"
              :style="{background:subjectColor}"
            >{{ item }}</div>
            <div :key="key1 + 'a'" v-else class="timeList_item " @click="listDateSwitchover(key1)">{{ item }}</div>
          </template>
          
        </div>
        <!-- 榜单规则 -->
        <div class="listRules" @click="rulesChange">榜单规则</div>
        <!-- 前三 start-->
        <div class="topThree" v-if="listType != 3" style="background: url(../../static/other25.png) no-repeat;">
          <!-- 第二 -->
          <div class="two flex-dir" v-if="lists[1]">
            <div class="headPortrait flex-content border-two">
              <img :src="lists[1].userAvatar" @click="checkUser(lists[1])" alt="" />
              <img src="../../static/56.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[1].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" :class="{'vipred':lists[1].vipFlag==1}">{{ lists[1].userName }}</div>
            <div class="flex differ" style="color:#333" v-if="lists[1].diff!=''">
              与上一名相差
              <p class="differ_text">{{ lists[1].diff }}</p>
            </div>
          </div>
          <div style="width: 100px" v-else></div>

          <!-- 第一 -->
          <div class="one flex-dir" v-if="lists[0]">
            <div class="headPortrait flex-content">
              <img :src="lists[0].userAvatar" @click="checkUser(lists[0])" alt="" />
              <img src="../../static/55.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[0].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" style="width: 100px" :class="{'vipred':lists[0].vipFlag==1}">
              {{ lists[0].userName }}
              <img src="../../static/58.png" alt="" class="champion_img" />
            </div>
            <div class="champion">榜单冠军</div>
          </div>
          <div style="width: 100px" v-else></div>
          <!-- 第三 -->
          <div class="three flex-dir" v-if="lists[2]">
            <div class="headPortrait flex-content border-three">
              <img :src="lists[2].userAvatar" alt="" @click="checkUser(lists[2])"  />
              <img src="../../static/57.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[2].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" :class="{'vipred':lists[2].vipFlag==1}">
              {{ lists[2].userName }}
              <img src="../../static/58.png" alt="" class="champion_img" />
            </div>
            <div class="flex differ" style="color:#333">
              与上一名相差
              <p class="differ_text">{{ lists[2].diff }}</p>
            </div>
          </div>
          <div style="width: 100px" v-else></div>
        </div>
        <!-- 前三end -->
        <!-- 礼物榜排行前三 start-->
        <div class="topThree" v-if="listType == 3" style="background: url(../../static/other25.png) no-repeat;">
          <!-- 第二 -->
          <div class="two flex-dir" v-if="lists[1]">
            <div class="headPortrait flex-content border-two" @click="checkUser(lists[1])">
              <img :src="lists[1].userAvatar" alt="" />
              <img src="../../static/56.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[1].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" :class="{'vipred':lists[1].vipFlag==1}">{{ lists[1].userName }}</div>
            <div class="flex differ">
              <p class="differ_text">
                <span class="f-ffff2e">{{ lists[1].epithet }}</span>
              </p>
            </div>
            <div class="flex differ algin-center mt4">
              <img
                :src="lists[1].giftLogo"
                style="width:18px;height:18px margin-right:2px"
              />
              <p style="color:#333">x{{ lists[1].giftNum }}</p>
            </div>
          </div>
          <div style="width: 100px" v-else></div>

          <!-- 第一 -->
          <div class="one flex-dir" v-if="lists[0]" @click="checkUser(lists[0])">
            <div class="headPortrait flex-content">
              <img :src="lists[0].userAvatar" alt="" />
              <img src="../../static/55.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[0].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" style="width: 100px" :class="{'vipred':lists[0].vipFlag==1}">
              {{ lists[0].userName }}
              <img src="../../static/58.png" alt="" class="champion_img" />
            </div>
            <div class="flex differ">
              <p class="differ_text">
                <span class="f-ffff2e">{{ lists[0].epithet }}</span>
              </p>
            </div>
            <div class="flex differ algin-center mt4">
              <img
                :src="lists[0].giftLogo"
                style="width:18px;height:18px margin-right:2px"
              />
              <p style="color:#333">x{{ lists[0].giftNum }}</p>
            </div>
          </div>
          <div style="width: 100px" v-else></div>
          <!-- 第三 -->
          <div class="three flex-dir" v-if="lists[2]" @click="checkUser(lists[2])">
            <div class="headPortrait flex-content border-three">
              <img :src="lists[2].userAvatar" alt="" />
              <img src="../../static/57.png" alt="" class="grade" />
			  <img src="../../static/vip.png"  v-if='lists[2].vipFlag==1'
			   style="width: auto;height:14px;position: absolute;bottom:-6px;left:50%;transform: translateX(-50%);border-radius: 0;" />
            </div>
            <div class="topThree_name text-cut" :class="{'vipred':lists[2].vipFlag==1}">
              {{ lists[2].userName }}
              <img src="../../static/58.png" alt="" class="champion_img" />
            </div>
            <div class="flex differ">
              <p class="differ_text">
                <span class="f-ffff2e">{{ lists[2].epithet }}</span>
              </p>
            </div>
            <div class="flex differ algin-center mt4">
              <img
                :src="lists[2].giftLogo"
                style="width:18px;height:18px margin-right:2px"
              />
              <p style="color:#333">x{{ lists[2].giftNum }}</p>
            </div>
          </div>
          <div style="width: 100px" v-else></div>
        </div>
        <!-- 礼物榜排行前三end -->
        <!-- 排行滚动 start-->
        <div class="ranking_scroll" v-if="lists.length > 3 && listType != 3">
          <div v-for="(item, key2) in lists" :key="key2">
            <div class="scroll_item" v-if="key2 > 2">
              <div class="flex-aling">
                <div class="flex-aling rowNumber" v-if="item.rank">
                  0{{ item.rank }}
                </div>
				<div style="position: relative;">
					<img @click="checkUser(item)" :src="item.userAvatar" onerror="../../static/other11.png" alt="" class="scroll_item_img" />
					<img src="../../static/vip.png"  v-if='item.vipFlag==1'
					 style="width: auto;height:13px;position: absolute;bottom:-2px;left:50%;transform: translateX(-50%);border-radius: 0;" />
				</div>
                
                <div>
                  <div class="font-14" style="color: #333" :class="{'vipred':item.vipFlag==1}">
                    {{ item.userName }}
                  </div>
                  <div style="maring-top: 3px; width: 151px" class="line1">
                    {{ item.personalSignature }}
                  </div>
                </div>
              </div>
              <div>
                <div style="color:#333">与上一名相差</div>
                <div class="gap" v-if="item.diff">{{ item.diff }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 排行滚动end -->
        <!-- 礼物榜排行滚动 start-->
        <div class="ranking_scroll" v-if="lists.length > 3 && listType == 3">
          <div v-for="(item, key2) in lists" :key="key2">
            <div class="scroll_item" v-if="key2 > 2">
              <div class="flex-aling">
                <div class="flex-aling rowNumber" v-if="item.rank">
                  0{{ item.rank }}
                </div>
				<div style="position: relative;">
					<img @click="checkUser(item)" :src="item.userAvatar" alt="" class="scroll_item_img" />
					<img src="../../static/vip.png"  v-if='item.vipFlag==1'
					 style="width: auto;height:13px;position: absolute;bottom:-2px;left:50%;transform: translateX(-50%);border-radius: 0;" />
				</div>
                <div>
                  <div class="font-14" style="color:#333" :class="{'vipred':item.vipFlag==1}">
                    {{ item.userName }}
                  </div>
                  <div style="maring-top: 3px; width: 151px" class="line1">
                    {{ item.personalSignature }}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img
                    :src="item.giftLogo"
                    style="width: 22px; height: 22px; margin-right: 2px"
                  />
                </div>
                <p
                  class="gap"
                  style=" color: #fff; width: 100%; text-align: center; margin-top: 0px; "
                >
                  x{{ item.giftNum }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--礼物榜排行滚动end -->
        <!-- 我的排名 -->
        <div class="myRanking flex-aling" v-if="listType != 3" :style="{background:subjectColor}">
          <div class="flex">
            <div class="flex-aling rowNumber">
              {{ myData.rank > 0 ? myData.rank : "20+" }}
            </div>
            <img  :src="myData.userAvatar" alt="" class="myRanking_img" />
            <div>
              <div class="font-14" :class="{'vipred':myData.vipFlag==1}">
                {{ myData.userName }}
				<img src="../../static/vip.png"  v-if='myData.vipFlag==1'
				 style="width: auto;height:14px;border-radius: 0;" />
              </div>
              <div style="maring-top: 3px; width: 151px" class="line1">{{ myData.personalSignature }}</div>
            </div>
          </div>
          <div class="flex-right">
            <div v-if="myData.rank == 0">距上榜差</div>
            <div v-else style="color:#333">与上一名相差</div>
            <div class="gap">{{ myData.diff }}</div>
          </div>
        </div>
      </div>
      <!-- 真爱榜 -->
      <div class="trueLove_box" v-else>
        <div
          class="flex-aling font-12"
          style="color: #8e92b1; margin-bottom: 17px"
        >
          榜单仅展示近30条最近记录 
          <!-- <img src="../../static/41.png" alt="" /> -->
        </div>
        <div class="listRules" @click="rulesChange">榜单规则</div>

        <!-- 列表内容 -->
        <div class="trueLove_list">
          <div
            class=""
            v-for="(item, index) in loveList"
            :key="index"
          >
            <div class="trueLove_item" v-if="index < 30">
              <div class="flex-spacebetween">
                <div class="flex">
                  <div>
                    <img :src="item.fromUserAvatar" alt="" class="trueLove_img" />
                    <img
                      src="../../static/173.png"
                      alt=""
                      class="trueLove_contImg"
                    />
                    <img
                      :src="item.toUserAvatar"
                      alt=""
                      class="trueLove_img rightImg"
                    />
                  </div>
                  <div>
                    <div class="font-16" style="color:rgba(90, 65, 18, 1);display:flex">
                      <div class="textOv">{{ item.fromUserName }}</div>
                      <img style="width:18px;height:18px" :src="item.fromUserWealthLevelLogo" alt="" />
                      <img style="width:18px;height:18px"  :src="item.fromUsercharismaLevelLogo" alt="" />
                    </div>
                    <div class="font-14" style="color:rgba(90, 65, 18, 1);display:flex">

                        
                        <div>赠送</div>
                      <span class="textOv" style="color: rgba(65, 119, 221, 1); margin-left: 4px;display: inline-block;">{{
                        item.toUserName
                      }}</span>
                    </div>
                  </div>
                </div>
                <div style="color:#333;font-size:12px">
                  <img
                  :src="item.giftFile"
                  alt=""
                  class="trueLove_giftImg"
                />
                *{{item.number}} {{ item.giftName }}
                </div>
                
              </div>
              <div class="flex-spacebetween" style="margin-top: 12px">
                <div class="trueLove_text flex-aling">
                  <img src="../../static/14.png" alt="" /> {{ item.roomName }}
                </div>
                <div class="trueLove_time">{{ item.createTimeStr }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 规则弹窗 -->
    <div class="rulesPopup_box" v-if="rulesPopupShow">
      <div class="rulesPopup">
        <div class="rules_topicon"></div>
        <div class="rules_top">
          <div>榜单规则</div>
          <div class="rules_quit" @click="rulesPopupShow = false"></div>
        </div>
        <!-- 规则内容 -->
        <div class="rules_content" v-html="explainContent"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { IMG_URL } from "@/common/config.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      imgUrl: IMG_URL,
      listType: 1,
      timeShow: 0,
      listName: ["财富榜", "魅力榜", "礼物榜", "真爱榜"],
      timeList: ["日榜", "周榜", "月榜", "总榜"],
      rulesPopupShow: false,
      explainContent: "", //榜单说明内容
      lists: [], //排行榜
      myData: {}, //用户自己的排名
      loveList: [], //真爱榜 
      rankingBg:require('../../image/ranking/1.png'),
      subjectColor:'#ffdd00'
    };
  },
  mounted() {
    this.rankRules(1);
    this.getWealthList();
  },
  methods: {
    checkUser(item){
      this.$emit('showOther',item)
      // console.log(item,'itemitem')
    },

    //关闭页面
    closeRanking() {
      this.$emit("closeRanking");
    },
    // 财富榜单
    getWealthList() {
      let data = { pageNum: 1, pageSize: 20 };
      let api = "";
      if (this.timeShow == 0) {
        // 日榜
        api = "wealthListDay";
      } else if (this.timeShow == 1) {
        // 周榜
        api = "wealthListWeek";
      } else if (this.timeShow == 2) {
        // 月榜
        api = "wealthListMonth";
      } else {
        // 总榜
        api = "wealthList";
      }
      this.$api[api](data).then((res) => {
        // console.log("财富榜", res);
        if (res.code == 1) {
          this.myData = res.data.mineWealthInfo;
          this.lists = res.data.wealthInfoVos;
          // this.lists.pop();
        }
      });
    },
    // 魅力榜单
    getCharismaList() {
      let data = { pageNum: 1, pageSize: 20 };
      let api = "";
      if (this.timeShow == 0) {
        // 日榜
        api = "charismaListDay";
      } else if (this.timeShow == 1) {
        // 周榜
        api = "charismaListWeek";
      } else if (this.timeShow == 2) {
        // 月榜
        api = "charismaListMonth";
      } else {
        // 总榜
        api = "charismaList";
      }
      this.$api[api](data).then((res) => {
        // console.log("魅力榜", res);
        if (res.code == 1) {
          this.myData = res.data.minecharismaInfo;
          this.lists = res.data.charismaInfoVos;
          // this.lists.pop();
        }
      });
    },
    // 礼物榜
    getGiftList() {
      let data = { pageNum: 1, pageSize: 20 };
      let api = "";
      if (this.timeShow == 0) {
        // 日榜
        api = "giftListDay";
      } else if (this.timeShow == 1) {
        // 周榜
        api = "giftListWeek";
      } else if (this.timeShow == 2) {
        // 月榜
        api = "giftListMonth";
      } else {
        // 总榜
        api = "giftList";
      }
      this.$api[api](data).then((res) => {
        // console.log("礼物榜", res);
        if (res.code == 1) {
          // this.myData = res.data.mineWealthInfo;
          this.myData = "";
          this.lists = res.data;
          // this.lists.pop();
        }
      });
    },
    // 榜单切换
    listSwitchover(i) {
      this.rankingBg = require(`../../image/ranking/${i}.png`)
      switch (i) {
        case 1:
          this.subjectColor = '#FFDD00'
          break;
        case 2:
          this.subjectColor = '#c9bbf4'
          break;
        case 3:
          this.subjectColor = '#96cbfe'
          break;
        default:
          break;
      }
      // console.log("--i---", i);
      this.listType = i;
      this.timeShow = 0;
      
      if (this.listType == 1) {
        this.getWealthList();
        this.rankRules(1)
      } else if (this.listType == 2) {
        this.getCharismaList();
        this.rankRules(2)
      } else if (this.listType == 3) {
        this.getGiftList();
        this.rankRules(3)
      } else {
        this.rankRules(0)
        this.$api.realLoveListRoom({ pageNum: 1, pageSize: 30 }).then((res) => {
          // console.log("真爱榜", res);
          if (res.code == 1) {
            this.loveList = res.data;
          }
        });
      }
    },
    // 榜单日期切换
    listDateSwitchover(key) {
      // console.log(key);
      this.timeShow = key;
      if (this.listType == 1) {
        this.getWealthList();
      } else if (this.listType == 2) {
        this.getCharismaList();
      } else if (this.listType == 3) {
        this.getGiftList();
      }
    },
    // 规则弹窗
    rulesChange() {
      this.rulesPopupShow = !this.rulesPopupShow;
    },
    // 榜单说明
    rankRules(type) {
      //榜单类型（0-真爱榜 1-财富榜 2-魅力榜 3-礼物榜）,示例值(0)
      this.$api.rankRules({ type: type }).then((res) => {
        // console.log("榜单说明", res);
        if (res.code == 1) {
          this.explainContent = res.data.content;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  font-size: 16px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.8);
  .ranking_topImg {
    width: 400px;
    height: 170px;
    position: absolute;
    top: -26%;
  }
  .rankingLogo{
    width:142px;
    height: 45.15px;
    margin: 24px 0 31px 25px; 
  }
  .ranking {
    width: 400px;
    height: 750px;
    background: #1d223b;
    position: absolute;
    top: 10%;
    right: 12%;
    display: flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .tabList {
      font-size: 14px;
      color: #ffffff;
      justify-content: space-around;
      .pitch_item {
        color: #66381E !important;
        font-size: 16px;
        position: relative;
      }
      .pitch_item:after {
        content: "";
        width: 16px;
        height: 2px;
        position: absolute;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%);
        display: inline-block;
        background: #66381E;
      }
      .tab_item {
        color: #66381E;
        margin-top: 16px;
      }
    }
    .timeList_box {
      width: 192px;
      height: 28px;
      margin: 10px auto;
      display: flex;
      font-size: 12px;
      color: #6a6a94;
      align-items: center;
      border-radius: 32px 32px 32px 32px;
	  background: #f8f8f8;
      .timeList_item {
        flex: 1;
        text-align: center;
      }
      .timeList_pitch {
        color: #66381E;
        width: 48px;
        height: 28px;
        line-height: 28px;
        background: #fee12d;
        border-radius: 32px 32px 32px 32px;
        opacity: 0.8;
      }
    }
    .listRules {
      width: 65px;
      height: 24px;
      font-size: 12px;
      color: #66381E;
      text-align: center;
      line-height: 24px;
      position: absolute;
      top: 21.5%;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba($color: #66381e, $alpha: 0.1) 0%,
        rgba($color: #66381e, $alpha: 0.1) 100%
      );
      border-radius: 12px 0px 0px 12px;
    }
    .topThree {
      width: 368px;
      height: 135px;
      display: flex;
      justify-content: space-around;
      background: url(../../static/233.png) no-repeat;
      background-size: 100% 100%;
      margin: 0px auto 0;
      .topThree_name {
        width: 70px;
        height: 20px;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        margin: 10px 0 4px 0;
        
color: #363659;
      }
      .champion {
        font-size: 12px;
        
color: #E1502D;
      }
      .champion_img {
        width: 16px;
        height: 16px;
        // position: absolute;
        // right: 0;
      }
      .differ {
        font-size: 12px;
        .differ_text {
          color: rgba(246, 130, 41, 1);
        }
      }
      .headPortrait {
        width: 54px;
        height: 54px;
        border-radius: 69px;
        position: relative;
        border: 1px solid #fcb436;
        > img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          cursor: pointer;
        }
        .grade {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -8px;
          right: 0;
        }
      }
      .border-two {
        border: 1px solid #a3b0fa;
      }
      .border-three {
        border: 1px solid #f5812c;
      }
      .one {
		  flex:1;
        position: relative;
        top: 0px;
		background: linear-gradient(to bottom,#ebf1ed 10%, rgba(0,0,0,0) 100%);
		border-radius: 30px 30px 0 0;
      }
      .two{
		  flex:1;
        position: relative;
        top: 35px;
		background: linear-gradient(to bottom,#ebf0fa 10%, rgba(0,0,0,0) 100%);
		border-radius: 30px 0px 0 0;
      }
      .three{
		  flex:1;
        position: relative;
        top: 35px;
		background: linear-gradient(to bottom,#ebf0fa 10%, rgba(0,0,0,0) 100%);
		border-radius: 0px 30px 0 0;
      }
    }
    .myRanking {
      width: 400px;
      height: 64px;
      position: absolute;
      bottom: 0;
      font-size: 12px;
      color: #333333;
      padding: 0 20px 0 19px;
      justify-content: space-between;
      background: #FFDD00;
      border-radius: 0px 0px 8px 8px;
      box-sizing: border-box;
      .myRanking_img {
        width: 40px;
        height: 40px;
        margin: 0 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .gap {
    margin-top: 7px;
    color: #333;
  }
  .ranking_scroll {
    height: 320px;
    overflow-y: auto;
    margin: 30px 0 70px 0;
    padding: 0 18px;
    color:#000000;
    .scroll_item {
      width: 360px;
      height: 64px;
      display: flex;
      font-size: 12px;
      color: #8e92b1;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 8px;
      background: #fff;
      border-radius: 4px;
      box-sizing: border-box;

      .scroll_item_img {
        width: 40px;
        height: 40px;
        margin: 0 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .rowNumber {
    font-size: 16px;
    font-weight: bolder;
    color: #6a6a94;
  }
  .quitPopup {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -4%;
    right: -7%;
    background: url(../../static/quit2.png) no-repeat;
    cursor: pointer;
  }
  .rulesPopup_box {
    z-index: 9999;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: #333;
    background: rgba($color: #000000, $alpha: 0.8);
    .rulesPopup {
      width: 860px;
      height: 287px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 8px 8px 8px 8px;
      z-index: 999999;
      .rules_topicon {
        width: 860px;
        height: 6px;
        

        background: linear-gradient(270deg, #FFDD00 0%, #FFA200 100%);;
        border-radius: 8px 8px 0px 0px;
      }
      .rules_top {
        width: 860px;
        height: 39px;
        display: flex;
        padding: 0 20px;
        font-size: 14px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        
background: rgba(254, 227, 56, 0.1);
        .rules_quit {
          width: 15px;
          height: 15px;
          cursor: pointer;
          background: url(../../static/quit.png) no-repeat;
        }
      }
      .rules_content {
        padding: 20px;
        font-size: 12px;
        color: #8e92b1;
        line-height: 20px;
      }
    }
  }
  // 真爱榜样式
  .trueLove_box {
    padding: 0 20px;

    .trueLove_list {
      height: 520px;
      overflow-y: scroll;
    }
    .trueLove_item {
      width: 350px;
      height: 97px;
      padding: 10px;
      margin-bottom: 8px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;

      .trueLove_contImg {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
        right: 10px;
        z-index: 9;
      }
      .trueLove_img {
        width: 38px;
        height: 38px;
        border: 2px solid #fff;
        border-radius: 50%;
      }
      .rightImg {
        position: relative;
        right: 20px;
      }
      .trueLove_giftImg {
        width: 40px;
        height: 40px;
      }
      .trueLove_text {
        width: 160px;
        height: 20px;
        font-size: 12px;
        padding-left: 12px;
        color: #000000;
        background: linear-gradient(
          90deg,
          rgba(190, 166, 48, 0.77) 0%,
          rgba(235, 201, 47, 0) 100%
        );
        border-radius: 20px 20px 20px 20px;
      }
      .trueLove_time {
        width: 150px;
        color: #6f7387;
        font-size: 12px;
        text-align: right;
      }
    }
  }
}
.line1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
img {
  object-fit: cover;
}
.f-ffff2e {
  color: rgba(65, 119, 221, 1);
}
.algin-center {
  align-items: center;
}
.mt4 {
  margin-top: 4px;
}
.textOv{
  max-width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}
</style>
