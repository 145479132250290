<template>
	<!-- 聊天室首页 -->
	<div class="chatPage" @click="(searchPopupShow = false), (emceeTaskShow = false)">
		<!-- <div class="broadcast_right" v-if="1">
    <div class="broadcast_rightItem flex-aling">
      <div class="fullBand_text flex text-cut">
        <p>{{ wholeData.sendUserName }}</p>
        <p>{{ wholeData.content }}</p>
      </div>
      <img src="../../../image/room/80.png" alt="" style="margin: 0 10px" />
      <div class="fullBand_ben" @click="goPage(wholeData)">去围观</div>
    </div>
  </div> -->

		<div class="wrapper" style="display: none">
			<video id="videoElement" controls autoplay muted width="300px" height="100px"></video>
		</div>
		<!-- 顶部样式 -->
		 <div class='containerWidthNew' :style="{'background-image': roomDetail.backImg? 'url(' + roomDetail.backImg + ')'
		 :'url(' + defaultBg + ')'}">
		<div class="chat_top flex-aling containerWidth" style="margin:0px auto 0 auto;position: relative;">
			<div class="flex">
				<!-- 左侧 -->
				<!-- <img :src="userInfo.avatar" alt="" class="chat_headImg" @click="showChange('personal')" /> -->
				<div style="position: relative;margin-right:10px">
					<el-avatar :size="40" fit="cover" :src="userInfo.avatar" @click="showChange('personal')"></el-avatar>
					<img src="../../static/vip.png" v-if='userInfo.vipFlag==1'
						style="width: auto;height:14px;position: absolute;bottom:3px;left:50%;transform: translateX(-50%);" />
				</div>
				<div>
					<!-- 用户信息 -->
					<div class="flex" style="align-items: center">
						<span style="color:#fff" :class="{'vipred':userInfo.vipFlag==1}">{{ userInfo.nickname }}</span>
						<img :src="userInfo.logo" alt="" class="margin_r4" style="width: 28px; height: 28px" />
					</div>
					<div class="flex">
						<div class="flex-aling font-12">
							<img src="../../static/61.png" alt="" class="margin_r4" />
							<span style='color:#fff'>{{ myDiamondData }}</span>
						</div>
						<!-- 充值按钮 -->
						<div class="topUpBtn flex-content XS" @click.stop="walletTopupShow = true">
							<span>充值</span>
							<img src="../../static/62.png" alt="" />
						</div>
					</div>
				</div>
				<el-input v-model="keyword" style="color:#fff" prefix-icon="el-icon-search" placeholder="昵称/ID/房间" class="search" @focus="focussearchRoomChange()" @input="searchRoomChange()"></el-input>
				<!-- @change="searchChange()" -->
				<!-- 搜索内容 -->
				<div class="search_frame" v-if="searchPopupShow">
					<div class="flex search_title">
						<div :class="searchNum == '0' ? 'search_pitch' : ''" @click.stop="searchNum = '0'">
							全部
						</div>
						<div :class="searchNum == '1' ? 'search_pitch' : ''" @click.stop="searchNum = '1'">
							用户
						</div>
						<div :class="searchNum == '2' ? 'search_pitch' : ''" @click.stop="searchNum = '2'">
							房间
						</div>
					</div>
					<div>
						<div class="search_roll">
							<div v-if="searchNum == '2' || searchNum == '0'">
								<div class="search_subhead">相关房间</div>
								<div class="search_rollItem" v-for="item in searchRoomData" :key="item.id" @click.stop="skipRoom(item)">
									<img :src="item.logo" alt="" />
									<div class="flex flex-spacebetween rollItem_text">
										<div>
											<b>{{ item.name }}</b>
											<p style="font-size: 12px; color: #333">
												房间ID:{{ item.roomNo }}
											</p>
										</div>
										<div style="font-size: 12px; color: #999999">
											房间热度：{{ item.hot }}
										</div>
									</div>
								</div>
								<div class="flex-justify" style="width: 100%" v-if="searchRoomData.length == 0">
									<img src="../../image/room/wu.png" alt="" style="width: 90px" />
								</div>
							</div>
							<div v-if="searchNum == '1' || searchNum == '0'">
								<div class="search_subhead">相关用户</div>
								<div class="search_rollItem" v-for="item in searchPlayerData" :key="item.id" @click.stop="placepage(item.id)">
									<img :src="item.avatar" alt="" />
									<div class="flex flex-spacebetween rollItem_text">
										<div>
											<p class="flex flex-aling">
												<b :class="{'vipred':item.vipFlag==1}">{{ item.nickname }}</b>
												<img src="../../static/vip.png" v-if='item.vipFlag==1' style="width: auto;height:13px;margin-left: 3px;" />
											</p>
											<p style="font-size: 12px; color: #999999">
												用户ID:{{ item.userNo }}
											</p>
										</div>
										<div style="font-size: 12px; color: #8b8992" v-if="item.onlineStatus == '0'">
											<span class="icon_style" style="background-color: #8b8992"></span>离线
										</div>
										<div style="font-size: 12px; color: #1cd276" v-else-if="item.onlineStatus == '1'">
											<span class="icon_style" style="background-color: #1cd276"></span>在线
										</div>
										<div style="font-size: 12px; color: #f03993" v-else-if="item.onlineStatus == '2'">
											<span class="icon_style" style="background-color: #f03993"></span>语聊中
										</div>
									</div>
								</div>
								<div class="flex-justify" style="width: 100%" v-if="searchPlayerData.length == 0">
									<img src="../../image/room/wu.png" alt="" style="width: 90px" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 左侧end -->
			<!-- 右侧tab -->
			<div class="flex">
				<div class="flex-aling operationTabl" @click="showChange('ranking')">
					<img src="../../static/115new.png" alt="" />
					<span  style='color:#fff'>排行榜</span>
				</div>
				<div class="flex-aling operationTabl" @click="wealthThatShow = true">
					<img src="../../static/152new.png" alt="" />
					<span style='color:#fff'>财富特权</span>
				</div>
				<!-- <div class="flex-aling operationTabl" @click="OpenPage('message')"> -->
				<div class="flex-aling operationTabl" style="position: relative" @click="myMessageShow = true">
					<img src="../../static/153new.png" alt="" />
					<span style='color:#fff'>我的消息</span>
					<div class="myMessageNum" v-if="totalUnreadCount">
						{{ totalUnreadCount }}
					</div>
				</div>
				<div class="flex-aling operationTabl" @click="showChange('personal')">
					<img src="../../static/154new.png" alt="" />
					<span style='color:#fff'>我的订单</span>
				</div>
				<el-popover placement="bottom" width="270" trigger="click">
					<div class="downloadApp">
						<div class="flex">
							<div class="flex-dir">
								<div style="color:#333;font-weight: 600;">Android下载</div>
								<img :src="download.appdown" alt="" class="downloadApp_img" />
							</div>
							<div class="flex-dir">
								<div style="color:#333;font-weight: 600;">IOS下载</div>
								<img :src="download.iosdown" alt="" class="downloadApp_img" />
							</div>
						</div>
						<div style="color:#000;cursor: pointer;display:flex;align-items: center;margin-top:10px;" @click="downGoShu(urlLink)">
							<img src="../../static/user10.png" style="width:20px;height:20px" alt=""></img>
							<div style="margin-left:14px;font-weight: 600;" v-loading="loadings">狗书语音桌面端下载</div>
						</div>
					</div>
					<div slot="reference" class="flex-aling operationTabl">
						<img src="../../static/user10new.png" alt="" />
						<span style='color:#fff'>下载APP</span>
					</div>
				</el-popover>

				<div class="flex-aling operationTabl" @click="
					outRoomShow = true;
					emceeTaskShow = false;
					othersHomeShow = false;
					othersHomeShowLiao = false;
					wheatHomeShow = false;">
					<img src="../../static/155new.png" alt="" />
					<span style='color:#fff'>返回首页</span>
				</div>
			</div>
			<!-- 右侧end -->
			<!-- 下载弹窗 -->

		</div>
		<div class="flex containerWidth"  @click.stop="pageBtn" style='border-radius: 0 0 10px 10px; margin:0px auto 0 auto;background-size: cover;background-repeat: no-repeat;'>
			<div class="chat_left">
				<!-- 房间详情 -->
				<div class="romm_details">
					<!-- <div class="rommDetails_icon">{{ roomDetail.theme }}</div> -->
					<!-- 左上角icon -->
					<!-- <img
            src="../../image/room/room1.png"
            alt=""
            v-if="
              roomDetail.labelName == '游戏派单厅' ||
              roomDetail.labelName == '声优派单厅'
            "
            class="topLeftIcon"
          />

          <img
            src="../../image/room/room2.png"
            alt=""
            class="topLeftIcon"
            v-else-if="roomDetail.labelName == '点唱'"
          />
          <img
            src="../../image/room/room4.png"
            alt=""
            class="topLeftIcon"
            v-else-if="roomDetail.labelName == '游戏陪玩'"
          />
          <img
            src="../../image/room/room5.png"
            alt=""
            class="topLeftIcon"
            v-else-if="roomDetail.labelName == '扩列交友'"
          />
          <img
            src="../../image/room/room6.png"
            alt=""
            class="topLeftIcon"
            v-else-if="roomDetail.labelName == '男声'"
          />
          <img
            src="../../image/room/room4.png"
            alt=""
            class="topLeftIcon"
            v-else-if="roomDetail.resultCode == 5"
          />
          <img
            src="../../image/room/room3.png"
            alt=""
            class="topLeftIcon"
            v-else
          /> -->
					<div class="flex" style="width:100%">
						<img :src="roomDetail.logo" alt="" class="rommDetails_head" />
						<div>
							<div class="flex-aling" style="justify-content: space-between">
								<div class="font-14">{{ roomDetail.roomName }}</div>
								<img src="../../static/130.png" alt="" />
							</div>
							<div class="flex-aling">
								<span class="font-12">ID:{{ roomDetail.roomNo }}</span>
								<div class="flex-aling padding_r4" style="margin-top: 2px">
									<img src="../../static/131.png" alt="" />
									<span class="font-10" style="color: #8e92b1">{{
                    hotNum
                  }}</span>
								</div>
							</div>
							<!-- 收藏--分析 -->
							<div class="flex">
								<div class="room_collect flex-content XS" v-if="roomDetail.isCollect == 1" @click.stop="collectRoom()">
									已收藏
								</div>
								<div class="room_collect flex-content XS" v-else @click.stop="collectRoom()">
									<img src="../../static/132.png" alt="" />
									收藏
								</div>
								<div class="room_share flex-content XS" @click="
                  copyUserId(
                    'https://ser.t9cp.com/pch5/#/?roomId=' + roomDetail.id
                  )
                ">
									<img src="../../static/133.png" alt="" />
									分享
								</div>
							</div>
							<!-- 收藏--分析end -->
						</div>
					</div>
					<div class="line_between"></div>
					<!-- 房间介绍 -->
					<div class="font-12" style="position: relative">
						<div class="padding_4">厅公告</div>
						<div class="afficheAlter_ccc" v-if="userRole == 0 || userRole == 1" @click="editBillboardShow = true">
							修改
						</div>
						<div class="roomDetail_text">
							{{ roomDetail.announcement }}
						</div>
					</div>
				</div>
				<!-- 房间人员信息 -->
				<div class="personnel_box">
					<!-- 他人名片  -->
					<div class="othersHome" v-if="othersHomeShow" style="width: 300px">
						<el-tooltip class="item" effect="dark" content="举报用户" placement="bottom" v-if="userInfo.id != othersData.userId">
							<div class="othersHome_icon" @click.stop="userReportChange()"></div>
						</el-tooltip>

						<div class="othersHome_topBox">
							<!-- 个人信息 -->
							<div class="othersHome_top flex-dir">
								<div class="othersHome_head flex-content" @click.stop="placepage(othersData.id)">
									<img :src="othersData.avatar" alt="" />
									<img src="../../static/vip.png" v-if='othersData.vipFlag==1'
										style="width:auto;height:14px;position: absolute;bottom:-3px;left:50%;transform: translateX(-50%);" />
								</div>
								<div style="font-size: 16px; margin-bottom: 3px;font-weight: 600;" :class="{'vipred':othersData.vipFlag==1}">
									{{ othersData.userName }}
								</div>
								<div style="font-size: 12px; color: #333; margin-bottom: 6px">
									ID：{{ othersData.userNo }}
								</div>
								<div style="font-size: 12px; height: 20px;white-space: pre-wrap;word-wrap: break-word;width: 95%;text-align: center;">
									<span style="">{{ othersData.personalSignature }}</span>
								</div>
							</div>
							<!-- 标签 -->
							<!-- <div
					  class="flex othersHome_labelBox"
					  :style="{ top: isBlackRoom == 2 ? '100px' : '' }"
					>
					  <div class="othersHome_label">王者荣耀</div>
					  <div class="othersHome_label">哈利波特手游</div>
					  <div class="othersHome_label">点唱</div>
					</div> -->
						</div>
						<div class="othersHome_bottom flex_wrap">
							<div class="othersHome_bg"></div>
							<div class="flex" style="z-index: 1">
								<div class="othersHome_value1 flex">
									<!-- <img src="../../static/83.png" alt="" /> -->
									<div class="img"></div>
									<div style="margin-top: 5px">
										<div>财富</div>
										<div>{{ othersData.money }}</div>
									</div>
								</div>
								<div class="othersHome_value2 flex" style="margin: 0 7px">
									<div class="img"></div>
									<div style="margin-top: 5px">
										<div>大米</div>
										<div>{{ othersData.charisma }}</div>
									</div>
								</div>
								<div class="othersHome_value flex" @click.stop="skipMedalWall(othersData.userId)">
									<div class="img"></div>
									<div style="margin-top: 5px">
										<div>勋章墙</div>
										<div>{{ othersData.medalWallLength }}个</div>
									</div>
								</div>
							</div>
							<!-- 下单等操作 -->
							<div class="others_operationBox flex_wrap" style="z-index: 1" v-if="userInfo.id != othersData.userId">
								<div class="others_operation flex-dir" @click.stop="placepage(othersData.userId)">
									<img src="../../image/room/32.png" alt="" class="others_operationIcon" />
									<div>下单</div>
								</div>
								<div class="others_operation flex-dir" @click.stop="userGiveAwayChange(1)">
									<img src="../../static/150.png" alt="" class="others_operationIcon" />
									<div>送礼物</div>
								</div>
								<div class="others_operation flex-dir" @click="privateLetter(othersData)">
									<img src="../../image/room/33.png" alt="" class="others_operationIcon" />
									<div>私聊</div>
								</div>
								<div v-if="othersData.followFlag == 0" class="others_operation flex-dir" @click="followUser()">
									<!-- othersData.followFlag == 0 -->
									<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
									<div>关注</div>
								</div>
								<div v-if="othersData.followFlag == 1" class="others_operation flex-dir" @click="stopFollowUser()">
									<!--othersData.followFlag == 1  -->
									<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
									<div>取消关注</div>
								</div>
								<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.userRole > 2" @click="setManagerAdd()">
									<img src="../../image/room/58.png" alt="" class="others_operationIcon" />
									<div>设置管理</div>
								</div>
								<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.muteStatus == 0" @click.stop="muteUserChange()">
									<img src="../../image/room/88.png" alt="" class="others_operationIcon" />
									<div>禁言中</div>
								</div>
								<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.muteStatus == 1" @click.stop="disableSendMsgInit()">
									<img src="../../image/room/85.png" alt="" class="others_operationIcon" />
									<div>禁言</div>
								</div>
								<div class="others_operation flex-dir" v-if="userRole < 3 && othersData.userRole > 2" @click.stop="kickOutShowChange()">
									<img src="../../image/room/86.png" alt="" class="others_operationIcon" />
									<div>踢出房间</div>
								</div>
								<div class="others_operation flex-dir" v-if="1" @click.stop="blackUserChange()">
									<img src="../../image/room/87.png" alt="" class="others_operationIcon" />
									<div>厅拉黑</div>
								</div>
								<div class="cut_offRule"></div>
								<div class="flex-content" style="width: 100%" v-if="userRole <= 1" @click="directEmbraceUser(othersData)">
									<div>抱TA上麦</div>
								</div>
							</div>
						</div>
					</div>
					<div class="personnel_table flex-aling">
						<div class="flex" style="width: 100%" v-if="roomStatus == 0">
							<div class="personnel_item flex-content" v-for="(item, key2) in personnelTable" :key="key2"
								:class="personnelType == key2 + 1 ? 'personnel_pitchOn' : ''" @click="getRoomPcRankList(key2 + 1)">
								<div>
									<span class="XS">{{ item }}</span>
									<span class="font-10 XS" v-if="item == '在线'">({{ listNum.onlineNum }})</span>
									<span class="font-10 XS" v-if="item == 'VIP'">({{ listNum.vipNum }})</span>
									<span class="font-10 XS" v-if="item == '点单'">({{ listNum.orderNum }})</span>
									<span class="font-10 XS" v-if="item == '试音'">({{ listNum.testVoiceNum }})</span>
									<span class="font-10 XS" v-if="item == '排麦'">({{ listNum.normalNum }})</span>
								</div>
							</div>
						</div>
						<div class="flex" style="width: 100%" v-else>
							<div class="personnel_item flex-content" v-for="(item, key2) in gangUpTable" :key="key2" :class="personnelType == key2 + 1 ? 'personnel_pitchOn' : ''"
								@click="getRoomPcRankList(key2 + 1)">
								<div>
									<span class="XS">{{ item }}</span>
									<span class="font-10 XS" v-if="item == '在线'">({{ listNum.onlineNum }})</span>
									<span class="font-10 XS" v-if="item == 'VIP'">({{ listNum.vipNum }})</span>
									<span class="font-10 XS" v-if="item == '点单'">({{ listNum.orderNum }})</span>
									<span class="font-10 XS" v-if="item == '排麦'">({{ listNum.normalNum }})</span>
								</div>
							</div>
						</div>
					</div>
					<!-- 用户列表 -->
					<div class="personnel_list" v-if="personnelType == 1 || personnelType == 2">
						<div class="personnel_listItem flex-aling" v-for="(item, key3) in roomUserList" :key="key3" @click.stop="othersHomeChange(item)">
							<div style="position: relative;" class='margin_r8'>
								<img :src="item.userAvatar" style="object-fit: cover XS" alt="" class="listItem_head" />
								<img src="../../static/vip.png" v-if='item.vipFlag==1'
									style="width:auto;height:11px;position: absolute;bottom:0px;left:50%;transform: translateX(-50%);" />
							</div>
							<div class="font-14 text-cut" style="max-width: 100px" :class="{'vipred':item.vipFlag==1}">
								{{ item.userName }}
							</div>
							<img src="../../image/message/24.png" class="margin_r4" alt="" v-if="item.sex == 1" />
							<img src="../../image/message/32.png" class="margin_r4" alt="" v-if="item.sex == 2" style="width: 12px; height: 12px" />
							<div class="flex">
								<img :src="item.wealthLog" alt="" v-if="item.wealthLog" class="listItem_icon" />
								<!-- <img
                  :src="item.charismaLog"
                  alt=""
                  v-if="item.charismaLog"
                  class="listItem_icon"
                /> -->
							</div>
							<img src="../../image/room/r1.png" class="margin_r4" style="width: 32px" alt="" v-if="item.idCard == 0" />
							<img src="../../image/room/r2.png" class="margin_r4" style="width: 32px" alt="" v-if="item.idCard == 1" />
							<img src="../../image/room/r3.png" class="margin_r4" style="width: 32px" alt="" v-if="item.idCard == 2" />
							<!-- <div v-if="personnelType == 2" class="vip_num">
                {{ item.totalDiamond }}
              </div> -->
						</div>
					</div>
					<div class="vip_bottom" v-if="personnelType == 2">
						<p>本厅消费累计成为VIP</p>
						<p style="color: #ffdd00; margin-left: 8px">VIP特权 尊享荣耀</p>
					</div>
					<!-- 排麦列表 -->
					<div class="personnel_list" v-if="personnelType == 3 || personnelType == 4">
						<div class="personnel_listItem flex-spacebetween" style="align-items: center" v-for="(item, key3) in roomUserList" :key="key3">
							<div class="flex-aling" style="width: 70%" @click.stop="othersHomeChange(item)">

								<div style="position: relative;" class="margin_r8">
									<img :src="item.avatar" alt="" class="listItem_head XS" />
									<img src="../../static/vip.png" v-if='item.vipFlag==1'
										style="width: auto;height:11px;position: absolute;bottom:0px;left:50%;transform: translateX(-50%);" />
								</div>
								<span class="font-14 text-cut" style="width: 100px" :class="{'vipred':item.vipFlag==1}">{{ item.nickname }}</span>
								<img src="../../image/message/24.png" class="margin_r4" alt="" v-if="item.sex == 1" />
								<img src="../../image/message/25.png" class="margin_r4" alt="" v-else style="width: 12px; height: 12px" />
								<div class="flex">
									<!-- <img :src="item.charismaLevelLogo" alt="" v-if="item.charismaLevelLogo" class="listItem_icon" style="width: 22px; height: 22" /> -->
									<img :src="item.wealthLevelLogo" alt="" v-if="item.wealthLevelLogo" class="listItem_icon" />
								</div>
								<img src="../../image/room/72.png" alt="" class="margin_r4" v-if="item.idCard == 0" />
								<img src="../../image/room/73.png" alt="" class="margin_r4" v-if="item.idCard == 1" />
								<img src="../../image/room/74.png" alt="" class="margin_r4" v-if="item.idCard == 2" />
							</div>
							<!-- 上麦操作 -->
							<div class="flex" v-if="userRole < 2 || userRole == 6 || compereType">
								<div class="personnel_btn cursor" style="margin-right: 4px" @click="acceptUpMicrophone(item, 2)">
									拒绝
								</div>
								<div class="personnel_btn cursor" style="color: #fff; border: 1px solid #fff" @click="acceptUpMicrophone(item, 1)">
									上麦
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="chat_centre" @click="wheatHomeShow = false">
				<!-- vollbildShow && roomSetData -->
				<div class="broadcast_right" v-if="vollbildShow && allGfitBanner">
					<div class="broadcast_rightItem flex-aling">
						<div class="flex text-cut" style="align-items: center">
							<div class="fullBand_textFlex">
								{{ vollbildData.sendUserName }}在{{
                  vollbildData.roomName
                }}送给{{ vollbildData.toUserName }}
							</div>
							<div class="flex" style="align-items: center">
								<img :src="vollbildData.giftLogo" alt="" style="width: 40px; height: 40px" />
								<div>*{{ vollbildData.giftNum }}</div>
							</div>
							<!-- <p>{{ wholeData.content }}</p> -->
						</div>
						<!-- <img src="../../image/room/80.png" alt="" style="margin: 0 10px" /> -->
						<!-- <div class="fullBand_ben" @click="goPage(wholeData)">去围观</div> -->
					</div>
				</div>
				<!--播放礼物特效弹窗 -->
				<!--  -->
				<div v-if="giftSvgaPlayShow && roomSetData.allGift != 1" class="gift_style" style="pointer-events: none">
					<!--  -->
					<svgaImg ref="newGiftSvga" style="position: absolute; width:100%;left:0;top:0; z-index: 10" :value="giftValue" :cid="'gift'" :widthNum="'820px'"
						:heightNum="'565px'" :loopsNum="1" @closeSvgaChange="closeSvgaPopUpChange()"></svgaImg>
				</div>
				<el-tooltip class="item" effect="dark" content="举报房间" placement="bottom">
					<div class="suspension_icon" @click="reportChangeShow(2)"></div>
				</el-tooltip>

				<!-- <div class="suspension_icon" @click="upgraeShow = !upgraeShow"></div> -->
				<!-- 相亲相爱弹窗 -->
				<div class="suspension_topIcon flex-aling" v-if="roomDetail.resultCode == 4" @click.stop="loveEachPopupChange()">
					玩法说明
					<img src="../../static/41.png" alt="" style="margin-left: 4px" />
				</div>
				<div v-if="roomDetail.roomVoiceVO && roomDetail.roomVoiceVO.existGodRich" class="suspension_icon2" @click.stop="deityRichRuleShowChange()"></div>

				<!-- 开黑房 -->
				<div class="voice_box" v-if="roomDetail.resultCode == 6" @click="darkRoomChange()">
					<!-- sssssssss{{ roomDetail.resultCode}}12 -->
					<!-- v-if="isBlackRoom == 1" -->
					<div>
						<div v-if="roomDetail.resultCode == 6">
							<!-- 主持人任务弹窗end -->
							<!-- 用户麦 -->
							<div class="microphone_boxBlack">
								<div class="microphone_item" style="width: 90px" v-for="(item, key3) in roomDetail.normalPositions" :key="key3">
									<div v-if="item.status == 2">
										<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="userClick(key3, item)" />
										<div class="microphone_text" style="color: #fff">
											麦位已锁
										</div>
									</div>
									<div v-else style="position: relative; width: 90px">
										<img :src="item.userAvatar" alt="" class="wheat_avatar" style="object-fit: cover" v-if="item.userId"
											@click="wheatHomeShowChenge(item, 1)" />
										<img src="../../static/143.png" alt="" v-else />
										<div class="gift_num" v-if="item.giftValueStr > 0">
											{{ item.giftValueStr }}
										</div>
										<svgaImg style="position: absolute; top: -19px; left: 0px;width:90px;" :cid="key3" :widthNum="'90px'" :heightNum="'90px'"
											:value="roomDetail.normalPositions[key3].photoFrame" v-if="roomDetail.normalPositions[key3].photoFrame"></svgaImg>
										<img src="../../static/149.png" alt="" class="bannedToPost_icon" v-if="item.microphone == 1 && item.userId" />
										<img src="../../static/236.png" alt="" class="bannedToPost_icon" v-if="item.microphone == 0 && item.userId" />
										<div class="microphone_text" style="color: #fff">
											{{ item.userId ? item.userName : "空闲麦位" }}
										</div>
									</div>

									<!-- 锁麦时点击 -->
									<div class="function_popover" v-if="popoverShow == key3 && item.status == 2">
										<div class="popover_item" @click="setLockPosition(item, 1)">
											解锁
										</div>
										<div class="popover_item" @click.stop="closingTime(item)">
											设为闭麦位
										</div>
									</div>
									<!-- 用户麦位点击弹窗 -->
									<div class="function_popover" v-if="
                    popoverShow == key3 && userRole == 3 && whetherWheat != 1
                  ">
										<div class="popover_item" @click.stop="upMicrophoneChenge(item)">
											上麦
										</div>
									</div>
									<!-- 管理及以为麦位点击弹窗 -->
									<div class="function_popover" v-if="
                    popoverShow == key3 && userRole != 3 && item.status == 0
                  ">
										<div class="popover_item" @click.stop="upMicrophoneChenge(item)" v-if="whetherWheat != 1">
											上麦
										</div>
										<div class="popover_item" @click.stop="embraceShowChange(item)">
											抱用户上麦
										</div>
										<div class="popover_item" @click="setLockPosition(item, 0)">
											锁麦
										</div>
										<div class="popover_item" @click.stop="closingTime(item)">
											设为闭麦位
										</div>
									</div>
								</div>
							</div>
							<!-- 麦克风end -->
						</div>
					</div>
					<div class="order_meal flex-dir" style="margin-top: 60px" v-if="compereType" @click.stop="musicShow = !musicShow">
						<img src="../../static/8.png" alt="" />
						<img src="../../static/187.png" alt="" style="position: absolute" v-if="musicShow" />
						<musicBox v-show="musicShow" @closeMusic="musicShow = false"></musicBox>
					</div>

					<div class="microphone_feature flex">
						<div class="flex-aling" v-if="userGiveAwayType">
							<div>赠送:</div>
							<div class="giveAway_box flex-aling">
								<img src="../../image/room/57.png" alt="" class="giveAway_icon" @click="closeGiveChange()" />
								<img :src="
                  othersHometype == 2
                    ? theWheatData.avatar
                    : othersData.avatar
                " alt="" class="giveAway_head" />
								<div>
									{{
  othersHometype == 2
  ? theWheatData.nickname
  : othersData.nickname
                  }}
								</div>
							</div>
						</div>
						<div class="roomPerson_list" v-else>
							<div class="flex-dir" style="width: 60px" @click="allUserPitchChange()">
								<img src="../../static/150.png" style="width: 20px;height:20px;" alt="" />
								<div style="color: #fff;font-size: 12px;margin-top:5px;" class="XS">送全麦</div>
							</div>
							<div class="roomPerson_item" v-for="(item, index) in roomMicrophoneList" :key="index">
								<div class="roomPerson_item" v-if="index < 3 && !microphoneListType && item.userId" @click="userPitchChange(item, index)">
									<img :src="item.userAvatar" alt="" style="object-fit: cover" class="roomPerson_img" :class="item.pitch ? 'roomPerson_imgPitch' : ''" />
									<img src="../../static/148.png" class="roomPerson_icon" v-if="item.idCard == 0" />
									<template v-for="(opn, key1) in userPitchList">
										<div class="roomPerson_pitch flex-content" v-if="item.userId == opn.userId" :key="key1">
											{{ key1 + 1 }}
										</div>
									</template>
								</div>
								<div class="roomPerson_item" v-if="microphoneListType && item.userId" @click="userPitchChange(item, key1)">
									<img :src="item.userAvatar" alt="" style="object-fit: cover" class="roomPerson_img" :class="item.pitch ? 'roomPerson_imgPitch' : ''" />
									<img src="../../static/148.png" class="roomPerson_icon" v-if="item.idCard == 0" />
									<template v-for="(opn, key1) in userPitchList">
										<div class="roomPerson_pitch flex-content" v-if="item.userId == opn.userId" :key="key1">
											{{ key1 + 1 }}
										</div>
									</template>
								</div>
							</div>
							<img src="../../image/room/56.png" alt="" v-if="roomMicrophoneList.length > 3" :style="{
                transform: microphoneListType ? 'rotate(180deg)' : '',
              }" @click="microphoneListType = !microphoneListType" />
						</div>
						<!-- 房主权限 -->
						<div class="feature_box flex-aling" v-if="
              roomDetail.userRole == 0 ||
              roomDetail.userRole == 1 ||
              roomDetail.userRole == 2
            ">
							<img src="../../static/146.png" alt="" class="feature_icon" @click="shieldingSetChange()" />
							<img src="../../image/room/23.png" alt="" class="feature_icon" @click="roomFunctionShowChange()" />

							<div v-if="whetherWheat == 1" class="flex-content downMicrophone" @click="userLeaveSeat()">
								<img src="../../image/room/22.png" alt="" />
								<span>下麦</span>
							</div>
							<div v-else-if="whetherWheat == 2" class="flex-content cancelBtn" @click="cancelWheatClearing()">
								<img src="../../image/room/22.png" alt="" />
								<span>取消</span>
							</div>
							<div v-else class="flex-content upMicrophone" @click="wheatRemindShow = true">
								<!--  -->
								<img src="../../static/147.png" alt="" />
								<span>上麦</span>
							</div>
							<div class="flex-content closeMicrophone" @click="closeMicrophone(1)" v-if="microphoneType">
								<span>开麦</span>
							</div>
							<div class="flex-content closeMicrophone" @click="closeMicrophone(0)" v-else>
								<img src="../../image/room/20.png" alt="" />
								<span>闭麦</span>
							</div>
							<!-- 屏蔽设置弹窗 -->
							<div class="shielding_set" v-if="shieldingSetShow">111
								<div class="shielding_list flex-aling" style="cursor: pointer;" v-for="(item, key0) in shieldingList" :key="key0" @click.stop="setRoomUserStateChange(item.state)">
									<img src="../../static/158.png" alt="" v-if="roomSetData[item.state] == 1" />
									<img src="../../static/159.png" alt="" v-else />
									<span>{{ item.text }}</span>
								</div>
							</div>
							<!-- 房间操作弹窗 -->
							<!-- 开黑房房主操作权限 -->
							<div class="room_function" v-if="roomFunctionShow" style="left: -210px">
								<div class="flex-dir roomFunction_item" v-for="(item, index) in roomDetail.userRole == 2
                ? houseOwnerListGuan
                : houseOwnerList" :key="index" @click="houseOwnerSwitch(index + 1)">
									<img :src="item.url" alt="" />
									{{ index == 0 ? roomDetail.isLock == 0 ? item.name : "房间解锁" : item.name }}
								</div>
							</div>
						</div>
						<!-- 房主权限end -->

						<!-- 用户权限 -->
						<div class="feature_box flex-aling" v-if="userRole == 4 || userRole == 3">
							<img src="../../static/146.png" alt="" class="feature_icon" @click="shieldingSetShow = !shieldingSetShow" />
							<div v-if="whetherWheat == 1" class="flex-content downMicrophone cursor" @click="userLeaveSeat()">
								<img src="../../image/room/22.png" alt="" />
								<span>下麦</span>
							</div>
							<div v-else-if="whetherWheat == 2" class="flex-content cancelBtn cursor" @click="cancelWheatClearing()">
								<img src="../../image/room/22.png" alt="" />
								<span>取消</span>
							</div>
							<div v-else class="flex-content upMicrophone cursor" @click="wheatRemindShow = true">
								<img src="../../static/147.png" alt="" />
								<span>上麦</span>
							</div>

							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(1)" v-if="microphoneType">
								<span>开麦</span>
							</div>
							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(0)" v-else>
								<img src="../../image/room/20.png" alt="" />
								<span>闭麦</span>
							</div>

							<!-- 屏蔽设置弹窗 -->
							<div class="shielding_set" v-if="shieldingSetShow">
								<div class="shielding_list flex-aling" style="cursor: pointer;" v-for="(item, key0) in shieldingList" :key="key0" @click.stop="setRoomUserStateChange(item.state)">
									<img src="../../static/158.png" alt="" v-if="roomSetData[item.state] == 1" />
									<img src="../../static/159.png" alt="" v-else />
									<span>{{ item.text }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 开黑房end -->
				<!-- 公会房间 -->
				<div class="voice_box" v-else @click="GuildRoomChange()">
					<div>
						<!-- 派单厅 -->
						<!-- <div v-if="roomStatus == 5 && roomDetail.roomDistributeVO"> -->
						<div v-if="roomDetail.resultCode == 0">
							<!-- 派单说明 -->
							<!-- <div class="send_list flex">
                <div class="send_icon">派单说明</div>
                <div style="width: 200px">
                  <span>{{ roomDetail.orderExplain }}</span>
                  <span class="send_item flex-aling">王者荣耀</span>
                  <span class="send_item flex-aling">荣耀王者</span>
                  <span class="send_item flex-aling">性别不限</span>
                  <span>1000/局</span>
                  <div>备注：Q区 会教学</div>
                </div>
              </div> -->
							<!-- 派单说明end -->
							<div class="flex-spacebetween emceeTop_box">
								<!-- 主持人 -->
								<div class="emcee_box" v-if="roomDetail.hostVO.userId">
									<div class="emcee_head flex-content" @click.stop="wheatHomeShowChenge(roomDetail.hostVO, 2)">
										<img :src="roomDetail.hostVO.userAvatar" style="object-fit: cover" alt="" />
										<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="roomDetail.hostVO.photoFrame"
											v-if="roomDetail.hostVO.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'">
										</svgaImg>
										<div class="soundWave"></div>
										<div class="gift_num" v-if="roomDetail.hostVO.giftValue > 0">
											{{ roomDetail.hostVO.giftValueStr }}
										</div>
										<img src="../../static/149.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 1" />
										<img src="../../static/236.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 0" />
										<!-- <img v-if="roomDetail.hostVO.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->

										<!-- <img v-else src="../../static/230.png" class="head_icon" /> -->
									</div>
									<div class="emcee_name">
										主持:<span class="padding_r4">{{roomDetail.hostVO.userName}}</span>
										<span class="emcee_task" @click="getHostTask()">任务</span>
									</div>
									<div class="emcee_state" v-if="roomDetail.hostVO.hostSign">
										{{ roomDetail.hostVO.hostSign }}
									</div>
								</div>
								<!-- 主持人被锁麦 -->
								<div v-else-if="roomDetail.hostVO.status == 2" class="emcee_box">
									<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="relieveShow = true" />
									<div class="microphone_text" style="color: #fff; margin-top: 8px">
										麦位已锁
									</div>
									<div class="function_popover" v-if="relieveShow" style="top: 5px; left: 70%">
										<div class="popover_item" @click="setLockPosition(roomDetail.hostVO, 1, 1)">
											解锁
										</div>
									</div>
								</div>
								<!-- 主持人未上麦 -->
								<div class="emcee_box" v-else @click.stop="userClickWheat()">
									<div class="emcee_head flex-content" style="background: transparent">
										<img src="../../static/143.png" alt="" />
									</div>
									<div class="emcee_name">
										主持:<span class="padding_r4"></span>
									</div>
									<!-- <div class="emcee_state"></div> -->
								</div>
								<div class="function_popover" style="left: 55%; top: 10%" v-if="
                  comperepopoverShow &&
                  whetherWheat != 1 &&
                  (userRole == 0 || userRole == 1)
                ">
									<div class="popover_item" @click.stop="
  comperephoneChenge();
comperepopoverShow = false;
                  ">
										上麦
									</div>
									<div class="popover_item" @click.stop="
  embraceShowChange(roomDetail.hostVO, 1);
comperepopoverShow = false;
                  ">
										抱用户上麦
									</div>
									<div class="popover_item" @click.stop="
  setLockPosition(roomDetail.hostVO, 0, 1);
comperepopoverShow = false;
                  ">
										锁麦
									</div>
									<!-- <div class="popover_item" @click.stop="closingTime(item)">
                    设为闭麦位
                  </div> -->
								</div>
								<!-- 主持人end -->
							</div>
							<!-- 主持人任务弹窗 -->
							<div class="emceeTask_popup" v-if="emceeTaskShow">
								<div class="emceeTask_title">主持人礼物任务</div>
								<div class="flex-spacebetween">
									<div class="flex-dir" v-for="(item, key1) in hostTaskList" :key="key1">
										<div class="task_box flex-dir">
											<div class="task_type flex-content">
												<div style="transform: scale(0.7); color: #fff">
													{{ `${item.getNum}/${item.num}` }}
												</div>
											</div>
											<img :src="item.giftPath" alt="" class="task_icon1" />
											<div style="color: #212121">{{ item.giftName }}</div>
											<div class="flex-aling">
												<img src="../../static/user3.png" alt="" class="task_icon2" />
												<span>{{ item.giftPrice }}</span>元宝
											</div>
											<div class="task_presented flex-content" @click="sendGift1(item)">
												赠送
											</div>
										</div>
										<div class="font-10 padding_6" style="color: #333" v-if="item.type == 1">
											今日任务
										</div>
										<div class="font-10 padding_6" style="color: #333" v-else-if="item.type == 2">
											本周任务
										</div>
										<div class="font-10 padding_6" style="color: #333" v-else-if="item.type == 3">
											本月任务
										</div>
									</div>
									<div class="notSetHint" v-if="hostTaskList.length === 0">
										暂未设置礼物任务
									</div>
								</div>
								<div class="task_hint flex-content" style="color: #333">
									超过限定时间，礼物任务失效
								</div>
							</div>
							<!-- 主持人任务弹窗end -->
							<!-- 麦克风 -->
							<div class="microphone_box">
								<!-- 老板位 -->
								<div class="microphone_item" style="position: relative">
									<template v-if="roomDetail.normalPositions[0].userId">
										<img :src="roomDetail.normalPositions[0].userAvatar" alt="" class="wheat_avatar" style="object-fit: cover" @click.stop="
                        aloneWheatHomeShow(roomDetail.normalPositions[0].userId)
                      " />
										<svgaImg style="position: absolute; top: -15px; left: 0;right:0;margin:0 auto;width:90px;" :value="roomDetail.normalPositions[0].photoFrame"
											:cid="'boss'" :widthNum="'90px'" :heightNum="'90px'"></svgaImg>
										<div class="gift_num" v-if="roomDetail.normalPositions[0].giftValue > 0">
											{{ roomDetail.normalPositions[0].giftValueStr }}
										</div>
										<div class="microphone_text" style="color: #fff">
											{{ roomDetail.normalPositions[0].userName }}
										</div>
										<img src="../../static/231.png" alt="" class="bannedToPost_icon" v-if="
                      roomDetail.normalPositions[0].positionIsClose == 1 ||
                      (roomDetail.normalPositions[0].positionIsClose == 1 && roomDetail.normalPositions[0].userId)
                    " />
										<img src="../../static/230.png" alt="" class="bannedToPost_icon"
											v-if="roomDetail.normalPositions[0].positionIsClose == 0 && roomDetail.normalPositions[0].userId" />
									</template>
									<template v-else>
										<img src="../../static/142.png" alt="" @click.stop="
                      (wheatRemindShow = true),
                      (userPositionId = roomDetail.normalPositions[0])
                    " />
										<div class="microphone_text" style="color: #fff">
											我要点单
										</div>
									</template>
								</div>
								<!-- 老板位 end-->
								<!-- 用户麦 -->
								<div class="microphone_item" v-for="(item, key3) in roomDetail.normalPositions" :key="key3" v-if="key3 != 0">
									<div v-if="item.status == 2">
										<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="userClick(key3, item)" />
										<div class="microphone_text" style="color: #fff">
											麦位已锁
										</div>
									</div>
									<div style="position: relative" v-else>
										<div v-if="item.userId" @click.stop="wheatHomeShowChenge(item, 1)">
											<img :src="item.userAvatar" alt="" class="wheat_avatar" style="object-fit: cover" v-if="item.userId" />
											<svgaImg style="position: absolute; top: -8px;left:0;right:0;margin:auto;width:70px;" :value="wheatFrameList[key3].photoFrame"
												:cid="item.order" :widthNum="'70px'" :heightNum="'70px'"></svgaImg>
										</div>

										<img src="../../static/143.png" alt="" v-else @click.stop="userClick(key3, item)" />
										<div class="gift_num" v-if="item.giftValue > 0">
											{{ item.giftValue > 0 ? item.giftValueStr : "0" }}
										</div>

										<img src="../../static/231.png" alt="" class="bannedToPost_icon" v-if="
                      item.positionIsClose == 1 ||
                      (item.positionIsClose == 1 && item.userId)
                    " />
										<img src="../../static/230.png" alt="" class="bannedToPost_icon" v-if="item.positionIsClose == 0 && item.userId" />
										<div class="microphone_text" style="color: #fff">
											{{ item.userId ? item.userName : "空闲麦位" }}
										</div>
									</div>

									<!-- 锁麦时点击 -->
									<div class="function_popover" v-if="popoverShow == key3 && item.status == 2">
										<div class="popover_item" @click="setLockPosition(item, 1)">
											解锁
										</div>
										<div class="popover_item" @click.stop="closingTime(item)">
											设为闭麦位
										</div>
									</div>
									<!-- 用户麦位点击弹窗 -->
									<div class="function_popover" v-if="
                    popoverShow == key3 && userRole == 3 && whetherWheat != 1
                  ">
										<div class="popover_item" @click.stop="upMicrophoneChenge(item)">
											上麦
										</div>
									</div>
									<!-- 管理及以为麦位点击弹窗 -->
									<div class="function_popover" v-if="
                    popoverShow == key3 && userRole != 3 && item.status == 0
                  ">
										<div class="popover_item" @click.stop="upMicrophoneChenge(item)" v-if="whetherWheat != 1">
											上麦
										</div>
										<div class="popover_item" @click.stop="embraceShowChange(item)">
											抱用户上麦
										</div>
										<div class="popover_item" @click="setLockPosition(item, 0)">
											锁麦
										</div>
										<div class="popover_item" @click.stop="closingTime(item)">
											{{
                        item.positionIsClose == 1 ? "解除闭麦位" : "设为闭麦位"
                      }}
										</div>
									</div>
								</div>
							</div>
							<!-- 麦克风end -->
						</div>
					</div>
					<!-- 正常语聊模式 && 点唱厅-->
					<div v-if="roomDetail.resultCode == 1 || roomDetail.resultCode == 5">
						<div class="flex-spacebetween emceeTop_box">
							<!-- 主持人 -->
							<div class="emcee_box" v-if="roomDetail.hostVO.userId">
								<div class=" flex-content" style="position: relative" @click.stop="wheatHomeShowChenge(roomDetail.hostVO, 2)">
									<div class="emcee_head">
										<img :src="roomDetail.hostVO.userAvatar" style="object-fit: cover" alt="" />
										<img src="../../static/149.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 1" />
										<img src="../../static/236.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 0" />
									</div>
									<div class="gift_num" v-if="roomDetail.hostVO.giftValue > 0">
										{{ roomDetail.hostVO.giftValueStr }}
									</div>
									<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="roomDetail.hostVO.photoFrame"
										v-if="roomDetail.hostVO.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'"></svgaImg>
								</div>
								<div class="emcee_name">
									主持:<span class="padding_r4">{{roomDetail.hostVO.userName}}</span>
									<span class="emcee_task" @click="getHostTask()" v-if="taskStatus == 1">任务</span>
								</div>
								<!-- <span style="color:#333;">
									{{roomDetail.hostVO}}
								</span> -->
								<div class="emcee_state" v-if="roomDetail.hostVO.sign" style="left: 70%; top: 0%">
									{{ roomDetail.hostVO.sign }}
								</div>
							</div>
							<div v-else-if="roomDetail.hostVO.status == 2" class="emcee_box">
								<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="relieveShow = true" />
								<div class="microphone_text" style="color: #fff; margin-top: 8px">
									麦位已锁
								</div>
								<div class="function_popover" v-if="relieveShow" style="top: 25px; left: 70%">
									<div class="popover_item" @click="setLockPosition(roomDetail.hostVO, 1, 1)">
										解锁
									</div>
								</div>
							</div>
							<div class="emcee_box" v-else @click.stop="userClickWheat()">
								<div class="emcee_head flex-content">
									<img src="../../static/143.png" alt="" />
								</div>
								<div class="emcee_name">
									主持:<span class="padding_r4"></span>
								</div>
								<div class="function_popover" style="left: 70%; top: 10%" v-if="comperepopoverShow && whetherWheat != 1">
									<div class="popover_item" @click.stop="comperephoneChenge()">
										上麦
									</div>
									<div v-if="roomDetail.userRole == 0 || roomDetail.userRole == 1" class="popover_item" @click.stop="embraceShowChange(roomDetail.hostVO, 1)">
										抱用户上麦
									</div>
									<div v-if="roomDetail.userRole == 0 || roomDetail.userRole == 1" class="popover_item" @click.stop="setLockPosition(roomDetail.hostVO, 0, 1)">
										锁麦
									</div>
									<!-- <div class="popover_item" @click.stop="closingTime(item)">
                    设为闭麦位
                  </div> -->
								</div>
								<!-- <div class="emcee_state">少年强则国强</div> -->
							</div>
							<!-- 主持人end -->
							<!-- 神豪位 -->
							<div class="emcee_box" v-if="roomDetail.hasGodRich">
								<div class="flex-dir" v-if="roomDetail.richVO.userId" style="margin-top: 0px">
									<div class="emcee_headDeity flex-content">
										<img src="../../image/room/7.png" alt="" class="emcee_deityImg" />
										<img :src="roomDetail.richVO.userAvatar" alt="" />
										<div class="gift_num" v-if="roomDetail.richVO.giftValue > 0">
											{{ roomDetail.richVO.giftValueStr }}
										</div>
									</div>
									<div class="emcee_name">
										<span class="padding_r4">{{
                      roomDetail.richVO.userName
                    }}</span>
									</div>
									<!-- <THcountDown :endTime="roomDetail.richVO.endTime" ></THcountDown>
                  {{this.pkMinute = m;
        this.pkSecond = s;}} -->
									{{ ` ${pkShi}:${pkMinute}:${pkSecond}` }}
								</div>
								<div class="flex-dir" v-else>
									<div class="emcee_headDeity flex-content">
										<img src="../../image/room/7.png" alt="" class="emcee_deityImg" />
										<img src="../../static/143.png" alt="" />
									</div>
									<div class="emcee_name">
										<span class="padding_r4" style="color: #8692ae">神豪位空</span>
									</div>
								</div>
							</div>
							<!-- 神豪位end -->
						</div>
						<!-- 主持人任务弹窗 -->
						<div class="emceeTask_popup" v-if="emceeTaskShow" :style="{
              left: roomDetail.richVO ? '35%' : '60%',
            }">
							<div class="emceeTask_title">主持人礼物任务</div>
							<div class="flex">
								<div class="flex-dir" v-for="(item, key1) in hostTaskList" :key="key1">
									<div class="task_box flex-dir">
										<div class="task_type flex-content">
											<div style="transform: scale(0.7); color: #fff">
												{{ `${item.getNum}/${item.num}` }}
											</div>
										</div>
										<img :src="item.giftPath" alt="" class="task_icon1" />
										<div style="color: #212121">{{ item.giftName }}</div>
										<div class="flex-aling">
											<img src="../../static/user3.png" alt="" class="task_icon2" />
											<span>{{ item.giftPrice }}</span>元宝
										</div>
										<div class="task_presented flex-content">赠送</div>
									</div>
									<div class="font-10 padding_6" v-if="item.type == 1">
										今日任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 2">
										本周任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 3">
										本月任务
									</div>
								</div>
								<div class="notSetHint" v-if="hostTaskList.length === 0">
									暂未设置礼物任务
								</div>
							</div>
							<div class="task_hint flex-content">
								超过限定时间，礼物任务失效
							</div>
						</div>
						<!-- 主持人任务弹窗end -->
						<!-- 麦克风 -->
						<div class="microphone_box">
							<div class="microphone_item" v-for="(item, key3) in roomDetail.normalPositions" :key="key3">
								<img src="../../static/149.png" alt="" v-if="item.microphone == 1 && item.userId" style="
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    bottom: 32px;
                    right: 50px;
                  " />
								<img src="../../static/236.png" alt="" v-if="item.microphone == 0 && item.userId" style="
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    bottom: 32px;
                    right: 50px;
                  " />
								<div v-if="item.status == 2">
									<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="userClick(key3, item)" />
									<div class="microphone_text" style="color: #fff">
										麦位已锁
									</div>
								</div>
								<div v-else>
									<div v-if="item.userId" @click.stop="wheatHomeShowChenge(item, 1)">
										<div class="gift_num" v-if="item.giftValue > 0">
											{{ item.giftValueStr }}
										</div>
										<img :src="item.userAvatar" alt="" style="object-fit: cover" class="wheat_avatar" />
										<!-- <img style="position: absolute; top: -19px; left: 33px;width:90px;height:90px" :src="wheatFrameList[key3].photoFrame" alt=""> -->
										<svgaImg style="position: absolute; top: -19px;  left: 0;right:0;margin:0 auto;width: calc(78px);" :cid="key3" :widthNum="'90px'"
											:heightNum="'90px'" :value="wheatFrameList[key3].photoFrame" v-if="wheatFrameList[key3].photoFrame"></svgaImg>
										<img src="../../static/149.png" alt="" v-if="item.microphone == 1 && item.userId" style="
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        bottom: 32px;
                        right: 50px;
                      " />
										<img src="../../static/236.png" alt="" v-if="item.microphone == 0 && item.userId" style="
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        bottom: 32px;
                        right: 50px;
                      " />
										<img src="../../static/147.png" alt="" v-if="item.microphone == 0" style="
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        bottom: 32px;
                        right: 50px;
                      " />
										<div class="microphone_text" style="color: #fff">
											{{ item.userName }}
										</div>
									</div>
									<template v-else>
										<img src="../../static/143.png" alt="" @click.stop="userClick(key3, item)" />
										<div class="microphone_text">空闲麦位</div>
									</template>
								</div>
								<!-- 锁麦时点击 -->
								<div class="function_popover" v-if="
                  popoverShow == key3 &&
                  item.status == 2 &&
                  (userRole < 2 || userRole == 6 || roomDetail.userRole < 2)
                ">
									<div class="popover_item" @click="setLockPosition(item, 1)">
										解锁
									</div>
									<div class="popover_item" @click.stop="closingTime(item)" v-if="item.positionIsClose == 1">
										解除闭麦位
									</div>
									<div class="popover_item" @click.stop="closingTime(item)" v-else>
										设为闭麦位
									</div>
								</div>
								<div class="function_popover" v-if="
                  popoverShow == key3 &&
                  item.status == 0 &&
                  (userRole < 2 || userRole == 6 || roomDetail.userRole < 2)
                ">
									<!-- popoverShow == key3 && item.status == 0 && userRole < 2 -->
									<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
										上麦
									</div>
									<div class="popover_item" @click.stop="embraceShowChange(item)">
										抱用户上麦
									</div>
									<div class="popover_item" @click="setLockPosition(item, 0)">
										锁麦
									</div>
									<div class="popover_item" @click.stop="closingTime(item)" v-if="item.positionIsClose == 1">
										解除闭麦位
									</div>
									<div class="popover_item" @click.stop="closingTime(item)" v-else>
										设为闭麦位
									</div>
								</div>
							</div>
						</div>

						<!-- 麦克风end -->
					</div>
					<!-- 相亲相爱模式  roomStatus == 1 &&-->
					<div v-if="roomDetail.resultCode == 4" style="position: relative">
						<!-- 流程 -->
						<div class="flowPath_box flex-content">
							<div class="flowPath_item flex-content" :class="loveStage >= 1 ? 'flowPath_path' : ''" @click="modeSwitchChange(1)">
								嘉宾交流
							</div>
							<div class="you_icon1" :class="loveStage >= 2 ? 'you_icon2' : ''"></div>
							<div class="flowPath_item flex-content" :class="loveStage >= 2 ? 'flowPath_path' : ''" @click="modeSwitchChange(2)">
								真爱选择
							</div>
							<div class="you_icon1" :class="loveStage >= 3 ? 'you_icon2' : ''"></div>
							<div class="flowPath_item flex-content" :class="loveStage >= 3 ? 'flowPath_path' : ''">
								<!-- @click="modeSwitchChange(3)" -->
								公布真爱
							</div>
						</div>
						<div class="flex-content" v-if="loveStage == 2" style="margin-top: 8px">
							<span style="color: #ff7d00; font-size: 16px; font-weight: bold">{{ `00:${loveTimeCount}` }}</span>
						</div>
						<div class="flex-spacebetween emceeTop_box">
							<!-- 主持人 -->
							<div class="emcee_box" v-if="roomDetail.hostVO.userId">
								<div class="emcee_head flex-content">
									<img :src="roomDetail.hostVO.userAvatar" style="object-fit: cover" alt="" />
									<div class="gift_num" v-if="roomDetail.hostVO.hostGiftNum > 0">
										{{ roomDetail.hostVO.hostGiftNum }}
									</div>
									<svgaImg style="position: absolute; top: -15px; left: -15px;width: calc(100% + 30px);" :value="roomDetail.hostVO.photoFrame"
										v-if="roomDetail.hostVO.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'"></svgaImg>
									<div class="soundWave"></div>
								</div>
								<div class="emcee_name">
									主持:<span class="padding_r4">{{
                    roomDetail.hostVO.userName
                  }}</span>
									<span class="emcee_task" @click="getHostTask()" v-if="taskStatus == 1">任务</span>
								</div>
								<div class="emcee_state" v-if="roomDetail.hostVO.sign">
									{{ roomDetail.hostVO.sign }}
								</div>
							</div>
							<div v-else-if="roomDetail.hostVO.status == 2" class="emcee_box">
								<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="relieveShow = true" />
								<div class="microphone_text" style="color: #fff; margin-top: 8px">
									麦位已锁
								</div>
								<div class="function_popover" v-if="relieveShow" style="top: 10px; left: 70%">
									<div class="popover_item" @click="setLockPosition(roomDetail.hostVO, 1, 1)">
										解锁
									</div>
								</div>
							</div>
							<div class="emcee_box" v-else @click.stop="userClickWheat()">
								<div class="emcee_head flex-content">
									<img src="../../static/143.png" alt="" />
								</div>
								<div class="emcee_name">
									主持:<span class="padding_r4"></span>
								</div>
								<!-- <div class="emcee_state">少年强则国强</div> -->
								<div class="function_popover" style="left: 70%; top: 10%" v-if="comperepopoverShow && whetherWheat != 1">
									<div class="popover_item" @click.stop="comperephoneChenge()">
										上麦
									</div>
									<div v-if="userRole == 0 || userRole == 1" class="popover_item" @click.stop="embraceShowChange(roomDetail.hostVO, 1)">
										抱用户上麦
									</div>
									<div v-if="userRole == 0 || userRole == 1" class="popover_item" @click.stop="setLockPosition(roomDetail.hostVO, 0, 1)">
										锁麦
									</div>
								</div>
							</div>
							<!-- 主持人end -->
						</div>
						<!-- 主持人任务弹窗 -->
						<div class="emceeTask_popup" v-if="emceeTaskShow">
							<div class="emceeTask_title">主持人礼物任务</div>
							<div class="flex">
								<div class="flex-dir" v-for="(item, key1) in hostTaskList" :key="key1">
									<div class="task_box flex-dir">
										<div class="task_type flex-content">
											<div style="transform: scale(0.7); color: #fff">
												{{ `${item.getNum}/${item.num}` }}
											</div>
										</div>
										<img :src="item.giftPath" alt="" class="task_icon1" />
										<div style="color: #212121">{{ item.giftName }}</div>
										<div class="flex-aling">
											<img src="../../static/user3.png" alt="" class="task_icon2" />
											<span>{{ item.giftPrice }}</span>元宝
										</div>
										<div class="task_presented flex-content">赠送</div>
									</div>
									<div class="font-10 padding_6" v-if="item.type == 1">
										今日任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 2">
										本周任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 3">
										本月任务
									</div>
								</div>
								<div class="notSetHint" v-if="hostTaskList.length === 0">
									暂未设置礼物任务
								</div>
							</div>
							<div class="task_hint flex-content">
								超过限定时间，礼物任务失效
							</div>
						</div>
						<!-- 主持人任务弹窗end -->
						<!-- 正常麦位 -->
						<div class="blindData_box" @click="wheatHomeShow = false">
							<!-- 麦克风 -->
							<!-- 左侧用户 -->
							<div class="microphone_blindox">
								<template v-for="(item, key3) in roomDetail.normalPositions">
									<div v-if="key3 < 4" :key="key3" class="microphone_item" style="position: relative">
										<div v-if="item.status == 2">
											<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="userClick(key3, item)" />
											<div class="microphone_text" style="color: #fff">
												麦位已锁
											</div>
										</div>
										<div v-else-if="item.userId" style="position: relative">
											<div @click.stop="wheatHomeShowChenge(item, 1)">
												<img :src="item.userAvatar" class="head_img" alt="" style="object-fit: cover" />
												<svgaImg style="position: absolute; top: -16px; left: 0;right:0;margin:0 auto;width: calc(64px);"
													:value="wheatFrameList[key3].photoFrame" v-if="wheatFrameList[key3].photoFrame" :cid="item.order" :widthNum="'80px'"
													:heightNum="'80px'">
												</svgaImg>
											</div>
											<!-- && commonWheatType -->
											<div v-if="loveStage == 2 && userRole != 6">
												<div v-if="item.userId != userInfo.id && commonWheatType" class="love_isChoose love_isChoose1" @click.stop="selectTa(item)">
													选择TA
												</div>
												<!-- <div
                          v-else-if="item.userId == userInfo.id && item.isChoose == 1"
                          class="love_isChoose"
                        >
                          已选择
                        </div> -->
												<div v-else-if="
                          item.userId == userInfo.id && item.isChoose == 0
                        " class="love_isChoose love_isChoose1">
													未选择
												</div>
												<div class="microphone_text" style="color: #fff" v-else>
													{{ item.userName }}
												</div>
											</div>
											<div class="microphone_text" style="color: #fff" v-else>
												{{ item.userName }}
											</div>
											<!-- 礼物值 -->
											<div class="love_num">
												<img src="../../image/room/71.png" alt="" />
												{{ item.giftValueStr ? item.giftValueStr : 0 }}
											</div>
										</div>
										<div v-else @click.stop="userClick(key3, item)">
											<img src="../../static/143.png" alt="" />
											<div class="microphone_text">空闲麦位</div>
										</div>
										<!-- 锁麦时点击 -->
										<div class="function_popover" v-if="
                      popoverShow == key3 &&
                      item.status == 2 &&
                      (userRole < 2 ||
                        userRole == 6 ||
                        roomDetail.userRole < 2)
                    ">
											<div class="popover_item" @click="setLockPosition(item, 1)">
												解锁
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
										<!-- 锁麦时点击end -->
										<div class="function_popover" v-if="
                      popoverShow == key3 && item.status == 0 && userRole != 3
                    ">
											<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
												上麦
											</div>
											<div class="popover_item" @click.stop="embraceShowChange(item)">
												抱用户上麦
											</div>
											<div class="popover_item" @click="setLockPosition(item, 0)">
												锁麦
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
									</div>
								</template>
							</div>
							<!-- 中间图标 -->
							<img src="../../image/room/28.png" alt="" class="blindData_img" />
							<!-- 右侧用户 -->
							<div class="microphone_blindox">
								<template v-for="(item, key3) in roomDetail.normalPositions">
									<div v-if="key3 > 3" :key="key3" class="microphone_item" style="position: relative">
										<div v-if="item.status == 2">
											<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="userClick(key3, item)" />
											<div class="microphone_text" style="color: #fff">
												麦位已锁
											</div>
										</div>
										<div v-else-if="item.userId" style="position: relative">
											<div @click.stop="wheatHomeShowChenge(item, 1)">
												<img :src="item.userAvatar" class="head_img" alt="" style="object-fit: cover" />
												<svgaImg style="position: absolute; top: -16px; left: 0;right:0;margin:0 auto;width: calc(64px);"
													:value="wheatFrameList[key3].photoFrame" v-if="wheatFrameList[key3].photoFrame" :cid="item.order" :widthNum="'80px'"
													:heightNum="'80px'">
												</svgaImg>
											</div>
											<div v-if="loveStage == 2 && userRole != 6">
												<div v-if="item.userId != userInfo.id && commonWheatType" class="love_isChoose" @click.stop="selectTa(item)">
													选择TA
												</div>
												<!--  && commonWheatType -->
												<!-- <div
                          v-else-if="item.userId == userInfo.id &&  item.isChoose == 1"
                          class="love_isChoose"
                        >
                          已选择
                        </div> -->
												<div v-else-if="
                          item.userId == userInfo.id && item.isChoose == 0
                        " class="love_isChoose love_isChoose1">
													未选择
												</div>
												<div class="microphone_text" style="color: #fff" v-else>
													{{ item.userName }}
												</div>
											</div>
											<div class="microphone_text" style="color: #fff" v-else>
												{{ item.userName }}
											</div>
											<!-- 礼物值 -->
											<div class="love_num">
												<img src="../../image/room/71.png" alt="" />
												{{ item.giftValueStr ? item.giftValueStr : 0 }}
											</div>
										</div>
										<div v-else @click.stop="userClick(key3, item)">
											<img src="../../static/143.png" alt="" />
											<div class="microphone_text">空闲麦位</div>
										</div>
										<!-- 锁麦时点击 -->
										<div class="function_popover" v-if="
                      popoverShow == key3 &&
                      item.status == 2 &&
                      (userRole < 2 ||
                        userRole == 6 ||
                        roomDetail.userRole < 2)
                    ">
											<div class="popover_item" @click="setLockPosition(item, 1)">
												解锁
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
										<!-- 锁麦时点击end -->
										<div class="function_popover" v-if="
                      popoverShow == key3 && item.status == 0 && userRole != 3
                    ">
											<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
												上麦
											</div>
											<div class="popover_item" @click.stop="embraceShowChange(item)">
												抱用户上麦
											</div>
											<div class="popover_item" @click="setLockPosition(item, 0)">
												锁麦
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
									</div>
								</template>
							</div>
							<!-- 麦克风end -->
						</div>
						<!-- 公布真爱弹窗 -->
						<div class="publishLove" v-if="trueLoveShow">
							<template v-for="(item, index) in trueLoveList">
								<div class="flex-content realLove_box" v-if="trueLoveNum == index" :key="index">
									<img src="../../image/room/78.png" alt="" class="realLove_left" />
									<img src="../../image/room/79.png" alt="" class="realLove_right" />
									<div class="realLoveUser user1 flex-dir">
										<img :src="item.othUserAvatar" alt="" />
										<p>{{ item.othUserName }}</p>
									</div>
									<div class="realLoveUser user2 flex-dir">
										<img :src="item.userAvatar" alt="" />
										<p>{{ item.userName }}</p>
									</div>
									<div class="flex-dir font-16" style="z-index: 1; position: absolute; margin-top: 259px">
										<p style="margin: 8px 0">{{ item.nowDate }}</p>
										<p class="font-14">
											我们在{{ roomDetail.roomName }}相亲恋爱
										</p>
										<p class="font-14" style="width: 300px">
											聊天室
											<span style="color: #7eee5d">ID:{{ item.roomId }}</span>
											甜蜜牵手
										</p>
									</div>
								</div>
							</template>
						</div>
					</div>
					<!-- 房内PK模式 -->
					<div v-if="roomDetail.resultCode == 2">
						<!-- 主持人 -->
						<div class="emcee_box" v-if="roomDetail.hostVO.userId">
							<div class="emcee_head flex-content">
								<img :src="roomDetail.hostVO.userAvatar" style="object-fit: cover" alt="" />
								<div class="gift_num" v-if="roomDetail.hostVO.giftValue > 0">
									{{ roomDetail.hostVO.giftValueStr }}
								</div>
								<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="roomDetail.hostVO.photoFrame" v-if="roomDetail.hostVO.photoFrame"
									:cid="'host'" :widthNum="'90px'" :heightNum="'90px'"></svgaImg>
								<div class="soundWave"></div>
								<div class="soundWave"></div>
								<img src="../../static/149.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 1" />
								<img src="../../static/236.png" alt="" class="head_icon" v-if="roomDetail.hostVO.microphone == 0" />
							</div>
							<div class="emcee_name">
								主持:<span class="padding_r4">{{roomDetail.hostVO.userName}}</span>
								<span class="emcee_task" @click="getHostTask()" v-if="taskStatus == 1">任务</span>
							</div>
							<div class="emcee_state" v-if="roomDetail.hostVO.sign">
								{{ roomDetail.hostVO.sign }}
							</div>
						</div>
						<div v-else-if="roomDetail.hostVO.status == 2" class="emcee_box">
							<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="relieveShow = true" />
							<div class="microphone_text" style="color: #fff; margin-top: 8px">
								麦位已锁
							</div>
							<div class="function_popover" v-if="relieveShow" style="top: 10px; left: 70%">
								<div class="popover_item" @click="setLockPosition(roomDetail.hostVO, 1, 1)">
									解锁
								</div>
							</div>
						</div>
						<!-- 主持人未上麦 -->
						<div class="emcee_box" v-else @click.stop="userClickWheat()">
							<div class="emcee_head flex-content" style="background: transparent">
								<img src="../../static/143.png" alt="" />
							</div>
							<div class="emcee_name">
								主持:<span class="padding_r4"></span>
							</div>
							<div class="function_popover" style="left: 70%; top: 10%" v-if="comperepopoverShow && whetherWheat != 1">
								<div class="popover_item" @click.stop="comperephoneChenge()">
									上麦
								</div>
								<div v-if="userRole == 0 || userRole == 1" class="popover_item" @click.stop="embraceShowChange(roomDetail.hostVO, 1)">
									抱用户上麦
								</div>
								<div v-if="userRole == 0 || userRole == 1" class="popover_item" @click.stop="
  setLockPosition(roomDetail.hostVO, 0, 1);
comperepopoverShow = false;
                ">
									锁麦
								</div>
								<!-- <div class="popover_item" @click.stop="closingTime(item)">
                    设为闭麦位
                  </div> -->
							</div>
						</div>
						<!-- 主持人任务弹窗 -->
						<div class="emceeTask_popup" v-if="emceeTaskShow">
							<div class="emceeTask_title">主持人礼物任务</div>
							<div class="flex">
								<div class="flex-dir" v-for="(item, key1) in hostTaskList" :key="key1">
									<div class="task_box flex-dir">
										<div class="task_type flex-content">
											<div style="transform: scale(0.7); color: #fff">
												{{ `${item.getNum}/${item.num}` }}
											</div>
										</div>
										<img :src="item.giftPath" alt="" class="task_icon1" />
										<div style="color: #212121">{{ item.giftName }}</div>
										<div class="flex-aling">
											<img src="../../static/user3.png" alt="" class="task_icon2" />
											<span>{{ item.giftPrice }}</span>元宝
										</div>
										<div class="task_presented flex-content" @click="sendGift1(item)">
											赠送
										</div>
									</div>
									<div class="font-10 padding_6" v-if="item.type == 1">
										今日任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 2">
										本周任务
									</div>
									<div class="font-10 padding_6" v-else-if="item.type == 3">
										本月任务
									</div>
								</div>
								<div class="notSetHint" v-if="hostTaskList.length === 0">
									暂未设置礼物任务
								</div>
							</div>
							<div class="task_hint flex-content">
								超过限定时间，礼物任务失效
							</div>
						</div>
						<!-- 主持人任务弹窗end -->
						<div class="function_popover" style="left: 55%; top: 10%" v-if="comperepopoverShow && userRole == 0 && whetherWheat != 1">
							<div class="popover_item" @click.stop="comperephoneChenge()">
								上麦
							</div>
						</div>
						<!-- 主持人end -->
						<!-- 麦克风 -->
						<div class="pk_box">
							<!-- 准备中 -->
							<div class="pk_status flex-content" v-if="pkStatus == 1 && roomDetail.hostVO.userId == userInfo.id" @click.stop="pkStatusChenge()">
								开始PK
							</div>
							<div class="pk_status flex-content" v-else-if="pkStatus == 1">
								准备中 ...
							</div>
							<div class="pk_status pk_status2 flex-content" v-if="pkStatus == 2 && roomDetail.hostVO.userId == userInfo.id" @click.stop="pkStatusChenge()">
								{{ `PK中 ${pkMinute}:${pkSecond}` }}
							</div>
							<div class="pk_status pk_status2 flex-content" v-else-if="pkStatus == 2">
								{{ `PK中 ${pkMinute}:${pkSecond}` }}
							</div>
							<div class="pk_status pk_status2 flex-content" v-if="pkStatus == 3 && userInfo.id == roomDetail.hostVO.userId" @click.stop="modeSwitch(2)">
								PK结束
							</div>
							<img src="../../image/room/pk.png" alt="" class="pk_img" />
							<div class="pk_bgBox flex-aling">
								<!-- 普通麦位 -->
								<!-- 红方麦位 -->
								<template v-for="(item, key0) in roomDetail.normalPositions">
									<div v-if="key0 < 4" :key="key0" style="position: relative">
										<div class="pk_person flex-dir" v-if="item.userId" @click.stop="wheatHomeShowChenge(item, 1)">
											<div class="pk_head flex-content">
												<img :src="item.userAvatar" alt="" class="head_img" />
												<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
												<img src="../../static/149.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 1 && item.userId" />
												<img src="../../static/236.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 0 && item.userId" />
												<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="item.photoFrame"
													v-if="item.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'">
												</svgaImg>
											</div>
											<div class="font-12 padding_8">
												<span class="pk_index">{{ key0 + 1 }}</span>
												<span class="padding_r4">{{ item.userName }}</span>
											</div>
											<div class="pk_gift">
												<img src="../../image/room/1.png" alt="" />
												<span style="margin-left: 2px">{{item.giftValueStr}}</span>
											</div>
										</div>
										<!-- 麦上没人 -->
										<div class="pk_person flex-dir" v-else>
											<div v-if="item.status == 2">
												<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="popoverShow = key0" />
												<div class="microphone_text" style="color: #fff;font-size: 8px;margin-bottom: 6px;">
													麦位已锁
												</div>
											</div>
											<div class="pk_head" v-else @click.stop="popoverShow = key0">
												<div class="flex-content">
													<img src="../../static/143.png" alt="" class="head_img" />
													<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
												</div>
												<div class="font-12 padding_8">
													<span class="pk_index">{{ key0 + 1 }}</span>
													<span class="padding_r4">空闲麦位</span>
												</div>
											</div>
											<!-- 锁麦时点击 -->
											<div style="left: 90%" class="function_popover" v-if="
                        popoverShow == key0 &&
                        item.status == 2 &&
                        (userRole < 2 || userRole == 6)
                      ">
												<div class="popover_item" @click="setLockPosition(item, 1)">
													解锁
												</div>
												<div class="popover_item" @click.stop="closingTime(item)">
													设为闭麦位
												</div>
											</div>
											<!-- 锁麦时点击end -->
											<div class="function_popover" v-if="
                        popoverShow == key0 &&
                        item.status == 0 &&
                        userRole != 3
                      " style="left: 90%">
												<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
													上麦
												</div>
												<div class="popover_item" @click.stop="embraceShowChange(item)">
													抱用户上麦
												</div>
												<div class="popover_item" @click="setLockPosition(item, 0)">
													锁麦
												</div>
												<div class="popover_item" @click.stop="closingTime(item)">
													设为闭麦位
												</div>
											</div>
											<div class="function_popover" v-if="
                        popoverShow == key0 &&
                        item.status == 0 &&
                        userRole == 3
                      " style="left: 90%">
												<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
													上麦
												</div>
											</div>

											<div class="pk_gift">
												<img src="../../image/room/1.png" alt="" />
												<span style="margin-left: 2px">0</span>
											</div>
										</div>
									</div>
								</template>
							</div>

							<div class="pk_bgBox flex-aling">
								<!-- 蓝方麦位 -->
								<template v-for="(item, key1) in roomDetail.normalPositions">
									<div v-if="key1 > 3" :key="key1" style="position: relative">
										<div class="pk_person flex-dir" v-if="item.userId" @click.stop="wheatHomeShowChenge(item, 1)">
											<div class="pk_head flex-content">
												<img :src="item.userAvatar" alt="" class="head_img" />
												<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="item.photoFrame"
													v-if="item.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'">
												</svgaImg>
												<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
												<img src="../../static/149.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 1 && item.userId" />
												<img src="../../static/236.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 0 && item.userId" />
											</div>
											<div class="font-12 padding_8">
												<span class="pk_index">{{ key1 + 1 }}</span>
												<span class="padding_r4">{{ item.userName }}</span>
											</div>
											<div class="pk_gift">
												<img src="../../image/room/1.png" alt="" />
												<span style="margin-left: 2px">{{item.giftValueStr}}</span>
											</div>
										</div>
										<!-- 麦上没人 -->
										<div class="pk_person flex-dir" v-else>
											<div v-if="item.status == 2">
												<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="popoverShow = key1" />
												<div class="microphone_text" style="
                            color: #fff;
                            font-size: 8px;
                            margin-bottom: 6px;
                          ">
													麦位已锁
												</div>
											</div>
											<div class="pk_head" v-else @click.stop="popoverShow = key1">
												<div class="flex-content">
													<img src="../../static/143.png" alt="" class="head_img" />
													<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
												</div>
												<div class="font-12 padding_8">
													<span class="pk_index">{{ key1 + 1 }}</span>
													<span class="padding_r4">空闲麦位</span>
												</div>
											</div>
											<div class="function_popover" v-if="
                        popoverShow == key1 &&
                        item.status == 0 &&
                        userRole == 3
                      " style="left: 90%">
												<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
													上麦
												</div>
											</div>
											<!-- 锁麦时点击 -->
											<div style="left: 90%" class="function_popover" v-if="
                        popoverShow == key1 &&
                        item.status == 2 &&
                        (userRole < 2 ||
                          userRole == 6 ||
                          roomDetail.userRole < 2)
                      ">
												<div class="popover_item" @click="setLockPosition(item, 1)">
													解锁
												</div>
												<div class="popover_item" @click.stop="closingTime(item)">
													设为闭麦位
												</div>
											</div>
											<!-- 锁麦时点击end -->
											<div class="function_popover" v-if="
                        popoverShow == key1 &&
                        item.status == 0 &&
                        userRole != 3
                      " style="left: 90%">
												<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
													上麦
												</div>
												<div class="popover_item" @click.stop="embraceShowChange(item)">
													抱用户上麦
												</div>
												<div class="popover_item" @click="setLockPosition(item, 0)">
													锁麦
												</div>
												<div class="popover_item" @click.stop="closingTime(item)">
													设为闭麦位
												</div>
											</div>
											<div class="pk_gift">
												<img src="../../image/room/1.png" alt="" />
												<span style="margin-left: 2px">0</span>
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
						<!-- 麦克风end -->
						<!-- 进度条 -->
						<div class="pkProgress_title">
							<div class="pkProgress_icon" style="color: #e95558">红队</div>
							<div class="pkProgress_icon" style="color: #7c4ff0">蓝队</div>
						</div>
						<Progress :width="650" :progress="pkRedDutyCycle" :strokeWidth="14" :showInfo="true" :blueTeamValue="pkTopThreeData.blueTeamValue"
							:redTeamValue="pkTopThreeData.redTeamValue"></Progress>
						<!-- 进度条end -->
						<div class="pkProgress_title" v-if="pkStatus != 1">
							<div class="flex">
								<div class="flex-dir position_r pkProgress_titleBox" v-for="(item, key1) in pkTopThreeData.redTeamTopThreeList" :key="key1 + 'a'">
									<img src="../../image/room/24.png" alt="" class="pkRanking_icon" v-if="key1 == 2" />
									<img src="../../image/room/25.png" alt="" class="pkRanking_icon" v-if="key1 == 1" />
									<img src="../../image/room/26.png" alt="" class="pkRanking_icon" v-if="key1 == 0" />
									<img :src="item.userAvatar" alt="" class="pkRanking_head" />
									<div class="pkRanking_text">{{ item.redValueStr }}</div>
								</div>
							</div>
							<div class="flex">
								<div class="flex-dir position_r pkProgress_titleBox" v-for="(item, key3) in pkTopThreeData.blueTeamTopThreeList" :key="key3 + 'b'">
									<img src="../../image/room/24.png" alt="" class="pkRanking_icon" v-if="key3 == 2" />
									<img src="../../image/room/25.png" alt="" class="pkRanking_icon" v-if="key3 == 1" />
									<img src="../../image/room/26.png" alt="" class="pkRanking_icon" v-if="key3 == 0" />
									<img :src="item.userAvatar" alt="" class="pkRanking_head" />
									<div class="pkRanking_text">{{ item.blueValueStr }}</div>
								</div>
							</div>
						</div>
						<!-- 房内pk结算弹窗 -->
						<div class="chat_popup" v-if="pkFinishShow">
							<div class="settlement_popUp">
								<div class="settlement_title flex-dir">
									<img src="../../image/room/81.png" alt="" style="width: 160px" />
									<!-- <img src="../../image/room/82.png" alt="" />
                  <img src="../../image/room/83.png" alt="" /> -->
								</div>
								<!-- 人员 -->
								<div class="flex-aling">
									<div class="flex-dir settlement_item" v-for="(item, key0) in endUserDataList" :key="key0">
										<img :src="item.userAvatar" alt="" v-if="item.userAvatar" />
										<div class="font-12 color_fff" style="margin-bottom: 6px">
											{{ item.userName }}
										</div>
										<div class="font-10 padding_4">
											{{ `PK胜场：${item.pkWinner}` }}
										</div>
										<div class="font-10">
											{{ `PK胜率${item.pkWinnerRate}` }}
										</div>
									</div>
								</div>
							</div>
							<div class="settlement_close flex-content" @click="pkFinishShow = false">
								<!-- {{ `关闭(${closeCountDown}s)` }} -->
								{{ `关闭` }}
							</div>
						</div>
						<!-- PK开始动画 -->
						<div class="chat_popup" v-if="pkStartAnimationShow">
							<img src="../../image/popout/pkStart.png" alt="" class="pkStartAnimation_style" />
						</div>
					</div>
					<!-- 房内PK模式end -->
					<!-- 房房PK模式 -->
					<div v-if="roomDetail.resultCode == 3">
						<!-- pk房间 -->
						<div class="roomPk_box">
							<!-- 蓝方主持人 -->
							<div class="blue_side flex-aling" v-if="roomDetail.roomPkOutVO.blueHostId" @click="pkRoomSkip(roomDetail.roomPkOutVO, 1)">
								<img :src="roomDetail.roomPkOutVO.blueHostAvatar" alt="" class="side_head" />
								<div class="side_box">
									<div>
										<span>{{ roomDetail.roomPkOutVO.blueHostName }}</span>
										<span class="side_label">主持人</span>
									</div>
									<div class="padding_8">
										{{ roomDetail.roomPkOutVO.blueRoomName }}
									</div>
									<div class="side_icon flex-aling">
										<img src="../../static/150.png" alt="" />
										<span>{{
                      roomDetail.roomPkOutVO.blueHostGiftValueStr
                        ? roomDetail.roomPkOutVO.blueHostGiftValueStr
                        : 0
                    }}</span>
									</div>
								</div>
							</div>
							<div class="blue_side flex-aling" v-else @click.stop="comperepopoverShow = !comperepopoverShow">
								<img src="../../static/143.png" alt="" class="side_head" />
								<div class="side_box">
									<div>
										<span></span>
										<span class="side_label">主持人</span>
									</div>
									<div class="padding_8"></div>
									<div class="side_icon flex-aling">
										<img src="../../static/150.png" alt="" />
										<span>0</span>
									</div>
								</div>
								<div class="function_popover" style="left: 25%; top: 25%" v-if="
                  comperepopoverShow &&
                  userRole == 0 &&
                  whetherWheat != 1 &&
                  roomDetail.roomPkOutVO.blueRoomId == roomId
                ">
									<div class="popover_item" @click.stop="comperephoneChenge()">
										上麦
									</div>
								</div>
							</div>
							<!-- 蓝方主持人end -->
							<!-- 红方主持人 -->
							<div class="red_side flex-aling" v-if="roomDetail.roomPkOutVO.redHostId" @click="pkRoomSkip(roomDetail.roomPkOutVO, 2)">
								<img :src="roomDetail.roomPkOutVO.redHostAvatar" alt="" class="side_head" />
								<div class="side_box">
									<div>
										<span>{{ roomDetail.roomPkOutVO.redHostName }}</span>
										<span class="side_label">主持人</span>
									</div>
									<div class="padding_8">
										{{ roomDetail.roomPkOutVO.redRoomName }}
									</div>
									<div class="side_icon flex-aling">
										<img src="../../static/150.png" alt="" />
										<span>{{
                      roomDetail.roomPkOutVO.redHostGiftValueStr
                        ? roomDetail.roomPkOutVO.redHostGiftValueStr
                        : 0
                    }}</span>
									</div>
								</div>
							</div>
							<div class="red_side flex-aling" v-else @click.stop="comperepopoverShow = !comperepopoverShow">
								<img src="../../static/143.png" alt="" class="side_head" />
								<div class="side_box">
									<div>
										<span></span>
										<span class="side_label">主持人</span>
									</div>
									<div class="padding_8"></div>
									<div class="side_icon flex-aling">
										<img src="../../static/150.png" alt="" />
										<span>0</span>
									</div>
								</div>
								<div class="function_popover" style="left: 25%; top: 25%" v-if="
                  comperepopoverShow &&
                  userRole == 0 &&
                  whetherWheat != 1 &&
                  roomDetail.roomPkOutVO.redRoomId == roomId
                ">
									<div class="popover_item" @click.stop="comperephoneChenge()">
										上麦
									</div>
								</div>
							</div>
							<!-- 红方主持人end -->
							<img src="../../image/room/6.png" alt="" class="roomPk_icon" />
						</div>
						<!-- pk房间end -->
						<!-- pk房状态 -->
						<div class="pk_box" style="margin-top: 90px">
							<div class="pk_status pk_status0 flex-content" v-if="roomDetail.roomPkOutVO.pkStatus == 0" @click.stop="setDisinvite()">
								取消邀请
							</div>
							<div class="pk_status pk_status0 flex-content" v-if="
                roomDetail.roomPkOutVO.pkStatus == 1 &&
                roomDetail.roomPkOutVO.redHostId == userInfo.id
              " @click.stop="pkStatusChenge()">
								开始PK
							</div>
							<div class="pk_status pk_status0 flex-content" v-else-if="roomDetail.roomPkOutVO.pkStatus == 1">
								准备中...
							</div>
							<div class="pk_status pk_status2 flex-content" v-if="roomDetail.roomPkOutVO.pkStatus == 2">
								{{ `PK中 ${pkMinute}:${pkSecond}` }}
							</div>
							<div class="pk_status pk_status2 flex-content" v-if="roomDetail.roomPkOutVO.pkStatus == 3" @click.stop="pkEndChange(roomDetail.roomPkOutVO)">
								PK结束
							</div>
							<!-- <img src="../../image/room/6.png" alt="" class="pk_img" /> -->
						</div>

						<!-- 房房PK房状态end -->
						<!-- 进度条 -->
						<div class="pkProgress_title">
							<div class="pkProgress_icon" style="color: #e95558">红队</div>
							<div class="pkProgress_icon" style="color: #7c4ff0">蓝队</div>
						</div>
						<Progress :width="650" :progress="pkRedDutyCycle" :strokeWidth="14" :showInfo="true" :blueTeamValue="pkTopThreeData.blueTeamValue"
							:redTeamValue="pkTopThreeData.redTeamValue"></Progress>
						<!-- 进度条end -->
						<!-- 礼物前三排名 -->
						<div class="pkProgress_title">
							<!-- 红方前三 -->
							<div class="flex">
								<div class="flex-dir position_r pkProgress_titleBox" v-for="(
                    item, key0
                  ) in roomAtroomPkThreeData.redTeamTopThreeList" :key="key0">
									<img src="../../image/room/26.png" alt="" class="pkRanking_icon" v-if="key0 == 0" />
									<img src="../../image/room/25.png" alt="" class="pkRanking_icon" v-if="key0 == 1" />
									<img src="../../image/room/24.png" alt="" class="pkRanking_icon" v-if="key0 == 2" />
									<img :src="item.userAvatar" alt="" class="pkRanking_head" />
									<div class="pkRanking_text">{{ item.redValueStr }}</div>
								</div>
							</div>
							<!-- 蓝方前三 -->

							<div class="flex">
								<div class="flex-dir position_r pkProgress_titleBox" v-for="(
                    item, key1
                  ) in roomAtroomPkThreeData.blueTeamTopThreeList" :key="key1">
									<img src="../../image/room/26.png" alt="" class="pkRanking_icon" v-if="key1 == 0" />
									<img src="../../image/room/25.png" alt="" class="pkRanking_icon" v-if="key1 == 1" />
									<img src="../../image/room/24.png" alt="" class="pkRanking_icon" v-if="key1 == 2" />
									<img :src="item.userAvatar" alt="" class="pkRanking_head" />
									<div class="pkRanking_text">{{ item.blueValueStr }}</div>
								</div>
							</div>
						</div>
						<!-- 礼物前三排名end -->

						<!-- pk房间普通麦位 -->
						<div class="pk_bgBox flex-aling" style="background: transparent; width: 100%">
							<template>
								<div v-for="(item, key0) in roomDetail.normalPositions" :key="key0" style="position: relative">
									<div class="pk_person flex-dir" v-if="item.userId" @click.stop="wheatHomeShowChenge(item, 1)">
										<div class="pk_head flex-content">
											<img :src="item.userAvatar" alt="" class="head_img" />
											<svgaImg style="position: absolute; top: -15px; left: -15px;width:90px;" :value="item.photoFrame"
												v-if="item.photoFrame" :cid="'host'" :widthNum="'90px'" :heightNum="'90px'">
											</svgaImg>
											<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
											<img src="../../static/149.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 1 && item.userId" />
											<img src="../../static/236.png" alt="" class="bannedToPost_icon-tow" v-if="item.microphone == 0 && item.userId" />
										</div>
										<div class="font-12 padding_8">
											<span class="pk_index">{{ key0 + 1 }}</span>
											<span class="padding_r4">{{ item.userName }}</span>
										</div>
										<div class="pk_gift">
											<img src="../../image/room/1.png" alt="" />
											<span style="margin-left: 2px">{{
                        item.giftValueStr
                      }}</span>
										</div>
									</div>
									<!-- 麦上没人 -->
									<div class="pk_person flex-dir" v-else>
										<div v-if="item.status == 2">
											<img src="../../image/room/62.png" alt="" class="wheat_avatar" @click.stop="popoverShow = key0" />
											<div class="microphone_text" style="color: #fff; font-size: 8px; margin-bottom: 6px">
												麦位已锁
											</div>
										</div>
										<div class="pk_head" v-else @click.stop="popoverShow = key0">
											<div class="flex-content">
												<img src="../../static/143.png" alt="" class="head_img" />
												<!-- <img v-if="item.userIsClose == 1" src="../../image/room/18.png" class="head_icon" /> -->
											</div>
											<div class="font-12 padding_8">
												<span class="pk_index">{{ key0 + 1 }}</span>
												<span class="padding_r4">空闲麦位</span>
											</div>
										</div>
										<!-- 锁麦时点击 -->
										<div style="left: 90%" class="function_popover" v-if="
                      popoverShow == key0 &&
                      item.status == 2 &&
                      (userRole < 2 ||
                        userRole == 6 ||
                        roomDetail.userRole < 2)
                    ">
											<div class="popover_item" @click="setLockPosition(item, 1)">
												解锁
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
										<!-- 锁麦时点击end -->
										<div class="function_popover" v-if="
                      popoverShow == key0 && item.status == 0 && userRole != 3
                    " style="left: 90%">
											<div class="popover_item" v-if="whetherWheat != 1" @click.stop="upMicrophoneChenge(item)">
												上麦
											</div>
											<div class="popover_item" @click.stop="embraceShowChange(item)">
												抱用户上麦
											</div>
											<div class="popover_item" @click="setLockPosition(item, 0)">
												锁麦
											</div>
											<div class="popover_item" @click.stop="closingTime(item)">
												设为闭麦位
											</div>
										</div>
										<div class="pk_gift">
											<img src="../../image/room/1.png" alt="" />
											<span style="margin-left: 2px">0</span>
										</div>
									</div>
								</div>
							</template>
						</div>
						<!-- 房内pk结算弹窗 -->
						<div class="chat_popup" v-if="pkFinishShow">
							<div class="settlement_popUp">
								<div class="settlement_title flex-dir">
									<img src="../../image/room/81.png" alt="" style="width: 160px" />
									<!-- <img src="../../image/room/82.png" alt="" />
                  <img src="../../image/room/83.png" alt="" /> -->
								</div>
								<!-- 人员 -->
								<div class="flex-aling">
									<div class="flex-dir settlement_item" v-for="(item, key0) in endUserDataList" :key="key0">
										<img :src="item.userAvatar" alt="" v-if="item.userAvatar" />
										<div class="font-12 color_fff" style="margin-bottom: 6px">
											{{ item.userName }}
										</div>
										<div class="font-10 padding_4">
											{{ `PK胜场：${item.pkWinner}` }}
										</div>
										<div class="font-10">
											{{ `PK胜率${item.pkWinnerRate}` }}
										</div>
									</div>
								</div>
							</div>
							<div class="settlement_close flex-content" @click="pkFinishShow = false">
								<!-- {{ `关闭(${closeCountDown}s)` }} -->
								{{ `关闭` }}
							</div>
						</div>
						<!-- PK开始动画 -->
						<div class="chat_popup" v-if="pkStartAnimationShow">
							<img src="../../image/popout/pkStart.png" alt="" class="pkStartAnimation_style" />
						</div>
					</div>
					<!-- 房间PK模式end -->
					<div class="order_meal flex-dir" @click.stop="dotSingPopUp()" v-if="roomDetail.resultCode == 1">
						<img src="../../static/144.png" alt="" />
						<!-- <span>点唱</span> -->
					</div>
					<div class="order_meal flex-dir" @click.stop="dotDianPopUp()" v-if="roomDetail.resultCode == 0">
						<img src="../../static/to13.png" alt="" />
						<!-- <span>点唱</span> -->
					</div>
					<div class="microphone_feature flex">
						<div class="flex-aling" v-if="userGiveAwayType">
							<div>赠送:</div>
							<div class="giveAway_box flex-aling">
								<img src="../../image/room/57.png" alt="" class="giveAway_icon" @click="closeGiveChange()" />
								<img :src="
                  othersHometype == 2
                    ? theWheatData.avatar
                    : othersData.avatar
                " alt="" class="giveAway_head" />
								<div>
									{{
  othersHometype == 2
  ? theWheatData.nickname
  : othersData.nickname
                  }}
								</div>
							</div>
						</div>
						<!-- 麦上人员 选择赠送礼物 -->
						<div class="roomPerson_list" v-else>
							<div class="flex-dir" style="width: 60px" @click="allUserPitchChange()">
								<img src="../../static/150.png" style="width: 20px;height:20px;" alt="" />
								<div style="color: #FFF;font-size: 12px;margin-top:5px;" class="XS">送全麦</div>
							</div>
							<!-- 麦上用户 -->
							<div v-for="(item, index) in roomMicrophoneList" :key="index">
								<div class="roomPerson_item" v-if="index < 3 && !microphoneListType && item.userId" @click="userPitchChange(item, index)">
									<div class="roomPerson_img" :class="item.pitch ? 'roomPerson_imgPitch' : ''">
										<img :src="item.userAvatar" alt="" style="width: 100%; height: 100%; object-fit: cover" />
									</div>
									<img src="../../static/148.png" class="roomPerson_icon" v-if="item.idCard == 0" />
									<div class="roomPerson_pitch flex-content" v-if="item.order != 0">
										{{ item.order }}
									</div>
								</div>
								<div class="roomPerson_item" v-if="microphoneListType && item.userId" @click="userPitchChange(item, index)">
									<div class="roomPerson_img" :class="item.pitch ? 'roomPerson_imgPitch' : ''">
										<img :src="item.userAvatar" alt="" style="width: 100%; height: 100%; object-fit: cover" />
									</div>
									<img src="../../static/148.png" class="roomPerson_icon" v-if="item.idCard == 0" />
									<div class="roomPerson_pitch flex-content" v-if="item.order != 0">
										{{ item.order }}
									</div>
								</div>
							</div>
							<img src="../../image/room/56.png" alt="" v-if="roomMicrophoneList.length > 3" :style="{
                transform: microphoneListType ? 'rotate(180deg)' : '',
              }" @click="microphoneListType = !microphoneListType" />
						</div>
						<!-- 公会房间 -->
						<!-- 用户角色 0-房主 1-超级管理员 2-管理员 3-游客 4-麦上普通用户 5-主持人 -->
						<!-- 主持人权限 -->
						<div class="feature_box flex-aling" v-if="0">
							<img src="../../static/146.png" alt="" class="feature_icon" @click="shieldingSetChange()" />
							<img src="../../image/room/23.png" alt="" class="feature_icon" @click="roomFunctionShowChange()" />
							<div class="flex-content sendOrders_btn" @click="sendOrdersShow = true" v-if="
                (userRole == 6 || userRole == 0) && roomDetail.resultCode == 0
              ">
								<img src="../../image/room/21.png" alt="" />
								<span>派单</span>
							</div>
							<div class="flex-content closeMicrophone" v-if="userRole == 1 || userRole == 0" style="margin-right: 10px" @click="Chiengmai()">
								<img src="../../image/room/84.png" alt="" style="width: 20px" />
								<span>清麦</span>
							</div>
							<div class="flex-content sendOrders_btn" @click="overSendOrdersShow = true" v-if="roomDetail.resultCode == 0">
								<img src="../../image/room/21.png" alt="" />
								<span>截单</span>
							</div>
							<div v-if="whetherWheat == 1" class="flex-content downMicrophone" @click="userLeaveSeat()">
								<img src="../../image/room/22.png" alt="" />
								<span>下麦</span>
							</div>
							<div v-else-if="whetherWheat == 2" class="flex-content cancelBtn" @click="cancelWheatClearing()">
								<img src="../../image/room/22.png" alt="" />
								<span>取消</span>
							</div>
							<div v-else class="flex-content upMicrophone" @click="wheatRemindShowBtn">
								<img src="../../static/147.png" alt="" />
								<span>上麦</span>
							</div>
							<div class="flex-content closeMicrophone" @click="closeMicrophone(1)" v-if="microphoneType">
								<span>开麦</span>
							</div>
							<div class="flex-content closeMicrophone" @click="closeMicrophone(0)" v-else>
								<img src="../../image/room/20.png" alt="" />
								<span>闭麦</span>
							</div>
							<!-- 屏蔽设置弹窗 -->
							<div class="shielding_set" v-if="shieldingSetShow" style="top: 10px">
								<div class="shielding_list flex-aling" style="cursor: pointer;" v-for="(item, key0) in shieldingList" :key="key0" @click.stop="setRoomUserStateChange(item.state)">
									<img src="../../static/158.png" alt="" v-if="roomSetData[item.state] == 1" />
									<img src="../../static/159.png" alt="" v-else />
									<span>{{ item.text }}</span>
								</div>
							</div>
							<!-- 房间操作弹窗 -->
							<!-- 主持人 -->
							<div class="room_function" :style="
                userRole == 6
                  ? 'top:-350px;left:-210px'
                  : 'top:-300px;left:-210px'
              " v-if="roomFunctionShow">
								<div class="flex-dir roomFunction_item" v-for="(item, index) in functionList" :key="index" @click="modeSwitch(item.type)">
									<img :src="item.url" alt="" />
									{{ item.name }}
									<img src="../../static/107.png" alt="" v-if="
                    roomDetail.resultCode == item.type &&
                    roomDetail.resultCode < 5
                  " class="pitch_icon" />
								</div>
							</div>
						</div>
						<!-- 主持人权限end -->
						<!-- 公会房间房主权限 -->
						<div class="feature_box flex-aling" v-else-if="
              userRole == 0 || userRole == 1 || userRole == 6 || userRole == 2
            ">
							<img src="../../static/146.png" alt="" class="feature_icon" @click="shieldingSetChange()" />
							<img src="../../image/room/23.png" alt="" class="feature_icon" @click="roomFunctionShowChange(1)" />
							<div class="flex-content sendOrders_btn cursor" @click="sendOrdersShow = true" v-if="
                (userRole == 6 || userRole == 0) && roomDetail.resultCode == 0
              ">
								<img src="../../image/room/21.png" alt="" />
								<span>派单</span>
							</div>
							<div class="flex-content closeMicrophone cursor" v-if="userRole == 0 || userRole == 1" style="margin-right: 10px" @click="Chiengmai()">
								<img src="../../image/room/84.png" alt="" style="width: 20px" />
								<span>清麦</span>
							</div>
							<div class="flex-content sendOrders_btn cursor" @click="overSendOrdersShow = true" v-if="roomDetail.resultCode == 0" style="margin-right: 10px">
								<img src="../../image/room/21.png" alt="" />
								<span>截单</span>
							</div>
							<div v-if="whetherWheat == 1" class="flex-content downMicrophone cursor" @click="userLeaveSeat()">
								<img src="../../image/room/22.png" alt="" />
								<span>下麦</span>
							</div>
							<div v-else-if="whetherWheat == 2" class="flex-content cancelBtn cursor" @click="cancelWheatClearing()">
								<img src="../../image/room/22.png" alt="" />
								<span>取消</span>
							</div>
							<div v-else class="flex-content upMicrophone cursor" @click="wheatRemindShowBtn">
								<img src="../../static/147.png" alt="" />
								<span>上麦</span>
							</div>
							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(1)" v-if="microphoneType">
								<span>开麦</span>
							</div>
							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(0)" v-else>
								<img src="../../image/room/20.png" alt="" />
								<span>闭麦</span>
							</div>
							<!-- 房间操作弹窗 -->
							<div class="room_function" v-if="roomFunctionShow" :class="
                roomDetail.resultCode != 0 &&
                  userRole == 6 &&
                  (roomDetail.userRole == 1 || roomDetail.userRole == 0)
                  ? 'room_function room_functionNew'
                  : 'room_function'
              " :style="
  roomDetail.resultCode == 0
    ? (roomDetail.userRole == 1 || roomDetail.userRole == 0) &&
      userRole == 6
      ? 'top: -305px; left: -210px;'
      : roomDetail.userRole == 1 || roomDetail.userRole == 0
        ? 'top: -305px; left: -210px;'
        : userRole == 6 || roomDetail.userRole == 2
          ? 'top: -125px; left: -210px;'
          : ''
    : (roomDetail.userRole == 1 || roomDetail.userRole == 0) &&
      userRole == 6
      ? 'top: -105px; left: -210px;'
      : roomDetail.userRole == 1 || roomDetail.userRole == 0
        ? 'top: -305px; left: -210px;'
        : userRole == 6 && roomDetail.userRole == 2
          ? 'top: -305px; left: -210px;'
          : 'top: -225px; left: -210px;'
">
								<!-- userRole==0&&userInfo.id==roomDetail.hostVO.userId?'top: -305px; left: -210px; ':'' -->
								<!-- functionList -->
								<div v-for="(item, index) in roomDetail.resultCode == 0
        ? (roomDetail.userRole == 1 || roomDetail.userRole == 0) &&
          userRole == 6
          ? functionList
          : roomDetail.userRole == 1 || roomDetail.userRole == 0
            ? functionListHost
            : userRole == 6 || roomDetail.userRole == 2
              ? functionListOner
              : ''
        : (roomDetail.userRole == 1 || roomDetail.userRole == 0) &&
          userRole == 6
          ? functionListHostDian
          : roomDetail.userRole == 1 || roomDetail.userRole == 0
            ? functionListHost
            : userRole == 6 && roomDetail.userRole == 2
              ? functionDian
              : roomDetail.userRole == 2
                ? functionGuand
                : functionDian" :key="index" @click="associationHouseOperate(item.type)">
									<!-- userRole == 6 &&
              (roomDetail.userRole == 1 || roomDetail.userRole == 0)
                ? 'top: -225px; left: -210px; '
                : roomDetail.userRole == 1 || roomDetail.userRole == 0
                ? 'top: -305px; left: -210px; '
                : 'top: -225px; left: -210px; ' -->

									<!-- userRole == 6 &&
                (roomDetail.userRole == 1 || roomDetail.userRole == 0)
                ? functionListHost
                : roomDetail.userRole == 1 || roomDetail.userRole == 0
                ? functionListHost
                : functionList
              : userRole == 6 &&
                (roomDetail.userRole == 1 || roomDetail.userRole == 0)
              ? functionListHostDian
              : roomDetail.userRole == 1 || roomDetail.userRole == 0
              ? functionListHost
              : functionList" -->

									<div v-if="userInfo.id == roomDetail.hostVO.userId && item.type == 7" class="flex-dir roomFunction_item">
										<img :src="item.url" alt="" />
										{{ item.name }}
									</div>
									<div v-else-if="item.type == 13" class="flex-dir roomFunction_item">
										<img :src="item.url" alt="" />
										{{ roomDetail.isAutoLock == "1" ? '取消锁麦' : '自动锁麦' }}
									</div>
									<div v-else class="flex-dir roomFunction_item">
										<img :src="item.url" alt="" />
										{{ item.name }}
									</div>
								</div>
							</div>
							<!-- 屏蔽设置弹窗 -->
							<div class="shielding_set" v-if="shieldingSetShow">
								<div class="shielding_list flex-aling" style="cursor: pointer;" v-for="(item, key0) in shieldingList" :key="key0" @click.stop="setRoomUserStateChange(item.state)">
									<img src="../../static/158.png" alt="" v-if="roomSetData[item.state] == 1" />
									<img src="../../static/159.png" alt="" v-else />
									<span>{{ item.text }}</span>
								</div>
							</div>
						</div>
						<!-- 房主权限end -->
						<!-- 用户权限 -->
						<div class="feature_box flex-aling" v-else-if="roomDetail.userRole == 2 || roomDetail.userRole == 3">
							<img src="../../static/146.png" alt="" class="feature_icon" @click="shieldingSetShow = !shieldingSetShow" />
							<div v-if="whetherWheat == 1" class="flex-content downMicrophone cursor" @click="userLeaveSeat()">
								<img src="../../image/room/22.png" alt="" />
								<span>下麦</span>
							</div>
							<div v-else-if="whetherWheat == 2" class="flex-content cancelBtn cursor" @click="cancelWheatClearing()">
								<img src="../../image/room/22.png" alt="" />
								<span>取消</span>
							</div>
							<div v-else class="flex-content upMicrophone cursor" @click="wheatRemindShowBtn">
								<!--  -->
								<img src="../../static/147.png" alt="" />
								<span>上麦</span>
							</div>

							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(1)" v-if="microphoneType">
								<span>开麦</span>
							</div>
							<div class="flex-content closeMicrophone cursor" @click="closeMicrophone(0)" v-else>
								<img src="../../image/room/20.png" alt="" />
								<span>闭麦</span>
							</div>
							<!-- 屏蔽设置弹窗 -->
							<div class="shielding_set" v-if="shieldingSetShow">
								<div class="shielding_list flex-aling" style="cursor: pointer;" v-for="(item, key0) in shieldingList" :key="key0" @click.stop="setRoomUserStateChange(item.state)">
									<img src="../../static/158.png" alt="" v-if="roomSetData[item.state] == 1" />
									<img src="../../static/159.png" alt="" v-else />
									<span>{{ item.text }}</span>
								</div>
							</div>
						</div>
						<!-- 用户权限end -->
					</div>
				</div>
				<!-- 公会房间end -->
				<img src="../../static/manghe.png" alt="" class="manghe_style" v-if="blindBoxBtnShow == 'true'" @click.stop="blindBoxShow = true" />
				<!-- 礼物 -->
				<div class="gift_box">
					<div class="gift_tabBox">
						<div class="gift_tab">
							<div class="gift_tabItem XS" :class="giftType == key3 ? 'gift_pitchOn' : ''" v-for="(item, key3) in giftTab" :key="key3" @click="cutGiftType(key3)">
								{{ item }}
							</div>
						</div>
						<div @click="cutGiftType(4)" :class="giftType == 4 ? 'gift_pitchOn XS' : 'XS'" style="display: flex; align-items: center ">
							<img src="../../static/user8.png" style='margin-right:6px' />
							我的背包
						</div>
						<!-- <div class="gift_paging">1/5</div> -->
					</div>
					<!-- 礼物列表 -->
					<div class="gift_show_bar">
						<div @click.stop="scrollToPrevItem" class="left_arrow"></div>
						<div class="gift_list_box flex">
							<div id="gift_list" ref="giftListRef" style="flex:1;">
								<div ref="giftItemRef" class="gift_item XS" v-for="(item, key4) in giftList" :key="key4" @mouseenter="onShowTip($event, item)" @mouseleave="onHideTip"
									:class="`${giftItemType.id == item.id ? 'gift_pitch' : ''}`" @click="
  giftItemChenge(item, key4);
selectGiftItem(key4);
                  ">
									<div class="giftWealThName" v-if="giftType == 2">
										{{ item.wealthName }}
									</div>
									<div class="giftWealThName" v-if="giftType == 3">
										{{ item.isAllAnnouncement == 1 ? "全频" : "" }}
									</div>
									<img :src="item.giftPath" alt="" />
									<div v-if="giftType == 4" class="myGift_num">
										x{{ item.num }}
									</div>
									<div style="width: 100px" class="line1">
										{{ item.giftName }}
									</div>
								</div>
								<div v-if="!giftList || giftList.length == 0" style="width: 100px; height: 62px"></div>
							</div>
						</div>
						<div @click.stop="scrollToNextItem" class="right_arrow"></div>
					</div>

					<!-- 礼物列表 -->
					<div class="flex-aling gift_giving">
						<div class="flex-aling gift_balance XS" v-if="giftType != 4">
							<img src="../../static/user3.png" alt="" />
							<span style="color: #ebc92f">{{ myDiamondData }}</span>
							<span style="color: #cebbff; margin-left: 11px" @click.stop="walletTopupShow = true">充值></span>
						</div>
						<div v-else></div>
						<!-- 赠送 -->
						<div class="givingBox flex-aling">
							<div class="giving_num" @click="givingNumSelectShow = !givingNumSelectShow">
								{{ giveAwayNum }} <img src="../../static/62.png" alt="" />
							</div>
							<div class="giving_btn flex-content" @click="sendGift()">
								赠送
							</div>
							<!-- 赠送数量选择 -->
							<div class="givingNum_select" v-if="givingNumSelectShow">
								<div class="givingNum_item" v-for="(item, key0) in givingNumList" :key="key0" @click="giveAwayChange(item)">
									{{ item.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="chat_right">
				<!-- 厅排行榜 && 文字聊天 -->
				<roomRight ref="roomRight" :forbidSpeach="roomDetail.forbidSpeach" @checkUser="checkUser" :danmuSetShow="danmuSetShow"></roomRight>
			</div>
		</div>
	</div>
		<!-- 礼物悬浮框 -->
		<Tooltip ref="tooltip" :maxWidth="240">
			<div class="floating_frame" v-if="currentGift">
				<div class="flex">
					<img :src="currentGift.giftPath" alt="" class="floating_img" />
					<div>
						<div class="flex-aling" style="font-size:12px;">
							{{ currentGift.giftName }}
							<img src="../../static/61.png" alt="" class="padding_r4" />
							<span style="color: #ebc92f; font-size: 12px">{{currentGift.price}}</span>
						</div>
						<div style="font-size:12px;">{{ `魅力值 +${currentGift.price}` }}</div>
					</div>
				</div>

				<div style="color: #ebc92f;font-size:12px;">全频：单次赠出10个全频通知</div>
			</div>
			<div class="padding_8" style="color: #ebc92f" v-if="currentGift.isAllAnnouncement == 1">
				礼物出全频通知
			</div>
		</Tooltip>

		<!-- 聊天名片  -->
		<div class="othersHome1" v-if="othersHomeShowLiao" style="width: 300px">
			<el-tooltip class="item" effect="dark" content="举报用户" placement="bottom" v-if="userInfo.id != othersData.userId">
				<div class="othersHome_icon" @click.stop="userReportChange()"></div>
			</el-tooltip>

			<div class="othersHome_topBox">
				<!-- 个人信息 -->
				<div class="othersHome_top flex-dir">
					<div class="othersHome_head flex-content" @click.stop="placepage(othersData.userId)">
						<img :src="othersData.avatar" alt="" />
						<img src="../../static/vip.png" v-if='othersData.vipFlag==1'
							style="width: auto;height:13px;position: absolute;bottom:-5px;left:50%;transform: translateX(-50%);" />
					</div>
					<div style="font-size: 16px; margin-bottom: 3px;font-weight: 600;" :class="{'vipred':othersData.vipFlag==1}">
						{{ othersData.userName }}
					</div>
					<div style="font-size: 12px; color: #333; margin-bottom: 6px">
						ID：{{ othersData.userNo }}
					</div>
					<div style="font-size: 12px; height: 20px;white-space: pre-wrap;word-wrap: break-word;width: 95%;text-align: center;">
						{{ othersData.personalSignature }}
					</div>
				</div>
				<!-- 标签 -->
				<!-- <div
          class="flex othersHome_labelBox"
          :style="{ top: isBlackRoom == 2 ? '100px' : '' }"
        >
          <div class="othersHome_label">王者荣耀</div>
          <div class="othersHome_label">哈利波特手游</div>
          <div class="othersHome_label">点唱</div>
        </div> -->
			</div>
			<div class="othersHome_bottom flex_wrap">
				<div class="othersHome_bg"></div>
				<div class="flex" style="z-index: 1">
					<div class="othersHome_value1 flex">
						<!-- <img src="../../static/83.png" alt="" /> -->
						<div class="img"></div>
						<div style="margin-top: 5px">
							<div>财富</div>
							<div>{{ othersData.money }}</div>
						</div>
					</div>
					<div class="othersHome_value2 flex" style="margin: 0 7px">
						<div class="img"></div>
						<div style="margin-top: 5px">
							<div>大米</div>
							<div>{{ othersData.charisma }}</div>
						</div>
					</div>
					<div class="othersHome_value flex" @click.stop="skipMedalWall(othersData.userId)">
						<div class="img"></div>
						<div style="margin-top: 5px">
							<div>勋章墙</div>
							<div>{{ othersData.medalWallLength }}1个</div>
						</div>
					</div>
				</div>
				<!-- 下单等操作 -->
				<div class="others_operationBox flex_wrap" style="z-index: 1" v-if="userInfo.id != othersData.userId">
					<div class="others_operation flex-dir" @click.stop="placepage(othersData.userId)">
						<img src="../../image/room/32.png" alt="" class="others_operationIcon" />
						<div>下单</div>
					</div>
					<div class="others_operation flex-dir" @click.stop="userGiveAwayChange(1, othersData)">
						<img src="../../static/150.png" alt="" class="others_operationIcon" />
						<div>送礼物</div>
					</div>
					<div class="others_operation flex-dir" @click="privateLetter(othersData)">
						<img src="../../image/room/33.png" alt="" class="others_operationIcon" />
						<div>私聊</div>
					</div>
					<div v-if="othersData.followFlag == 0" class="others_operation flex-dir" @click="followUser()">
						<!-- othersData.followFlag == 0 -->
						<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
						<div>关注</div>
					</div>
					<div v-if="othersData.followFlag == 1" class="others_operation flex-dir" @click="stopFollowUser()">
						<!--othersData.followFlag == 1  -->
						<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
						<div>取消关注</div>
					</div>
					<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.userRole > 2" @click="setManagerAdd()">
						<img src="../../image/room/58.png" alt="" class="others_operationIcon" />
						<div>设置管理</div>
					</div>
					<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.muteStatus == 0" @click.stop="muteUserChange()">
						<img src="../../image/room/88.png" alt="" class="others_operationIcon" />
						<div>禁言中</div>
					</div>
					<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && othersData.muteStatus == 1" @click.stop="disableSendMsgInit()">
						<img src="../../image/room/85.png" alt="" class="others_operationIcon" />
						<div>禁言</div>
					</div>
					<div class="others_operation flex-dir" v-if="userRole < 3 && othersData.userRole > 2" @click.stop="kickOutShowChange()">
						<img src="../../image/room/86.png" alt="" class="others_operationIcon" />
						<div>踢出房间</div>
					</div>
					<div class="others_operation flex-dir" v-if="1" @click.stop="blackUserChange()">
						<img src="../../image/room/87.png" alt="" class="others_operationIcon" />
						<div>厅拉黑</div>
					</div>
					<div class="cut_offRule"></div>
					<div class="flex-content" style="width: 100%" v-if="userRole <= 1" @click="directEmbraceUser(othersData)">
						<div>抱TA上麦</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 麦上人员名片 -->
		<div class="othersHome" v-if="wheatHomeShow" style="top: 27%; left: 53%; width: 300px">
			<div class="othersHome_icon" @click.stop="userReportChange()" v-if="userInfo.id != theWheatData.id"></div>
			<div class="othersHome_topBox">
				<!-- 个人信息 -->
				<div class="othersHome_top flex-dir">
					<div class="othersHome_head flex-content" @click.stop="placepage(theWheatData.id)">
						<img :src="theWheatData.avatar" alt="" />
						<img src="../../static/vip.png" v-if='theWheatData.vipFlag==1'
							style="width:auto;height:13px;position: absolute;bottom:-5px;left:50%;transform: translateX(-50%);" />
					</div>
					<div style="font-size: 16px; margin-bottom: 3px;font-weight: 600;" :class="{'vipred':theWheatData.vipFlag==1}">
						{{ theWheatData.userName }}
					</div>
					<div style="font-size: 12px; color: #333; margin-bottom: 6px">
						ID：{{ theWheatData.userNo }}
						<!-- <img
              src="@/image/room/fz.png"
              alt=""
              style="width: 14px"
              @click="copyUserId(othersData.userNo)"
            /> -->
					</div>
					<div style="font-size: 12px; height: 20px;white-space: pre-wrap;word-wrap: break-word;width: 95%;text-align: center;">
						{{ theWheatData.personalSignature }}
					</div>
				</div>
			</div>
			<div class="othersHome_bottom flex_wrap">
				<div class="othersHome_bg"></div>
				<div class="flex" style="z-index: 1">
					<div class="flex" style="z-index: 1">
						<div class="othersHome_value1 flex">
							<div class="img"></div>
							<div style="margin-top: 5px">
								<div style="font-size: 10px">财富</div>
								<div>{{ theWheatData.money }}</div>
							</div>
						</div>
						<div class="othersHome_value2 flex" style="margin: 0 7px">
							<div class="img"></div>
							<div style="margin-top: 5px">
								<div style="font-size: 10px">大米</div>
								<div>{{ theWheatData.charisma }}</div>
							</div>
						</div>
						<div class="othersHome_value flex" @click.stop="skipMedalWall(theWheatData.id)">
							<div class="img"></div>
							<div style="margin-top: 5px">
								<div style="font-size: 10px">勋章墙</div>
								<div>{{ theWheatData.medalWallLength }}个</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 下单等操作 -->
				<div class="others_operationBox flex_wrap" style="z-index: 1" v-if="userInfo.id != theWheatData.id">
					<div class="others_operation flex-dir" @click.stop="placepage(theWheatData.id)">
						<img src="../../image/room/32.png" alt="" class="others_operationIcon" />
						<div>下单</div>
					</div>
					<div class="others_operation flex-dir" @click.stop="userGiveAwayChange(2)">
						<img src="../../static/150.png" alt="" class="others_operationIcon" />
						<div>送礼物</div>
					</div>
					<div class="others_operation flex-dir" @click.stop="privateLetter(theWheatData)">
						<img src="../../image/room/33.png" alt="" class="others_operationIcon" />
						<div>私聊</div>
					</div>
					<div class="others_operation flex-dir" v-if="theWheatData.followFlag" @click="followUser()">
						<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
						<div>关注</div>
					</div>
					<div class="others_operation flex-dir" v-if="theWheatData.followFlag" @click="stopFollowUser()">
						<img src="../../image/room/34.png" alt="" class="others_operationIcon" />
						<div>取消关注</div>
					</div>
					<div class="others_operation flex-dir" v-if="(userRole < 2 || userRole == 6) && theWheatData.userRole > 2" @click="setManagerAdd()">
						<img src="../../image/room/58.png" alt="" class="others_operationIcon" />
						<div>设置管理</div>
					</div>
					<div class="others_operation flex-dir" v-if="
            (userRole < 2 || userRole == 6) && theWheatData.muteStatus == 0
          " @click.stop="muteUserChange()">
						<img src="../../image/room/88.png" alt="" class="others_operationIcon" />
						<div>禁言中</div>
					</div>
					<div class="others_operation flex-dir" v-if="
            (userRole < 2 || userRole == 6) && theWheatData.muteStatus == 1
          " @click.stop="disableSendMsgInit()">
						<img src="../../image/room/85.png" alt="" class="others_operationIcon" />
						<div>禁言</div>
					</div>
					<div class="others_operation flex-dir" v-if="userRole < 2 && theWheatData.userRole > 2" @click.stop="kickOutShowChange()">
						<img src="../../image/room/86.png" alt="" class="others_operationIcon" />
						<div>踢出房间</div>
					</div>
					<div class="others_operation flex-dir" v-if="userRole < 2 && theWheatData.userRole > 2" @click.stop="blackUserChange()">
						<img src="../../image/room/87.png" alt="" class="others_operationIcon" />
						<div>厅拉黑</div>
					</div>
					<div class="cut_offRule"></div>
					<div class="flex-content" style="width: 100%; justify-content: space-around" v-if="userRole <= 1">
						<div @click.stop="userLeaveSeat(1)">抱TA下麦</div>
						<div @click.stop="blankingOther(pitchOnWheat)">{{ pitchOnWheat.positionIsClose == 1 ? '开麦' : '闭麦' }}</div>
						<div @click.stop="lockWheatChange()">锁麦位</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 财富等级升级弹窗 -->
		<div class="upgrae_popup flex-dir" v-if="upgraeShow">
			<img :src="upgraeData.logo" alt="" style="width: 56px; hegiht: 56px; margin: 25px 0 15px 0" />
			<div style="color: rgba(65, 119, 221, 1)">
				{{ upgraeData.nickname }}
				<span style="font-size: 20px; color: #b7643d">升级{{ upgraeData.levelName }}</span>
			</div>
		</div>
		<!-- 顶部全服广播 -->
		<div class="broadcast_top flex-aling" v-if="broadcastTopShow && roomSetData.allGfitBanner != 1">
			<div class="flex-aling" @click="skipRoom({id: giftRoomInfo.roomId})">
				<div class="broadcast_content">
					<div class="broadcast_cartoon">
						<img :src="wholeGift.sendUserAvatar" alt="" class="broadcast_head" />
						<span style="color: #f4e28e; margin-left: 6px">{{
              wholeGift.sendUserName
            }}</span>
						<span style="margin: 4px">在</span>
						<span style="color: #f4e28e">{{ wholeGift.roomName }}</span>
						<span style="margin: 4px">送给</span>
						<span style="color: #f4e28e">{{ wholeGift.toUserName }}</span>
					</div>
				</div>
				<img :src="wholeGift.giftLogo" alt="" style="width: 60px; height: 60px; position: relative; top: -10px" />
				<span style="color: #f4e28e; font-size: 28px; font-weight: bolder">x{{ wholeGift.giftNum }}</span>
			</div>
		</div>
		<!-- 进入房间效果 -->
		<div class="enterRoomStyle"></div>

		<!-- 全语聊房广播 -->
		<div class="popUp_box" v-if="guangboAll">
			<div class="wheat_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">全语聊房广播</div>
					<img src="../../static/quit.png" alt="" @click="guangboAll = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div class="guangboAllTitle">您当前财富等级：游侠</div>
					<div style="color: rgba(65, 119, 221, 1); font-size: 12px">
						勋爵以上
					</div>

					<div class="guangboAllBtn" @click="wealthThatShow = true">
						了解财富等级特权
					</div>
				</div>
			</div>
		</div>
		<!-- 上麦邀请弹窗 -->
		<div class="popUp_box" v-if="wheatInviteShow">
			<div class="wheat_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="wheatInviteShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img :src="userInfo.avatar" alt="" class="wheatRemind_img" />
					<div style="margin-top: 5px">是否同意上麦</div>
					<div class="flex-aling">
						<div class="wheatRemind_apply btn2 flex-content cursor" @click="inviteDispose(2)">
							拒绝
						</div>
						<div class="wheatRemind_apply flex-content cursor" @click="inviteDispose(1)">
							同意
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 上麦提醒 -->
		<div class="popUp_box" v-if="wheatRemindShow">
			<div class="wheat_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="wheatRemindShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img :src="userInfo.avatar" alt="" class="wheatRemind_img" />
					<div class="wheatRemind_apply flex-content cursor" @click="applyRemind()">
						申请上麦
					</div>
				</div>
			</div>
		</div>
		<!-- 退出房间提醒 -->
		<div class="popUp_box" v-if="outRoomShow">
			<div class="wheat_remind" style="height: 320px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="outRoomShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin-top: 54px" />
					<div style="margin-top: 15px">确定离开退出房间吗？</div>
					<div class="flex">
						<div class="wheatRemind_apply btn2 flex-content XS" @click="outRoomShow = false">
							再听听
						</div>
						<div class="wheatRemind_apply flex-content XS" @click="skipHome()">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 提前结束PK提醒 -->
		<div class="popUp_box" v-if="pkAdvanceOverShow">
			<div class="wheat_remind" style="height: 320px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="pkAdvanceOverShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin-top: 54px" />
					<div style="margin-top: 15px">确定提前结束PK吗？</div>
					<div class="flex">
						<div class="wheatRemind_apply flex-content btn2" @click="pkAdvanceOverShow = false">
							取消
						</div>
						<div class="wheatRemind_apply flex-content" @click="affirmOver()">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 是否跳转房间提醒 -->
		<div class="popUp_box" v-if="jumpPopoverShow">
			<div class="wheat_remind" style="height: 320px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="jumpPopoverShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin-top: 54px" />
					<div style="margin-top: 15px">
						是否跳转到房间 {{ adverseData.name }}
					</div>
					<div class="flex">
						<div class="wheatRemind_apply flex-content btn2" @click="jumpPopoverShow = false">
							取消
						</div>
						<div class="wheatRemind_apply flex-content" @click="affirmSkipRoom()">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 房间踢人提醒 -->
		<div class="popUp_box" v-if="kickOutShow">
			<div class="wheat_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="kickOutShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/128.png" alt="" style="margin-top: 40px; margin-bottom: 12px" />
					<div>确定要将该用户移出聊天室吗？仅针对本次直播有效</div>
					<div class="flex">
						<div class="wheatRemind_apply flex-content btn2" @click="kickOutShow = false">
							再想想
						</div>
						<div class="wheatRemind_apply flex-content" @click="kickOutRoom()">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 神豪位规则弹窗 -->
		<div class="popUp_box" v-if="deityRichRuleShow">
			<div class="deityRich_rule">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content" style="background: transparent">
					<div class="font-14"></div>
					<img src="../../static/quit.png" alt="" @click="deityRichRuleShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div class="flex-dir" v-if="roomDetail.roomVoiceVO.existGodRich">
						<div class="deityRich_head flex-content">
							<img src="../../image/room/7.png" alt="" class="deityRich_boximg" />
							<img :src="roomDetail.roomVoiceVO.godRichAvatar" alt="" class="deityRich_headImg" v-if="roomDetail.roomVoiceVO.godRichAvatar" />
						</div>
						<div class="deityRich_div">
							当前神豪
							<span style="color: #77d769">{{
                roomDetail.roomVoiceVO.godRichName
              }}</span>
						</div>
						<div class="deityRich_div">
							本房间一次性消费大于
							<span style="color: #ffdba3">{{ divineData.god_value }}元宝</span>
						</div>
					</div>
					<div v-else class="flex-dir">
						<div class="deityRich_head flex-content">
							<img src="../../image/room/7.png" alt="" class="deityRich_boximg" />
						</div>
						<div class="">你暂时没有资格享用神豪座位哦！</div>
					</div>
					<div class="deityRich_div">即可夺取神豪座位使用权！</div>
					<div class="deityRich_ruleTitle"></div>
					<div class="flex-dir font-12 deityRich_text">
						<div>
							1.本房间单次送礼达到{{
                divineData.god_value || 0
              }}元宝可享用神豪座位。
						</div>
						<div>2.同时有多人达到送礼标准时，送礼更多的人可享用神豪座位。</div>
						<div class="text-center">
							3.神豪座位采用倒计时形式，{{
                divineData.god_time || 0
              }}分钟内可以由其他送礼更高者顶替；如无人顶替，{{
  divineData.god_time || 0
}}分钟后神豪座位自动清空。
						</div>
						<div class="text-center">
							4.如已入座神豪位,当前还有剩余时间，再次在本房间送礼物单次送礼价值大于之前,则重新开始{{
                divineData.god_time || 0
              }}分钟倒计时。
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 点唱弹窗 -->
		<div class="popUp_box" v-if="someSingingShow">
			<div class="deityRich_rule">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14 flex-aling">
						选择演唱者
						<img src="../../static/164.png" alt="" class="margin_r4" />
					</div>
					<img src="../../static/quit.png" alt="" @click="someSingingShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir someSing">
					<div class="someSing_top flex-aling">
						<div class="flex-dir" @click="allPitchChange()">
							<img src="../../static/150.png" style="width: 20px;height:20px;" alt="" />
							<div style="color: #fff;font-size: 12px;margin-top:5px;" class="XS">送全麦</div>
						</div>
						<div v-for="(item, index) in concertUserLisr" :key="index">
							<div class="someSing_bg" :class="item.pitch ? 'someSing_headPitch' : ''" v-if="item.userId && item.userId != userInfo.id" @click="someSingChange(item)">
								<img :src="item.userAvatar" style="object-fit: cover" alt="" class="someSing_topHead" />
								<div class="someSing_userPitch flex-content">
									{{ item.order }}
								</div>
							</div>
						</div>
					</div>
					<!-- 点唱内容 -->
					<div class="someSing_box flex-aling">
						<div class="someSing_item" v-if="jukeboxCost[1]" :class="jukeType == 1 ? 'someSing_pitch' : ''" @click="jukeChange(1)">
							<div class="font-17">试音</div>
							<div class="font-12">试听一下Ta的声音</div>
							<div class="someSing_num">
								<img src="../../static/61.png" alt="" />{{
                  jukeboxCost[1].configValue
                }}/人
							</div>
						</div>
						<div class="someSing_item someSing_item2" v-if="jukeboxCost[5]" :class="jukeType == 5 ? 'someSing_pitch' : ''" @click="jukeChange(5)">
							<div class="font-17">点歌</div>
							<div class="font-12">指定Ta为您演唱</div>
							<div class="someSing_num">
								<img src="../../static/61.png" alt="" />
								{{ jukeboxCost[5].configValue }}/人
							</div>
						</div>
						<div class="someSing_item someSing_item3" v-if="jukeboxCost[4]" :class="jukeType == 4 ? 'someSing_pitch' : ''" @click="jukeChange(4)">
							<div class="font-17">包场</div>
							<div class="font-12">
								让所有麦上歌手 <br />
								为您演唱
							</div>
							<div class="someSing_num">
								<img src="../../static/61.png" alt="" />
								{{ jukeboxCost[4].configValue }}/人
							</div>
						</div>
					</div>
					<div class="someSing_text" v-if="jukeboxCost[1]">
						点唱/包场 每首歌需向主持人支付{{
              jukeboxCost[2].configValue
            }}元宝场地费~
					</div>
					<div class="someSing_bottom">
						<!-- 赠送礼物弹窗 -->
						<div class="give_gifts" v-if="giveGiftsShow" :style="{ top: jukeType == 0 ? '-80px' : '-140px' }">
							<div class="font-12" style="margin-bottom: 8px">赠送礼物</div>
							<div class="flex">
								<img :src="jukeboxCost[jukeType].giftImg" alt="" class="give_giftsImg" />
								<div class="flex-between">
									<div>
										{{
                    `${jukeboxCost[jukeType].giftName}x${jukeboxPitchNum}`
                    }}
									</div>
									<div>
										<span>{{
                      jukeboxCost[jukeType].configValue * jukeboxPitchNum
                    }}</span>
										<img src="../../static/61.png" alt="" />
									</div>
								</div>
							</div>
							<div class="flex" v-if="jukeType == 2 || jukeType == 3" style="margin-top: 10px">
								<img :src="jukeboxCost[1].giftImg" alt="" class="give_giftsImg" />
								<div class="flex-between">
									<div>
										{{ `${jukeboxCost[1].configName}x${jukeboxPitchNum}` }}
									</div>
									<div>
										<span>{{
                      jukeboxCost[1].configValue * jukeboxPitchNum
                    }}</span>
										<img src="../../static/61.png" alt="" />
									</div>
								</div>
							</div>
						</div>
						<!-- 赠送礼物弹窗end -->
						<div class="font-14" style="color: #333">
							已选择
							<span style="color: #f68229"> {{ jukeboxPitchNum }} </span> 人
						</div>
						<div class="flex-aling someSing_bottomFlex">
							<div class="flex-aling">
								<img src="../../static/61.png" alt="" />
								<div class="font-24" style="color: #f68229; margin-left: 11px">
									{{ jukeboxSum }}
								</div>

								<img src="../../static/41.png" alt="" style="margin-left: 11px" @click="giveGiftsShow = !giveGiftsShow" />
								<div class="someSing_topUp">
									<span class="padding_r8">{{ myDiamondData }}</span>
									<img src="../../static/61.png" alt="" />
									<div class="someSing_topUpBtn flex-content XS" @click.stop="walletTopupShow = true">
										充值>
									</div>
								</div>
							</div>
							<div class="someSing_confirm flex-content" @click="confirmOrder()">
								<img src="../../image/room/5.png" alt="" class="margin_r4" />
								确认下单
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 相亲相爱规则弹窗 -->
		<div class="popUp_box" v-if="loveEachPopupShow">
			<div class="loveEachPopup flex-dir">
				<div style="position: absolute;bottom:0;left:0;width:522px;height:429px;background:#FFF;border-radius: 10px;"></div>
				<img type="image" src="../../image/room/2929.png" alt="" style="position: absolute;bottom:0;left:0;width:522px;height:478px;" />
				<div style="position: absolute;bottom:0;left:0;width:522px;height:429px;">
					<div class="love_quit XS" @click="loveEachPopupShow = false">×</div>
					<!-- <img
					  src="../../static/quit.png"
					  alt=""
					  class="love_quit"
					  @click="loveEachPopupShow = false"
					/> -->
					<img style="margin: 100px auto 20px;display: block;" src="../../image/room/30.png" alt="" />
					<div v-html="loveExplain" style="padding:20px 40px;"></div>
					
					<!-- <svgaImg style="position: absolute; top: -200px; left: -30px; z-index: 10" :value="'http://ser.t9cp.com/static/svga/6816.svga'" :cid="'host'" :widthNum="'600px'"
								:heightNum="'600px'" :loopsNum="1"></svgaImg> -->
				</div>

				
			</div>
		</div>

		<!-- pk房间列表 -->
		<div class="popUp_box" v-if="roomListBoxShow">
			<div class="wheat_remind" style="height: 598px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">房间列表</div>
					<img src="../../static/quit.png" alt="" @click="roomListBoxShow = false" />
				</div>
				<!-- 头部end -->
				<el-input type="text" placeholder="搜索房间名称/ID" v-model="searchInput" class="roomList_input" style="margin: 20px 40px" @change="getPkRoomList()"></el-input>
				<div class="roomList_box">
					<div class="roomList_item" v-for="(item, index) in pkRoomList" :key="index">
						<img src="../../static/15.png" alt="" class="roomList_itemIcon" />
						<div class="flex">
							<img :src="item.logo" alt="" class="roomList_itemHead" />
							<div>
								<div>{{ item.name }}</div>
								<div style="padding: 6px 0">
									<img :src="item.hostAvatar" alt="" class="roomList_person1" v-if="item.hostAvatar" />
									<img :src="item.userAvatarList[0]" alt="" class="roomList_person2" v-if="item.userAvatarList[0]" style="margin-left: 4px" />
									<img :src="item.userAvatarList[1]" alt="" class="roomList_person2" v-if="item.userAvatarList[1]" />
									<img :src="item.userAvatarList[2]" alt="" class="roomList_person2" v-if="item.userAvatarList[2]" />
									<span style="margin-left: 6px">{{ item.roomNum }}人</span>
								</div>
								<div class="font-12" style="color: #6a6a94">
									ID {{ item.roomNo }}
								</div>
							</div>
						</div>
						<div class="roomList_itemBtn flex-content" @click.stop="launchPkInvite(item)">
							邀请PK
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- pk邀请弹窗 -->
		<div class="pk_invitation" v-if="pkInvitationShow">
			<div class="invitation_box">
				<div class="roomList_item" style="width: 362px">
					<img src="../../static/15.png" alt="" class="roomList_itemIcon" />
					<div class="flex">
						<img :src="pkInvitationData.logo" alt="" class="roomList_itemHead" />
						<div>
							<div>{{ pkInvitationData.name }}</div>
							<div>
								<img v-if="pkInvitationData.hostAvatar" :src="pkInvitationData.hostAvatar" alt="" class="roomList_person1" />
								<template v-if="pkInvitationData.newAvatarList.length">
									<img v-for="(item, index) in pkInvitationData.newAvatarList" :key="index" :src="item" alt="" class="roomList_person2"
										style="margin-left: 8px" />
								</template>

								<!-- <img
                  v-if="pkInvitationData.userAvatarList[2]"
                  :src="pkInvitationData.userAvatarList[2]"
                  alt=""
                  class="roomList_person2"
                /> -->
								<span style="margin-left: 8px">{{ pkInvitationData.roomNum }}人</span>
							</div>
							<div class="font-12" style="color: #6a6a94">
								ID {{ pkInvitationData.roomNo }}
							</div>
						</div>
					</div>
				</div>
				<div class="flex-spacebetween">
					<div class="invitation_btn flex-content" @click="pkInviteStateChange(0, pkInvitationData)">
						拒绝
					</div>
					<div class="invitation_btn flex-content" style="background: rgba(255, 221, 0, 1); color: #333" @click="pkInviteStateChange(1, pkInvitationData)">
						同意
					</div>
				</div>
			</div>
		</div>

		<!-- 开始PK设置PK时长弹窗 -->
		<div class="popUp_box" v-if="setPkTimeShow">
			<div class="editRoom" style="width: 460px; height: 250px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14 flex-aling">PK设置</div>
					<img src="../../static/quit.png" alt="" @click="setPkTimeShow = false" />
				</div>
				<!-- 头部end -->
				<div style="width: 320px; margin: 20px auto">
					<div style="padding: 6px 0">本轮PK时长</div>
					<el-select v-model="pkTimeValue" placeholder="请选择PK时长" style="width: 320px" calss="textInput">
						<el-option v-for="item in durationList" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</div>
				<div class="editRoom_btn flex-content" style="margin-top: 40px" @click.stop="setStartPK()">
					确认开启
				</div>
			</div>
		</div>
		<!-- 编辑房间信息 -->
		<div class="popUp_box" v-if="editRoomShow">
			<div class="editRoom">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14 flex-aling">编辑房间信息</div>
					<img src="../../static/quit.png" alt="" @click="editRoomShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-aling" style="margin: 20px 20px 0 20px">
					<div class="establishTab_item flex-content" :class="labelType == 1 ? 'establishTab_puth' : ''" @click="labelChange(1)">
						游戏开黑
					</div>
					<div class="establishTab_item flex-content" :class="labelType == 2 ? 'establishTab_puth' : ''" @click="labelChange(2)">
						娱乐
					</div>
				</div>
				<!-- 二级分类标签 -->
				<div class="skill_box" style="margin: 0 20px">
					<div class="skill_item flex-dir" v-for="(item, index) in labelType == 1
          ? gameLabels
          : amusementLabels" :key="index" :class="twolabel.id == item.id ? 'skill_pitch' : ''" @click="secondLevelChange(item)">
						<img :src="item.logo" alt="" class="label_img" />
						<div class="itemText">{{ item.name }}</div>
					</div>
				</div>
				<!-- 二级分类标签 -->
				<div class="editRoom_box">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="房间ID:" class="color_fff" style="color: #333">
							<span style="color:333">123456789</span>
						</el-form-item>
						<el-form-item label="房间封面:" class="color_fff"> </el-form-item>
						<el-form-item label="房间类型:" class="color_fff">
							<el-input v-model="form.name" placeholder="请输入类型" style="color:#333"></el-input>
						</el-form-item>
						<el-form-item label="房间类别:" class="color_fff" style="color: #333">
							<el-input v-model="form.name" placeholder="请输入房间类别"></el-input>
						</el-form-item>
						<el-form-item label="房间名称:" class="color_fff" style="color: #333">
							<el-input v-model="form.name" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="房间背景:" class="color_fff" style="color: #333">
							<!-- <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
							<!-- <el-dialog :visible.sync="dialogVisible"> -->
							<el-dialog>
								<!-- <img width="100%" :src="dialogImageUrl" alt="" /> -->
							</el-dialog>
						</el-form-item>
					</el-form>
				</div>
				<div class="editRoom_btn flex-content">保存</div>
			</div>
		</div>
		<!-- 公会房间信息编辑 -->
		<div class="popUp_box" v-if="societyEditShow" style="z-index: 9999999">
			<div class="editRoom">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">编辑房间信息</div>
					<img src="../../static/quit.png" alt="" @click="societyEditShow = false" />
				</div>
				<!-- 头部end -->
				<div class="editRoom_box">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="房间ID:" class="color_fff" style="color: #333">
							<span style="color: #333">{{ roomInformation.roomNo }}</span>
						</el-form-item>
						<el-form-item label="房间封面:" class="color_fff" style="color: #333">
							<img :src="roomInformation.logo" alt="" style="width: 80px; height: 80px; border-radius: 4px" />
						</el-form-item>
						<el-form-item label="房间类型:" class="color_fff" style="color: #333">
							<span style="color: #333">{{ roomInformation.topic }}</span>
						</el-form-item>
						<!-- <el-form-item label="房间类别:" class="color_fff">
              <el-input
                v-model="form.name"
                placeholder="请输入房间类别"
                style="width: 200px"
                :disabled="true"
              ></el-input>
            </el-form-item> -->
						<el-form-item label="房间名称:" class="color_fff" style="color: #333">
							<span style="color: #333">{{ roomInformation.name }}</span>
						</el-form-item>
						<el-form-item label="房间背景:" class="color_fff" style="color: #333">
							<div style="position: relative; width: 220px; height: 160px" v-if="roomBack" @click.stop="backgroundImageShow = true">
								<img :src="roomBack" alt="" style="width: 100%; height: 100%; border-radius: 4px" />
								<img src="../../image/my/19.png" alt="" style="position: absolute; right: 6px; top: 6px" @click.stop="bgImgChangeDel()" />
							</div>
							<img v-else src="../../static/183.png" alt="" @click.stop="backgroundImageShow = true" />

							<!-- <el-upload
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                :limit="1"
                :show-file-list="false"
                list-type="picture-card"
                :on-success="streamImgSuccess"
                v-else
              >
                <img src="../../static/183.png" alt="" />
              </el-upload> -->
						</el-form-item>
					</el-form>
				</div>
				<div class="editRoom_btn flex-content cursor" @click.stop="saveRoomChange()">
					保存
				</div>
			</div>
		</div>
		<!-- 背景图选择弹窗 -->
		<div class="popUp_box" v-if="backgroundImageShow" style="z-index: 99999999">
			<div class="bgImgBox">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">背景图选择</div>
					<img src="../../static/quit.png" alt="" @click="backgroundImageShow = false" />
				</div>
				<!-- 头部end -->
				<div class="bgImg_scroll">
					<div v-for="(item, index) in bgImgList" :key="index" class="bgImg_item" @dblclick.stop="selectBackGround(item)">
						<img :src="item.imageurl" style="width: 100%; height: 100%" />
					</div>
				</div>
			</div>
		</div>
		<!-- 编辑公告弹窗 -->
		<div class="popUp_box" v-if="editBillboardShow">
			<div class="editRoom">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14 flex-aling">编辑公告</div>
					<img src="../../static/quit.png" alt="" @click="
  editBillboardShow = false;
afficheValue = roomDetail.announcement;
          " />
				</div>
				<!-- 头部end -->
				<div class="edit_billboard flex-dir">
					<el-input type="textarea" placeholder="请输入内容" v-model="afficheValue" maxlength="200" :rows="4" resize="none"></el-input>
					<div class="billboard_btn flex-content" @click="saveAffiche()">
						保存
					</div>
				</div>
			</div>
		</div>
		<!-- 房间上锁弹窗 -->
		<div class="popUp_box" v-if="roomLockShow">
			<div class="wheat_remind" style="height: 310px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">
						{{ roomDetail.isLock == 0 ? "房间上锁" : " 房间解锁" }}
					</div>
					<img src="../../static/quit.png" alt="" @click="roomLockShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div class="flex-content" style="color: #b9bbc5; width: 100%; margin-top: 30px">
						请输入四位数字密码
					</div>
					<!-- 输入密码 -->
					<template>
						<div class="salary-container">
							<div class="enter_box">
								<input class="int" v-model="passwordName" maxlength="4" type="number" autofocus />
								<div class="enter" v-for="(item, index) in password" :key="index">
									<!-- 跳跃的指示符 -->
									<div :class="{ tips: place == index }"></div>
									{{ item }}
								</div>
							</div>
						</div>
					</template>
					<div class="flex">
						<div class="wheatRemind_apply flex-content btn2" @click="roomLockShow = false">
							取消
						</div>
						<div class="wheatRemind_apply flex-content" @click="setPassword()">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 清屏提醒弹窗 -->
		<div class="popUp_box" v-if="clsRemindShow">
			<div class="wheat_remind" style="height: 295px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="clsRemindShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin: 30px 0 20px 0" />
					<div style="color: #333">
						清除当前聊天室的聊天内容，清除后不可恢复，确定清屏吗？
					</div>
					<div class="flex">
						<div class="wheatRemind_apply btn2 flex-content" @click="clsRemindShow = false">
							取消
						</div>
						<div class="wheatRemind_apply flex-content" @click="roomCls()">
							确定清屏
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关闭房间弹窗 -->
		<div class="popUp_box" v-if="closeRoomShow">
			<div class="wheat_remind" style="height: 295px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="closeRoomShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin: 30px 0 20px 0" />
					<div style="color: #333">确定要结束本次直播吗？</div>
					<div class="flex">
						<div class="wheatRemind_apply btn2 flex-content" @click="closeRoomShow = false">
							再想想
						</div>
						<div class="wheatRemind_apply flex-content" @click="closeRoomChange()">
							确认
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 直播反馈弹窗 -->
		<div class="popUp_box" v-if="isFankui">
			<div class="wheat_remind" style="height: 446px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">反馈</div>
					<img src="../../static/quit.png" alt="" @click="isFankui = false" />
				</div>
				<div style="padding: 40px 60px">
					<div class="fankuiType" :style="index == tabIndex ? 'background:#ffdd00' : ''" v-for="(item, index) in fankuiArr" :key="index"
						@click="fankuiBtnIndex(index, item)">
						{{ item.title }}
					</div>
					<div>问题描述(选填)</div>
					<div>
						<el-input style="width: 402px; height: 89px; margin-top: 16px" type="textarea" placeholder="填写详细说明" v-model="fankuiValue" maxlength="200"
							resize="none"></el-input>
					</div>
					<div class="fankuiBtn" @click="fankuisubmit">提交</div>
				</div>
			</div>
		</div>
		<!-- 房间管理弹窗 -->
		<div class="popUp_box" v-if="houseOwnerOperate == 4">
			<div class="wheat_remind" style="height: 346px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">房间管理</div>
					<img src="../../static/quit.png" alt="" @click="houseOwnerOperate = '-1'" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div class="room_titleBox flex">
						<img class="room_titleBoxHead" :src="roomInformation.logo" alt="" />
						<div>
							<div class="flex-aling">
								<div class="titleBoxIcon" style="color: #fff">
									{{ roomInformation.topic }}
								</div>
								<div class="font-14 padding_r10 titleBoxName" style="color: #fff">
									{{ roomInformation.name }}
								</div>
								<img src="../../image/room/52.png" alt="" @click="editRoomChange()" />
							</div>
							<div class="room_titleBoxText" style="color: #fff">
								ID：{{ roomInformation.roomNo }}
							</div>
							<div class="room_titleBoxText" style="color: #fff">
								本次已开启：{{ roomInformation.timeLength }}
							</div>
						</div>
					</div>
					<div class="flex-spacebetween" style="width: 403px; color: #333">
						<div class="roomAdmin_bg flex-aling cursor">
							<img src="../../static/other17.png" alt="" class="roomAdmin_icon" />
							<img src="../../static/62.png" alt="" class="roomAdmin_page" @click.stop="visitorListShow = true" />
							<div style="color: #333">
								<div class="font-14">访客</div>
								<div class="font-10">{{ this.visitorNum }}人</div>
							</div>
						</div>
						<div class="roomAdmin_bg flex-aling cursor" @click="darkRoomAdminChange()" style="color: #333">
							<img src="../../static/other18.png" alt="" class="roomAdmin_icon" />
							<img src="../../static/62.png" alt="" class="roomAdmin_page" />
							<div>
								<div class="font-14">管理员</div>
								<div class="font-10" style="color: #8e92b1">
									{{ this.adminNum }}人
								</div>
							</div>
						</div>
						<div class="roomAdmin_bg flex-aling cursor" style="color: #333" @click="associationHouseOperate(9)">
							<img src="../../static/other19.png" alt="" class="roomAdmin_icon" />
							<img src="../../static/62.png" alt="" class="roomAdmin_page" />
							<div>
								<div class="font-14">背景图</div>
							</div>
						</div>
					</div>
					<div class="flex-spacebetween" style="width: 403px; margin-top: 10px; color: #333">
						<div class="roomAdmin_bg flex-aling" style="width: 198px; justify-content: space-between">
							<div style="display: flex">
								<div>
									<div class="font-14">用户上麦申请</div>
								</div>
							</div>
							<div>
								<el-switch v-model="roomInformation.userApplySwitch" :active-value="1" :inactive-value="0" @change="setUpMicroSwitch('all')">
								</el-switch>
							</div>
						</div>
						<div class="roomAdmin_bg flex-aling" style="width: 198px; justify-content: space-between" v-if="roomDetail.resultCode == 6">
							<div style="display: flex">
								<img src="../../static/other21.png" alt="" class="roomAdmin_icon" />
								<div>
									<div class="font-14">对讲机模式</div>
								</div>
							</div>
							<div>
								<el-switch v-model="roomDetail.isIntercom" :active-value="1" :inactive-value="0" @change="setRoomUserSet('all')">
								</el-switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="popUp_box" v-if="isGuan">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">选择分组</div>
					<img src="../../static/quit.png" alt="" @click="isGuan = false" />
				</div>
				<!-- 头部end -->
				<div style="height: 219px">
					<div style="width: 382px; margin: 48px auto 60px">
						<el-select v-model="FenValue" placeholder="请选择" style="width: 382px; z-index: 99999">
							<el-option v-for="item in goodsFen" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="BtnSubmit" @click="BtnSubmit">确认</div>
				</div>
			</div>
		</div>

		<!-- 我的任务弹窗 -->
		<div class="popUp_box" v-if="myFunctionShow == 7">
			<div class="deityRich_rule" style="height: 600px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14 flex-aling">我的任务</div>
					<img src="../../static/quit.png" alt="" @click="myFunctionShow = 0" />
				</div>
				<!-- 头部end -->
				<div class="myTask_box">
					<div class="myTask_text flex-dir">
						<div>主持人可设置自己的礼物任务，限定时间内需求指定礼物数</div>
						<div>超过限定时间，礼物任务失效；</div>
					</div>
					<div class="flex" style="width: 450px">
						<div class="myTask_itemBox flex-dir" v-for="(item, key1) in hostTaskList" :key="key1" style="margin-right: 10px">
							<div class="myTask_item flex-dir">
								<img src="../../static/59.png" alt="" class="myTask_leftImg" @click.stop="removeUserTask(item.id)" />
								<div class="myTask_icon flex-content">
									{{ `${item.getNum}/${item.num}` }}
								</div>
								<img :src="item.giftPath" alt="" class="myTask_head" />
								<div class="font-14" style="padding: 8px 0 4px 0; color: #fff">
									{{ item.giftName }}
								</div>
								<div style="color: #f9cc45">{{ item.giftPrice }}元宝</div>
								<!-- <div class="myTask_btn flex-content">设置</div> -->
							</div>
							<div class="padding_10" style="color: #8e92b1" v-if="item.type == 1">
								今日任务
							</div>
							<div class="padding_10" style="color: #8e92b1" v-else-if="item.type == 2">
								本周任务
							</div>
							<div class="padding_10" style="color: #8e92b1" v-else-if="item.type == 3">
								本月任务
							</div>
						</div>
						<!-- 礼物为空 -->
						<div class="myTask_addBox" @click.stop="addTaskChange()" v-if="hostTaskList.length < 3">
							<div class="addTask_btn"></div>
							<div class="addTask_text">添加任务</div>
						</div>
						<!-- 新增任务选择礼物弹窗样式 -->
						<div class="addTask_popUp" v-if="addTaskShow">
							<div class="gift_box" style="
                  background: rgba(0, 0, 0, 0.95);
                  width: 461px;
                  height: 128px;
                ">
								<div class="gift_tabBox">
									<div class="gift_tab">
										<div class="gift_tabItem XS" :class="giftType == key3 ? 'gift_pitchOn' : ''" v-for="(item, key3) in giftTab" :key="key3"
											@click="cutGiftType(key3)">
											{{ item }}
										</div>
									</div>
									<!-- <div
                    @click="cutGiftType(4)"
                    :class="giftType == 4 ? 'gift_pitchOn' : ''"
                    style="display: flex; align-items: center"
                  >
                    <img src="../../static/user8.png" />
                    我的背包
                  </div> -->
								</div>

								<!-- 礼物列表 -->
								<div id="gift_list" ref="giftListRef1">
									<div class="gift_item" v-for="(item, key4) in giftList" :key="key4" @mouseenter="onShowTip($event, item)" @mouseleave="onHideTip"
										:class="taskGiftitem.id == item.id ? 'gift_pitch' : ''" @click="setGiftItemChange(item, key4)">
										<div class="giftWealThName" v-if="giftType == 2">
											{{ item.wealthName }}
										</div>
										<img :src="item.giftPath" alt="" />
										<div style="width: 100px" class="line1">
											{{ item.giftName }}
										</div>
									</div>
									<div v-if="!giftList || giftList.length == 0" style="width: 100px; height: 62px"></div>
								</div>
								<!-- 礼物列表 end -->
							</div>
							<!-- 设置礼物数量 -->
							<div class="flex-aling setGiftTask_style">
								<div>礼物数量：</div>
								<div class="flex-content">
									<img src="../../static/47.png" alt="" class="addAndImg" @click.stop="amendNumAddSubtract(2)" />
									<div style="padding: 0 12px">{{ setGiftNum }}</div>
									<img src="../../static/46.png" alt="" class="addAndImg" @click.stop="amendNumAddSubtract(1)" />
								</div>
								<div style="margin-left: 22px">任务周期：</div>
								<el-select v-model="giftPeriodValue" placeholder="请选择周期" class="giftPeriod_select">
									<el-option v-for="(value, key) in giftPeriod" :key="value" :label="key" :value="value">
									</el-option>
								</el-select>
								<div class="giftPeriod_btn flex-content" @click.stop="setSaveHostGiftTask()">
									确认
								</div>
							</div>
						</div>
					</div>
					<div style="margin-top: 40px; font-size: 16px">
						任务状态：
						<el-switch v-model="taskState" active-color="#7e51f1" inactive-color="#ccc">
						</el-switch>
					</div>
					<div style="margin-top: 16px; font-size: 14px; color: #6a6a94">
						<div>开启状态，每次上主持麦则默认展示当前任务信息</div>
						<div>关闭状态，每次上主持麦则不展示任务信息，需手动开启</div>
					</div>
					<div class="myTask_addBtn flex-content" @click="myFunctionShow = 0">
						确认
					</div>
				</div>
			</div>
		</div>
		<!-- 举报 -->
		<div class="popUp_box" v-if="reportShow">
			<div class="report_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">举报{{ reportType == 2 ? "房间" : "用户" }}</div>
					<img src="../../static/quit.png" alt="" @click="
  reportShow = false;
clearPopup();
          " />
				</div>
				<!-- 头部end -->
				<div class="report_topText flex-content">
					举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
				</div>
				<div class="report_from">
					<div class="report_fromTitle">举报原因</div>
					<el-select v-model="reportValue" placeholder="请选择举报原因">
						<el-option v-for="item in reportOptions" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<div class="report_fromTitle">
						举报内容
						<span style="color: #6a6a94; margin-left: 11px">补充说明，协助审核人员尽快处理</span>
						<el-input type="textarea" placeholder="填写详细说明" v-model="reportTextarea" maxlength="200" :rows="4" resize="none" style="margin-top: 10px">
						</el-input>
					</div>
					<div class="report_fromTitle">截图</div>
					<div class="flex-aling">
						<div class="imgBox" v-for="(item, index) in reportImgs" :key="index">
							<img :src="item" alt="" style="width: 100%; height: 100%" />
							<img src="../../static/127.png" alt="" class="imgDeleIcon" @click.stop="imgDeleChange(index)" />
						</div>
						<el-upload action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :limit="4" :show-file-list="false" list-type="picture-card"
							:on-success="reportImgSuccess" accept=".png,.jpg.jpeg" v-if="reportImgs.length < 4">
							<img src="../../static/183.png" alt="" />
						</el-upload>
					</div>
					<div style="color: #6a6a94; font-size: 10px; margin-top: 20px">
						最多4张图片
					</div>
					<div class="report_btn flex-content" @click.stop="addRoomReport()">
						确认提交
					</div>
				</div>
			</div>
		</div>


		<!-- 实名认证 -->
		<div class="popUp_box" v-if="shingmingShow">
			<div class="report_remind" style='width:600px'>
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">实名认证</div>
					<!-- 头部end -->
					<div class=" flex-content">
						<div>
							<img src="../../static/quit.png" alt="" @click="shingmingShow = false" />
						</div>

					</div>
				</div>
				<div style='width:98px;margin:24px auto'>
					<img class='report_topTextImg' src="../../image/message/34.png" alt="" @click="shingmingShow = false" />
				</div>
				<div class='report_topTextImgText'>
					赠送礼物金额已达上限，实名认证后方可继续赠送礼物
				</div>
				<div class='report_topTextImgTextBtn' @click="shingmingShow = false">
					前往APP进行认证
				</div>

			</div>


		</div>
		<!-- 禁言弹窗 -->
		<div class="popUp_box" v-if="disableSendMsgShow">
			<div class="report_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">禁言</div>
					<img src="../../static/quit.png" alt="" @click="disableSendMsgShow = false" />
				</div>
				<!-- 头部end -->

				<div class="report_from">
					<div class="report_fromTitle">禁言天数</div>
					<el-select v-model="bearValue" placeholder="请选择">
						<el-option v-for="item in bearOptions" :key="item.value" :label="item.configName" :value="item.configValue">
						</el-option>
					</el-select>
					<div class="report_fromTitle">
						禁言原因
						<span style="color: #6a6a94; margin-left: 11px">补充说明，协助审核人员尽快处理</span>
						<el-input type="textarea" placeholder="填写禁言原因" v-model="bearTextarea" maxlength="10" :rows="3" resize="none" style="margin-top: 10px">
						</el-input>
					</div>

					<div class="report_btn flex-content" @click.stop="addDisableSendMsg()">
						确认提交
					</div>
				</div>
			</div>
		</div>
		<!-- 抱用户上麦弹窗 -->
		<div class="popUp_box" v-if="embraceShow">
			<div class="report_remind">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">房间在线</div>
					<img src="../../static/quit.png" alt="" @click="embraceShow = false" />
				</div>
				<!-- 头部end -->
				<el-input type="text" v-model="searchUser" placeholder="搜索用户昵称/ID" class="blacklist_input" prefix-icon="el-icon-search" @change="searchChange()"></el-input>
				<div class="report_list">
					<div class="report_item" v-for="(item, index) in onLineList" :key="index">
						<div class="flex-aling">
							<img :src="item.avatar" alt="" class="report_head" />
							<div style="color: #333333">{{ item.nickname }}</div>
							<img src="../../static/134.png" alt="" style="margin-left: 5px" v-if="item.sex == 1" />
							<img src="../../static/25.png" alt="" style="width: 12px; height: 12px; margin-left: 5px" v-else />
							<div class="flex">
								<img :src="item.wealthLevelLogo" alt="" v-if="item.wealthLevelLogo" class="report_icon" />
								<img :src="item.charismaLevelLogo" alt="" v-if="item.charismaLevelLogo" class="report_icon" />
							</div>
							<img src="../../image/room/r1.png" class="margin_r4" style="width: 32px" alt="" v-if="item.userRole == 0" />
							<img src="../../image/room/r2.png" class="margin_r4" style="width: 32px" alt="" v-if="item.userRole == 1" />
							<img src="../../image/room/r3.png" class="margin_r4" style="width: 32px" alt="" v-if="item.userRole == 2" />
						</div>
						<div class="baoUserBtn" @click="embraceChange(item)">抱ta上麦</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<!-- 结束派单 -->
		<div class="popUp_box" v-if="overSendOrdersShow">
			<div class="wheat_remind" style="height: 295px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img src="../../static/quit.png" alt="" @click="overSendOrdersShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img src="../../static/188.png" alt="" style="margin: 30px 0 20px 0" />
					<div style="color: #333">确定要结束本次派单吗？</div>
					<div class="flex">
						<div class="wheatRemind_apply btn2 flex-content" @click="overSendOrdersShow = false">
							再想想
						</div>
						<div class="wheatRemind_apply flex-content" @click="overOrders()">
							确认
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 派单 -->
		<sendOrders v-if="sendOrdersShow" :roomId="roomId" @sendShowChange="sendOrdersShow = false"></sendOrders>
		<wealthPopover v-if="wealthThatShow" @setWealthShow="wealthThatShow = false" style="z-index: 99"></wealthPopover>
		<!-- 开黑房管理列表 -->
		<darkRoomAdmin :roomId="roomId" v-if="darkRoomAdminShow" @darkAdminListChange="darkRoomAdminChange()">
		</darkRoomAdmin>
		<!-- 公会房间管理列表 -->
		<societyAdministrator :roomId="roomId" v-if="societyAdminShow" @cancelAdmin="setCancelAdmin($event)" @addAdmin="moduleAddAdmin($event)"
			@darkAdminListChange="(societyAdminShow = false), getRoomPcRankList(1)">
		</societyAdministrator>
		<!-- 访客列表 -->
		<visitorList :liveStreamId="liveStreamId" v-if="visitorListShow" @visitorListChange="visitorListShow = false">
		</visitorList>
		<!-- 禁言列表 -->
		<gagList :roomId="roomId" v-if="houseOwnerOperate == 7" @darkAdminListChange="houseOwnerOperate = 0"></gagList>
		<!-- 厅黑名单 -->
		<roomBlacklist :roomId="roomId" v-if="houseOwnerOperate == 8" @blackListChange="houseOwnerOperate = 0">
		</roomBlacklist>
		<!-- 我的消息 -->

		<myMessage ref="myMessage" v-show="myMessageShow" @closeMessage="
  myMessageShow = false;
getXiaoxiNum();
    " @placeSkip="placeSkipChange($event)"></myMessage>

		<!-- 充值页面 -->
		<walletTopup v-if="walletTopupShow" @walletQuitChange="walletTopupShow = false" style="position: absolute; left: 0%; top: 0%"></walletTopup>

		<!-- 排行榜 -->
		<rankingList v-if="ranking" @closeRanking="showChange('ranking')"></rankingList>

		<!-- 个人中心 -->
		<personalCenter ref="personal" v-if="personalShow" :userId="userInfo.id" @closePersonal="showChange('personal')">
		</personalCenter>
		<blindBox v-if="blindBoxShow" @closeBlindBox="blindBoxShow = false" @myMoney="myMoney"></blindBox>
		<!-- 他人主页 -->
		<othersHome @closeOthers="othersShow = false" :userId="manitoId" :userType="othersType" v-if="othersShow">
		</othersHome>
	</div>
</template>

<script>
	import Progress from "./components/schedule.vue";
	import Tooltip from "./components/tooltip.vue";
	import roomRight from "./components/roomRight.vue"; //聊天室右侧榜单与聊天
	import sendOrders from "./components/sendOrders.vue"; //派单
	import wealthPopover from "../personalCenterAll/wealthPopover.vue"; //财富说明
	import darkRoomAdmin from "./components/darkRoomAdmin.vue"; // 开黑管理员列表
	import societyAdministrator from "./components/societyAdministrator.vue"; //公会房管理
	import gagList from "./components/gagList.vue"; // 禁言列表
	import roomBlacklist from "./components/roomBlacklist.vue"; // 房间黑名单列表
	import visitorList from "./components/visitorList.vue"; //访客列表
	import musicBox from "./components/musicBox.vue"; // 音乐播放
	import myMessage from "../homePage/messagePage.vue"; //我的消息
	import svgaImg from "./components/svgaImg.vue"; //svga文件解析
	import walletTopup from "../personalCenterAll/walletTopup.vue"; //充值弹窗
	import rankingList from "../homePage/rankingList.vue"; //排行帮
	import personalCenter from "../homePage/personalCenter.vue"; //个人中心
	import THcountDown from "../../components/THcountDown/index.vue"; //神豪倒计时
	import blindBox from "../blindBox/index.vue"; //盲盒
	import othersHome from "../homePage/othersHome.vue";
	import {
		CDNStreaming,
		PublishMode
	} from "trtc-sdk-v5/plugins/cdn-streaming";
	import flvjs from "flv.js";
	export default {
		name: "",
		components: {
			Progress,
			Tooltip,
			roomRight,
			sendOrders,
			wealthPopover,
			darkRoomAdmin,
			societyAdministrator,
			gagList,
			visitorList,
			roomBlacklist,
			musicBox,
			myMessage,
			svgaImg,
			walletTopup,
			rankingList,
			personalCenter,
			THcountDown,
			blindBox,
			othersHome,
		},
		inject: ["reload"],
		data() {
			return {
				giftRoomInfo: {},
				page: 1,
				name: "",
				player: null,
				disableSendMsgShow: false, //禁言弹窗
				bearOptions: [], //禁言天数
				bearValue: "",
				bearTextarea: "", //禁言原因
				relieveShow: false, //主持人解锁弹窗
				blindBoxShow: false, //盲盒
				blindBoxBtnShow: "false", //盲盒按钮
				is_createdRoom: false, //是否创建过房间
				svgaURL: "",
				walletTopupShow: false, //充值页面
				downloadAppShow: false, //下载弹窗
				// 左侧
				listNum: {}, //左侧列表数量
				vollbildShow: false, //全频广播
				vollbildData: {
					giftLogo: "",
					giftNum: "",
				},
				twolabel: {}, //二级标签
				labelType: 1, //房间类型 1游戏 2娱乐
				gameLabels: [], //游戏标签
				amusementLabels: [], //娱乐标签
				userGiveAwayType: false, //是否给个人赠送客户
				comperepopoverShow: false, //主持人麦位点击
				roomLockShow: false, //房间上锁弹窗
				clsRemindShow: false, //清屏弹窗
				closeRoomShow: false, //关闭房间弹窗
				overSendOrdersShow: false, //结束派单弹窗
				reportShow: false, //举报弹窗
				shingmingShow: false,
				reportType: "", //举报类型 1举报房间 2举报用户
				wealthThatShow: false, //财富说明
				popoverShow: "-1", //麦位点击弹窗
				giveGiftsShow: false, //点唱详情弹窗
				searchPopupShow: false, //搜索弹窗
				keyword: "", //搜索内容
				searchNum: 0, //0全部 1用户 2房间
				searchRoomData: [], //搜索聊天室列表
				searchPlayerData: [], //搜索大神列表
				giftTab: ["热门", "情人专场", "特权专区", "土豪包场"],
				giftType: 0, // 礼物类型0-热门 1-情人专场 2-特权专区 3-土豪包场
				activeGift: 3, // 默认激活中间的礼物
				giftItemType: {}, //选中礼物
				giftQueueItem:[], //队列播放当前礼物
				giftPopUpShow: false, //是否添加礼物动画
				taskGiftitem: {}, //设置礼物任务
				giftList: [], //礼物列表
				currentGift: {}, //当前礼物
				giveAwayNum: 1, //赠送数量
				content: "",
				gangUpTable: ["在线", "VIP", "排麦"],
				personnelTable: ["在线", "VIP", "点单", "试音"],
				roomUserList: [], //左侧列表
				personnelType: 1, // 名单类型 1-在线 2-vip 3-点单 4-试音
				searchInput: "", //搜索内容
				othersData: {}, //他人主页数据
				othersHomeShow: false, //他人主页
				wheatHomeShow: false, //麦上人员主页
				othersHomeShowLiao: false, //聊天框主页
				othersHometype: "", //2 送麦上用户 ，1在线用户
				theWheatData: {}, //麦上用户数据
				emceeTaskShow: false, //任务弹窗
				upgraeShow: false, //升级弹窗
				upgraeData: {}, //升级数据
				urlLink: "https://tjj-sever.oss-cn-beijing.aliyuncs.com/goushuyuyin.zip",
				shieldingList: [{
						state: "allSpecialEffects",
						text: "屏蔽所有特效",
						status: 0
					},
					{
						state: "allGift",
						text: "屏蔽礼物特效及音效",
						status: 0
					},
					{
						state: "allGfitBanner",
						text: "屏蔽礼物横幅",
						status: 0
					},
					{
						state: "allIn",
						text: "屏蔽入场特效",
						status: 0
					},
					{
						state: "allBackImg",
						text: "屏蔽房间特效",
						status: 0
					},

					{
						state: "allBulletChat",
						text: "屏蔽全屏弹窗",
						status: 0
					},
				],
				download: {},
				shieldingIndex: 0,
				shieldingSetShow: false, //屏蔽弹窗设置
				danmuSetShow: false,
				loadings: false,
				givingNumList: [{
						name: "1 一心一意",
						value: 1,
					},
					{
						name: "5 五彩缤纷",
						value: 5,
					},
					{
						name: "10 十全十美",
						value: 10,
					},
					{
						name: "21 爱你爱你",
						value: 21,
					},
					{
						name: "30 想你每一天",
						value: 30,
					},
					{
						name: "66 顺心顺意",
						value: 66,
					},
					{
						name: "99 天长地久",
						value: 99,
					},
				],
				givingNumSelectShow: false, //赠送数量选择
				wheatRemindShow: false, //上麦提醒弹窗
				wheatInviteShow: false, //上麦邀请弹窗
				inviteData: {}, //邀请上麦数据
				outRoomShow: false, //退出房间提醒
				kickOutShow: false, //踢出房间弹窗
				deityRichRuleShow: false, //神豪规则弹窗
				divineData: {}, //神豪位上限值
				someSingingShow: false, //点唱弹窗
				broadcastTopShow: false, //顶部弹窗
				roomStatus: 1, //房间状态 1语聊 2房内pk 3房间pk 4相亲
				myFunctionShow: 0, // 7我的任务
				roomFunctionShow: false, //房间操作弹窗
				loveEachPopupShow: false, //相亲相爱规则弹窗
				loveExplain: "", //相亲相爱说明
				pkStatus: 1, //0准备
				roomListBoxShow: false, //房间列表弹窗
				pkInvitationShow: false, //pk邀请弹窗
				pkInvitationData: {}, //PK邀请方数据
				editRoomShow: false, //编辑房间弹窗
				societyEditShow: false, //编辑公会房间
				editBillboardShow: false, //编辑公告弹窗
				backgroundImageShow: false, //背景图选择
				othersShow: false, //他人主页
				manitoId: "", //他人用户id
				othersType: "", //4 勋章墙
				houseOwnerOperate: 0, //房主操作.
				goodsFen: [],
				FenValue: "",
				defaultBg: require("../../assets/bg2.png"),
				onerFunctionList: [{
						url: require("../../image/room/39.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/45.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/9.png"),
						name: "相亲相爱",
						type: 4,
					},
					{
						url: require("../../image/room/10.png"),
						name: "房内PK",
						type: 2,
					},
					{
						url: require("../../image/room/pk.png"),
						name: "房间PK",
						type: 3,
					},
					{
						url: require("../../image/room/12.png"),
						name: "语聊模式",
						type: 1,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/15.png"),
						name: "我的任务",
						type: 7,
					},
					{
						url: require("../../image/room/16.png"),
						name: "禁言名单",
						type: 8,
					},
				],
				// 房间并且是主持人 点唱厅
				functionListHostDian: [{
						url: require("../../image/room/39.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/42.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
					{
						url: require("../../image/room/9.png"),
						name: "相亲相爱",
						type: 4,
					},
					{
						url: require("../../image/room/10.png"),
						name: "房内PK",
						type: 2,
					},
					{
						url: require("../../image/room/pk.png"),
						name: "房间PK",
						type: 3,
					},
					{
						url: require("../../image/room/12.png"),
						name: "语聊模式",
						type: 1,
					},
				],
				// 公会厅 超管房主权限

				// 派单厅超管权限 房主
				functionListHost: [{
						url: require("../../image/room/39.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/42.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
				],
				// 管理员
				functionGuan: [{
						url: require("../../image/room/42.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
				],

				// 点唱厅主持人
				functionDian: [{
						url: require("../../image/room/42.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
					{
						url: require("../../image/room/9.png"),
						name: "相亲相爱",
						type: 4,
					},
					{
						url: require("../../image/room/10.png"),
						name: "房内PK",
						type: 2,
					},
					{
						url: require("../../image/room/pk.png"),
						name: "房间PK",
						type: 3,
					},
					{
						url: require("../../image/room/12.png"),
						name: "语聊模式",
						type: 1,
					},
				],
				// 派单厅主持人
				functionListOner: [{
						url: require("../../image/room/42.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
					{
						url: require("../../image/room/15.png"),
						name: "我的任务",
						type: 7,
					},
				],

				// 派单厅主持人 房主
				functionList: [{
						url: require("../../image/room/39.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/42.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
					{
						url: require("../../image/room/15.png"),
						name: "我的任务",
						type: 7,
					},
				],
				// 派单厅房主超管
				sendOrdersOperateList: [{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/42.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/15.png"),
						name: "我的任务",
						type: 7,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
					},
				],
				// 房主超管主持人
				//开黑房 房主权限弹窗
				houseOwnerList: [{
						url: require("../../image/room/38.png"),
						name: "房间上锁",
					},
					{
						url: require("../../image/room/39.png"),
						name: "清屏",
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/42.png"),
						name: "关闭房间",
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
					},
					{
						url: require("../../image/room/40.png"),
						name: "管理员",
					},
				],
				houseOwnerListGuan: [{
						url: require("../../image/room/38.png"),
						name: "房间上锁",
					},
					{
						url: require("../../image/room/39.png"),
						name: "清屏",
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 12,
					},
					{
						url: require("../../image/room/42.png"),
						name: "关闭房间",
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
					},
				],
				// 公会房间
				conferenceHandleList: [{
						url: require("../../image/room/39.png"),
						name: "房间信息",
						type: 9,
					},
					{
						url: require("../../image/room/39.png"),
						name: "管理员",
						type: 10,
					},
					{
						url: require("../../image/room/45.png"),
						name: "厅黑名单",
						type: 11,
					},
					{
						url: require("../../image/room/13.png"),
						name: "礼物值清空",
						type: 14,
					},
					{
						url: require("../../image/room/14.png"),
						name: "清屏",
						type: 6,
					},
					{
						url: require("../../image/room/40.png"),
						name: "自动锁麦",
						type: 13,
					},
					{
						url: require("../../image/room/41.png"),
						name: "房间管理",
						type: 3,
					},
					{
						url: require("../../image/room/43.png"),
						name: "直播反馈",
						type: 5,
					},
					{
						url: require("../../image/room/44.png"),
						name: "禁言名单",
						type: 8,
					},
				],
				placingheader: ["序号", "用户信息", "用户ID", "操作"],
				form: {
					name: "",
					region: "",
					date1: "",
					date2: "",
					delivery: false,
					type: [],
					resource: "",
					desc: "",
				},
				musicShow: false, //音乐播放器显示
				roomId: "", //房间id
				liveStreamId: "", //	房间直播id
				userPositionId: "", //申请上麦用户
				userInfo: {}, //用户信息
				roomDetail: {}, //聊天室详情
				concertUserLisr: [], //点唱厅演唱者
				jukeboxCost: [], //点唱费用
				isBlackRoom: 2, //是否开黑房 1：是 2：不是
				userRole: 3, //用户角色 0-房主 1-超级管理员 2-管理员 3-游客 4-麦上普通用户 5-主持人
				// 房内pk
				value: "",
				sendOrdersShow: false, //派单弹窗
				roomMicrophoneList: [], //房间麦克风列表
				userPitchList: [], //选中人员
				microphoneListType: false, //是否展示完麦位人员
				whetherWheat: 0, //是否在麦上 0 上麦 1下麦 2排麦中
				myWheat: {}, //我的麦位数据
				passwordName: "",
				place: 0,
				password: ["", "", "", ""],
				darkRoomAdminShow: false, //管理员列表
				societyAdminShow: false, //公会房间管理
				visitorListShow: false, //访客列表
				allWheatUserData: [], //麦位全部用户
				volumeNum: 10,
				searchValue: "",
				microphoneType: false, //是否开麦闭麦
				allDataList: [], //全部麦数据
				embraceShow: false, //抱用户上麦弹窗
				embraceWheat: {}, //抱用户上麦点击麦位
				pitchOnWheat: {}, //选中有用户的麦位
				adminNum: 0, //管理员人数
				visitorNum: 0, //访客数
				compereType: false, //是否在主持人麦位
				commonWheatType: false, //是否普通用户
				taskState: false, //主持人任务状态
				roomInformation: {}, //房间数据
				roomBack: "", //房间背景
				jukeType: "0", //点唱选择类型
				jukeboxPitchNum: 0, //点唱选中人员
				jukeBoxPitch: {}, //选中点唱
				jukeboxSum: 0, //点唱总额
				reportImgs: "", //举报图片
				// 相亲恋爱模式
				loveStage: 0, //模式状态
				loveTime: 60, //真爱选择时间
				trueLoveList: [], //公布真爱列表
				trueLoveShow: false, //公布真爱弹窗
				trueLoveTime: null, //公布真爱计时器
				trueLoveNum: 0, //公布真爱次数
				loveTimeCount: "59", //倒计时
				downTime: null,
				searchUser: "", //搜索抱用户上麦列表
				onLineList: [], //抱用户上麦列表
				reportValue: "", //举报原因选择
				reportTextarea: "", //举报内容
				reportOptions: [], //举报原因
				reportImgs: [], //举报图片
				myMessageShow: false, //我的信息
				afficheValue: "", //修改公告内容
				myDiamondData: 0, //我的元宝
				beInvitedData: {}, //被邀请人数据
				// 房间PK模式
				pkRoomList: [], //选择PK房间列表
				inviterRoomId: "", //邀请者的房间id
				inviterUserId: "", //邀请者的用户id
				pkInviteDate: {}, //PK被邀请房间数据
				setPkTimeShow: false, //设置PK时长弹窗
				pkTimeValue: "", //选中PK时长
				durationList: [], //时长列表
				roomAtroomPkThreeData: {}, //房房pk礼物前三数据
				pkStartAnimationShow: false, //pk开始动画
				ranking: false, //排行榜展示
				personalShow: false, //个人中心
				wholeGift: {}, //全服礼物
				adverseData: {
					name: "",
					id: "",
				}, //对方房间数据
				jumpPopoverShow: false, //跳转对方房间弹窗
				pkResponse: false, //同意或拒绝PK
				endUserDataList: [], //PK结束数据
				//房内PK
				closeCountDown: 5, //PK结束弹窗倒计时
				pkAdvanceOverShow: false, //提前结束弹窗
				pkendTime: 0, //PK结束时间
				pkShi: "00",
				pkMinute: "00", //倒计时分
				pkSecond: "00", //倒计时秒
				pkTopThreeData: {}, //pk礼物前三数据
				pkRedDutyCycle: 50, //红方占比
				pkFinishShow: false, //结束PK内容弹窗
				pkFinishTime: null, //结束弹窗PK定时器
				pkCountDown: null, //PK倒计时计时器
				// 主持人任务
				addTaskShow: false, //设置主持人任务
				giftPeriod: {}, //任务周期
				giftPeriodValue: "", //选择周期
				wheatFrameList: [], //麦位数据
				roomSetData: {}, // 用户房间设置
				fankuiArr: [], //反馈类型
				setGiftNum: 1, //设置礼物数量
				hostTaskList: [], //主持人礼物列表
				totalUnreadCount: 0,
				// 礼物
				giftValue: "", //当前播放礼物动画
				giftQueue: [], //礼物队列
				giftSvgaPlayShow: false, //礼物动画播放
				bgImgList: [], //背景图列表
				kickRoomShow: false, //被踢出房间
				taskStatus: 0, //主持人任务 0关 1开
				giftListRef: null,
				giftItemRef: null,
				guangboAll: false,
				isGuan: false,
				tabIndex: 0,
				tabItem: "",
				fankuiValue: "",
				isFankui: false,
				trtcA1: "",
				isFlag: true,
				trtcA2: "",
				trtcB2: "",
				hotNum: "",
				sendStatus: false,
			};
		},
		computed: {},
		created() {
			this.roomId = this.$route.query.roomId;
			this.enterRoom();

			if (localStorage.getItem("userTIMInfo")) {} else {
				return;
			}
			this.getStatus();
			this.getRoomPkList();
			this.getRoomPcRankList(1);
			this.roompk();
			this.getRoomFeedBackTypes();
			let TencentCloudChat = this.TencentCloudChat;

			let TRTC = this.TRTC;
			let that = this;
			that.trtcA2 = TRTC.create();
			that.trtcB2 = TRTC.create();
			this.getUserInfo();
			let onMessageReceived = function(event) {
				// event.data - 存储 Message 对象的数组 - [Message]
				const messageList = event.data;
				messageList.forEach((message) => {
					console.log('聊天室消息',message)
					if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
						// 文本消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.TextPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_IMAGE) {
						// 图片消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.ImagePayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_SOUND) {
						// 音频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.AudioPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_VIDEO) {
						// 视频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.VideoPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_FILE) {
						// 文件消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.FilePayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_CUSTOM) {
						if (message.type == "TIMCustomElem") {
					
							
							// 退出房间
							// if (JSON.parse(message.payload.data).cmd == 'CustomCmdMsg') {
							// 	console.log(JSON.parse(message.payload.data).userId)
							// 	console.log(that.roomMicrophoneList,'当前送全麦的人')
							// 	if(that.roomMicrophoneList.length){
							// 		that.roomMicrophoneList.forEach((item,index) =>{
							// 			if(item.userId == JSON.parse(message.payload.data).userId){
							// 				that.roomMicrophoneList.splice(index,1)
							// 			}
							// 		})
							// 	}
							// }
							

							if (JSON.parse(message.payload.data).data.cmd == 'custom_face_msg') {

								JSON.parse(message.payload.data).data.enter = 5
								let enterRoom = {
									data: JSON.parse(message.payload.data).data
								}

								that.$refs.roomRight.getGiftData(enterRoom);
							}


							if (
								JSON.parse(message.payload.data).data.cmd == "set_super_manager"
							) {
								let newData = JSON.parse(message.payload.data).data;
								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.userRole = 1;
									that.userRole = 1;
								}
								that.getRoomPcRankList(1);
							}
							if (
								JSON.parse(message.payload.data).data.cmd == "refuse_invite_wheat"
							) {
								let newData = JSON.parse(message.payload.data).data;
								if (that.userInfo.id == newData.fromUserId) {
									that.$message(newData.userName + "拒绝了您的上麦申请");
								}
							}
							if (JSON.parse(message.payload.data).data.cmd == "all_gift_msg") {
								let newData = JSON.parse(message.payload.data).data;
								that.wholeGift = newData;
								that.giftRoomInfo = newData;
								that.broadcastTopShow = true;
								// this.broadcastTopShow = true;
								setTimeout(() => {
									that.broadcastTopShow = false;
								}, 4000);
								if (newData.giftPcSvga) {
									that.giftQueueItem = newData.giftPcSvga;
									console.log('待播放的礼物',that.giftQueueItem)
									that.giftPopUpShow = true;
								} else {
									that.giftQueueItem = newData.giftSvga;
									that.giftPopUpShow = true;
									console.log('待播放的礼物',that.giftQueueItem)
								}
								// that.getGiftQueue();
							}
							if (JSON.parse(message.payload.data).data.cmd == "text_msg") {
								// that.$room
								// getBroadcastData
								// that.$refs.roomRight.getBroadcastData();
								that.$refs.roomRight.wholeData = {
									content: JSON.parse(message.payload.data).data.content,
									sendUserName: JSON.parse(message.payload.data).data
										.sendUserName,
									sendUserAvatar: JSON.parse(message.payload.data).data
										.sendUserAvatar,
									roomId: JSON.parse(message.payload.data).data.roomId,
								};
							}
						}

						if (JSON.parse(message.payload.data).cmd == "CustomCmdMsg") {
							//申请上麦
							if (JSON.parse(message.payload.data).data.cmd == "in_pk_end") {
								let newData = JSON.parse(message.payload.data).data;
								that.pkStatus = 3;
								window.clearInterval(that.pkCountDown);
								that.pkCountDown = null;
								// 结束PK通知
								that.endUserDataList = newData.roomInPkResult;
								that.pkFinishShow = true;
								setTimeout(() => {
									that.pkFinishShow = false;
								}, 5000);
							}
							// 厅拉黑 black_user

							if (JSON.parse(message.payload.data).data.cmd == "black_user") {
								if (
									that.userInfo.id ==
									JSON.parse(message.payload.data).data.targetUserId
								) {
									that.$message.info("你被踢出房间");
									that.trtcA1.exitRoom();
									that.$router.push({
										path: "/home",
									});
								}
								that.getRoomPcRankList(1);
							}

							// tell_end_pk
							if (JSON.parse(message.payload.data).data.cmd == "tell_end_pk") {
								// 切换模式
								let newData = JSON.parse(message.payload.data).data;

								if (newData.redUHost.userId == that.userInfo.id) {
									that.trtcA2.stopLocalAudio();
									that.trtcA2.exitRoom();
									that.trtcA2.destroy();
								}
								if (newData.blueHost.userId == that.userInfo.id) {
									that.trtcB2.stopLocalAudio();
									that.trtcB2.exitRoom();
									that.trtcB2.destroy();
								}
								that.roomModeSwitch(1);
							}
							if (JSON.parse(message.payload.data).data.cmd == "back_change") {
								let newData = JSON.parse(message.payload.data).data;
								// that.roomBack=newData.roomBack
								that.roomDetail.backImg = newData.roomBack;
								// // 切换模式
							}
							// 同意房房pk
							if (
								JSON.parse(message.payload.data).data.cmd == "accept_refuse_pk"
							) {
								let newData = JSON.parse(message.payload.data).data;
								let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
								that.pkRedDutyCycle = 50;
								that.roomDetail.resultCode = 3;
								that.roomDetail.roomPkOutVO = newData.roomVoiceOutPKVO;
								that.pkTopThreeData.blueTeamValue =
									newData.roomVoiceOutPKVO.blueTeamValue;
								that.pkTopThreeData.redTeamValue =
									newData.roomVoiceOutPKVO.redTeamValue;
								if (
									that.roomDetail.hostVO.userId ==
									newData.roomVoiceOutPKVO.redHostId
								) {
									that.trtcA2.enterRoom({
										sdkAppId: that.$SDKAppID,
										userId: newData.roomVoiceOutPKVO.redHostId,
										userSig: newData.redSgin,
										scene: TRTC.TYPE.SCENE_LIVE,
										strRoomId: newData.roomVoiceOutPKVO.blueRoomId,
										role: TRTC.TYPE.ROLE_ANCHOR,
									});
									that.trtcA2.startLocalAudio();
								}
								if (
									that.roomDetail.hostVO.userId ==
									newData.roomVoiceOutPKVO.blueHostId
								) {
									// that.trtcB2 = TRTC.create();
									that.trtcB2.enterRoom({
										sdkAppId: that.$SDKAppID,
										userId: newData.roomVoiceOutPKVO.blueHostId,
										userSig: newData.blueSgin,
										scene: TRTC.TYPE.SCENE_LIVE,
										strRoomId: newData.roomVoiceOutPKVO.redRoomId,
										role: TRTC.TYPE.ROLE_ANCHOR,
									});
									that.trtcB2.startLocalAudio();
								}
							}
							// out_pk_end pk结束
							if (JSON.parse(message.payload.data).data.cmd == "out_pk_end") {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.roomPkOutVO.pkStatus = 3;

								// that.trtcA1.stopLocalAudio();
								// that.trtcA1.exitRoom();

								that.closeCountDown = 5;
								that.pkAdvanceOverShow = false;
								let invitedId = "";
								that.pkFinishShow = true;
								setTimeout(() => {
									that.pkFinishShow = false;
								}, 5000);
								if (
									that.roomDetail.roomPkOutVO &&
									that.roomDetail.roomPkOutVO.blueRoomId
								) {
									invitedId = that.roomDetail.roomPkOutVO.blueRoomId;
								} else if (
									that.roomDetail.roomPkOutVO &&
									that.roomId == that.roomDetail.roomPkOutVO.blueRoomId
								) {
									// return this.enterRoom();
								}

								let thenAt = that;
								// this.enterRoom();
								window.clearInterval(that.pkCountDown);
								that.pkCountDown = null;
								// 结束PK通知
								if (
									that.roomDetail.roomPkOutVO &&
									that.roomDetail.roomPkOutVO.blueRoomId
								) {
									// this.$RCVoiceRoomLib.notifyVoiceRoom("external_pkFinish", {
									//   oppositeId: this.roomDetail.roomPkOutVO.blueRoomId,
									// });
								} else {
									// that.$RCVoiceRoomLib.notifyVoiceRoom("interior_pkFinish");
								}
								//房间PK通知对方房间刷新页面 content 1开始pk 2结束pk 3礼物
								if (
									that.roomDetail.roomPkOutVO &&
									that.roomDetail.roomPkOutVO.blueRoomId == that.roomId
								) {
									that.$api.sendMessage2Room({
										roomId: that.roomDetail.roomPkOutVO.redRoomId,
										content: 2,
									});
								} else if (
									that.roomDetail.roomPkOutVO &&
									that.roomDetail.roomPkOutVO.redRoomId == that.roomId
								) {
									that.$api.sendMessage2Room({
										roomId: that.roomDetail.roomPkOutVO.blueRoomId,
										content: 2,
									});
								}
								that.endUserDataList = newData.roomInPkResult;
							}
							if (JSON.parse(message.payload.data).data.cmd == "level_upt") {
								let newData = JSON.parse(message.payload.data).data;
								that.upgraeData = newData;
							}
							// pk开始
							if (JSON.parse(message.payload.data).data.cmd == "out_pk_start") {
								let newData = JSON.parse(message.payload.data).data;
								let pkEndTime = Date.parse(newData.pkEndTime);
								that.pkCountDown = window.setInterval(function() {
									let fanfanPkDataTime = new Date().getTime(); //初始化当前时间
									that.calculateCountDown(fanfanPkDataTime, pkEndTime);
								}, 1000);
								that.roomDetail.roomPkOutVO.pkStatus = 2;
								//房间PK通知对方房间刷新页面 content 1开始pk 2结束pk 3礼物
								if (
									newData.roomVoiceOutPKVO &&
									newData.roomVoiceOutPKVO.blueRoomId == that.roomId
								) {
									that.$api.sendMessage2Room({
										roomId: newData.roomVoiceOutPKVO.redRoomId,
										content: 1,
									});
								} else if (
									newData.roomVoiceOutPKVO &&
									newData.roomVoiceOutPKVO.redRoomId == that.roomId
								) {
									that.$api.sendMessage2Room({
										roomId: newData.roomVoiceOutPKVO.blueRoomId,
										content: 1,
									});
								}
								that.pkendTime = newData.pkEndTime;
								that.setPkTimeShow = false;
								that.pkStartAnimationShow = true;
								setTimeout(() => {
									that.pkStartAnimationShow = false;
								}, 6000);
								that.setPkTimeShow = false;
								if (
									newData.roomVoiceOutPKVO &&
									newData.roomVoiceOutPKVO.pkStatus == 2 &&
									pkEndTime - dataTime > 0
								) {
									// 获取结束时间倒计时
								}
							}
							if (JSON.parse(message.payload.data).data.cmd == "in_pk_start") {
								let newData = JSON.parse(message.payload.data).data;
								// 房内PK
								// if (
								//   this.roomDetail.roomVoiceInPKVO &&
								//   this.roomDetail.roomVoiceInPKVO.pkStatus == 2 &&
								//   thenAt.roomDetail.roomVoiceInPKVO.pkendTime - dataTime > 0
								// ) {
								//   // 获取结束时间倒计时
								that.pkTopThreeData = {};
								that.pkRedDutyCycle = 50;
								that.roomDetail.normalPositions = newData.roomDistributeNormalVOS;
								that.pkCountDown = window.setInterval(function() {
									let neiDataTime = new Date().getTime(); //初始化当前时间
									let pkEndTime = Date.parse(newData.pkEndTime);
									that.calculateCountDown(neiDataTime, pkEndTime);
								}, 1000);
								// }
								// if (that.roomDetail.roomVoiceInPKVO) {
								that.pkStatus = 2;
								// }

								that.pkendTime = newData.pkEndTime;
								that.setPkTimeShow = false;
								that.pkStartAnimationShow = true;
								setTimeout(() => {
									that.pkStartAnimationShow = false;
								}, 6000);
							}
							// 开麦
							if (JSON.parse(message.payload.data).data.cmd == "open_voice") {
								let newData = JSON.parse(message.payload.data).data;
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.positionId
									) {
										that.roomDetail.normalPositions[i].microphone = 0;
										that.roomDetail.normalPositions[i].positionIsClose = 0;
										if (that.userInfo.id == newData.userId) {
											that.microphoneType = false;
											if (that.roomDetail.forbidSpeach == 1) {
												that.$message.info("你被禁言不能开麦");
												that.roomDetail.normalPositions[i].microphone = 1;
												that.roomDetail.normalPositions[i].positionIsClose = 1;
											} else {
												that.trtcA1.switchRole(TRTC.TYPE.ROLE_ANCHOR);
												that.trtcA1.startLocalAudio();
											}
										}
									}
									that.$forceUpdate();
								}

								if (that.roomDetail.hostVO.userPositionId == newData.positionId) {
									that.roomDetail.hostVO.userIsClose = 0
									that.roomDetail.hostVO.microphone = 0
									if (that.roomDetail.forbidSpeach == 1) {
										that.$message.info("你被禁言不能开麦");
									} else {
										that.trtcA1.switchRole(TRTC.TYPE.ROLE_ANCHOR);
										that.trtcA1.startLocalAudio();
									}
								}
							}
							// 闭麦
							if (JSON.parse(message.payload.data).data.cmd == "close_voice") {
								let newData = JSON.parse(message.payload.data).data;

								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.positionId
									) {
										that.roomDetail.normalPositions[i].microphone = 1;
										that.roomDetail.normalPositions[i].positionIsClose = 1;
										if (that.userInfo.id == newData.userId) {
											that.microphoneType = true;
											that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
											that.trtcA1.stopLocalAudio();
										}
									}
									that.$forceUpdate();
								}
								if (that.roomDetail.hostVO.userPositionId == newData.positionId) {
									that.roomDetail.hostVO.userIsClose = 1
									that.roomDetail.hostVO.microphone = 1
									that.roomDetail.normalPositions[i].microphone = 1;
									that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
									that.trtcA1.stopLocalAudio();
								}
							}
							// 他人申请上麦
							if (JSON.parse(message.payload.data).data.cmd == "apply_wheat") {
								that.getRoomPcLeftNumbers();
								if (that.roomDetail.resultCode == 0) {}
								that.getRoomPcRankList(1);
							}
							// 清礼物值
							if (JSON.parse(message.payload.data).data.cmd == "clear_gift") {
								let newData = JSON.parse(message.payload.data).data;
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									that.roomDetail.normalPositions[i].giftValue = 0;
									that.roomDetail.normalPositions[i].giftValueStr = 0;
								}
								that.roomDetail.hostVO.giftValueStr = 0;
								that.roomDetail.hostVO.giftValue = 0;
							}
							// 清麦
							if (JSON.parse(message.payload.data).data.cmd == "clear_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.hostVO = newData.hostVO;
								that.roomDetail.normalPositions = newData.positionList;
								that.whetherWheat = 0;
								that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
								that.trtcA1.stopLocalAudio();
							}
							// 取消排麦
							// cancel_wheat
							if (JSON.parse(message.payload.data).data.cmd == "cancel_wheat") {
								that.getRoomPcRankList(3);
							}
							// 上麦
							if (JSON.parse(message.payload.data).data.cmd == "up_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								newData.microphone = 1;
								newData.positionIsClose = 1;
								if (that.roomDetail.resultCode == 6) {
									newData.photoFrame = "";
								}
								if (!that.microphoneListType) {
									that.allUserPitchChange();
								}
								that.getRoomPcRankList(1);
								if (newData.normalPositions) {
									that.roomDetail.normalPositions = newData.normalPositions;
								}
								if (that.userInfo.id == newData.userId) {
									that.microphoneType = 1
									that.whetherWheat = 1;
								}
								if (that.roomDetail.resultCode == 6) {
									that.roomDetail.hostVO = {};
									that.roomDetail.hostVO.userPositionId = "";
									newData.normalPositions.forEach((item) => {
										item.microphone = 0;
										item.positionIsClose = 0;
										item.photoFrame = "";
										// that.roomDetail.normalPositions[i].microphone = 1;
										// that.roomDetail.normalPositions[i].positionIsClose = 1;
									});
								}
								if (
									newData.userPositionId == that.roomDetail.hostVO.userPositionId
								) {

									if (that.roomDetail.forbidSpeach == 1) {
										that.$message.info("你被禁言不能开麦");
									} else {
										that.trtcA1.switchRole(TRTC.TYPE.ROLE_ANCHOR);
										if (that.userInfo.id == newData.userId) {
											if (newData.positionIsClose == 1) {

												that.userRole = 6;
												// that.roomDetail.normalPositions[i].microphone = 1;
												// that.roomDetail.normalPositions[i].positionIsClose = 1;
											} else {
												that.trtcA1.startLocalAudio();
											}
										}
									}
									newData.userIsClose = 1
									// that.microphoneType = 1
									that.roomDetail.hostVO = newData;
									if (that.roomDetail?.hostVO?.userId && that.roomDetail.hostVO.userId != that.userInfo.id) that.concertUserLisr.unshift(newData);
								} else {
									for (
										let i = 0; i < that.roomDetail.normalPositions.length; i++
									) {
										if (
											that.roomDetail.normalPositions[i].userPositionId ==
											newData.userPositionId
										) {
											// that.microphoneType = false;

											that.roomDetail.normalPositions[i] = newData;

											if (newData.userId == that.userInfo.id) {
												if (that.roomDetail.forbidSpeach == 1) {
													that.roomDetail.normalPositions[i].microphone = 1;
													that.roomDetail.normalPositions[i].positionIsClose = 1;
													that.$message.info("你被禁言不能开麦");
												} else {
													that.trtcA1.switchRole(TRTC.TYPE.ROLE_ANCHOR);
													if (newData.status == 1) {
														if (newData.positionIsClose == 1) {
															that.roomDetail.normalPositions[i].microphone = 1;
															that.roomDetail.normalPositions[
																i
															].positionIsClose = 1;
														}
														if (newData.positionIsClose == 0) {
															that.trtcA1.startLocalAudio();
														}

														that.$forceUpdate();
													}
												}
											}
										}
									}
								}
							}
							// down_wheat下麦
							if (JSON.parse(message.payload.data).data.cmd == "down_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								if (that.roomDetail.resultCode == 6) {
									that.roomDetail.hostVO = {};
									that.roomDetail.hostVO.userPositionId = "";
									that.roomDetail.normalPositions = newData.normalPositions;
								}
								if (newData.position == that.roomDetail.hostVO.userPositionId) {
									if (newData.userId == that.userInfo.id) {
										that.userRole = newData.userRole;
										that.trtcA1.stopLocalAudio();
										that.whetherWheat = 0;
										that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
									}
									that.roomDetail.hostVO.giftValue = "";
									that.roomDetail.hostVO.giftValueStr = "";
									that.roomDetail.hostVO.photoFrame = "";
									that.roomDetail.hostVO.positionIsClose = "";
									that.roomDetail.hostVO.userAvatar = "";
									that.roomDetail.hostVO.userId = "";
									that.roomDetail.hostVO.userName = "";
									that.roomDetail.hostVO.userRole = "";
									if (newData.isAutoLock == 1) {
										that.roomDetail.hostVO.status = 2;
									}
									if (that.concertUserLisr && that.concertUserLisr.length > 0) {
										that.concertUserLisr.forEach((item, index) => {
											if (item.userId == newData.userId) {
												that.concertUserLisr.splice(index, 1);
											}
										});
									}
									that.$forceUpdate();
								} else {
									for (
										let i = 0; i < that.roomDetail.normalPositions.length; i++
									) {
										if (
											that.roomDetail.normalPositions[i].userId == newData.userId
										) {
											if (newData.isAutoLock == 1) {
												that.roomDetail.normalPositions[i].status = 2;
											} else {
												that.roomDetail.normalPositions[i].status = 0;
											}
											that.getRoomPcRankList(1);
											that.roomDetail.normalPositions[i].giftValue = "";
											that.roomDetail.normalPositions[i].giftValueStr = "";
											that.roomDetail.normalPositions[i].photoFrame = "";
											that.roomDetail.normalPositions[i].positionIsClose = "";
											that.roomDetail.normalPositions[i].userAvatar = "";
											that.roomDetail.normalPositions[i].userId = "";
											that.roomDetail.normalPositions[i].userName = "";
											that.roomDetail.normalPositions[i].userRole = "";
										}
									}
									if (newData.userId == that.userInfo.id) {
										that.trtcA1.stopLocalAudio();
										that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
										that.whetherWheat = 0;
									}
									that.$forceUpdate();
								}
							}
							// 修改公告
							if (
								JSON.parse(message.payload.data).data.cmd == "set_announcement"
							) {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.announcement = newData.announcement;
							}
							// 拒绝上麦
							if (
								JSON.parse(message.payload.data).data.cmd == "refuse_up_wheat"
							) {
								let newData = JSON.parse(message.payload.data).data;
								if (that.userInfo.id == newData.userId) {
									that.$message("您的上麦申请被拒绝");
								}
								that.getRoomPcRankList(3);
							}
							// 同意上麦
							// accept_up_wheat
							if (
								JSON.parse(message.payload.data).data.cmd == "accept_up_wheat"
							) {
								let newData = JSON.parse(message.payload.data).data;
								that.getRoomPcRankList(3);
								that.getMicrophoneDetail();
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId == newData.userPositionId
									) {
										that.whetherWheat = 1;
										that.roomDetail.normalPositions[i].giftValue =
											newData.giftValue;
										that.roomDetail.normalPositions[i].giftValueStr =
											newData.giftValueStr;
										that.roomDetail.normalPositions[i].photoFrame =
											newData.photoFrame;
										that.roomDetail.normalPositions[i].positionIsClose =
											newData.positionIsClose;
										that.roomDetail.normalPositions[i].userAvatar =
											newData.targetUserAvatar;
										that.roomDetail.normalPositions[i].userId =
											newData.targetUserId;
										that.roomDetail.normalPositions[i].userName =
											newData.targetUserName;
										that.roomDetail.normalPositions[i].userRole =
											newData.targetUserRole;
										that.$forceUpdate();
									}
								}
							}
							// cancel_manager 取消管理
							if (JSON.parse(message.payload.data).data.cmd == "cancel_manager") {
								let newData = JSON.parse(message.payload.data).data;
								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.userRole = 3;
									that.userRole = 3;
								}
								let enterRoom = {
									data: {
										userName: newData.targetUserName,
										enter: 4,
										operate: 2,
										type: 2,
									},
								};
								// that.$refs.roomRight.getGiftData(enterRoom);
								that.getRoomPcRankList(1);
							}
							// 闭麦close_voice
							// if (JSON.parse(message.payload.data).data.cmd == "close_voice") {
							//   let newData = JSON.parse(message.payload.data).data;
							//   for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
							//     if (
							//       that.roomDetail.normalPositions[i].userPositionId ==
							//       newData.positionId
							//     ) {
							//       that.roomDetail.normalPositions[i].microphone = 1;
							//       if (that.userInfo.id == newData.userId) {
							//         that.$trtc.startLocalAudio();
							//       }
							//     }
							//     that.$forceUpdate();
							//   }
							// }
							// 设置管理
							// set_manager
							if (JSON.parse(message.payload.data).data.cmd == "set_manager") {
								let newData = JSON.parse(message.payload.data).data;
								that.othersData = newData;
								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.userRole = 2;
									that.userRole = 2;
								}
								that.getRoomPcRankList(1);
								let enterRoom = {
									data: {
										userName: newData.targetUserName,
										enter: 4,
										operate: 1,
										type: 2,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};

								that.$refs.roomRight.getGiftData(enterRoom);
							}
							// up_rich_wheat
							if (JSON.parse(message.payload.data).data.cmd == "up_rich_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								clearInterval(that.pkCountDown);
								that.roomDetail.richVO.userName = newData.userName;
								that.roomDetail.richVO.userAvatar = newData.userAvatar;
								that.roomDetail.richVO.userId = newData.userId;
								that.roomDetail.richVO.endTime = newData.endTime;
								that.pkCountDown = window.setInterval(function() {
									let neiDataTime = new Date().getTime(); //初始化当前时间
									let pkEndTime = Date.parse(newData.endTime);
									that.calculateCountDown(neiDataTime, pkEndTime);
								}, 1000);
							}
							// // rich_down_wheat 神豪位下麦
							if (
								JSON.parse(message.payload.data).data.cmd == "rich_down_wheat"
							) {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.richVO.userName = newData.userName;
								that.roomDetail.richVO.userAvatar = newData.userAvatar;
								that.roomDetail.richVO.userId = newData.userId;
								that.roomDetail.richVO.endTime = newData.endTime;
							}
							// 进入房间
							if (JSON.parse(message.payload.data).data.cmd == "enter_room" && that.userInfo.id != JSON.parse(message.payload.data).data.userId) {
								let newData = JSON.parse(message.payload.data).data;
								that.hotNum = newData.hotNum;
								let enterRoom = {
									data: {
										userName: JSON.parse(message.payload.data).data.userName,
										logo: JSON.parse(message.payload.data).data.logo,
										enter: 1,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};
								// this.pkMinute
								// this.pkSecond
								// that.calculateCountDown

								that.$refs.roomRight.getGiftData(enterRoom);

								if (that.roomSetData.allIn != 1) {
									if (newData.bubble) {
										that.giftPopUpShow = true;
										that.giftQueueItem = newData.bubble;
										console.log('待播放的礼物2',that.giftQueueItem);
										that.getGiftQueue();
									}
								}
								if (
									that.roomDetail.resultCode == 6 && that.roomDetail.userRole == 0
								) {
									that.applyRemind(newData);
								}

								that.getRoomPcRankList(1);
							}
							// 退出房间
							if (JSON.parse(message.payload.data).data.cmd == "exit_room") {
								let newData = JSON.parse(message.payload.data).data;
								if (newData.userId == that.userInfo.id) {
									let promise1 = that.chat.quitGroup(that.roomId);
									promise1
										.then(function(imResponse) {
											// that.chat.destroy();
											that.trtcA1.exitRoom();
											that.$router.push("/home");
										})
										.catch(function(imError) {
											that.trtcA1.exitRoom();
											that.$router.push("/home");
											console.warn("quitGroup error:", imError); // 退出群组失败的相关信息
										});
								}
								if (that.concertUserLisr && that.concertUserLisr.length > 0) {
									that.concertUserLisr.forEach((item, index) => {
										if (item.userId == newData.userId) {
											that.concertUserLisr.splice(index, 1);
										}
									});
								}
								if (that.othersData.userId == newData.userId) {
									that.userGiveAwayType = false
									that.othersData = {}
								}
								if (that.theWheatData.userId == newData.userId) {
									that.userGiveAwayType = false
									that.theWheatData = {}
								}
								that.getRoomPcRankList(1);
							}
							// 禁言mute
							if (JSON.parse(message.payload.data).data.cmd == "mute") {
								let newData = JSON.parse(message.payload.data).data;

								that.roomDetail.normalPositions.forEach((item) => {
									if (
										item.userId == newData.targetUserId &&
										newData.targetUserId == that.userInfo.id
									) {
										that.$message("您被禁言");
										// that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
										that.trtcA1.stopLocalAudio();
									}
								});
								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.forbidSpeach = 1;
								}
								let enterRoom = {
									data: {
										userName: newData.targetUserName,
										enter: 4,
										type: 4,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};
								that.$refs.roomRight.getGiftData(enterRoom);
								that.getRoomPcRankList(1);
							}
							// 解言unMute
							if (JSON.parse(message.payload.data).data.cmd == "unMute") {
								let newData = JSON.parse(message.payload.data).data;

								that.roomDetail.normalPositions.forEach((item) => {
									if (
										item.userId == newData.targetUserId &&
										newData.targetUserId == that.userInfo.id
									) {
										that.$message("您已解言");
										// that.trtcA1.switchRole(TRTC.TYPE.ROLE_AUDIENCE);
										that.trtcA1.startLocalAudio();
									}
								});
								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.forbidSpeach = 0;
								}
								let enterRoom = {
									data: {
										userName: newData.targetUserName,
										enter: 4,
										type: 5,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};
								that.$refs.roomRight.getGiftData(enterRoom);
								that.getRoomPcRankList(1);
							}
							// 切换房间模式
							if (JSON.parse(message.payload.data).data.cmd == "mode_switch") {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.resultCode = newData.type;
								if (newData.type == 4) {
									that.loveStage = 0;
									that.roomDetail.roomLoveVO = newData.roomLoveVO;
								}
								if (newData.type == 2) {
									that.pkStatus = 1;
								}
								// that.trtcA2.exitRoom();
								// that.trtcA2.stopLocalAudio()
								// that.trtcB2.exitRoom();
								// that.trtcB2.stopLocalAudio()
							}
							// 清屏
							if (JSON.parse(message.payload.data).data.cmd == "clear_message") {
								that.$refs.roomRight.chatContentData = [];
							}
							// love_change
							if (JSON.parse(message.payload.data).data.cmd == "love_change") {
								let newData = JSON.parse(message.payload.data).data;
								that.roomDetail.roomLoveVO = newData.roomLoveVO;
								that.loveStage = newData.roomLoveVO.activityStage;
								if (
									that.roomDetail.roomLoveVO &&
									that.roomDetail.roomLoveVO.activityStage == 2
								) {
									that.commonWheatType = true;
									that.loveTime = new Date(
										that.roomDetail.roomLoveVO.trueLoveDate
									).getTime();
									let atPresent = new Date().getTime();
									let ssdate = that.loveTime - atPresent;

									if (ssdate > 0) {
										if (!that.downTime) {
											that.downTime = window.setInterval(function() {
												that.countDownTime();
											}, 1000);
										}
									} else {
										if (that.downTime) {
											window.clearInterval(that.downTime);
											this.downTime = null;
										}
									}
								}
								if (newData.roomLoveVO.activityStage == 0) {
									that.loveStage = 0;
								}
							}
							// 打开闭麦位
							// open_position
							if (JSON.parse(message.payload.data).data.cmd == "open_position") {
								let newData = JSON.parse(message.payload.data).data;
								that.getMicrophoneDetail();
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.position
									) {
										that.roomDetail.normalPositions[i].positionIsClose = 0;
										that.$forceUpdate();
									}
								}
							}

							// 房房pk invite_pk
							if (JSON.parse(message.payload.data).data.cmd == "invite_pk") {
								let newData = JSON.parse(message.payload.data).data;
								if (newData.userAvatarList) {
									newData.newAvatarList = newData.userAvatarList.split(",");
								} else {
									newData.newAvatarList = [];
								}
								that.pkInvitationData = newData;
								that.pkInvitationShow = true;
							}
							// 设置闭麦位
							if (JSON.parse(message.payload.data).data.cmd == "close_position") {
								let newData = JSON.parse(message.payload.data).data;
								that.getMicrophoneDetail();
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.position
									) {
										that.roomDetail.normalPositions[i].positionIsClose = 1;
										that.$forceUpdate();
									}
								}
							}

							// 拒绝上麦 refuse_invite_wheat
							if (
								JSON.parse(message.payload.data).data.cmd == "refuse_invite_wheat"
							) {
								let newData = JSON.parse(message.payload.data).data;
								if (that.userInfo.id == newData.fromUserId) {
									that.$message(newData.userName + "拒绝了您的上麦申请");
								}
							}
							// 解锁麦位
							if (JSON.parse(message.payload.data).data.cmd == "unlock_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								that.getMicrophoneDetail();
								if (that.roomDetail.hostVO.userPositionId == newData.positionId) {
									that.roomDetail.hostVO.status = 0;
								}
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.positionId
									) {
										that.roomDetail.normalPositions[i].status = 0;
										that.$forceUpdate();
									}
								}
							}
							// 发送消息

							if (JSON.parse(message.payload.data).data.cmd == "chat_msg") {
								let newData = JSON.parse(message.payload.data).data;
								let data = {
									data: {
										enter: 3,
										content: newData.content,
										logo: newData.logo,
										userName: newData.userName,
										bubble: newData.bubble,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};
								that.$refs.roomRight.getGiftData(data);
							}
							// 取消排麦
							if (JSON.parse(message.payload.data).data.cmd == "voice_cancel") {
								let newData = JSON.parse(message.payload.data).data;
								that.getRoomPcRankList(1);
							}

							if (JSON.parse(message.payload.data).data.cmd == "lock_wheat") {
								let newData = JSON.parse(message.payload.data).data;
								that.getMicrophoneDetail();
								if (that.roomDetail.hostVO.userPositionId == newData.positionId) {
									that.roomDetail.hostVO.status = 2;
								}
								for (let i = 0; i < that.roomDetail.normalPositions.length; i++) {
									if (
										that.roomDetail.normalPositions[i].userPositionId ==
										newData.positionId
									) {
										that.roomDetail.normalPositions[i].status = 2;
										that.$forceUpdate();
									}
								}
							}
							// 退出房间
							// 踢出房间
							if (JSON.parse(message.payload.data).data.cmd == "kick_user") {
								let newData = JSON.parse(message.payload.data).data;
								let enterRoom = {
									data: {
										userName: newData.targetUserName,
										enter: 4,
										type: 3,
										userId: newData.userId,
										vipFlag:newData.vipFlag || ''
									},
								};
								that.$refs.roomRight.getGiftData(enterRoom);
								that.othersHomeShow = false;
								that.othersHomeShowLiao = false;
								that.kickOutShow = false;
								that.getRoomPcRankList(1);
								if (
									that.userInfo.id ==
									JSON.parse(message.payload.data).data.targetUserId
								) {
									that.$message.info("你被踢出房间");
									that.trtcA1.exitRoom();
									that.$router.push({
										path: "/home",
									});
								}
							}
							// 抱他上麦
							if (JSON.parse(message.payload.data).data.cmd == "invite_up_wheat") {
								if (that.userInfo.id == JSON.parse(message.payload.data).data.roomUserId) {
									that.inviteData = JSON.parse(message.payload.data).data;
									that.wheatInviteShow = true;
								}
							}
							// 对讲机模式 set_Intercom
							if (JSON.parse(message.payload.data).data.cmd == "set_Intercom") {
								let newData = JSON.parse(message.payload.data).data;
								if (newData.isIntercom == 1) {
									that.trtcA1.stopLocalAudio();
									window.addEventListener("keydown", that.keyDownFun);
									window.addEventListener("keyup", that.keyUpFun);
								} else {
									for (
										let i = 0; i < that.roomDetail.normalPositions.length; i++
									) {
										if (that.roomDetail.normalPositions[i].status == 1) {
											if (
												that.roomDetail.normalPositions[i].userId ==
												that.userInfo.id
											) {
												that.trtcA1.startLocalAudio();
											}
										}
									}
									// that.roomDetail.normalPositions.forEach(item)
									window.removeEventListener("keydown", that.keyDownFun);
									window.removeEventListener("keyup", that.keyUpFun);
								}
							}
							// 送礼物逻辑

							if (JSON.parse(message.payload.data).data.cmd == "send_gift") {
								let newData = JSON.parse(message.payload.data).data;
								that.hotNum = newData.hotNum;
								that.$refs.roomRight.roomRanking();
								newData.positionInfo.forEach((item) => {
									if (item.order == 0) {
										that.roomDetail.hostVO.giftValueStr = item.goldGiftStr;
									}
								});
								// 如果关闭礼物特效声音，将特效文件改成静音文件
								if (that.roomSetData.allSpecialVoice == 1) {
									newData.giftPcSvga = newData.giftPcMuteSvga || newData.giftPcSvga
								}
								if (newData.roomTheme == "点唱厅房间PK模式") {
									that.pkTopThreeData = newData.roomVoiceOutPKVO;
									that.roomDetail.roomPkOutVO.redHostGiftValueStr =
										newData.roomVoiceOutPKVO.redHostGiftValueStr;
									that.roomDetail.roomPkOutVO.blueHostGiftValueStr =
										newData.roomVoiceOutPKVO.blueHostGiftValueStr;
									let sumUp =
										newData.roomVoiceOutPKVO.blueTeamValue +
										newData.roomVoiceOutPKVO.redTeamValue;
									if (sumUp == 0) {
										that.pkRedDutyCycle = 50;
									} else {
										that.pkRedDutyCycle = that.toPercent(
											newData.roomVoiceOutPKVO.redTeamValue,
											sumUp
										);
									}
									if (that.roomDetail.roomPkOutVO.pkStatus == 2) {
										for (
											let i = 0; i < that.roomDetail.normalPositions.length; i++
										) {
											for (let j = 0; j < newData.positionInfo.length; j++) {
												if (
													that.roomDetail.normalPositions[i].userPositionId ==
													newData.positionInfo[j].positionId
												) {
													that.roomDetail.normalPositions[i].giftValueStr =
														newData.positionInfo[j].goldGiftStr;
													that.$forceUpdate();
												}
											}
										}
									}
								}
								if (newData.roomTheme == "点唱厅房内PK模式") {
									that.pkTopThreeData = newData.roomVoiceInPKVO;
									let sumUp =
										newData.roomVoiceInPKVO.blueTeamValue +
										newData.roomVoiceInPKVO.redTeamValue;

									if (sumUp == 0) {
										that.pkRedDutyCycle = 50;
									} else {
										that.pkRedDutyCycle = that.toPercent(
											newData.roomVoiceInPKVO.redTeamValue,
											sumUp
										);
									}
									if (that.pkStatus == 2) {
										for (
											let i = 0; i < that.roomDetail.normalPositions.length; i++
										) {
											for (let j = 0; j < newData.positionInfo.length; j++) {
												if (
													that.roomDetail.normalPositions[i].userPositionId ==
													newData.positionInfo[j].positionId
												) {
													that.roomDetail.normalPositions[i].giftValueStr =
														newData.positionInfo[j].goldGift;
													that.$forceUpdate();
												}
											}
										}
									}
								}
								if (that.roomDetail.resultCode == 6) {
									that.roomDetail.normalPositions.forEach((item) => {
										newData.positionInfo.forEach((item1) => {
											if (item.userId == item1.userId) {
												item.giftValue = item1.giftValue;
												item.giftValueStr = item1.giftValueStr;
											}
										});
									});
								}
								if (that.roomId == that.roomId) {
									console.log(111111111111);
									that.roomDetail.normalPositions.forEach((item) => {
										newData.positionInfo.forEach((item1) => {
											if (item.userPositionId == item1.positionId) {
												item.giftValueStr = item1.goldGiftStr;
												item.giftValue = item1.goldGift;
											}
										});
									});
									let newGiftdata = {
										data: {
											enter: 2,
											cmd: "send_gift",
											userName: newData.sendUserName, //发送者名称
											userId: newData.sendUserId, //发送者Userid
											toUserName: newData.toUserName, //接收者名称,如果送多人用,隔开
											toUserID: newData.toUserId, //接受者userid 如果送多人用,隔开
											giftName: newData.giftName, //礼物名称
											giftNumber: newData.giftNum, //礼物数量
											giftLogo: newData.giftLogo, //礼物图片
											giftSvga: newData.giftSvga, //礼物特效
											giftPcSvga: newData.giftPcSvga, //礼物特效
											sendUserId: newData.sendUserId, //发送者Userid
										},
									};

									if (newData.giftPcSvga) {
										that.giftQueueItem = newData.giftPcSvga;
										that.giftPopUpShow = true;
										console.log('待播放的礼物1',that.giftQueueItem);
									} else {
										that.giftQueueItem = newData.giftSvga;
										that.giftPopUpShow = true;
										console.log('待播放的礼物1',that.giftQueueItem);
									}
									that.getGiftQueue(); //礼物动画播放
									that.$refs.roomRight.getGiftData(newGiftdata); //zwp  1 -10 送礼物应该  先走接口 在发送成功
								}
							}
							// 设置超管
							if (
								JSON.parse(message.payload.data).data.cmd == "set_super_manager"
							) {
								let newData = JSON.parse(message.payload.data).data;

								if (that.userInfo.id == newData.targetUserId) {
									that.roomDetail.userRole = 1;
									that.userRole = 1;
								}
								that.getRoomPcRankList(1);
							}
							// 全频广告
							if (JSON.parse(message.payload.data).data.cmd == "text_msg") {
								let newData = JSON.parse(message.payload.data).data;
								that.getRoomPcRankList(1);
							}
							// 设置任务set_host_gift
							if (JSON.parse(message.payload.data).data.cmd == "set_host_gift") {
								let newData = JSON.parse(message.payload.data).data;
								that.getHostTask();
							}
						}
						// 自定义消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.CustomPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_MERGER) {
						// 合并消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.MergerPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_LOCATION) {
						// 地理位置消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.LocationPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_GRP_TIP) {
						// 进入房间
						if (message.payload.operatorID == that.userInfo.id) {
							let enterRoom = {
								data: {
									userName:that.userInfo.nickname,
									logo: that.userInfo.backImg,
									enter: 1,
									userId: that.userInfo.id,
									vipFlag:that.userInfo.id.vipFlag || ''
								},
							};
						
							that.$refs.roomRight.getGiftData(enterRoom);
						
							if (that.roomSetData.allIn != 1) {
								if (that.userInfo.approachShow) {
									that.giftPopUpShow = true;
									that.giftQueueItem = that.userInfo.approachShow;
									console.log('待播放的礼物2',that.giftQueueItem);
									that.getGiftQueue();
								}
							}
						}
						that.getRoomPcRankList(1);
						// 群提示消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupTipPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE) {
						// 群系统通知 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupSystemNoticePayload
					}
				});
			};
			this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);


			let onNetStateChange = function(event) {

				if (event.data.state == TencentCloudChat.TYPES.NET_STATE_DISCONNECTED) {
					taht.skipHome()
				}

			};
			this.chat.on(TencentCloudChat.EVENT.NET_STATE_CHANGE, onNetStateChange);

			let then = this;
			window.onunload = function() {
				self.location = document.referrer;
				this.$trtc.exitRoom();
				then.$api.signOutRoom({
					roomId: then.roomId
				}).then((res) => {
					if (res.code == 1) {
						return then.$router.push("/home");
					} else {
						then.$message.error(res.msg);
					}
				});
			};
			//页面后退时触发监听
			// window.addEventListener(
			//   "popstate",
			//   function (e) {
			//     then.$api.signOutRoom({ roomId: then.roomId });
			//     // this.$trtc.exitRoom();
			//   },
			//   false
			// );
		},
		mounted() {
			this.gitImg()
			window.addEventListener("beforeunload", (e) => this.beforeunloadFn());
			this.liveStreamId = this.$route.query.liveStreamId;
			this.getFriendGroupList();
			this.getMyDiamond();
			this.getGiftList();
			this.scrollInit();
			this.getRoomPcRankList(1);
			this.getmicrophoneType();
			this.getSimplePositionInfoByRoomId();
			this.getXiaoxiNum();
			this.emceeTaskShow = false;
			let thenAt = this;
			// 清除定时器
			window.clearInterval(thenAt.pkCountDown);
			window.clearInterval(this.downTime);
			thenAt.this = null;
			thenAt.pkCountDown = null;
			this.$api.getAgreement({
				configName: "bindBox"
			}).then((res) => {
				if (res.code == 1) {
					this.blindBoxBtnShow = res.data;
				}
			});
			let that = this;
			window.addEventListener('offline', () => {
				this.$message.info('网络断开')
			});
		},

		beforeUnmount() {
			let thenAt = this;
			window.clearInterval(thenAt.pkCountDown);
			thenAt.pkCountDown = null;
		},
		watch: {
			'$route.query.roomId'(newV) {
				location.reload()
			},
			passwordName(newV) {
				let arr = newV.split(""); //转为数组
				if (arr.length > 4) {
					arr = arr.splice(0, 4); //只取六位
				}
				this.place = arr.length; //更新指示符的位置
				this.password = ["", "", "", ""];
				arr.map((item, index) => {
					this.password[index] = item;
				});
			},
		},
		beforeunload() {
			// window.open('https://www.baidu.com/', '_bank')
			this.$trtc.exitRoom();
			this.$api.signOutRoom({
				roomId: this.roomId
			});
		},
		destroyed() {
			let thenAt = this;
			window.removeEventListener("beforeunload", (e) => this.beforeunloadFn());
			this.$trtc.exitRoom();
			this.$api.signOutRoom({
				roomId: this.roomId
			});
			// 清除定时器
			window.clearInterval(thenAt.pkCountDown);
			window.clearInterval(thenAt.downTime);

			window.removeEventListener('offline');
		},
		methods: {
			//获取下载二维码
			gitImg() {
				this.$api.getConfig({
					configGroup: "appiosimage"
				}).then((res) => {
					if (res.code == 1) {
						this.download = res.data;
					}
				});
			},
			// 按住说话
			beforeunloadFn() {
				this.$trtc.exitRoom();
				this.$api.signOutRoom({
					roomId: this.roomId
				});
			},
			// 重置麦位
			resetWheat() {
				let roomLibList = []; //融云最新麦位
				let roomAdminList = []; //后台最新麦位
				this.$RCVoiceRoomLib.getLatestSeatInfo().then((res) => {
					roomLibList = res;
				});
				this.$api.roomPostrionMesage({
					roomId: this.roomId
				}).then((res) => {
					roomAdminList = res;
				});
				let ifWheat = 0;
				for (let i = 0; i < roomLibList.length; i++) {
					if (roomLibList[i].userId == this.userInfo.id) {
						for (let j = 0; j < roomAdminList.length; j++) {
							if (roomAdminList[j].userId == this.userInfo.id) {
								return (ifWheat = 1);
							}
						}
						if (ifWheat == 0) {
							this.$RCVoiceRoomLib.leaveSeat();
						}
					}
				}
			},
			getFriendGroupList() {
				this.$api.friendGroupList().then((res) => {
					res.data.forEach((item) => {
						item.label = item.groupingName;
						item.value = item.id;
					});
					this.goodsFen = res.data;
				});
			},
			BtnSubmit() {
				this.$api
					.followUser({
						followUserIds: this.othersData.userId,
						groupId: this.FenValue,
					})
					.then((res) => {
						if (res.code == 1) {
							this.othersData.followFlag = 1;
							this.isGuan = false;
						} else {
							this.$message.info(res.msg)
						}
					});
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
			},
			async roompk() {
				let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
				let TencentCloudChat = this.TencentCloudChat;
				this.trtcA1 = this.TRTC.create({
					plugins: [CDNStreaming]
				});
				try {
					await this.trtcA1.enterRoom({
						strRoomId: this.roomId,
						scene: "live",
						sdkAppId: this.$SDKAppID,
						userId: userTIMInfo.id,
						userSig: userTIMInfo.userSig,
						streamId: this.roomId,
						role: this.TRTC.TYPE.ROLE_ANCHOR,
					});
					let promise = this.chat.joinGroup({
						groupID: this.roomId,
						type: TencentCloudChat.TYPES.GRP_AVCHATROOM,
					});
					promise
						.then(function(imResponse) {
							switch (imResponse.data.status) {
								case TencentCloudChat.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
									break;
								case TencentCloudChat.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
									break;
								case TencentCloudChat.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
									break;
								default:
									break;
							}
						})
						.catch(function(imError) {
							console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
						});
				} catch (err) {
					this.$router.go(0);
				}
			},
			// 确认跳转聊天室
			affirmSkipRoom() {
				this.$router.replace({
					path: "/someSingingHall",
					query: {
						roomId: this.adverseData.id,
						liveStreamId: this.adverseData.liveStreamingId,
					},
				});
				this.jumpPopoverShow = false;
				this.reload();
			},
			// 跳转聊天室
			skipRoom(i) {
				this.adverseData = i;
				if (i.id == this.roomId) return
				this.jumpPopoverShow = true;
			},
			focussearchRoomChange() {
				if (this.keyword) {
					setTimeout(() => {
						this.searchRoomChange()
					}, 300)
				}
			},
			// 搜索大神与聊天室
			searchRoomChange() {
				this.searchPopupShow = true;
				let data = {
					pageNum: 1,
					pageSize: 999,
					key: this.keyword
				};
				// 搜索聊天室
				this.$api
					.searchRoomList({
						pageNum: 1,
						pageSize: 4,
						key: this.keyword
					})
					.then((res) => {
						if (res.code == 1) {
							this.searchRoomData = res.data.rows;
						}
					});
				// 搜索大神
				this.$api.searchPlayerList(data).then((res) => {
					if (res.code == 1) {
						this.searchPlayerData = res.data.rows;
					}
				});
			},
			// 点击复制
			copyUserId(id) {
				var input = document.createElement("input"); // 创建input对象
				input.value = id; // 设置复制内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$message.success("复制成功！");
			},
			userClickWheat() {
				if (this.userRole == 6) {
					this.comperepopoverShow = !this.comperepopoverShow;
				} else {
					if (this.whetherWheat == 1) {
						this.$message("已在麦上，请勿重复点击！");
					} else if (
						this.roomDetail.userRole == 0 ||
						this.roomDetail.userRole == 1
					) {
						this.comperepopoverShow = !this.comperepopoverShow;
					} else {
						// this.comperepopoverShow = !this.comperepopoverShow;
						this.$message("您不是房主或者超管不能进行主持人操作");
					}
				}
			},
			// 跳转勋章墙
			skipMedalWall(id) {
				this.manitoId = id;
				this.othersType = 4;
				this.othersShow = true;
			},
			// 获取会话
			getStatus() {
				let that = this;
				let onTotalUnreadMessageCountUpdated = function(event) {
					that.getXiaoxiNum();
				};
				this.chat.on(
					this.TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
					onTotalUnreadMessageCountUpdated
				);
			},
			// 显示神豪位提示
			deityRichRuleShowChange() {
				this.$api.queryByConfigGroup({
					configGroup: "god_group"
				}).then((res) => {
					if (res.code == 1) {
						this.divineData = res.data;
						this.deityRichRuleShow = !this.deityRichRuleShow;
					}
				});
			},
			async downGoShu(url) {
				this.loadings = true;
				let response = await fetch(url);
				// 内容转变成blob地址
				let blob = await response.blob();
				// 创建隐藏的可下载链接
				let objectUrl = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.style.display = "none";
				//地址
				a.href = objectUrl;
				//修改文件名
				a.download = name;
				// 触发点击
				document.body.appendChild(a);
				a.click();
				//移除
				URL.revokeObjectURL(a.href);
				this.loadings = false;
			},
			// 清麦
			Chiengmai() {
				this.$confirm("您确定要执行此操作吗？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$api.clearWheat({
							roomId: this.roomId
						}).then((res) => {
							if (res.code == 1) {
								// this.$RCVoiceRoomLib.notifyVoiceRoom("clear_microphone"); //清除麦位
								// if (this.whetherWheat == 1) {
								//   this.userLeaveSeat();
							}
						});
					})
					.catch(() => {
						// 用户点击了取消按钮，取消对应操作
					});
			},
			dotSingPopUp() {
				this.getVoice();
				this.someSingingShow = true;
				this.compereType == false;
			},
			dotDianPopUp() {
				this.wheatRemindShowBtn();
			},
			//用户点击麦位
			userClick(key, item) {
				if (this.userRole == 6) {
					this.popoverShow = key;
				} else {
					if (this.whetherWheat == 1) {
						this.$message("已在麦上，请勿重复点击！");
						return;
					}

					if (this.roomDetail.userRole == 3 || this.roomDetail.userRole == 2) {
						if (item.status == 2) {
							this.$message("麦位已锁");
						} else {
							this.userPositionId = item;
							this.wheatRemindShow = true;
						}
					} else {
						this.popoverShow = key;
					}
				}

				// if (this.roomDetail.userRole == 3) {
				//   this.wheatRemindShow = true;
				// } else {
				//   // this.popoverShow = key;
				// }

				// else{

				// }
			},
			/**
			 * 静音所有远程音频流
			 *
			 * @param muteType 是否静音
			 */
			allMute() {
				this.muteType = !this.muteType;
				this.$RCVoiceRoomLib.muteAllRemoteStreams(this.muteType);
			},
			// 显示设置礼物任务弹窗
			addTaskChange() {
				this.addTaskShow = !this.addTaskShow;
			},
			// 显示
			showChange(type, id) {
				switch (type) {
					case "ranking": // 排行榜显示
						this.ranking = !this.ranking;
						break;
					case "personal": //个人中心
						this.personalShow = !this.personalShow;
						this.$nextTick(() => {
							this.$refs.personal.setLeft(4);
						});

						break;
					default:
						break;
				}
			},
			// 获取消息未读数
			getXiaoxiNum() {
				let num = this.chat.getTotalUnreadMessageCount();
				if (typeof num === "number") {
					this.$api.unreadCount().then((res) => {
						if (res.code == 1) {
							res.data.forEach((item) => {
								this.totalUnreadCount = num + item.unReadCount;
							});
						}
					});
				}

				//  =this.chat.getTotalUnreadMessageCount()
			},
			// 编辑公告
			saveAffiche() {
				this.$api
					.chatRoomAnnouncement({
						roomId: this.roomId,
						announcement: this.afficheValue,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.roomDetail.announcement=this.afficheValue
							this.editBillboardShow = false;
						}
					});
			},
			// 私聊
			privateLetter(i) {
				i.userProfile = {
					userID: i.id,
				};
				i.type = 'C2C'
				this.$refs.myMessage.selectUserChat(i, "", true);
				this.myMessageShow = true;
				this.wheatHomeShow = false;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
				// this.$refs.myMessage.selectUserChat(i);
				// this.$emit("closeOthers");

			},
			pageBtn() {
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				// this.roomFunctionShow=!this.roomFunctionShow
				this.wheatHomeShow = false;
			},
			// 显示举报弹窗
			reportChangeShow(type) {
				this.queryReportReason();
				this.reportType = type;
				this.reportShow = true;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				// this.roomFunctionShow=!this.roomFunctionShow
				this.wheatHomeShow = false;
			},
			userReportChange() {
				this.queryReportReason();
				this.reportType = 1;
				this.reportShow = true;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				// this.roomFunctionShow=!this.roomFunctionShow
				this.wheatHomeShow = false;
			},
			// 举报原因
			queryReportReason() {
				this.$api.queryReportReason().then((res) => {
					if (res.code == 1) {
						this.reportOptions = res.data;
					}
				});
			},
			// 举报弹窗图片上传
			reportImgSuccess(res) {
				if (res.code == 1) {
					this.reportImgs.push(res.data.url);
				}
			},
			// 清除举报弹窗
			clearPopup() {
				this.reportTextarea = "";
				this.reportValue = "";
				this.reportImgs = [];
			},
			// 举报房间&&用户
			addRoomReport() {
				if (this.reportValue) {} else {
					this.$message("请选择举报原因");
					return;
				}
				if (this.reportTextarea) {} else {
					this.$message("请填写举报内容");
					return;
				}
				//1举报用户 2举报房间
				if (this.reportType == 1) {
					let data = {
						content: this.reportTextarea,
						image: this.reportImgs.toString(),
						reason: this.reportValue,
						roomId: this.reportType == 2 ? this.roomId : this.othersData.id,
						type: 1, //举报类型（1-用户 2-房间）
					};
					this.$api.roomReport(data).then((res) => {
						if (res.code == 1) {
							this.clearPopup();
							this.reportShow = false;
							this.$message.success(res.msg);
						} else {
							this.$message.error(res.msg);
						}
					});
				} else {
					// 举报房间
					let data = {
						content: this.reportTextarea,
						image: this.reportImgs.toString(),
						reason: this.reportValue,
						roomId: this.roomId,
						type: 2, //举报类型（1-用户 2-房间）
					};
					this.$api.roomReport(data).then((res) => {
						if (res.code == 1) {
							this.clearPopup();
							this.reportShow = false;
							this.$message.success(res.msg);
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			// 天 时 分 秒 格式化函数 相亲
			countDownTime() {
				let atPresent = new Date().getTime();
				let ssdate = this.loveTime - atPresent;

				let secondNum = parseInt(ssdate / 1000);
				let s = secondNum < 10 ? "0" + secondNum : secondNum;
				this.loveTimeCount = s;

				let thenAt = this;
				if (secondNum < 0) {
					window.clearInterval(this.downTime);
					this.downTime = null;
					this.getTrueLoveList();
				}
			},
			// 获取真爱列表
			getTrueLoveList() {
				this.$api
					.getTrueLoveList({
						roomId: this.roomId,
						activityStageId: this.roomDetail.roomLoveVO.activityStageId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.trueLoveList = res.data;
							if (this.trueLoveList.length > 0) {
								this.trueLoveShow = true;
								this.trueLoveNum = 0;
								this.trueLoveChange();
							} else {
								this.trueLoveShow = false;
								this.$message("本次活动没有人牵手成功!");
							}
							// this.trueLoveTime = setInterval(()=>{
							//   this.trueLoveChange()
							// },10000)
						}
					});
			},
			// 公布真爱
			trueLoveChange() {
				if (this.trueLoveList.length > this.trueLoveNum) {
					let then = this;
					// window.clearTimeout(this.trueLoveTime);
					then.trueLoveTime = window.setTimeout(function() {
						then.trueLoveNum = then.trueLoveNum + 1;
						then.trueLoveChange();
					}, 9000);
				} else {
					this.trueLoveShow = false;
				}
				// if (this.trueLoveNum > this.trueLoveList.length) {
				//   this.trueLoveShow = false;
				//   // clearInterval(this.trueLoveTime);
				//   // this.trueLoveTime = null
				// } else {

				// }
			},
			// 相亲模式结束
			loveFinish() {
				if (this.roomDetail.roomLoveVO.activityStage != 2) return;
				let data = {
					activityStageId: this.roomDetail.roomLoveVO.activityStageId, //活动id
					isEnd: 1, //活动是否结束 1：是 0：否
					roomId: this.roomId, //	房间id
				};
				this.$api.finshLoveRoom(data).then((res) => {
					if (res.code == 1) {
						// this.enterRoom();
					}
				});
			},
			// 相亲相爱阶段切换
			modeSwitchChange(type) {
				// if(type <= this.loveStage) return
				if (this.roomDetail.hostVO.userId != this.userInfo.id)
					return this.$message("请等待主持人操作~");
				let data = {
					activityStage: "", //	活动阶段（0-未开始 1-嘉宾交流 2-真爱选择 3-公布真爱）
					activityStageId: "", //	活动id
					roomId: "", //	房间id
				};
				if (type == 1 && this.roomDetail.roomLoveVO.activityStage == 0) {
					data.activityStage = type;
					data.activityStageId = this.roomDetail.roomLoveVO.activityStageId; //	活动id
					data.roomId = this.roomId; //	房间id
				} else if (type == 2 && this.roomDetail.roomLoveVO.activityStage == 1) {
					data.activityStage = type;
					data.activityStageId = this.roomDetail.roomLoveVO.activityStageId; //	活动id
					data.roomId = this.roomId; //	房间id
				} else if (type == 3 && this.roomDetail.roomLoveVO.activityStage == 2) {
					data.activityStage = type;
					data.activityStageId = this.roomDetail.roomLoveVO.activityStageId; //	活动id
					data.roomId = this.roomId; //	房间id
				} else {
					this.$message("请按流程进行操作~");
				}
				// else {
				//   data.activityStage = type;
				//   data.activityStageId = this.roomDetail.roomLoveVO.activityStageId; //	活动id
				//   data.roomId = this.roomId; //	房间id
				// }
				this.$api.changeStage(data).then((res) => {
					if (res.code == 1) {
						//相亲模式
						this.loveStage = this.roomDetail.roomLoveVO.activityStage; //活动阶段（0-未开始 1-嘉宾交流 2-真爱选择 3-公布真爱）
						// this.enterRoom();
						// if (type == 3 && this.roomDetail.loveVO.activityStage == 2) {
						//   this.$RCVoiceRoomLib.notifyVoiceRoom("finish_trueLove"); //相亲相爱模式公布真爱
						// }
						// this.$RCVoiceRoomLib.notifyVoiceRoom("love_modeSwitch"); //相亲相爱模式阶段切换
					}
				});
			},
			// 相亲相爱说明弹窗
			loveEachPopupChange() {
				this.loveEachPopupShow = true;
				this.$api
					.getPlayWithRule({
						configName: "love_room_rule"
					})
					.then((res) => {
						if (res.code == 1) {
							this.loveExplain = res.data;
						}
					});
			},
			// 相亲模式-选择对象
			selectTa(i) {
				let whether = true;
				// 左侧只能选择右侧，右侧相同
				let userOrder = "";
				let meWheat = {};
				this.roomDetail.normalPositions.forEach((item) => {
					if (this.userInfo.id == item.userId) {
						meWheat = item;
						userOrder = item.order;
					}
				});
				if (userOrder < 5) {
					//左侧点击
					if (i.order < 5) {
						whether = false;
						return this.$message.error("请选择对面用户");
					}
				} else if (userOrder > 5) {
					//右侧点击
					if (i.order > 4) {
						whether = false;
						return this.$message.error("请选择对面用户");
					}
				}
				if (!whether) return;
				if (meWheat.isChoose == 1) return this.$message("已选择，请勿重复点击！");
				let data = {
					activityStageId: this.roomDetail.roomLoveVO.activityStageId, //活动id
					choiceId: i.userId, //选择对象id
					positionId: i.userPositionId, //麦位id
					roomId: this.roomId, //房间id
				};
				this.$api.choiceUser(data).then((res) => {
					if (res.code == 1) {
						this.$message.success(res.msg);
					} else {
						this.$message(res.msg);
					}
				});
			},
			// 删除房间背景图
			bgImgChangeDel() {
				this.roomBack = "";
			},
			// 房间背景图上传
			streamImgSuccess(res) {
				if (res.code == 1) {
					this.roomBack = res.data.url;
				}
			},
			// 房间设置 屏蔽弹窗
			shieldingSetChange() {
				this.shieldingSetShow = !this.shieldingSetShow;
				this.roomFunctionShow = false;
			},
			// 房间操作
			roomFunctionShowChange() {
				this.roomFunctionShow = !this.roomFunctionShow;
				this.shieldingSetShow = false;
			},
			// 主持人任务
			getHostTask() {
				let hostId = "";
				// if (this.roomDetail.roomVoiceVO) {
				//   hostId = this.roomDetail.roomVoiceVO.hostId;
				// } else if (this.roomDetail.roomVoiceInPKVO) {
				//   hostId = this.roomDetail.roomVoiceInPKVO.hostId;
				// } else if (this.roomDetail.roomPkOutVO) {
				//   hostId = this.roomDetail.roomPkOutVO.hostId;
				// } else if (this.roomDetail.roomLoveVO) {
				//   hostId = this.roomDetail.roomLoveVO.hostId;
				// } else if (this.roomDetail.roomPartnerVO) {
				//   hostId = this.roomDetail.roomPartnerVO.hostId;
				// } else if (this.roomDetail.roomPlayWithVO) {
				//   hostId = this.roomDetail.roomPlayWithVO.hostId;
				// } else if (this.roomDetail.hostVO) {
				//   hostId = this.roomDetail.hostVO.hostId;
				// }
				this.emceeTaskShow = !this.emceeTaskShow;
				this.$api
					.getHostTask({
						hostId: this.roomDetail.hostVO.userId,
						roomId: this.roomId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.hostTaskList = res.data.roomHostTaskList;
							this.taskStatus = res.data.taskStatus;
						}
					});
			},
			// 设置主持人任务
			setSaveHostGiftTask() {
				if (!this.taskGiftitem.id) return this.$message("请选择礼物");
				if (!this.giftPeriodValue) return this.$message("请选择任务周期");
				let data = {
					giftId: this.taskGiftitem.id,
					hostId: this.roomDetail.hostVO.userId,
					num: this.setGiftNum,
					type: this.giftPeriodValue,
				};
				this.$api.saveHostGiftTask(data).then((res) => {
					if (res.code == 1) {
						this.addTaskShow = false;
						this.taskGiftitem = {};
						this.giftPeriodValue = "";
						this.getHostTask();
					}
					this.$message(res.msg);
				});
			},
			// 删除主持人任务
			removeUserTask(id) {
				this.$api.removeUserTask({
					taskId: id
				}).then((res) => {
					if (res.code == 1) {
						this.addTaskShow = false;
						this.taskGiftitem = {};
						this.giftPeriodValue = "";
						this.getHostTask();
					}
					this.$message(res.msg);
				});
			},
			// 聊天室收藏或取消收藏
			collectRoom() {
				this.$api.collectRoom({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						if (this.roomDetail.isCollect == 0) {
							this.roomDetail.isCollect = 1;
						} else {
							this.roomDetail.isCollect = 0;
						}
					}
				});
			},
			// 分享链接
			share() {
				document.execCommand("Copy");
			},
			// 开黑房点击
			darkRoomChange() {
				this.popoverShow = "-1";
				this.emceeTaskShow = false;
			},
			// 公会房间点击
			GuildRoomChange() {
				this.popoverShow = "-1";
				this.relieveShow = false;
				this.comperepopoverShow = false;
			},
			// 用户同意被抱上麦
			userConsent() {
				let data = {
					liveStreamId: this.liveStreamId,
					targetPositionId: this.embraceWheat.userPositionId,
					targetUserId: this.beInvitedData.userId,
				};
				this.$api.hugUserToPosition(data).then((res) => {
					this.embraceShow = false;
				});
			},
			// 用户处理是否上麦 1同意上麦 0拒绝上麦
			inviteDispose(type) {
				let userType = ""; //accept(同意)  reject(拒绝)
				if (type == 1) {
					userType = "1";
					this.$api.acceptInvite({
						liveStreamId: this.liveStreamId,
						targetPositionId: this.inviteData.targetPositionId,
						type: type,
					});
				} else {
					userType = "2";
					this.$api.acceptInvite({
						liveStreamId: this.liveStreamId,
						targetPositionId: this.inviteData.targetPositionId,
						type: type,
					});
				}
				this.wheatInviteShow = false;
			},
			// 房主同意上麦
			acceptInviteBtn() {
				// agreeOrRefuse
				// let
				// this.$api.acceptUpMicrophone({
				// });
			},
			// 列表直接选择抱上麦
			directEmbraceUser(i) {
				let wheatOrder = {};

				// isBlackRoom 1开黑房
				//personnelType 3点单 4试音
				if (this.isBlackRoom != 1) {
					//   //公会房间
					//   //  allDataList 全部麦数据

					try {
						this.roomDetail.normalPositions.forEach((item) => {
							// item.type 	`麦位类型（0-主持人 1-普通麦位 2-神壕位 3-点单麦位）`
							// item.status	`麦位状态（0-空闲 1-已有人 2-麦位已上锁 ）`
							if (this.personnelType == 3 && item.status == 0) {
								//点单排麦
								wheatOrder = item;
								throw "";
							} else if (this.personnelType == 4 && item.status == 0) {
								//         //试音排麦
								wheatOrder = item;
								throw "";
							} else if (this.personnelType == 1 && item.status == 0) {
								//         //正常排麦
								wheatOrder = item;
								throw "";
							}
						});
					} catch (e) {}
				}
				// else {
				//   //开黑房排麦
				//   this.allDataList.forEach((item) => {
				//     if (item.type == 1 && item.status == 0) {
				//       return (wheatOrder = item);
				//     }
				//   });
				// }
				if (!wheatOrder) return this.$message("麦位已满");
				// this.$RCVoiceRoomLib.notifyVoiceRoom("hugUserToPosition", {
				//   liveStreamId: this.liveStreamId, //直播Id
				//   roomUserId: i.id, //被邀请的UserID
				//   targetPositionId: wheatOrder.id, //麦位ID(取第一个空麦位)
				//   index: wheatOrder.order, //融云麦序（取第一个空麦位）
				//   compereHeadImg: this.userInfo.avatar, // 主持人头像
				// });

				let data = {
					liveStreamId: this.liveStreamId,
					targetPositionId: wheatOrder.userPositionId,
					targetUserId: i.id,
				};
				this.$api.hugUserToPosition(data).then((res) => {
					this.embraceShow = false;
				});
			},
			// 选择用户抱上麦
			embraceChange(i) {
				this.beInvitedData = i;
				let data = {
					liveStreamId: this.liveStreamId,
					targetPositionId: this.embraceWheat.userPositionId,
					targetUserId: this.beInvitedData.userId,
				};
				this.$api.hugUserToPosition(data).then((res) => {
					this.embraceShow = false;
				});
			},
			// 搜索抱用户上麦
			searchChange() {
				this.embraceShowChange();
			},
			// 抱用户上麦弹窗
			embraceShowChange(i, type) {
				// type '' 上普通麦 1主持人麦 2PK模式主持麦
				if (type == 1) {
					this.embraceWheat.userPositionId = i.userPositionId;
					this.embraceWheat.order = 0;
				} else {
					this.embraceWheat = i;
				}
				this.$api
					.onlineUserList({
						liveStreamId: this.liveStreamId,
						searchKey: this.searchUser,
					})
					.then((res) => {
						if (res.code == 1) {
							this.embraceShow = true;
							this.onLineList = res.data;
							console.error(this.onLineList)
							// userId this.userInfo.id
							this.onLineList = this.onLineList.filter((list) => {
								return list.userId != this.userInfo.id;
							})
						}
					});
			},
			//同意用户排麦请求
			acceptUpMicrophone(i, type) {
				// if(this.roomDetail.roomLoveVO.activityStage==2){
				//   this.$message('请等待真爱选择结束哦~')
				//   return
				// }
				let wheatOrder = {};
				// 老板位是否有人
				let bossOrder = false;
				// isBlackRoom 1开黑房
				//personnelType 3点单 4试音
				if (this.isBlackRoom != 1) {
					//公会房间
					try {
						this.roomDetail.normalPositions.forEach((item) => {
							// item.type 	`麦位类型（0-主持人 1-普通麦位 2-神壕位 3-点单麦位）`
							// item.status	`麦位状态（0-空闲 1-已有人 2-麦位已上锁 ）`
							if (this.personnelType == 3 && item.status == 0) {
								//点单排麦
								wheatOrder = item;
								throw "";
							} else if (this.personnelType == 4 && item.status == 0) {
								//试音排麦
								wheatOrder = item;
								throw "";
							} else if (item.status == 0) {
								//正常排麦
								wheatOrder = item;
								throw "";
							}
							if (item.order == 1 && item.userId) {
								bossOrder = true;
							}
						});
					} catch (e) {}
				} else {
					//开黑房排麦
					this.allDataList.forEach((item) => {
						if (item.type == 1 && item.status == 0) {
							return (wheatOrder = item);
						}
					});
				}
				let upMicrop = {
					operateId: i.id + "", //	目标用户id
					type: type,
				};
				this.$api.acceptUpMicrophone(upMicrop).then((res) => {
					if (res.code == 1) {} else {
						this.$message(res.msg);
					}
				});
			},
			//本地麦克风状态
			getmicrophoneType() {
				// this.$RCVoiceRoomLib.isDisableAudioRecording().then((res) => {
				//   this.microphoneType = res;
				// });
			},
			checkUser(item) {
				// cons
				this.newOthersHomeChange(item.data);
			},
			newOthersHomeChange(i) {
				let length = [];
				this.$api
					.getOtherInfo({
						id: i.userId,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							res.data.medalWallVoList.forEach((item) => {
								if (item.isGet == 1) {
									length.push(item);
								}
							});
							res.data.medalWallLength = length.length;
							this.othersHometype = 1;
							this.userGiveAwayType = true;
							this.othersData = res.data;
							this.othersData.userId = res.data.id;
						}
					});
				this.othersHomeShowLiao = true;
			},
			// 类型选择
			labelChange(type) {
				this.labelType = type;
				if (type == 1) {
					this.twolabel = this.gameLabels[0];
				} else {
					this.twolabel = this.amusementLabels[0];
					this.roomform = {
						name: ""
					};
				}
				this.getCreateGameRoomOptions(this.twolabel.id);
			},
			// 热门-游戏-娱乐，三个分类下所有分类
			getAllSecondLabel() {
				this.$api.getAllSecondLabel().then((res) => {
					if (res.code == 1) {
						this.amusementLabels = res.data.amusementLabels;
						this.gameLabels = res.data.gameLabels;
						this.twolabel = this.gameLabels[0];
					}
				});
			},
			// 开黑房管理页
			darkRoomAdminChange() {
				this.darkRoomAdminShow = !this.darkRoomAdminShow;
			},
			// 主页点击赠送礼物
			userGiveAwayChange(type, item) {
				this.othersHometype = type;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
				this.userGiveAwayType = true;
			},
			closeGiveChange() {
				this.userGiveAwayType = false;
				this.othersHometype = "";
			},
			// 点击展示麦位名片
			aloneWheatHomeShow(id) {
				// this.pitchOnWheat = i;
				let length = [];
				this.$api.getOtherInfo({
					id: id,
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						res.data.medalWallVoList.forEach((item) => {
							if (item.isGet == 1) {
								length.push(item);
							}
						});
						res.data.medalWallLength = length.length;
						this.theWheatData = res.data;
					}
				});
				this.wheatHomeShow = !this.wheatHomeShow;
			},
			// 有用户麦位点击
			wheatHomeShowChenge(i, type) {
				//type 1正常麦位 2主持人麦位
				// wheatHomeShow
				this.userRole = this.roomDetail.userRole;
				this.othersData = i;
				if (type == 1) {
					this.pitchOnWheat = i;
				} else {
					this.pitchOnWheat.order = 0;
					this.pitchOnWheat.userId = i.userId;
					this.pitchOnWheat.userPositionId = i.userPositionId;
				}
				this.$api
					.getOtherInfo({
						id: i.userId,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							res.data.medalWallLength = length.length;
							this.theWheatData = res.data;
							this.wheatHomeShow = !this.wheatHomeShow;
						}
					});
			},
			// 用户在麦位上锁麦时点击
			lockWheatChange() {
				this.userLeaveSeat(1);
				let data = {
					isLock: 0, //锁定或解锁（0-锁定 1-解锁）
					liveStreamId: this.liveStreamId, //房间直播id
					targetPositionId: this.pitchOnWheat.userPositionId, //目标麦位id
				};
				this.$api.lockPosition(data).then((res) => {});
				let isLocked = true;
				let seatIndex = this.pitchOnWheat.order;
				// 融云 锁定指定索引的麦位  seatIndex 麦位序号  isLocked 是否锁麦位
				this.$RCVoiceRoomLib.lockSeat(seatIndex, isLocked).then((res) => {});
			},
			// 选择全部人员赠送礼物
			allUserPitchChange() {
				this.getMicphoneUser();
			},
			// 礼物赠送人员选择
			userPitchChange(opn, index) {
				let item = this.roomMicrophoneList[index];
				item.pitch = !item.pitch;
				this.$set(this.roomMicrophoneList, index, item);
			},
			// 礼物选择
			giftItemChenge(i) {
				console.log(i,'/////////')
				// this.giftQueueItem = i;
				this.giftItemType = i;
			},
			// 设置礼物任务选择礼物
			setGiftItemChange(i) {
				this.taskGiftitem = i;
			},
			// 礼物任务数量
			amendNumAddSubtract(type) {
				if (type == 1) {
					//加
					this.setGiftNum++;
					if (this.setGiftNum > 99) {
						this.setGiftNum = 99;
					}
				} else if (type == 2) {
					//减
					this.setGiftNum--;
					if (this.setGiftNum < 1) {
						this.setGiftNum = 1;
						this.$message("至少选择一个礼物~");
					}
				}
			},
			// 关闭礼物弹窗
			closeSvgaPopUpChange() {
				console.log('礼物动画播放结束剩余' + (this.giftQueue.length - 1) + "个动画");
				this.giftSvgaPlayShow = false;
				let that = this;
				this.$refs.newGiftSvga.player.stopAnimation();
				this.giftQueue = this.giftQueue.slice(1);
				setTimeout(() => {
					if (this.giftQueue.length > 0) {
						this.getGiftQueue();
					}
				}, 50);
			},
			// 礼物动画播放队列
			getGiftQueue() {
				console.log('礼物动画播放开始',this.giftQueueItem);
				if (this.giftPopUpShow) {
					if (this.giftQueueItem) {
						this.giftQueue.push(this.giftQueueItem);
						this.giftQueueItem = "";
					}
				}
				if (this.giftSvgaPlayShow) {
					return false
				}
				if (this.giftQueue[0]) {
					this.giftValue = this.giftQueue[0];
				}
				console.log("播放地址：" + this.giftValue,this.giftQueue[0])
				if (!this.giftValue) return false
				this.giftSvgaPlayShow = true;
			},
			// 房主任务
			sendGift1(item) {
				this.giveAwayNum = 1;
				this.giftItemType.id = item.giftId;
				this.giftType = item.type;
				this.sendGift(item.userId);
			},
			// 赠送礼物
			sendGift(userId) {
				let data = {
					giftId: this.giftItemType.id, //礼物id
					giftNum: this.giveAwayNum, //	赠送数量
					roomId: this.roomId,
					userIds: [],
				};
				if (userId) {
					data.userIds = [userId];
				}

				let userNameList = [];
				if (this.userGiveAwayType && this.othersHometype == 1) {
					data.userIds.push(this.othersData.userId);
					userNameList.push(this.othersData.userName);
				} else if (this.userGiveAwayType && this.othersHometype != 1) {
					data.userIds.push(this.theWheatData.id);
					userNameList.push(this.theWheatData.userName);
				} else {
					this.roomMicrophoneList.forEach((item) => {
						if (item.pitch) {
							data.userIds.push(item.userId);
							userNameList.push(item.userName);
						}
					});
				}

				if (data.userIds.length == 0 && !this.userGiveAwayType)
					return this.$message("请选择赠送人");
				if (!data.giftId) return this.$message("请选择礼物");
				//       发送礼物消息（自定义消息Type:  RCMic:chatRoomGift）
				// const message = new PersonMessage(giftData);
				//  ;  //zwp  1 -10 送礼物应该  先走接口 在发送成功
				// // 发送消息
				data.userIds = data.userIds.toString();
				if (this.giftType == 4) {
					//type 0默认 1背包礼物
					data.type = "1";
				} else {
					data.type = "0";
				}
				if (this.sendStatus) {
					return;
				}
				this.sendStatus = true;

				this.$api.sendGift(data).then((res) => {
					if (res.code == 1) {
						if (this.giftType == 4) {
							this.myGiftList();
						}
						if (this.roomSetData.allGift != 1) {
							this.giftPopUpShow = true;
						}
						// 是否全服礼物
						let wholeObj = {
							toUserName: this.giftItemType.userNameList,
							giftPath: this.giftItemType.giftPath,
						};
						if (this.giftItemType.isAllAnnouncement == 1 || res.data) {
							let wholeData = {
								sendUserId: this.userInfo.id, //发消息的用户ID
								content: "全服礼物", //	推送消息内容
								msgType: 1, //0-全屏广播 1-礼物信息
								giftId: this.giftItemType.id, //礼物id
								giftNum: this.giveAwayNum, //	礼物数量
								price: this.giftItemType.price, //价格
								roomId: this.roomId, //房间id
								toUserId: data.userIds, //接收礼物的用户ID
							};
							// 后台发送全频消息
							this.$api.sendWholeMessage(wholeData).then((res) => {
								if (res.code == 1) {
									(wholeObj.sendUserName = this.userInfo.nickname),
									(wholeObj.sendUserAvatar = this.userInfo.avatar),
									(wholeObj.giftNum = this.giveAwayNum);
									wholeObj.roomName = this.roomDetail.roomName;
									wholeObj.toUserName = userNameList[0];
									this.wholeGift = wholeObj;
									this.broadcastTopShow = true;
									setTimeout(() => {
										this.broadcastTopShow = false;
									}, 6000);
								}
							});
						}
						//房间PK通知对方房间刷新页面
						if (
							this.roomDetail.roomPkOutVO &&
							this.roomDetail.roomPkOutVO.blueRoomId == this.roomId
						) {
							this.$api.sendMessage2Room({
								roomId: this.roomDetail.roomPkOutVO.redRoomId,
								content: 3,
							});
						} else if (
							this.roomDetail.roomPkOutVO &&
							this.roomDetail.roomPkOutVO.redRoomId == this.roomId
						) {
							this.$api.sendMessage2Room({
								roomId: this.roomDetail.roomPkOutVO.blueRoomId,
								content: 3,
							});
						}

						this.getMyDiamond();
						this.$refs.roomRight.roomRanking();
					} else {
						this.sendStatus = false;
						if (res.msg == '请进行实名认证') {
							this.shingmingShow = true
						} else {
							return this.$message(res.msg);
						}

					}
				});
			},
			// // 点唱--确认下单
			confirmOrder() {
				if (this.jukeboxSum > this.myDiamondData)
					return this.$message.error("元宝不足，请充值");
				let userIds = [];
				this.concertUserLisr.map((item) => {
					if (item.pitch) {
						userIds.push(item.userId);
					}
				});
				if (userIds.length == 0) return this.$message("请选择人员");
				let spaceDiamond = 0;
				if (this.jukeType == 2 || this.jukeType == 3) {
					spaceDiamond = this.jukeboxCost[1].configValue * this.jukeboxPitchNum;
				} else {
					spaceDiamond = 0;
				}
				let hostId = "";
				if (this.roomDetail.roomVoiceVO) {
					hostId = this.roomDetail.roomVoiceVO.hostId;
				} else if (this.roomDetail.roomVoiceInPKVO) {
					hostId = this.roomDetail.roomVoiceInPKVO.hostId;
				} else if (this.roomDetail.roomPkOutVO) {
					hostId = this.roomDetail.roomPkOutVO.blueHostId;
				}
				let data = {
					diamond: this.jukeboxSum, //花费元宝
					hostId: this.roomDetail.hostVO.userId, //主持人id
					roomId: this.roomId, //点唱厅id
					spaceDiamond: spaceDiamond, //场地花费元宝
					type: this.jukeboxCost[this.jukeType].configName, //包场-点歌-试音
					userIds: userIds.toString(), //用户id，多个用,隔开
				};
				this.$api.confirmOrder(data).then((res) => {
					if (res.code == 1) {
						this.someSingingShow = false;
						this.$message.success('点单成功')
						this.getMyDiamond();
					} else {
						this.$message(res.msg);
					}
				});
			},

			// 选择全部人员
			allPitchChange() {
				let list = []
				if (this.roomDetail?.hostVO?.userId && this.roomDetail.hostVO.userId != this.userInfo.id) {
					list.push(this.roomDetail.hostVO)
				}
				let concertUserLisr = (this.roomDetail.normalPositions || []).filter(e => {
					return e.userId && e.userId != this.userInfo.id
				})

				this.concertUserLisr = [...list, ...concertUserLisr].map(e => {
					return {
						...e,
						pitch: true
					}
				});
				this.jukeboxPitchNum = this.concertUserLisr.length || 0;
				return
				this.concertUserLisr = [];
				this.roomDetail.normalPositions.forEach((item) => {
					if (item.userId == this.userInfo.id) {} else {
						if (item.userId) {
							this.concertUserLisr.push(item);
						}
					}
				});
				this.concertUserLisr.map((item) => {
					item.pitch = true;
				});
				this.jukeboxPitchNum = 0;
				this.concertUserLisr.forEach((item) => {
					if (item.pitch) {
						this.jukeboxPitchNum++;
					}
				});
			},
			// 点唱选择
			jukeChange(type) {
				//type 0试音 5点唱 4包场
				if (type == 4) {
					this.concertUserLisr.map((item) => {
						item.pitch = true;
					});
					this.jukeboxPitchNum = 0;
					this.concertUserLisr.forEach((item) => {
						if (item.pitch) {
							this.jukeboxPitchNum++;
						}
					});
				}
				this.jukeType = type;
				this.jukeBoxPitch = this.jukeboxCost[type];
				if (this.jukeBoxPitch.configValue && this.jukeType == 1) {
					this.jukeboxSum = this.jukeboxPitchNum * this.jukeBoxPitch.configValue;
				} else {
					this.jukeboxSum =
						this.jukeboxPitchNum * this.jukeBoxPitch.configValue +
						this.jukeboxCost[2].configValue * this.jukeboxPitchNum;
				}
			},
			// 点单人员
			someSingChange(i) {
				i.pitch = !i.pitch;
				let list = this.concertUserLisr.filter(e => e.pitch)
				this.jukeboxPitchNum = list.length || 0
				if (this.jukeBoxPitch.configValue && this.jukeType == 0) {
					this.jukeboxSum = this.jukeboxPitchNum * this.jukeBoxPitch.configValue;
				}
				return
				this.jukeboxPitchNum = 0;
				this.concertUserLisr.map((item) => {
					if (item.userId == i.userId) {
						item.pitch = !item.pitch;
					}
				});
				this.concertUserLisr.forEach((item) => {
					if (item.pitch) {
						this.jukeboxPitchNum++;
					}
				});
				if (this.jukeBoxPitch.configValue && this.jukeType == 0) {
					this.jukeboxSum = this.jukeboxPitchNum * this.jukeBoxPitch.configValue;
				}
				//  else {
				//   this.jukeboxSum =
				//     this.jukeboxPitchNum * this.jukeBoxPitch.configValue +
				//     this.jukeboxCost[1].configValue * this.jukeboxPitchNum;
				// }
			},

			// 显示个人主页
			othersHomeChange(i) {
				let length = [];
				this.$api
					.getOtherInfo({
						id: i.userId,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							res.data.medalWallVoList.forEach((item) => {
								if (item.isGet == 1) {
									length.push(item);
								}
							});
							res.data.medalWallLength = length.length;
							this.othersData = res.data;
							if (this.othersData.id) {
								this.othersData.userId = this.othersData.id;
							}
						}
					});
				this.othersHomeShow = true;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
			},
			// 禁言弹窗初始化
			disableSendMsgInit() {
				this.$api.muteDays().then((res) => {
					if (res.code == 1) {
						this.bearOptions = res.data;
						this.disableSendMsgShow = true;
						this.othersHomeShow = false;
						this.othersHomeShowLiao = false;
						this.wheatHomeShow = false;
					}
				});
			},
			// 添加禁言
			addDisableSendMsg() {
				if (!this.bearValue) return this.$message("请选择禁言天数");
				if (!this.bearTextarea) return this.$message("请输入禁言原因");
				let data = {
					muteLength: this.bearValue, //禁言时长
					muteReason: this.bearTextarea, //禁言原因
					muteStatus: 0, //	禁言状态（0-被禁言 1-正常）
					roomId: this.roomId, //	房间id
					targetUserId: this.othersData.userId,
				};
				this.$api.muteUser(data).then((res) => {
					this.bearValue = "";
					this.bearTextarea = "";
					this.setAdminMember(4);
					this.disableSendMsgShow = false;
					this.othersHomeShow = false;
					this.othersHomeShowLiao = false;
					this.wheatHomeShow = false;
				});
			},
			// 设置禁言
			muteUserChange() {
				let data = {
					muteStatus: 1, //	禁言状态（0-被禁言 1-正常）
					roomId: this.roomId, //	房间id
					targetUserId: this.othersData.userId,
				};
				if (this.othersData.muteStatus == 1) {
					data.muteStatus = 0;
					// this.setAdminMember(4);
				} else {
					data.muteStatus = 1;
					// this.setAdminMember(5);
				}
				this.$api.muteUser(data).then((res) => {
					this.othersHomeShow = false;
					this.othersHomeShowLiao = false;
					this.wheatHomeShow = false;
				});
			},
			// 设置拉黑
			blackUserChange() {
				let data = {
					blackStatus: 1, //	是否拉黑（0-取消拉黑 1-拉黑）
					roomId: this.roomId, //	房间id
					targetUserId: this.othersData.userId,
				};
				this.$api.blackUser(data).then((res) => {
					this.$message("拉黑成功");
					this.othersHomeShow = false;
					this.wheatHomeShow = false;
					this.othersHomeShowLiao = false;
				});
			},

			// 设置管理
			setManagerAdd() {
				let data = {
					isManager: 1, //是否管理（0-否 1-是）
					roomId: this.roomId, //	房间id
					targetUserId: this.othersData.userId,
				};
				this.$api.setManager(data).then((res) => {
					this.othersHomeShow = false;
					this.othersHomeShowLiao = false;
					this.wheatHomeShow = false;
					// this.setAdminMember(2, 1); //发送房间通知
					// if(res.code==1){
					// }
				});
			},
			// 房主取消管理
			setCancelAdmin(i) {
				this.$api
					.getOtherInfo({
						id: i.userId,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							this.othersData = res.data;
							let type = "";
							if (i.userRole == 1) {
								type = 1;
							} else if (i.userRole == 2) {
								type = 2;
							}
							this.setAdminMember(type, 2);
						}
					});
			},
			// 房主设置管理
			moduleAddAdmin(typeitem) {
				this.$api
					.getOtherInfo({
						id: typeitem.userId,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							//
							this.setAdminMember(typeitem.type, 1);
						}
					});
			},
			// 设置厅管理消息
			setAdminMember(adminType, opn) {
				this.getRoomPcRankList(1);
				/**
				 * 发送设置管理消息（自定义消息Type:  RC:Chatroom:Admin）
				 * 字段：
				 * userId  被设置管理的id
				 * userName  被设置管理的名称
				 * type     1超管 2平台管理 3踢出房间  4禁言 5取消禁言
				 * operate     操作类型  1设置  2取消 (仅管理员时区分)
				 */
				// 构建要发送的自定义消息
				// let adminData = {
				//   userName: this.othersData.userName,
				//   userId: this.othersData.id,
				//   operate: "",
				//   type: adminType,
				// };
				// if (opn) {
				//   adminData.operate = opn;
				// }
				// const message = new PersonMessage(adminData);
				// this.$refs.roomRight.setAdminLocal(adminData);
			},
			// 关注
			followUser() {
				this.isGuan = true;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
				// this.$api
				//   .followUser({ followUserIds: this.othersData.id })
				//   .then((res) => {
				//     if (res.code == 1) {
				//       this.othersHomeShow = false;
				//       this.$message.success(`成功关注用户：${this.othersData.userName}`);
				//     }
				//   });
			},
			// 停止关注
			stopFollowUser() {
				this.$api
					.stopFollowUser({
						followUserId: this.othersData.userId
					})
					.then((res) => {
						if (res.code == 1) {
							this.othersHomeShow = false;
							this.othersHomeShowLiao = false;
							this.wheatHomeShow = false;
							this.othersData.followFlag = 0;
							this.$message(`已取消关注用户：${this.othersData.userName}`);
						}
					});
			},
			// 踢出房间点击
			kickOutShowChange() {
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
				if (this.othersData.userId == this.userInfo.id)
					return this.$message("不能踢出自己");

				this.kickOutShow = true;
			},
			//将用户踢出房间  userId   用户 Id
			kickOutRoom() {
				this.$api
					.kickUser({
						liveStreamId: this.liveStreamId,
						targetUserId: this.othersData.userId,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.$RCVoiceRoomLib
							//   .kickUserFromRoom(this.othersData.id)
							//   .then((res) => {});
							// setTimeout(() => {
							//
							// }, 1000);
							//
						}
					});
			},
			//下单
			placepage(id) {
				// this.OpenPage("others", this.othersData.id);
				this.manitoId = id;
				this.othersType = "";
				this.othersShow = true;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
			},
			// 页面跳转
			OpenPage(open, id) {
				this.$router.push({
					path: "/home",
					query: {
						popUpShow: open,
						userId: id
					},
				});
			},
			// 左侧排行名单
			getRoomPcRankList(i) {
				this.personnelType = i;
				this.roomUserList = [];
				if (this.roomStatus != 0) {
					//   //开黑厅
					if (i == 3) {
						this.$api
							.upMicroApplyList({
								liveStreamId: this.liveStreamId,
								searchKey: "",
								upMicroType: 0,
							})
							.then((res) => {
								if (res.code == 1) {
									this.roomUserList = res.data;
								}
							});
					} else {
						//房间 在线与VIP列表
						this.$api
							.getRoomPcRankList({
								roomId: this.roomId,
								type: this.personnelType,
							})
							.then((res) => {
								if (res.code == 1) {
									this.roomUserList = res.data.roomPcOnlineVOList;
								}
							});
					}
				} else {
					//   // 公会房间
					if (i == 3) {
						//点唱排麦列表
						//upMicroType 上麦类型（0-普通上麦，1-试音上麦 2-点单上麦）
						this.$api
							.upMicroApplyList({
								liveStreamId: this.liveStreamId,
								searchKey: "",
								upMicroType: 2,
							})
							.then((res) => {
								if (res.code == 1) {
									this.roomUserList = res.data;
								}
							});
					} else if (i == 4) {
						//试音排麦列表
						this.$api
							.upMicroApplyList({
								liveStreamId: this.liveStreamId,
								searchKey: "",
								upMicroType: 1,
							})
							.then((res) => {
								if (res.code == 1) {
									this.roomUserList = res.data;
								}
							});
					} else {
						//房间 在线与VIP列表
						this.$api
							.getRoomPcRankList({
								roomId: this.roomId,
								type: this.personnelType,
							})
							.then((res) => {
								if (res.code == 1) {
									this.roomUserList = res.data.roomPcOnlineVOList;
								}
							});
					}
				}
				this.getRoomPcLeftNumbers();
			},
			// 我的消息跳转他人主页
			placeSkipChange($event) {
				this.myMessageShow = false;
				this.manitoId = $event;
				this.othersShow = true;
			},
			// 左侧列表数量
			getRoomPcLeftNumbers() {
				this.$api
					.getRoomPcLeftNumbers({
						roomId: this.roomId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.listNum = res.data;
						}
					});
			},
			// 锁定或解锁麦位
			setLockPosition(i, type, role = 0) {
				//role 1主持人
				let data = {
					isLock: type, //锁定或解锁（0-锁定 1-解锁）
					liveStreamId: this.liveStreamId, //房间直播id
					targetPositionId: "", //目标麦位id
				};
				if (role == 1) {
					data.targetPositionId = i.userPositionId; //目标麦位id
				} else {
					data.targetPositionId = i.userPositionId; //目标麦位id
				}
				this.$api.lockPosition(data).then((res) => {
					if (res.code == 1) {} else {
						this.$message(res.msg);
					}
				});
			},
			/**
			 * 静音指定索引的麦位
			 *
			 * @param seatIndex 麦位序号
			 * @param isMute    是否静音
			 */
			//全员闭麦
			allclosingTime() {
				let backstage = {
					isClose: "", //	闭麦或开麦（0-闭麦 1-开麦）
					liveStreamId: this.liveStreamId, //	房间直播id
				};
				let isType = true;
				if (this.roomDetail.microphoneStatus == 1) {
					backstage.isClose = 0;
					isType = true;
				} else {
					backstage.isClose = 1;
					isType = false;
				}
				this.$api.closeAllMicrophone(backstage).then((res) => {
					if (res.code == 1) {
						// this.enterRoom()
					}
				});
			},
			// 关闭麦克风
			blankingOther(i) {
				let backstage = {
					isClose: 0, //	闭麦或开麦（0-闭麦 1-开麦）
					liveStreamId: this.liveStreamId, //	房间直播id
					targetPositionId: i.userPositionId, //目标麦位id
				};
				let seatIndex = i.order;
				let ismute = true;
				if (i.positionIsClose == 1) {
					ismute = false;
					backstage.isClose = 1;
				} else {
					ismute = true;
					backstage.isClose = 0;
				}
				// // 用户闭麦
				if (i == 1) {
					objData.seatIndex = this.theWheatData.order;
					backstage.isClose = 0;
					backstage.targetPositionId = this.theWheatData.userPositionId;
				}
				this.$api.closeMicrophoneOn(backstage).then((res) => {
					if (res.code == 1) {
						this.$message.success("用户闭麦成功");
						this.wheatHomeShow = false;
						this.popoverShow = "-1";
					} else {
						this.$message(res.msg);
					}
				});
			},
			//闭麦位
			closingTime(i) {
				let objData = {
					//融云
					seatIndex: i.order, //麦位序号
					isMute: "", //是否静音
				};

				let backstage = {
					//后台
					isClose: "", //闭麦或开麦（0-闭麦 1-开麦）
					liveStreamId: this.liveStreamId, //房间直播id
					targetPositionId: i.userPositionId, //目标麦位id
				};
				if (i.positionIsClose == 1) {
					objData.isMute = false;
					backstage.isClose = 1;
				} else {
					objData.isMute = true;
					backstage.isClose = 0;
				}
				this.$api.closePosition(backstage).then((res) => {
					if (res.code == 1) {
						this.wheatHomeShow = false;
						this.popoverShow = "-1";
					} else {
						this.$message(res.msg);
					}
				});
			},
			// 关闭本地麦克风
			closeMicrophone(item) {
				if (this.roomDetail.isIntercom == 1) {
					this.$message("对讲机状态下按V开麦");
				} else {
					let backstage = {
						isClose: item,
						liveStreamId: this.liveStreamId,
						targetPositionId: "",
					};
					if (this.roomDetail.resultCode == 6) {
						this.roomDetail.normalPositions.forEach((item) => {
							if (item.userId == this.userInfo.id) {
								backstage.targetPositionId = item.userPositionId;
							}
						});
					} else {
						if (this.roomDetail.hostVO.userId == this.userInfo.id) {
							backstage.targetPositionId = this.roomDetail.hostVO.userPositionId;
						} else {
							this.roomDetail.normalPositions.forEach((item) => {
								if (item.userId == this.userInfo.id) {
									backstage.targetPositionId = item.userPositionId;
								}
							});
						}
					}

					this.$api.closeMicrophoneOn(backstage).then((res) => {
						if (res.code == 1) {
							if (item == 1) {
								this.microphoneType = false;
							}
							if (item == 0) {
								this.microphoneType = true;
							}
							this.$message.success(
								"用户" + (item == 0 ? "闭麦" : "开麦") + "成功"
							);
							this.wheatHomeShow = false;
							this.popoverShow = "-1";
						} else {
							this.$message(res.msg);
						}
					});
				}
				// 传 true 或 false
				// this.$RCVoiceRoomLib.disableAudioRecording(!this.microphoneType);
				// this.getmicrophoneType();
			},
			// 上主持人麦
			comperephoneChenge() {
				this.$api
					.upMicrophone({
						liveStreamId: this.liveStreamId,
						targetPositionId: this.roomDetail.hostVO.userPositionId,
						upMicroType: 0,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.$RCVoiceRoomLib.enterSeat(0).then((res) => {});
							// setTimeout(() => {
							//   this.comperepopoverShow = false;
							// }, 300);
						} else {
							this.$message(res.msg);
						}
					});
			},
			// 房主管理，自由上麦
			upMicrophoneChenge(i) {
				// if(this.roomDetail.roomLoveVO.activityStage==2){
				//   this.$message('请等待真爱选择结束哦~')
				//   return
				// }
				this.$api
					.upMicrophone({
						liveStreamId: this.liveStreamId,
						targetPositionId: i.userPositionId,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.$message.success(res.msg);
						} else {
							this.$message(res.msg);
						}
					});
			},
			//下麦&抱用户下麦
			userLeaveSeat(type) {
				let maid = "";
				let userId = "";
				if (type == 1) {
					//抱用户下麦
					userId = this.pitchOnWheat.userId;
					maid = this.pitchOnWheat.userPositionId;
				} else {
					if (this.roomDetail.hostVO) {
						if (
							this.userInfo.id == this.roomDetail.hostVO.userId &&
							this.roomDetail.resultCode != 6
						) {
							maid = this.roomDetail.hostVO.userPositionId;
						} else {
							this.roomDetail.normalPositions.forEach((item) => {
								if (this.userInfo.id == item.userId) {
									maid = item.userPositionId;
								}
							});
						}
					} else {
						this.roomDetail.normalPositions.forEach((item) => {
							if (this.userInfo.id == item.userId) {
								maid = item.userPositionId;
							}
						});
					}
					//自己下麦
					userId = this.userInfo.id;
				}
				let data = {
					liveStreamId: this.liveStreamId, //房间直播id
					targetPositionId: maid, //目标麦位id
					targetUserId: userId, //目标用户id
				};
				this.$api.hugUserFromPosition(data).then((res) => {
					if (res.code == 1) {
						setTimeout(() => {
							this.getSimplePositionInfoByRoomId();
						}, 300);
					} else {
						this.$message(res.msg);
					}
				});
				this.emceeTaskShow = false;
				this.othersHomeShow = false;
				this.othersHomeShowLiao = false;
				this.wheatHomeShow = false;
			},
			// 赠数量选择
			giveAwayChange(i) {
				this.giveAwayNum = i.value;
				this.givingNumSelectShow = false;
			},
			// 礼物切换
			cutGiftType(k) {
				this.giftType = k;
				if (this.giftType == 4) {
					this.myGiftList();
				} else {
					this.getGiftList();
				}
			},
			// 我的礼物
			myGiftList() {
				this.$api.myGiftList().then((res) => {
					if (res.code == 1) {
						// this.mydiamondCost = res.data.diamondCost;
						this.giftList = res.data;
					}
				});
			},
			// 礼物清单
			getGiftList() {
				// 	类型 0-热门 1-情人专场 2-特权专区 3-土豪包场
				this.$api.getGiftList({
					type: this.giftType
				}).then((res) => {
					if (res.code == 1) {
						this.mydiamondCost = res.data.diamondCost;
						this.giftList = res.data.giftVOList;
					}
				});
			},
			// 点唱厅数据
			getVoice() {
				//获取点唱厅演唱者
				this.$api.getVoicePlayer({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						res.data.map((item) => {
							item.pitch = false;
						});
						this.concertUserLisr = res.data;
						if (this.roomDetail?.hostVO?.userId && this.roomDetail.hostVO.userId != this.userInfo.id) this.concertUserLisr.unshift(this.roomDetail.hostVO)
					}
				});
				// 获取点唱试音-点歌-包场-场地费费用
				this.$api.getVoiceFree({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						this.jukeboxCost = res.data;
						this.jukeBoxPitch = this.jukeboxCost[0];
					}
				});
			},
			//我的元宝
			getMyDiamond() {
				this.$api.myDiamond().then((res) => {
					if (res.code == 1) {
						this.sendStatus = false;
						this.myDiamondData = res.data;
					}
				});
			},

			//获取房内PK模式礼物前三
			getTeamValue() {
				this.$api.getTeamValue({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						this.pkTopThreeData = res.data;
						let sumUp = res.data.blueTeamValue + res.data.redTeamValue;
						if (sumUp == 0) {
							this.pkRedDutyCycle = 50;
						} else {
							this.pkRedDutyCycle = this.toPercent(res.data.redTeamValue, sumUp);
						}
					}
				});
			},
			//获取房房PK模式礼物前三
			getRoomPkTeamValue() {
				this.$api.getRoomPkTeamValue({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						this.roomAtroomPkThreeData = res.data;
						let sumUp = res.data.blueTeamValue + res.data.redTeamValue;
						if (sumUp == 0) {
							this.pkRedDutyCycle = 50;
						} else {
							this.pkRedDutyCycle = this.toPercent(res.data.redTeamValue, sumUp);
						}
					}
				});
			},
			// 计算百分比
			toPercent(num, total) {
				return Math.round((num / total) * 10000) / 100.0; // 小数点后两位百分比
			},
			// 获取麦位用户麦位框
			getMicrophoneDetail() {
				this.wheatFrameList = this.roomDetail.normalPositions;
			},
			// 加入房间
			joinRoom() {
				// 房间未开始新建房间
				if (this.roomDetail.isEstablish == 0 && !this.is_createdRoom) {
					//配置房间信息
					const rcVoiceRoomInfo = {};
					// 设置房间名称
					rcVoiceRoomInfo.roomName = this.roomDetail.roomName;
					// 设置麦位数量
					rcVoiceRoomInfo.seatCount = 10;
					this.$RCVoiceRoomLib
						.createAndJoinRoom(this.roomId, rcVoiceRoomInfo)
						.then((res) => {
							if (res == "加入聊天室成功") {
								this.is_createdRoom = true;
								this.resetWheat();
								// this.$api
								//   .saveRoomBack({ isEstablish: 1, roomId: this.roomId })
								//   .then((res) => {
								//   });
							}
						});
				} else if (this.roomDetail.isEstablish == 1 && !this.is_createdRoom) {

					// this.$RCVoiceRoomLib.leaveRoom(this.roomId).then((res) => {
					this.$RCVoiceRoomLib
						.joinRoom(this.$route.query.roomId)
						.then((res) => {
							this.is_createdRoom = true;
							// this.getUserInfo()
							const PersonMessage = this.$RongIMLib.registerMessageType(
								"RC:Chatroom:Enter",
								true,
								true,
								[],
								false
							);
							let enterRoom = {
								userId: this.userInfo.id,
								userName: this.userInfo.nickname,
								logo: this.userInfo.logo,
							};
							this.resetWheat();
							// const message = new PersonMessage(enterRoom);
							// this.$RongIMLib.sendMessage(
							//   {
							//     conversationType: this.$RongIMLib.ConversationType.CHATROOM,
							//     targetId: this.roomId,
							//   },
							//   message
							// );
						})
						.catch((err) => {});
					// }).catch(err=>{
					//   })
				}
			},
			// 键盘监听
			keyDownFun(event) {
				
				let that = this;
				console.log(that.isFlag)
				event = event || window.event;
				if (that.isFlag) {
					if (event.keyCode == 86) {
						that.isFlag = false;
						that.roomDetail.normalPositions.forEach((item) => {
							
							if (item.userId == that.userInfo.id) {
								console.log('aaaaaa',item.microphone,'roomDetail.hostVO.microphone')
								item.microphone=0
								that.trtcA1.startLocalAudio();
								that.$forceUpdate()
							}
						});
					}
				}
			},
			// 键盘监听
			keyUpFun(event) {
				let that = this;
				event = event || window.event;
				if (event.keyCode == 86) {
					that.roomDetail.normalPositions.forEach((item) => {
						if (item.userId == that.userInfo.id) {
								console.log('aaaaaa',item.microphone,'roomDetail.hostVO.microphone')
								item.microphone=1
								that.trtcA1.stopLocalAudio();
								that.isFlag = true;
								that.$forceUpdate()
							}
					})
					// that.trtcA1.stopLocalAudio();
					// that.isFlag = true;
					// that.$forceUpdate()
				}
			},
			//聊天室详情
			enterRoom(roomId) {
				if (roomId) {
					this.roomId = roomId;
				} else {}
				this.$api.enterRoom({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						let enterRoom = {
							data: {
								text: res.data.announcement,
								enter: 5,
								userId: res.data.userId,
							},
						};

						this.$refs.roomRight.getGiftData(enterRoom);
						this.getRoomPcRankList(1);
						let thenAt = this;
						let that = this;

						// that.calculateCountDown(neiDataTime, pkEndTime);
						if (res.data.resultCode == 6) {} else {
							if (res.data.hostVO.userId == this.userInfo.id) {
								this.whetherWheat = 1;
							}
						}
						res.data.normalPositions.forEach((item) => {
							if (item.userId == this.userInfo.id) {
								this.whetherWheat = 1;
							}
						});
						this.roomDetail = res.data;

						this.userRole = res.data.userRole;
						this.isBlackRoom = res.data.isBlackRoom;
						this.roomStatus = res.data.resultCode;
						this.roomBack = res.data.backImg;
						this.afficheValue = res.data.announcement;
						this.liveStreamId = res.data.liveStreamingId;
						this.getMicrophoneDetail();
						this.getRoomUserSet(); //房间用户设置
						let dataTime = new Date().getTime(); //初始化当前时间
						// 清除定时器
						window.clearInterval(thenAt.pkCountDown);
						if (that.roomDetail.resultCode == 2) {
							that.pkStatus = that.roomDetail.pkStatus;
						}
						if (that.roomDetail.isIntercom == 1) {
							window.addEventListener("keydown", this.keyDownFun);
							window.addEventListener("keyup", this.keyUpFun);
						}
						if (res?.data?.richVO?.userId) {
							this.pkCountDown = window.setInterval(function() {
								let neiDataTime = new Date().getTime(); //初始化当前时间
								let pkEndTime = Date.parse(res.data.richVO.endTime);
								that.calculateCountDown(neiDataTime, pkEndTime);
							}, 1000);
						}

						thenAt.pkCountDown = null;
						if (res.data.status == 0) {
							this.skipHome();
							return this.$message("聊天室已关闭");
						}
						// 房房pk
						if (res.data.resultCode == 3) {

							this.pkCountDown = window.setInterval(function() {
								let neiDataTime = new Date().getTime(); //初始化当前时间
								let pkEndTime = Date.parse(res.data.roomPkOutVO.pkEndTime);
								that.calculateCountDown(neiDataTime, pkEndTime);
							}, 1000);
						}
						if (this.roomDetail.roomVoiceOutPKVO) {
							this.pkTopThreeData = this.roomDetail.roomVoiceOutPKVO;
						}
						if (
							this.roomDetail.roomVoiceInPKVO &&
							this.roomDetail.roomVoiceInPKVO.pkStatus == 2
						) {
							//房内pK 礼物前三
							// this.getTeamValue();
						}

						//相亲模式
						if (this.roomDetail.roomLoveVO) {
							this.loveStage = this.roomDetail.roomLoveVO.activityStage; //活动阶段（0-未开始 1-嘉宾交流 2-真爱选择 3-公布真爱）
						}
					} else {
						this.$message.info(res.msg);
						this.$router.go(-1);
					}
				});
				this.getSimplePositionInfoByRoomId();
			},
			// 个人信息
			getUserInfo() {
				this.$api.queryUser().then((res) => {
					if (res.code == 1) {
						this.userInfo = res.data;
						if (
							this.roomDetail.isBlackRoom == 1 &&
							this.userInfo.id == this.roomDetail.landlordId
						) {
							this.$api
								.upMicrophone({
									liveStreamId: this.liveStreamId,
									upMicroType: 0,
								})
								.then((res) => {
									// this.$RCVoiceRoomLib.enterSeat(0).then((res) => {});
								});
						}
					}
				});
			},
			// 获取麦位用户
			getMicphoneUser() {
				this.roomMicrophoneList = [];
				console.log(this.roomDetail.normalPositions,'？？？？？',this.roomDetail.hostVO)
				this.roomDetail.normalPositions.forEach((item) => {
					if (item.userId) {
						if (item.userId == this.userInfo.id) {} else {
							this.roomMicrophoneList.push(item);
						}
					}
				});
				if(this.roomDetail.hostVO && this.roomDetail.hostVO.userId){
					if (this.roomDetail.hostVO.userId == this.userInfo.id) {} else {
						this.roomMicrophoneList.push(this.roomDetail.hostVO);
					}
				}
				console.error(this.roomMicrophoneList)
				this.$nextTick(() => {
					this.roomMicrophoneList.map((item) => {
						item.pitch = true;
					});
				});
			},
			// 房间麦上全部数据
			getSimplePositionInfoByRoomId() {
				this.$api
					.getSimplePositionInfoByRoomId({
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							this.allDataList = res.data;
							this.whetherWheat = 0;
							this.compereType = false;
							this.commonWheatType = false;
							res.data.forEach((item) => {
								if (this.userInfo.id == item.userId && item.type == 0) {
									// 是否主持人
									this.compereType = true;
								}
								if (this.userInfo.id == item.userId && item.type == 1) {
									// 是否普通用户
									this.commonWheatType = true;
								}
								if (this.userInfo.id == item.userId) {
									this.myWheat = item;
									return (this.whetherWheat = 1);
								}
							});
						}
					});
			},
			onShowTip(e, content) {
				this.currentGift = content;
				this.$refs.tooltip.show(e.target);
			},
			onHideTip() {
				this.$refs.tooltip.onHide();
			},
			// 设置开黑房密码
			setPassword() {
				let str = "";
				let messageType = false;
				this.password.forEach((item) => {
					if (item != "") {
						str = str + item;
					} else {
						return (messageType = true);
					}
				});
				if (messageType) return this.$message("请输入完整密码");
				let isLock = "";
				if (this.roomDetail.isLock == 0) {
					isLock = 1;
				} else {
					isLock = 0;
				}
				let data = {
					isLock: isLock, //是否上锁(0-否 1-是),示例值(0)
					password: str, //密码
					roomId: this.roomId, //	房间id
				};
				this.$api.lockRoom(data).then((res) => {
					if (res.code == 1) {
						this.roomLockShow = false;
						this.password = ["", "", "", ""];
						this.passwordName = "";
						if (isLock == 0) {
							this.roomDetail.isLock = 0;
							this.$message.success("房间解锁成功");
						} else {
							this.roomDetail.isLock = 1;
							this.$message.success("房间上锁成功");
						}
						this.$forceUpdate();
					}
				});
			},
			/**
			 * 离开房间
			 * @param roomId   房间 Id
			 */
			skipHome() {
				// this.$RCVoiceRoomLib.leaveRoom(this.roomId);
				this.$api.signOutRoom({
					roomId: this.roomId
				}).then((res) => {
					this.trtcA1.exitRoom();
					this.$router.push("/home");
					this.closeSvgaPopUpChange();
				});
			},



			// 取消排麦
			cancelWheatClearing() {
				this.$api
					.cancelUpMicrophone({
						liveStreamId: this.liveStreamId
					})
					.then((res) => {
						if (res.code == 1) {
							this.whetherWheat = 0;
							this.$message.success(res.msg);
						} else {
							this.$message.error(res.msg);
						}
					});
			},
			// 上麦申请
			applyRemind(newData) {
				//排麦申请
				// this.$RCVoiceRoomLib.requestSeat().then((res) => {
				// });

				// if(newData.status!=1){
				//   if(this.roomDetail.roomLoveVO.activityStage==2){
				//   this.$message('请等待真爱选择结束哦~')
				//   return
				// }
				let data = {
					liveStreamId: this.liveStreamId,
					upMicroType: 0,
					targetPositionId: this.roomDetail.resultCode == 0 ?
						"" : this.userPositionId.userPositionId,
				};
				this.$api.upMicrophone(data).then((res) => {
					if (res.code == 1) {
						this.whetherWheat = 2; //排麦
						this.getRoomPcRankList(this.personnelType);
					} else {
						this.$message(res.msg);
					}
				});
				this.wheatRemindShow = false;
				// }
			},
			nextPage() {},
			pageOn() {},
			// 房房PK模式跳转房间 type 1蓝 2红
			pkRoomSkip(roomData, type) {
				if (this.roomId == roomData.redRoomId && type == 1) {
					if (this.userInfo.id == this.roomDetail.hostVO.userId) {
						this.$message.error("麦位中的人不能跳转");
						return;
					}
					this.roomDetail.normalPositions.forEach((item) => {
						if (item.userId == this.userInfo.id) {
							this.$message.error("麦位中的人不能跳转");
						} else {
							this.adverseData.name = roomData.blueRoomName;
							this.adverseData.id = roomData.blueRoomId;
							this.jumpPopoverShow = true;
						}
					});
				}
				if (this.roomId == roomData.blueRoomId && type == 2) {
					if (this.userInfo.id == this.roomDetail.hostVO.userId) {
						this.$message.error("麦位中的人不能跳转");
						return;
					}
					this.roomDetail.normalPositions.forEach((item) => {
						if (item.userId == this.userInfo.id) {
							this.$message.error("麦位中的人不能跳转");
						} else {
							this.adverseData.name = roomData.blueRoomName;
							this.adverseData.id = roomData.blueRoomId;
							this.jumpPopoverShow = true;
						}
					});
				}
			},
			/**
			 * 房间PK
			 */
			//  获取PK房间列表
			getPkRoomList() {
				this.$api
					.getPkRoomList({
						pageNum: 1,
						pageSize: 999,
						keywords: this.searchInput,
						roomId: this.roomId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.pkRoomList = res.data;
							this.roomListBoxShow = true;
						}
					});
			},
			// 主持人发起邀请
			launchPkInvite(i) {
				this.inviterRoomId = i.id;
				this.$api
					.getInvitationSingRoom({
						roomId: this.roomId,
						invitedRoomId: i.id,
					})
					.then((opn) => {
						if (opn.code == 1) {
							// this.roomModeSwitch(3);
							this.roomListBoxShow = false;
							this.$message(opn.msg);
						} else {
							this.$message(opn.msg);
						}
					});
				// }

				/**
				 * 发送 PK 邀请
				 * 被邀请用户所在的房间 id
				 * 被邀请人的用户id
				 */
				// this.pkInviteDate = i;
				// this.$RCVoiceRoomLib
				//   .sendPKInvitation(i.id, i.moderatorUserId)
				//   .then((res) => {

				//   })
				//   .catch((error) => {});
			},
			// 被邀请人同意&解决操作 type 0拒绝 1同意
			pkInviteStateChange(type, data) {
				let arectStatus = 0;
				if (type == 1) {
					this.pkResponse = true;
					arectStatus = 1;
				}
				if (type == 0) {
					this.pkResponse = false;
				}
				/** 同意or拒绝
				 * arect	拒绝/同意(0拒绝-1同意)
				 * roomId	我的房间id
				 * invitedRoomId	被邀请房间id
				 */
				this.$api
					.agreeOrRefuse({
						arect: arectStatus,
						roomId: data.roomId,
						invitedRoomId: data.invitedRoomId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.pkInvitationShow = false;
						}
						this.$message(res.msg);
					});
			},
			// 取消PK邀请
			setDisinvite() {
				this.roomModeSwitch(1);
				/**
				 * 撤回已发送的PK邀请
				 * @param inviteeRoomId 被邀请用户所在的房间id
				 * @param inviteeUserId 被邀请人的用户id
				 */
				this.$RCVoiceRoomLib
					.cancelPKInvitation(
						this.pkInviteDate.id,
						this.pkInviteDate.moderatorUserId
					)
					.then((res) => {});
			},
			// 显示PK时长设置
			setPkTimeChange() {
				this.$api.getConfig({
					configGroup: "pktime"
				}).then((res) => {
					if (res.code == 1) {
						this.durationList = [];
						for (const key in res.data) {
							this.durationList.push(res.data[key]);

							function func(a, b) {
								return a - b;
							}
							this.durationList.sort(func);
						}
						this.setPkTimeShow = true;
					}
				});
			}, //获取创建游戏聊天室选项列表
			getCreateGameRoomOptions(id) {
				this.$api.getCreateGameRoomOptions({
					gameLabelId: id
				}).then((res) => {
					if (res.code == 1) {
						this.defaultImg = res.data.defaultImg;
						this.gameDistricts = res.data.gameDistricts; //游戏区服列表
						this.gameLevels = res.data.gameLevels; //游戏段位列表
						this.gameMode = res.data.gameMode; //	游戏模式列表
						this.gamePersonNum = res.data.gamePersonNum; //		游戏人数列表
					}
				});
			},
			secondLevelChange(i) {
				this.twolabel = i;
				this.getCreateGameRoomOptions(this.twolabel.id);
				this.roomform = {
					name: "",
					type: 0,
					sex: 0,
					gameDistrictId: "", //游戏区服id
					levelId: "", //游戏段位Id
					gameModeId: "", //游戏模式id
					numLimit: "", //开黑人数限制
				};
			},
			// 计算倒计时
			calculateCountDown(startTime, endTime) {
				let residueTime = endTime - startTime;
				residueTime = residueTime / 1000;
				if (residueTime > 0) {
					let m = parseInt((residueTime / 60) % 60, 10);
					m = m < 10 ? "0" + m : m;
					let s = parseInt(residueTime % 60, 10);
					s = s < 10 ? "0" + s : s;
					let shi = parseInt((residueTime / 60 / 60) % 60, 10);
					shi = shi < 10 ? "0" + shi : shi;
					this.pkMinute = m;
					this.pkSecond = s;
					this.pkShi = shi;
				} else if (residueTime < 0) {
					this.pkMinute = "00";
					this.pkSecond = "00";
					this.pkShi = "00";
					let thenAt = this;
					window.clearInterval(thenAt.pkCountDown);
					this.pkCountDown = null;
				}
			},
			// PK状态切换
			pkStatusChenge() {
				// 主持人才可切换
				if (this.pkStatus == 1) {
					this.setPkTimeChange();
				}
				if (this.pkStatus == 2) {
					// this.pkAdvanceOverShow = true;
				}
			},
			// PK已结束，切换语聊模式
			pkEndChange(item) {
				if (
					this.roomDetail.roomVoiceInPKVO &&
					this.roomDetail.roomVoiceInPKVO.hostId != this.userInfo.id
				)
					return;
				// if(this.roomDetail.roomPkOutVO && this.roomDetail.roomPkOutVO.blueHostId != this.userInfo.id) return
				// type 	0：派单厅 1：点唱厅 2：点唱厅房内PK模式 3：点唱厅房间PK模式 4：娱乐厅相亲相爱 5：陪玩游戏厅
				// 被邀请
				this.$api
					.voiceRoomEndPK({
						roomId: item.redRoomId,
						invitedRoomId: item.blueRoomId,
					})
					.then((res) => {
						if (res.code == 1) {}
					});
			},
			// roomPkList
			getRoomPkList() {
				this.$api.roomPkList().then((res) => {});
			},
			// 开启PK
			setStartPK() {
				if (!this.pkTimeValue) return this.$message("请选择PK时长");
				let invitedId = "";
				// if (
				//   this.roomDetail.roomPkOutVO &&
				//   this.roomDetail.roomPkOutVO.blueRoomId
				// ) {
				//   invitedId = this.roomDetail.roomPkOutVO.blueRoomId;
				// }
				this.$api
					.startPK({
						pkduration: this.pkTimeValue,
						roomId: this.roomId,
						invitedRoomId: this.roomDetail.roomPkOutVO ?
							this.roomDetail.roomPkOutVO.blueRoomId : "",
					})
					.then((res) => {
						if (res.code == 1) {
							// 开启PK通知
							// this.$RCVoiceRoomLib.notifyVoiceRoom("interior_pkBegin");
							// if(countDownData.length != 0){
							//   this.pkMinute = countDownData[0]
							//   this.pkSecond = countDownData[1]
							// }
							//房间PK通知对方房间刷新页面 content 1开始pk 2结束pk 3礼物
							// if (
							//   this.roomDetail.roomPkOutVO &&
							//   this.roomDetail.roomPkOutVO.blueRoomId == this.roomId
							// ) {
							//   this.$api.sendMessage2Room({
							//     roomId: this.roomDetail.roomPkOutVO.redRoomId,
							//     content: 1,
							//   });
							// } else if (
							//   this.roomDetail.roomPkOutVO &&
							//   this.roomDetail.roomPkOutVO.redRoomId == this.roomId
							// ) {
							//   this.$api.sendMessage2Room({
							//     roomId: this.roomDetail.roomPkOutVO.blueRoomId,
							//     content: 1,
							//   });
							// }
							// this.pkendTime = res.data.endTime;
							// this.setPkTimeShow = false;
							// this.pkStartAnimationShow = true;
							// setTimeout(() => {
							//   this.pkStartAnimationShow = false;
							// }, 6000);
						}
					});
			},
			// 结束PK
			affirmOver() {},
			//截单 停止派单
			overOrders() {
				this.$api.stopOrderStatus({
					roomId: this.roomId
				}).then((res) => {
					if (res.code == 1) {
						// this.enterRoom();
						this.overSendOrdersShow = false;
						this.$message.success("截单成功");
					} else {
						this.$message(res.msg);
					}
				});
			},
			//主持人 房间模式切换
			modeSwitch(type) {
				if (
					this.roomDetail.roomLoveVO &&
					this.roomDetail.roomLoveVO.activityStage == 2 &&
					type < 5
				)
					return this.$message("相亲中不可切换模式");
				if (this.pkStatus == 2) return this.$message("pk中不可切换模式");
				// // if (type == 3 || type == 2) return this.$message("模式测试中");
				// // 	0-派单厅 1-点唱厅 2-点唱厅房内PK模式 3-点唱厅房间PK模式 4-相亲恋爱 5-陪玩游戏厅 6-开黑厅
				if (type == 1) {
					//语聊模式
					this.roomModeSwitch(type);
				} else if (type == 2) {
					//房内PK
					this.roomModeSwitch(type);
				} else if (type == 3) {
					//房间PK
					this.getPkRoomList();
					// this.roomModeSwitch(type)
				} else if (type == 4) {
					//相亲模式
					this.roomModeSwitch(type);
				} else if (type == 5) {
					// this.clsRemindShow = true;//礼物清空
					this.$api
						.clearRoomUserGiftValue({
							roomId: this.roomId
						})
						.then((res) => {
							if (res.code == 1) {
								this.roomFunctionShow = false;
							}
						});
				} else if (type == 6) {
					this.clsRemindShow = true; //清屏
				} else if (type == 7) {
					//设置主持人礼物任务
					// 获取任务周期项
					this.$api.getConfig({
						configGroup: "taskCycle"
					}).then((res) => {
						if (res.code == 1) {
							this.giftPeriod = res.data;
						}
					});
					this.getHostTask();
					this.myFunctionShow = type; //我的任务
				} else if (type == 8) {
					this.houseOwnerOperate = 7; //禁言名单
				}
				this.roomFunctionShow = false;
				this.emceeTaskShow = false;
			},
			myMoney(money) {
				this.getMyDiamond();
			},
			// 模式切换
			roomModeSwitch(type) {
				this.$api.modeSwit({
					roomId: this.roomId,
					type: type
				}).then((res) => {});
				this.roomStatus = type;
			},
			// 房主清屏操作
			roomCls() {
				this.clsRemindShow = false;
				let that = this;
				let data = {
					cmd: "CustomCmdMsg",
					data: {
						bubble: this.userInfo.bubble,
						cmd: "clear_message",
					},
				};
				let message = that.chat.createCustomMessage({
					to: this.roomId,
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					payload: {
						data: JSON.stringify(data),
						description: JSON.stringify(data),
						extension: JSON.stringify(data),
					},
				});
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.$refs.roomRight.chatContentData = [];
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
				//
			},
			// 选择
			fankuiBtnIndex(index, item) {
				this.tabIndex = index;
				this.tabItem = item;
			},
			play() {
				this.flvPlayer.play();
			},
			// 反馈提交
			fankuisubmit() {
				this.$api
					.roomFeedBack({
						type: this.tabItem.value,
						content: this.fankuiValue,
						roomId: this.roomId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.$message.success("反馈提交成功");
							this.isFankui = false;
						} else {
							this.$message.error(res.msg)
						}
					});
			},
			// 关闭房间
			closeRoomChange() {
				// this.$RCVoiceRoomLib.notifyVoiceRoom("close_room");
				this.$api.closeRoom({
					liveStreamId: this.liveStreamId
				}).then((res) => {
					if (res.code == 1) {
						this.$router.path("/home");
					}
				});
			},
			jiesuo() {},
			//开黑房房主权限
			houseOwnerSwitch(type) {
				if (type == 1) {
					//房间上锁
					this.roomLockShow = true;
				} else if (type == 2) {
					//房间清屏
					this.clsRemindShow = true;
				} else if (type == 3) {
					//房间管理
					//管理员
					this.houseOwnerOperate = 4;
					this.$api.roomInfo({
						liveStreamId: this.liveStreamId
					}).then((res) => {
						if (res.code == 1) {
							this.roomInformation = res.data;
						}
					});
					this.$api.roomManagersInfo({
						roomId: this.roomId
					}).then((res) => {
						if (res.code == 1) {
							this.adminNum = res.data.rows.length;
						}
					});
					// 访客
					this.$api
						.roomVisitorInfo({
							liveStreamId: this.liveStreamId
						})
						.then((res) => {
							if (res.code == 1) {
								this.visitorNum = res.data.rows.length;
							}
						});
				} else if (type == 4) {
					//   //关闭房间
					this.closeRoomShow = true;
				} else if (type == 5) {
					this.isFankui = true;
				} else if (type == 6) {
					this.houseOwnerOperate = 7;
				} else if (type == 7) {
					this.houseOwnerOperate = 8;
				} else if (type == 8) {
					this.societyAdminShow = true;
				}
			},
			// 设置对讲机模式
			setRoomUserSet() {
				// setRoomIntercom
				this.$api.setRoomIntercom({
					roomId: this.roomId
				}).then((res) => {});
				// /room/setRoomIntercom
			},
			// 用户申请上麦模式
			// setUpMicroSwitch
			setUpMicroSwitch() {
				this.$api.setUpMicroSwitch({
					roomId: this.roomId
				}).then((res) => {});
			},
			// 保存房间数据
			saveRoomChange() {
				// 保存背景图片
				this.$api
					.saveRoomBack({
						roomBack: this.roomBack,
						roomId: this.roomId
					})
					.then((res) => {
						if (res.code == 1) {
							this.societyEditShow = false;
							// this.$RCVoiceRoomLib.notifyVoiceRoom("img_back", this.roomBack);
							// this.enterRoom();
						}
					});
			},
			// 选择背景图
			selectBackGround(i) {
				this.roomBack = i.imageurl;
				this.backgroundImageShow = false;
			},
			// 公会房主权限
			associationHouseOperate(type) {
				if (type == 1) {
					//语聊模式
					this.roomModeSwitch(type);
				} else if (type == 2) {
					//房内PK
					this.roomModeSwitch(type);
				} else if (type == 3) {
					//房间PK
					this.getPkRoomList();
					// this.roomModeSwitch(type);
				} else if (type == 4) {
					// if(this.loveStage<1||this.loveStage>=3){
					this.roomModeSwitch(type);
					// }else{
					//   this.$message('模式暂未结束不可进行切换~')
					// }
					//相亲模式
				} else if (type == 5) {
					this.isFankui = true;
					// this.clsRemindShow = true;//礼物清空
					this.$api
						.clearRoomUserGiftValue({
							roomId: this.roomId
						})
						.then((res) => {
							if (res.code == 1) {
								this.roomFunctionShow = false;
							}
						});
				} else if (type == 6) {
					this.clsRemindShow = true; //清屏
				} else if (type == 7) {
					//设置主持人礼物任务
					// 获取任务周期项
					this.$api.getConfig({
						configGroup: "taskCycle"
					}).then((res) => {
						if (res.code == 1) {
							this.giftPeriod = res.data;
						}
					});
					this.getHostTask();
					this.myFunctionShow = type; //我的任务
				} else if (type == 8) {
					this.houseOwnerOperate = 7; //禁言名单
				} else if (type == 10) {
					//管理员
					this.societyAdminShow = true;
				} else if (type == 11) {
					this.houseOwnerSwitch(7);
				} else if (type == 12) {
					this.houseOwnerOperate = 4;
					this.houseOwnerOperate = 4;
					this.$api.roomInfo({
						liveStreamId: this.liveStreamId
					}).then((res) => {
						if (res.code == 1) {
							this.roomInformation = res.data;
						}
					});

					this.$api.roomManagersInfo({
						roomId: this.roomId
					}).then((res) => {
						if (res.code == 1) {
							this.adminNum = res.data.rows.length;
						}
					});
					// 访客
					this.$api
						.roomVisitorInfo({
							liveStreamId: this.liveStreamId
						})
						.then((res) => {
							if (res.code == 1) {
								this.visitorNum = res.data.rows.length;
							}
						});
				} else if (type == 13) {
					let isAutoLock = "";
					if (this.roomDetail.isAutoLock == 0) {
						isAutoLock = 1;
					} else {
						isAutoLock = 0;
					}
					this.$api
						.autoLockMicro({
							roomId: this.roomId,
							isAutoLock: isAutoLock
						})
						.then((res) => {
							if (isAutoLock == 1) {
								this.roomDetail.isAutoLock = 1;
								this.$message("设置成功");
							} else {
								this.roomDetail.isAutoLock = 0;
								this.$message("取消成功");
							}

						});
				} else if (type == 14) {
					this.$api
						.clearRoomUserGiftValue({
							roomId: this.roomId
						})
						.then((res) => {
							if (res.code == 1) {
								this.roomFunctionShow = false;
							}
						});
				} else {
					this.societyEditShow = true;
					this.$api.selRoomImage().then((res) => {
						if (res.code == 1) {
							this.bgImgList = res.data;
						}
					});
					this.$api.roomInfo({
						liveStreamId: this.liveStreamId
					}).then((res) => {
						if (res.code == 1) {
							this.roomInformation = res.data;
						}
					});
				}
				this.roomFunctionShow = false;
			},
			// --------- 用户操作 ---------
			// 获取用户房间设置
			getRoomUserSet() {
				this.$api.getRoomUserSet().then((res) => {
					if (res.code == 1) {
						this.roomSetData = res.data;
						this.shieldingList = this.shieldingList.map((item) => {
							return {
								...item,
								status: this.roomSetData[item.state]
							}
						});
					}
				});
			},
			//设置用户房间设置
			setRoomUserStateChange(state) {
				if (state == "allSpecialEffects") {
					this.roomSetData.allSpecialEffects = this.roomSetData.allSpecialEffects == 1 ? 2 : 1;
					this.shieldingList.forEach((item) => {
						if (item.state != state) this.roomSetData[item.state] = this.roomSetData.allSpecialEffects
					});
				} else {
					if (state == "allGift") {
						this.giftQueue = [];
						this.giftValue = "";
					}
					if (this.roomSetData[state] == 1) {
						this.roomSetData[state] = 2;
					} else {
						this.roomSetData[state] = 1;
					}
				}
				this.shieldingList = this.shieldingList.map((item) => {
					return {
						...item,
						status: this.roomSetData[item.state]
					}
				});
				let shieldingList = [...this.shieldingList]
				shieldingList.splice(0, 1)
				this.roomSetData.allSpecialEffects = shieldingList.every(e=>e.status == 1) ? 1 : 2;
				this.shieldingList[0].status = this.roomSetData.allSpecialEffects;
				this.roomSetData.createBy = "";
				this.roomSetData.roomId = this.roomId;
				this.$api.setRoomUserSet(this.roomSetData).then((res) => {});
			},
			// 房间编辑显示
			editRoomChange() {
				this.editRoomShow = true;
				this.houseOwnerOperate = 0;
				this.getAllSecondLabel();
			},
			// 初始化与绑定监听事件方法 == 鼠标滚动
			scrollInit() {
				// 获取要绑定事件的元素
				const nav = document.getElementById("gift_list");
				var flag; // 鼠标按下
				var downX; // 鼠标点击的x下标
				var scrollLeft; // 当前元素滚动条的偏移量
				nav.addEventListener("mousedown", function(event) {
					flag = true;
					downX = event.clientX; // 获取到点击的x下标
					scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
				});
				nav.addEventListener("mousemove", function(event) {
					if (flag) {
						// 判断是否是鼠标按下滚动元素区域
						var moveX = event.clientX; // 获取移动的x轴
						var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
						this.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
					}
				});
				// 鼠标抬起停止拖动
				nav.addEventListener("mouseup", function() {
					flag = false;
				});
				// 鼠标离开元素停止拖动
				nav.addEventListener("mouseleave", function(event) {
					flag = false;
				});
			},
			selectGiftItem(index) {
				try {
					const giftList = this.$refs.giftListRef || {};
					const giftItem = this.$refs.giftListRef?.children?.[index] || {};
					giftList.scrollTo({
						left: giftItem.offsetLeft -
							giftList.offsetWidth / 2 +
							giftItem.offsetWidth / 2,
						behavior: "smooth",
					});
				} catch (error) {}
			},
			scrollToPrevItem(num) {
				if (num == 1) {
					try {
						const giftList1 = this.$refs.giftListRef1;
						// const itemWidth = this.$refs.giftListRef?.children?.[0]?.offsetWidth
						giftList1.scrollTo({
							// left: giftList?.scrollLeft - itemWidth,
							left: giftList1?.scrollLeft - giftList1?.offsetWidth,
							behavior: "smooth",
						});
					} catch (error) {}
					return;
				}
				try {
					const giftList = this.$refs.giftListRef;
					// const itemWidth = this.$refs.giftListRef?.children?.[0]?.offsetWidth
					giftList.scrollTo({
						// left: giftList?.scrollLeft - itemWidth,
						left: giftList?.scrollLeft - giftList?.offsetWidth,
						behavior: "smooth",
					});
				} catch (error) {}
			},
			scrollToNextItem(num) {
				if (num == 1) {
					try {
						const giftList1 = this.$refs.giftListRef1;
						// const itemWidth = this.$refs.giftListRef?.children?.[0]?.offsetWidth
						giftList1.scrollTo({
							// left: giftList?.scrollLeft - itemWidth,
							left: giftList1?.scrollLeft - giftList1?.offsetWidth,
							behavior: "smooth",
						});
					} catch (error) {}
					return;
				}
				try {
					const giftList = this.$refs.giftListRef;
					// const itemWidth = this.$refs.giftListRef?.children?.[0]?.offsetWidth
					giftList.scrollTo({
						// left: itemWidth + giftList?.scrollLeft || 0,
						left: giftList?.scrollLeft + giftList?.offsetWidth,
						behavior: "smooth",
					});
				} catch (error) {}
			},
			wheatRemindShowBtn() {
				// if(this.roomDetail.roomLoveVO.activityStage==2){
				//   this.$message('请等待真爱选择结束哦~')
				//   return
				// }
				if (this.roomDetail.userRole == 0) {
					for (let i = 0; i < this.roomDetail.normalPositions.length; i++) {
						if (this.roomDetail.normalPositions[i].status == 0) {
							this.upMicrophoneChenge(this.roomDetail.normalPositions[i]);
							break;
						}
					}
				} else {
					this.wheatRemindShow = true;
				}
			},
			// roomFeedBackTypes 反馈
			getRoomFeedBackTypes() {
				this.$api.roomFeedBackTypes().then((res) => {
					if (res.code == 1) {
						this.fankuiArr = res.data;
						this.tabIndex = 0;
						if (res.data.length) {
							this.tabItem = res.data[0];
						}
					} else {
						this.$message.error(res.msg)
					}
				});
			},
		},
	};
</script>
<style scoped lang="scss">
	//搜索内容
	.myMessageNum {
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.7);
		position: absolute;
		z-index: 1;
		border-radius: 50%;
		text-align: center;
		line-height: 20px;
		right: 0;
		top: -10px;
		color: #fff;
	}

	.search_frame {
		width: 400px;
		color: #000;
		padding: 10px;
		min-height: 200px;
		max-height: 600px;
		position: absolute;
		top: 50px;
		left: 455px;
		z-index: 1;
		border-radius: 10px;
		box-sizing: border-box;
		background-color: #FFF;

		.search_title {
			font-weight: 600;
			margin-bottom: 10px;
			justify-content: space-around;
		}

		.search_pitch {
			color: #6da4f5;
		}

		.search_pitch::after {
			content: "";
			width: 15px;
			height: 4px;
			display: block;
			margin: 2px 7px;
			border-radius: 8px;
			background-color: #6da4f5;
		}

		.search_subhead {
			font-weight: 600;
			margin: 6px 0;
		}

		.icon_style {
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-right: 4px;
			border-radius: 50%;
			background-color: #9d7045;
		}

		.search_roll {
			width: 100%;
			max-height: 550px;
			overflow-y: scroll;

			.search_rollItem {
				display: flex;
				align-items: center;

				>img {
					width: 50px;
					height: 50px;
					border-radius: 8px;
				}

				.rollItem_text {
					width: 100%;
					padding: 10px;
					box-sizing: border-box;
					align-items: center;
				}
			}
		}
	}

	.bgImgBox {
		width: 700px;
		height: 600px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;

		.bgImg_scroll {
			display: flex;
			height: 500px;
			justify-content: space-around;
			flex-wrap: wrap;
			overflow-y: scroll;

			.bgImg_item {
				width: 45%;
				height: 125px;
				margin-top: 18px;
				border-radius: 8px;
				overflow: hidden;
			}
		}
	}

	//盲盒样式
	.manghe_style {
		width: 86px;
		position: absolute;
		top: 55%;
		left: 88%;
	}

	.notSetHint {
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333;
	}

	// 礼物动画弹窗
	.gift_style {
		background-color: #fff;
		border-radius: 16px;
		position: absolute;
		// top: 20%;
		// left: 14%;
		width: 100%;
		// transform: translate(-50%, -50%);
		z-index: 2;
	}

	// 下载弹窗
	.downloadApp {
		padding: 0 10px;
		color: #fff;
		font-size: 12px;
		justify-content: space-around;
		position: relative;
		background: #FFF;
		border-radius: 8px;
		z-index: 1;

		.downloadApp_img {
			width: 128px;
			height: 128px;
			margin-top: 8px;
		}
	}

	// .downloadApp::after {
	// 	content: "";
	// 	width: 0px;
	// 	height: 0px;
	// 	position: absolute;
	// 	top: -5%;
	// 	right: 12%;
	// 	border-bottom: 10px solid #FFF;
	// 	border-right: 10px solid transparent;
	// 	border-left: 10px solid transparent;
	// }

	//恋爱模式样式
	.love_num {
		min-width: 45px;
		height: 19px;
		position: absolute;
		top: 30px;
		left: 50%;
		font-size: 12px;
		display: flex;
		background: #1d223b;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		color: #ff3067;
		transform: translateX(-50%);
	}

	.love_isChoose {
		width: 53px;
		height: 20px;
		display: flex;
		color: #fff;
		align-items: center;
		justify-content: center;
		background: #fb963b;
		margin: 0 auto;
		margin-top: 5px;
		border-radius: 10px;
		z-index: 1;
	}

	.love_isChoose1 {
		background: rgba($color: #ffff, $alpha: 0.4);
	}

	.demoSvag {
		width: 100px;
		height: 100px;
	}

	//音波特效
	.soundWave {
		position: absolute;
		display: inline-block;
		width: 58px;
		height: 58px;
		top: 0;
		left: 0;
		border-radius: 50%;
		border: 1px solid #ffd12a;
	}

	@keyframes circle-opacity {
		from {
			opacity: 1;
			transform: scale(1);
		}

		to {
			opacity: 0;
			transform: scale(1.6);
		}
	}

	//抱上麦弹窗
	.blacklist_input {
		width: 320px;
		margin: 25px 60px 0 60px;

		background: #f5f5f5;

		color: #8e92b1;
		border-radius: 4px 4px 4px 4px;
	}

	.report_list {
		width: 300px;
		height: 120px;
		margin: 45px auto;
		overflow-y: scroll;
		padding-right: 6px;

		.report_item {
			width: 100%;
			height: 40px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.report_head {
				width: 28px;
				height: 28px;
				margin-right: 7px;
				border-radius: 50%;
			}

			.report_icon {
				width: 14px;
				height: 14px;
			}
		}
	}

	@keyframes fade {

		//闪烁的动画
		from {
			opacity: 1;
		}

		50% {
			opacity: 0.2;
		}

		to {
			opacity: 1;
		}
	}

	.enter_box {
		position: relative;
		display: flex;

		.enter {
			width: 50px;
			height: 50px;
			border: 1px solid #545473;
			border-radius: 5px;
			margin: 0 5px 0 0;
			z-index: 100;
			display: flex;
			font-size: 30px;
			justify-content: center;
			align-items: center;
			background: #545473;

			//跳跃的指示符
			.tips {
				height: 30px;
				width: 1px;
				background-color: #000;
				animation: fade 1000ms infinite;
			}
		}

		.int {
			border: none;
			display: inline-block;
			width: 100%;
			height: 50px;
			position: absolute;
			z-index: 999;
			opacity: 0;
		}
	}

	.salary-container {
		padding: 30px;
	}

	//举报弹窗
	.report_remind {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;

		.report_topText {
			width: 679px;
			height: 36px;

			color: #e1502d;
			font-size: 12px;
			background: rgba(225, 80, 45, 0.1);
		}

		.report_from {
			width: 320px;
			margin: 0 180px;

			.report_fromTitle {
				color: #333;
				font-size: 14px;
				margin: 20px 0 10px 0;
				text-align: left;
			}

			.report_btn {
				width: 320px;
				height: 40px;
				color: #333;
				margin: 30px 0 40px 0;
				background: rgba(255, 221, 0, 1);
				border-radius: 4px;
			}
		}

		.imgBox {
			width: 100px;
			height: 100px;
			border-radius: 8px;
			margin-right: 10px;
			position: relative;
			overflow: hidden;

			.imgDeleIcon {
				position: absolute;
				top: 8px;
				right: 8px;
			}
		}
	}

	//麦位头像
	.wheat_avatar {
		width: 54px;
		height: 54px;
		border-radius: 50%;
	}

	.bannedToPost_icon-tow {
		position: absolute;
		bottom: 0px;
		width: 20px;
		height: 20px;
		right: 0px
	}

	//禁言图标
	.bannedToPost_icon {
		position: absolute;
		bottom: 30px;
		width: 20px;
		height: 20px;
	}

	//禁言图标
	.bannedToPost_icon2 {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 20px;
		height: 20px;
	}

	//搜索框
	.search {
		width: 280px;
		height: 40px;
		color: #fff;
		margin-left: 270px;
		background: rgba($color: #1d2030, $alpha: 0.13);
		border-radius: 20px 20px 20px 20px;
	}

	.search /deep/.el-input__inner {
		border: 0;
		background: rgba($color: #1d2030, $alpha: 0.13);
		border-radius: 20px 20px 20px 20px;
		font-size:13px
	}

	.topLeftIcon {
		position: absolute;
		left: 0;
		top: 0;
	}

	//派单说明
	.send_list {
		width: 286px;
		height: 50px;
		padding: 5px 0;
		font-size: 8px;
		background: rgba($color: #000000, $alpha: 0.19);
		border-radius: 0px 0px 32px 32px;
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		top: -8%;
		transform: translateX(-50%);

		.send_icon {
			width: 34px;
			height: 32px;
			margin: 0 10px 0 24px;
			font-size: 12px;
			text-align: center;
			background: rgba(255, 221, 0, 1);
			border-radius: 8px 2px 4px 2px;
			color: #000;
		}

		.send_item {
			width: 50px;
			height: 12px;
			display: inline-block;
			border-right: 1px solid rgba(255, 255, 255, 0.13);
			color: #fff;
		}
	}

	// 我的任务弹窗
	.myTask_box {
		padding: 20px;

		.myTask_text {
			font-size: 14px;
			color: #8586a9;
		}

		.myTask_itemBox {
			margin-top: 40px;
			font-size: 12px;

			.myTask_item {
				width: 139px;
				height: 179px;
				background: #363659;
				border-radius: 4px;
				position: relative;
			}

			.myTask_leftImg {
				width: 11.71px;
				height: 11.71px;
				position: absolute;
				top: 12px;
				left: 12px;
			}

			.myTask_head {
				width: 56px;
				height: 56px;
			}

			.myTask_icon {
				width: 34px;
				height: 18px;
				font-size: 10px;
				color: #fff;
				background: #d18a42;
				border-radius: 0px 4px 0px 5px;
				position: absolute;
				top: 0;
				right: 0;
			}

			.myTask_btn {
				width: 72px;
				height: 28px;
				color: #fff;
				font-size: 14px;
				margin-top: 15px;
				background: #e13edb;
				border-radius: 4px;
			}
		}

		.myTask_addBox {
			width: 139px;
			height: 139px;
			margin-top: 40px;
			font-size: 12px;
			position: relative;
			background: rgba(54, 54, 89, 0.12);
			border-radius: 4px;
			border: 1px dashed #363659;
		}

		.addTask_btn::before {
			content: "";
			position: absolute;
			top: 35%;
			right: 29%;
			width: 55px;
			border-top: 6px solid;
		}

		.addTask_btn::after {
			content: "";
			position: absolute;
			top: 22%;
			right: 45%;
			height: 55px;
			border-left: 6px solid;
		}

		.addTask_text {
			position: absolute;
			top: 60%;
			right: 26%;
			font-size: 14px;
		}

		.setGiftTask_style {
			width: 429px;
			height: 40px;
			color: #fff;
			padding: 0 16px;
			background: rgba($color: #fff, $alpha: 0.1);
			border-radius: 0px 0px 8px 8px;
		}

		.giftPeriod_select {
			width: 115px;
		}

		.giftPeriod_select /deep/.el-input__inner {
			height: 30px;
			background: #f5f5f5;
		}

		.giftPeriod_select /deep/.el-input__icon {
			line-height: 30px;
		}

		.giftPeriod_btn {
			width: 64px;
			height: 28px;
			margin-left: 10px;
			background: rgba(255, 221, 0, 1);
			border-radius: 29px;
			color: #333;
		}

		//新增任务选择礼物弹窗样式
		.addTask_popUp {
			width: 461px;
			height: 168px;
			position: absolute;
			top: 55%;
			left: 2%;
			background: rgba($color: #000000, $alpha: 0.95);
			border-radius: 8px 8px 8px 8px;
			z-index: 1;
		}

		.myTask_addBtn {
			width: 320px;
			height: 40px;
			margin: 40px auto;
			color: #333;
			font-size: 14px;
			background: rgba(255, 221, 0, 1);
			border-radius: 4px;
		}
	}

	.floating_frame {
		font-size: 8px;

		.floating_img {
			width: 80px;
			height: 80px;
			margin-right: 8px;
		}
	}

	// 房间管理弹窗
	.room_titleBox {
		width: 403px;
		height: 80px;
		padding: 16px 0 0 17px;
		margin: 40px 0 18px 0;
		background: url(../../image/room/46.png) no-repeat;
		box-sizing: border-box;

		.titleBoxIcon {
			width: 48px;
			height: 18px;
			font-size: 8px;
			background: #1d2030;
			border-radius: 3px;
			text-align: center;
			line-height: 18px;
		}

		.titleBoxName {
			width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.room_titleBoxHead {
			width: 48px;
			height: 48px;
			margin-right: 16px;
		}

		.room_titleBoxText {
			font-size: 10px;
			color: #8e92b1;
			margin-top: 4px;
		}
	}

	.roomAdmin_bg {
		padding: 14px;
		width: 127px;
		height: 56px;
		background: #f5f5f5;
		border-radius: 4px;
		box-sizing: border-box;
		position: relative;

		.roomAdmin_page {
			width: 6px;
			height: 9px;
			position: absolute;
			top: 16px;
			right: 10px;
		}

		.roomAdmin_icon {
			width: 24px;
			height: 24px;
			margin-right: 11px;
		}
	}

	.pop_up {
		background: #1d223b;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 4px;
	}

	.solutionRemind_box {
		padding: 54px 75px 41px 75px;

		.confirm_unbundling {
			width: 176px;
			height: 40px;
			margin-top: 55px;
			background: #363659;
			border-radius: 4px;
		}

		.think_gain {
			width: 176px;
			height: 40px;
			margin-top: 55px;
			margin-right: 20px;
			background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
			border-radius: 4px;
		}
	}

	.pkStartAnimation_style {
		width: 500px;
		height: 300px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: pkStartTime 6s;
	}

	@keyframes pkStartTime {
		0% {
			opacity: 0;
		}

		20% {
			opacity: 1;
		}

		80% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	//聊天弹窗
	.chat_popup {
		width: 790px;
		height: 539px;
		position: absolute;
		top: -40px;
		left: 0;
		background: rgba($color: #000000, $alpha: 0.8);
		z-index: 9;

		// 结算弹窗
		.settlement_popUp {
			width: 522px;
			height: 280px;
			background: #1d223b;
			border-radius: 4px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.settlement_title {
				color: #faa320;
				font-size: 16px;
				font-weight: bold;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);

				>img {
					margin-bottom: 12px;
				}
			}

			.settlement_item {
				width: 90%;
				margin: 0 auto;
				color: #9f9f9f;
				margin-top: 125px;

				>img {
					width: 48px;
					height: 48px;
					border-radius: 50%;
					margin-bottom: 8px;
				}
			}
		}

		.settlement_close {
			width: 150px;
			height: 40px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 85%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: rgba($color: #9a9a9a, $alpha: 0.21);
			border-radius: 4px;
		}
	}

	// 相亲相爱规则
	.loveEachPopup {
		width: 522px;
		height: 478px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		background-size: 100% 100%;
		z-index: 1;

		// &::after{
		// 	background: url(../../image/room/2929.png) no-repeat,#FFF;
		// 	background-size:522px 400px;
		// 	background-position: 0 -60px;
		// 	z-index: 0;
		// 	content:'';
		// 	position:absolute;
		// 	bottom:0;
		// 	left:0;
		// 	margin-top:60px;
		// 	width: 100%;
		// 	height:100%;
		// }
		.love_quit {
			z-index: 999;
			position: absolute;
			right: 5%;
			top: 22%;
			font-size: 24px;
		}
	}

	// 相亲相爱模式
	.flowPath_box {
		.flowPath_item {
			width: 80px;
			height: 28px;
			font-size: 12px;
			background: rgba($color: #fff, $alpha: 0.2);
			border-radius: 14px;
		}

		.flowPath_path {
			background: #ff7d00;
		}

		.you_icon1 {
			width: 10px;
			height: 10px;
			border-right: 5px solid #fff;
			border-bottom: 5px solid #fff;
			transform: rotate(-45deg);
			margin: 0 12px;
		}

		.you_icon2 {
			border-right: 5px solid #ff7d00;
			border-bottom: 5px solid #ff7d00;
		}
	}

	//公布真爱弹窗
	.publishLove {
		width: 810px;
		height: 565px;
		position: absolute;
		top: -40px;
		left: -10px;
		background: rgba($color: #000000, $alpha: 0.8);
		z-index: 1;

		.realLove_box {
			width: 280px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			animation: realLove-opacity 12s;

			.realLove_left {
				position: absolute;
				right: 60px;
			}

			.realLove_right {
				position: absolute;
				left: 60px;
			}

			.realLoveUser {
				z-index: 1;

				>img {
					width: 40px;
					height: 40px;
					border: 1px solid #fff;
					border-radius: 50%;
				}
			}

			.user1 {
				position: absolute;
				right: 40px;
			}

			.user2 {
				position: absolute;
				left: 40px;
			}
		}
	}

	@keyframes realLove-opacity {
		0% {
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	.blindData_box {
		width: 90%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.blindData_img {
			width: 219.5px;
			height: 185px;
		}
	}

	.microphone_blindox {
		width: 50%;
		display: flex;
		flex-wrap: wrap;

		.microphone_item {
			width: 50%;
			text-align: center;
			margin-top: 14px;
			color: #8e92b1;
			position: relative;

			.microphone_text {
				margin-top: 8px;
				color: #fff;
			}
		}
	}

	//用户礼物数值
	.gift_num {
		padding: 0 2px;
		color: #fff;
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 60%;
		top: -10%;
		z-index: 1;
		background: linear-gradient(111deg, #e17616 0%, #ff9b41 100%);
		border-radius: 14px 14px 14px 0px;
		border: 1px solid #ffffff;
		position: absolute;
	}

	// 房间pk样式
	.roomPk_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.roomPk_icon {
			width: 108px;
			height: 108px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -30%);
		}

		.blue_side {
			width: 258px;
			height: 80px;
			background: linear-gradient(270deg, #4148ff 0%, rgba(179, 57, 246, 0) 100%);
			box-shadow: inset 0px 3px 6px 1px rgba(255, 255, 255, 0);
			border-radius: 38px;
		}

		.red_side {
			width: 258px;
			height: 72px;
			padding-left: 35px;
			position: relative;
			top: 34px;
			background: linear-gradient(278deg,
					rgba(251, 217, 101, 0) 0%,
					#ff0046 100%);
			border-radius: 38px;
			box-sizing: border-box;
		}

		.side_head {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin-right: 8px;
		}

		.side_box {
			font-size: 10px;

			.side_label {
				font-size: 8px;
				margin-left: 10px;
				background: #722ed1;
				border-radius: 10px;
				padding: 0 6px;
			}

			.side_icon {
				min-width: 42px;
				height: 20px;
				color: #000;
				justify-content: space-around;
				background: rgba($color: #ffffff, $alpha: 0.8);
				border-radius: 4px;
			}
		}
	}

	// pk模式
	.pk_box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		margin-top: 42px;

		.pk_img {
			position: absolute;
			top: 5px;
			left: 50%;
			transform: translate(-50%);
			width: 120px;
			height: 120px;
		}

		.pk_status {
			width: 107px;
			height: 28px;
			background: #f68229;
			border-radius: 16px;
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translate(-50%);
		}

		.pk_status0 {
			background: #f68129;
		}

		.pk_status2 {
			background: linear-gradient(90deg, #f04f4f 0%, #6ebcff 100%);
		}
	}

	.head_img {
		width: 48px;
		height: 48px;
		border-radius: 50%;
	}

	.pk_bgBox {
		width: 380px;
		height: 135px;
		justify-content: space-around;
		background: rgba($color: #000000, $alpha: 0.5);
		border-radius: 8px;

		.pk_person {
			width: 80px;

			.pk_head {
				position: relative;

				.head_icon {
					width: 20px;
					height: 20px;
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}

			.pk_index {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: rgba($color: #000000, $alpha: 0.4);
			}

			.pk_gift {
				max-width: 70px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				border-radius: 5px;
				padding: 0 4px;
				background: rgba($color: #fff, $alpha: 0.2);
				border: 1px solid rgba($color: #fff, $alpha: 0.2);
			}
		}
	}

	// pk邀请弹窗
	.pk_invitation {
		width: 482px;
		height: 368px;
		background: url(../../static/232.png) no-repeat;
		background-size: 100% 100%;
		border-radius: 16px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		.invitation_icon {
			width: 164px;
			height: 164px;
			position: absolute;
			top: -20%;
			left: 50%;
			transform: translate(-50%);
		}

		.invitation_box {
			width: 370px;
			margin: 0 auto;
			margin-top: 171px;
		}

		.invitation_btn {
			width: 176px;
			height: 40px;
			background: rgba(183, 100, 61, 0.16);
			border-radius: 4px;

			color: #b7643d;
		}
	}

	// pk房间列表
	.roomList_input {
		width: 442px;
		height: 40px;
		text-align: center;
		margin: 0 auto;
	}

	.roomList_box {
		width: 100%;
		height: 460px;
		padding: 0 40px;
		overflow-y: scroll;
		box-sizing: border-box;
	}

	.roomList_item {
		width: 100%;
		color: #333;
		display: flex;
		margin-bottom: 20px;
		padding: 8px 20px 8px 8px;
		position: relative;
		font-size: 14px;
		align-items: center;
		justify-content: space-between;
		background: rgba(255, 221, 0, 0.2);
		box-sizing: border-box;

		.roomList_itemIcon {
			width: 50px;
			height: 20px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.roomList_itemHead {
			width: 74px;
			height: 74px;
			margin-right: 8px;
			border-radius: 8px;
		}

		.roomList_person1 {
			width: 28px;
			height: 28px;
			border-radius: 50%;
		}

		.roomList_person2 {
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}

		.roomList_itemBtn {
			width: 77px;
			height: 36px;
			color: #333;
			background: rgba(255, 221, 0, 1);
			border-radius: 20px;
		}
	}

	// 编辑公告
	.edit_billboard {
		width: 310px;
		padding: 20px 60px;

		.billboard_input {
			width: 320px;
			height: 113px;
		}

		.billboard_btn {
			width: 176px;
			height: 40px;
			margin-top: 20px;
			background: rgba(255, 221, 0, 1);
			color: #333;
			border-radius: 4px;
		}
	}

	// 编辑房间弹窗
	.editRoom {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;
		color: #333;
	}

	.editRoom_box {
		width: 820px;
		height: 531px;
		margin: 20px;
		padding: 0 40px;
		background: #f5f5f5;
		border-radius: 0px 4px 4px 4px;
		box-sizing: border-box;
	}

	.editRoom_btn {
		width: 320px;
		height: 40px;
		margin: 20px auto;
		background: rgba(255, 221, 0, 1);
		border-radius: 4px;
	}

	.establishTab_item {
		width: 102px;
		height: 32px;
		margin-right: 10px;
		background: rgba(245, 246, 247, 1);
		border-radius: 4px 4px 0px 0px;
	}

	.establishTab_puth {
		background-color: rgba(255, 221, 0, 1);
	}

	.skill_box {
		width: 820px;
		height: 110px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		background: rgba(255, 221, 0, 1);
		border-radius: 0px 4px 4px 4px;
		box-sizing: border-box;
		overflow-x: auto;

		.skill_item {
			width: 90px;
			height: 90px;
			color: #8586a9;
		}

		.itemText {
			text-align: center;
			width: 90px;
			color: #000;
		}

		.skill_pitch {
			width: 90px;
			height: 90px;
			color: #333;
			background: linear-gradient(138deg,
					rgba($color: #635ea5, $alpha: 0.8) 0%,
					rgba($color: #454178, $alpha: 0.8) 100%);
			border-radius: 8px;
		}
	}

	.label_img {
		width: 40px;
		height: 40px;
		margin-bottom: 6px;
		border-radius: 8px;
	}

	// 弹窗背景
	.popUp_box {
		width: 100%;
		height: 100%;
		background: rgba($color: #000000, $alpha: 0.8);
		position: absolute;
		top: 0;
		left: 0%;
		z-index: 999;
		color: #333;
	}

	.baoUserBtn {
		width: 72px;
		height: 26px;
		background: #ffdd00;
		border-radius: 29px 29px 29px 29px;
		opacity: 1;
		font-size: 12px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		color: #333333;
		text-align: center;
		line-height: 26px;
	}

	.guangboAllTitle {
		margin-top: 52px;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #000000;
		margin-bottom: 14px;
	}

	.guangboAllBtn {
		width: 176px;
		height: 40px;
		background: #ffdd00;
		border-radius: 4px 4px 4px 4px;
		opacity: 1;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		text-align: center;
		line-height: 40px;
		margin-top: 40px;
	}

	.wheat_remind {
		width: 522px;
		height: 285px;
		position: absolute;
		top: 40%;
		left: 50%;
		color: #333;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;

		.fankuiType {
			background: #f5f5f5;
			border-radius: 4px 4px 4px 4px;
			padding: 10px 20px;
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 14px;
		}

		.fankuiBtn {
			width: 176px;
			height: 40px;
			background: #ffdd00;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			line-height: 40px;
			text-align: center;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			margin: 0 auto;
			color: #333333;
		}

		.wheatRemind_img {
			width: 80px;
			height: 80px;
			margin-top: 40px;
			border-radius: 50%;
		}

		.wheatRemind_apply {
			width: 176px;
			height: 40px;
			background: #ffdd00;
			margin-top: 40px;
			border-radius: 4px;
		}

		.btn2 {
			color: #f68229;
			margin-right: 20px;
			background: rgba(246, 130, 41, 0.2);
		}
	}

	// 神豪规则弹窗
	.deityRich_rule {
		height: 422px;
		color: #6a6a94;
		font-size: 14px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;

		.deityRich_div {
			line-height: 25px;
			padding: 0 14px;
		}

		.deityRich_head {
			width: 86px;
			height: 83px;
			position: relative;
			margin-bottom: 20px;

			.deityRich_boximg {
				width: 86px;
				height: 83px;
				position: absolute;
			}

			.deityRich_headImg {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}

		.deityRich_ruleTitle {
			width: 167px;
			height: 33px;
			background: url(../../image/room/8.png);
			margin: 20px 0 16px 0;
		}

		.deityRich_text {
			width: 456px;
			margin: 0 14px;
		}
	}

	// 点单弹窗
	.someSing {
		.someSing_top {
			max-width: 380px;
			height: 40px;
			color: #333;
			font-size: 8px;
			margin-top: 20px;
			padding: 0 0 0 0;
			display: flex;
			background: linear-gradient(132deg, #ffffff 0%, #ffffff 100%);
			border-radius: 8px;

			.someSing_topHead {
				width: 35px;
				height: 35px;
				border-radius: 50%;
			}

			.someSing_bg {
				margin: 0 6px;
				position: relative;
				border: 1px solid #FFF;
			}

			.someSing_userPitch {
				width: 12px;
				height: 12px;
				font-size: 8px;
				color: #fff;
				position: absolute;
				border-radius: 50%;
				right: 0;
				bottom: 0;
				background-color: #1f172a;
			}

			.someSing_headPitch {
				border-radius: 50%;
				height: 35px;
				border: 1px solid black;
			}
		}

		.someSing_box {
			width: 430px;
			display: flex;
			justify-content: space-between;
			margin: 32px 28px;

			.someSing_item {
				width: 130px;
				height: 160px;
				color: #fff;
				position: relative;
				padding: 59px 0 0 8px;
				border-radius: 16px;
				background: url(../../image/room/2.png) no-repeat;
				box-sizing: border-box;
				border: 2px solid #fff;

				.someSing_num {
					position: absolute;
					bottom: 10px;
					font-size: 15px;
				}
			}

			.someSing_pitch {
				border: 2px solid #09eef9;
			}

			.someSing_item:hover {
				border: 2px solid #09eef9;
			}

			.someSing_item2 {
				background: url(../../image/room/3.png) no-repeat;
			}

			.someSing_item3 {
				background: url(../../image/room/4.png) no-repeat;
			}
		}

		.someSing_confirm {
			width: 125px;
			height: 36px;
			background: rgba(255, 221, 0, 1);
			border-radius: 4px;
			color: #333;
		}

		.someSing_text {
			color: #8e92b1;
			font-size: 12px;
		}

		.someSing_bottom {
			width: 430px;
			position: relative;

			.give_gifts {
				padding: 10px;
				background: rgba($color: #000000, $alpha: 0.8);
				box-shadow: 0px 0px 6px 1px rgba(196, 225, 255, 0.1);
				border-radius: 8px;
				position: absolute;
				top: -80px;
				left: 25%;

				.give_giftsImg {
					width: 48px;
					height: 48px;
					border-radius: 8px;
					margin-right: 8px;
				}
			}

			.give_gifts::after {
				content: "";
				width: 0px;
				height: 0px;
				border-top: 10px solid rgba($color: #000000, $alpha: 0.8);
				border-right: 10px solid transparent;
				border-left: 10px solid transparent;
				display: inline-block;
				position: absolute;
				bottom: -10px;
				right: 35px;
			}

			.someSing_bottomFlex {
				width: 100%;
				color: #fff;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.someSing_topUp {
				width: 107px;
				height: 20px;
				color: #252b4a;
				font-size: 12px;
				display: flex;
				align-items: center;
				margin-left: 20px;
				background: #f7f7f9;
				border-radius: 11px;
				position: relative;
			}

			.someSing_topUpBtn {
				width: 48px;
				height: 20px;
				color: #fff;
				position: absolute;
				right: -15px;

				background: linear-gradient(90deg, #eb5862 0%, #f09e81 100%);
				border-radius: 10px 10px 10px 0px;
			}
		}
	}

	// 升级弹窗
	.upgrae_popup {
		width: 191px;
		height: 220px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: url(../../static/156.png) no-repeat;
		background-size: 100% 100%;
		z-index: 99;
	}

	// 顶部弹窗
	.broadcast_top {
		width: 369.37px;
		height: 42.44px;
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translate(-50%);
		padding-left: 15px;
		animation: broadcast_animation 3s;
		background: url(../../image/popout/1.png) no-repeat;
		box-sizing: border-box;
		z-index: 2;

		.broadcast_head {
			width: 35px;
			height: 35px;
			border-radius: 50%;
		}

		.broadcast_content {
			width: 48%;
			padding-left: 90px;

			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
			box-sizing: border-box;
		}

		.broadcast_content::-webkit-scrollbar-thumb,
		::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			border-radius: 0;
			background: rgba(0, 0, 0, 0.2);
		}

		.broadcast_cartoon {
			display: flex;
			align-items: center;
			justify-content: center;
			animation-name: voluntarilyRoll;
			animation-duration: 5s;
			animation-iteration-count: infinite;
		}
	}

	//滚动
	@keyframes voluntarilyRoll {
		50% {
			transform: translateX(0%);
		}

		100% {
			transform: translateX(-65%);
		}
	}

	//从左到右的动画
	@keyframes broadcast_animation {
		0% {
			transform: translateX(-150%);
		}

		100% {
			transform: translateX(-50%);
		}
	}

	.room_functionNew {
		top: -405px !important;
	}

	// 房间操作
	.room_function {
		width: 320px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: absolute;
		top: -220px;
		left: -68px;
		padding: 0 20px;
		padding-top: 20px;
		background: rgba($color: #000000, $alpha: 0.8);
		box-shadow: 0px 0px 6px 1px rgba(196, 225, 255, 0.1);
		border-radius: 8px;
		box-sizing: border-box;
		z-index: 1;

		.roomFunction_item {
			width: 70px;
			margin-bottom: 20px;
			position: relative;

			>img {
				width: 40px;
				height: 40px;
				margin-bottom: 10px;
			}

			.pitch_icon {
				width: 20px !important;
				height: 20px !important;
				position: absolute;
				top: -5px;
				right: 5px;
			}
		}

		>img {
			margin: 10px 0;
		}
	}

	.room_function::after {
		content: "";
		width: 0px;
		height: 0px;
		border-top: 10px solid rgba($color: #000000, $alpha: 0.8);
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		display: inline-block;
		position: absolute;
		bottom: -10px;
		right: 45px;
	}

	//操作栏
	.operationTabl {
		margin-left: 20px;
		cursor: pointer;

		>img {
			width: 16px;
			height: 16px;
			margin-right: 11px;
		}
	}

	.chatPage {
		width: 100%;
		height:calc(100vh - 60px) ;
		color: #fff;
		font-size: 14px;
		background: url(../../assets/bg1.png) no-repeat;
		background-size: 100% 100%;
		border-radius: 12px 12px 8px 8px;
		position: relative;
	}

	// 

	/* Default width */
	.containerWidth {
		width: 90vw;
		min-width: 1600px;
		max-width: 2100px;
	}
	.containerWidthNew{
		width: 90vw;
		min-width: 1600px;
		max-width: 2100px;
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
	}
	.container::after{

	}

	// @media (min-width: 1500px) and (max-width: 1600px) {
	// 	.containerWidth {
	// 		width: 1500px;
	// 	}
	// }

	// @media (min-width: 1600px) and (max-width: 1800px) {
	// 	.containerWidth {
	// 		width: 1500px;
	// 	}
	// }

	// @media (min-width: 1800px) and (max-width: 2000px) {
	// 	.containerWidth {
	// 		width: 1700px;
	// 	}
	// }

	.chat_top {
		// width: 1400px;
		height: 60px;
		padding: 0 20px;
		color: #333333;
		border-radius: 8px 8px 0px 0px;
		background: rgba(29, 32, 48, 0.23);
		box-sizing: border-box;
		justify-content: space-between;

		.chat_headImg {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			margin-right: 10px;
		}

		.topUpBtn {
			width: 48px;
			height: 20px;
			margin-left: 30px;
			background: rgba($color: #fff, $alpha: 0.4);
			border-radius: 10px;
		}
	}

	.remid_box {
		width: 522px;
		position: absolute;
		top: 40%;
		left: 50%;
		font-size: 14px;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;
	}

	.BtnSubmit {
		width: 256px;
		height: 40px;
		background: #ffdd00;
		border-radius: 4px 4px 4px 4px;
		opacity: 1;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		color: #333333;
		margin: 0 auto;
	}

	//左侧
	.chat_left {
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		// 房间详情
		.romm_details {
			width: 320px;
			height: 224px;
			padding: 10px;
			position: relative;
			background: rgba($color: #1d2030, $alpha: 0.23);
			border-radius: 8px;
			box-sizing: border-box;

			.rommDetails_icon {
				height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 8px;
				padding: 0 4px;
				background-color: #31d9ad;
				border-radius: 10px 2px 2px 2px;
			}

			//background: url(../../static/16.png) no-repeat;
			.rommDetails_head {
				width: 74px;
				height: 74px;
				margin-right: 16px;
				border-radius: 4px;
				object-fit: cover;
			}

			//分享与收藏按钮
			.room_share {
				width: 52px;
				height: 19px;
				font-size: 10px;
				color: #f1892c;
				margin-top: 14px;
				background: rgba(243, 178, 56, 0.16);
				border-radius: 11px;

				>img {
					margin-right: 2px;
				}
			}

			.room_collect {
				width: 52px;
				height: 19px;
				font-size: 10px;
				color: #f4d239;
				margin: 14px 9px 0 0;
				background: rgba(243, 209, 56, 0.16);
				border-radius: 11px;

				>img {
					margin-right: 2px;
				}
			}

			.line_between {
				width: 100%;
				height: 1px;
				margin: 16px 0 6px 0;
				background-color: rgba($color: #fff, $alpha: 0.04);
			}

			.roomDetail_text {
				width: 300px;
				height: 83px;
				overflow-y: scroll;
				overflow-wrap: break-word;
			}

			.afficheAlter_ccc {
				position: absolute;
				top: 10px;
				right: 10px;
				color: #77d769;
				font-size: 12px;
			}
		}

		// 房间人员
		.personnel_box {
			width: 320px;
			height: 480px;
			padding: 16px 10px;
			background: rgba($color: #1d2030, $alpha: 0.23);
			border-radius: 8px;
			box-sizing: border-box;
			position: relative;

			.personnel_table {
				.personnel_item {
					font-size: 14px;
					flex: 1;
					justify-content: space-around;
					color: #f4f4f5;
				}

				.personnel_pitchOn {
					font-size: 16px;
					color: #fff;
					position: relative;
				}

				.personnel_pitchOn::after {
					content: "";
					width: 10px;
					height: 4px;
					position: absolute;
					top: 120%;
					background: #ffdd00;
					border-radius: 4px;
				}
			}

			.personnel_list {
				width: 100%;
				height: 430px;
				padding-top: 20px;
				overflow-y: scroll;
				box-sizing: border-box;

				.personnel_listItem {
					padding: 8px 0;
					position: relative;
					animation: fade-in 0.5s ease-in;

					.listItem_head {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						// margin-right: 7px;
					}

					.vip_num {
						font-size: 14px;
						color: #71ca66;
						position: absolute;
						right: 8px;
					}
				}

				.personnel_btn {
					font-size: 12px;
					padding: 0 4px;
					color: red;
					border: 1px solid red;
					border-radius: 10px;
					text-align: center;
				}

				.listItem_icon {
					width: 28px;
					height: 28px;
				}
			}
		}

		.vip_bottom {
			width: 100%;
			height: 35px;
			font-size: 10px;
			border-radius: 0 0 8px 8px;
			font-size: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #090a1b;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	//中间内容
	.chat_centre {
		width: 100%;
		padding: 0 10px 10px 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		position: relative;

		.suspension_icon {
			width: 26px;
			height: 26px;
			position: absolute;
			top: 16px;
			left: 26px;
			background: url(../../static/141.png) no-repeat;
			cursor: pointer;
		}

		.suspension_icon2 {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 10%;
			right: 20%;
			background: url(../../static/41.png) no-repeat;
			z-index: 1;
			cursor: pointer;
		}

		.suspension_topIcon {
			position: absolute;
			top: 16px;
			right: 26px;
		}

		.pkProgress_title {
			width: 100%;
			display: flex;
			padding: 7px 75px 9px 75px;
			justify-content: space-between;
			box-sizing: border-box;
			position: relative;

			.pkProgress_icon {
				width: 32px;
				height: 16px;
				font-weight: bold;
				background: #ffffff;
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.pkProgress_titleBox {
				margin-right: 30px;
			}

			.pkRanking_icon {
				width: 29.87px;
				height: 27px;
				position: absolute;
				top: -18px;
				right: -10px;
			}

			.pkRanking_head {
				width: 32px;
				height: 32px;
				border-radius: 50%;
			}

			.pkRanking_text {
				font-size: 10px;
				color: #77d769;
			}
		}

		// 语音聊天
		.voice_box {
			width: 100%;
			height: 500px;
			margin-top: 40px;
			position: relative;

			.order_meal {
				position: absolute;
				top: 60%;
				right: 2%;
			}

			.emceeTop_box {
				width: 100%;
				margin: 0 auto;
			}

			// 主持人
			.emcee_box {
				width: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin: 0 auto;
				margin-top: 40px;
				position: relative;

				.emcee_head {
					width: 60px;
					height: 60px;
					// background: #ffd12a;
					border-radius: 50%;
					position: relative;

					>img {
						width: 60px;
						height: 60px;
						border-radius: 50%;
					}

					.head_icon {
						width: 20px;
						height: 20px;
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}

				.emcee_name {
					width: 220px;
					font-size: 14px;
					margin-top: 8px;
					display: flex;
					justify-content: center;
					line-height: 24px;
				}

				.emcee_gift {
					padding: 4px;
					background: rgba($color: #fff, $alpha: 0.2);
					border-radius: 5px;
					border: 1px solid rgba($color: #fff, $alpha: 0.2);
				}

				.emcee_task {
					width: 37px;
					color: #212121;
					font-size: 8px;
					text-align: center;
					display: inline-block;
					background: linear-gradient(90deg,
							#ffffff 0%,
							rgba(255, 255, 255, 0.71) 100%);
					border-radius: 12px 4px 6px 12px;
					line-height: 24px;
				}

				.emcee_state {
					max-width: 180px;
					margin-top: 6px;
					padding: 2px 6px;
					font-size: 10px;
					background: linear-gradient(90deg,
							rgba(255, 255, 255, 0.27) 0%,
							rgba(255, 255, 255, 0) 100%);
					border-radius: 18px 0px 0px 18px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}

			// 神豪

			.emcee_headDeity {
				width: 74px;
				height: 72px;
				position: relative;

				.emcee_deityImg {
					width: 74px;
					height: 72px;
					position: absolute;
				}

				>img {
					width: 54px;
					height: 54px;
					border-radius: 50%;
				}
			}

			//主持人任务
			.emceeTask_popup {
				width: 236px;
				height: 178px;
				padding: 0 10px;
				font-size: 10px;
				background: #fff;
				border-radius: 4px;
				position: absolute;
				top: 18%;
				left: 60%;
				box-sizing: border-box;
				z-index: 1;

				.emceeTask_title {
					color: #333;
					margin: 8px 0 11px 0;
				}

				.task_box {
					width: 66px;
					height: 91px;
					color: #f68229;
					position: relative;
					background: linear-gradient(110deg, #ffffff 0%, #ffffff 100%);
					border-radius: 8px;
					margin-right: 6px;

					.task_type {
						width: 23px;
						height: 11px;
						font-size: 12px;
						color: #fff;
						position: absolute;
						top: 0;
						right: 0;
						background: linear-gradient(313deg, #ff9e54 0%, #f68229 100%);
						border-radius: 3px;
					}

					.task_icon1 {
						width: 30px;
						height: 30px;
					}

					.task_icon2 {
						width: 7.59px;
						height: 6.44px;
					}

					.task_presented {
						width: 45px;
						height: 15px;
						color: #fff;
						background: linear-gradient(133deg, #7c4ff0 0%, #976eff 100%);
						border-radius: 8px;
					}

					.task_hint {
						font-size: 10px;
						color: #333;
						opacity: 0.5;
					}
				}
			}

			.microphone_boxBlack {
				width: 80%;
				margin: 0 auto;
				display: flex;
				justify-content: space-around;
				margin-top: 20px;
				flex-wrap: wrap;
				height: 430px;
				overflow-y: auto;

				.microphone_item {
					position: relative;
					margin-top: 14px;
					color: #8e92b1;
					flex: 25%;
					text-align: center;

					.microphone_text {
						margin-top: 12px;
						color: #fff;
					}
				}
			}

			.microphone_box {
				width: 80%;
				margin: 0 auto;
				display: flex;
				justify-content: space-around;
				margin-top: 20px;
				flex-wrap: wrap;

				.microphone_item {
					position: relative;
					margin-top: 14px;
					color: #8e92b1;
					flex: 25%;
					text-align: center;

					.microphone_text {
						margin-top: 12px;
						color: #fff;
					}
				}
			}

			//上麦操作作
			.function_popover {
				width: 90px;
				color: #fff;
				font-size: 12px;
				padding: 12px 17px 0 13px;
				position: absolute;
				top: 0;
				left: 75%;
				background: rgba($color: #000000, $alpha: 0.8);
				box-shadow: 0px 0px 6px 1px rgba(196, 225, 255, 0.1);
				border-radius: 8px;
				box-sizing: border-box;
				z-index: 1;

				.popover_item {
					width: 100%;
					height: 100%;
					margin-bottom: 12px;
					cursor: pointer;
				}
			}

			.function_popover::after {
				content: "";
				position: absolute;
				top: 15%;
				left: -8px;
				border-right: 8px solid rgba($color: #000000, $alpha: 0.8);
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
			}

			//赠送人员
			.giveAway_box {
				height: 44px;
				padding: 10px 12px;
				margin-left: 12px;
				font-size: 14px;
				background: rgba($color: #1d2030, $alpha: 0.23);
				border-radius: 4px;
				position: relative;
				box-sizing: border-box;

				.giveAway_icon {
					position: absolute;
					top: -6px;
					right: -6px;
				}

				.giveAway_head {
					width: 25px;
					height: 25px;
					margin-right: 10px;
					border-radius: 50%;
				}
			}

			.roomPerson_imgPitch {
				padding: 0 !important;
				border-radius: 50%;
				border: 2px solid #fff;
			}

			.microphone_feature {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				bottom: 0;

				.roomPerson_list {
					height: 44px;
					display: flex;
					align-items: center;
					padding: 0 14px;
					background: rgba($color: #1d2030, $alpha: 0.23);
					border-radius: 8px;
					overflow: hidden;

					.roomPerson_img {
						width: 25px;
						height: 25px;
						padding: 1px;
						border-radius: 50%;
						overflow: hidden;
					}

					.roomPerson_item {
						flex: 1;
						margin: 0 5px;
						position: relative;
					}

					.roomPerson_pitch {
						width: 12px;
						height: 12px;
						font-size: 8px;
						color: #fff;
						position: absolute;
						border-radius: 50%;
						right: 0;
						bottom: 0;
						background-color: #1f172a;
					}

					.roomPerson_icon {
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}

				.feature_box {
					position: relative;

					.feature_icon {
						margin-right: 10px;
					}

					.allSendOrders_btn {
						width: 92px;
						height: 28px;
						background: linear-gradient(135deg, #413d4d 0%, #302b3d 100%);
						border-radius: 14px;
						margin-right: 8px;
					}

					.sendOrders_btn {
						width: 64px;
						height: 28px;
						background: rgba($color: #ffff, $alpha: 0.2);
						border-radius: 14px;
					}

					.upMicrophone {
						width: 72px;
						height: 28px;
						color: #333333;
						background: #ffdd00;
						border-radius: 14px;
					}

					.cancelBtn {
						width: 72px;
						height: 28px;
						background: linear-gradient(135deg, #747474 0%, #747474 100%);
						border-radius: 14px;
					}

					.downMicrophone {
						width: 64px;
						height: 28px;
						margin-left: 8px;
						background: linear-gradient(135deg, #f04f75 0%, #ff6e78 100%);
						border-radius: 14px;
					}

					.closeMicrophone {
						width: 64px;
						height: 28px;
						margin-left: 8px;
						background: linear-gradient(135deg, #413d4d 0%, #302b3d 100%);
						border-radius: 14px;
					}

					.shielding_set {
						padding: 10px;
						position: absolute;
						top: 15px;
						left: 0;
						transform: translateY(-110%);
						background: rgba($color: #1d2030, $alpha: 0.23);

						.shielding_list {
							color: #f2f2f2;
							font-size: 12px;
							margin-bottom: 9px;

							>img {
								margin-right: 4px;
								width: 12px;
								height: 12px;
							}
						}
					}
				}
			}
		}
	}

	// 礼物
	.gift_box {
		width: 100%;
		height: 165px;
		padding: 10px;
		background: rgba($color: #1d2030, $alpha: 0.23);
		border-radius: 8px;
		box-sizing: border-box;

		.gift_tabBox {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.gift_tab {
				display: flex;
				color: #8e92b1;
				font-size: 12px;
			}

			.gift_tabItem {
				margin-right: 43px;
			}

			.gift_pitchOn {
				color: #fff;
				font-size: 14px;
				position: relative;
			}

			.gift_pitchOn::after {
				content: "";
				display: inline-block;
				width: 10px;
				height: 4px;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				background: #ffdd00;
				border-radius: 4px;
			}
		}

		.gift_show_bar {
			position: relative;

			.left_arrow,
			.right_arrow {
				position: absolute;
				padding: 0 4px;
				width: 21px;
				height: 100%;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAYCAMAAAArvOYAAAAAAXNSR0IArs4c6QAAADlQTFRFAAAA////////////////////////////////////////////////////////////////////////Cjo1bwAAABN0Uk5TAAIFBgsSHB4gKCpCVFVgeXx9f/HLF10AAAAwSURBVBjTY2CgA2BGF+AUYEMTEORlpFSAC12AgUeIA81iJj5+dioKsaILcbPQOCgBj4EB34eh6jsAAAAASUVORK5CYII=);
				background-repeat: no-repeat;
				background-position: 50%;
				cursor: pointer;
				z-index: 2;
			}

			.right_arrow {
				right: 0;
				top: 0;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAYCAMAAAArvOYAAAAAAXNSR0IArs4c6QAAADlQTFRFAAAA////////////////////////////////////////////////////////////////////////Cjo1bwAAABN0Uk5TAAIFBgsSHB4gKCpCVFVgeXx9f/HLF10AAAAwSURBVBjTY2CgA2BGF+AUYEMTEORlpFSAC12AgUeIA81iJj5+dioKsaILcbPQOCgBj4EB34eh6jsAAAAASUVORK5CYII=);
				transform: rotate(180deg);
			}

			.gift_list_box {
				padding: 0 30px;

				// 列表
				#gift_list {
					width: 440px;
					display: flex;
					align-items: center;
					margin: 10px 0 0 0;
					padding: 10px;
					// overflow-x: scroll;
					overflow-x: auto;
					overflow-y: hidden;

					.gift_item {
						width: 100px;
						text-align: center;
						position: relative;
						margin: 0 5px;

						>img {
							width: 33px;
							height: 33px;
							margin-bottom: 6px;
						}

						.giftWealThName {
							color: #515a00;
							font-size: 10px;
							position: absolute;
							left: 50%;
							transform: translate(-50%, -50%);
							background: linear-gradient(139deg, #fffc9b 0%, #e6ff00 100%);
						}
					}

					.gift_pitch {
						// margin: 0 10px;
						background: rgba(255, 255, 255, 0.2);
						border-radius: 10px;
					}
				}
			}
		}

		.myGift_num {
			position: absolute;
			bottom: 40%;
			right: 18%;
		}

		.gift_giving {
			padding-bottom: 14px;
			margin-top: 6px;
			justify-content: space-between;

			.gift_balance {
				font-size: 12px;

				>img {
					width: 14px;
					height: 14px;
					margin-right: 6px;
				}
			}

			.givingBox {
				width: 115px;
				height: 26px;
				background: rgba(255, 255, 255, 0.14);
				border-radius: 29px;
				border: 2px solid rgba(183, 183, 183, 0.22);
				justify-content: space-between;
				position: relative;

				.giving_num {
					padding-left: 8px;

					>img {
						width: 4.5px;
						height: 8px;
						transform: rotate(-90deg);
					}
				}

				.giving_btn {
					width: 58px;
					height: 26px;
					background: rgba(255, 221, 0, 1);
					border-radius: 29px;
					color: #333;
					cursor: pointer;
				}

				.givingNum_select {
					position: absolute;
					left: -10px;
					padding: 10px 6px 3px 22px;
					font-size: 12px;
					background: rgba($color: #1d2030, $alpha: 0.23);
					box-shadow: 0px 3px 6px 1px rgba(142, 142, 142, 0.16);
					transform: translateY(-65%);
					cursor: pointer;

					.givingNum_item {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	//右侧
	// .chat_right {

	// }
	.othersHome1 {
		position: absolute;
		left: 50%;
		bottom: 15%;
		border-radius: 8px;
		z-index: 1;

		.othersHome_head {
			width: 62px;
			height: 62px;
			background: #fff;
			border-radius: 50%;
			margin-bottom: 6px;
			position: relative;

			>img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}

		.othersHome_icon {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 12px;
			left: 12px;
			background: url(../../static/141.png) no-repeat;
			background-size: 100%;
			cursor: pointer;
			z-index: 1;
		}

		.othersHome_top {
			transform: translateY(-30%);
		}

		.othersHome_labelBox {
			position: absolute;
			top: 85px;
			left: 10px;
		}

		.othersHome_label {
			color: #7c4ff0;
			font-size: 10px;
			padding: 1px 7px;
			border-radius: 10px;
			background: #fff;
			margin-right: 6px;
		}

		.othersHome_topBox {
			width: 100%;
			height: 121px;
			// padding: 10px;
			box-sizing: border-box;
			background: url(../../static/160.png) no-repeat;
			background-size: 100%;
			border-radius: 8px 8px 0px 0px;
			color: #333;
		}

		.othersHome_bottom {
			padding: 10px;
			box-sizing: border-box;
			position: relative;

			.othersHome_bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				filter: blur(1px);
				border-radius: 0 0 8px 8px;
				background: #fff;
				z-index: 0;
			}

			.othersHome_value1 {
				width: 78px;
				height: 40px;
				font-size: 8px;
				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user5.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;


				width: 90px;
				height: 50px;
				font-size: 12px;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.othersHome_value2 {
				width: 78px;
				height: 40px;
				font-size: 8px;
				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user6.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;


				width: 90px;
				height: 50px;
				font-size: 12px;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.othersHome_value {
				width: 78px;
				height: 40px;
				font-size: 8px;
				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user7.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;


				width: 90px;
				height: 50px;
				font-size: 12px;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.others_operationBox {


				width: 100%;
				display: flex;
				align-items: center;
				margin-top: 20px;
				color: #333;

				.others_operation {
					width: 25%;
					height: 50px;
					font-size: 12px;
					margin-bottom: 15px;

					.others_operationIcon {
						width: 24px;
						height: 24px;
						margin-bottom: 10px;
					}
				}

				.cut_offRule {
					width: 100%;
					height: 1px;
					margin: 20px 0 15px 0;
					background-color: rgba($color: #fff, $alpha: 0.04);
				}


			}
		}
	}

	// 他人名片
	.othersHome {
		// width: 262px;
		// height: 245px;
		position: absolute;
		right: -100%;
		bottom: 15%;
		border-radius: 8px;
		z-index: 999;

		.othersHome_head {
			width: 62px;
			height: 62px;
			background: #fff;
			border-radius: 50%;
			margin-bottom: 6px;
			position: relative;

			>img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}

		.othersHome_icon {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 12px;
			left: 12px;
			background: url(../../static/141.png) no-repeat;
			background-size: 100%;
			cursor: pointer;
			z-index: 1;
		}

		.othersHome_top {
			transform: translateY(-30%);
		}

		.othersHome_labelBox {
			position: absolute;
			top: 85px;
			left: 10px;
		}

		.othersHome_label {
			color: #7c4ff0;
			font-size: 10px;
			padding: 1px 7px;
			border-radius: 10px;
			background: #fff;
			margin-right: 6px;
		}

		.othersHome_topBox {
			width: 100%;
			height: 121px;
			// padding: 10px;
			box-sizing: border-box;
			background: url(../../static/160.png) no-repeat;
			background-size: 100%;
			border-radius: 8px 8px 0px 0px;
			color: #333;
		}

		.othersHome_bottom {
			padding: 10px;
			box-sizing: border-box;
			position: relative;

			.othersHome_bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				filter: blur(1px);
				border-radius: 0 0 8px 8px;
				background: #fff;
				z-index: 0;
			}

			.othersHome_value1 {

				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user5.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;
				width: 90px;
				height: 50px;
				font-size: 12px;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.othersHome_value2 {
				width: 90px;
				height: 50px;
				font-size: 12px;
				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user6.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.othersHome_value {
				width: 90px;
				height: 50px;
				font-size: 12px;
				padding: 5px;
				color: rgba(102, 102, 102, 1);
				background: url(../../static/user7.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;

				>.img {
					width: 40px;
					height: 40px;
					margin-right: 0px;
				}
			}

			.others_operationBox {
				width: 100%;
				display: flex;
				align-items: center;
				margin-top: 20px;
				color: #333;

				.others_operation {
					width: 25%;
					height: 50px;
					font-size: 12px;
					margin-bottom: 15px;

					.others_operationIcon {
						width: 24px;
						height: 24px;
						margin-bottom: 10px;
					}
				}

				.cut_offRule {
					width: 100%;
					height: 1px;
					margin: 20px 0 15px 0;
					background-color: rgba($color: #fff, $alpha: 0.04);
				}
			}
		}
	}

	.broadcast_right {
		position: absolute;
		top: 3%;
		right: 30%;
		overflow: hidden;

		.broadcast_rightItem {
			width: 314.93px;
			height: 60px;
			background: url(../../image/popout/2.png) no-repeat;
			animation-name: rightDynamic;
			animation-iteration-count: 1;
			animation-duration: 2s;

			.fullBand_img {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #fff;
				position: relative;
				left: 50px;
			}

			.fullBand_text {
				width: 120px;
				font-size: 8px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-content: center;
				margin-left: 56px;
			}

			.fullBand_textFlex {
				width: 190px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-left: 56px;
			}

			.fullBand_ben {
				width: 44px;
				height: 21px;
				color: #f68229;
				font-size: 12px;
				background: #ffffff;
				text-align: center;
				line-height: 21px;
				border-radius: 28px;
			}
		}
	}

	.blacklist_input /deep/.el-input__inner {
		border: 0;

		background-color: #f5f5f5;
	}

	/deep/.el-upload--picture-card {
		width: 0;
		height: 90px;
		background-color: transparent;
		border: 0;
	}

	// 滚动条样式
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.2);
	}

	/deep/.el-form-item__label {
		color: #333;
	}

	/deep/.el-input__inner {
		border: 0 !important;
		background-color: #f5f5f5;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #7e53f0;
		background: #7e53f0;
	}

	/deep/.el-textarea__inner {
		border: 0;

		color: #8e92b1;
		background-color: #f5f5f5;
	}

	/deep/.el-textarea__inner::placeholder {
		color: #8e92b1 !important;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #fff;
	}

	/deep/.el-input__inner::placeholder {
		color: #fff !important;
	}
	
	/deep/.el-input__inner {
		color: #fff !important;
	}

	::-webkit-scrollbar {
		// display: none;
		height: 2px;
	}

	#gift_list {
		// width: 100%;
		display: flex;
		align-items: center;
		margin: 10px 0 0 0;
		padding: 10px;
		// overflow-x: scroll;
		overflow-x: auto;
		overflow-y: hidden;

		.gift_item {
			width: 100px;
			text-align: center;
			position: relative;
			margin: 0 5px;

			>img {
				width: 33px;
				height: 33px;
				margin-bottom: 6px;
			}

			.giftWealThName {
				color: #515a00;
				font-size: 10px;
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
				background: linear-gradient(139deg, #fffc9b 0%, #e6ff00 100%);
			}
		}

		.gift_pitch {
			// margin: 0 10px;
			background: rgba(255, 255, 255, 0.2);
			border-radius: 10px;
		}
	}

	.report_topTextImg {
		width: 98px;
		height: 98px
	}

	.report_topTextImgText {
		width: 100%;
		text-align: center;
		margin-bottom: 30px
	}

	.report_topTextImgTextBtn {
		width: 176px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		background: #FFDD00;
		border-radius: 8px 8px 8px 8px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #333333;
		margin: 0 auto 24px;
	}

	@keyframes rightDynamic {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(0);
		}
	}

	/* 设置滚动条的样式 */
	/* 设置滚动条的轨道背景颜色 */

	/* 设置滚动条的滑块颜色 */
	::-webkit-scrollbar-thumb {
		background-color: #888;
	}

	/* 设置滚动条的滑块悬停时的颜色 */
	::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	}
</style>