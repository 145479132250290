<template>
  <div class="page">
    <!-- 注册页面 -->
    <img src="../../static/96.png" alt="" class="left_img" />
    <div class="login_box">
      <img
        src="../../static/59.png"
        alt=""
        class="quit_img"
        @click="skipHome()"
      />
      <div class="font-20 font_bold" style="color: #1d2030">手机号绑定</div>
      <img src="../../static/102.png" alt="" />
      <div
        class="font-12"
        style="color: #86909b; margin: 16px 0 22px 0; line-height: 20px"
      >
        <p>
          ·根据国家《网络音视频信息服务管理规定》，为了保证您的账号资金安全，请绑定您的手机号码。
        </p>
        <p>·若您的账号已绑定手机号，需您原绑定手机号验证身份，提升账号安全。</p>
      </div>
      <!-- <el-input v-model="phone" placeholder="请输入手机号" class="login_input">
        <img slot="prefix" class="login_icon" src="../../static/97.png" alt="">
      </el-input>
      <el-input v-model="password" placeholder="请输入密码"></el-input> -->
      <div class="login_form">
        <div class="position_r">
          <div class="login_icon"><img src="../../static/97.png" alt="" /></div>
          <input
            type="text"
            v-model="phone"
            placeholder="请输入手机号"
            class="login_input"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
            maxlength="11"
          />
        </div>
        <div class="position_r flex-aling">
          <input
            type="text"
            v-model="code"
            placeholder="验证码"
            class="login_code"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
            maxlength="6"
          />
          <div
            class="font-20 cursor"
            style="color: #7c4ff0; margin-left: 18px"
            @click="code = '369258'"
          >
            获取验证码
          </div>
        </div>
        <div class="position_r">
          <div class="login_icon"><img src="../../static/98.png" alt="" /></div>
          <input
            type="text"
            v-model="password"
            placeholder="请输入密码(6~12位字母+数字)"
            class="login_input"
          />
          <div class="password_show">
            <img src="../../static/99.png" alt="" />
          </div>
        </div>
      </div>

      <div class="enter flex-content" @click="submitAuthentication()">登录</div>
    </div>
    <!-- 身份验证 -->
    <authentication v-if="authenticationShow"></authentication>
  </div>
</template>
<script>
import authentication from "./authentication.vue";
export default {
  name: "",
  components: {
    authentication, //身份验证
  },
  data() {
    return {
      authenticationShow: false,
      phone: "",
      password: "",
      code: "369258/", //验证码
    };
  },
  methods: {
    //身份验证
    submitAuthentication() {
      this.authenticationShow = true;
    },
    // 返回首页
    skipHome() {
      this.$router.push("/home");
    },
    skipLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 827px;
  height: 596px;
  display: flex;
  font-size: 16px;
  background: #fff;
}
.left_img {
  width: 360px;
  height: 596px;
}
.login_box {
  width: 100%;
  padding: 40px 40px 0 40px;
  position: relative;
}
.quit_img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login_input {
  width: 387px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 80px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.login_code {
  width: 267px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 20px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.login_icon {
  height: 20px;
  position: absolute;
  top: 25px;
  left: 25px;
  padding-right: 20px;
  border-right: 1px solid #ccc;
  > img {
    width: 20px;
    height: 20px;
  }
}
.password_show {
  position: absolute;
  top: 30px;
  right: 25px;
}
.enter {
  width: 387px;
  height: 56px;
  color: #fff;
  margin-top: 40px;
  font-size: 20px;
  background: linear-gradient(132deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 4px;
}
.quickLogin {
  width: 120px;
  margin: auto;
  color: #646c78;
  font-size: 13px;
  margin-top: 40px;
}
.loginType {
  margin-top: 20px;
  justify-content: space-between;
}
.else_login {
  width: 180px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6eaf1;
}
</style>
