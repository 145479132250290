<template>
  <div class="page">
    <!-- 帮助中心 -->
    <div class="help_box" v-if="helpType == 0">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>帮助中心</div>
        <img src="../../static/59.png" alt="" @click.stop="quitChange()" />
      </div>
      <!-- 内容 -->
      <div class="content_box">
        <div class="con_item" @click="getAgreement('platformRule')">
          <img src="../../image/help/1.png" alt="" />平台规则
        </div>
        <div class="con_item" @click="getAgreement('informationSpecification')">
          <img src="../../image/help/2.png" alt="" />信息规范
        </div>
        <div class="con_item" @click="getAgreement('authenticationBinding')">
          <img src="../../image/help/3.png" alt="" />认证绑定
        </div>
        <div class="con_item" @click="getAgreement('bigGodAgreement')">
          <img src="../../image/help/4.png" alt="" />成为大神
        </div>
        <div class="con_item" @click="getAgreement('orderIssues')">
          <img src="../../image/help/5.png" alt="" />订单问题
        </div>
        <div class="con_item" @click="getAgreement('depositWithdraw')">
          <img src="../../image/help/6.png" alt="" />充值提现
        </div>
        <div class="con_item" @click="getAgreement('privacyAgreement')">
          <img src="../../image/help/7.png" alt="" />隐私政策
        </div>
        <!-- <div class="con_item" @click="getAgreement('realService')">
          实名认证协议
        </div> -->
        <div class="content_bottom flex">
			
        <div
            class="bottm_item flex"
            style="margin-right: 10px"
            @click="helpTypeChange()"
          >
            <img src="../../image/help/8.png" alt="" />
            <div class="bottom_text">
              <div>意见反馈</div>
              <p style="color:#999999">使用过程中的意见、建议</p>
            </div>
          </div>
          <div class="bottm_item flex" @click.stop="getService()">
            <img src="../../image/help/9.png" alt="" />
            <div class="bottom_text">
              <div>在线客服</div>
              <p style="color:#999999">订单异常、投诉举报</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页面弹窗 myq-->
    <div class="help_box" v-if="helpType == 1">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>帮助中心</div>
        <img src="../../static/59.png" alt="" @click.stop="helpType = 0" />
      </div>
      <!-- 内容 -->
      <!-- <div style="padding: 25px 20px 0 20px">绑定认证</div> -->
      <div class="help_content" style="padding: 25px 20px">
        <div v-html="agreementText" class="font-14 agreement_style" style=" padding-top: 16px"></div>
      </div>
    </div>
    <!-- 意见反馈 -->
    <div
      class="feedback_box"
      v-if="helpType == 2"
      :style="{ left: recordShow ? '30%' : '50%' }"
    >
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>意见反馈</div>
        <img src="../../static/59.png" alt="" @click.stop="helpType = 0" />
      </div>
      <!-- 内容 -->
      <div class="feedback_cont">
        <div class="padding_10">反馈类型</div>

        <div class="flex">
          <el-select
          v-model="typeValue"
          class="feedback_select"
          placeholder="请选择"
          :placeholderStyle="{ color: '#6a6a94' }"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.title"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          class="flex-content" style="margin-left:10px;color:#4177DD"
          @click="getQueryFeedbackList()"
        >
          反馈记录
        </div>
        </div>
        
        <div class="padding_10">问题描述</div>

        <textarea
          v-model="textareaSeason"
          placeholder="请输入内容"
          class="feedback_textarea"
          maxlength="180"
        ></textarea>
        <div class="flex_wrap" style="margin-top:10px;width:350px">
          <div style="position: relative;">

            <img v-for="(item,imgkey) in reportImgList" :key="imgkey" :src="item" alt="" class="report_img">
          </div>
          <el-upload
              class="avatar-uploader"
              action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
            <img src="../../image/my/18.png" alt="" />
          </el-upload>
        </div>
        <div class="feedback_btn flex-content" @click="setAddFeedback()">确认提交</div>
        
      </div>
    </div>
    <!-- 反馈记录 -->
    <div class="record_box" v-if="recordShow">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>反馈记录</div>
        <img
          src="../../static/59.png"
          alt=""
          @click.stop="recordShow = false"
        />
      </div>
      <!-- 内容 -->
      <div class="record_cont">
        <div class="flex" v-for="(item,key1) in feedbackList" :key="key1" style="margin-bottom:14px">
          <img :src="item.avatar" alt="" class="record_head" />
          <div>
            <div class="record_text">
              {{ item.content }}
            </div>
            <div class="img_box flex-warp flex" >
              <div class="flex" v-for="(imgItem,key2) in item.image" :key="key2" >
                <img :src="imgItem" alt="" class="img_item" v-if="imgItem != ''"/>
              </div>
            </div>
            <div class="padding_6">反馈类型:<span>{{item.type}}</span></div>
            <div>反馈时间:<span>{{item.createTime}}</span></div>
            <!-- <div class="platform_record">
              平台回复：您的反馈已收到，我们会尽快处理
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeOptions: [],
      typeValue: "",
      text: "",
      helpType: 0,
      textareaSeason:'',//反馈内容
      recordShow: false, //反馈记录
      reportImgList:[],//举报图片列表
      feedbackList:[],//反馈记录
      agreementText:'',//协议内容
    };
  },
  mounted() {
  },
  methods: {
    //打开在线客服
    getService(){
      // window.localtion.href = 'https://pc.yinsuwangluokj.com/rcrtc/#/chat.html'
      // location.href = 'https://pc.yinsuwangluokj.com/rcrtc/#/chat.html'
      var a = document.createElement("a");
      a.setAttribute("href", "https://jn.t9cp.com/chatlink.html");
      a.setAttribute("target", "_blank");
      a.click();
    },
    // 获取反馈记录
    getQueryFeedbackList(){
      this.recordShow = true
      this.helpType = 2
      this.$api.getQueryFeedbackList().then(res=>{
        // console.log(res);
        if(res.code == 1){
          this.feedbackList = res.data.rows
          // this.feedbackList.image = this.feedbackList.image.split(',')
          this.feedbackList.map(item=>{
            item.image = item.image.split(',')
          })
        }
      })
    },
    // 显示意见反馈
    helpTypeChange(){
      this.helpType = 2
      this.reportImgList = []
      this.getQueryFeedbackType()
    },
    // 提交反馈
    setAddFeedback(){
      if(!this.typeValue) return this.$message.info('请输入反馈内容')
      this.$api.setAddFeedback({
        image:this.reportImgList.toString(),
        type:this.typeValue,
        content:this.textareaSeason,
      }).then(res=>{
        if(res.code == 1){
          this.helpType = 0
          this.typeValue = ''
          this.textareaSeason = ''
          this.reportImgList = []
          this.recordShow = false
          this.$message.info('举报已经反馈')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 反馈类型
    getQueryFeedbackType(){
      this.$api.queryFeedbackType().then(res=>{
        // console.log('反馈类型',res);
        if(res.code == 1){
          this.typeOptions = res.data
        }
      })
    },
    handleAvatarSuccess(res){
      if (res.code == 1) {
        this.reportImgList.push(res.data.url);
      }
    },
    // 获取协议数据
    getAgreement(configName){
      this.$api.getAgreement({configName}).then(res=>{
        if(res.code == 1){
          this.helpType = 1
          this.agreementText = res.data
        }
      })
    },
    // 关闭页面
    quitChange() {
      this.$emit("closeHelpindex");
    },
  },
};
</script>

<style scoped lang="scss">
.agreement_style{
  color:#333 ;
}
.report_img{
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
}
// 反馈记录
.record_box {
  width: 679px;
  height: 664px;
  position: absolute;
  top: 50%;
  left: 64%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  .record_cont {
    color: #6a6a94;
    font-size: 12px;
    padding: 40px 0 0 83px;
    .record_head {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .record_text {
      width: 490px;
    }
    .img_box {
      width: 490px;
      margin: 12px 0;
      .img_item {
        width: 64px;
        height: 64px;
        margin-right: 8px;
        border-radius: 8px;
      }
    }
    .platform_record {
      width: 490px;
      height: 36px;
      color: #d3d4dc;
      padding: 10px 8px;
      margin-top: 17px;
      background: #252b4a;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}
// 意见反馈
.feedback_box {
  width: 440px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  .feedback_cont {
    width: 320px;
    color: #333;
    margin: 40px auto;
  }
  .feedback_textarea {
    width: 320px;
    height: 108px;
    border: none; // 去除边框
    outline: none; // 去除聚焦边框
    resize: none; // 去除右下的可拖动
    appearance: none; // 去除内阴影样式
    background: #F5F5F5;
    border-radius: 4px;
    color:#333;
	padding:10px;
  }
  .feedback_textarea::-webkit-input-placeholder {
    color: #8E92B1;
  }

  .feedback_btn {
    width: 320px;
    height: 40px;
    color: #333;
    margin-top: 40px;
    background: #ffdd00;
    border-radius: 4px;
  }
  
  .feedback_text {
    width: 100%;
    color: #6a6a94;
    margin-top: 24px;
  }
  .feedback_select {
    width: 240px;
    height: 36px;
    background: #F5F5F5;
    border-radius: 4px;
  }
}

::v-deep .el-input__inner {
  color: #333;
  background-color: rgba(54, 54, 89, 0.2);
}
.page {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  font-size: 16px;
  color: #333333;
  background: rgba($color: #0000, $alpha: 0.8);
}
.help_box {
  width: 630px;
  height: 446px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  border-radius: 4px 4px 4px 4px;
  .title_content {
    > img {
      width: 13px;
      height: 13px;
    }
  }
  .content_box {
    padding: 22px 50px 22px 60px;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    .con_item {
      width: 120px;
      height: 40px;
      color: #333;
      font-size: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 10px 0;
      background: #F5F5F5;
      > img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }
  .content_bottom {
    margin-top: 141px;
    .bottm_item {
      width: 250px;
      height: 67px;
      display: flex;
      align-items: center;
      background: #F5F5F5;
      > img {
        width: 23px;
        height: 23px;
        margin: 0 10px 0 18px;
      }
    }
    .bottom_text {
      > div {
        font-size: 12px;
        color: #333;
      }
      > p {
        font-size: 10px;
        margin-top: 4px;
        color: rgba(142, 146, 177, 1);
      }
    }
  }
  .help_content {
    width: 90%;
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}
/deep/.el-input__inner::placeholder{
  color:#333 !important;
}

</style>
