<template>
  <div class="orderPage">
    <!-- 个人中心——我的订单 -->
    <div class="order_tab flex">
      <div
        class="tab_item XS"
        v-for="(tabItem, key0) in tabList"
        :key="key0"
        :class="tabType == key0 ? 'tab_pitch' : ''"
        @click="orderSwitchover(key0)"
      >
        {{ tabItem }}
      </div>
    </div>
    <!-- 陪玩订单 表格 -->
    <div v-if="tabType == 0">
      <div class="title_box flex-aling">
        <div class="title_item" style="width: 210px">陪玩大神</div>
        <div class="title_item" style="width: 140px">陪玩类型</div>
        <div class="title_item" style="width: 80px">单价</div>
        <div class="title_item" style="width: 80px">数量</div>
        <div class="title_item" style="width: 90px">总价</div>
        <div class="title_item" style="width: 90px">实付金额</div>
        <div class="title_item" style="width: 100px">状态</div>
        <div class="title_item" style="width: 120px">操作</div>
      </div>
      <div
        class="content_box flex-content"
        style="height: 200px; color: #1d223b; font-size: 14px"
        v-if="orderList.length == 0"
      >
        暂无数据
      </div>
      <div class="content_box" v-else>
        <div class="order_item" v-for="item in orderList" :key="item.id">
          <!-- 头部 -->
          <div class="order_top flex-aling">
            <div style="color: #8f8f8e">{{ item.createTime }}</div>
            <div style="margin-left: 72px">订单号：{{ item.orderNo }}</div>
            <div
              class="flex-aling"
              style="
                color: rgba(65, 119, 221, 1);
                margin-left: 72px;
                cursor: pointer;
              "
              @click.stop="privateLetter(item, 1,item.orderTakerId)"
            >
              <img
                src="../../image/my/8.png"
                alt=""
                style="margin-right: 10px"
              />联系大神
            </div>
          </div>
          <!-- 头部end -->
          <div class="order_box">
            <div class="order_tb flex-content" style="border: 0; width: 210px">
              <img :src="item.avatar" alt="" class="order_haed" />
              {{ item.nickname }}
            </div>
            <div class="order_tb flex-content" style="width: 140px">
              
              <div class="skills_icon"><img :src="item.logo" alt="" style="width:14px;height:14px;margin-right:5px"  />{{ item.labelName }}</div>
            </div>
            <div class="order_tb flex-content"><img src="../../static/195.png" alt="" style="width:14px;height:14px"  />{{ item.price }}</div>
            <div class="order_tb flex-content" style="width: 80px">
              {{ item.number }}
            </div>
            <div class="order_tb flex-content" style="width: 90px">
              <img src="../../static/195.png" alt="" style="width:14px;height:14px"  />{{ item.price * item.number }}
            </div>
            <div class="order_tb flex-content" style="width: 90px">
              {{ item.paymentAmount }}
            </div>
            <div class="order_tb flex-content">
              <!-- 接单状态，0待接单，1待开始，2服务中，3已完成，4退款，5取消，6大神拒单，7超时未接单 -->
              <div class="color_red" @click.stop="examineCause(item)">
                {{ item.receiveStatus | orderStatus }}
              </div>
            </div>
            <div class="order_tb flex-dir" style="width: 140px">
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 0"
                @click="sendMassage(item,0)"
              >
                提醒接单
              </div>
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 1"
                @click="sendMassage(item,1)"
              >
                提醒开始
              </div>
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 2"
                @click="endOrder(item.id)"
              >
                确认完成
              </div>
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 3 && item.isEvaluation != 1"
                @click="evaluateShowChange(item)"
              >
                去评价
              </div>
              <div
                style="color: #cf8234"
                v-if="item.receiveStatus == 0 || item.receiveStatus == 1"
                @click="cancelOrder(item.id)"
              >
                取消订单
              </div>
              <div
                style="color: #1d223b;cursor: pointer;"
                v-if="item.receiveStatus == 2 || item.receiveStatus == 3"
                @click="
                  complaintShow = true;
                  complaintId = item.id;
                "
              >
                投诉
              </div>
              <!-- <div
                style="color: #1d223b"
                v-if="item.receiveStatus > 4"
                @click="orderDelete(item.id)"
              >
                删除订单
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的接单 表格 -->
    <div v-if="tabType == 1">
      <div class="title_box flex-aling">
        <div class="title_item" style="width: 210px">陪玩大神</div>
        <div class="title_item" style="width: 140px">陪玩类型</div>
        <div class="title_item" style="width: 80px">单价</div>
        <div class="title_item" style="width: 80px">数量</div>
        <div class="title_item" style="width: 90px">总价</div>
        <div class="title_item" style="width: 90px">实付金额</div>
        <div class="title_item" style="width: 100px">状态</div>
        <div class="title_item" style="width: 120px">操作</div>
      </div>
      <div
        class="content_box flex-content"
        style="height: 200px; color: #1d223b; font-size: 14px"
        v-if="myOrderList.length == 0"
      >
        暂无数据
      </div>
      <div class="content_box" v-else>
        <div class="order_item" v-for="item in myOrderList" :key="item.id">
          <!-- 头部 -->
          <div class="order_top flex-aling">
            <div style="color: #8f8f8e">{{ item.createTime }}</div>
            <div style="margin-left: 72px">订单号：{{ item.orderNo }}</div>
            <div
              class="flex-aling"
              style="color: rgba(65, 119, 221, 1); margin-left: 72px; cursor: pointer"
              @click.stop="privateLetter(item, 2,item.orderPersonId)"
            >
              <img
                src="../../image/my/8.png"
                alt=""
                style="margin-right: 10px"
              />联系用户
            </div>
          </div>
          <!-- 头部end -->
          <div class="order_box">
            <div class="order_tb flex-content" style="border: 0; width: 210px">
              <img :src="item.avatar" alt="" class="order_haed" />
              {{ item.nickname }}
            </div>
            <div class="order_tb flex-content" style="width: 140px">
              <div class="skills_icon" style="display:flex;align-items: center; justify-content: center;"><img :src="item.logo" alt="" style="width:14px;height:14px;margin-right:5px"  /> {{ item.labelName }}</div>
            </div>
            <div class="order_tb flex-content"><img src="../../static/195.png" alt="" style="width:14px;height:14px"  /> {{ item.price }}</div>
            <div class="order_tb flex-content" style="width: 80px">
              {{ item.number }}
            </div>
            <div class="order_tb flex-content" style="width: 90px">
              <img src="../../static/195.png" alt="" style="width:14px;height:14px"  />  {{ item.price * item.number }}
            </div>
            <div class="order_tb flex-content" style="width: 90px">
              <img src="../../static/195.png" alt="" style="width:14px;height:14px"  /> {{ item.paymentAmount }}
            </div>
            <div class="order_tb flex-content">
              <!-- 接单状态，0待接单，1待开始，2服务中，3已完成，4退款，5取消，6大神拒单，7超时未接单 -->
              <div class="color_red" @click.stop="examineCause(item)">
                {{ item.receiveStatus | orderStatus }}
              </div>
            </div>
            <div class="order_tb flex-dir" style="width: 140px">
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 0"
                @click="takeOrder(item.id)"
              >
                立即接单
              </div>
              <div
                class="remind_btn flex-content"
                v-if="item.receiveStatus == 1"
                @click="startOrder(item.id)"
              >
                开始服务
              </div>

              <div
                style="color: #cf8234"
                v-if="item.receiveStatus == 0"
                @click="rejectOrder(item.id)"
              >
                拒绝接单
              </div>
              <div style="color: #1d223b" v-if="item.receiveStatus == 2">
                进行中
              </div>
              <div style="color: #c9c9c9" v-if="item.receiveStatus == 3">
                已结束
              </div>
              <!-- <div
                style="color: #1d223b"
                v-if="item.receiveStatus > 4"
                @click="orderDelete(item.id)"
              >
                删除订单
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 道具订单列表 -->
    <div v-if="tabType == 2">
      <div class="title_box flex-aling">
        <div class="title_item" style="width: 350px">道具信息</div>
        <div class="title_item" style="width: 180px">道具类型</div>
        <div class="title_item" style="width: 180px">规格数量</div>
        <div class="title_item" style="width: 220px">实付金额</div>
      </div>
      <div
        class="content_box flex-content"
        style="height: 200px; color: #1d223b; font-size: 14px"
        v-if="orderList.propList == 0"
      >
        暂无数据
      </div>
      <div class="content_box" v-else>
        <div class="order_item" v-for="item in propList" :key="item.id">
          <!-- 头部 -->
          <div class="order_top flex-aling">
            <div style="color: #8f8f8e">{{ item.createTime }}</div>
            <div style="margin-left: 72px">订单号：{{ item.orderNo }}</div>
          </div>
          <!-- 头部end -->
          <div class="order_box">
            <!-- 道具信息 -->
            <div class="order_tb flex-content" style="width: 350px">
              <img
                :src="item.logo"
                alt=""
                class="order_haed"
                style="border-radius: 8px"
              />
              {{ item.name }}
            </div>
            <!-- 道具类型 -->
            <div class="order_tb flex-content" style="width: 180px">
              {{ item.labelName }}
            </div>
            <!-- 规格数量 -->
            <div class="order_tb flex-content" style="width: 180px">
              ×{{item.number}}{{item.unit}}
            </div>
            <!-- 实付金额 -->
            <div class="order_tb flex-content" style="width: 220px">
              <img src="../../static/61.png" alt="" />
              {{ item.paymentAmount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评价弹窗 -->
    <div class="popUp_box" v-if="evaluateShow">
      <div class="remid_box" style="width: 679px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">评价大神</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="evaluateShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="report_from">
          <div>
            <div class="flex-content" style="margin-top: 40px">
              <img
                src="../../image/message/26.png"
                alt=""
                v-for="(item, key1) in evaluateNum"
                :key="key1 + 'a'"
                @click="evaluateChange(1, key1)"
              />
              <img
                src="../../image/message/27.png"
                alt=""
                v-for="(item, key2) in 5 - evaluateNum"
                :key="key2 + 'b'"
                @click="evaluateChange(2, key2)"
              />
            </div>
            <div class="flex-content" style="margin-top: 20px; color: #333">
              {{ evaluateNum | evaluateStatus }}
            </div>
          </div>
          <div class="report_fromTitle" style="color: #333; margin-top: 37px">
            您的评价可以帮助大神赢得更多支持
          </div>
          <el-input
            type="textarea"
            placeholder="请输入评价~"
            v-model="evaluateText"
            maxlength="200"
            :rows="4"
            resize="none"
            class="textarea"
          >
          </el-input>
          <div class="report_btn flex-content" @click="evaluationOrder()">
            立即评价
          </div>
        </div>
      </div>
    </div>
    <!-- 投诉弹窗 -->
    <div class="popUp_box" v-if="complaintShow">
      <div class="remid_box" style="width: 679px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14" style="color: #333">订单投诉</div>
          <img src="../../static/quit.png" alt="" @click="complainEmpty()" />
        </div>
        <!-- 头部end -->
        <div class="report_from">
          <div class="report_fromTitle" style="color: #333; margin-top: 37px">
            投诉内容
          </div>
          <el-input
            type="textarea"
            placeholder="请输入投诉内容"
            v-model="complaintText"
            maxlength="200"
            :rows="4"
            resize="none"
            class="textarea"
          >
          </el-input>
          <div class="report_fromTitle">截图</div>
		 
          <div class="flex-aling">
            <div
              class="imgBox"
              v-for="(item, index) in complaintImgs"
              :key="index"
            >
              <img :src="item" alt="" style="width: 100%; height: 100%" />
              <img
                src="../../static/127.png"
                alt=""
                class="imgDeleIcon"
                @click.stop="imgDeleChange(index)"
              />
            </div>
            <el-upload
			accept='image/*'
              action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
              :limit="4"
              :show-file-list="false"
              list-type="picture-card"
              :on-success="complaintImgSuccess"
			  :before-upload="beforeAvatarUpload"
              v-if="complaintImgs.length < 4"
            >
              <img src="../../static/183.png" alt="" />
            </el-upload>
          </div>
          <div style="color: #6a6a94; font-size: 10px; margin-top: 20px">
            最多4张图片
          </div>
          <div class="report_btn flex-content" @click="complainOrder()">
            提交
          </div>
        </div>
      </div>
    </div>
    <!-- 我的消息 -->
   <!-- <myMessage
      ref="myMessage"
      v-show="myMessageShow"
      @closeMessage="myMessageShow = false"
      @placeSkip="placeSkipChange($event)"
    ></myMessage>
		<!-- <myMessage
      ref="myMessage"
      v-show="myMessageShow"
      style="top: -150px"
      @closeMessage="myMessageShow = false"
      @placeSkip="placeSkipChange($event)"

    ></myMessage> -->
     <!-- 他人主页 -->
     <othersHome
     @closeOthers="showChange('others')"
     :userId="manitoId"
     v-if="othersShow"
   ></othersHome>
    <!-- 拒绝接单提示 -->
    <el-dialog
      title="提示"
      :visible.sync="refuseValue"
      width="30%"
      :modal="false"
      :before-close="handleClose"
    >
      <div>
        确认拒单后订单将自动结束，用户查看原因为【{{
          refuseText
        }}】,确认拒绝接单吗？
      </div>
      <div
        class="flex"
        style="align-items: center; justify-content: center; margin: 12px 0"
        @click="refusePopupShow()"
      >
        拒绝接单说明<img src="@/static/41.png" alt="" style="width: 18px" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseValue = false">取 消</el-button>
        <el-button type="primary" @click="refuseOrder()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 拒绝接单说明 -->
    <el-dialog
      title="拒绝接单说明"
      :visible.sync="explainShow"
      width="30%"
      :modal="false"
      :before-close="handleClose"
    >
      <div v-html="orderExplain"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="explainShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import othersHome from "../homePage/othersHome.vue";
import myMessage from "../homePage/messagePage.vue"; //我的消息
export default {
  name: "",
  components: { myMessage ,othersHome},
  data() {
    return {
      tabList: ["陪玩订单", "我的接单", "道具订单"],
      titleList: [],
      tabType: 0,
      manitoId:0,
      othersShow:false,
      myOrderList: [], //我的订单列表
      orderList: [], //陪玩订单列表
      propList: [], //道具订单列表
      evaluateShow: false, //评价弹窗
      evaluateNum: 5, //评价
      evaluateText: "",
      orderPitch: {}, //选中订单数据
      complaintShow: false, //投诉弹窗
      userInfo: {}, //用户信息
      myMessageShow: false, //我的消息是否显示
      complaintId: "", //投诉订单id
      complaintText: "", //投诉内容
      complaintImgs: [], //投诉图片
      refuseText: "", //拒绝原因
      refuseValue: false,
      refuseId: "",
      explainShow: false,
      orderExplain: "",
    };
  },
  mounted() {
    this.playWithList();
    this.getInfo();
  },
  methods: {
	  imgDeleChange(index){
		this.complaintImgs.splice(index,1)  
	  },
	  //限制只能上传图片
	  beforeAvatarUpload(file){
		  console.log(file)
		  const isLt2M = file.size / 1024 / 1024 < 2;
		  if (!isLt2M) return this.$message.error('图片大小不能超过 2MB!');
	  },
    //查看拒绝原因
    examineCause(i) {
      console.log(i, "iiiiiii");
      // if(i.receiveStatus){
      //   this.$alert(i.denialReason, '拒单原因', {
      //     confirmButtonText: '确定',
      //   });
      // }
    },
    // 我的消息跳转他人主页
    placeSkipChange($event) {
      this.myMessageShow = false;
      this.manitoId = $event;
      this.othersShow = true;
    },
    showChange(){
      this.othersShow = false;
    },
    sendMassage(item,index) {
      let that=this
      let message = this.chat.createTextMessage({
        to: item.orderTakerId,
        conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
        // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: index==0?"我发起了一笔陪玩订单，赶快接单吧~":"我已准备完毕随时可以开始",
        },
        // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        needReadReceipt: true,
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
        // cloudCustomData: 'your cloud custom data'
      });
      // 2. 发送消息
      let promise = this.chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          // 发送成功
         that.$message('提醒成功')
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });

      // let data = {
      //   cmd: "CustomCmdMsg",
      //   data: {
      //     cmd: "chat_msg",
      //     content: "我发起了一笔陪玩订单，赶快接单吧~",
      //     logo: this.userInfo.logo,
      //     userId: this.userInfo.id,
      //     userName: this.userInfo.nickname,
      //     enter: 3,
      //   },
      // };
      // let message = that.chat.createCustomMessage({
      //   to: this.roomId,
      //   conversationType: TencentCloudChat.TYPES.CONV_GROUP,
      //   payload: {
      //     data: JSON.stringify(data),
      //     description: JSON.stringify(data),
      //     extension: JSON.stringify(data),
      //   },
      // });
      // let promise = this.chat.sendMessage(message);
      // promise
      //   .then(function (imResponse) {
      //     // 发送成功
      //     that.chatContentData.push(
      //       JSON.parse(imResponse.data.message.payload.data)
      //     );
      //     that.chatInput = "";
      //   })
      //   .catch(function (imError) {
      //     // 发送失败
      //     console.warn("sendMessage error:", imError);
      //   });

      // console.log(item, "itemitem");
    },
    // 私信
    privateLetter(i, type,id) {
      i.userProfile = {
        userID: id,
      };
      // this.$refs.myMessage.selectChat(i, "", id);
      // this.myMessageShow = true;
      this.$emit("closeOthers",i, "", id);
    },
    // 个人信息
    getInfo() {
      this.$api.queryUser({ otherUserId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
        }
      });
    },
    handleClose() {
      this.explainShow=false
    },
    // 投诉弹窗清空
    complainEmpty() {
      this.complaintShow = false;
      this.complaintText = "";
      this.complaintImgs = [];
    },
    // 订单投诉
    complainOrder() {
      if (!this.complaintText) return this.$message.info("请输入投诉内容");
      let data = {
        orderId: this.complaintId, //投诉订单id
        content: this.complaintText, //投诉内容
        imgUrl: this.complaintImgs.toString(), //投诉截图
      };
      this.$api.complainOrder(data).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
          this.complainEmpty();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 投诉图片上传
    complaintImgSuccess(res) {
		console.log(res)
      if (res.code == 1) {
        this.complaintImgs.push(res.data.url);
      }
    },
    // 显示评价
    evaluateShowChange(i) {
      this.orderPitch = i;
      this.evaluateShow = true;
    },
    // 评价订单
    evaluationOrder() {
      if (!this.evaluateText) return this.$message.info("请输入评价内容");
      let data = {
        evaluation: this.evaluateText, //	评价内容
        orderId: this.orderPitch.id, //	被评价订单id
        score: this.evaluateNum, //	评分（0-5）
      };
      this.$api.evaluationOrder(data).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
          this.evaluateShow = false;
          this.evaluateNum = 5;
          this.evaluateText = "";
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 评价星级 type 1加 2减
    evaluateChange(type, index) {
      if (type == 1) {
        this.evaluateNum = index + 1;
      } else {
        this.evaluateNum = this.evaluateNum + (index + 1);
      }
    },
    // 大神开始订单
    startOrder(id) {
      this.$api.startOrder({ orderId: id }).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
        }
      });
    },
    // 陪玩大神接单
    takeOrder(id) {
      this.$api.takeOrder({ orderId: id }).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
        }
      });
    },
    // 完成订单
    endOrder(id) {
      this.$api.endOrder({ orderId: id }).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
        }
      });
    },
    // 拒绝接单说明
    refusePopupShow() {
      this.$api.getAgreement({ configName: "rctrMessage" }).then((res) => {
        if (res.code == 1) {
          this.orderExplain = res.data;
          this.explainShow = true;
        }
      });
    },
    //确认拒绝接单
    refuseOrder() {
      this.$api.rejectOrder({ orderId: this.refuseId }).then((res) => {
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
          this.refuseValue = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      });
    },
    // 拒绝接单
    rejectOrder(id) {
      this.$api
        .getAgreement({ configName: "rejectOrderReason" })
        .then((res) => {
          if (res.code == 1) {
            this.refuseText = res.data;
            this.refuseId = id;
            this.refuseValue = true;
          }
        });
    },
    // 取消订单
    cancelOrder(id) {
      this.$confirm('确认取消订单？').then(res=>{
        this.$api.cancelOrder({ orderId: id }).then((res1) => {
        if (res1.code == 1) {
          this.playWithList();
          this.takeMyOrders();
        }
      });
      }).catch(res=>{

      })
     
    },
    // 通过id删除订单
    orderDelete(id) {
      this.$api.orderDelete({ id, deluserId: this.userInfo.id }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.playWithList();
          this.takeMyOrders();
          this.$message.success(res.msg);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 订单切换
    orderSwitchover(key) {
      this.tabType = key;
      if (key == 0) {
        this.playWithList();
      } else if (key == 1) {
        this.takeMyOrders();
      } else {
        this.propsOrder();
      }
    },
    // 陪玩订单列表
    playWithList() {
      this.$api.playWithList().then((res) => {
        if (res.code == 1) {
          this.orderList = res.data.rows;
        }
      });
    },
    // 我的订单列表
    takeMyOrders() {
      this.$api.takeMyOrders({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code == 1) {
          this.myOrderList = res.data.rows;
        }
      });
    },
    // 道具订单列表
    propsOrder() {
      this.$api.propsOrder().then((res) => {
        if (res.code == 1) {
          this.propList = res.data.rows;
        }
      });
    },
  },
  filters: {
    orderStatus: (value) => {
      let arrayData = {
        0: "待接单",
        1: "待开始",
        2: "服务中",
        3: "已完成",
        4: "退款",
        5: "取消",
        6: "大神拒单",
        7: "超时未接单",
      };
      return arrayData[value];
    },
    evaluateStatus: (value) => {
      let arrayData = {
        1: "差评",
        2: "一般",
        3: "一般",
        4: "满意",
        5: "非常满意",
      };
      return arrayData[value];
    },
  },
};
</script>
<style scoped lang='scss'>
.imgBox {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  .imgDeleIcon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  .remid_box {
    width: 522px;
    position: absolute;
    top: 30%;
    left: 35%;
    color: #ffffff;
    font-size: 14px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
  }
  .remind_btn {
    width: 176px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px 4px 4px 4px;
  }
  .report_from {
    width: 320px;
    margin: 0 180px;
    .report_fromTitle {
      color: #d3d4dc;
      font-size: 14px;
      margin: 20px 0 10px 0;
      text-align: left;
    }
    .report_btn {
      width: 250px;
      height: 40px;
      color: #333;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 40px;
      justify-content: center;
      background: rgba(255, 221, 0, 1);
      border-radius: 4px;
    }
  }
}
/deep/.el-textarea__inner {
  border: 0;
  color: #333;
  background-color: #f5f5f5 !important;
}
.textarea /deep/.el-textarea {
  color: #333;
  background: #f5f5f5 !important;
}
.textarea /deep/.el-input__count {
  color: #333;
  background: #f5f5f5 !important;
}
.textarea /deep/.el-textarea__inner {
  color: #333;
  background: #f5f5f5 !important;
}
.orderPage {
  width: 100%;
  height: 650px;
  color: #000000;
  padding: 0px 16px;
  background: #ffffff;
  box-sizing: border-box;
  overflow-y: auto;
}
// 表格
.title_box {
  width: 100%;
  height: 36px;
  color: #1d223b;
  margin-top: 20px;
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 2px;
  .title_item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.content_box {
  font-size: 12px;
  .order_item {
    .order_top {
      width: 100%;
      height: 36px;
      padding: 0 20px;
      margin-top: 16px;
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
    }
  }
  .order_box {
    display: flex;
    border-left: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    .order_tb {
      width: 100px;
      color: #999999;
      padding: 15px 0;
      border-right: 1px solid #f5f5f5;
      box-sizing: border-box;
    }
    .order_haed {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      margin-right: 16px;
    }
    .skills_icon {
      color: #fff;
      padding: 5px 9px;
      font-size: 12px;
      background: #363659;
      border-radius: 12px;
    }
    .color_red {
      color: #ff2a3e;
    }
    .remind_btn {
      width: 80px;
      height: 28px;
      color: #1d223b;
      margin-bottom: 12px;
      background: #f5f5f5;
      border-radius: 2px;
      border: 1px solid #e5e5e5;
    }
  }
}
.order_tab {
  /* height: 30px; */
  position: relative;
  .tab_item {
    color: #999;
    font-size: 14px;
    margin-right: 40px;
    padding: 16px 0 13px;
  }
  .tab_pitch {
    color: #212121;
    font-weight: 600;
    border-bottom: 2px solid #ffa800;
  }
}
//el 上传样式
/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}
</style>