<template>
  <div>
    <div style="color:#333;background-color: #fff;padding: 6px 12px 0;" v-if="!isSHowDetail">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我的动态" name="first">
          <div class="dynamicBox">
            <div class="dynamicItem flex" style="cursor: pointer;position: relative;" v-for="(items, index) in guangchangData" :key="index"
              @click="goPingLun(items)">
              <div style="position: absolute;right: 0;top: 0;" class="showMore">
                <div class="flex justify-end" style="justify-content: flex-end;height: 40px;align-items: center;">
                  <span style="font-weight: 600;width: 40px;text-align: center;line-height: 40rpx;">···</span>
                </div>
                <div class="btns"
                  style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
                  <div class="flex align-center" @click.stop="delForum(items)">
                    <img src="@/static/238.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                    <span>删除</span>
                  </div>
                </div>
              </div>
              <img :src="items.avatar" class="dynamicImg" alt="" />
              <div style="margin-left: 10px">
                <div class="font-16 flex flex-aling" style="font-weight: bold" :class="{'vipred':items.vipFlag==1}">{{ items.nickname }}
				<img src="../../static/vip.png"  v-if='items.vipFlag==1'
				 style="width: auto;height:13px;" /></div>
                <div class="font-12" style="color: #999999; margin: 8px 0 12px 0">
                  {{ items.createTimeDesc }}
                </div>
                <div class="font-14" style="color: #666666">
                  <span style="color: #4177dd" @click.stop="goTopic(items)" v-if="items.topicName">#{{ items.topicName }}</span>
                  {{ items.title }}
                </div>
                <!-- 视频 -->
                <div v-if="items.videoPicture" style="position: relative" @click="playMusic(items)">
                  <div style="margin-top:10px">
                    <video width="320" height="140" controls>
                      <source :src="items.video">
                      </source>
                    </video>
                  </div>
                </div>
                <!-- 图片 -->
                <template v-if="items.newImages && items.newImages.length > 0">
                  <div style="margin-top: 12px; width: 340px">
                    <img :src="itemImg" alt="" style="
                      width: 100px;
                      height: 100px;
                      border-radius: 4px;
                      margin: 0 8px 8px 0;
                    " v-for="(itemImg, indexImg) in items.newImages" :key="indexImg" />
                  </div>
                </template>
                <!-- 按钮 -->
                <div class="flex" style="margin-top: 16px">
                  <div class="flex flex-content share-btn" style="margin-right: 40px">
                    <img src="../../static/oth7.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>分享</span>
                    <div
                      class="share_popup"
                      style="left: -20px"
                    >
                      <div class="ipAddress">
                        <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                        <div
                          class="copyBtn"
                          style="color: #333; width: 68px; height: 32px"
                          @click.stop="
                            copyUserId('https://ser.t9cp.com/pch5/#/pages/newDownload')
                          "
                        >
                          复制
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike == 0" @click.stop="zanBtn(items)">
                    <img src="../../static/oth8.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>{{ items.isLike == 0 && items.likeNumber == 0 ? '首赞' : items.likeNumber }} </span>
                  </div>
                  <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike == 1" @click.stop="zanBtn(items)">
                    <img src="../../static/4.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span style="color:rgba(255, 167, 0, 1)">{{ items.isLike == 0
                      && items.likeNumber == 0 ? '首赞' :items.likeNumber }} </span>
                  </div>
                  <div class="flex flex-content" @click="goPingLun(items)">
                    <img src="../../static/oth9.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>评论</span>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-if="guangchangData.length == 0"></el-empty>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收藏动态" name="second">
          <div class="dynamicBox">
            <div class="dynamicItem flex" v-for="(items, index) in guangchangData" :key="index" @click="goPingLun(items)"
              style="cursor: pointer;position: relative;">
              <div style="position: absolute;right: 0;top: 0;" class="showMore">
                <div class="flex justify-end" style="justify-content: flex-end;height: 40px;align-items: center;">
                  <span style="font-weight: 600;width: 40px;text-align: center;line-height: 40rpx;">···</span>
                </div>
                <div class="btns"
                  style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
                  <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="collectForum(items)">
                    <img src="@/static/239.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                    <span>取消收藏</span>
                  </div>
                  <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="noSee(items, 2)">
                    <img src="@/static/240.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                    <span>不看TA</span>
                  </div>
                  <div class="flex align-center" @click.stop="jubao(items)">
                    <img src="@/static/241.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                    <span>举报</span>
                  </div>
                </div>
              </div>
              <img :src="items.avatar" class="dynamicImg" alt="" />
              <div style="margin-left: 10px">
                <div class="font-16 flex flex-aling" style="font-weight: bold"
				:class="{'vipred':items.vipFlag==1}">{{ items.nickname }}
				<img src="../../static/vip.png"  v-if='items.vipFlag==1'
				 style="width: auto;height:13px;" />
         <span v-if="items.wheatFollow != 0" style="border: 1px solid #FF7268;color: #FF7268;background-color: rgba(255, 114, 104, 0.10);font-size: 12px;padding: 2px 8px;font-weight: 600;border-radius: 4px;margin-left: 8px;">关注</span>
				</div>
                <div class="font-12" style="color: #999999; margin: 8px 0 12px 0">
                  {{ items.createTimeDesc }}
                </div>
                <div class="font-14" style="color: #666666">
                  <span style="color: #4177dd" @click.stop="goTopic(items)" v-if="items.topicName">#{{ items.topicName }}</span>
                  {{ items.title }}
                </div>
                <!-- 视频 -->
                <div v-if="items.videoPicture" style="position: relative" @click="playMusic(items)">
                  <div style="margin-top:10px">
                    <video width="320" height="140" controls>
                      <source :src="items.video">
                      </source>
                    </video>
                  </div>
                </div>
                <!-- 图片 -->
                <template v-if="items.newImages && items.newImages.length > 0">
                  <div style="margin-top: 12px; width: 340px">
                    <img :src="itemImg" alt="" style="
                      width: 100px;
                      height: 100px;
                      border-radius: 4px;
                      margin: 0 8px 8px 0;
                    " v-for="(itemImg, indexImg) in items.newImages" :key="indexImg" />
                  </div>
                </template>
                <!-- 按钮 -->
                <div class="flex" style="margin-top: 16px">
                  <div class="flex flex-content share-btn" style="margin-right: 40px">
                    <img src="../../static/oth7.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>分享</span>
                    <div
                      class="share_popup"
                      style="left: -20px"
                    >
                      <div class="ipAddress">
                        <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                        <div
                          class="copyBtn"
                          style="color: #333; width: 68px; height: 32px"
                          @click.stop="
                            copyUserId('https://ser.t9cp.com/pch5/#/pages/newDownload')
                          "
                        >
                          复制
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike == 0" @click.stop="zanBtn(items)">
                    <img src="../../static/oth8.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>{{ items.isLike == 0 && items.likeNumber == 0 ? '首赞' : items.likeNumber }} </span>
                  </div>
                  <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike == 1" @click.stop="zanBtn(items)">
                    <img src="../../static/4.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span style="color:rgba(255, 167, 0, 1)">{{ items.isLike == 0
                      && items.likeNumber == 0 ? '首赞' :items.likeNumber }}
                    </span>
                  </div>
                  <div class="flex flex-content" @click="goPingLun(items)">
                    <img src="../../static/oth9.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                    <span>评论</span>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-if="guangchangData.length == 0"></el-empty>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-else style="color:#333;width: 100%;height: 658px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;">
      <!-- 123123123 -->
      <div class="titleContent">
        <div style="display:flex;justify-content: space-between;align-items: center;padding-top:15px;position: relative;">
          <i class="el-icon-arrow-left" style="cursor: pointer;" @click="isSHowDetail = !isSHowDetail"></i>
          <div style="margin: 0 auto 0 12px;">
            动态详情
          </div>
          <div style="position: absolute;right: 0;top: 0;" class="showMore">
            <div class="flex justify-end" style="justify-content: flex-end;height: 40px;align-items: center;">
              <span style="font-weight: 600;width: 40px;text-align: center;line-height: 40rpx;">···</span>
            </div>
            <div class="btns" v-if="activeName == 'second'"
              style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
              <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="noSee(guangDetailData, 1)">
                <img src="@/static/240.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>不看TA</span>
              </div>
              <div class="flex align-center" @click.stop="jubao(guangDetailData)">
                <img src="@/static/241.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>举报</span>
              </div>
            </div>
            <div class="btns" v-else
                style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
                <div class="flex align-center" @click.stop="delForum(guangDetailData)">
                  <img src="@/static/238.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                  <span>删除</span>
                </div>
              </div>
          </div>
          <div>
            <!-- <el-popover style="backgorund:#333" placement="bottom" width="100" trigger="click">
              <div>
                <div style="display:flex" @click="noSee(guangDetailData, 1)">
                  <img src="../../static/234.png" alt="">
                  <div style="margin-left:5px">
                    不看TA
                  </div>
                </div>
                <div style="display:flex;margin-top:5px;" @click="jubao(guangDetailData)">
                  <img src="../../static/235.png" alt="">
                  <div style="margin-left:5px">
                    举报
                  </div>
                </div>
              </div>
              <img slot="reference" style="width: 19px; height: 16px;margin-right:14px" src="../../static/212.png"
                alt="" />
            </el-popover> -->
          </div>
        </div>
        <div class="ContentDetail">
          <div class="contentAvato">
            <img :src="guangDetailData.avatar" style="width:100%;height:100%;bor" alt="">
          </div>
          <div class="contentRight">
            <div class="contentRightTitle flex flex-aling" :class="{'vipred':guangDetailData.vipFlag==1}">
              {{ guangDetailData.nickname }}
			  <img src="../../static/vip.png"  v-if='guangDetailData.vipFlag==1'
			   style="width: auto;height:13px;" />
         <span v-if="guangDetailData.wheatFollow != 0" style="border: 1px solid #FF7268;color: #FF7268;background-color: rgba(255, 114, 104, 0.10);font-size: 12px;padding: 2px 8px;font-weight: 600;border-radius: 4px;margin-left: 8px;">关注</span>
            </div>
            <div class="contentRightTime">
              {{ guangDetailData.createTimeDesc }}
            </div>
            <div class="contentRightContent">
              <div> <span style="color:rgba(65, 119, 221, 1)"
                  v-if="guangDetailData.topicName" @click.stop="goTopic(guangDetailData)">#{{ guangDetailData.topicName }}</span> {{ guangDetailData.title }}
              </div>
            </div>
            <div style="margin-top:10px">
              <template v-if="guangDetailData.newImages && guangDetailData.newImages.length > 0">
                <img style="width:200px;height:200px;margin-right:10px" :src="item"
                  v-for="(item, index) in guangDetailData.newImages" :key="index" alt="">
              </template>
            </div>
            <div style="margin-top:10px">
              <template v-if="guangDetailData.video">
                <video width="420" height="300" controls>
                  <source :src="guangDetailData.video">
                  </source>
                </video>
              </template>
            </div>
          </div>
        </div>
        <div>
          <div class="contentDetailFlex">
          评价
          <span
            style="
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            "
            >{{ guangDetailData.commentNumber }}</span
          >
        </div>
        <div class="commentOvlea" v-if="commite && commite.length > 0">
          <div v-for="item in commite" :key="item.id">
            <div class="commentFlex" style="display: flex">
              <div>
                <img
                  :src="item.avatar"
                  style="
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    margin-left: 20px;
                    margin-top: 16px;
                  "
                  alt=""
                />
              </div>
              <div class="commentName flex flex-aling" :class="{'vipred':item.vipFlag==1}">{{ item.nickname }}
				<img src="../../static/vip.png"  v-if='item.vipFlag==1'
				 style="width: auto;height:13px;" />
			  </div>
            </div>
            <div class="commentConent" @click="checkPingLun(item)">
              {{ item.content }}
            </div>
            <div style="margin-left: 60px; margin-top: 6px; display: flex">
              <div class="commentTime">{{ item.createTimeDesc }}</div>
              <div
                @click.stop="zanBtn(item, 2, 2)"
                style="display: flex; align-items: center; margin-left: 16px"
              >
                <img
                  v-if="item.isLike == 1"
                  src="../../static/217.png"
                  alt=""
                />
                <img v-else src="../../static/214.png" alt="" />
                <span
                  v-if="item.isLike == 1"
                  style="
                    padding-left: 4px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffa700;
                    margin-top: 2px;
                  "
                  >{{ item.likeNumber }}</span
                >
                <span
                  v-else
                  style="
                    padding-left: 4px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333;
                    margin-top: 2px;
                  "
                  >{{ item.likeNumber }}</span
                >
              </div>
            </div>
            <div style="margin-left: 60px">
              <div v-for="(item1, index) in item.secondList" :key="index">
                <div class="secondFlex">
                  <img
                    style="width: 16px; height: 16px; border-radius: 50%"
                    :src="item1.avatar"
                    alt=""
                  />
                  <div
                    class="commentName"
                    style="
                      fontsize: 12px;
                      margin-left: -4px;
                      padding-bottom: 10px;
                      padding-top: 5px;
                    "
                  >
                    {{ item1.nickname }}
                    <span v-if="item1.otherUserId">回复</span>
                    {{ item1.otherUserId ? item1.otherNickname : "" }}
                  </div>
                </div>
                <div
                  class="commentConent"
                  style="fontsize: 12px; margin-left: 28px; margin-top: 0"
                >
                  {{ item1.content }}
                </div>
              </div>
              <div
                class="openMore"
                v-if="
                  item.secondCount > item.secondList.length &&
                  item.secondCount > 2
                "
                @click="openMore(item)"
              >
                展开更多
              </div>
              <div
                class="openMore"
                v-if="
                  item.secondCount == item.secondList.length &&
                  item.secondCount > 2
                "
                @click="getForumCommentList(guangDetailData.id)"
              >
                收起
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="contentBottom">
          <div class="flex" style="align-items: center;width: 700px;background: #f8f8f8;">
            <input style="
              height: 48px;
              border: none;
              flex: 1;
              background: #f8f8f8;
              padding-left: 24px;
              border-radius: 8px 8px 8px 8px;
            " :placeholder="currentPlInfo.id ? ('回复'+currentPlInfo.nickname) : '请输入评论'" v-model="inputModel" @keyup.enter="pingLunSend()" />
            <div
              v-if="currentPlInfo.id"
              @click="currentPlInfo = {}"
              class="sendPingBtns"
              style="right: 100px"
            >
              X
            </div>
            <div class="sendPingBtn" v-if="inputModel" style="margin-right: 24px;" @click="pingLunSend()">发送</div>
          </div>
          <div class="flex flex-content" style="margin-left: 40px" v-if="guangDetailData.isLike == 0" @click.stop="zanBtn(guangDetailData, 2)">
            <img src="../../static/oth8.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
            <span>{{ guangDetailData.isLike == 0 && guangDetailData.likeNumber == 0 ? '首赞' : guangDetailData.likeNumber }} </span>
          </div>
          <div class="flex flex-content" style="margin-left: 40px" v-if="guangDetailData.isLike == 1" @click.stop="zanBtn(guangDetailData, 2)">
            <img src="../../static/4.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
            <span style="color:rgba(255, 167, 0, 1)">{{ guangDetailData.isLike == 0
              && guangDetailData.likeNumber == 0 ? '首赞' :guangDetailData.likeNumber }} </span>
          </div>
          <div class="flex flex-content share-btn" style="margin-left: 40px;position: relative;">
            <img src="../../static/oth7.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
            <span>分享</span>
            <div
              class="share_popup"
              style="right: -20px"
            >
              <div class="ipAddress">
                <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                <div
                  class="copyBtn"
                  style="color: #333; width: 68px; height: 32px"
                  @click.stop="
                    copyUserId('https://ser.t9cp.com/pch5/#/pages/newDownload')
                  "
                >
                  复制
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <el-dialog :modal-append-to-body="false" :visible.sync="jubaoShow" :show-close="false" width="562px"
      :before-close="handleClose">
      <div>
        <div class="wheat_remind">
          <!-- 头部start -->
          <div class="titleIcon"></div>
          <div class="title_content" style="padding: 0 20px;">
            <div class="font-14">举报动态</div>
            <img src="../../static/quit.png" alt="" @click.stop="jubaoShow = false" />
          </div>
          <!-- 头部end -->
          <div style="
            width: 100%;
            height: 52px;
            background: rgba(255, 114, 104, 0.1);
            color: #ff7268;
            text-align: center;
          ">
            <div style="padding: 0 28px">
              举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
            </div>
          </div>
          <div style="margin-top: 24px; padding: 0 29px 39px">
            <div>举报原因</div>
            <div style="
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              flex-wrap: wrap;
            ">
              <div style="display: flex;font-size: 14px;align-items: center;width: 25%;margin-bottom: 12px;" v-for="item in personData" :key="item.id" @click="personBtn(item)">
                <div v-if="item.id == reasonData">
                  <img style="margin-right: 5px;width: 12px;height: 12px;" src="../../static/219.png" alt="" />
                </div>
                <div v-else>
                  <img style="margin-right: 5px;width: 12px;height: 12px;" src="../../static/242.png" alt="" />
                </div>
                {{ item.title }}
              </div>
            </div>
            <div class="noGuangchangTitle">
              <div>同时不看TA的广场信息</div>
              <div>
                <el-switch v-model="jubaoValue">
                </el-switch>
              </div>
            </div>
            <div class="corfinBtn" @click="jubaoSubmit">确认提交</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    othUserId: null,
  },
  data() {
    return {
      activeName: "first",
      userInfo: {},
      guangchangData: [],
      indexTab: 0,
      audio: "", // 创建音频对象
      videoFile: "",
      isSHowDetail: false,
      guangDetailData: {},
      inputModel: "",
      jubaoShow: false,
      reasonData: '',
      personData: [],
      jubaoValue: true,
      jubaoUserId: "",
      flages: true,
      commite: [],
      currentPlInfo: {},
    };
  },
  created() {
    this.getInfo();
    this.getHomepageList();
    this.getforumQueryById();
    this.getQueryReportReason();
  },
  mounted() {
    this.audio = new Audio();
  },
  methods: {
    copyUserId(id) {
      var input = document.createElement("input"); // 创建input对象
      input.value = id; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    goTopic(info) {
      console.log(info, 'infoinfo')
      this.$emit('goTopic', info)
    },
    // 选中评论选项
    checkPingLun(item) {
      this.currentPlInfo = item
    },
    openMore(item) {
      // console.log(item.id,'itemitem')
      this.$api
        .secondListAll({ firstPid: item.id, pageNum: 1, pageSize: 15 })
        .then((res) => {
          this.commite.forEach((item1) => {
            if (item1.id == item.id) {
              item1.secondList = res.data.rows;
            }
          });
        });
    },
    delForum(item, type) {
      this.$confirmMy({
        title: "提醒",
        msg: "删除不可恢复，确定要删除动态吗？",
        btn: {
          confirmVal: "确定",
          cancelVal: "再想想",
          iconType: 'del'
        },
      })
        .then((res) => {
          this.delateLun(item, type)
        })
        .catch((err) => {});
    },
    delateLun(item, type) {
      this.$api.forumLimitDelete({ id: item.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getforumQueryById();
          this.isSHowDetail = false;
        }
      });
    },
    // 不看ta
    noSee(item, type) {
      this.$api.forumLimitAdd({ otherUserId: item.userId }).then((res) => {
        if (res.code == 1) {
          this.getforumMyCollectionList();
          this.isSHowDetail = false;
          // this.goPingLun(item);
          // this.$forceUpdate();
        }
      });
    },
    // 帖子取消收藏
    collectForum(item) {
      this.$api.collectForum({ forumId: item.id }).then((res) => {
        // this.dataTopice = res.data.rows;
        if (res.code == 1) {
          this.$message({
            message: "取消收藏成功",
            type: "success",
          });
          this.getforumMyCollectionList();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    goPingLun(item) {
      this.isSHowDetail = !this.isSHowDetail;
      this.goGuangDetail(item);
    },
    handleClose() { },
    handleClick(tab, event) {
      this.indexTab = tab.index;
      if (tab.index == 0) {
        this.getforumQueryById();
      }
      if (tab.index == 1) {
        this.getforumMyCollectionList();
      }
    },
    pingLunSend() {
      console.log("aaabbb");
      let type = this.currentPlInfo.id ? 2 : 1
      this.getForumCommentAdd(type, this.currentPlInfo.id || this.guangDetailData.id, this.inputModel);
    },
    getForumCommentAdd(type, id, content) {
      let that = this;
      this.$api
        .forumCommentAdd({
          content: content,
          sourceId: id,
          type: type,
        })
        .then((res) => {
          this.inputModel = "";
          that.pingData = {};
          that.goGuangDetail(this.guangDetailData);
        });
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        // let playTime = audioElement.duration;
        // this.skillsForm.voiceLen = parseInt(playTime);
      });
    },
    zanBtn(item, type, types) {
      this.$api.forumClickAdd({ sourceId: item.id, type: types || 1 }).then((res) => {
        if (type == 2) {
          this.goGuangDetail(types ? this.guangDetailData : item)
          return
        }
        if (this.indexTab == 0) {
          this.getforumQueryById();
        }
        if (this.indexTab == 1) {
          this.getforumMyCollectionList();
        }
      });
    },
    getInfo() {
      this.$api.queryUser({ otherUserId: this.othUserId }).then((res) => {
        // console.log("总个人信息", res);
        if (res.code == 1) {
          this.userInfo = res.data;
          console.log(this.userInfo, "aaaaaabbbbbaaa");
        }
      });
    },
    getHomepageList() {
      this.$api
        .homepageList({
          othUserId: "1679384132735070209",
          pageNum: 1,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res, "aaaaaa");
        });
    },
    personBtn(item) {
      this.reasonData = item.id;
      // item.isFlag = !item.isFlag;
    },
    getQueryReportReason() {
      this.$api.queryReportReason().then((res) => {
        res.data.forEach((item) => {
          item.isFlag = false;
        });
        this.personData = res.data;
      });
    },
    jubao(item) {
      this.jubaoUserId = item.id;
      this.jubaoShow = true;
    },
    jubaoSubmit() {
      // report/report
      console.log(this.reasonData, "aaaaaa")
      if (!this.reasonData) return this.$message.info('请选择举报原因');
      if (this.flages) {
        this.flages = true;
        let data = {
          noSee: this.jubaoValue ? 1 : 0,
          type: 3,
          reason: this.reasonData,
          sourceId: this.jubaoUserId,
        };
        this.$api.report(data).then((res) => {
          if (res.code == 1) {
            this.jubaoShow = false;
            this.flages = true;
            this.$message({
              message: "举报成功",
              type: "success",
            });
          } else {
            this.flages = true;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
    getforumQueryById() {
      this.$api.forumMyList().then((res) => {
        // if(rew.d)
        if (res.code == 1) {
          let list = res.data.rows || []
          list.forEach((item) => {
            if (item.images) {
              item.newImages = item.images.split(",");
            } else {
              item.newImages = [];
            }
          });
          this.guangchangData = list;
        } else {
          this.guangchangData = [];
        }
      });
    },
    playMusic(i) {
      console.log(this.audio);
      this.videoFile = i.video;
      this.audio.src = i.video;
      this.audio.loop = true;
      this.audio.play(); // 播放
    },
    getforumMyCollectionList() {
      this.$api.forumMyCollectionList().then((res) => {
        // if(rew.d)
        if (res.code == 1) {
          let list = res.data.rows || [];
          list.forEach((item) => {
            if (item.images) {
              item.newImages = item.images.split(",");
            } else {
              item.newImages = [];
            }
          });
          this.guangchangData = list || [];
        } else {
          this.guangchangData = [];
        }
      });
    },
    goGuangDetail(item) {
      this.guangIsSHow = false;
      this.$api.forumQueryById({ id: item.id }).then((res) => {
        if (res.data.images) {
          res.data.newImages = res.data.images.split(",");
        } else {
          res.data.newImages = [];
        }
        this.guangDetailData = res.data;
      });
      this.getForumCommentList(item.id);
    },
    getForumCommentList(id) {
      this.$api
        .forumCommentList({
          forumId: id,
          // pageNum: this.pageNum,
          // pageSize: 15,
        })
        .then((res) => {
          if (res.code == 1) {
            this.commite = res.data.rows || []
          } else {
            this.commite = []
          }
        });
    },
  },
};
</script>
<style scoped>
.dynamicBox {
  height: 600px;
  overflow-y: auto;

  .dynamicItem {
    margin-bottom: 18px;
  }
}

.sendPingBtn {
  right: 70px;
  bottom: 6px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
}

.dynamicImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.medalBtn {
  position: absolute;
  left: 50%;
  bottom: 25%;
}

.titleContent {
  width: calc(100% - 36px);
  height: 46px;
  color: #000;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}

.ContentDetail {
  display: flex;
  width: calc(100% - 32px);
  margin: 40px auto 0;
}

.contentAvato {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.contentRight {
  width: 800px;
  margin-left: 10px;
}

.contentBottom {
  display: flex;
  align-items: center;
  margin: 0 16px;
  position: absolute;
  bottom: 52px;
}

.BottomInp {
  width: 772px;
  height: 48px;
  border: none;
  background-color: #f5f5f5;
  padding: 16px;
  box-sizing: border-box;
}

.contentRightTitle {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.contentRightTime {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 6px;
}

.contentRightContent {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
}

.prompt_top {
  width: 1210px;
  height: 25px;
  position: fixed;
  z-index: 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: linear-gradient(129deg, #8b39fb 0%, #ec51a3 100%);
}

.noGuangchangTitle {
  width: 466px;
  height: 44px;
  line-height: 44px;

  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 4px 4px;
  margin: 40px auto 20px;
  box-sizing: border-box;
  padding: 12px 10px;
}

.corfinBtn {
  width: 176px;
  height: 40px;
  background: #ffdd00;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #212121;
  cursor: pointer;
  margin: 0 auto;
}

/deep/ .el-dialog__header {
  display: none;
}
 /deep/ .el-tabs__item:hover{
	 color: rgba(255, 167, 0, 1);
 }
/deep/ .el-tabs__item.is-active, /deep/ .el-tabs__item.is-foucs {
 color: rgba(255, 167, 0, 1);
}
/deep/ .el-tabs__active-bar {
  background-color: rgba(255, 167, 0, 1);
}

.showMore {
  .btns {
    display: none;
  }

  &:hover {
    .btns {
      display: block;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}

.contentDetailFlex {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  margin-top: 16px;
  margin-left: 20px;
}
.commentOvlea {
  width: 100%;
  height: 260px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 24px;
  padding-bottom: 140px;
  box-sizing: border-box;
}
.commentFlex {
  display: flex;
  align-content: center;
}
.commentName {
  padding: 10px;
}
.commentConent {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 64px;
}
.secondFlex {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.commentTime {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}
.sendPingBtns {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  margin-right: 16px;
}

.openMore {
  color: rgba(51, 112, 251, 1);
  font-size: 14px;
  margin: 12px 0;
}

.share_popup {
  display: none;
  width: 435px;
  height: 64px;
  color: #212121;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  top: 16px;
  /* left: 0px; */
  background: #ffffff;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
  align-items: center;
  .copyBtn {
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    background-color: rgba(255, 221, 0, 1);
  }
  .ipAddress {
    margin-top: 0px;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    background: #ececec;
    border-radius: 4px;
    align-items: center;
  }
}

.share-btn {
  position: relative;
  &:hover {
    .share_popup {
      display: block;
    }
  }
}
</style>