<template>
  <!-- 钱包充值 -->
  <div class="walletPages">
    <div class="wallet_box">
      <div class="wallet_top flex-dir" @click="firstShow=true">
      </div>
      <img
        src="../../static/169.png"
        alt=""
        class="wallet_quit"
        @click="walletQuit()"
      />
      <div class="font-14">元宝余额</div>
      <div class="wallet_num">{{ diamondCost }}</div>
      <div class="hengxian"></div>
      <div class="wallet_title">充值面额</div>
      <div class="flex_wrap">
        <div
          class="denomination_item flex-dir"
          v-for="(item, key0) in topUpMenu"
          :key="key0"
          :class="amount == key0 ? 'pitchOn2' : ''"
          @click="amountPitch(item, key0)" style="cursor: pointer;"
        >
          <div class="wallet_title" style="margin-bottom: 4px">
            {{ item.diamondNum }}
            <img src="../../static/195.png" alt="" class="item_img" />
          </div>
          <div class="font-12">{{ item.price }}元</div>
        </div>
        <div
          class="denomination_item flex-dir"
          :class="amount == 6 ? 'pitchOn2' : ''"
          @click="amountPitch(customValue, 6)" style="cursor: pointer;"
        >
        <div>其他金额</div>
        <div class="flex">
          ￥<input type="text" placeholder="" autocomplete="off" maxlength="5" class="customInput" v-model="customValue" @input="moneyInput()">
        </div>
          <!-- <div class="wallet_title" style="margin-bottom: 4px">
            600 <img src="../../static/61.png" alt="" class="item_img" />
          </div>
          <div class="font-12">6元</div> -->
        </div>
      </div>
      <div class="hengxian"></div>
      <div class="wallet_title">支付方式</div>
      <div class="flex">
        <div
          class="denomination_item flex-dir"
          :class="modeType == 1 ? 'pitchOn' : ''"
          @click="modeType = 1" style="cursor: pointer;"
        >
          <div class="flex-content">
            <img
              src="../../static/170.png"
              alt=""
              style="margin-right: 10px"
            />支付宝
          </div>
        </div>
        <div
          class="denomination_item flex-dir"
          :class="modeType == 2 ? 'pitchOn' : ''"
          @click="modeType = 2" style="cursor: pointer;"
        >
          <div class="flex-content">
            <img src="../../static/171.png" alt="" style="margin-right: 10px" />
            <span style="color: #09bb07">微信支付</span>
          </div>
        </div>
      </div>
      <div class="font-12 flex-content" style="margin-top:20px">
        <img
          src="../../static/107.png"
          alt=""
          class="item_img"
          style="cursor: pointer;"
          v-if="readType"
          @click="readType = false"
        />
        <img
          src="../../static/106.png"
          alt=""
          class="item_img"
          style="cursor: pointer;"
          v-else
          @click="readType = true"
        />
        <span style="margin-left: 4px">勾选表示同意<span class="cursor" style="color: blue;" @click.stop="getRechargeServiceShow()">《充值服务协议》</span></span>
      </div>
      <div class="flex-content">
        <div class="topup_btn flex-content" style="margin-top:16px;cursor: pointer;" @click.stop="rechargeChange()">立即充值</div>
      </div>
    </div>
    <!-- 支付宝充值弹窗 -->
    <div class="topUPpopup_box" v-if="alipayPopupShow" :style="{top: entrance == 1?'0':'5%'}">
      <div class="pop_up" style="height: 500px; width: 570px">
        <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">扫码支付</div>
        <img src="../../static/quit.png" alt="" @click="closeWxPopUp()" />
      </div>
      <!-- 头部end -->
        <div class="flex-dir">
            <!-- <img :src="wxImg" alt="" style="margin-top: 48px;" /> -->
            <iframe  name="iframeMap" id="iframeMapViewComponent"  v-bind:src="AlipayValue"
            width="350px" height="350px"
            frameborder="0" scrolling="no" ref="iframeDom"
            ></iframe>
            <p style="margin-top: 28px;">请使用支付宝扫一扫</p>
          </div>
      </div>
  </div>
    <!-- 微信扫码充值弹窗 -->
    <div class="topUPpopup_box" v-if="payPopupShow" :style="{top: entrance == 1?'0':'5%'}">
        <div class="pop_up" style="height: 500px; width: 570px">
          <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">扫码支付</div>
          <img src="../../static/quit.png" alt="" @click="closeWxPopUp()" />
        </div>
        <!-- 头部end -->
          <div class="flex-dir">
              <img :src="wxImg" alt="" style="margin-top: 48px;" />
              <p style="margin-top: 28px;">请使用微信扫一扫</p>
          </div>
        </div>
    </div>
    <!-- 充值服务协议弹窗 -->
    <div class="topUPpopup_box" v-if="rechargeServiceShow" :style="{top: entrance == 1?'0':'5%'}">
      <div class="pop_up" style="height: 500px; width: 640px">
         <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">充值服务协议</div>
        <img src="../../static/quit.png" style="cursor: pointer;" alt="" @click="rechargeServiceShow = false" />
      </div>
      <!-- 头部end -->
       <div v-html="serveText" style="height: 415px;padding:10px;overflow-y: scroll;"></div>
      </div>
  </div>
    <!-- 首充弹窗 -->
    <firstCharge v-if="firstShow"  @firstQuit="firstShow = false"></firstCharge>
  </div>
</template>

<script>
import firstCharge from "../homePage/firstCharge.vue";

export default {
  name: "",
  props: {
    entrance:{
      default:0
    }
  },
  components: {
    firstCharge,
  },
  data() {
    return {
      rechargeServiceShow:false,//充值服务协议弹窗
      serveText:'',//服务协议内容
      firstShow: false, //首充弹窗
      amount: 0, //充值金额菜单顺序
      priceData: {}, //充值金额
      modeType: 1, //支付方式 1支付宝 2微信
      diamondCost: 0, //可用元宝
      topUpMenu: [], //充值菜单
      readType: true, //是否阅读协议
      payPopupShow:false,//支付弹窗
      wxImg:'',//微信img
      orderNo:'',//订单号
      stopTime:null,//定时器
      customValue:'',//自定义充值数值
      alipayPopupShow:false,
      AlipayValue:'',//支付宝二维码
      alipayOrder:'',//支付宝订单号
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.queryByMyDiamond();
    this.getQueryRechargeMenu();
  },
  methods: {
    // 输入自定义金额
    moneyInput(){
      // setTimeout(()=>{
      //   if(this.customValue < 1){
      //     this.$message.error('最低充值1元')
      //     return this.customValue = 1
      //   }else if(this.customValue > 10000){
      //     this.$message.error('最高充值10000元')
      //     return this.customValue = 10000
      //   }
      // },800)
      
    },
    // 获取充值协议
    getRechargeServiceShow(){
      this.$api.getAgreement({configName:'rechargeMessage'}).then(res=>{
        if(res.code == 1){
          this.serveText = res.data
          this.rechargeServiceShow = true
        }
      })
    },
    // 关闭
    walletQuit() {
      this.$emit("walletQuitChange");
      // this.$router.go(-1)
    },
    // 立即充值
    rechargeChange() {
      //  1支付宝 2微信
      if (!this.readType) return this.$message.info("请阅读并勾选协议");
      if (this.modeType == 1) {
        let moneyValue = ''
        if(this.amount == 6){
          moneyValue = this.customValue
		  if(!moneyValue) return this.$message.error('请输入充值金额')
        }else{
          moneyValue = this.priceData.price
        }
        // return this.$message.info('暂未开通，请使用微信支付')
        this.$api.aliPayPc({money:moneyValue}).then(res=>{
          // console.log('支付宝',res);
          if(res.code == 1){
            this.AlipayValue = res.data.resultUrl
            this.alipayOrder = res.data.outTradeNo
            this.alipayPopupShow = true
            this.getAliPayPayment()
            // const div = document.createElement('div')
						// div.id = 'alipay'
						// div.innerHTML = res.msg
						// document.body.appendChild(div)
						// document.querySelector('#alipay').children[0].submit()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        let moneyValue = ''
        if(this.amount == 6){
          moneyValue = this.customValue
          if (!moneyValue) return this.$message.error('请输入充值金额')
          if (!Number(moneyValue || 0)) return this.$message.error('请输入正确的充值金额')
          if (Number(moneyValue) < 1) return this.$message.error('充值金额必须大于1')
        }else{
          moneyValue = this.priceData.price
        }
        this.$api.weChatPaymentPc({money:moneyValue}).then(res=>{
          // console.log('微信充值',res);
          if(res.code == 1){
            this.wxImg = "data:image/png;base64," + res.data.base64Image
            this.orderNo = res.data.orderNo
            this.payPopupShow = true
            this.getWeChatPayResult()
          }else{
            this.$message.info(res.msg)
          }
        })
      }
    },
    // 获取微信支付结果
    getWeChatPayResult(){
      this.$api.weChatPayResult({orderNo:this.orderNo}).then(res=>{
        if(res.code == 1){
          if(res.data == 2){
          this.queryByMyDiamond()
          this.payPopupShow = false
          this.$message.success('充值成功')
          }else{
          this.stopTime = setTimeout(()=>{
            this.getWeChatPayResult()
          },3000)
          }
        }
      })
    },
    // 获取支付宝支付结果
    getAliPayPayment(){
      this.$api.aliResultRech({outTradeNo:this.alipayOrder}).then(res=>{
        if(res.code == 1){
          if(res.data == 2){
          this.queryByMyDiamond()
          this.alipayPopupShow = false
          this.$message.success('充值成功')
          }else{
          this.stopTime = setTimeout(()=>{
            this.getAliPayPayment()
          },3000)
          }
        }
      })
    },
    // 选择充值金额
    amountPitch(item, index) {
      this.amount = index;
      if(index != 6){
        this.priceData = item;
      }
      
    },
    // 获取充值菜单
    getQueryRechargeMenu() {
      this.$api.queryRechargeMenu().then((res) => {
        if (res.code == 1) {
          this.topUpMenu = res.data;
          this.priceData = res.data[0];
        }
      });
    },
    // 关闭弹窗
    closeWxPopUp(){
      this.alipayPopupShow = false
      this.payPopupShow = false;
      window.clearTimeout(this.stopTime)
    },
    // 我的钱包-元宝
    queryByMyDiamond() {
      this.$api.queryByMyDiamond().then((res) => {
        // console.log("我的钱包-元宝", res);
        if (res.code == 1) {
          this.diamondCost = res.data.diamondCost;
        }
      });
    },
  },
  destroyed(){
    window.clearTimeout(this.stopTime)
  }
};
</script>

<style scoped lang="scss">

.topUPpopup_box{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #161722, $alpha: 0.7);
  z-index: 99;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
}
.walletPages {
  width: 1920px;
  height: 1080px;
  color: #212121;
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  font-size: 14px;
}
.wallet_box {
  width: 992px;
  height: 538px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  .wallet_quit {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .hengxian {
    width: 100%;
    margin: 20px 0;
    border: 1px dashed #707070;
  }
  .wallet_num {
    font-size: 28px;
  }
  .wallet_title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .denomination_item {
    width: 200px;
    height: 56px;
    margin: 0 10px 10px 0;
    border-radius: 4px;
    background: #F4F4F4;
    border: 1px solid #F4F4F4;
  }
  .customInput{
    width: 100px;
    color: #333;
    border: 0;
    text-align: center;
    background: transparent;
    border-bottom: 1px solid #333;
  }
  .pitchOn {
    color: #fff;
    background: #6777ef;
  }
  .pitchOn2{
    border: 1px solid #ffbb3a;
    background: #ffedcc;
  }
  .item_img {
    width: 14px;
    height: 14px;
  }
  .topup_btn {
    width: 160px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.wallet_top {
  width: 992px;
  height: 80px;
  color: #fff;
  background-image:url('../../static/225.png');
  background-size: 100% 100%;
  border-radius: 8px;
  position: absolute;
  top: -90px;
  left: 0px;

  .details_btn {
    width: 65px;
    height: 21px;
    font-size: 12px;
    background: linear-gradient(180deg, #7c4ff0 0%, #976eff 100%);
    border-radius: 4px;
    position: absolute;
    right: 25px;
  }
}
</style>
