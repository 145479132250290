<template>
  <div class="page">
    <!-- 登录页面 -->
    <!-- <img src="../../static/96.png" alt="" class="left_img" /> -->
    <div class="left_box">
      <div style="font-size: 32px; margin-top: 74px" class="font_bold">
        狗书语音
      </div>
      <div style="font-size: 18px; margin-top: 21px">
        甜蜜 久伴 你的心灵港湾
      </div>
      <img
        src="@/static/194.png"
        alt=""
        style="width: 206.33px; height: 210px; margin-top: 74px"
      />
    </div>
    <div class="login_box">
      <img
        src="../../static/59.png"
        alt=""
        class="quit_img"
        @click="skipHome()"
      />
      <!-- <div class="font-20 font_bold" style="color: #1d2030">登录</div>
      <img src="../../static/102.png" alt="" /> -->
      <div class="flex titleBox">
        <div class="cursor" :class="loginType == 1 ? 'titlePitch' : ''" @click="loginType = 1">
          密码登录
        </div>
        <div class="cursor" :class="loginType == 2 ? 'titlePitch' : ''" @click="loginType = 2">
          验证码登录
        </div>
      </div>
      <!-- <el-input v-model="phone" placeholder="请输入手机号" class="login_input">
      <img slot="prefix" class="login_icon" src="../../static/97.png" alt="">
    </el-input>
    <el-input v-model="password" placeholder="请输入密码"></el-input> -->
      <div class="login_form">
        <div class="position_r">
          <div class="login_icon"><img src="../../static/97.png" alt="" /></div>
          <input
            type="text"
            v-model="phone"
            placeholder="请输入手机号"
            class="login_input"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
            maxlength="11"
          />
        </div>
        <!-- 账号密码登录 -->
        <div class="position_r" v-if="loginType == 1">
          <div class="login_icon"><img src="../../static/98.png" alt="" /></div>
          <input
            :type="passwordType"
            v-model="password"
            placeholder="请输入密码"
            class="login_input"
            maxlength="15"
          />
          <div class="password_show cur_head" @click="passwordChange()">
            <img
              src="../../static/99.png"
              alt=""
              v-if="passwordType == 'password'"
            />
            <img src="../../image/my/20.png" alt="" v-else />
          </div>
        </div>
        <!-- 手机号登录 -->
        <div class="position_r flex-aling" v-if="loginType == 2">
          <input
            type="text"
            v-model="code"
            placeholder="验证码"
            class="login_code"
            maxlength="6"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
          />
          <verificationCode :event="'login'" :mobile="phone"></verificationCode>
        </div>
      </div>
      <div class="flex" style="justify-content: space-between">
        <div class="flex" style="color: #86909b">
          还没有账户？
          <p style="color: #3370fb" class="cur_head" @click="skipRegister()">
            注册账户
          </p>
        </div>
        <div style="color: #14264f" class="cur_head" @click="reset()">
          忘记密码？
        </div>
      </div>
	  <!-- 记住密码 -->
	  <div v-if="loginType == 1" class="flex cursor" style="margin-top:16px;" @click="ispassword = !ispassword">
		  <img
		    src="../../image/my/21.png"
		    alt=""
		    style="margin-right: 8px"
		    class="cur_head"
		    v-if="ispassword"
		  />
		  <img
		    src="../../static/103.png"
		    alt=""
		    style="margin-right: 8px"
		    class="cur_head"
		    v-else
		  />
		  <div style="color:rgb(134, 144, 155);font-size:15px;margin-left:2px;">记住账号和密码</div>
	  </div>
	  
	  <div v-else class="flex cursor" style="margin-top:16px;" @click="isphone = !isphone">
		  <img
			src="../../image/my/21.png"
			alt=""
			style="margin-right: 8px"
			class="cur_head"
			v-if="isphone"
		  />
		  <img
			src="../../static/103.png"
			alt=""
			style="margin-right: 8px"
			class="cur_head"
			v-else
		  />
		  <div style="color:rgb(134, 144, 155);font-size:15px;margin-left:2px;">记住手机号</div>
	  </div>
      <div
        class="enter flex-content cur_head"
        @click="logonChang()"
        v-if="loginType == 1"
      >
        登录
      </div>
      <div class="enter flex-content cur_head" @click="codeLogonChang()" v-else>
        登录
      </div>
      <div class="quickLogin">快捷登录</div>
      <!-- 其他登录方式 -->
      <div class="loginType flex">
        <div class="else_login flex-content cur_head" @click="wxLoginHandle()">
          <img src="../../static/100.png" alt="" style="margin-right: 8px" />
          微信登录
        </div>
        <div class="else_login flex-content cur_head" @click="QQLoginHandle()">
          <img src="../../static/101.png" alt="" style="margin-right: 8px" />
          QQ登录
        </div>
      </div>
      <!-- 其他登录方式 -->
      <div class="flex-content" style="margin-top: 28px">
        <img
          src="../../image/my/21.png"
          alt=""
          style="margin-right: 8px"
          class="cur_head"
          v-if="readType"
          @click="readType = false"
        />
        <img
          src="../../static/103.png"
          alt=""
          style="margin-right: 8px"
          class="cur_head"
          v-else
          @click="readType = true"
        />
        已阅读并同意
        <p
          style="color: #3370fb"
          class="cur_head"
          @click="getAgreement('registrationAgreement')"
        >
          《用户服务协议》
        </p>
        和
        <p
          style="color: #3370fb"
          class="cur_head"
          @click="getAgreement('privacyAgreement')"
        >
          《隐私政策》
        </p>
      </div>
    </div>

    <!-- 微信登录 -->
    <div class="popUp_box" v-if="wxLoginShow">
      <div class="wxLogin_box" style="width: 400px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">微信扫码登录</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="wxLoginShow = false"
          />
        </div>
        <!-- 头部end -->
        <iframe
          :src="urltest"
          frameborder="0"
          width="100%"
          height="400px"
          scrolling="auto"
        ></iframe>
        <!-- <div class="wxLogin_bg" @click="binding()"></div> -->
      </div>
    </div>
    <!-- 用户协议 -->
    <div class="popUp_boxs" v-if="userAgreementShow">
      <div class="wxLogin_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">用户注册协议</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="userAgreementShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="agreementShow">
          <div class="font-14" v-html="agreementText"></div>
        </div>
      </div>
    </div>
    <!-- 隐私协议 -->
    <div class="popUp_boxs" v-if="privacyShow">
      <div class="wxLogin_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">隐私政策</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="privacyShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="agreementShow">
          <div style="width: 98%" class="font-14" v-html="agreementText"></div>
        </div>
      </div>
    </div>
    <!-- 隐私政策end -->
    <!-- wx绑定 -->
    <div class="popUp_boxs" v-if="accountShow">
      <div class="wxLogin_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">手机绑定</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="accountShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="form_box">
          <el-form ref="form" label-width="120px">
            <el-form-item label="手机号：" class="color_fff">
              <el-input
                v-model="bindingPhone"
                style="width: 70%"
                placeholder="请输入手机号"
                :maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="短信验证码：" class="color_fff">
              <div class="flex">
                <el-input
                  v-model="bindingCode"
                  style="width: 40%"
                  placeholder="请输入短信验证码"
                ></el-input>
                <verificationCode
                  :event="'bindThird'"
                  :mobile="bindingPhone"
                ></verificationCode>
              </div>
            </el-form-item>
            <el-form-item label="密码：" class="color_fff">
              <div class="flex">
                <el-input
                  v-model="bindingPassword"
                  style="width: 70%"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="form_btn flex-content" @click="tripartiteBinding()">
                立即绑定
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 绑定弹窗 end -->
    <!-- QQ绑定账号 -->
    <div class="popUp_boxs" v-if="qqAccountShow">
      <div class="wxLogin_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">手机绑定</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="qqAccountShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="form_box">
          <el-form ref="form" label-width="120px">
            <el-form-item label="手机号：" class="color_fff">
              <el-input
                v-model="qqMobile"
                style="width: 70%"
                placeholder="请输入手机号"
                :maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="短信验证码：" class="color_fff">
              <div class="flex">
                <el-input
                  v-model="qqCode"
                  style="width: 40%"
                  placeholder="请输入短信验证码"
                ></el-input>
                <verificationCode
                  :event="'login'"
                  :mobile="qqMobile"
                ></verificationCode>
              </div>
            </el-form-item>
            <!-- <el-form-item label="密码：" class="color_fff">
              <div class="flex">
                <el-input
                  v-model="bindingPassword"
                  style="width: 70%"
                  placeholder="请输入密码"
                ></el-input>
              </div>
            </el-form-item> -->
            <el-form-item>
              <div class="form_btn flex-content" @click="qqBoundAccount()">
                立即绑定
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- QQ绑定 -->
  </div>
</template>
<script>
import API from "../../api";
import verificationCode from "../../components/verificationCode/index.vue";
import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";
export default {
  name: "",
  components: { verificationCode },
  data() {
    return {
      phone:localStorage.getItem('phone') || '',
      password:localStorage.getItem('password') || '',
      wxLoginShow: false,
      userAgreementShow: false,
      privacyShow: false,
      passwordType: "password",
      readType: false, //协议
      urltest: "",
      loginTime: null,
      QQloginTime: null,
      accountShow: false, //绑定账号
      qqAccountShow: false, //qq绑定账号
      bindingPhone: "", //绑定手机号
      bindingCode: "", //绑定验证码
      bindingPassword: "", //绑定密码
      openId: "",
      wxState: "",
      code: "", //验证码
      loginType: 1, //1账号密码登录 2验证码登录
      qqMobile: "",
      qqCode: "",
      qqOpenid: "",
      qqAccessToken: "",
      agreementText: "",
	  ispassword:true,
	  isphone:true
    };
  },
  created() {
    let options = {
      SDKAppID: 1600008716,
    };
  },
  mounted() {
    // QC.Login({
    //    btnId:"qqLoginBtn"	//插入按钮的节点id
    //   });
    // let APPID = "wx518f64b259c1a61a";
    // let REDIRECT_URI = encodeURIComponent("https://pc.yinsuwangluokj.com/rcrtc/#/login");
    // console.log(`https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_login&state=e5cd773bee67c5b2c6cc3e6c3427a21c#wechat_redirect`,'sssssssssssssbbbbbbbbbbbbb')
    // this.urltest = `https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_login&state=e5cd773bee67c5b2c6cc3e6c3427a21c#wechat_redirect`;
  },
  destroyed() {
    clearInterval(this.loginTime);
  },
  methods: {
    // 获取协议数据
    getAgreement(configName) {
      this.$api.getAgreement({ configName }).then((res) => {
        // console.log('协议',res);
        if (res.code == 1) {
          this.agreementText = res.data;
          if (configName == "privacyAgreement") {
            this.privacyShow = true;
          } else if (configName == "registrationAgreement") {
            this.userAgreementShow = true;
          }
        }
      });
    },
    // 切换登录方式
    switchLogin() {
      if (this.loginType == 1) {
        this.loginType = 2;
      } else {
        this.loginType = 1;
      }
    },
    // qq 绑定账号
    qqBoundAccount() {
      let data = {
        accessToken: this.qqAccessToken,
        openid: this.qqOpenid,
        mobile: this.qqMobile,
      };
      this.$api
        .checkSend({
          captcha: this.qqCode,
          event: "login",
          mobile: this.qqMobile,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$api.setCreateUser(data).then((res) => {
              if (res.code == 1) {
                this.qqAccessToken = "";
                this.qqOpenid = "";
                this.qqMobile = "";
                this.qqCode = "";
                localStorage.setItem("user-token", res.data.token);
                this.qqAccountShow = false;
				setTimeout(() =>{
					window.location.reload();
				},50)
                this.$router.push("/home");
              } else {
                this.$message.info(res.msg);
              }
            });
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // wx三方绑定
    tripartiteBinding() {
      let data = {
        captcha: this.bindingCode, //	验证码
        event: "bindThird", //事件,示例值(bindThird)
        mobile: this.bindingPhone, //	手机号
        unionID: this.openId, //三方唯一标识ID
        loginFrom: 1, //	登录来源（0-app 1-pc）
        password: this.bindingPassword,
        platform: 1, //平台类型(0-QQ 1-微信 2-微博 3-ios)
        state: this.wxState,
      };
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.bindingPhone) return this.$message.info("请输入手机号");
      if (!reg.test(this.bindingPhone))
        return this.$message.info("手机号码格式错误");
      if (!this.bindingCode) return this.$message.info("请输入验证码");
      if (!this.bindingPassword) return this.$message.info("请输入密码");
      this.$api.bindOpenId(data).then((res) => {
        if (res.code == 1) {
          this.bindingCode = "";
          this.bindingPhone = "";
          this.bindingPassword = "";
          localStorage.setItem("user-token", res.data.token);
          this.accountShow = false; 
          this.$router.push("/home");
		  setTimeout(() =>{
			  window.location.reload();
		  },50)
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    //wx 登录正在开发中
    wxLoginHandle() {
      // wx 登录正在开发中
      // this.$message.info('wx 登录正在开发中');
      // return
      if (!this.readType) return this.$message.info("请阅读用户协议");
      clearInterval(this.loginTime);
      this.$api.getConAddressSer().then((res) => {
        console.log('获取微信登录',res.data.url,'res.data.url');
        this.wxState = res.data.state;
        var newWindow = window.open(
          res.data.url,
          "oauth2Login_10640",
          "height=600,width=800, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes,left=550px,top=260px"
        );
        this.loginTime = setInterval(() => {
          this.$api.getWxState({ state: res.data.state }).then((res) => {
            console.log(res.data,'is_loginis_loginis_login')
            if (res.code == 1) {
              //is_login 0未绑定 1已绑定 2未扫码
              switch (res.data.is_login) {
                case 0:
                  // console
                  newWindow.close()
                  this.openId = res.data.openid;
                  this.accountShow = true;
                  clearInterval(this.loginTime);
                  break;
                case 1:
                  clearInterval(this.loginTime);
                  this.$api
                    .mobileThirdLogin({
                      unionID: res.data.openid,
                      loginFrom: 1,
                    })
                    .then((opn) => {
                      console.log('三方登录',opn);
                      if (opn.code == 1) {
                        newWindow.close()
                        localStorage.setItem("user-token", opn.data.token);
						setTimeout(() =>{
							window.location.reload();
						},50)
                        this.$router.push("/home");
                      }
                    });
                  break;
                default:
                  break;
              }
            }
          });
        }, 2000);
      });
      // this.$message.info("当前功能正在开发中");
      // this.wxLoginShow = true
    },
    //QQ登录
    QQLoginHandle() {
       //this.$message.info('QQ登录正在开发中');
       //return
       if (!this.readType) return this.$message.info("请阅读用户协议");
      this.$api.getAuthPageUrl().then((res) => {
      var newWindow =  window.open(
          res.url,
          "oauth2Login_10640",
          "height=600,width=800, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes,left=550px,top=260px"
        );
        this.QQloginTime = setInterval(() => {
          this.$api.getQQUserId({ state: res.state }).then((opn) => {
            switch (opn.status) {
              case 0:
              newWindow.close()
                this.qqAccessToken = opn.access_token;
                this.qqOpenid = opn.openid;
                this.qqAccountShow = true;
                clearInterval(this.QQloginTime);
                break;
              case 1:
              window.close()
                clearInterval(this.QQloginTime);
                localStorage.setItem("user-token", opn.token);
				setTimeout(() =>{
					window.location.reload();
				},50)
                this.$router.push("/home");
                newWindow.close()
                break;
              default:
                break;
            }
          });
        }, 2000);
      });

      // this.$message.info("当前功能正在开发中");
      // window.open('https://graph.qq.com/oauth2.0/authorize?client_id=102039215&response_type=code&scope=all&redirect_uri=https://pc.yinsuwangluokj.com/connect/qqcallback.html',
      // 'oauth2Login_10640' ,'height=600,width=800, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes,left=550px,top=260px')
      // let oOpts = {
      // appId:"222222",
      // redirectURI:"http://yousite.com/qc_back.html"
      // }
      // QC.Login.showPopup(oOpts)
    },
    // 登录
    logonChang() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let passwordText = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,12}$/;
      if (!this.phone) return this.$message.info("请输入手机号");
      if (!reg.test(this.phone)) return this.$message.info("手机号码格式错误");
      if (!passwordText.test(this.password))
        return this.$message.info("请输入密码(6~12位字母+数字)");
      // if (!this.password) return this.$message.info("请输入数字和字母6~12位密码");
      if (!this.readType) return this.$message.info("请阅读用户协议");
      let data = {
        mobile: this.phone,
        password: this.password,
        loginFrom: 1,
      };
      this.$api.login(data).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("userTIMInfo", JSON.stringify(res.data.userVo));
		  this.chat.logout();
          let promise = this.chat.login({
            userID: res.data.userVo.id,
            userSig: res.data.userVo.userSig,
          });
          promise
            .then((res) => {
             console.warn('login success:', res)
            })
            .catch((err) => {
              console.warn('login error:', error)
            });
			if(this.ispassword){
				localStorage.setItem('password',this.password)
				localStorage.setItem('phone',this.phone)
			}else{
				localStorage.removeItem('password',this.password)
				localStorage.removeItem('phone',this.phone)
			}
			setTimeout(() =>{
				window.location.reload();
			},50)
          this.$router.push("/home");
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    // 手机号验证码登录
    codeLogonChang() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.phone) return this.$message.info("请输入手机号");
      if (!reg.test(this.phone)) return this.$message.info("手机号码格式错误");
      if (!this.code) return this.$message.info("验证码不能为空");
      if (!this.readType) return this.$message.info("请阅读用户协议");
      let data = {
        mobile: this.phone,
        captcha: this.code,
        event: "login",
      };
      this.$api.loginForCaptcha(data).then((res) => {
        if (res.code == 1) {
          
          localStorage.setItem("userTIMInfo", JSON.stringify(res.data.userVo));
		  this.chat.logout();
          let promise = this.chat.login({
            userID: res.data.userVo.id,
            userSig: res.data.userVo.userSig,
          });
          promise
            .then((res) => {
             console.warn('login success:', res)
            })
            .catch((err) => {
              console.warn('login error:', error)
            });
			if(this.ispassword){
				localStorage.setItem('phone',this.phone)
			}else{
				localStorage.removeItem('phone',this.phone)
			}
			this.$message.success(res.msg);
			setTimeout(() =>{
				window.location.reload();
			},50)
            // this.$store.commit("setUserInfo", this.userData);
            this.$router.push("/home");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 显示隐藏密码
    passwordChange() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    // 返回首页
    skipHome() {
      this.$router.push("/home");
    },
    // 注册页
    skipRegister() {
      this.$router.push("/register");
    },
    //绑定手机号
    binding() {
      this.$router.push("bindingPhone");
    },
    //重置密码
    reset() {
      this.$router.push("/resetPasswords");
    },
  },
};
</script>
<style lang="scss">
.titleBox {
  color: #999999;
  font-size: 20px;
  padding-bottom: 48px;
  justify-content: space-around;
}
.titlePitch {
  color: #333333;
  font-weight: 600;
  position: relative;
}
.titlePitch:after {
  content: "";
  width: 64px;
  height: 2px;
  display: inline-block;
  background: #ffdd00;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50%;
}
.login_code {
  width: 267px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 20px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.form_box {
  width: 500px;
  margin-top: 20px;
}
.form_btn {
  width: 320px;
  height: 40px;
  color: #333;
  font-size: 14px;
  background:#FFDD00;
  border-radius: 4px;
}
.page {
  width: 827px;
  height: 596px;
  display: flex;
  font-size: 16px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.left_img {
  width: 360px;
  height: 596px;
}
.left_box {
  width: 360px;
  height: 596px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffdd00;
}
.login_box {
  padding: 40px 40px 0 40px;
  position: relative;
}
.quit_img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login_input {
  width: 387px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 80px;
  background: #f5f6f7;
  box-sizing: border-box;
}
//.login_input:focus {
//border: 1px solid #1a39c2;
//}
.login_icon {
  height: 20px;
  position: absolute;
  top: 25px;
  left: 25px;
  padding-right: 20px;
  border-right: 1px solid #ccc;
  > img {
    width: 20px;
    height: 20px;
  }
}
.password_show {
  position: absolute;
  top: 30px;
  right: 25px;
}
.enter {
  width: 387px;
  height: 56px;
  color: #333333;
  margin-top: 20px;
  font-size: 20px;
  background: #ffdd00;
  border-radius: 4px;
}
.quickLogin {
  width: 52px;
  margin: auto;
  color: #646c78;
  font-size: 13px;
  margin-top: 20px;
  text-align: center;
}
.loginType {
  margin-top: 20px;
  justify-content: space-between;
}
.else_login {
  width: 180px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6eaf1;
}
//弹窗
.popUp_boxs {
  // width: 1920px;
  // height: 1080px;
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.8);

  position: fixed;
  top: 0;
  left: 0;
}
.wxLogin_box {
  color: #333333;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .wxLogin_bg {
    width: 240px;
    height: 292px;
    background: #222846;
    border-radius: 8px 8px 8px 8px;
    margin: 60px 80px;
  }
}
.agreementShow {
  width: 800px;
  max-height: 500px;
  overflow-y: scroll;
  margin: 20px;
  font-size: 12px;
}
input:focus {
  outline: none !important;
}
.cur_head{
  cursor: pointer;
}
</style>
