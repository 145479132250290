<template>
  <!-- 开黑房管理员列表 -->
  <div class="popUp_box">
    <div class="pop_up" >
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">房间管理员</div>
        <img src="../../../static/quit.png" alt="" @click="darkChange()" />
      </div>
      <!-- 头部end -->
      <div class="solutionRemind_box flex-dir">
        <div class="font-12" style="color:#e37f2d;margin:20px 0">
          点击房内人员名片设置管理员
        </div>
        <div class="llacingCont_table">
          <!-- 头部表头 -->
          <div class="flex-aling llacingCont_tr">
            <div class="llacingCont_heder flex-content" style="width:80px">序号</div>
            <div class="llacingCont_heder flex-content" style="width:250px">用户信息</div>
            <div class="llacingCont_heder flex-content" >用户ID</div>
            <div class="llacingCont_heder flex-content">时间</div>
            <div class="llacingCont_heder flex-content" style="width:150px">操作</div>
          </div>
          <!-- 下单内容 -->
          <div class="flex-aling llacingCont_tr" v-if="(adminList.length == 0)" style="height:100px;height: 200px;justify-content: center;">
            暂无数据
          </div>
          <div class="flex-aling llacingCont_tr" v-for="(item,index) in adminList" :key="index" style="color:#333">
            <div class="llacingCont_td flex-content" style="width:80px">{{(index + 1)}}</div>
            <div class="llacingCont_td flex-content"  style="width:250px">
              <img :src="item.avatar" alt="" class="acatarImg"> <span>{{item.nickname}}</span>
              <div v-if="item.sex==0" style="margin-left:14px">
                <img  src="../../../image/message/32.png" alt="" class="ageImg" />{{item.age}}
              </div>
              <div v-if="item.sex==1" style="margin-left:14px">
                <img  src="../../../image/message/24.png" alt="" class="ageImg" />{{item.age}}
              </div>
            </div>
            <div class="llacingCont_td flex-content">{{item.userNo}}</div>
            <div class="llacingCont_td flex-content">{{item.inTime}}</div>
            <div class="llacingCont_td flex-content" style="width:150px;border-right:none" >
              <div style="color:#e37f2d;" class="cursor" @click.stop="cancelSetManager(item)" >取消管理</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props:['roomId'],
  data() {
    return {
      placingheader: ["序号", "用户信息", "用户ID",'时间', "操作"],
      adminList:[],//管理员列表
    };
  },
  mounted(){
    this.roomManagersInfo()
  },
  methods: {
    // 取消管理
    cancelSetManager(i){
      let data = {
        isManager: 0, //是否管理（0-否 1-是）
        roomId: this.roomId, //	房间id
        targetUserId:i.userId, 
      };
      this.$api.setManager(data).then((res) => {
        this.roomManagersInfo()
      });
    },
    //获取管理列表
    roomManagersInfo(){
      this.$api.roomManagersInfo({roomId:this.roomId}).then(res=>{
        this.adminList = []
        if(res.code == 1){
          this.adminList = res.data.rows
        }
      })
    },
    darkChange() {
      this.$emit("darkAdminListChange");
    },
  },
};
</script>
<style lang='scss' scoped>
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  .pop_up {
    width: 900px;
    background: #fff;
    height: 650px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: #000000;
    z-index: 999;
  }
  .llacingCont_table{
    width: 783px;
    height: 500px;
    overflow-y: auto;
  }
  .llacingCont_heder{
    color: #333;
    border-right: none !important;
    height:50px !important;
  }
  .acatarImg{
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
  }
  .itemSex{
    color: #fff;
    background: #4e5169;
    padding: 0 2px;
    margin: 0 5px;
    border-radius: 8px;
  }
  .ageImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}
</style>