<template>
  <div class="page">
    <!-- 帮助中心 -->
    <div class="help_box" v-if="quanyiShowVip">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>VIP会员</div>
        <img style="cursor: pointer" src="../../static/59.png" alt="" @click.stop="quitChange()" />
      </div>

      <div class="titleHeader" style="overflow-y: auto; height: 600px">
        <div class="titleImg" v-if="userData.vipFlag == 0">
          <div class="flex" style="align-items: center">
            <img style="width: 56px; height: 56px; border-radius: 50%" :src="userData.avatar" alt="" />
            <div style="margin-left: 16px">
              <div class="flex" style="align-items: center">
                <div class="titleName">{{ userData.nickname }}</div>
                <div class="titleId">ID：{{ userData.userNo }}</div>
              </div>
              <div class="titleVip" style="margin-top: 10px">
                您暂时未开通会员，开通后享尊享会员专属权益
              </div>
            </div>
          </div>
        </div>
        <div class="titleImgFlex" v-if="userData.vipFlag == 1">
          <div class="flex" style="align-items: center">
            <img style="width: 56px; height: 56px; border-radius: 50%" :src="userData.avatar" alt="" />
            <div style="margin-left: 16px">
              <div class="flex" style="align-items: center">
                <div class="titleName">{{ userData.nickname }}</div>
                <div class="titleId">ID：{{ userData.id }}</div>
              </div>
              <div class="titleVipUse" style="margin-top: 10px">
                到期时间：{{ userData.vipEndTime }}到期，购买后有效期将顺延
              </div>
            </div>
          </div>
        </div>
        <div class="flex" style="justify-content: space-between; width: 560px; margin: 0 auto">
          <div class="flex">
            <div class="buyCart">购买VIP</div>
            <div class="flex" style="align-items: center; margin-left: 16px; cursor: pointer" @click="equityBtn()">
              <img style="width: 14px; height: 14px; margin-right: 3px" src="../../static/user17.png" alt="" />
              <div class="buyCartFont">权益说明</div>
            </div>
          </div>
          <div class="flex" style="align-items: center; cursor: pointer" @click="
  recordEquity = true;
quanyiShowVip = false;
getVipList();
          ">
            <div>开通记录</div>
            <img style="
                width: 10px;
                height: 15px;
                margin-left: 3px;
                margin-top: 2px;
              " src="../../static/oth6.png" alt="" />
          </div>
        </div>
        <div class="flex" style="
            width: 560px;
            margin: 16px auto 0;
            justify-content: space-between;
          ">
          <div :class="vipTabs == index ? 'vipPup' : 'vipPupFlag'" style="width: 180px; cursor: pointer"
            v-for="(item, index) in VipTypes" @click="vipCart(index)">
            <div :class="vipTabs == index ? 'vipPopImg' : 'vipPopImgSelect'">
              {{
                item.vipTitle == "月卡"
                  ? "月卡"
                  : item.vipTitle == "季卡"
                    ? "季卡"
                    : item.vipTitle == "年卡"
                      ? "年卡"
                      : ""
              }}
            </div>
            <div class="vipPrices">￥{{ item.vipDiscountPrice }} 元</div>
            <div class="vipPricesLine" style="margin-top: 4px">
              ￥{{ item.vipPrice }}
            </div>
            <!-- <img  src="../../static/user22.png" alt="" /> -->
          </div>
          <!-- <div class="vipPup" style="width: 180px">
            <div class="vipPopImg">季卡</div>
            <div class="vipPrices">￥15.00</div>
            <div class="vipPricesLine" style="margin-top: 4px">￥100.00/月</div>
          </div>
          <div class="vipPupFlag" style="width: 180px">
            <div class="vipPopImgSelect">年卡</div>
            <div class="vipPrices">￥15.00</div>
            <div class="vipPricesLine" style="margin-top: 4px">￥100.00/月</div>
          </div> -->
        </div>

        <div class="flex" style="margin: 24px 24px">
          <div class="denomination_item flex-dir" :class="modeType == 1 ? 'pitchOn' : ''" @click="modeType = 1">
            <div class="flex-content">
              <img src="../../static/170.png" alt="" style="margin-right: 10px" />支付宝
            </div>
          </div>
          <div class="denomination_item flex-dir" :class="modeType == 2 ? 'pitchOn' : ''" @click="modeType = 2">
            <div class="flex-content">
              <img src="../../static/171.png" alt="" style="margin-right: 10px" />
              <span style="color: #09bb07">微信支付</span>
            </div>
          </div>
        </div>

        <div class="vipBtn" v-if="userData.vipFlag == 0" @click="confirmVip">
          确认协议并开通会员
        </div>

        <div class="vipBtn" v-if="userData.vipFlag == 1" @click="confirmVip">立即续费</div>
        <div class="vipFont">
          开通前阅读
          <span style="color: rgba(26, 129, 244, 1); cursor: pointer" @click="
  vipSerace = true;
quanyiShowVip = false;
getAgreement('vipAgreement');
          ">《会员服务协议》</span>
        </div>
        <div class="vipLine"></div>
        <div class="flex" style="align-items: center; margin: 0 auto; width: 140px">
          <img src="../../static/user19.png" alt="" />
          <div class="zunText" style="margin: 0 10px">尊享特权</div>
          <img src="../../static/user20.png" alt="" />
        </div>
        <div class="flex" style="
            width: 560px;
            margin: 16px auto 0;
            justify-content: space-between;
          ">
          <div class="vipPup flex" style="
              width: 180px;
              height: 76px;
              padding: 16px 12px;
              box-sizing: border-box;
              background: rgba(250, 246, 243, 1);
            ">
            <img class="vipUserLogo" src="../../static/user33.png" alt="" />
            <div style="margin-left: 10px">
              <div class="zhuanshuText">专属身份标识</div>
              <div class="zhangxianText">彰显身份特权</div>
            </div>
          </div>
          <div class="vipPup flex" style="
              width: 180px;
              height: 76px;
              padding: 16px 12px;
              box-sizing: border-box;
              background: rgba(250, 246, 243, 1);
            ">
            <img class="vipUserLogo" src="../../static/user34.png" alt="" />
            <div style="margin-left: 10px">
              <div class="zhuanshuText">双向撤回</div>
              <div class="zhangxianText">私聊不留痕</div>
            </div>
          </div>
          <div class="vipPup flex" style="
              width: 180px;
              height: 76px;
              padding: 16px 12px;
              box-sizing: border-box;
              background: rgba(250, 246, 243, 1);
            ">
            <img class="vipUserLogo" src="../../static/user35.png" alt="" />
            <div style="margin-left: 10px">
              <div class="zhuanshuText">群向撤回</div>
              <div class="zhangxianText">群聊更安心</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="help_boxNew" v-if="equityShow">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>权益说明</div>
        <img style="cursor: pointer" src="../../static/59.png" alt="" @click.stop="
  quanyiShow = false;
quanyiShowVip = true;
recordEquity = false;
equityShow = false;
        " />
      </div>
      <div style="width: 135px; height: 130px; margin: 40px auto 0">
        <img style="width: 100%; height: 100%" src="../../static/user21.png" alt="" />
      </div>
      <div style="padding-left: 60px; padding-top: 32px">
        <div class="userVip">购买会员可享受专属权益：</div>
        <div class="userContent" style="padding-top: 12px">
          <div>1、专属身份标识；</div>
          <div>2、单聊双向撤回；</div>
          <div>3、群向撤回；</div>
        </div>
      </div>
    </div>

    <div class="help_boxNew" v-if="vipSerace">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>会员服务协议</div>
        <img style="cursor: pointer" src="../../static/59.png" alt="" @click.stop="
  quanyiShow = false;
quanyiShowVip = true;
recordEquity = false;
equityShow = false;
vipSerace = false;
        " />
      </div>
      <div style="padding-left: 60px; padding-top: 32px; box-sizing: border-box">
        <div class="userContent" style="padding-top: 12px">
          <div v-html="agreementText"></div>
        </div>
      </div>
    </div>

    <div class="help_boxNewRegion" v-if="recordEquity">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>开通记录</div>
        <img style="cursor: pointer" src="../../static/59.png" alt="" @click.stop="
  quanyiShow = false;
quanyiShowVip = true;
recordEquity = false;
        " />
      </div>
      <div class="tables">
        <el-table :data="tableData" border style="
            width: 820px;
            margin: 40px auto 0;
            background: rgba(245, 246, 247, 1);
          ">
          <el-table-column prop="buyTime" label="购买时间" width="213">
          </el-table-column>
          <el-table-column prop="orderNumber" label="会员卡" width="152">
            <template slot-scope="scope">
              <div class="flex">
                {{ scope.row.vipTime }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payType" label="支付方式" width="152">
            <template slot-scope="scope">
              <div class="flex">
                <p>
                  {{
                    scope.row.payType == 1
                      ? "支付宝"
                      : scope.row.payType == 2
                        ? "微信"
                        : ""
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="charmVal" label="描述" width="300">
            <template slot-scope="scope">
              <div class="flex">
                <p>会员到期时间： {{ scope.row.endTime }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="help_box" v-if="quanyiShowVipPay">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>VIP会员</div>
        <img style="cursor: pointer" src="../../static/59.png" alt=""
          @click.stop="quanyiShowVipPay = false; window.clearTimeout(this.stopTime)" />
      </div>
      <div class="helpVieContent">
        <div style="width: 350px; margin: 0 auto">
          <img style="
              cursor: pointer;
              width: 350px;
              height: 350px;
            " :src="wxImg" alt="" v-if="modeType == 2" />

          <iframe style="margin-right:24px" v-if="modeType == 1" name="iframeMap" id="iframeMapViewComponent"
            v-bind:src="AlipayValue" width="350px" height="350px" frameborder="0" scrolling="no"
            ref="iframeDom"></iframe>
        </div>

        <div class="flex" style="margin: 26px auto 0; align-items: center; width: 350px">
          <!-- <img
            style="cursor: pointer; width: 40px; height: 40px; margin-top: 2px"
            src="../../static/user56.png"
            alt=""
          />
          <div style="margin-left: 10px">
            <div style="width: 150px">打开手机 微信 扫一扫继续付款</div>
          </div> -->
        </div>
      </div>
      <!-- <div class="helpVieContentSuccess">
        支付成功前请勿关闭本窗口, 以防丢单
      </div> -->
      <div class="userPay" @click="checkPay">我已支付</div>
    </div>
    <div class="help_box" v-if="quanyiShowVipSuccess">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div>VIP会员</div>
        <img style="cursor: pointer" src="../../static/59.png" alt="" @click.stop="quanyiShowVipSuccess = false" />
      </div>
      <div class="helpVieContentSuccessBuy">
        <div style="width: 72px; height: 72px; margin: 0 auto">
          <img style="cursor: pointer; width: 72px; height: 72px; margin-top: 20px" src="../../static/user57.png"
            alt="" />
        </div>

        <div class="flex" style="margin: 26px auto 0; align-items: center; width: 200px">
          <div style="margin-left: 10px; width: 100%">
            <div class="successFul">购买成功</div>
          </div>
        </div>
      </div>
      <div class="helpVieContentPupText">支付方式：微信支付</div>
      <div class="helpVieContentPupText">支付时间：2023-07-29 14:00</div>
      <div class="userPaySuccess" @click="quanyiShowVipSuccess = false">
        完成
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quanyiShowVipSuccess: false,
      quanyiShowVipPay: false,
      typeOptions: [],
      typeValue: "",
      text: "",
      helpType: 0,
      textareaSeason: "", //反馈内容
      recordShow: false, //反馈记录
      reportImgList: [], //举报图片列表
      feedbackList: [], //反馈记录
      agreementText: "", //协议内容
      quanyiShow: false,
      quanyiShowVip: true,
      equityShow: false,
      recordEquity: false,
      tableData: [],
      userData: {},
      loading: false,
      vipSerace: false,
      VipTypes: [],
      vipTabs: 0,
      modeType: 1,
      wxImg: "",
      AlipayValue: '',
      stopTime: ''
    };
  },
  mounted() {
    //
    this.getUserInfo();
    this.queryVipTypes();
  },
  destroyed() {
    window.clearTimeout(this.stopTime)
  },
  methods: {
    //打开在线客服
    getService() {
      // window.localtion.href = 'https://pc.yinsuwangluokj.com/rcrtc/#/chat.html'
      // location.href = 'https://pc.yinsuwangluokj.com/rcrtc/#/chat.html'
      var a = document.createElement("a");
      a.setAttribute("href", "https://yicheng.yinsuwangluokj.com/chat.html");
      a.setAttribute("target", "_blank");
      a.click();
    },
    async getVipList() {
      let data = {
        types: 0,
      };
      this.$api.vipBuyRecordVipTypes(data).then((res) => {
        console.log(res, "rees");
        if (res.code == 1) {
          this.tableData = res.data.rows;
        }
      });
    },
    confirmVip() {
      if (this.modeType == 1) {
        this.getAlipayVip();
      }
      if (this.modeType == 2) {
        this.wxpayVipVip()
      }
      // console.log(this.vipTabs,'vipTabs')
      this.quanyiShowVipPay = true;
    },
    // 获取反馈记录
    getQueryFeedbackList() {
      this.recordShow = true;
      this.helpType = 2;
      this.$api.getQueryFeedbackList().then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.feedbackList = res.data.rows;
          this.feedbackList.map((item) => {
            item.image = item.image.split(",");
          });
        }
      });
    },
    // 显示意见反馈
    helpTypeChange() {
      this.helpType = 2;
      this.reportImgList = [];
      this.getQueryFeedbackType();
    },
    equityBtn() {
      this.quanyiShowVip = false;
      this.quanyiShow = false;
      this.equityShow = true;
    },
    getUserInfo() {
      this.$api.queryUser().then((res) => {
        if (res.code == 1) {
          this.userData = res.data;
          this.loading = false;
        } else {
          this.loading = true;
        }
      });
    },

    aliPayPc() { },
    checkWx() {
      this.$api.wxPayPc().then((res) => {
        if (res.code == 2) {
          this.wxImg = "data:image/png;base64," + res.data.base64Image
          this.orderNo = res.data.orderNo
        }
      });
    },

    checkPay(order) {
      
      window.clearTimeout(this.stopTime)
      // getAliPayPayment
      if (this.modeType == 1) {
        this.$api.aliPayResult({ outTradeNo: this.alipayOrder }).then((res) => {
          if (res.code == 1) {
            if (res.data == 2) {
              this.queryByMyDiamond();
              this.alipayPopupShow = false;
              this.$message.success("充值成功");
              this.quanyiShowVipPay = false
            } else {
              this.$message.warning("充值未成功");
            }
          }
        });
      }
      if (this.modeType == 2) {
        this.$api.weChatPayResult({ orderNo: this.orderNo }).then(res => {
          if (res.code == 1) {
            if (res.data == 2) {
              this.queryByMyDiamond()
              this.payPopupShow = false
              this.$message.success('充值成功')
              this.quanyiShowVipPay = false
            } else {
              this.$message.warning("充值未成功");
            }
          }
        })
      }
      is.getUserInfo();
      // this.$api.getAliPayPayment(() => {
      //   outTradeNo: this.outTradeNo;
      // });
    },
    getAlipayVip() {
      let vipId = this.VipTypes[this.vipTabs].id;
      this.$api
        .alipayVip({
          vipId: vipId,
        })
        .then((res) => {
          if (res.code == 1) {

            console.log(res.data.result, 'res.data.result')
            this.AlipayValue = res.data.resultUrl
            this.alipayOrder = res.data.orderNo
            this.alipayPopupShow = true
            this.getAliPayPayment();
          }
          console.log(res, "resres");
        });
    },
    wxpayVipVip() {
      let vipId = this.VipTypes[this.vipTabs].id;
      this.$api
        .wxpayVip({
          vipId: vipId,
        })
        .then((res) => {
          this.wxImg = "data:image/png;base64," + res.data.base64Image
          this.orderNo = res.data.orderNo
          this.payPopupShow = true
          this.getWeChatPayResult()
        });
    },
    getWeChatPayResult() {
      this.$api.weChatPayResult({ orderNo: this.orderNo }).then(res => {
        if (res.code == 1) {
          if (res.data == 2) {
            this.queryByMyDiamond()
            this.payPopupShow = false
            this.$message.success('充值成功')
          } else {
            this.stopTime = setTimeout(() => {
              this.getWeChatPayResult()
            }, 3000)
          }
        }
      })
    },
    getAliPayPayment() {
      this.$api.aliPayResult({ outTradeNo: this.alipayOrder }).then((res) => {
        if (res.code == 1) {
          if (res.data == 2) {
            this.queryByMyDiamond();
            this.alipayPopupShow = false;
            this.$message.success("充值成功");
          } else {
            this.stopTime = setTimeout(() => {
              this.getAliPayPayment();
            }, 3000);
          }
        }
      });
    },
    queryVipTypes() {
      this.$api.queryVipTypes().then((res) => {
        // console.log(res.data, "resresres");
        if (res.code == 1) {
          this.VipTypes = res.data;
        }
      });
    },
    // 提交反馈
    setAddFeedback() {
      if (!this.typeValue) return this.$message.info("请输入反馈内容");
      this.$api
        .setAddFeedback({
          image: this.reportImgList.toString(),
          type: this.typeValue,
          content: this.textareaSeason,
        })
        .then((res) => {
          if (res.code == 1) {
            this.helpType = 0;
            this.typeValue = "";
            this.textareaSeason = "";
            this.reportImgList = [];
            this.recordShow = false;
            this.$message.info("举报已经反馈");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 反馈类型
    getQueryFeedbackType() {
      this.$api.queryFeedbackType().then((res) => {
        // console.log('反馈类型',res);
        if (res.code == 1) {
          this.typeOptions = res.data;
        }
      });
    },
    vipCart(index) {
      this.vipTabs = index;
    },
    handleAvatarSuccess(res) {
      if (res.code == 1) {
        this.reportImgList.push(res.data.url);
      }
    },
    // 获取协议数据
    getAgreement(configName) {
      this.$api.getAgreement({ configName }).then((res) => {
        if (res.code == 1) {
          this.helpType = 1;
          this.agreementText = res.data;
        }
      });
    },
    // 关闭页面
    quitChange() {
      this.$emit("vipShow");
    },
  },
};
</script>

<style scoped lang="scss">
.helpVieContentPupText {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.helpVieContentSuccessTime {}

.userPaySuccess {
  width: 176px;
  height: 40px;
  background: #ffdd00;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 40px;
  margin: 40px auto 0;
}

.successFul {
  width: 100%;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 14px;
  color: #4dc777;
  text-align: center;
  margin-top: 8px;
}

.helpVieContentSuccess {
  width: 300px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #fa554a;
  text-align: center;
  margin: 0 auto 10px;
}

.helpVieContentPup {
  width: 300px;
  margin: 0 auto 10px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #1b85fb;
  text-align: center;
}

.userPay {
  width: 350px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #ffdd00;
  border-radius: 4px 4px 4px 4px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #212121;
  margin: 24px auto;
  cursor: pointer;
}

.aliyPay {
  margin-left: 48px;
  display: flex;
  align-items: center;
}

.wxPay {
  display: flex;
  align-items: center;
}

.helpVieContent {
  width: 350px;
  border-radius: 8px 8px 8px 8px;
  margin: 40px auto 24px;
}

.helpVieContentSuccessBuy {
  width: 350px;
  border-radius: 8px 8px 8px 8px;
  margin: 40px auto 24px auto;
}

.zhuanshuText {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #865b34;
  margin-top: 4px;
}

.zhangxianText {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #a9896e;
  margin-top: 4px;
}

.vipUserLogo {
  width: 44px;
  height: 44px;
}

.help_boxNewRegion {
  width: 860px;
  height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  border-radius: 4px 4px 4px 4px;
}

.titleImg {
  width: 560px;
  height: 96px;
  margin: 40px auto;
  background-image: url("../../static/user16.png");
  background-size: 100% 100%;
  padding: 20px;
  box-sizing: border-box;
}

.titleImgFlex {
  width: 560px;
  height: 96px;
  margin: 40px auto;
  background-image: url("../../static/user32.png");
  background-size: 100% 100%;
  padding: 20px;
  box-sizing: border-box;
}

.vipPup {
  width: 180px;
  height: 96px;
  background: linear-gradient(90deg, #efd7b3 0%, #ddb280 100%);
  border-radius: 4px 4px 4px 4px;
  position: relative;
}

.vipPopImg {
  width: 66px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url("../../static/user22.png");
  background-size: 100% 100%;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}

.vipPrices {
  font-family: Lato, Lato;
  font-weight: bold;
  font-size: 24px;
  color: #98601f;
  text-align: center;
  margin-top: 30px;
}

.vipPricesLine {
  font-family: Lato, Lato;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 0px;
  text-align: center;
  font-style: normal;
  text-decoration-line: line-through;
  text-transform: none;
}

.vipPopImgSelect {
  width: 66px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url("../../static/user23.png");
  background-size: 100% 100%;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: rgba(102, 102, 102, 1);
}

.vipPupFlag {
  width: 180px;
  height: 96px;
  background: rgba(232, 232, 232, 1);
  border-radius: 4px 4px 4px 4px;
  position: relative;
}

.vipBtn {
  width: 392px;
  height: 54px;
  background-image: url("../../static/user18.png");
  background-size: 100% 100%;
  margin: 32px auto 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 19px;
  color: #3e1d1d;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
}

.vipFont {
  width: 100%;
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(117, 116, 117, 1);
  margin-top: 16px;
}

.zunText {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 16px;
  color: #46240e;
}

.vipLine {
  width: 560px;
  height: 1px;
  background-color: rgba(230, 230, 230, 1);
  margin: 24px auto;
}

.buyCartFont {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #1b85fb;
}

.agreement_style {
  color: #333;
}

.report_img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
}

// 反馈记录
.record_box {
  width: 680px;
  height: 664px;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;

  .record_cont {
    color: #6a6a94;
    font-size: 12px;
    padding: 40px 0 0 83px;

    .record_head {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .record_text {
      width: 490px;
    }

    .img_box {
      width: 490px;
      margin: 12px 0;

      .img_item {
        width: 64px;
        height: 64px;
        margin-right: 8px;
        border-radius: 8px;
      }
    }

    .platform_record {
      width: 490px;
      height: 36px;
      color: #d3d4dc;
      padding: 10px 8px;
      margin-top: 17px;
      background: #252b4a;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}

.titleVip {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.titleVipUse {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #f83b37;
}

.titleName {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.titleId {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  margin-left: 12px;
  color: #333333;
}

.buyCart {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 16px;
  color: #212121;
}

.userVip {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
}

.userContent {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

// 意见反馈
.feedback_box {
  width: 440px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;

  .feedback_cont {
    width: 320px;
    color: #333;
    margin: 40px auto;
  }

  .feedback_textarea {
    width: 320px;
    height: 108px;
    border: none; // 去除边框
    outline: none; // 去除聚焦边框
    resize: none; // 去除右下的可拖动
    appearance: none; // 去除内阴影样式
    background: #f5f5f5;
    border-radius: 4px;
    color: #333;
  }

  .feedback_textarea::-webkit-input-placeholder {
    color: #8e92b1;
  }

  .feedback_btn {
    width: 320px;
    height: 40px;
    color: #333;
    margin-top: 40px;
    background: #ffdd00;
    border-radius: 4px;
  }

  .feedback_text {
    width: 100%;
    color: #6a6a94;
    margin-top: 24px;
  }

  .feedback_select {
    width: 240px;
    height: 36px;
    background: #f5f5f5;
    border-radius: 4px;
  }
}

::v-deep .el-input__inner {
  color: #333;
  background-color: rgba(54, 54, 89, 0.2);
}

.page {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  font-size: 16px;
  color: #333333;
  background: rgba($color: #0000, $alpha: 0.8);
}

.help_boxNew {
  width: 440px;
  height: 391px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  border-radius: 4px 4px 4px 4px;
}

.help_box {
  width: 630px;
  height: 661px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  border-radius: 4px 4px 4px 4px;

  .title_content {
    >img {
      width: 13px;
      height: 13px;
    }
  }

  .content_box {
    padding: 22px 50px 22px 60px;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    .con_item {
      width: 120px;
      height: 40px;
      color: #333;
      font-size: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 10px 0;
      background: #f5f5f5;

      >img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }

  .content_bottom {
    margin-top: 141px;

    .bottm_item {
      width: 250px;
      height: 67px;
      display: flex;
      align-items: center;
      background: #f5f5f5;

      >img {
        width: 23px;
        height: 23px;
        margin: 0 10px 0 18px;
      }
    }

    .bottom_text {
      >div {
        font-size: 12px;
        color: #333;
      }

      >p {
        font-size: 10px;
        margin-top: 4px;
        color: rgba(142, 146, 177, 1);
      }
    }
  }

  .help_content {
    width: 90%;
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}

.denomination_item {
  width: 200px;
  height: 56px;
  margin: 0 10px 10px 0;
  border-radius: 4px;
  background: #f4f4f4;
}

.pitchOn {
  color: #fff;
  background: #6777ef;
}

/deep/.el-input__inner::placeholder {
  color: #333 !important;
}
</style>
