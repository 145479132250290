<template>
  <!-- 获取验证码 -->
  <div class="cursor">
    <!-- setTimeout方法: -->
    <!-- <el-button @click="getCode" :disabled="disadle">{{ massage }}</el-button> -->
    <div style="color: #4177DD; margin-left: 18px" v-if="disadle">{{massage}}</div>
    <div style="color: #4177DD; margin-left: 18px" @click="getAliSendCode" v-else>{{massage}}</div>
    <!-- setInterval方法: -->
    <!-- <el-button @click="getCodes" :disabled="disadles">{{ msgText }}</el-button> -->
  </div>
</template>
<script>
export default {
name: '',
components: {},
props:['event','mobile'],
data(){
	return{
	  massage: "获取验证码",
    disadle: false,
    disadles: false,
    count: 60,
    msgText: "获取验证码"
	}
},
  methods: {
    getAliSendCode(){
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; 
      if (!reg.test(this.mobile)) return this.$message.info("手机号码格式错误");
      this.$api.getAliSendCode({event:this.event,mobile:this.mobile}).then(res=>{
        console.log(res);
        if(res.code == 1){
          this.getCode()
        }else{
          this.$message.info(res.msg)
        }
      })
    },
  //setTimeout方法
    getCode() {
      this.massage = "重新获取(" + this.count + ")";
      this.count--;
      if (this.count <= 0) {
        this.count = 60;
        this.massage = "获取验证码";
        this.disadle = false;
      } else {
        this.disadle = true;
        setTimeout(() => {
          this.getCode();
        }, 1000);
      }
    },
    //setInterval方法
    getCodes() {
      let times = 60;
      let setInter = setInterval(() => {
        times--;
        if (times <= 0) {
          this.msgText = "获取验证码";
          this.disadles = false;
          clearInterval(setInter);
        } else {
          this.msgText = "重新获取(" + times + ")";
          this.disadles = true;
        }
      }, 1000);
    }
  }

};
</script>
<style lang='scss'>
</style>