<template>
	<!-- 解析svga文件 -->
	<div>
		<!-- width: widthNum, -->
		<div style="width:100%;" :id="'demoSvag' + cid" :style="{  height: heightNum }"></div>
	</div>
</template>
<script>
	import SVGA from "svgaplayerweb"; //svga图片播放
	export default {
		name: "svgaImg",
		props: {
			value: {
				type: [String,Object],
				required: true,
			},
			cid: {
				type: [String, Number],
				required: false,
				default: "",
			},
			widthNum: {
				type: String,
				required: false,
				default: "90px",
			},
			heightNum: {
				type: String,
				required: false,
				default: "90px",
			},
			loopsNum: {
				type: [String, Number],
				required: false,
				default: 0,
			},
		},
		components: {},
		data() {
			return {
				visible: false,
				svgaUrl: "",
				cid1: "",
				player: "",
				parser: "",
				isShow:true
				// loopsValue:0
			};
		},
		created() {},
		mounted() {
			this.infoSvga(this.value);
		},
		destroyed() {
			// this.player.destroy()
			// this.player.stop()
			// this.parser.destroy()
		},
		watch: {
			value: {
				immediate: true,
				handler(v) {
					if (v) {
						this.svgaUrl = v;
					} else {
						this.svgaUrl = "";
					}
					this.infoSvga(this.svgaUrl);
				},
			},
			cid(v) {
				if (v) {
					this.cid1 = v;
				} else {
					this.cid1 = "";
				}
			},
		},
		methods: {

			// 解析svga文件
			infoSvga(myUrl) {
				var that = this;
				this.$nextTick(() => {

					that.player = new SVGA.Player("#demoSvag" + that.cid);
					that.parser = new SVGA.Parser("#demoSvag" + that.cid);

					that.parser.load(myUrl, function(videoItem) {
						//图片路径需要线上地址，本地引用会报错
						that.player.clearsAfterStop = true;
						that.player.loops = 1; // 设置循环播放次数是1,0是无限播放

						that.player.setVideoItem(videoItem);
						that.player.startAnimation();

						that.player.onFinished(function() {
							that.$emit("closeSvgaChange");
						});
					});
				});
			},
			stopSvga() {
				// console.log('svgasvga')
			},
		},
	};
</script>

<style lang='scss'>
	#demoSvag {
		width: 100%;
		height: 100%;
	}
</style>