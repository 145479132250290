<template>
	<!-- 我的消息 -->
	<div class="page1">
		<!-- 他人主页 -->
		<othersHome :userId="manitoId" v-if="othersShow" types="1" @closeOthers="showChange('others')"></othersHome>
		<div class="message_box" ref="screenshotTarget">
			<!-- 头部start -->
			<div class="titleIcon"></div>
			<div class="title_content">
				<div class="font-14 flex-aling">消息</div>
				<img alt="img" class="messageText" src="../../static/quit.png" @click="closeMessage()" />
			</div>
			<!-- 头部end -->
			<div class="flex">
				<!-- 左侧tab -->
				<div class="left_tab">
					<img alt="img" :src="myUser.avatar" class="head" />
					<div class="tab_item flex-dir" :class="tabType == 0 ? 'tab_paht' : ''" @click="tabTypeChange(0)">
						<img alt="img" v-if="tabType == 0" src="../../static/user40.png" />
						<img alt="img" v-else src="../../static/user39.png" />
						<div>消息</div>
					</div>
					<div class="tab_item flex-dir" :class="tabType == 1 ? 'tab_paht' : ''" @click="tabTypeChange(1)">
						<img alt="img" v-if="tabType == 1" src="../../static/user41.png" />
						<img alt="img" v-else src="../../static/user36.png" />
						<div>关注</div>
					</div>
					<div class="tab_item flex-dir" :class="tabType == 2 ? 'tab_paht' : ''" @click="tabTypeChange(2)">
						<img alt="img" v-if="tabType == 2" src="../../static/user42.png" />
						<img alt="img" v-else src="../../static/user37.png" />
						<div>粉丝</div>
					</div>
					<div class="tab_item flex-dir" :class="tabType == 3 ? 'tab_paht' : ''" @click="tabTypeChange(3)">
						<img alt="img" v-if="tabType == 3" src="../../static/user43.png" />
						<img alt="img" v-else src="../../static/user38.png" />
						<div>通讯录</div>
					</div>
				</div>
				<!-- 左侧tab end -->
				<!-- 消息列表 -->

				<!-- 关注&&粉丝列表 -->
				<div class="message_list" v-if="tabType == 1">
					<div v-if="attentionlist.length == 0" class="flex flex-content" style="flex-direction: column">
						<img alt="img" src="../../image/room/wu.png" style="width: 120px; margin-top: 240px" />
						<p style="margin-top: 12px;color:#666">暂无数据</p>
					</div>
					<div class="flex-spacebetween" v-else v-for="(item, index) in attentionlist" :key="index" :class="listid == item.id ? 'message_item-active' : 'message_item'"
						@click="goSelect(item, '', item.id)">
						<div class="flex">
							<div style="position: relative;"><img alt="img" :src="item.avatar" class="message_head" /><img src="../../static/vip.png"  v-if='item.vipFlag==1' style="width:auto;height:13px;position:absolute;bottom:0;left:2px;" /></div>
							<div>
								<div class="font-14" style="display: flex; align-items: center">
									<div :class="{'vipred':item.vipFlag==1}">
										{{ item.nickname }}
									</div>
									<div class="pageSex" style="margin-left: 6px" v-if="item.sex == 0">
										<img alt="img" src="../../image/message/25.png" style="
											width: 12px;
											height: 12px;
											margin-left: 4px;
											margin-right: 2px;
											color:#fff
										  " />
										<div style="color:#fff">
											{{ item.age }}
										</div>
									</div>
									<div class="pageSex1" style="margin-left: 6px" v-if="item.sex == 1">
										<img alt="img" src="../../static/134.png" style="
										width: 12px;
										height: 12px;
										margin-left: 4px;
										margin-right: 2px;
										color:#fff
										" />
										<div style="color:#fff">
											{{ item.age }}
										</div>
									</div>
								</div>
								<div class="font-12 Signature" style="color: #8e92b1;margin-top:6px">
									{{ item.personalSignature }}
								</div>
							</div>
						</div>
						<img alt="img" v-if="item.isConcern == 2" src="../../image/message/33.png" class="message_icon" />
					</div>
				</div>
				<div class="message_list" v-if="tabType == 2">
					<div v-if="attentionlist.length == 0" class="flex flex-content" style="flex-direction: column">
						<img alt="img" src="../../image/room/wu.png" style="width: 120px; margin-top: 240px" />
						<p style="margin-top: 12px">暂无数据</p>
					</div>
					<div class="flex-spacebetween" v-else v-for="(item, index) in attentionlist" :key="index" :class="listid == item.id ? 'message_item-active' : 'message_item'"
						@click="goSelect(item, '', item.id)">
						<div class="flex">
							<div style="position: relative;"><img alt="img" :src="item.avatar" class="message_head" /><img src="../../static/vip.png"  v-if='item.vipFlag==1' style="width:auto;height:13px;position:absolute;bottom:0;left:2px;" /></div>
							<div>
								<div class="font-14" style="display: flex; align-items: center">
									<div :class="{'vipred':item.vipFlag==1}">
										{{ item.nickname }}
									</div>
									<div class="pageSex" style="margin-left: 6px" v-if="item.sex == 0">
										<img alt="img" src="../../image/message/25.png" style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      " />
										<div style="color:#fff">
											{{ item.age }}
										</div>
									</div>
									<div class="pageSex1" style="margin-left: 6px" v-if="item.sex == 1">
										<img alt="img" src="../../static/134.png" style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      " />
										<div style="color:#fff">
											{{ item.age }}
										</div>
									</div>
								</div>
								<div class="font-12 Signature" style="color: #8e92b1">
									{{ item.personalSignature }}
								</div>
							</div>
						</div>
						<img alt="img" v-if="item.isConcern == 2" src="../../image/message/33.png" class="message_icon" />
					</div>
				</div>

				<!-- 通讯录 -->

				<div class="message_list" style="background: #fff;" v-if="tabType == 3">
					<div class="flex" style="
              color: #333;
              align-items: center;
              padding: 14px;
              box-sizing: border-box;
            ">
						<input class="inpMassage" v-model="tongModerl" type="text" @change="searchChange()" placeholder="搜索好友" />
						<img alt="img" src="../../static/user24.png" style="width: 20px; height: 20px; margin-left: 14px;cursor: pointer;" @click="createGroupId()" />
					</div>
					<div class="massageBtn">
						<div :class="tongMassage == 0 ? 'massageBtnFlex' : 'massageBtnFlexFriend'" @click="friendBtn(0)">
							好友
						</div>
						<div :class="tongMassage == 1 ? 'massageBtnFlex' : 'massageBtnFlexFriend'" @click="friendBtn(1)">
							分组
						</div>
						<div :class="tongMassage == 2 ? 'massageBtnFlex' : 'massageBtnFlexFriend'" @click="friendBtn(2)">
							群聊
						</div>
					</div>
					<div>
						<div style="padding: 14px;" v-if="tongMassage == 0">
							<div v-if="tongxuArr.length" class="flex" :class="haoyouid == item.userId?'new-bg':'new-bg-view'" style="cursor: pointer;" v-for="item in tongxuArr" @click="tongxuArrShow(item)" >
								<div style='position:relative;margin-left: 14px;'><img alt="img" :src="item.avatar" style="
								width: 40px;
								height: 40px;
								border-radius: 50%;
								border:solid 1px #eee
							  " />
									<img src="../../static/vip.png"  v-if='item.vipFlag==1' style="width:auto;height:13px;position:absolute;bottom:0;left:50%;transform: translateX(-50%);" /></div>
								<div style="margin-left: 10px">
									<div class="flex">
										<div style="color: #333;font-size: 14px;" :class="{'vipred':item.vipFlag==1}">{{ item.nickName }}</div>
										<div class="messagePerson flex" style="margin-left: 8px" v-if="item.sex == 0">
											<img alt="img" style="
												  width: 12px;
												  height: 12px;
												  margin-left: 4px;
												  margin-top: 4px;
												" src="../../static/user25.png" />
																	<div style="
												  margin-left: 4px;
												  margin-top: 4px;
												  margin-right: 4px;
												  color:#fff
												">
												{{ item.age }}
											</div>
										</div>
										<div class="messagePerson flex" style="margin-left: 8px;background: #ff807c;" v-if="item.sex == 1">
											<img alt="img" style="
												width: 12px;
												height: 12px;
												margin-left: 4px;
												margin-top: 4px;
											  " src="../../static/134.png" />
																	<div style="
												margin-left: 4px;
												margin-top: 4px;
												margin-right: 4px;
												color:#fff
											  ">
												{{ item.age }}
											</div>
										</div>
									</div>
									<div class="tongxunText text-cut" style="margin-top: 5px">
										{{ item.title }}
									</div>
								</div>
							</div>
							<div v-if="tongxuArr.length == 0" style="width:100%;text-align:center;font-size:12px;color:#333;padding-top:24px;">暂无好友，快去添加吧</div>
						</div>
						<div style="padding: 14px;" v-if="tongMassage == 2">
							<div class="flex" style="align-items: center;cursor: pointer;" :class="haoyouid == ('GROUP' + item.id)?'new-bg':'new-bg-view'" v-for="item in groupArrList" @click='grounpClick(item)'>
								<img alt="img" :src="item.avatar" style="
                    width: 40px;
                    height: 40px;
                    margin-left: 14px;
                    border-radius: 50%;
                  " />
								<div style="margin-left: 10px">
									<div class="flex">
										<div style="color: #333">{{ item.name }}</div>
									</div>
								</div>
							</div>
							<div v-if="groupArrList.length == 0" style="width:100%;text-align:center;font-size:12px;color:#333;padding-top:24px;">
								<!-- <img alt="img" src="../../image/message/5.png" v-if="messageList.length == 0" class="vacancy" /> -->
								<span>暂无群聊，快去创建吧</span>
							</div>
						</div>
						<div style="padding: 14px;" v-if="tongMassage == 1">
							<el-tree :data="friendsArr" :props="defaultProps" @node-click="handleNodeClick">
								<template v-slot="{ data }">
									<div style="display:flex;width:100%;align-items:center;">
										<div style="display:flex;width:100%;align-items:center;" @click="goBtnMessage(data)">
											<img alt="img" style="width:32px;height:32px;border-radius: 50%;" v-if="data.avatar" :src="data.avatar"></img>
											<div style="margin-left:4px">
												<div style="font-size:15px;color:#333;height:20px;line-height: 20px;">{{ data.groupingName }}</div>
												<div class="textOv text-cut" style="max-width: 100px;font-size:12px;color:#999" :style="data.avatar ? 'line-height:16px' : ''"
													v-if="data.avatar">{{data.title}}
												</div>
											</div>
											<div class="userNum" v-if="data.groupingNum && data.groupingNum != 0 && data.onlineNum">{{ data.onlineNum }}/{{ data.groupingNum }}
											</div>
										</div>
									</div>
								</template>
							</el-tree>
						</div>
					</div>
				</div>
				<!-- 我的消息 -->
				<div class="message_list" v-if="tabType == 0">
					<!-- 狗书公告 -->
					<div class="message_item flex-aling" v-for="(item, index) in informList" :key="index" :class="listid == item.id ? 'message_item-active' : 'message_item'"
						@click.stop="queryAllList(item, index + 1)">
						<div v-if="index == 0 && platformNum != 0" class="num_icon">
							{{ platformNum }}
						</div>
						<div v-if="index == 1 && systemNum != 0" class="num_icon">
							{{ systemNum }}
						</div>
						<div v-if="index == 2 && orderNumUnread != 0" class="num_icon">
							{{ orderNumUnread }}
						</div>
						<div v-if="index == 3 && sendOrdersNum != 0" class="num_icon">
							{{ sendOrdersNum }}
						</div>
						<div v-if="index == 4 && interactionNum != 0" class="num_icon">
							{{ interactionNum }}
						</div>

						<div class="flex">
							<img alt="img" :src="item.imgUrl" class="message_head" />
							<div style="width: 200px">
								<div class="font-14 flex-spacebetween flex-aling">
									<div>{{ item.name }}</div>
									<div class="font-12" style="color: #6a6a94;" v-for="timeItem in newestMessage" :key="timeItem.createTime">
										<span v-if="index + 1 == timeItem.type">{{timeItem.createTime}}</span>
									</div>
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1;margin-top:2px" v-for="timeItem in newestMessage" :key="timeItem.createTime">
									<span v-if="index + 1 == timeItem.type">{{timeItem.title}}</span>
								</div>
							</div>
						</div>
					</div>
					<!-- 会话列表 -->
					<div class="flex-spacebetween" @click="selectUserChat(dialogueItem)" :class="listid == (dialogueItem.userProfile && dialogueItem.userProfile.userID || dialogueItem.conversationID) ? 'message_item-active' : 'message_item'" v-for="dialogueItem in dialogueList" :key="dialogueItem.conversationID"
						v-if="dialogueItem.groupProfile || dialogueItem.userProfile">
						<div class="flex" v-if="dialogueItem.type == 'C2C' && dialogueItem.userProfile.nick">
							<div class="unreadNum" v-if="dialogueItem.unreadCount > 0">
								{{ dialogueItem.unreadCount }}
							</div>
							<img alt="img" :src="dialogueItem.userProfile.avatar" class="message_head" />
							<div>
								<div class="font-14">{{ dialogueItem.userProfile.nick }}</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px" v-if="dialogueItem.lastMessage.type == 'TIMImageElem'">
									[图片]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 21 && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 31 && JSON.parse(dialogueItem.lastMessage.payload.data).businessID == 'userCard_message'">
									[名片]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 21 && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 31 && dialogueItem.lastMessage.payload.description">
									[文件]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 21 && !dialogueItem.lastMessage.payload.description && JSON.parse(dialogueItem.lastMessage.payload.data).businessID == 'location_message'">
									[位置]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && JSON.parse(dialogueItem.lastMessage.payload.data).msgType != 21 && !dialogueItem.lastMessage.payload.description && JSON.parse(dialogueItem.lastMessage.payload.data).businessID != 'C2C_revocationMsgList'">
									[通话]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px;"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && (JSON.parse(dialogueItem.lastMessage.payload.data).msgType == 21 || JSON.parse(dialogueItem.lastMessage.payload.data).msgType == 31)">
									[提示消息]
								</div>
								<div v-html="renderEmoji(dialogueItem.lastMessage.messageForShow)" class="font-12 text-cut" style="color: #8e92b1; width: 160px;margin-top:5px;height:16px"v-else></div>
							</div>
						</div>
						<!-- v-if="dialogueItem.type == 'GROUP'" -->
						<div class="flex" v-if="dialogueItem.type == 'GROUP'">
							<div class="unreadNum" v-if="dialogueItem.unreadCount > 0">
								{{ dialogueItem.unreadCount }}
							</div>
							<img alt="img" :src="dialogueItem.groupProfile.avatar" class="message_head" />
							<!-- {{dialogueItem.lastMessage}} -->
							<div v-if="!dialogueItem.lastMessage.isRevoked">
								<div class="font-14">{{ dialogueItem.groupProfile.name }}</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px" v-if="dialogueItem.lastMessage.type == 'TIMImageElem'">
									[图片]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && dialogueItem.lastMessage.cloudCustomData && JSON.parse(dialogueItem.lastMessage.cloudCustomData).address">
									[位置消息]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px"
									v-else-if="dialogueItem.lastMessage.type == 'TIMCustomElem' && dialogueItem.lastMessage.messageForShow == '[自定义消息]'">
									[群系统消息]
								</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px;height:16px"  v-else v-html="renderEmoji(dialogueItem.lastMessage.messageForShow)"></div>
							</div>
							<div v-if="dialogueItem.lastMessage.isRevoked">
								<div class="font-14">{{ dialogueItem.groupProfile.name }}</div>
								<div class="font-12 text-cut" style="color: #8e92b1; width: 160px">
									[撤回了一条消息]
								</div>
							</div>
						</div>
						<!-- <img  alt="img" v-if="item.isConcern == 2" src="../../image/message/33.png"   class="message_icon" /> -->
					</div>
				</div>
				<!-- 消息列表end -->

				<!-- 消息内容 -->
				<div class="message_content" v-if="userChatType && isTabs && tabType != 3">
					<!-- 群管理员 -->
					<div class="messageGroup" v-if="groupUserVip">
						<div class="flex groupHeader">
							<div @click="groupUserVip = false;frendsDetail = true">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								群管理员
							</div>
							<div>

							</div>
						</div>
						<div class="groupLine"></div>
						<div class="userSeachVip" style="margin-bottom:0px;">
							<input @input="getqunguanli()" placeholder="搜索群管理员" v-model="nickname"
								style="width:100%;height:100%;border: none;padding-left:14px;box-sizing: border-box;"></input>
						</div>
						<div class="groupBoxName" v-for="item in managerArr">
							<div class="flex" style="align-items: center;" @click="checkOtherPagequn(item.userId)">
								<img alt="img" :src="item.avatar" class="message_icon" style="margin-top:4px;cursor: pointer;width:36px;height:36px;border-radius: 50%;" />
								<div style="margin-left:8px;max-width:150px;" class="text-cut">{{ item.nickname }}</div>
								<img alt="img" src="../../image/room/74.png" class="message_icon" v-if="item.identity == 1"
									style="margin-left:8px;cursor: pointer;width:33px;height:20px;" />
							</div>
							<el-tooltip class="item" effect="dark" content="取消管理员" placement="top">
								<img alt="img" src="../../static/user76.png" class="message_icon" style="margin-top:4px;cursor: pointer;width:14px;height:14px"
									@click="decalManage(item)" />
							</el-tooltip>
						</div>
						<div v-if="managerArr.length == 0" class="text-999" style="width:100%;text-center;font-size:12px;color:#999;margin-top:24px;text-align: center;">
							您还没有设置管理员~
						</div>
					</div>

					<!-- 群成员 -->
					<div class="messageGroup" v-if="groupShow3">
						<div class="flex groupHeader">
							<div @click="groupShow3 = false;frendsDetail = true">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								群成员
							</div>
							<div></div>
						</div>
						<div class="groupLine"></div>
						<div class="userSeachVip" style="margin-bottom:0px;">
							<input @input="userGroupListtow()" placeholder="搜索群成员" v-model="usernickname"
								style="width:100%;height:100%;border: none;padding-left:14px;box-sizing: border-box;"></input>
						</div>
						<div class="groupBoxName" v-for="item in userListGrouptow">
							<div class="flex cursor" style="align-items: center;" @click="checkOtherPagequn(item.userId)">
								<img alt="img" :src="item.avatar" class="message_icon" style="margin-top:4px;cursor: pointer;width:36px;height:36px;border-radius: 50%;" />
								<div style="margin-left:8px;max-width:100px;" class="text-cut">{{ item.nickname }}</div>
								<img alt="img" src="../../static/user77.png" class="message_icon" v-if="item.identity == 0"
									style="margin-left:8px;cursor: pointer;width:33px;height:20px;" />
								<img alt="img" src="../../image/room/74.png" class="message_icon" v-if="item.identity == 1"
									style="margin-left:8px;cursor: pointer;width:33px;height:20px;" />
							</div>
							<div class="flex align-center">
								<el-tooltip class="item" effect="dark" content="转让群主" placement="top">
									<img alt="img" src="../../static/user74.png" class="message_icon" v-if="pitchChatGroup.userIdentity == 0"
										style="margin-top:4px;cursor: pointer;width:14px;height:14px;margin-right:20px;" @click="zhuanrangqunzu(item)" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="设为管理员" placement="top">
									<img alt="img" src="../../static/user75.png" class="message_icon" v-show="pitchChatGroup.userIdentity == 0 && item.identity == 2"
										style="margin-top:4px;cursor: pointer;width:14px;height:14px;margin-right:20px;" @click="sheweiguanliyuan(item)" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="取消管理员" placement="top">
									<img alt="img" src="../../static/user75.png" class="message_icon" v-show="pitchChatGroup.userIdentity == 0 && item.identity == 1"
										style="margin-top:4px;cursor: pointer;width:14px;height:14px;margin-right:20px;" @click="decalManage(item)" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="踢出群聊" placement="top">
									<img alt="img" src="../../static/user61.png" class="message_icon" v-if="pitchChatGroup.userIdentity != 2"
										style="margin-top:4px;cursor: pointer;width:14px;height:14px" @click="removeruser(item)" />
								</el-tooltip>
							</div>
						</div>
					</div>

					<div class="messageGroup" v-if="failChat">
						<div class="flex groupHeader">
							<div @click="failChat = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天文件
							</div>
							<div>

							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupBoxName cursor" v-for="item in chatFileGroup" @click="downFile(item)">
							<div class="flex" style="align-items: center;">
								<img alt="img" src="../../static/user67.png" class="message_icon"
									style="margin-top:4px;cursor: pointer;width:36px;height:36px;border-radius: 50%;" />
								<div style="margin-left:8px">
									<div class="fifleName text-cut">{{ item.fileName }}</div>
									<div class="fifleCreate">{{ item.createTime }}</div>
								</div>
							</div>
							<div class="fileNameName text-cut">
								{{ item.fromNickname }}
							</div>
						</div>
						<div v-if="chatFileGroup.length == 0" style="width:100%;font-size:12px;text-align:center;color:#999;margin-top:24px">暂无内容</div>
					</div>


					<!-- 系统设置 -->
					<div class="messageGroup" v-if="groupDetail">
						<div class="flex groupHeader">
							<div @click="groupDetail = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天设置
							</div>
							<div>
							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupCent">
							<div>免打扰</div>
							<el-switch v-model="disturbUser" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangedisturb">
							</el-switch>
						</div>
						<div class="groupLineNew"></div>
						<div class="groupCent">
							<div>置顶聊天</div>
							<el-switch v-model="disturbZhiDing" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangeUser">
							</el-switch>
						</div>
						<div class="groupLine"></div>
						<div style="padding-bottom:14px">
							<div class="groupCent">
								<div>开启双向撤回</div>
								<el-switch v-model="shuangxiangchehui" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
									@change="pushSwitchChange">
								</el-switch>
							</div>
							<div class="otherArgeen">
								对方同意后，双方发起“双向撤回”可直接销毁双方所有聊天记录
							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" v-if="pitchChatUser.isFriends == 1" @click="guanzhuShow = true">
							<div>好友分组</div>
							<div class="flex" style="align-items: center;">
								<div style="font-size: 12px;height:16px;line-height:16px;margin-right:8px;">{{setnames || pitchChatUser.groupName}}</div>
								<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
							</div>
						</div>
						<div class="groupCent" @click="getChatFileRecordGrouptow(pitchChatUser.id,myUser.id)">
							<div>聊天文件</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupCent" @click="clearTheChatShowUser = true">
							<div>清空聊天记录</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" @click="complainData.content='';complainArr=[];complaintShow = true">
							<div>投诉</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
					</div>

					<div class="messageGroup" v-if="frendsDetail">
						<div class="flex groupHeader">
							<div @click="frendsDetail = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天设置
							</div>
							<div></div>
						</div>
						<div class="groupLine"></div>
						<div class="message_flex flex">
							<div class="flex" style="padding:16px;box-sizing: border-box">
								<img alt="img" :src="pitchChatGroup.groupAvatar" class="message_icon" style="width:44px;height:44px;border-radius: 50%;" />
								<div style="margin-left:8px">
									<div class="flex" style="align-items: center;">
										<div v-if="changeName">{{ pitchChatGroup.groupName }}</div>
										<input v-else v-model="qunName"></input>
										<img alt="img" v-if="pitchChatGroup.userIdentity == 0" @click='changeGroupName(pitchChatGroup)' src="../../static/user62.png"
											class="message_icon" style="width:16px;height:16px;border-radius: 50%;margin-left:8px;cursor: pointer;" />
									</div>
									<div class="groupIds" style="margin-top:4px">ID：{{ pitchChatGroup.groupId }}</div>
								</div>
							</div>
							<div class="shareGroup flex" @click='gruopShare = true, getShareGroups()'>
								<img alt="img" src="../../static/user63.png" class="message_icon" style="width:16px;height:16px;margin-left:8px;margin-top:9px" />
								<div class="groupIds" style="margin-left:4px;margin-top:9px">分享</div>
							</div>
						</div>
						<div class="groupLine"></div>
						<div>
							<div class="message_flex cursor" style="padding-right:0;padding-bottom:0px" @click="groupShow3 = true;frendsDetail = false">
								<div class="groupFlex flex" style="padding-bottom:0px">
									<div>群成员</div>
									<div class="flex" style="align-items: center;">
										<div class="userPeople" style="margin-right:4px">{{ userListGroup.length }}人</div>
										<img alt="img" src="../../static/user64.png" class="message_icon" style="width:12px;height:12px;" />
									</div>
								</div>
							</div>
							<div class="userInfoImgs">
								<div v-for="(item, index) in userListGroup" @click="checkOtherPagequn(item.userId)" class="userClickFlex cursor"
									:style="{ marginRight: (index + 1) % 5 == 0 ? '0px' : '10px' }" style="margin-bottom:10px">
									<img alt="img" :src="item.avatar" class="message_icon" style="width:44px;height:44px;border-radius: 50%" />
									<div class="groupTextName">{{ item.nickname }}</div>
								</div>
								<div class="userClickFlex cursor" style="margin-bottom:10px;margin-right: 10px;" @click='tongListtow();groupShow4 = true'>
									<img alt="img" src="../../static/user72.png" class="message_icon" style="width:44px;height:44px;border-radius: 50%" />
									<div class="groupTextName">邀请</div>
								</div>
								<div class="userClickFlex cursor" style="margin-bottom:10px;" v-if="pitchChatGroup.userIdentity == 0 || pitchChatGroup.userIdentity == 1"
									@click="groupShow3 = true;frendsDetail = false">
									<img alt="img" src="../../static/user73.png" class="message_icon" style="width:44px;height:44px;border-radius: 50%" />
									<div class="groupTextName">移除</div>
								</div>
							</div>
						</div>
						<div class="groupLine" v-if="pitchChatGroup.userIdentity == 0"></div>
						<div class="flex" v-if="pitchChatGroup.userIdentity == 0"
							style="align-items: center;justify-content: space-between;padding:16px;box-sizing: border-box;cursor: pointer;" @click='groupUserArr()'>
							<div>群管理</div>
							<img alt="img" src="../../static/user64.png" class="message_icon" style="width:12px;height:12px;" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent">
							<div>消息免打扰</div>
							<el-switch v-model="disturbGroup" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangedisturbGroup">
							</el-switch>
						</div>
						<div class="groupLineNew"></div>
						<div class="groupCent">
							<div>置顶聊天</div>
							<el-switch v-model="disturbZhiDing" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangeGroup">
							</el-switch>
						</div>
						<div>
							<div class="groupCent">
								<div>保存至通讯录</div>
								<el-switch v-model="pitchChatGroup.isAddressBook" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
									@change="intoAddressBookChange">
								</el-switch>
							</div>
						</div>
						
						<div class="groupLine"></div>
						<div class="groupCent flex" style="align-items:center;">
							<div>我在本群的昵称</div>
							<div style="margin-left:8px">
								<div class="flex" style="align-items: center;font-size:14px;">
									<div v-if="UserNichengstate">{{ pitchChatGroup.userNickName }}</div>
									<input maxlength="10" v-else v-model="CutuserName"></input>
									<img alt="img" @click='xiugmyqunnicheng' src="../../static/user62.png"
										class="message_icon" style="width:16px;height:16px;border-radius: 50%;margin-left:8px;cursor: pointer;" />
								</div>
							</div>
						</div>
						<div class="groupCent">
							<div>显示群成员昵称</div>
							<el-switch v-model="pitchChatGroup.showNicknameStatus" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="cutshowNicknameStatus">
							</el-switch>
						</div>
						<div class="groupLine"></div>

						<div class="groupLine"></div>
						<div class="groupCent" @click='getChatFileRecordGroup(2)'>
							<div>聊天文件</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupCent" @click="clearTheChatShowGroup = true">
							<div>清空聊天记录</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" @click="complainData.content='';complainArr=[];complaintShow = true">
							<div>投诉</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupLine" v-if="pitchChatGroup.userIdentity == 0"></div>
						<div class="groupCent" v-if="pitchChatGroup.userIdentity == 0" @click="jiesanqunliao" style="border-bottom: solid 1px #eee;">
							<div style="color:red">解散群聊</div>
						</div>
						<div class="groupCent" v-if="pitchChatGroup.userIdentity == 0" @click="groupShow3 = true;frendsDetail = false" style="border-bottom: solid 1px #eee;">
							<div style="color:red">转让群主</div>
						</div>
						<div class="groupCent" v-else @click="tuichuqunliao" style="border-top: solid 1px #eee;">
							<div style="color:red">退出群聊</div>
						</div>
					</div>


					<!-- 订单消息 -->
					<div class="messageType_box" v-if="pitchMessageType.name && userChatTypeChild == false && groupChatChild == false">
						<!-- 标题 -->
						<div class="messageTitle" v-if="messageList.length != 0">
							{{ pitchMessageType.name }}
						</div>
						<div style="
                width: 648px;
                height: 0px;
                opacity: 0.11;
                border: 1px solid #ffffff;
              "></div>
						<!-- 消息内容 -->
						<img alt="img" src="../../image/message/5.png" v-if="messageList.length == 0" class="vacancy" />
						<div class="allMsg_box">
							<div style="width: 100%" v-for="(item, key0) in messageList" :key="key0">
								<!-- 时间 -->
								<div class="flex-content font-12" style="margin: 18px 0 13px 0;color: #999;">
									{{ item.createTime }} {{  item.type  }}
								</div>
								<!-- 内容 -->
								<div class="flex">
									<img alt="img" :src="pitchMessageType.imgUrl" v-if="item.type != 5" style="margin: 0 15px 0 24px; width: 42px; height: 42px" />
									<img alt="img" src="../../image/message/35.png" v-else style="margin: 0 15px 0 24px; width: 42px; height: 42px" />
									<!-- 派单消息 -->
									<div class="content_box" v-if="item.type == 4">
										<div class="font-14">类目：{{ item.labelName }}</div>
										<div class="flex-spacebetween">
											<div class="font-12 send_style">
												<p>
													等级要求：{{ item.segment ? item.segment : "不限" }}
												</p>
												<p>
													性别：{{
                            item.sex == 1 ? "女" : item.sex == 2 ? "男" : "保密"
                          }}
												</p>
												<p>
													派单人：{{ item.disName ? item.disName : "暂无" }}
												</p>
												<p>备注：{{ item.remark ? item.remark : "暂无" }}</p>
											</div>
											<div style="position: relative; width: 50px" v-if="item.status == 0">
												<div class="send_status">派单中</div>
												<div class="roomSkip" @click.stop="skipOrder(item)">
													立即跳单
												</div>
											</div>
											<div style="position: relative; width: 50px" v-else>
												<div class="send_status">已结束</div>
											</div>
										</div>
									</div>
									<div class="flex content_box" v-else-if="item.type == 5" style="align-items: center;background-color: rgb(245,245,245);color: #FFA700;font-size: 14px;">
										<img style="width: 32px;height: 32px;border-radius: 4px;" :src="item.targetUserAvatar" alt="">
										<span style="margin: 0 8px;">{{ item.targetUserNickName }}</span>
										<span style="color: #212121;">{{ item.title }}</span>
										<span style="margin: 0 8px;">了我</span>
										<span v-if="item.title == '评论'" style="color: #212121;">的动态</span>
										<span v-if="item.title != '评论'" style="color: #4177DD;font-size: 12px;margin-left: auto;cursor: pointer;" @click="checkOtherPagequn(item.beOrderId)">查看主页</span>
									</div>
									<div class="content_box" v-else style="position: relative">
										<!-- @click.stop='getMessagedetail(item)'平台公告打开详情弹窗方法 。列表内容都显示了， 详情弹窗暂不需要 -->
										<div>
											<div class="font-14">{{ item.title }}</div>
											<div v-html="item.content" class="font-12" style="color: #8e92b1; margin-top: 8px"></div>
											<div v-if="item.type == 1">
												<img alt="img" :src="item.picture" v-if="item.picture" class="img_style" />
											</div>
										</div>
										<div v-if="item.groupStatus == '1'" style="position: relative;left: 450px;width:60px;color: #666666;font-size: 12px;">
											已同意
										</div>
										<div v-if="item.groupStatus == '2'" style="position: relative;left: 450px;width:60px;color: #666666;font-size: 12px;">
											已拒绝
										</div>
										<div class="flex" v-if="item.groupStatus == '0' && item.type == 2">
											<div class="roomSkip"
												style="position: relative; left: 350px;background:rgba(255, 178, 178, 0.50);color:rgba(245, 47, 47, 1);width:56px;margin-top:14px"
												@click.stop="refunOrder(item)">
												拒绝
											</div>
											<div class="roomSkip" style="position: relative; left: 370px;background:rgba(7, 193, 96, 1);color:#fff;width:56px;margin-top:14px"
												@click.stop="addreesBtn(item)">
												同意
											</div>
										</div>
										<!-- 待续约提示 -->
										<div v-if="item.surtype == '0'" class="roomSkip" style="position: relative; left: 370px" @click.stop="renewalShow = true;msgId = item.id;">
											去续约
										</div>
										<div v-if="item.surtype == '1'" style="position: relative; left: 370px; font-size: 12px">
											已续约
										</div>
										<div v-if="item.surtype == '2'" style="position: relative; left: 370px; font-size: 12px">
											已拒绝续约
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 消息内容end -->
					</div>


					<!-- 用户单聊 -->
					<div class="messageType_box 3333" v-if="userChatTypeChild" @click="groupDetail = false">
						<div class="userChat_top flex-spacebetween">
							<div>
								<div class="flex flex-aling cursor">
									<span @click="checkOtherPage(pitchChatUser)" 
										class="font-18 pitchChatUserName cursor" 
										:class="{'vipred':pitchChatUser.vipFlag==1}">{{  pitchChatUser.nickname  }}</span>		
									<img src="../../static/vip.png"  v-if='pitchChatUser.vipFlag==1'
									   style="width: auto;height:14px;margin-left: 3px;" />
									<img alt="img" :src="pitchChatUser.wealthLevelLogo" v-if='pitchChatUser.wealthLevelLogo' style="width: 28px;height:28px;margin-left:8px" />
									<div class="userChat_age flex-content" :style="
                    pitchChatUser.sex == 1
                      ? 'background: #ff9280;color:#fff'
                      : 'background: #85BCFE;color:#fff'
                  ">
										<img alt="img" src="../../static/134.png" style="margin-right: 4px" v-if="pitchChatUser.sex == 1" />
										<img alt="img" src="../../image/message/25.png" style="margin-right: 4px;color:#fff" v-else />
										{{ pitchChatUser.age }}
									</div>
								</div>
								<div class="flex-aling" style="margin-top: 8px">
									<div class="userChat_topIcon">
										<span v-if="pitchChatUser.onlineStatus == 0" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: red;
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										<span v-if="pitchChatUser.onlineStatus == 1" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										<span v-if="pitchChatUser.onlineStatus == 2" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										{{
                      pitchChatUser.onlineStatus == 0
                        ? "不在线"
                        : pitchChatUser.onlineStatus == 1
                          ? "在线"
                          : pitchChatUser.onlineStatus == 2
                            ? "房间中"
                            : ""
                    }}
									</div>
									<!-- <div class="font-14">ID {{ pitchChatUser.userNo }}</div> -->
								</div>
							</div>
							<div class="flex">
								<div class="userChat_attentionIcon flex-content" style="background: #ccc" v-if="pitchChatUser.isFollow == 1" @click="stopFollowUser()">
									已关注
								</div>
								<div class="userChat_attentionIcon flex-content" v-else @click="followUser()">
									关注
								</div>
								<img alt="img" src="../../image/message/13.png" class="userChat_img" @click.stop="blockShow = true" />
								<img alt="img" src="../../image/message/14.png" class="userChat_img" @click.stop="reportShow = true" />
								<img alt="img" src="../../static/user53.png" class="userChat_img" @click.stop="groupDetail = !groupDetail" />
							</div>
						</div>
						<div class="manitoSkill flex-aling" v-if="pitchChatUser && pitchChatUser.userSkillVos.length != 0">
							<img alt="img" src="../../image/message/18.png" @click="skillCut(1)" />
							<img alt="img" :src="manitoSkillData.skillLogo" style="width: 63px; height: 63px; margin: 0 11px" />
							<div style="width: 170px">
								<div class="font-16" style="color: #333">
									{{ manitoSkillData.skillName }}
								</div>
								<div class="font-12" style="margin-top: 19px; color: #333">
									{{ manitoSkillData.skillLevel }}
								</div>
							</div>
							<div style="position: relative; width: 170px">
								<span style="
                    color: rgba(33, 33, 33, 1);
                    font-size: 18px;
                    font-weight: 600;
                  ">{{ manitoSkillData.price }}</span>
								<span style="font-size: 10px; color: #333">元宝/局</span>
							</div>
							<div class="flex-aling">
								<div style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  " @click="orderAddition(1)">
									-
								</div>
								<span style="
                    display: inline-block;
                    width: 30px;
                    text-align: center;
                    color: #333;
                  ">
									{{ orderNum }}
								</span>
								<div style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  " @click="orderAddition(2)">
									+
								</div>
							</div>
							<div class="order_btn flex-content" style="margin-left: 23px" @click="placeSkipChange()">
								我要下单
							</div>
							<img alt="img" src="../../image/message/19.png" style="margin-left: 15px" @click="skillCut(2)" />
						</div>
						<div class="userChat_content" id="myElement" :style="{ height: pitchChatUser.userSkillVos.length != 0 ? '380px' : ''}">
							<div class="chat_box" v-for="(item, index) in chatList" :key="index"
								v-if="!item.payload.data || (!JSON.parse(item.payload.data).businessID || JSON.parse(item.payload.data).businessID != 'C2C_revocationMsgList')">
								<div
									v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 21 || item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31">
									<!-- 双向撤回消息 -->
									<div class="jinquanxiaoxi" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 21">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已发起“双向撤回功能”,等待同意
									</div>
									<!-- 双向撤回消息 -->
									<!-- 双向撤回消息 -->
									<div class="jinquanxiaoxi"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31 && JSON.parse(JSON.parse(item.payload.data).content).status == 2">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已拒绝开启双向撤回功能
									</div>

									<div class="jinquanxiaoxi"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31 && JSON.parse(JSON.parse(item.payload.data).content).status == 1">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已同意开启双向撤回功能
									</div>
								</div>
								<div class="my flex" v-else-if="item.flow == 'out'">
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMVideoFileElem'">
										<div v-if="item.isRevoked == false && item.type == 'TIMVideoFileElem'" class="my_content">
											<video width="320" height="240" controls>
												<source :src="item.payload.remoteVideoUrl" type="video/webm" />
											</video>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									
									<div class="my_content" v-if="item.isRevoked == false && item.type == 'TIMSoundElem'">
										<audio :src='item.payload.remoteAudioUrl' controls>
										</audio>
									</div>
									<!-- <template> -->
									<!-- <el-popconfirm
      title="确认要删除吗"
      @confirm="handleConfirm(item)"
      @cancel="handleCancel"
    >
		<div slot='reference'  @mousedown="chehuiBtn(item)" @mouseup="endLongPress(item)" @mouseleave="endLongPress(item)" class="my_content" v-html="renderEmoji(item.payload.text)" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && !isValidURL(item.payload.text)"></div>
    </el-popconfirm> -->

		<!-- <el-popconfirm
      title="确认要撤回消息吗?"
			ref="popconfirm"
      :visible="popconfirmVisible"
      @confirm="handleConfirm(item)"
      @cancel="handleCancel"
			placement="right-end"
    >
      <template #reference>
				
        <div ref="popconfirmTrigger">
				</div>
				
      </template>
    </el-popconfirm>


	</template> -->
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked">
										<div  @mousedown="chehuiBtn(item,index,$event)" @mouseup="endLongPress" @mouseleave="endLongPress"  class="my_content" v-html="renderEmoji(item.payload.text)" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && !isValidURL(item.payload.text)"></div>

										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
											</template>
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && isValidURL(item.payload.text)">
										<div class="my_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && isValidURL(item.payload.text)"></div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
											</template>
									</div>
									<div  class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message'">
										<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
											v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message'">
											<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
											<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img v-if='isShowChehuiBtn(item)'   slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
								</div>
								<div class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message'">
									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message'">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>
									<template>
										<el-popconfirm
											title="是否确认撤回该消息"
											@confirm="handleConfirm(item)"
										>
											<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
										</el-popconfirm>
									</template>
								</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMFileElem' && item.payload.downloadFlag == '2'" >
										<div class="my_content flex cursor" style="align-items: center;" v-if="item.isRevoked == false && item.type == 'TIMFileElem' && item.payload.downloadFlag == '2'" @click="xiazai(item.payload.fileUrl)">
											<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
											<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									
									<div class="my_content" v-if="item.isRevoked == false && item.type == 'TIMRelayElem'">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="my_content" v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}

										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>

									<div class="flex" style="align-items: center;display:flex" v-if="item.isRevoked == false && item.type == 'TIMImageElem'">
										<div class="my_content" v-if="item.isRevoked == false && item.type == 'TIMImageElem'">
											<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]"></el-image>
											<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									<div style="position: relative;" v-if="item.isRevoked == false">
										<img alt="img" :src="item.avatar" class="userHead" />
										<img src="../../static/vip.png"  v-if='myUser.vipFlag==1'
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
								</div>

								<div class="other flex" v-else-if="item.flow == 'in'">
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
									<div style="position: relative;" v-if="item.isRevoked == false">
										<img @click="checkOtherPagequn(item.from)" alt="img" :src="item.avatar" class="userHead cursor" />
										<img src="../../static/vip.png"  v-if='pitchChatUser.vipFlag==1'
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
									<div class="other_content" v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>
									<div v-if="item.isRevoked == false && item.type == 'TIMVideoFileElem'" class="other_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="other_content" v-if="item.isRevoked == false && item.type == 'TIMSoundElem'">
										<audio :src='item.payload.remoteAudioUrl' controls>
										</audio>
									</div>
									<div class="other_content" v-html="renderEmoji(item.payload.text)" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && !isValidURL(item.payload.text)"></div>
									<div class="other_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.isRevoked == false && item.type == 'TIMTextElem' && isValidURL(item.payload.text)"></div>
									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message'">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>
									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.isRevoked == false && item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message'">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.isRevoked == false && item.type == 'TIMFileElem' && item.payload.downloadFlag == '2'" @click="xiazai(item.payload.fileUrl)">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									<div class="other_content" v-if="item.isRevoked == false && item.type == 'TIMRelayElem'">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="other_content" v-if="item.isRevoked == false && item.type == 'TIMImageElem'">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
								</div>
							</div>
						</div>
						<!-- 输入内容 -->
						<div class="userChat_inputBox">
							<div class="iconFlex">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>

							<div class="flex-aling" style="padding: 0 17px;background:#f5f5f5">
								<el-popover placement="top-start" width="400" trigger="hover">
									<div>
										<div>
											<div v-if="imgType == 1" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<img style="height:30px;width:30px;margin-right:5px;margin-bottom:5px;" v-for="(item, index) in emojiList" :key="index"
													:src="require(`../../assets/emoji/${item}@2x.png`)" @click="insertEmoji(item)" class="emoji-item iconImg" />
											</div>
											<div v-if="imgType == 2" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<div style="margin-bottom: 5px;">
													<span v-if="!imgEdit" style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=true">编辑表情</span>
													<span v-else style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=false">完成编辑</span>
												</div>
												<div class="flex flex-wrap">
													<el-upload style="display: inline-block;" ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
														:show-file-list="false" :with-credentials="true" :on-success="handleAvatarSuccessImg" accept=".png,.jpg,.jpeg"
														id="testPasteInput">
														<img src="../../static/add1.png" style="height:60px;width:60px;margin-right:5px;margin-bottom:5px;" alt="" />
													</el-upload>
													<div v-for="(item, index) in RmoteList" :key="index" style="position: relative; display: inline-block;height:60px;width:60px;margin-right:5px;margin-bottom:5px;cursor: pointer;">
														<img style="height:60px;width:60px;" 
															:src="item.url" @click="sendimgDom(item.url,1)" :id="'bq'+index" class="emoji-item" />
														<span @click.stop="deleteMyImg(item)" v-if="imgEdit" style="background-color: rgba(0,0,0,0.5);position: absolute;top:0;right:0;padding:2px 5px;color:#FFF;">
															X
														</span>	
													</div>
												</div>
											</div>
										</div>
										<div style="background-color: #c7c7c7;padding-left:10px;">
											<img @click="imgType = 1" style="width: 30px;height:30px;" :style="{'background-color':imgType == 1?'#FFF':'#c7c7c7'}"
												src="../../static/bq.png" alt="" />
											<img @click="imgType = 2" style="width: 30px;height:30px;margin-left: 10px;" :style="{'background-color':imgType == 2?'#FFF':'#c7c7c7'}"
												src="../../static/sc.png" alt="" />
										</div>
									</div>
									<img slot="reference" alt="img" src="../../static/xl.png" @click="showEmoji = true" class="iconImg" style="margin-right:20px" />
								</el-popover>
								<el-tooltip class="item" effect="dark" content="截图" placement="top">
								      <img alt="img" src="../../static/user46.png" @click="jietu" class="iconImg" style="margin-right:20px" />
								</el-tooltip>
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleAvatarSuccess" accept=".png,.jpg,.jpeg,.mp4" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传图片或视频" placement="top">
									    <img alt="img" src="../../static/user45.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleC2CAct" accept="" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传文件" placement="top">
									    <img alt="img" src="../../static/user47.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								<el-tooltip class="item" effect="dark" content="转发名片" placement="top">
								   <img alt="img" src="../../static/user48.png" class="iconImg" @click="shareBtnUser" style="margin-right:20px" />
								</el-tooltip>
								<!-- 打电话或视频 -->
								<el-tooltip class="item" effect="dark" content="拨打语音电话" placement="top">
								   <img alt="img" src="../../static/user50.png" class="iconImg" @click="callTIM(1)" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="拨打视频电话" placement="top">
								   <img alt="img" src="../../static/user51.png" class="iconImg" @click="callTIM(2)" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="私信设置" placement="top">
								   <img alt="img" src="../../static/user52.png" class="iconImg" @click="isSet = true" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="双向撤回消息" placement="top">
								  <img alt="img" src="../../static/user49.png" class="iconImg" @click="remindShowBtn" style="margin-right:20px" />
								</el-tooltip>
							</div>
							<el-input type="textarea" :rows="4" placeholder="请输入..." resize="none" v-model="chatText" @keydown.enter.native.prevent="sendTextChange($event)">
							</el-input>
							<div class="sendBtn flex-content" @click.stop="sendTextChange($event)">
								发送
							</div>
						</div>
					</div>
					<!-- 群组聊天 -->
					<div class="messageType_box 111" v-show="groupChatChild && pitchChatGroup" @click="frendsDetail = false;groupUserVip = false;groupShow3 = false" style="color:#3333">
						<div class="userChat_top flex-spacebetween">
							<div>
								<div class="flex">
									<span class="font-18 pitchChatUserName">{{pitchChatGroup.groupName}}</span>
								</div>
								<div class="flex-aling" style="margin-top: 8px">
									<div class="font-14">ID {{ pitchChatGroup.groupId }}</div>
								</div>
							</div>
							<div class="flex">
								<img alt="img" src="../../static/user53.png" class="userChat_img" @click.stop="frendsDetail = !frendsDetail, userGroupList(),userGroupListtow()" />
							</div>
						</div>
						<div class="userChat_content" id="myElementgroup" @clikc="groupUserVip = false">
							<div class="chat_box" v-for="(item, index) in chatListGroup" :key="index">
								<div class="my flex"
									v-if="item.flow == 'out' && (!item.payload.data || JSON.parse(item.payload.data).businessID != 'invite_group' && JSON.parse(item.payload.data).businessID != 'group_create' && JSON.parse(item.payload.data).businessID != 'clear_group_msg' && JSON.parse(item.payload.data).businessID != 'kick_group')">
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMVideoFileElem'">
										<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="my_content">
											<video width="320" height="240" controls>
												<source :src="item.payload.remoteVideoUrl" type="video/webm" />
											</video>
										</div>
									</div>
									<div class="flex" style="align-items: center;display:flex">
										<div class="my_content" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked"></div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked">
										<div class="my_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2' && !item.isRevoked" >
										<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2' && !item.isRevoked" @click="xiazai(item.payload.fileUrl)">
											<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
											<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									
									
									<div class="my_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
											v-if="item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message' && !item.isRevoked">
											<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
											<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
								<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>
									<template>
										<el-popconfirm
											title="是否确认撤回该消息"
											@confirm="handleConfirm(item)"
										>
											<img  v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
										</el-popconfirm>
									</template>
								</div>
									<div style="width:100%;" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType && !item.isRevoked">
										<!-- 转让群主 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 已成为新的群主
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && !item.payload.memberList">
											"<span style="color:#4981fa">{{ item.nick }}</span>"
											修改群名称为"{{ item.payload.newGroupProfile.groupName }}"
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 3">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 被踢出群组
										</div>
										<!-- 退出群聊 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 2">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 退出群组
										</div>
										<!-- 设为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 4 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被设置为管理员
										</div>
										<!-- 设取消为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 5 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被取消管理员
										</div>
									</div>

									<div class="my_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType  && !item.isRevoked">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}

										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>
									<div class="flex" style="align-items: center;display:flex" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<div class="my_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
											<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
											</el-image>
											<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
										</div>
										<template>
											<el-popconfirm
												title="是否确认撤回该消息"
												@confirm="handleConfirm(item)"
											>
												<img v-if='isShowChehuiBtn(item)'  slot="reference" alt="img" class='cursor' style='width:12px;height:12px;' src="../../image/message/chehui.png" 	 />
											</el-popconfirm>
										</template>
									</div>
									<div style="position: relative;" v-if="!item.isRevoked">
										<img alt="img" :src="item.avatar" v-if="!item.payload.operationType" class="userHead" />
										<img src="../../static/vip.png"  v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).vipFlag && JSON.parse(item.cloudCustomData).vipFlag == 1"
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
								</div>
								<div style="width:100%;"
									v-else-if="item.flow == 'out' && item.payload.data && JSON.parse(item.payload.data) && (JSON.parse(item.payload.data).businessID == 'invite_group' || JSON.parse(item.payload.data).businessID == 'group_create' || JSON.parse(item.payload.data).businessID == 'kick_group')">
									<!-- 邀请进群消息 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'invite_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>邀请<span>{{ JSON.parse(item.payload.data).data.inviteUserNames }}</span>进入群聊
									</div>
									<!-- 移出群 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'kick_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>将<span>{{ JSON.parse(item.payload.data).data.kickUserNames }}</span>移出群聊
									</div>
									<!-- 创建群聊 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && (JSON.parse(item.payload.data).content == '创建了群聊' || JSON.parse(item.payload.data).content == '创建了群聊，大家一起来聊天吧') && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"创建了群聊
									</div>
									<div class="jinquanxiaoxi" v-else-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"进入群聊
									</div>
								</div>
								<!-- !JSON.parse(item.payload.data).businessID -->
								<div class="other flex"
									v-if="item.flow == 'in' && (!item.payload.data || JSON.parse(item.payload.data).businessID != 'invite_group' && JSON.parse(item.payload.data).businessID != 'group_create' && JSON.parse(item.payload.data).businessID != 'clear_group_msg' && JSON.parse(item.payload.data).businessID != 'kick_group')">
									<div style="position: relative;" v-if="!item.isRevoked">
										<div v-if="pitchChatGroup.showNicknameStatus == 1 && !item.payload.operationType" class="nime-view text-cut">{{item.nameCard || item.nick}}</div>
										<img alt="img" :src="item.avatar" @click="checkOtherPagequn(item.from)" v-if="!item.payload.operationType" class="userHead cursor" />
										<img src="../../static/vip.png"  v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).vipFlag && JSON.parse(item.cloudCustomData).vipFlag == 1"
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
									<div v-if="item.cloudCustomData == '' && !item.isRevoked">
										<div class="other_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType">
											{{
                        JSON.parse(item.payload.data).actionType == 1
                          ? "发起通话"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 4
                          ? "拒绝通话"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 5
                          ? "未应答"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 3
                          ? "已接听"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 2
                          ? "取消通话"
                          : ""
                      }}
										</div>
									</div>

									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>

									<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="other_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="other_content" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="other_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="other_content" v-if="item.type == 'TIMSoundElem' && !item.isRevoked">
										<audio :src='item.payload.remoteAudioUrl' controls>
										</audio>
									</div>

									<div style="width:100%;" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType && !item.isRevoked">
										<!-- 转让群主 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 已成为新的群主
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && !item.payload.memberList">
											"<span style="color:#4981fa">{{ item.nick }}</span>"
											修改群名称为"{{ item.payload.newGroupProfile.groupName }}"
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 3">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 被踢出群组
										</div>
										<!-- 退出群聊 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 2">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 退出群组
										</div>
										<!-- 设为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 4 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被设置为管理员
										</div>
										<!-- 设取消为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 5 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被取消管理员
										</div>
									</div>

									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).businessID == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2' && !item.isRevoked" @click="xiazai(item.payload.fileUrl)">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									<div class="other_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="other_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
								</div>

								<!-- 系统消息 -->
								<div style="width:100%;"
									v-else-if="item.flow == 'in' && item.payload.data && JSON.parse(item.payload.data) && (JSON.parse(item.payload.data).businessID == 'invite_group' || JSON.parse(item.payload.data).businessID == 'group_create' || JSON.parse(item.payload.data).businessID == 'kick_group') && !item.isRevoked">
									<!-- 邀请进群消息 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'invite_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>邀请<span>{{ JSON.parse(item.payload.data).data.inviteUserNames }}</span>进入群聊
									</div>
									<!-- 移出群 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'kick_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>将<span>{{ JSON.parse(item.payload.data).data.kickUserNames }}</span>移出群聊
									</div>
									<!-- 创建群聊 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && (JSON.parse(item.payload.data).content == '创建了群聊' || JSON.parse(item.payload.data).content == '创建了群聊，大家一起来聊天吧') && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"创建了群聊
									</div>
									<div class="jinquanxiaoxi" v-else-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"进入群聊
									</div>
								</div>
							</div>
						</div>
						<!-- 输入内容 -->
						<div class="userChat_inputBox">
							<div class="iconFlex">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>

							<div class="flex-aling" style="padding: 0 17px;background:#f5f5f5">
								<el-popover placement="top-start" width="400" trigger="hover">
									<div>
										<div>
											<div v-if="imgType == 1" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<img style="height:30px;width:30px;margin-right:5px;margin-bottom:5px;" v-for="(item, index) in emojiList" :key="index"
													:src="require(`../../assets/emoji/${item}@2x.png`)" @click="insertEmoji(item)" class="emoji-item iconImg" />
											</div>
											<div v-if="imgType == 2" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<div style="margin-bottom: 5px;">
													<span v-if="!imgEdit" style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=true">编辑表情</span>
													<span v-else style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=false">完成编辑</span>
												</div>
												<div class="flex flex-wrap">
													<el-upload style="display: inline-block;" ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
														:show-file-list="false" :with-credentials="true" :on-success="handleAvatarSuccessImg" accept=".png,.jpg,.jpeg"
														id="testPasteInput">
														<img src="../../static/add1.png" style="height:60px;width:60px;margin-right:5px;margin-bottom:5px;" alt="" />
													</el-upload>
													<div v-for="(item, index) in RmoteList" :key="index" style="position: relative; display: inline-block;height:60px;width:60px;margin-right:5px;margin-bottom:5px;cursor: pointer;">
														<img style="height:60px;width:60px;" 
															:src="item.url" @click="sendimgDom(item.url,2)" :id="'bq'+index" class="emoji-item" />
														<span @click.stop="deleteMyImg(item)" v-if="imgEdit" style="background-color: rgba(0,0,0,0.5);position: absolute;top:0;right:0;padding:2px 5px;color:#FFF;">
															X
														</span>	
													</div>
												</div>
											</div>
										</div>
										<div style="background-color: #c7c7c7;padding-left:10px;">
											<img @click="imgType = 1" style="width: 30px;height:30px;" :style="{'background-color':imgType == 1?'#FFF':'#c7c7c7'}"
												src="../../static/bq.png" alt="" />
											<img @click="imgType = 2" style="width: 30px;height:30px;margin-left: 10px;" :style="{'background-color':imgType == 2?'#FFF':'#c7c7c7'}"
												src="../../static/sc.png" alt="" />
										</div>
									</div>
									<img slot="reference" alt="img" src="../../static/xl.png" @click="showEmoji = true" class="iconImg" style="margin-right:20px" />
								</el-popover>
								<el-tooltip class="item" effect="dark" content="截图" placement="top">
								   <img alt="img" src="../../static/user46.png" @click="jietutow" class="iconImg" style="margin-right:20px" />
								</el-tooltip>
								
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleGrouppSuccess" accept=".png,.jpg,.jpeg,.mp4" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传图片或视频" placement="top">
									   <img alt="img" src="../../static/user45.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleGrouppAct" accept="" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="发送文件" placement="top">
									   <img alt="img" src="../../static/user47.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								
								<el-tooltip class="item" effect="dark" content="转发名片" placement="top">
								   <img alt="img" src="../../static/user48.png" class="iconImg" @click="shareBtnUserGrop" style="margin-right:20px" />
								</el-tooltip>

								<!-- 
                <img
                  src="../../static/user47.png"
                  
                  class="iconImg"
                  @click="clearTheChatShow = true"
                  style="margin-right:20px"
                /> -->


								<!-- <img
                  src="../../static/user48.png"
                  
                  class="iconImg"
                  @click="shareBtnUser"
                  style="margin-right:20px"
                /> -->

								<!-- <img
                  src="../../static/user50.png"
                  
                  class="iconImg"
                  @click="clearTheChatShow = true"
                  style="margin-right:20px"
                />
                <img
                  src="../../static/user51.png"
                  
                  class="iconImg"
                  @click="clearTheChatShow = true"
                  style="margin-right:20px"
                /> -->
								<!-- <img
                  src="../../static/user52.png"
                  
                  class="iconImg"
                  @click="isSet = true"
                  style="margin-right:20px"
                /> -->
								<!-- <img
                  src="../../static/user49.png"
                  
                  class="iconImg"
                  @click="remindShowBtn"
                  style="margin-right:20px"
                /> -->

								<!-- <el-upload
                  ref="upload"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :with-credentials="true"
                  :on-success="handleAvatarSuccess"
                  accept=".png,.jpg,.jpeg"
                  :on-change="handleChange"
                  id="testPasteInput"
                >
                  <img
                    src="../../image/message/15.png"
                    
                    class="iconImg"
                    style="margin-top:5px;margin-right:5px"
                  />
                </el-upload>
                <img
                  v-if="0"
                  src="../../image/message/16.png"
                  
                  class="iconImg"
                  @click="clearTheChatShow = true"
                /> -->

								<!-- <el-upload
                  ref="upload"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :with-credentials="true"
                  :on-success="handleAvatarSuccess"
                  accept=".MP3"
                  :on-change="handleChange"
                  id="testPasteInput"
                >
                <img
                src="../../image/message/31.png"
                
                class="iconImg"
                @click="sendShi"
              />
                </el-upload> -->

								<!-- <el-upload
                  v-if="isSendShiping"
                  class="avatar-uploader"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :limit="1"
                  accept=".mp4,"
                  :on-change="handleChange"
                >
                  <img
                    style="margin-right: 5px; width: 22px; height: 22px"
                    src="../../image/message/31.png"
                    
                  />
                </el-upload>
                <img
                  v-else
                  @click="$message('今日发送视频次数已用完')"
                  style="margin-right: 5px; width: 24px; height: 24px"
                  src="../../image/message/31.png"
                  
                />
                <img
                  src="../../image/message/30.png"
                  
                  class="iconImg"
                  @click="callTIM(1)"
                />
                <img
                  src="../../image/message/29.png"
                  
                  class="iconImg"
                  @click="callTIM(2)"
                />
                <img
                  v-if="0"
                  src="../../image/message/28.png"
                  
                  class="iconImg"
                  @click="
                    isSet = true;
                    getChargeSetting();
                  "
                />
                <img
                  v-if="0"
                  src="../../image/message/17.png"
                  
                  class="iconImg"
                  @click="clearTheChatShow = true"
                /> -->
				
								<el-tooltip class="item" effect="dark" content="撤回群聊天记录" placement="top">
								   <img v-if="pitchChatGroup.userIdentity == 0" src="../../static/user49.png" class="iconImg" @click="qunzhucheui" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="撤回群聊天记录" placement="top">
								   <img v-if="pitchChatGroup.userIdentity != 0" src="../../static/user49.png" class="iconImg" @click="qunyuanchehuidianji" style="margin-right:20px" />
								</el-tooltip>
							</div>
							<el-input type="textarea" :rows="4" placeholder="请输入..." resize="none" v-model="chatText" @keydown.enter.native.prevent="sendTextChangeGroup($event)">
							</el-input>
							<div class="sendBtn flex-content" @click.stop="sendTextChangeGroup($event)">
								发送
							</div>
						</div>
					</div>
				</div>



				<!-- 通讯录-->
				<div class="message_content" v-if="userChatType && tabType == 3 && isTabs">
					<!-- 群管理员 -->
					<div class="messageGroup" v-if="groupUserVip">
						<div class="flex groupHeader">
							<div @click="groupUserVip = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								群管理员
							</div>
							<div>

							</div>
						</div>
						<div class="groupLine"></div>
						<div class="userSeachVip" style="margin-bottom:0px;">
							<input @input="getqunguanli()" placeholder="搜索群管理员" v-model="nickname"
								style="width:100%;height:100%;border: none;padding-left:14px;box-sizing: border-box;"></input>
						</div>
						<template v-if="managerArr.length">
							<div class="groupBoxName" v-for="item in managerArr">
								<div class="flex" style="align-items: center;" @click="checkOtherPagequn(item.userId)">
									<img alt="img" :src="item.avatar" class="message_icon" style="margin-top:4px;cursor: pointer;width:36px;height:36px;border-radius: 50%;" />
									<div class="text-cut" style="margin-left:8px;max-width:150px;">{{ item.nickname }}</div>
									<img alt="img" src="../../image/room/74.png" class="message_icon" v-if="item.identity == 1"
										style="margin-left:8px;cursor: pointer;width:33px;height:20px;" />
								</div>
								<img alt="img" src="../../static/user61.png" class="message_icon" v-if="myUser.id==userGroupData.userId"
									style="margin-top:4px;cursor: pointer;width:12px;height:12px" @click="deleteManage(item)" />
							</div>
						</template>
					</div>

					<div class="messageGroup" v-if="failChat">
						<div class="flex groupHeader">
							<div @click="failChat = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天文件
							</div>
							<div>

							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupBoxName cursor" v-for="item in chatFileGroup" @click="downFile(item)">
							<div class="flex" style="align-items: center;">
								<img alt="img" src="../../static/user67.png" class="message_icon"
									style="margin-top:4px;cursor: pointer;width:36px;height:36px;border-radius: 50%;" />
								<div style="margin-left:8px">
									<div class="fifleName text-cut">{{ item.fileName }}</div>
									<div class="fifleCreate">{{ item.createTime }}</div>
								</div>
							</div>
							<div class="fileNameName text-cut">
								{{ item.fromNickname }}
							</div>
						</div>
						<div v-if="chatFileGroup.length == 0" style="width:100%;font-size:12px;text-align:center;color:#999;margin-top:24px">暂无内容</div>
					</div>


					<!-- 系统设置 -->
					<div class="messageGroup" v-if="groupDetail">
						<div class="flex groupHeader">
							<div @click="groupDetail = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天设置
							</div>
							<div>
							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupCent">
							<div>免打扰</div>
							<el-switch v-model="disturbUser" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangedisturb">
							</el-switch>
						</div>
						<div class="groupLineNew"></div>
						<div class="groupCent">
							<div>置顶聊天</div>
							<el-switch v-model="disturbZhiDing" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangeUser">
							</el-switch>
						</div>
						<div class="groupLine"></div>
						<div style="padding-bottom:14px">
							<div class="groupCent">
								<div>开启双向撤回</div>
								<el-switch v-model="shuangxiangchehui" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
									@change="pushSwitchChange">
								</el-switch>
							</div>
							<div class="otherArgeen">
								对方同意后，双方发起“双向撤回”可直接销毁双方所有聊天记录
							</div>
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" @click="getChatFileRecordGrouptow(pitchChatUser.id,myUser.id)">
							<div>聊天文件</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupCent" @click="clearTheChatShowUser = true">
							<div>清空聊天记录</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" @click="complainData.content='';complainArr=[];complaintShow = true">
							<div>投诉</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
					</div>

					<div class="messageGroup" v-if="frendsDetail">
						<div class="flex groupHeader">
							<div @click="frendsDetail = false">
								<img alt="img" src="../../static/to11.png" class="message_icon" style="margin-top:4px;cursor: pointer;" />
							</div>
							<div>
								聊天设置
							</div>
							<div></div>
						</div>
						<div class="groupLine"></div>
						<div class="message_flex flex">
							<div class="flex" style="padding:16px;box-sizing: border-box">
								<img alt="img" :src="pitchChatGroup.groupAvatar" class="message_icon" style="width:44px;height:44px;border-radius: 50%;" />
								<div style="margin-left:8px">
									<div class="flex" style="align-items: center;">
										<div v-if="changeName">{{ pitchChatGroup.userNickName }}</div>
										<input v-else v-model="qunName"></input>
										<img alt="img" v-if="pitchChatGroup.userIdentity == 0" @click='changeGroupName(pitchChatGroup)' src="../../static/user62.png"
											class="message_icon" style="width:16px;height:16px;border-radius: 50%;margin-left:8px;cursor: pointer;" />
									</div>
									<div class="groupIds" style="margin-top:4px">ID：{{ pitchChatGroup.groupId }}</div>
								</div>
							</div>
							<div class="shareGroup flex" @click='gruopShare = true, getShareGroups()'>
								<img alt="img" src="../../static/user63.png" class="message_icon" style="width:16px;height:16px;margin-left:8px;margin-top:9px" />
								<div class="groupIds" style="margin-left:4px;margin-top:9px">分享</div>
							</div>
						</div>
						<div class="groupLine"></div>
						<div>
							<div class="message_flex cursor" style="padding-right:0;" @click="groupShow3 = true;frendsDetail = false">
								<div class="groupFlex flex" style="padding-bottom:0px">
									<div>群成员</div>
									<div class="flex" style="align-items: center;">
										<div class="userPeople" style="margin-right:4px">{{ userListGroup.length }}人</div>
										<img alt="img" src="../../static/user64.png" class="message_icon" style="width:12px;height:12px;" />
									</div>
								</div>
							</div>
							<div class="userInfoImgs">
								<div v-for="(item, index) in userListGroup" :style="{ marginRight: (index + 1) % 5 == 0 ? '0px' : '10px' }" style="margin-bottom:10px">
									<img alt="img" :src="item.avatar" class="message_icon" style="width:44px;height:44px;border-radius: 50%" />
									<div class="groupTextName">{{ item.nickname }}</div>
								</div>
							</div>
						</div>
						<div class="groupLine"></div>
						<div class="flex" style="align-items: center;justify-content: space-between;padding:16px;box-sizing: border-box;cursor: pointer;" @click='groupUserArr()'>
							<div>群管理</div>
							<img alt="img" src="../../static/user64.png" class="message_icon" style="width:12px;height:12px;" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent">
							<div>消息免打扰</div>
							<el-switch v-model="disturbGroup" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangedisturbGroup">
							</el-switch>
						</div>
						<div class="groupLineNew"></div>
						<div class="groupCent">
							<div>置顶聊天</div>
							<el-switch v-model="disturbZhiDing" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="pushSwitchChangeGroup">
							</el-switch>
						</div>
						<div>
							<div class="groupCent">
								<div>保存至通讯录</div>
								<el-switch v-model="pitchChatGroup.isAddressBook" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
									@change="intoAddressBookChange">
								</el-switch>
							</div>
						</div>
						
						<div class="groupLine"></div>
						<div class="groupCent" @click="getChatFileRecordGrouptow(pitchChatUser.id,myUser.id)">
							<div>我在本群的昵称</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupCent">
							<div>显示群成员昵称</div>
							<el-switch v-model="pitchChatGroup.showNicknameStatus" active-color="#9167fb" inactive-color="#cccccc" :active-value="1" :inactive-value="0"
								@change="cutshowNicknameStatus">
							</el-switch>
						</div>
						<div class="groupLine"></div>

						<div class="groupLine"></div>
						<div class="groupCent"  @click='getChatFileRecordGroup(2)'>
							<div>聊天文件</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupCent" @click="clearTheChatShowGroup = true">
							<div>清空聊天记录</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
						<div class="groupLine"></div>
						<div class="groupCent" @click="complainData.content='';complainArr=[];complaintShow = true">
							<div>投诉</div>
							<img alt="img" src="../../static/user54.png" class="message_icon" style="width:14px;height:14px" />
						</div>
					</div>


					<!-- 订单消息 -->
					<div class="messageType_box" v-if="pitchMessageType.name && !userChatType && !groupType">
						<!-- 标题 -->
						<div class="messageTitle" v-if="messageList.length != 0">
							{{ pitchMessageType.name }}
						</div>
						<div style="
                width: 648px;
                height: 0px;
                opacity: 0.11;
                border: 1px solid #ffffff;
              "></div>
						<!-- 消息内容 -->
						<img alt="img" src="../../image/message/5.png" v-if="messageList.length == 0" class="vacancy" />
						<div class="allMsg_box">
							<div style="width: 100%" v-for="(item, key0) in messageList" :key="key0">
								<!-- 时间 -->
								<div class="flex-content font-12" style="margin: 18px 0 13px 0">
									{{ item.createTime }}
								</div>
								<!-- 内容 -->
								<div class="flex">
									<img alt="img" :src="pitchMessageType.imgUrl" style="margin: 0 15px 0 24px; width: 42px; height: 42px" />
									<!-- 派单消息 -->
									<div class="content_box" v-if="item.type == 4">
										<div class="font-14">类目：{{ item.labelName }}</div>
										<div class="flex-spacebetween">
											<div class="font-12 send_style">
												<p>
													等级要求：{{ item.segment ? item.segment : "不限" }}
												</p>
												<p>
													性别：{{
                            item.sex == 1 ? "女" : item.sex == 2 ? "男" : "保密"
                          }}
												</p>
												<p>
													派单人：{{ item.disName ? item.disName : "暂无" }}
												</p>
												<p>备注：{{ item.remark ? item.remark : "暂无" }}</p>
											</div>
											<div style="position: relative; width: 50px" v-if="item.status == 0">
												<div class="send_status">派单中</div>
												<div class="roomSkip" @click.stop="skipOrder(item)">
													立即跳单
												</div>
											</div>
											<div style="position: relative; width: 50px" v-else>
												<div class="send_status">已结束</div>
											</div>
										</div>
									</div>
									<div class="content_box" v-else style="position: relative">
										<div class="font-14">{{ item.title }}</div>
										<div v-html="item.content" class="font-12" style="color: #8e92b1; margin-top: 8px"></div>

										<div v-if="item.type == 1">
											<img alt="img" :src="item.picture" v-if="item.picture" class="img_style" />
										</div>

										<div v-if="item.groupStatus == '1'" style="position: relative;left: 450px;width:60px;color: #666666;font-size: 12px;">
											已同意
										</div>
										<div v-if="item.groupStatus == '2'" style="position: relative;left: 450px;width:60px;color: #666666;font-size: 12px;">
											已拒绝
										</div>

										<div class="flex" v-if="item.groupStatus == '0' && item.type == 2">
											<div class="roomSkip"
												style="position: relative; left: 350px;background:rgba(255, 178, 178, 0.50);color:rgba(245, 47, 47, 1);width:56px;margin-top:14px"
												@click.stop="refunOrder(item)">
												拒绝
											</div>
											<div class="roomSkip" style="position: relative; left: 370px;background:rgba(7, 193, 96, 1);color:#fff;width:56px;margin-top:14px"
												@click.stop="addreesBtn(item)">
												同意
											</div>

										</div>
										<!-- 待续约提示 -->
										<div v-if="item.surtype == '0'" class="roomSkip" style="position: relative; left: 370px" @click.stop="renewalShow = true;msgId = item.id;
                      ">
											去续约
										</div>
										<div v-if="item.surtype == '1'" style="position: relative; left: 370px; font-size: 12px">
											已续约
										</div>
										<div v-if="item.surtype == '2'" style="position: relative; left: 370px; font-size: 12px">
											已拒绝续约
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 消息内容end -->
					</div>


					<!-- 用户单聊 -->
					<div class="messageType_box" v-if="userChatTypeChild">
						<div class="userChat_top flex-spacebetween cursor">
							<div>
								<div class="flex">
									<span @click="checkOtherPage(pitchChatUser)" class="font-18 pitchChatUserName">{{
                    pitchChatUser.nickname
                  }}</span>
									<div class="userChat_age flex-content" :style="
                    pitchChatUser.sex == 1
                      ? 'background: #ff9280;color:#fff'
                      : 'background: #85BCFE;color:#fff'
                  ">
										<img alt="img" src="../../static/134.png" style="margin-right: 4px" v-if="pitchChatUser.sex == 1" />
										<img alt="img" src="../../image/message/25.png" style="margin-right: 4px;color:#fff" v-else />
										{{ pitchChatUser.age }}
									</div>
								</div>
								<div class="flex-aling" style="margin-top: 8px">
									<div class="userChat_topIcon">
										<span v-if="pitchChatUser.onlineStatus == 0" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: red;
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										<span v-if="pitchChatUser.onlineStatus == 1" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										<span v-if="pitchChatUser.onlineStatus == 2" style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "></span>
										{{
                      pitchChatUser.onlineStatus == 0
                        ? "不在线"
                        : pitchChatUser.onlineStatus == 1
                          ? "在线"
                          : pitchChatUser.onlineStatus == 2
                            ? "房间中"
                            : ""
                    }}
									</div>
									<!-- <div class="font-14">ID {{ pitchChatUser.userNo }}</div> -->
								</div>
							</div>
							<div class="flex">
								<div class="userChat_attentionIcon flex-content" style="background: #ccc" v-if="pitchChatUser.isFollow == 1" @click="stopFollowUser()">
									已关注
								</div>
								<div class="userChat_attentionIcon flex-content" v-else @click="followUser()">
									关注
								</div>
								<img alt="img" src="../../image/message/13.png" class="userChat_img" @click.stop="blockShow = true" />
								<img alt="img" src="../../image/message/14.png" class="userChat_img" @click.stop="reportShow = true" />
								<img alt="img" src="../../static/user53.png" class="userChat_img" @click.stop="groupDetail = !groupDetail" />
							</div>
						</div>
						<div class="manitoSkill flex-aling" v-if="pitchChatUser && pitchChatUser.userSkillVos.length != 0">
							<img alt="img" src="../../image/message/18.png" @click="skillCut(1)" />
							<img alt="img" :src="manitoSkillData.skillLogo" style="width: 63px; height: 63px; margin: 0 11px" />
							<div style="width: 170px">
								<div class="font-16" style="color: #333">
									{{ manitoSkillData.skillName }}
								</div>
								<div class="font-12" style="margin-top: 19px; color: #333">
									{{ manitoSkillData.skillLevel }}
								</div>
							</div>
							<div style="position: relative; width: 170px">
								<span style="
                    color: rgba(33, 33, 33, 1);
                    font-size: 18px;
                    font-weight: 600;
                  ">{{ manitoSkillData.price }}</span>
								<span style="font-size: 10px; color: #333">元宝/局</span>
							</div>
							<div class="flex-aling">
								<div style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  " @click="orderAddition(1)">
									-
								</div>
								<span style="
                    display: inline-block;
                    width: 30px;
                    text-align: center;
                    color: #333;
                  ">
									{{ orderNum }}
								</span>
								<div style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  " @click="orderAddition(2)">
									+
								</div>
							</div>
							<div class="order_btn flex-content" style="margin-left: 23px" @click="placeSkipChange()">
								我要下单
							</div>
							<img alt="img" src="../../image/message/19.png" style="margin-left: 15px" @click="skillCut(2)" />
						</div>
						<div class="userChat_content" id="myElement" :style="{height: pitchChatUser.userSkillVos.length != 0 ? '380px' : ''}">
							<div class="chat_box" v-for="(item, index) in chatList" :key="index"
								v-if="!item.payload.data || (!JSON.parse(item.payload.data).businessID || JSON.parse(item.payload.data).businessID != 'C2C_revocationMsgList') && !item.isRevoked">
								<div
									v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 21 || item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31">
									<!-- 双向撤回消息 -->
									<div class="jinquanxiaoxi" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 21">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已发起“双向撤回功能”,等待同意
									</div>
									<!-- 双向撤回消息 -->
									
									<!-- 双向撤回消息 -->
									<div class="jinquanxiaoxi"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31 && JSON.parse(JSON.parse(item.payload.data).content).status == 2">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已拒绝开启双向撤回功能
									</div>

									<div class="jinquanxiaoxi"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).msgType == 31 && JSON.parse(JSON.parse(item.payload.data).content).status == 1">
										{{JSON.parse(JSON.parse(item.payload.data).content).userId == myUser.id?'':'对方'}}已同意开启双向撤回功能
									</div>
								</div>
								<div class="my flex" v-else-if="item.flow == 'out'">
									<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="my_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="my_content" v-if="item.type == 'TIMSoundElem' && !item.isRevoked">
										<audio :src='item.payload.remoteAudioUrl' controls></audio>
									</div>
									<div class="my_content" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="my_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>
									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>
									
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2' && !item.isRevoked" @click="xiazai(item.payload.fileUrl)">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									
									<div class="my_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="my_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType && !item.isRevoked">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}

										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>
									<div class="my_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
									<div style="position: relative;" v-if="!item.isRevoked">
										<img alt="img" :src="item.avatar" class="userHead" />
										<img src="../../static/vip.png"  v-if='myUser.vipFlag==1'
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
									
									<div style="position:relative;" class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
								</div>

								<div class="other flex" v-else-if="item.flow == 'in'">
									<div style="position: relative;" v-if="!item.isRevoked">
										<img @click="checkOtherPagequn(item.from)" alt="img" :src="item.avatar" class="userHead cursor" />
										<img src="../../static/vip.png"  v-if='pitchChatUser.vipFlag==1'
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
									<div class="other_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType && !item.isRevoked">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>
									<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="other_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="other_content" v-if="item.type == 'TIMSoundElem' && !item.isRevoked">
										<audio :src='item.payload.remoteAudioUrl' controls>
										</audio>
									</div>
									<div class="other_content" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked" v-html="renderEmoji(item.payload.text)"></div>
									<div class="other_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>
									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>
									
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2'" @click="xiazai(item.payload.fileUrl) && !item.isRevoked">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									
									<div class="other_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="other_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
									<div style="position:relative;" class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
								</div>
							</div>
						</div>
						<!-- 输入内容 -->
						<div class="userChat_inputBox">
							<div class="iconFlex">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>

							<div class="flex-aling" style="padding: 0 17px;background:#f5f5f5">
								<el-popover placement="top-start" width="400" trigger="hover">
									<div>
										<div>
											<div v-if="imgType == 1" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<img style="height:30px;width:30px;margin-right:5px;margin-bottom:5px;" v-for="(item, index) in emojiList" :key="index"
													:src="require(`../../assets/emoji/${item}@2x.png`)" @click="insertEmoji(item)" class="emoji-item iconImg" />
											</div>
											<div v-if="imgType == 2" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<div style="margin-bottom: 5px;">
													<span v-if="!imgEdit" style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=true">编辑表情</span>
													<span v-else style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=false">完成编辑</span>
												</div>
												<div class="flex flex-wrap">
													<el-upload style="display: inline-block;" ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
														:show-file-list="false" :with-credentials="true" :on-success="handleAvatarSuccessImg" accept=".png,.jpg,.jpeg"
														id="testPasteInput">
														<img src="../../static/add1.png" style="height:60px;width:60px;margin-right:5px;margin-bottom:5px;" alt="" />
													</el-upload>
													<div v-for="(item, index) in RmoteList" :key="index" style="position: relative; display: inline-block;height:60px;width:60px;margin-right:5px;margin-bottom:5px;cursor: pointer;">
														<img style="height:60px;width:60px;" 
															:src="item.url" @click="sendimgDom(item.url,1)" :id="'bq'+index" class="emoji-item" />
														<span @click.stop="deleteMyImg(item)" v-if="imgEdit" style="background-color: rgba(0,0,0,0.5);position: absolute;top:0;right:0;padding:2px 5px;color:#FFF;">
															X
														</span>	
													</div>
												</div>
											</div>
										</div>
										<div style="background-color: #c7c7c7;padding-left:10px;">
											<img @click="imgType = 1" style="width: 30px;height:30px;" :style="{'background-color':imgType == 1?'#FFF':'#c7c7c7'}"
												src="../../static/bq.png" alt="" />
											<img @click="imgType = 2" style="width: 30px;height:30px;margin-left: 10px;" :style="{'background-color':imgType == 2?'#FFF':'#c7c7c7'}"
												src="../../static/sc.png" alt="" />
										</div>
									</div>
									<img slot="reference" alt="img" src="../../static/xl.png" @click="showEmoji = true" class="iconImg" style="margin-right:20px" />
								</el-popover>
								<el-tooltip class="item" effect="dark" content="截图" placement="top">
								      <img alt="img" src="../../static/user46.png" @click="jietu" class="iconImg" style="margin-right:20px" />
								</el-tooltip>
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleAvatarSuccess" accept=".png,.jpg,.jpeg,.mp4" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传图片或视频" placement="top">
									    <img alt="img" src="../../static/user45.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleC2CAct" accept="" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传文件" placement="top">
									    <img alt="img" src="../../static/user47.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								<el-tooltip class="item" effect="dark" content="转发名片" placement="top">
								   <img alt="img" src="../../static/user48.png" class="iconImg" @click="shareBtnUser" style="margin-right:20px" />
								</el-tooltip>
								<!-- 打电话或视频 -->
								<el-tooltip class="item" effect="dark" content="拨打语音电话" placement="top">
								   <img alt="img" src="../../static/user50.png" class="iconImg" @click="callTIM(1)" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="拨打视频电话" placement="top">
								   <img alt="img" src="../../static/user51.png" class="iconImg" @click="callTIM(2)" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="私信设置" placement="top">
								   <img alt="img" src="../../static/user52.png" class="iconImg" @click="isSet = true" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="双向撤回消息" placement="top">
								  <img alt="img" src="../../static/user49.png" class="iconImg" @click="remindShowBtn" style="margin-right:20px" />
								</el-tooltip>
							</div>
							<el-input type="textarea" :rows="4" placeholder="请输入..." resize="none" v-model="chatText" @keydown.enter.native.prevent="sendTextChange($event)">
							</el-input>
							<div class="sendBtn flex-content" @click.stop="sendTextChange($event)">
								发送
							</div>
						</div>
					</div>
					<!-- 群组聊天 -->
					<div class="messageType_box 555" v-show="groupChatChild" style="color:#3333">
						<div class="userChat_top flex-spacebetween">
							<div>
								<div class="flex">
									<span @click="checkOtherPage(pitchChatGroup)" class="font-18 pitchChatUserName">{{pitchChatGroup.groupName}}</span>
								</div>
								<div class="flex-aling" style="margin-top: 8px">
									<div class="font-14">ID {{ pitchChatGroup.groupId }}</div>
								</div>
							</div>
							<div class="flex">
								<img alt="img" src="../../static/user53.png" class="userChat_img" @click.stop="frendsDetail = !frendsDetail, userGroupList(),userGroupListtow()" />
							</div>
						</div>
						<div class="userChat_content" id="myElementgroup">
							<div class="chat_box" v-for="(item, index) in chatListGroup" :key="index">
								<div class="my flex"
									v-if="item.flow == 'out' && (!item.payload.data || JSON.parse(item.payload.data).businessID != 'invite_group' && JSON.parse(item.payload.data).businessID != 'group_create' && JSON.parse(item.payload.data).businessID != 'clear_group_msg' && JSON.parse(item.payload.data).businessID != 'kick_group')">
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
									<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="my_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="my_content" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked" v-html="renderEmoji(item.payload.text)"></div>
									<div class="my_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2' && !item.isRevoked" @click="xiazai(item.payload.fileUrl)">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									<div class="my_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && item.payload.data && JSON.parse(item.payload.data).msgType == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>

									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>

									<div style="width:100%;" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType && !item.isRevoked">
										<!-- 转让群主 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 已成为新的群主
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && !item.payload.memberList">
											"<span style="color:#4981fa">{{ item.nick }}</span>"
											修改群名称为"{{ item.payload.newGroupProfile.groupName }}"
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 3">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 被踢出群组
										</div>
										<!-- 退出群聊 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 2">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 退出群组
										</div>
										<!-- 设为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 4 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被设置为管理员
										</div>
										<!-- 设取消为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 5 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被取消管理员
										</div>
									</div>

									<div class="my_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType  && !item.isRevoked">
										{{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
										{{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}

										{{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
									</div>
									<div class="my_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
									<div style="position: relative;" v-if="!item.isRevoked">
										<img alt="img" :src="item.avatar" v-if="!item.payload.operationType" class="userHead" />
										<img src="../../static/vip.png"  v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).vipFlag && JSON.parse(item.cloudCustomData).vipFlag == 1"
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
								</div>
								<div style="width:100%;"
									v-else-if="item.flow == 'out' && item.payload.data && JSON.parse(item.payload.data) && (JSON.parse(item.payload.data).businessID == 'invite_group' || JSON.parse(item.payload.data).businessID == 'group_create' || JSON.parse(item.payload.data).businessID == 'kick_group')  && !item.isRevoked">
									<!-- 邀请进群消息 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'invite_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>邀请<span>{{ JSON.parse(item.payload.data).data.inviteUserNames }}</span>进入群聊
									</div>
									<!-- 移出群 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'kick_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>将<span>{{ JSON.parse(item.payload.data).data.kickUserNames }}</span>移出群聊
									</div>
									<!-- 创建群聊 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && (JSON.parse(item.payload.data).content == '创建了群聊' || JSON.parse(item.payload.data).content == '创建了群聊，大家一起来聊天吧') && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"创建了群聊
									</div>
									<div class="jinquanxiaoxi" v-else-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"进入群聊
									</div>
								</div>
								<!-- !JSON.parse(item.payload.data).businessID -->
								<div class="other flex"
									v-if="item.flow == 'in' && (!item.payload.data || JSON.parse(item.payload.data).businessID != 'invite_group' && JSON.parse(item.payload.data).businessID != 'group_create' && JSON.parse(item.payload.data).businessID != 'clear_group_msg' && JSON.parse(item.payload.data).businessID != 'kick_group')">
									<div class="jinquanxiaoxi" v-if="item.isRevoked">
										撤回了一条消息
									</div>
									<div style="position: relative;" v-if="!item.isRevoked">
										<div v-if="pitchChatGroup.showNicknameStatus == 1 && !item.payload.operationType" class="nime-view text-cut">{{item.nameCard || item.nick}}</div>
										<img alt="img" :src="item.avatar" @click="checkOtherPagequn(item.from)" v-if="!item.payload.operationType" class="userHead cursor" />
										<img src="../../static/vip.png"  v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).vipFlag && JSON.parse(item.cloudCustomData).vipFlag == 1"
										   style="width:auto;height:14px;position:absolute;left:calc(50% - 22px);bottom:5px;" />
									</div>
									<div v-if="item.cloudCustomData == '' && !item.isRevoked">
										<div class="other_content" v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).actionType">
											{{
                        JSON.parse(item.payload.data).actionType == 1
                          ? "发起通话"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 4
                          ? "拒绝通话"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 5
                          ? "未应答"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 3
                          ? "已接听"
                          : ""
                      }}
											{{
                        JSON.parse(item.payload.data).actionType == 2
                          ? "取消通话"
                          : ""
                      }}
										</div>
									</div>

									<div class="shareCartBack" @click="checkOtherPagetow(JSON.parse(item.payload.data))"
										v-if="item.type == 'TIMCustomElem' && JSON.parse(item.payload.data).businessID == 'userCard_message' && !item.isRevoked">
										<div class="shareCart">
											<div>
												<img alt="img" :src="JSON.parse(item.payload.data).avatar" class="chatImgUrl" style="width:48px;height:48px;border-radius: 5px" />
											</div>
											<div style="margin-left:10px">
												<div class='userNameShare'>{{ JSON.parse(item.payload.data).userName }}</div>
												<div class='userNameShareId'>ID：{{ JSON.parse(item.payload.data).userShowId }}</div>
											</div>
										</div>
										<div class="userNameShareLine" style="margin-top:10px"></div>
										<div class="personShare" style="margin-top:6px">
											个人名片
										</div>
									</div>

									<div v-if="item.type == 'TIMVideoFileElem' && !item.isRevoked" class="other_content">
										<video width="320" height="240" controls>
											<source :src="item.payload.remoteVideoUrl" type="video/webm" />
										</video>
									</div>
									<div class="other_content" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && !isValidURL(item.payload.text) && !item.isRevoked"></div>
									<div class="other_content cursor" style="color:#0E98F5" @click="xiazai(item.payload.text)" v-html="renderEmoji(item.payload.text)" v-if="item.type == 'TIMTextElem' && isValidURL(item.payload.text) && !item.isRevoked"></div>
									
									<div class="other_content" v-if="item.type == 'TIMSoundElem' && !item.isRevoked">
										<audio :src='item.payload.remoteAudioUrl' controls>
										</audio>
									</div>

									<div style="width:100%;" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType && !item.isRevoked">
										<!-- 转让群主 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 已成为新的群主
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 6 && !item.payload.memberList">
											"<span style="color:#4981fa">{{ item.nick }}</span>"
											修改群名称为"{{ item.payload.newGroupProfile.groupName }}"
										</div>

										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 3">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 被踢出群组
										</div>
										<!-- 退出群聊 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 2">
											"<span style="color:#4981fa">{{ item.nick }}</span>" 退出群组
										</div>
										<!-- 设为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 4 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被设置为管理员
										</div>
										<!-- 设取消为管理员 -->
										<div class="jinquanxiaoxi" v-if="item.type == 'TIMGroupTipElem' && item.payload.operationType == 5 && item.payload.memberList">
											"<span style="color:#4981fa">{{ item.payload.memberList[0].nick }}</span>" 被取消管理员
										</div>
									</div>

									<div class="my_content-weizhi" @click="gotomap(JSON.parse(item.payload.data))"
										v-if="item.cloudCustomData && JSON.parse(item.cloudCustomData).businessID == 'location_message' && !item.isRevoked">
										<div class="my_content-weizhi-title text-cut">{{ JSON.parse(item.payload.data).title }}</div>
										<div class="my_content-weizhi-content text-cut">{{ JSON.parse(item.payload.data).address }}</div>
									</div>
									<div class="my_content flex cursor" style="align-items: center;" v-if="item.type == 'TIMFileElem' && item.payload.downloadFlag == '2'" @click="xiazai(item.payload.fileUrl) && !item.isRevoked">
										<img style="width:30rpx;height:30px;margin-right:10px;" src="../../static/n38.png" alt="" />
										<div style="max-width:200px;height:20px;line-height:20px;"> {{ formatString(item.payload.fileName) }}</div>
									</div>
									<div class="other_content" v-if="item.type == 'TIMRelayElem' && !item.isRevoked">
										收到了转发的聊天记录，请在app端查看
									</div>
									<div class="other_content" v-if="item.type == 'TIMImageElem' && !item.isRevoked">
										<el-image class="img" style="max-width:300px;" fit="contain" :src="item.payload.imageInfoArray[0].imageUrl" :preview-src-list="[item.payload.imageInfoArray[0].imageUrl]">
										</el-image>
										<img alt="img" src="../../static/xiazai.png" class="xiazai cursor" @click="xiazai(item.payload.imageInfoArray[0].imageUrl)"/>
									</div>
								</div>

								<!-- 系统消息 -->
								<div style="width:100%;"
									v-else-if="item.flow == 'in' && item.payload.data && JSON.parse(item.payload.data) && (JSON.parse(item.payload.data).businessID == 'invite_group' || JSON.parse(item.payload.data).businessID == 'group_create' || JSON.parse(item.payload.data).businessID == 'kick_group') && !item.isRevoked">
									<!-- 邀请进群消息 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'invite_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>邀请<span>{{ JSON.parse(item.payload.data).data.inviteUserNames }}</span>进入群聊
									</div>
									<!-- 移出群 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'kick_group'">
										<span>{{ JSON.parse(item.payload.data).data.userName }}</span>将<span>{{ JSON.parse(item.payload.data).data.kickUserNames }}</span>移出群聊
									</div>
									<!-- 创建群聊 -->
									<div class="jinquanxiaoxi" v-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && (JSON.parse(item.payload.data).content == '创建了群聊' || JSON.parse(item.payload.data).content == '创建了群聊，大家一起来聊天吧') && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"创建了群聊
									</div>
									<div class="jinquanxiaoxi" v-else-if="JSON.parse(item.payload.data) && JSON.parse(item.payload.data).businessID == 'group_create' && JSON.parse(item.payload.data).version == '4'">
										"<span style="color:#4981fa">{{ item.nick }}</span>"进入群聊
									</div>
								</div>
							</div>
						</div>
						<!-- 输入内容 -->
						<div class="userChat_inputBox">
							<div class="iconFlex">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>

							<div class="flex-aling" style="padding: 0 17px;background:#f5f5f5">
								<el-popover placement="top-start" width="400" trigger="hover">
									<div>
										<div>
											<div v-if="imgType == 1" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<img style="height:30px;width:30px;margin-right:5px;margin-bottom:5px;" v-for="(item, index) in emojiList" :key="index"
													:src="require(`../../assets/emoji/${item}@2x.png`)" @click="insertEmoji(item)" class="emoji-item iconImg" />
											</div>
											<div v-if="imgType == 2" class="emoji-list" style="height: 200px;overflow-y: auto;">
												<div style="margin-bottom: 5px;">
													<span v-if="!imgEdit" style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=true">编辑表情</span>
													<span v-else style="font-weight: 600;cursor: pointer;color:#333;" @click="imgEdit=false">完成编辑</span>
												</div>
												<div class="flex flex-wrap">
													<el-upload style="display: inline-block;" ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
														:show-file-list="false" :with-credentials="true" :on-success="handleAvatarSuccessImg" accept=".png,.jpg,.jpeg"
														id="testPasteInput">
														<img src="../../static/add1.png" style="height:60px;width:60px;margin-right:5px;margin-bottom:5px;" alt="" />
													</el-upload>
													<div v-for="(item, index) in RmoteList" :key="index" style="position: relative; display: inline-block;height:60px;width:60px;margin-right:5px;margin-bottom:5px;cursor: pointer;">
														<img style="height:60px;width:60px;" 
															:src="item.url" @click="sendimgDom(item.url,2)" :id="'bq'+index" class="emoji-item" />
														<span @click.stop="deleteMyImg(item)" v-if="imgEdit" style="background-color: rgba(0,0,0,0.5);position: absolute;top:0;right:0;padding:2px 5px;color:#FFF;">
															X
														</span>	
													</div>
												</div>
											</div>
										</div>
										<div style="background-color: #c7c7c7;padding-left:10px;">
											<img @click="imgType = 1" style="width: 30px;height:30px;" :style="{'background-color':imgType == 1?'#FFF':'#c7c7c7'}"
												src="../../static/bq.png" alt="" />
											<img @click="imgType = 2" style="width: 30px;height:30px;margin-left: 10px;" :style="{'background-color':imgType == 2?'#FFF':'#c7c7c7'}"
												src="../../static/sc.png" alt="" />
										</div>
									</div>
									<img slot="reference" alt="img" src="../../static/xl.png" @click="showEmoji = true" class="iconImg" style="margin-right:20px" />
								</el-popover>
								<el-tooltip class="item" effect="dark" content="截图" placement="top">
								   <img alt="img" src="../../static/user46.png" @click="jietutow" class="iconImg" style="margin-right:20px" />
								</el-tooltip>

								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleGrouppSuccess" accept=".png,.jpg,.jpeg,.mp4" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="上传图片或视频" placement="top">
									   <img alt="img" src="../../static/user45.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>

								<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
									:on-success="handleGrouppAct" accept="" :on-change="handleChange" id="testPasteInput">
									<el-tooltip class="item" effect="dark" content="发送文件" placement="top">
									   <img alt="img" src="../../static/user47.png" class="iconImg" style="margin-right:20px" />
									</el-tooltip>
								</el-upload>
								
								<el-tooltip class="item" effect="dark" content="转发名片" placement="top">
								   <img alt="img" src="../../static/user48.png" class="iconImg" @click="shareBtnUserGrop" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="清除群聊天记录" placement="top">
								   <img v-if="pitchChatGroup.userIdentity == 0" src="../../static/user49.png" class="iconImg" @click="qunzhucheui" style="margin-right:20px" />
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="撤回群聊天记录" placement="top">
								   <img v-if="pitchChatGroup.userIdentity != 0" src="../../static/user49.png" class="iconImg" @click="qunyuanchehuidianji" style="margin-right:20px" />
								</el-tooltip>
							</div>
							<el-input type="textarea" :rows="4" placeholder="请输入..." resize="none" v-model="chatText" @keydown.enter.native.prevent="sendTextChangeGroup($event)">
							</el-input>
							<div class="sendBtn flex-content" @click.stop="sendTextChangeGroup($event)">
								发送
							</div>
						</div>
					</div>
				</div>





				<div class="message_content" v-if="tabType == 3 && tousuShow" style="padding: 24px 20px; box-sizing: border-box">


					<div class="flex" style="justify-content: space-between">
						<div></div>
						<div>

							<img alt="img" src="../../static/user26.png" @click.stop="blockShow = true" />
							<img alt="img" style="margin-left: 24px" src="../../static/user27.png" @click.stop="reportShow = true" />
						</div>
					</div>
					<div class="messageLine"></div>
					<div class="messageContent">
						<div class="flex" style="
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
              ">
							<div class="touTitle" style="padding-left: 16px"
								@click='complaintShow = true, complainData.type = 1, complainData.complainObjectId = contentData.userID'>
								投诉
							</div>
							<div class="touTitleRight" @click="checkUser(contentData)">TA的主页</div>
						</div>
						<div style="margin-top: 20px">
							<div style="width: 70px; margin: 0 auto">
								<img alt="img" style="width: 70px; height: 70px; border-radius: 50%;" :src="contentData.avatar" />
							</div>
							<div style="
                  width: 100%;
                  color: #333;
                  text-align: center;
                  margin-top: 16px;
                ">
								{{ contentData.nickname }}
							</div>
							<div class="flex arrdessData" style="align-items: center; margin: 8px auto 0; width: 230px;justify-content: center;">
								ID：{{ contentData.userNo }}
								<img class="cursor" alt="img" style="width: 16px; height: 16px; margin-left: 14px" src="../../static/user30.png"
									@click="copyUserAddrees(contentData.userNo)" />
							</div>
							<div class="arrdessData" v-if="contentData.city" style="
                  align-items: center;
                  width: 100%;
                  text-align: center;
                  margin-top: 16px;
                  margin-bottom: 10px;
                ">
								位于 {{ contentData.city }}
							</div>
							<div v-if="contentData.personalSignature" class="titleDur text-cut" style="padding:0 24px">{{contentData.personalSignature}}</div>
						</div>
					</div>
					<div style="width: 360px; margin: 26px auto" class="flex">
						<div class="shareBtn" @click="shareBtnPop(contentData)">分享</div>
						<!-- {{contentData}} -->
						<div class="sendMassage" @click="sendMassage(contentData,'',contentData.id); tousuShow = false; tabType = 3">发消息</div>
					</div>
				</div>

				<div class="message_content" v-if="tabType == 3 && groupShowContent" style="padding: 24px 20px; box-sizing: border-box">


					<div class="flex" style="justify-content: space-between">
						<div></div>
						<div>
							<!-- 群聊不加拉黑 -->
							<!-- <img  alt="img" src="../../static/user26.png"  @click.stop="blockShow = true" /> -->
							<img alt="img" style="margin-left: 24px" src="../../static/user27.png" @click.stop="reportShow = true" />
						</div>
					</div>
					<div class="messageLine"></div>
					<div class="messageContent">
						<div class="flex" style="
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
              ">
							<div class="touTitle" style="padding-left: 16px" @click='complaintShow = true, complainData.type = 2, complainData.complainObjectId = type3Data.id'>投诉
							</div>
							<!-- <div class="touTitleRight" @click="checkUser(type3Data)">TA的主页</div> -->
						</div>
						<div style="margin-top: 20px">
							<div style="width: 70px; margin: 0 auto">
								<img alt="img" style="width: 70px; height: 70px; border-radius: 50%;" :src="type3Data.avatar" />
							</div>
							<div style="
                  width: 100%;
                  color: #333;
                  text-align: center;
                  margin-top: 16px;
                ">
								{{ type3Data.name }}
							</div>
							<div class="flex arrdessData" style="align-items: center; margin: 8px auto 0; width: 150px">
								ID：{{ type3Data.id }}
								<img alt="img" style="width: 16px; height: 16px; margin-left: 14px" src="../../static/user30.png" @click="copyUserAddrees(type3Data.id)" />
							</div>
							<div class="arrdessData" style="
                  align-items: center;
                  width: 100%;
                  text-align: center;
                  margin-top: 16px;
                  margin-bottom: 10px;
                ">
								<div></div>
								<div></div>
							</div>
							<div class="titleDur">
								<div>

								</div>
							</div>
						</div>
					</div>
					<div style="width: 360px; margin: 26px auto" class="flex" v-if="type3Data.userId == myUser.id">
						<div class="shareBtn" @click="dissolveBtnPop">解散群聊</div>
						<div class="sendMassage" @click="sendGroupMassage(type3Data); groupShowContent = false; tabType = 3">进入群聊
						</div>
					</div>
					<div v-else style="width: 360px; margin: 26px auto">
						<div class="sendMassage" style="margin:0 auto" @click="sendGroupMassage(type3Data); groupShowContent = false; tabType = 3">进入群聊</div>
					</div>
				</div>
				<!--  -->
			</div>
		</div>
		<!-- 清除聊天记录提醒弹窗 -->
		<div class="popUp_box" v-if="clearTheChatShowGroup">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="clearTheChatShowGroup = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img alt="img" src="../../static/129.png" style="margin-top: 40px" />
					<div style="margin-top: 20px; color: #333">
						清空记录将无法恢复，确认清空吗？
					</div>
					<div class="flex" style="margin: 68px 0 40px 0">
						<div class="remind_btn flex-content" @click="clearTheChatShowGroup = false">
							再想想
						</div>
						<div class="remind_btn flex-content" style="
                background: rgba(255, 167, 0, 0.2);
                color: #ffa700;
                margin-left: 20px;
              " @click="clearTheChatShowGroupBtn()">
							确认
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box" v-if="clearTheChatShowUser">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="clearTheChatShowUser = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img alt="img" src="../../static/129.png" style="margin-top: 40px" />
					<div style="margin-top: 20px; color: #333">
						清空记录将无法恢复，确认清空吗？
					</div>
					<div class="flex" style="margin: 68px 0 40px 0">
						<div class="remind_btn flex-content" @click="clearTheChatShowUser = false">
							再想想
						</div>
						<div class="remind_btn flex-content" style="
                background: rgba(255, 167, 0, 0.2);
                color: #ffa700;
                margin-left: 20px;
              " @click="clearTheChatShowBtn()">
							确认
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box" v-if="msgDetailpop">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">消息详情</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="msgDetailpop = false" />
				</div>
				<!-- 头部end -->
				<div style='padding:16px' v-if="msgDetailinfo">
					<p style="font-size: 15px;font-weight: bold;color:#333">{{ msgDetailinfo.title }}</p>
					<p style="font-size: 12px;color:#999;margin:10px 0">{{ msgDetailinfo.createTime }}</p>
					<p style="font-size: 13px;color:#666;height:300px;overflow-y: scroll;" v-html="msgDetailinfo.content"></p>
				</div>
			</div>
		</div>

		<!-- 投诉弹窗 -->
		<div class="popUp_box" v-if="complaintShow">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14" v-if="groupShowContent == true || frendsDetail">投诉该群聊</div>
					<div class="font-14" v-else>投诉该账号</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="complaintShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div>
						<div class="complaintText">投诉理由</div>
						<div style="width:360px;margin-top:10px">
							<el-select v-model="complainData.complainId" placeholder="请选择投诉理由" style="width: 100%">
								<el-option v-for="(item, index) in gradeList" :key="index" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="complaintText">详细投诉说明</div>
						<textarea v-model="complainData.content" class="complainTextArea" placeholder="为帮助审核人员尽快处理，可补充详细的举报说明">

            </textarea>
						<div class="flex" style="margin-top:20px">
							<span style="color:rgba(248, 59, 55, 1)">*</span>
							<div style="color:#333">涉及相关内容截图等平台取证请上传图片</div>
							<span style="color:rgba(27, 133, 251, 1)">(必填)</span>
						</div>
					</div>
					<div class="photowall_box">
						<div v-for="(item, index) in complainArr" :key="index" style="position: relative">
							<img alt="img" :src="item" class="photowall_item" />
							<img alt="img" src="../../image/my/19.png" @click.stop="delImg(item, index)" style="position: absolute; top: 8px; right: 18px" />
						</div>
						<el-upload class="avatar-uploader" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :on-success="photoWallSuccess"
							accept=".png,.jpg.jpeg" v-if="complainArr.length < 6">
							<img alt="img" src="../../image/my/18.png" />
						</el-upload>
					</div>


					<div class="flex" style="margin: 68px 0 40px 0">
						<div class="confirmBtn flex-content" @click="complaintBtn()">
							确认提交
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 拉黑提醒弹窗 -->
		<div class="popUp_box" v-if="blockShow">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="blockShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<img alt="img" src="../../static/129.png" style="margin-top: 40px" />
					<div class="flex-dir" style="margin-top: 20px">
						<span style="color: #333">{{
              pitchChatUser.inBlack == 1
                ? "是否将对方从黑名单中移出"
                : "拉黑后将不会收到对方的消息，该用户将无法对你下单"
            }}</span>
						<span style="color: #333">{{
              pitchChatUser.inBlack == 1 ? "确认移出吗？" : "确认拉黑吗？"
            }}</span>
					</div>
					<div class="flex" style="margin: 68px 0 40px 0">
						<div class="remind_btn flex-content" @click="blockShow = false">
							再想想
						</div>
						<div class="remind_btn flex-content" style="
                background: rgba(255, 167, 0, 0.2);
                margin-left: 20px;
                color: #ffa700;
              " @click="addUserBlack(pitchChatUser)">
							确认
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box" v-if="selectFrends">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14"></div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="selectFrends = false" />
				</div>
				<!-- 头部end -->
				<div class="flex" style="padding:24px ">
					<div class="selectLeft">
						<div>选择好友</div>
						<div style="position: relative;margin-top:10px">
							<img alt="img" style="position: absolute;top:10px;left:11px" src="../../static/user55.png" @click="selectFrends = false" />
							<input type="text" class="inpMassage" style="border-radius: 4px 4px 4px 4px;" placeholder="搜索">
						</div>
						<div style="margin-top:16px">
							<div class="popRight" style="margin-top:24px">
								<div class="flex" style="align-items: center;margin-bottom:16px" v-for="(item, key) in tongxuArr" :key='key'>
									<img alt="img" v-if="item.shareState == 1" class="groupImgPosition" src="../../static/user59.png" @click="shareState('', item)" />
									<img alt="img" v-if="item.shareState == 2" class="groupImgPosition" src="../../static/user60.png" @click="shareState('', item)" />
									<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow = false" />
									<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
								</div>
							</div>
						</div>
					</div>
					<div style='margin-left:16px'>
						<div class="selectRight">将名片发送给：</div>
						<div class="flex" style="margin-top:16px">
							<!-- {{atuserObj}} -->
							<img alt="img" :src="atuserObj.avatar || atuserObj.userProfile.avatar" style="margin-right:16px;width:34px;height:34px;border-radius:50%;">
							<div style="color:#333">{{ atuserObj.nick || atuserObj.userProfile.nick }}</div>
						</div>
						<div class="flex" style="justify-content: space-between;color:#333;margin-top:200px">
							<div></div>
							<div class="flex " style="justify-content: space-between;">
								<div class="canclBtn" @click='selectFrends = false'>取消</div>
								<div class="canclShare" @click='shareBtnFrend'>分享</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="popUp_box" v-if="selectFrendstow">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14"></div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="selectFrendstow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex" style="padding:24px ">
					<div class="selectLeft">
						<div>选择好友</div>
						<div style="position: relative;margin-top:10px">
							<img alt="img" style="position: absolute;top:10px;left:11px" src="../../static/user55.png" @click="selectFrendstow = false" />
							<input type="text" class="inpMassage" style="border-radius: 4px 4px 4px 4px;" placeholder="搜索">
						</div>
						<div style="margin-top:16px">
							<div class="popRight" style="margin-top:24px">
								<div class="flex" style="align-items: center;margin-bottom:16px" v-for="(item, key) in tongxuArr" :key='key'>
									<img alt="img" v-if="item.shareState == 1" class="groupImgPosition" src="../../static/user59.png" @click="shareState('', item)" />
									<img alt="img" v-if="item.shareState == 2" class="groupImgPosition" src="../../static/user60.png" @click="shareState('', item)" />
									<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow = false" />
									<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
								</div>
							</div>
						</div>
					</div>
					<div style='margin-left:16px'>
						<div class="selectRight">将名片发送至群聊：</div>
						<div class="flex" style="margin-top:16px">
							<img alt="img" :src="pitchChatGroup.groupAvatar" style="margin-right:16px;width:34px;height:34px;border-radius:50%;">
							<div style="color:#333">{{ pitchChatGroup.groupName }}</div>
						</div>
						<div class="flex" style="justify-content: space-between;color:#333;margin-top:200px">
							<div></div>
							<div class="flex " style="justify-content: space-between;">
								<div class="canclBtn" @click='selectFrendstow = false'>取消</div>
								<div class="canclShare" @click='shareBtnFrendGroud'>分享</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box" v-if="remindShow">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">提醒</div>
					<img alt="img" class="messageText" src="../../static/quit.png" style="cursor: pointer;" @click="remindShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-dir">
					<div class="flex-dir" style="margin-top: 48px">
						<div style="color: #333;width:270px;text-align: center;" v-if="!isMyorYou">发起撤回申请，对方同意后，将销毁您和对方设备上的本次聊天记录。</div>
						<div style="color: #333;width:270px;text-align: center;" v-if="isMyorYou">同意撤回申请，将销毁您和对方设备上的本次聊天记录。</div>
						<div style="color: #333;width:270px;text-align: center;color:rgba(230, 44, 44, 1);margin-top:12px">
							数据删除后双方均无法恢复，请谨慎操作！
						</div>
					</div>
					<div class="flex" style="margin: 68px 0 40px 0">

						<div class="remind_btn flex-content" style="background: rgba(248, 248, 248, 1);color: rgba(102, 102, 102, 1);" @click="guanbichehui">
							关闭
						</div>
						<div class="remind_btn flex-content" @click="vipSkipChange" style="margin-left: 20px;">
							VIP使用
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="popUp_box" v-if="isGuan">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">选择分组</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="isGuan = false" />
				</div>
				<!-- 头部end -->
				<div style="height: 219px">
					<div style="width: 382px; margin: 48px auto 60px">
						<el-select v-model="FenValue" placeholder="请选择" style="width: 382px">
							<el-option v-for="item in goodsFen" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="BtnSubmit" @click="BtnSubmit">确认</div>
				</div>
			</div>
		</div>
		<div class="popUp_box" style="color: #000" v-if="isSet">
			<div class="remid_box">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">私信设置</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="isSet = false" />
				</div>
				<div class="contentSi" style="margin-top: 24px">
					<div>免费</div>
					<el-switch v-model="siValue1" active-color="#13ce66" inactive-color="#ff4949" @change="mianfeiChange">
					</el-switch>
				</div>
				<div class="contentSi">
					<div>付费</div>
					<el-switch v-model="siValue2" active-color="#13ce66" inactive-color="#ff4949" @change="mianfeiChange2">
					</el-switch>
				</div>
				<div style="height: 150px">
					<div class="contentSi1" v-if="siValue2">
						<div>音频通话收费</div>
						<el-select v-model="audioValue" placeholder="请选择">
							<el-option v-for="item in audioArr" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="contentSi1" v-if="siValue2">
						<div>视频通话收费</div>
						<el-select v-model="videoValue" placeholder="请选择">
							<el-option v-for="item in videoArr" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="contentSi1" v-if="siValue2">
						<div>短视频收费</div>
						<el-select v-model="shortVideoValue" placeholder="请选择">
							<el-option v-for="item in shortVideoArr" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="BtnSubmit" @click="BtnSubmit1">确认提交</div>
				<div style="height: 20px; width: 100%"></div>
				<!-- 头部end -->
				<!-- <div style="height:219px">
          <div style="width:382px;margin:48px auto 60px">
            <el-select v-model="FenValue" placeholder="请选择" style="width:382px">
              <el-option
                v-for="item in goodsFen"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          
        </div> -->
			</div>
		</div>
		<!-- 举报弹窗 -->
		<div class="popUp_box" v-if="reportShow">
			<div class="remid_box" style="width: 679px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">举报用户</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="reportShow = false" />
				</div>
				<div class="report_title">
					举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
				</div>
				<!-- 头部end -->
				<div class="report_from">
					<div class="report_fromTitle">举报原因</div>
					<el-select v-model="reportValue" placeholder="请选择">
						<el-option v-for="item in reportOptions" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<div class="report_fromTitle">
						举报原因
						<span style="color: #6a6a94; margin-left: 11px">补充说明，协助审核人员尽快处理</span>
					</div>
					<el-input type="textarea" placeholder="填写详情说明" v-model="reportTextarea" maxlength="200" :rows="4" show-word-limit resize="none" class="textarea">
					</el-input>
					<div class="report_fromTitle">截图视频</div>
					<div class="flex-aling">
						<div class="imgBox" v-for="(item, index) in reportImgs" :key="index">
							<img alt="img" :src="item" style="width: 100%; height: 100%" />
							<img alt="img" src="../../static/127.png" class="imgDeleIcon" @click.stop="imgDeleChange(index)" />
						</div>
						<el-upload action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :limit="4" :show-file-list="false" list-type="picture-card"
							:on-success="reportImgSuccess" v-if="reportImgs.length < 4" id="imagePicker">
							<img alt="img" src="../../static/183.png" />
						</el-upload>
					</div>
					<div style="color: #6a6a94; font-size: 10px; margin-top: 20px">
						最多4张图片，1个视频
					</div>
					<div class="report_btn flex-content" @click="addReport()">
						确认提交
					</div>
				</div>
			</div>
		</div>
		<!-- 电话 -->
		<div class="popUp_box1" :style="'background-image:url(' + atuserObj.userProfile.avatar + ')'" v-if="isCall">
			<div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);margin-top: -10px;">
				<div style="
            display: flex;
            justify-content: space-between;
            margin: 10px 20px 0;
            overflow: hidden;
          ">
					<div></div>
					<div @click="isCall = false">×</div>
				</div>
				<div style="
            width: 170px;
            height: 170px;
            border-radius: 16px;
            margin: 124px auto 20px;
          ">
					<img alt="img" style="width: 100%; height: 100%" :src="atuserObj.userProfile.avatar" />
				</div>
				<div style="text-align: center">
					<div>{{ atuserObj.userProfile.nick }}</div>
					<div v-if="1" style="margin-top: 16px">等待对方接受邀请...</div>
				</div>
				<!-- <div
          style="
            width: 230px;
            height: 40px;
            background: rgba(0, 0, 0, 0.7);
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 204px auto 0;
          "
        >
          对方已挂断，通话结束
        </div> -->
				<div v-if="guaduanIsSHow" style="
            width: 230px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 150px auto 0;
          ">
					<div style="width:100%;color: #fff;font-size:20px;margin-top:12px;" v-if="formattedTime != '00:00:00'">{{
            formattedTime
          }}</div>
				</div>
				<div style="display: flex; justify-content: space-between" :style="
          guaduanIsSHow ? 'margin: 20px 113px;' : 'margin: 270px 113px;'
        ">
					<div v-if="guaduanIsSHow && isAudio && isjieting" @click="closeMicrophone">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/230.png" />
						<div style="width: 48px; text-align: center">麦克风</div>
					</div>
					<div v-if="guaduanIsSHow && !isAudio && isjieting" @click="openMicrophone">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/231.png" />
						<div style="width: 48px; text-align: center">麦克风</div>
					</div>
					<div v-if="!isjieting"></div>

					<div @click="overCall(1)" v-if="guaduanIsSHow">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/228.png" />
						<div style="width: 48px; text-align: center">挂断</div>
					</div>
					<div @click="DownBtn(1)" v-if="isBackCall">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/228.png" />
						<div style="width: 48px; text-align: center">拒绝</div>
					</div>

					<div @click="DownOn(1)" v-if="isBackCall">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/229.png" />
						<div style="width: 48px; text-align: center">接听</div>
					</div>

					<div v-if="!isjieting"></div>
				</div>
			</div>
		</div>

		<!-- 视频电话 -->
		<div id="localView" style="display:none;"></div>
		<div class="popUp_box2" :style="'background-image:url(' + isShopingBei.avatar + ')'" v-if="isShopIng">
			<div class="popUp_Title">
				<div class="popUp_TitleFlex">
					<div class="">
						<img alt="img" class="popUp_TitleImg" :src="isShopingBei.avatar" />
					</div>
					<div style="margin-left: 10px">
						<div style="font-size:18px;color:#333">{{ isShopingBei.nickname }}</div>
						<div style="margin-top: 4px;font-size:16px;color:#333" v-if="formattedTime == '00:00:00'">等待对方接受邀请...</div>
					</div>
				</div>
				<div>
					<img alt="img" src="../../static/169.png" @click="isShopIng = false" />
				</div>
			</div>
			<div style="width:800px;height:346px;position: absolute;top:80px;right:40px;" id="video_1"></div>
			<div class="popUp_TitleBottom">
				<div class="popUp_TitleBottomFlex">
					<div v-if="!isflagJie && isAudio && isjieting" @click="closeMicrophone">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/230.png" />
						<div style="width: 48px; text-align: center">麦克风</div>
					</div>
					<div v-if="!isflagJie && !isAudio && isjieting" @click="openMicrophone">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/231.png" />
						<div style="width: 48px; text-align: center">麦克风</div>
					</div>
					<div v-if="!isjieting"></div>
					<div v-if="!isflagJie && shexiangtouis && isjieting" @click="closeSHExiangtou">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/245.png" />
						<div style="width: 48px; text-align: center">摄像头</div>
					</div>
					<div v-if="!isflagJie && !shexiangtouis && isjieting" @click="kaiqishexiangtou">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/246.png" />
						<div style="width: 48px; text-align: center">摄像头</div>
					</div>

					<div v-if="isflagJie" @click="DownOn(2)">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/229.png" />
						<div style="width: 48px; text-align: center; font-size: 14px">
							接听
						</div>
					</div>
					<div @click="overCall(2)" v-if="!isflagJie">
						<img alt="img" style="width: 48px; height: 48px" src="../../static/228.png" />
						<div style="width: 48px; text-align: center; font-size: 14px;cursor: pointer;">
							挂断
						</div>
					</div>
					<div @click="DownBtn(2)" v-else>
						<img alt="img" style="width: 48px; height: 48px" src="../../static/228.png" />
						<div style="width: 48px; text-align: center; font-size: 14px">
							拒绝
						</div>
					</div>
					<div v-if="!isjieting"></div>
				</div>
			</div>
		</div>
		<div class="popUp_box" v-if="renewalShow">
			<div class="remid_box" style="width: 679px">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">续约通知</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="renewalShow = false" />
				</div>
				<!-- 头部end -->
				<div class="flex-content" style="flex-direction: column; margin-top: 16px">
					<h3>续约通知</h3>
					<div>你的大神资格即将到期,请及时续约！</div>
				</div>
				<div class="flex-content" style="margin: 16px 0">
					<div class="renewalBtn" style="background: #9c9c9c" @click.stop="setGreatGodRenewal(msgId, 0)">
						拒绝
					</div>
					<div class="renewalBtn" @click.stop="setGreatGodRenewal(msgId, 1)">
						同意
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box3" v-if="popUp_message">
			<div class="flex popUp_header" style="
          justify-content: space-between;
          padding: 16px;
          box-sizing: border-box;
        ">
				<div></div>
				<div class="chooseLiao">选择一个聊天</div>
				<img alt="img" style="width: 20px; height: 20px" src="../../static/user31.png" />
			</div>

			<div>
				<input class="searchInp" type="text" name="" id="">
			</div>

		</div>

		<div class="popUp_box" v-if="groupShow" style="z-index:999999">
			<div class="remid_box" style="width: 358px;height:338px;">
				<!-- 头部start -->
				<div class="titleIcon"></div>
				<div class="title_content">
					<div class="font-14">创建群聊</div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="groupShow = false" />
				</div>
				<div class="flex groupName" style="margin-top:40px;margin-left:40px">
					<div>群名称</div>
					<div style="margin-left:16px">
						<input class="messageInp" type="text" style="width:220px;height:40px" v-model="groupData.groupName" placeholder="请输入群聊名称">
					</div>
				</div>
				<div class="flex groupName" style="margin-top:20px;margin-left:40px">
					<div>群ID</div>
					<div style="margin-left:16px">
						{{ groupData.groupId }}
					</div>
				</div>
				<div class="flex groupName" style="margin-top:20px;margin-left:40px">
					<div>群头像</div>
					<div style="margin-left:16px">

						<el-upload ref="upload" action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :show-file-list="false" :with-credentials="true"
							:on-success="handlePictureCardPreview" accept=".png,.jpg,.jpeg" :on-change="handleChange" id="testPasteInput">
							<img alt="img" class="groupImg" v-if="dialogImageUrl == ''" src="../../image/message/甜久群聊默认头像.jpg" />
							<img alt="img" v-else class="groupImg" width="100%" :src="dialogImageUrl">
						</el-upload>
					</div>
				</div>
				<div class="groupSuccess" @click="createGroup()">
					完成
				</div>

			</div>
		</div>
		<div class="popUp_box" v-if="groupShow2">
			<div class="remid_box" style="width: 568px;height:376px;">
				<div class="titleIcon"></div>
				<div class="popLeft" style="margin-top:24px;">
					<div class="flex " style="margin-bottom:16px">
						<div class="" style="width:40%;padding-left:24px; box-sizing: border-box;">
							<div style="margin-top:10px">
								<input v-model="tongModerl" style="width:100%;height:36px;background:rgba(245, 245, 245, 1);border: none;box-sizing: border-box;padding-left:20px;"
									@blur="checkFrends" placeholder="搜索"></input>
							</div>
							<div class="flex" style="margin-top:10px" @click='setRoomUserStateChange("allSpecialEffects")'>
								<img alt="img" v-if="selectAll" class="groupImgPosition" src="../../static/user59.png" />
								<img alt="img" v-else class="groupImgPosition" src="../../static/user60.png" />
								<div style='margin-left:16px' class="addSuccessUser">全选</div>
							</div>
							<div class="popRight" style="margin-top:24px">
								<div class="flex" style="align-items: center;margin-bottom:16px" v-for="(item, key) in tongxuArr" :key='key'>
									<img alt="img" v-if="item.state == 1" class="groupImgPosition" src="../../static/user59.png" @click="setRoomUserStateChange('', item)" />
									<img alt="img" v-if="item.state == 2" class="groupImgPosition" src="../../static/user60.png" @click="setRoomUserStateChange('', item)" />
									<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow = false" />
									<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
								</div>
							</div>
						</div>
						<div style="width:60%;padding-left:24px;box-sizing: border-box;">
							<div class="addSuccessUser">添加成员创建群聊</div>
							<div class="selectPeop" style="margin-top:8px">
								已选中{{ newArrList.length }}人
							</div>
							<div class="popRight" style="margin-top:24px;height:180px">
								<div class="flex" style="align-items: center;margin-bottom:16px;justify-content: space-between;padding-right:24px" v-for="item in newArrList">
									<div class="flex" style="align-items: center;">
										<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow = false" />
										<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
									</div>
									<img alt="img" class="groupImgPosition" style="width:12px;height:12px;cursor: pointer;" src="../../static/user61.png"
										@click="setRoomUserStateChange('', item)" />
								</div>
							</div>
							<div class="flex" style="justify-content: space-between;align-items: center;margin-top:24px">
								<div></div>
								<div class="flex">
									<div class="orderCancel" style="color:#333" @click="groupShow2 = false">取消</div>
									<div class="orderSuccess" style="color:#333" @click="groupShow = true">完成</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 邀请好友加入群聊 -->
		<div class="popUp_box" v-if="groupShow4">
			<div class="remid_box" style="width: 568px;height:376px;">
				<div class="titleIcon"></div>
				<div class="popLeft" style="margin-top:24px;">
					<div class="flex " style="margin-bottom:16px">
						<div class="" style="width:40%;padding-left:24px; box-sizing: border-box;">
							<div style="margin-top:10px">
								<input v-model="tongModerlqun"
									style="width:100%;height:36px;background:rgba(245, 245, 245, 1);border: none;box-sizing: border-box;padding-left:20px;" @blur="tongListtow"
									placeholder="搜索"></input>
							</div>
							<div class="flex" style="margin-top:10px" @click='setRoomUserStateChangetow("allSpecialEffects")'>
								<img alt="img" v-if="selectAlltow" class="groupImgPosition" src="../../static/user59.png" />
								<img alt="img" v-else class="groupImgPosition" src="../../static/user60.png" />
								<div style='margin-left:16px' class="addSuccessUser">全选</div>
							</div>
							<div class="popRight" style="margin-top:24px">
								<div class="flex" style="align-items: center;margin-bottom:16px" v-for="(item, key) in tongxuArrtow" :key='key'>
									<img alt="img" v-if="item.state == 1" class="groupImgPosition" src="../../static/user59.png" @click="setRoomUserStateChangetow('', item)" />
									<img alt="img" v-if="item.state == 2" class="groupImgPosition" src="../../static/user60.png" @click="setRoomUserStateChangetow('', item)" />
									<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow4 = false" />
									<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
								</div>
							</div>
						</div>
						<div style="width:60%;padding-left:24px;box-sizing: border-box;">
							<div class="addSuccessUser">邀请好友进入群聊</div>
							<div class="selectPeop" style="margin-top:8px">
								已选中{{ newArrListtow.length }}人
							</div>
							<div class="popRight" style="margin-top:24px;height:180px">
								<div class="flex" style="align-items: center;margin-bottom:16px;justify-content: space-between;padding-right:24px" v-for="item in newArrListtow">
									<div class="flex" style="align-items: center;">
										<img alt="img" class="userMassageAva" :src="item.avatar" @click="groupShow4 = false" />
										<div style="color:#333;margin-left:8px">{{ item.nickName }}</div>
									</div>
									<img alt="img" class="groupImgPosition" style="width:12px;height:12px;cursor: pointer;" src="../../static/user61.png"
										@click="setRoomUserStateChangetow('', item)" />
								</div>
							</div>
							<div class="flex" style="justify-content: space-between;align-items: center;margin-top:24px">
								<div></div>
								<div class="flex">
									<div class="orderCancel" style="color:#333" @click="groupShow4 = false">取消</div>
									<div class="orderSuccess" style="color:#333" @click="querenyaoqing">完成</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="popUp_box" v-if="gruopShare">
			<div class=" gruopRemid" style="width: 342px;height:512px">
				<!-- 头部start -->
				<div class="title_content" style="border-radius:16px 16px 0 0;">
					<div class="font-14"></div>
					<img alt="img" class="messageText" src="../../static/quit.png" @click="gruopShare = false" />
				</div>
				<div class="popup_conent">
					<div class="sendTou">
						<img alt="img" class="sendImgFlexTou" :src="pitchChatGroup.groupAvatar" />
					</div>
					<div class="sendName" style="margin-top:40px;padding-top:44px">
						{{ pitchChatGroup.groupName }}
					</div>
					<div class="sendId" style="margin-top:8px">
						ID：{{ pitchChatGroup.groupId }}
					</div>
					<div style="width:130px;height:130px;margin:14px auto 0">
						<img alt="img" class="sendImgFlex" :src="wxImg" />
					</div>
					<div class="sendCond" style="margin-top:18px">
						扫一扫二维码，即刻加入群聊
					</div>
		
				</div>
				<div class="flex">
					<div class="proviceBtn" @click='proveImgs(wxImg)'>
						保存本地
					</div>
					<div class="proviceBtn-tow" :data-clipboard-text="`https://pc.t9cp.com/#/home?groupId=${zhuanyi(pitchChatGroup.groupId)}`" @click="fuzhilianjie">
						网页邀请
					</div>
				</div>
				<!-- 头部end -->
			</div>
		</div>

		<!-- 关注分组 -->
		<div class="placingThePopup_bg" v-if="guanzhuShow">
			<div class="placingThePopup_box">
				<div class="titleIcon"></div>
				<div class="title_content">
					<div>设置分组</div>
					<img src="../../static/59.png" alt="" @click.stop="guanzhuShow=false" />
				</div>
				<!-- 内容 -->
				<div class="llacingCont_box">
					<!-- 充值 -->
					<div class="topUp_box1 flex-aling">
						<el-select style="width:100%;" :name="setname" v-model="value" placeholder="请选择">
							<el-option v-for="item in goodsFen" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="topUp_box1Btn" @click="followUsertow(value)">确认</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import ClipboardJS from 'clipboard';
	import ScreenShort from "js-web-screen-shot";
	// import { JSONStringify } from "lib/tool";
	import othersHome from "./othersHome.vue";
	import html2canvas from 'html2canvas'
	import emoji from '../chatRoom/components/emoji.js'
	// import TencentCloudChat from '@tencentcloud/chat';


	export default {
		name: "",
		props: {
			groupIds:{
				type:[Number,String],
				default:''
			}
		},
		components: {
			othersHome
		},
		data() {
			return {
				longPressTimer:'',
				popconfirmVisible:'',
				guanzhuShow: '',
				options: [],
				value: "",
				tongModerlqun: '',
				userGroupData: "",
				msgDetailpop: false, //消息详情弹窗
				msgDetailinfo: null, //消息详情
				changeName: true,
				tabUserImg: true,
				gruopShare: false,
				groupSize: false,
				groupShow: false, //创建群聊2
				groupShow2: false, //创建群聊1
				groupShow3: false,
				groupShow4: false,
				groupShowContent: false,
				type3Data: {},
				skillDetails: {
					levelId: "",
				},
				imgType: 1,
				gradeList: [],
				complainText: "",
				complainArr: [],
				popUp_message: false,
				othersShow: false,
				renewalShow: false, //续约弹窗
				msgId: "", //消息id
				tabType: 0,
				groupDetail: false,
				groupUserVip: false,
				failChat: false, //文件列表
				frendsDetail: false,
				manitoId: "", //大神ID
				newArrList: [],
				newArrListtow: [],
				time: {
					hours: 0,
					minutes: 0,
					seconds: 0
				},
				informList: [{
						imgUrl: require("../../image/message/6.png"),
						name: "狗书公告",
					},
					{
						imgUrl: require("../../image/message/8.png"),
						name: "系统通知",
					},
					{
						imgUrl: require("../../image/message/9.png"),
						name: "订单消息",
					},
					{
						imgUrl: require("../../image/message/10.png"),
						name: "派单信息",
					},
					{
						imgUrl: require("../../image/message/11.png"),
						name: "互动消息",
					},
				],
				dialogImageUrl: "https://tjj-sever.oss-cn-beijing.aliyuncs.com/groupDefaultImg.jpg",
				dialogVisible: false,
				tongMassage: 0,
				isBackCall: false,
				isCall: false,
				files1: "",
				atPresent: "",
				isCallShoping: false,
				groupDetailUser: false,
				atuserObj: {}, //当前聊天用户
				newestMessage: [], //最新消息
				pitchMessageType: {}, //选中消息类型
				messageList: [], //消息数据
				userChatType: false, //用户聊天
				isTabs: true, //是否显示点三个
				userChatTypeChild: false,
				groupChatChild: false,
				groupType: false, //群组聊天
				chatText: "", //输入内容
				dialogueList: [], //会话列表
				attentionlist: [], //关注&&粉丝列表
				pitchChatUser: {}, //选中用户数据
				pitchChatGroup: {}, //选中群组数据
				chatList: [], //聊天内容
				chatListGroup: [], //群组聊天
				myUser: {}, //我的数据
				clearTheChatShowGroup: false, //清除聊天记录弹窗
				clearTheChatShowUser: false,
				complaintShow: false, //投诉弹窗
				blockShow: false, //拉黑提醒弹窗
				selectFrends: false, //选择好友
				selectFrendstow:false, //群聊选好友
				remindShow: false, //双方撤回
				isGuan: false, //分组弹窗
				reportShow: false, //举报弹窗
				reportOptions: [], //举报原因
				reportValue: "", //举报内容
				complainData: {
					content: "",
					complainId: "",
					type: "",
					name: "",
					imageEvidence: "",
					complainObjectId: "",
					userId: "",
					nickName: "",
				},
				reportTextarea: "", //举报说明
				selectAll: true,
				selectAlltow: true,
				reportImgs: [], //图片
				manitoSkillData: {}, //大神技能数据
				defaultProps: {
					children: "children",
					label: "nickname",
				},
				imgEdit:false,
				orderNum: 1, //订单数量
				platformNum: 0, //平台消息未读数
				systemNum: 0, //系统通知 未读数
				orderNumUnread: 0, //订单消息未读数
				sendOrdersNum: 0, //派单消息未读数
				interactionNum: 0, //互动消息未读数
				guaduanIsSHow: false,
				isAudio: false,
				siValue1: true,
				isSet: false,
				siValue2: false,
				goodsFen: [],
				setname: '',
				setnames: '',
				tongModerl: "",
				FenValue: "",
				audioArr: [],
				audioValue: "",
				videoArr: [],
				videoValue: "",
				shortVideoArr: [],
				shortVideoValue: "",
				isShopIng: false,
				groupContent: "",
				isShopingBei: {},
				groupListData: [],
				isflagJie: false,
				courentTime: "",
				h: 0, //定义时，分，秒，毫秒并初始化为0；
				m: 0,
				ms: 0,
				s: 0,
				timeClear: "",
				isSendShiping: false,
				tongxuluSearch: "",
				tousuShow: false, //好友显示
				tongxuArr: [],
				tongxuArrtow: [],
				shareArr: [],
				groupArr: [],
				groupArrList: [],
				friendsArr: [],
				bookData: [],
				disturbUser: false,
				disturbGroup: false,

				intoAddressBook: 0,
				groupData: {
					groupName: "",
					groupAvatar: "",
					groupId: "",
				},
				disturbZhiDing: false,
				managerArr: [],
				nickname: '',
				deleteManageShow: false,
				deleteData: {},
				groupDataDetail: {},
				contentData: {
					avatar: "",
					id: "",
					nickname: "",
				},
				userListGroup: [],
				userListGrouptow: [],
				usernickname: '',
				chatFileGroup: [],
				wxImg: "",
				shuangxiangchehui: 0,
				shexiangtouis: true,
				isjieting: false,
				capTureImg: false,
				showEmoji: false,
				emojiList: emoji,
				listid:'',
				haoyouid:'',
				RmoteList: [],
				oldv:0,
				oldvtow:0,
				isShow:false,
				isHide:false,
				qunName:'',
				CutuserName:'',
				UserNichengstate:true,
				isMyorYou:false
			};
		},
		computed: {
			formattedTime() {
				const hoursStr = String(this.time.hours).padStart(2, '0');
				const minutesStr = String(this.time.minutes).padStart(2, '0');
				const secondsStr = String(this.time.seconds).padStart(2, '0');
				return `${hoursStr}:${minutesStr}:${secondsStr}`;
			}
		},
		watch: {
			groupDetail(e){
				if(e == false){
					this.UserNichengstate = true;
				}
			},
			frendsDetail(e){
				if(e == false){
					this.UserNichengstate = true;
				}
			},
			chatListGroup(list){
				if (list) {
				 setTimeout(() =>{
					this.$nextTick(() => {
					  var bady = document.getElementById('myElementgroup') 
					  bady.onscroll = () => {
						// 获取距离顶部的距离
						var scrollTop = bady.scrollTop
						if (scrollTop !== 0 && this.oldvtow == 0) {
						  this.oldv = scrollTop
						}
						// 获取可视区的高度
						var windowHeight = bady.clientHeight
						// 获取滚动条的总高度
						var scrollHeight = bady.scrollHeight
						if (scrollTop + windowHeight >= scrollHeight) {
						  console.log('到达底部了')
						}
						console.log(scrollTop,this.oldvtow)
						if (scrollTop == 0) {
						  console.log('到达顶部了11')
						  this.getMsgChagorutlist();
						}
					  }
					})
				 },100)
				}
			},
			chatList(list) {
			  if (list) {
				this.$nextTick(() => {
				  var bady = document.getElementById('myElement') 
				  bady.onscroll = () => {
					// 获取距离顶部的距离
					var scrollTop = bady.scrollTop
					if (scrollTop !== 0 && this.oldv == 0) {
					  this.oldv = scrollTop
					}
					// 获取可视区的高度
					var windowHeight = bady.clientHeight
					// 获取滚动条的总高度
					var scrollHeight = bady.scrollHeight
					if (scrollTop + windowHeight >= scrollHeight) {
					  console.log('到达底部了')
					}
					if (scrollTop == 0 && this.oldv !== 0) {
					  console.log('到达底部了11')
					  this.getMsgChatlist();
					}
				  }
				})
			  }
			}
		},
		created() {
		
			let TencentCloudChat = this.TencentCloudChat;
			let tuiCallEngine = this.tuiCallEngine;
			let TUICallEvent = this.TUICallEvent;
			let that = this;
			//回显私信
			let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
			// chatListGroup
			let onMessageReceived = function(event) {
				// event.data - 存储 Message 对象的数组 - [Message]
				const messageList = event.data;
				messageList.forEach((message) => {
					that.getConversationList();
					console.log(message, '接收到的聊天消息')
					if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
						// 文本消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.TextPayload
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}

						}
					} else if (message.type === TencentCloudChat.TYPES.MSG_IMAGE) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 图片消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.ImagePayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_SOUND) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 音频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.AudioPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_VIDEO) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 视频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.VideoPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_FILE) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 文件消息 和群消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.FilePayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_CUSTOM) {
						
						console.log(JSON.parse(message.payload.data),'?????????????')
						
						// 群员撤回聊天记录
						if (JSON.parse(message.payload.data).businessID == 'clear_group_msg' && JSON.parse(message.payload.data).data.userType != 1) {
							that.qunyuanchehui(message.conversationID);
						}
						
						console.log('收到自定义消息')
						// 群主撤回聊天记录
						if (JSON.parse(message.payload.data).businessID == 'clear_group_msg' && JSON.parse(message.payload.data).data.userType == 1) {
							that.qunzhuchehui(message.conversationID);
						}

						// 对方撤回聊天记录
						if (JSON.parse(message.payload.data).businessID == 'C2C_revocationMsgList' && JSON.parse(message.payload.data).fromUserId == that.pitchChatUser
							.id) {
							that.deleteChattingUser(1);
						}

						// 创建群成功
						if (JSON.parse(message.payload.data).businessID == 'group_create' && JSON.parse(message.payload.data).version == 4) {
							console.log('接受到创建群')
							if (that.tabType == 0) {
								that.getConversationList(); //获取会话列表
							}
						}
						// 是否开启双向撤回
						if (JSON.parse(message.payload.data).msgType == 31 && JSON.parse(message.payload.data).content) {
							if (JSON.parse(message.payload.data).msgType == 31 && JSON.parse(JSON.parse(message.payload.data).content).status == 1) {
								that.shuangxiangchehui = 1;
								that.chehuixiaoxicaozuo(1, message.to);
							}
							if (JSON.parse(message.payload.data).msgType == 31 && JSON.parse(JSON.parse(message.payload.data).content).status == 2) {
								that.shuangxiangchehui = 0;
								that.chehuixiaoxicaozuo(2, message.to);
							}
							that.scrollToBottom();
						}

						// 收到开启撤回消息
						if (JSON.parse(message.payload.data).msgType == 21) {
							console.log(that.myUser, '??????????')
							that.isMyorYou = true;
							that.$confirm('对方邀请您“开启双向撤回”功能，确定要开启吗?', '提示', {
								confirmButtonText: '同意',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								if(that.myUser.vipFlag == 1){
									that.sendMsgNewtow('1')
									that.shuangxiangchehui = 1;
									that.chehuixiaoxicaozuo(1, message.to);
								}else{
									that.remindShow = true;
									that.shuangxiangchehui = 0;
								}
								
								// 确定
							}).catch(() => {
								// 取消
								that.sendMsgNewtow('2')
								that.shuangxiangchehui = 0;
								that.isMyorYou = false;
								that.chehuixiaoxicaozuo(2, message.to);
							});
							that.scrollToBottom();
						}
						if (JSON.parse(message.payload.data).businessID) {
							if (JSON.parse(message.payload.data).businessID != 'C2C_revocationMsgList') {
								if (message.conversationType == 'C2C') {
									if ('C2C' + that.pitchChatUser.id == message.conversationID) {
										that.chatList.push(message);
										that.scrollToBottom();
									}
									// 如果是电话把对方信息存入本地
									if (JSON.parse(message.payload.data).businessID == '1') {
										localStorage.setItem('phoneUserinfo', JSON.stringify(message))
									}
									// 给别人打电话，对方已接听
									if (JSON.parse(message.payload.data).businessID == '1' && JSON.parse(message.payload.data).actionType == 3) {
										that.time = {
											hours: 0,
											minutes: 0,
											seconds: 0
										}
										that.startTimer()
										that.isAudio = true;
										that.isjieting = true;
										that.kaiqishexiangtou();
									}

									// 给别人打电话，对方挂断
									if (JSON.parse(message.payload.data).businessID == '1' && JSON.parse(message.payload.data).actionType == 4) {
										that.isCall = false;
										that.isShopIng = false;
										that.stopTimer();
										that.isjieting = false;
									}
									// 给别人打电话，对方挂断
									if (JSON.parse(message.payload.data).businessID == '1' && JSON.parse(message.payload.data).actionType == 5) {
										that.isCall = false;
										that.isShopIng = false;
										that.stopTimer();
										that.isjieting = false;
									}
									that.scrollToBottom();
								} else {
									if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
										that.chatListGroup.push(message);
										that.scrollToBottom();
									}
								}
							}
						} else {
							if (message.conversationType == 'C2C') {
								if ('C2C' + that.pitchChatUser.id == message.conversationID) {
									that.chatList.push(message);
									that.scrollToBottom();
								}
								that.scrollToBottom();
							} else {
								if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
									that.chatListGroup.push(message);
									that.scrollToBottom();
								}
							}
						}
						// 自定义消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.CustomPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_MERGER) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
							that.scrollToBottom();
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 合并消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.MergerPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_LOCATION) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 地理位置消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.LocationPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_GRP_TIP) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 群提示消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupTipPayload
					} else if (message.type === TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE) {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 群系统通知 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupSystemNoticePayload
					} else if (message.type == 'TIMGroupTipElem') {
						if (message.conversationType == 'C2C') {
							if ('C2C' + that.pitchChatUser.id == message.conversationID) {
								that.chatList.push(message);
								that.scrollToBottom();
							}
						} else {
							if ('GROUP' + that.pitchChatGroup.groupId == message.conversationID) {
								that.chatListGroup.push(message);
								that.scrollToBottom();
							}
						}
						// 群系统通知 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupSystemNoticePayload
					}
				});
			};
			this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
			let onConversationListUpdated = function(event) {
				// console.log(event.data, "会话列表的监听"); // 包含 Conversation 实例的数组
			};
			this.chat.on(
				TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED,
				onConversationListUpdated
			);
			this.getShiping(); //监听用户是否开启摄像头
		},
		mounted() {
			// this.tabType = 0;
			// this.tabTypeChange(0)
			this.listenttuiCallgua();
			this.listenttuiCallNo();
			this.tongList();
			this.complainList();
			this.queryLastMessage(); //最新消息
			this.pitchMessageType = this.informList[0];
			this.getUserInfo();
			this.getConversationList(); //获取会话列表
			this.queryReportReason();
			this.getUnreadCount();
			this.getChargesListShi();
			this.getFriendGroupList();
			this.userCollectRmoteList()
		},
		destroyed() {
			// this.$RongIMLib.removeEventListener(Events.MESSAGES, () => {});
		},
		destroyed() {
			// 页面销毁
			// 移除具体事件的所有监听器
			// this.$RongIMLib.removeEventListeners(Events.MESSAGES);
			// this.$RongIMLib.removeChatRoomEntry(Events.MESSAGES);
		},
		methods: {
			// 关闭撤回
			guanbichehui(){
				this.remindShow = false;
				if(this.isMyorYou == true){
					this.sendMsgNewtow('2')
					this.shuangxiangchehui = 0;
					this.chehuixiaoxicaozuo(2, this.atuserObj.userProfile.userID);
				}
			},
			
			isValidURL(string) {
			    try {
			      new URL(string);
			      return true;
			    } catch (e) {
			      return false;
			    }
			  },
			
			// 获取单聊聊天记录
			getMsgChatlist(){
				let that = this;
				if(that.chatList.length && !that.isHide){
					let promise = that.chat.getMessageListHopping({conversationID:that.atuserObj.conversationID, time:that.chatList[0].time, count: 15, direction: 0});
					promise.then(function(imResponse) {
					  const { messageList, isCompleted, nextMessageTime } = imResponse.data;
					  console.log(messageList,isCompleted,'单聊聊天记录')
					  if(messageList.length && that.chatList.length >= 15){
						  that.chatList = [...messageList, ...that.chatList];
					  }
					  if(isCompleted == true){
						that.isHide = true;
					  }else{
						that.isHide = false;
					  }
					  if(messageList.length < 15){
					  	that.isHide = true;
					  }
					});
				}
			},
			
			// 获取群聊聊天记录
			getMsgChagorutlist(){
				let that = this;
				console.log(that.chatListGroup[0])
				if(that.chatListGroup.length && !that.isHide){
					let promise = that.chat.getMessageListHopping({conversationID:"GROUP" + that.groupContent.groupID, sequence:that.chatListGroup[0].sequence, count: 15, direction: 0});
					promise.then(function(imResponse) {
					  const { messageList, isCompleted, nextMessageTime } = imResponse.data;
					  console.log(messageList,isCompleted,'群聊聊天记录',nextMessageTime)
					  if(messageList.length && that.chatListGroup.length >= 15){
						  that.chatListGroup = [...messageList, ...that.chatListGroup];
						  that.chatListGroup = [...new Set(that.chatListGroup)];
					  }
					  if(isCompleted == true){
						that.isHide = true;
					  }else{
						that.isHide = false;
					  }
					  if(messageList.length < 15){
					  	that.isHide = true;
					  }
					});
				}
			},

			 formatString(str) {
			  if (str.length <= 10) {
				return str; // 如果字符串长度小于或等于10，直接返回原字符串
			  } else {
				return str.substring(0, 5) + '...' + str.substring(str.length - 5); // 返回前5个字符，加上"..."，再接后5个字符
			  }
			},
			getFileNameFromUrl(url) {
				return url.substring(url.lastIndexOf('/') + 1);
			},
			deleteMyImg(row){
				this.$api
					.userCollectRmoteDelete({
						id: row.id,
					})
					.then((res) => {
						if (res.code == 1) {
							this.$message("删除成功");
							this.userCollectRmoteList()
						}
					});
			},
			sendimgDom(url, type) {
				if(this.imgEdit){
					return false
				}
				fetch(url)
					.then(response => response.blob())
					.then(blob => {
						// 或者从URL解析文件名，例如通过正则表达式匹配最后的路径部分
						const fileName = this.getFileNameFromUrl(url);
						const file = new File([blob], fileName, {
							type: blob.type, // 例如 'image/jpeg'
							lastModified: Date.now() // 文件的最后修改时间，可选
						});
						if (type == 1) {
							this.sendimg(url, file)
						}
						if (type == 2) {
							this.sendimgGroup(url, file)
						}
					})
					.catch(error => {});
			},
			handleAvatarSuccessImg(res) {
				this.userCollectRmote(res.data.url)
			},
			// 收藏
			userCollectRmote(url) {
				this.$api
					.userCollectRmote({
						url: url,
					})
					.then((res) => {
						if (res.code == 1) {
							this.$message("添加成功");
							this.userCollectRmoteList()
						}
					});
			},
			// 获取收藏表情列表
			userCollectRmoteList() {
				this.$api
					.userCollectRmoteList({
						pageNum: 1,
						pageSize: 100
					})
					.then((res) => {
						if (res.code == 1) {
							this.RmoteList = res.data.rows;
						}
					});
			},

			renderEmoji(text) {
				text = text.replace(/\r\n/g, '<br/>'); //IE9、FF、chrome
				text = text.replace(/\n/g, '<br/>'); //IE7-8
				// 定义表情映射
				const emojiMap = {};
				this.emojiList.forEach(e => {
					emojiMap[e] = require(`../../assets/emoji/${e}@2x.png`);
				})
				// 生成正则表达式
				const emojiRegex = new RegExp(
					Object.keys(emojiMap)
					.map(emoji => emoji.replace(/\[/g, '\\[').replace(/\]/g, '\\]'))
					.join('|'),
					'g'
				);
				// 替换函数
				function replaceEmojis(text) {
					return text.replace(emojiRegex, (match) => "<img style='height:20px;width:20px;' src='" + emojiMap[match] + "'/>" || match);
				}
				return replaceEmojis(text);
			},
			insertEmoji(faceName) {
				this.chatText += faceName;
				this.showEmoji = false;
			},
			// 设置分组
			followUsertow(value) {
				if (!value) {
					this.$message("请选择分组");
					return;
				}
				this.$api
					.setHaoyoufenzu({
						targetUserId: this.pitchChatUser.id,
						groupId: value
					})
					.then((res) => {
						if (res.code == 1) {
							this.$message.success("设置成功");
						}
					});
				this.goodsFen.forEach((item) => {
					if (item.value == value) {
						this.setnames = item.groupingName
					}
				});
				this.guanzhuShow = false;
			},


			// 邀请加入群聊
			querenyaoqing() {
				let arr = "";
				let that = this;
				this.newArrListtow.forEach((item) => {
					arr += item.userId + ",";
				});
				let groupData = {
					groupId: this.pitchChatGroup.groupId,
					userIds: arr,
				};
				if (this.newArrListtow.length == 0) {
					this.$message("暂无选择好友");
					return;
				}

				this.$api.yaoqingchengyuan(groupData).then((res) => {
					if (res.code == 1) {
						this.groupShow4 = false;
						this.frendsDetail = false;
						this.newArrListtow = [];
					} else {
						this.$message(res.msg);
					}
				});
			},

			// 群聊点击截图
			jietutow() {
				const screenShotHandler = new ScreenShort({
					// 是否启用webrtc，值为boolean类型，值为false则使用html2canvas来截图
					enableWebRtc: false,
					// 层级级别，这个要比你页面上其他元素的z-index的值大，不然截不了
					level: 999999,
					completeCallback: this.callbacktow, // 截图成功完成的回调
					closeCallback: this.closeFn, // 截图取消的回调
					useCORS: true,
					allowTaint: true,
					loadCrossImg: true
				})
			},


			// 截图完成
			callbacktow(base64data) {
				var image = new Image()
				image.src = base64data
				image.onload = () => {
					var canvas = this.convertImageToCanvas(image)
					var url = canvas.toDataURL('image/jpeg') // base64编码的图片
					// 通过atob将base64进行编码
					var bytes = window.atob(url.split(',')[1])
					// 处理异常，将ASCII码小于0的转换为大于0,进行二进制转换
					var buffer = new ArrayBuffer(bytes.length)
					// 生成一个8位数的数组
					var uint = new Uint8Array(buffer)
					for (var j = 0; j < bytes.length; j++) {
						uint[j] = bytes.charCodeAt(j) // 根据长度返回相对应的Unicode 编码
					}
					// Blob对象  // type 为图片的格式
					var imageFile = new Blob([buffer], {
						type: 'image/jpeg'
					})
					const formData = new FormData()
					// 上传的参数名是file，数据是该图片的blob对象，文件名是当前时间戳.jpeg；
					//如果不指定第三个参数文件名，那么默认文件名就是blob，没有后缀，后端的上传接口如果要截取文件名的话会出问题
					formData.append('file', imageFile, Date.now() + '.jpeg')
					// console.log(formData.get('file'),'ssxs')
					// 我这儿是上传到某个地方了，上传地址是在data中定义的uploadRequestUrl
					this.$api.fileUploadAli(formData)
						.then((res) => {
							if (res.code == 1) {
								this.files1 = formData.get('file');
								this.sendimgGroup();
							}
						});
				}
			},

			// 点击截图
			jietu() {
				const screenShotHandler = new ScreenShort({
					// 是否启用webrtc，值为boolean类型，值为false则使用html2canvas来截图
					enableWebRtc: false,
					// 层级级别，这个要比你页面上其他元素的z-index的值大，不然截不了
					level: 999999,
					completeCallback: this.callback, // 截图成功完成的回调
					closeCallback: this.closeFn, // 截图取消的回调
					useCORS: true,
					allowTaint: true,
					loadCrossImg: true
				})
			},


			// 截图完成
			callback(base64data) {
				var image = new Image()
				image.src = base64data
				image.onload = () => {
					var canvas = this.convertImageToCanvas(image)
					var url = canvas.toDataURL('image/jpeg') // base64编码的图片
					// 通过atob将base64进行编码
					var bytes = window.atob(url.split(',')[1])
					// 处理异常，将ASCII码小于0的转换为大于0,进行二进制转换
					var buffer = new ArrayBuffer(bytes.length)
					// 生成一个8位数的数组
					var uint = new Uint8Array(buffer)
					for (var j = 0; j < bytes.length; j++) {
						uint[j] = bytes.charCodeAt(j) // 根据长度返回相对应的Unicode 编码
					}
					// Blob对象  // type 为图片的格式
					var imageFile = new Blob([buffer], {
						type: 'image/jpeg'
					})
					const formData = new FormData()
					// 上传的参数名是file，数据是该图片的blob对象，文件名是当前时间戳.jpeg；
					//如果不指定第三个参数文件名，那么默认文件名就是blob，没有后缀，后端的上传接口如果要截取文件名的话会出问题
					formData.append('file', imageFile, Date.now() + '.jpeg')
					// console.log(formData.get('file'),'ssxs')
					// 我这儿是上传到某个地方了，上传地址是在data中定义的uploadRequestUrl
					this.$api.fileUploadAli(formData)
						.then((res) => {
							if (res.code == 1) {
								this.files1 = formData.get('file');
								this.sendimg();
							}
						});
				}
			},

			// 截图取消
			closeFn() {

			},

			convertImageToCanvas(image) {
				var canvas = document.createElement('canvas')
				canvas.width = image.width
				canvas.height = image.height
				canvas.getContext('2d').drawImage(image, 0, 0)
				return canvas
			},
			isShowChehuiBtn(item){
				
				console.log(item,'item.time()')
				const date = new Date(item.time*1000).toLocaleString();
				const currentTimestamp = Date.now()
				const twoMinutesInMillis = 2 * 60 * 1000;

				if (currentTimestamp - item.time*1000 > twoMinutesInMillis) {
  				console.log("目标时间戳是 2 分钟之前的时间",date);
					return false
				} else {
					return true
  				console.log("目标时间戳不是 2 分钟之前的时间",date);
				}
			},
			timeComparison(data) {
      const timestampDate = new Date(data);
      const now = new Date();
      if (timestampDate > now) {
        return '时间戳在未来';
      } else if (timestampDate < now) {
        return '时间戳在过去';
      } else {
        return '时间戳就是现在';
      }
    },

			// 开启摄像头
			kaiqishexiangtou() {
				this.tuiCallEngine
					.openCamera('localView')
					.then((res) => {
						// success
						this.shexiangtouis = true;
						// console.log(res, "console.log(res);console.log(res);");
					})
					.catch((error) => {
						console.warn("openCamera error:", error);
					});
			},

			// 远端用户是否开启视频
			getShiping() {
				let that = this;
				let handleUserVideoChange = function(event) {
					if (event.isVideoAvailable) {
						console.log(event, "开启了摄像头");
						that.xuanran(event.userID);
					} else {
						console.log(event, "没开摄像头");
					}
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.USER_VIDEO_AVAILABLE,
					handleUserVideoChange
				);
			},
			// 渲染视频
			xuanran(user1) {
				let options = {
					objectFit: "contain",
				};
				let promise = this.tuiCallEngine.startRemoteView({
					userID: user1,
					videoViewDomID: "video_1",
					options,
				});
				promise
					.then(() => {
						// success
						console.log("ssssssssss");
					})
					.catch((error) => {
						console.warn("startRemoteView error:", error);
					});
			},

			startTimer() {
				this.time = {
					hours: 0,
					minutes: 0,
					seconds: 0
				}
				this.timer = setInterval(() => {
					this.incrementTime();
				}, 1000);
			},
			stopTimer() {
				if (this.timer) {
					clearInterval(this.timer);
					this.timer = null;
				}
			},
			incrementTime() {
				this.time.seconds++;
				if (this.time.seconds >= 60) {
					this.time.seconds = 0;
					this.time.minutes++;
					if (this.time.minutes >= 60) {
						this.time.minutes = 0;
						this.time.hours++;
					}
				}
			},

			// 跳转页面
			gotomap(item) {
				window.open(
					`https://uri.amap.com/navigation?to=${item.longitude},${item.latitude},${item.title},${item.address}&mode=car&policy=0&src=mypage&coordinate=gaode&callnative=1`
				)
				window.open(url, '_blank'); // 在新标签页打开
			},
			chehuiBtn(item,index,event){
				let that=this
				event.preventDefault(); // 阻止浏览器默认行为
				this.longPressTimer = setTimeout(() => {
					
					that.showPopconfirm(index)
				
      }, 1000); // 长按1秒后触发
				
			},
			endLongPress() {
      clearTimeout(this.longPressTimer);
    },
		showPopconfirm(index) {
			console.log(this.$refs.popconfirm[9],'this.$refs.popconfirm')
      this.$refs.popconfirm[index].visible=true; // 手动调用 Popconfirm 的 show 方法
    },
    handleConfirm(item) {
			let that=this
      this.popconfirmVisible = false;
			let promise = this.chat.revokeMessage(item);
				promise.then(function(imResponse) {
  // 消息撤回成功
				console.log('撤回成功',item);
				}).catch(function(imError) {
					console.log('撤回失败',item);
  // 消息撤回失败
	that.$message('消息时长已超过2分钟无法撤回');
				console.log('撤回失败',item);
  			// console.warn('revokeMessage error:', imError);
			});
    },
    handleCancel() {
      this.popconfirmVisible = false;
      console.log('取消操作');
    },
			// 照片墙删除
			delImg(item, i) {
				this.complainArr.splice(i, 1)
			},
			// 下载文件
			xiazai(url) {
				if(typeof jsBridge == 'undefined'){
					window.open(url, '_blank');
				}else{
					jsBridge
					  .openInBrowser({
					    url:url,
					  })
					  .then((res) => {
					    if (res.success) {
					      console.log('在浏览器打开成功');
					    }
					});
				}
			},
			//打开消息详情
			getMessagedetail(item) {
				//如果是平台公告
				if (item.type == 1) {
					this.$api.queryMassageById({
							id: item.id
						})
						.then((res) => {
							if (res.code == 1) {
								this.msgDetailpop = true
								this.msgDetailinfo = res.data;
								console.log(this.msgDetailinfo)
							} else {
								alert(res.msg);
							}
						});
				}
			},
			
			// 退出群聊
			tuichuqunliao(){
				let indexs = 0;
				let that = this;
				this.dialogueList.forEach((item,index) =>{
					if(item.conversationID == ('GROUP' + this.pitchChatGroup.groupId)){
						indexs = index;
					}
				})
				this.$confirm('确定要退出群聊吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					// 删除单一会话, 并清空会话历史消息
					let promise = this.chat.deleteConversation('GROUP' + this.pitchChatGroup.groupId);
					promise.then(function(imResponse) {
					  // 删除会话成功
					  that.$api
					  	.posttuichuqunliao({
					  		groupId: that.pitchChatGroup.groupId
					  	})
					  	.then((res) => {
					  		if (res.code == 1) {
					  			that.isTabs = false;
					  			that.groupChatChild = false;
					  			that.dialogueList.splice(indexs, 1);
					  			setTimeout(() =>{
					  				that.tabTypeChange(0);
					  			},100)
					  		} else {
					  			alert(res.msg);
					  		}
					  	});
					  const { conversationID } = imResponse.data; // 被删除的会话 ID
					}).catch(function(imError) {
					  console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
					});
				})
			},

			// 解散群聊
			jiesanqunliao() {
				let that = this;
				this.$confirm('确定要解散群聊吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					// 删除单一会话, 并清空会话历史消息
					let promise = this.chat.deleteConversation('GROUP' + this.pitchChatGroup.groupId);
					promise.then(function(imResponse) {
					  // 删除会话成功
					  that.$api
					  	.jiesanqunliaot({
					  		groupId: that.pitchChatGroup.groupId
					  	})
					  	.then((res) => {
					  		if (res.code == 1) {
					  			that.$message({
					  				type: 'success',
					  				message: '群聊已解散'
					  			});
					  			that.chat.deleteConversation('GROUP' + that.pitchChatGroup.groupId);
					  			that.isTabs = false;
					  			that.groupChatChild = false;
					  			that.getConversationList();
					  			that.tabTypeChange(0)
					  		} else {
					  			alert(res.msg);
					  		}
					  	});
					  const { conversationID } = imResponse.data; // 被删除的会话 ID
					}).catch(function(imError) {
					  console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
					});
				})
			},

			// 获取群组用户
			userGroupList() {
				this.$api
					.getMemberList({
						groupId: this.pitchChatGroup.groupId,
						pageNo: 1,
						pageSize: 100,
					})
					.then((res) => {
						if (res.code == 1) {
							console.log(res, "resres");
							this.userListGroup = res.data.records;
						} else {
							alert(res.msg);
						}
					});
			},

			// 获取群组用户
			userGroupListtow() {
				this.$api
					.getMemberList({
						groupId: this.pitchChatGroup.groupId,
						pageNo: 1,
						pageSize: 200,
						showLeader: this.pitchChatGroup.userIdentity == 2 ? '1' : '0',
						showManager: (this.pitchChatGroup.userIdentity == 0 || this.pitchChatGroup.userIdentity == 2) ? '1' : '0',
						nickname: this.usernickname
					})
					.then((res) => {
						if (res.code == 1) {
							this.userListGrouptow = res.data.records;
						} else {
							alert(res.msg);
						}
					});
			},

			getShareGroups() {
				this.$api
					.getShareGroup({
						groupId: this.pitchChatGroup.groupId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wxImg = "data:image/png;base64," + res.data.qrCodeUrl;

							console.log(res, "resres");
						} else {
							alert(res.msg);
						}
					});
			},
			changeGroupName(data) {
				this.changeName = !this.changeName;
				if (this.changeName && this.pitchChatGroup.groupName != this.qunName) {
					this.$api
						.xiugaiqunmingcheng({
							groupId:data.groupId,
							groupName:this.qunName
						})
						.then((ress) => {
							if (ress.code == 1) {
								this.pitchChatGroup.groupName = this.qunName;
							} else {
								alert(ress.msg);
							}
						});
				}
			},
			searchChange() {
				this.tongList();
			},

			// 获取单聊聊天文件
			getChatFileRecordGrouptow(toUserId, fromUserId) {
				console.log(fromUserId, toUserId)
				this.failChat = true;
				this.frendsDetail = false;
				this.groupDetail = false;
				let data = {
					type: 1,
					fromUserId,
					toUserId,
					pageNum: 1,
					pageSize: 100,
				};
				this.$api.chatFileRecord(data).then((res) => {
					if (res.code == 1) {
						console.log(res, "resresrsesres");
						this.chatFileGroup = res.data.rows;
					} else {
						alert(res.msg);
					}
				});
			},

			// 获取聊天文件
			// chatFileRecord
			getChatFileRecordGroup() {
				this.failChat = true;
				this.frendsDetail = false;
				this.groupDetail = false;
				let data = {
					type: 2,
					groupId: this.pitchChatGroup.groupId,
					pageNum: 1,
					pageSize: 100,
				};
				this.$api.chatFileRecord(data).then((res) => {
					if (res.code == 1) {
						console.log(res, "resresrsesres");
						this.chatFileGroup = res.data.rows;
					} else {
						alert(res.msg);
					}
				});
			},

			// 设为管理员
			sheweiguanliyuan(item) {
				this.$confirm('确定要将' + item.nickname + '设为管理员吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.setUserAdmin({
						groupId: item.groupId,
						userIds: item.userId
					}).then((res) => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								message: '设置成功!'
							});
							this.userGroupList();
							this.userGroupListtow();
						} else {
							alert(res.msg);
						}
					});
				})
			},

			// 转让群主
			zhuanrangqunzu(item) {
				this.$confirm('确定要将群主转让给' + item.nickname + '吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.zhuanrangqunzhu({
						groupId: item.groupId,
						otherUserId: item.userId
					}).then((res) => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								message: '已发起转让申请，请等待用户处理'
							});
							this.groupUserVip = false;
							this.frendsDetail = false;
						} else {
							alert(res.msg);
						}
					});
				}).catch(() => {

				});
			},

			// 确定删除群成员吗
			removeruser(item) {
				this.$confirm('确定要将' + item.nickname + '踢出群聊吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.useryichu({
						groupId: item.groupId,
						userId: item.userId
					}).then((res) => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								message: '踢出成功!'
							});
							this.userGroupList();
							this.userGroupListtow();
						} else {
							alert(res.msg);
						}
					});
				}).catch(() => {

				});
			},

			// 取消管理员
			decalManage(item) {
				let groupData = {
					groupId: item.groupId,
					othUserId: item.userId,
				};
				this.$confirm('确定取消' + item.nickname + '的管理员吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.outControl(groupData).then((res) => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								message: '取消成功!'
							});
							this.groupUserArr();
							this.userGroupList();
							this.userGroupListtow();
						} else {
							alert(res.msg);
						}
					});
				})
			},
			
			// 修改我的群昵称
			xiugmyqunnicheng(){
				this.UserNichengstate = !this.UserNichengstate;
				if(this.UserNichengstate){
					let groupData = {
						groupId: this.pitchChatGroup.groupId,
						nickname:this.CutuserName
					};
					
					this.$api.xiugqunchengyaunnicheng(groupData).then((res) => {
						if (res.code == 1) {
							this.userGroupListtow();
							this.userGroupList();
							this.pitchChatGroup.userNickName = this.CutuserName;
							this.UserNichengstate = true;
						} else {
							alert(res.msg);
						}
					});
				}
			},
			
			// 是否显示群成员昵称
			cutshowNicknameStatus(e){
				let groupData = {
					groupId: this.pitchChatGroup.groupId,
					status:e
				};
				
				this.$api.sfxianshiqunnicheng(groupData).then((res) => {
					if (res.code == 1) {
						this.userGroupListtow();
						this.userGroupList();
					} else {
						alert(res.msg);
					}
				});
			},
			
			intoAddressBookChange(e) {
				this.intoAddressBook = e;
				let groupData = {
					groupId: this.pitchChatGroup.groupId,
				};

				this.$api.intoAddressBook(groupData).then((res) => {
					if (res.code == 1) {
						if (e == 1) {
							this.$message.success("加入成功");
						}
						if (e == 0) {
							this.$message("取消通讯录");
						}
					} else {
						alert(res.msg);
					}
				});
			},
			deleteManage(item) {
				this.deleteData = item;
				this.deleteManageShow = true;
			},
			handleNodeClick(e) {
				if (e.userId) {
					e.userProfile = {
						userID: e.userId,
					};
					this.myMessageShow = true;
				}
			},
			createGroup() {
				let arr = "";
				let that = this;
				this.newArrList.forEach((item) => {
					arr += item.userId + ",";
				});
				let groupData = {
					groupId: this.groupData.groupId,
					groupName: this.groupData.groupName,
					groupAvatar: this.dialogImageUrl ?
						this.dialogImageUrl : this.groupData.groupDefaultImg,
					userIds: arr,
				};
				this.pitchChatGroup = '';
				if (this.newArrList.length == 0) {
					this.$message("暂无选择好友");
					return;
				}

				this.$api.createGroup(groupData).then((res) => {
					if (res.code == 1) {
						this.groupShow = false;
						this.groupShow2 = false;
						this.newArrList = [];
						this.userChatTypeChild = false;
						this.groupChatChild = false;
						this.groupData.groupName = '';
						
						this.$nextTick(() => {
							this.tabTypeChange(0);
						});
						this.getConversationList();
						this.sendTextChangeGrouptow();
						
					} else {
						this.$message(res.msg);
					}
				});
			},
			
			zhuanyi(res){
				let hexString = window.btoa(res);
				return hexString
			},
			
			// pc邀请链接
			fuzhilianjie(res){
				new ClipboardJS('.proviceBtn-tow');
				this.$message.success('复制网页端邀请链接成功，快分享给好友进入群聊吧~');
			},
			
			createGroupId() {
				this.groupShow2 = true;
				this.$api.createGroupId().then((res) => {
					if (res.code == 1) {
						this.groupData.groupId = res.data.groupId;
						this.groupData.groupDefaultImg = res.data.groupDefaultImg;
						// console.log(res.data, "resres");
					} else {
						this.$message(res.msg);
					}
				});
			},
			tongxuArrShow(item) {
				// this.tousuShow = true;
				this.goBtnMessage(item);
				this.haoyouid = item.userId;
			},
			// 消息免打扰
			pushSwitchChangedisturb() {
				// console.log(this.myUser)
				// return
				let that = this;
				let promise = that.chat.setMessageRemindType({
					userIDList: [this.atuserObj.userProfile.userID, this.myUser.id],
					messageRemindType: that.TencentCloudChat.TYPES.MSG_REMIND_ACPT_AND_NOTE,
				});
				promise
					.then(function(imResponse) {
						console.log(imResponse, "imResponse");
						// 设置成功后 SDK 会触发 TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED 事件
						// （遍历列表，并读取 Conversation.messageRemindType）
					})
					.catch(function(imError) {
						console.warn("setMessageRemindType error:", imError);
					});
			},

			// 同意或撤回消息
			sendMsgNewtow(e) {
				let that = this;
				console.log(this.atuserObj.userProfile, this.myUser)
				let dataobj = {
					msgType: 31 + '',
					businessID: 31 + '',
					userId: this.atuserObj.userProfile.userID,
					content: JSON.stringify({
						status: e + '',
						fromUserId: this.myUser.id,
						userAvatar: this.myUser.avatar,
						userNickName: this.myUser.nickname,
						userId: this.myUser.id,
						toUserId: this.atuserObj.userProfile.userID
					})
				}
				let touser = {
					fromUserId: this.atuserObj.userProfile.userID,
					userAvatar: this.atuserObj.userProfile.avatar,
					userNickName: this.atuserObj.userProfile.nick,
					userId: this.atuserObj.userProfile.userID,
					toUserId: this.myUser.id,
					status: e + ''
				}

				let message = this.chat.createCustomMessage({
					to: this.atuserObj.userProfile.userID,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					conversationType: 'C2C',
					payload: {
						data: JSON.stringify(dataobj),
						description: JSON.stringify(touser)
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送撤回消息
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						that.chatList.push(imResponse.data.message);
						that.scrollToBottom();
					})
					.catch(function(imError) {
						console.warn("sendMessage error:", imError);
					});
			},

			// 发送撤回消息
			sendMsgNew(e) {
				let that = this;
				console.log(this.atuserObj.userProfile, this.myUser)
				let dataobj = {
					msgType: '21',
					businessID: '21',
					userId: this.atuserObj.userProfile.userID,
					content: JSON.stringify({
						fromUserId: this.myUser.id,
						userAvatar: this.myUser.avatar,
						userNickName: this.myUser.nickname,
						userId: this.myUser.id,
						toUserId: this.atuserObj.userProfile.userID
					})
				}
				let touser = {
					fromUserId: this.atuserObj.userProfile.userID,
					userAvatar: this.atuserObj.userProfile.avatar,
					userNickName: this.atuserObj.userProfile.nick,
					userId: this.atuserObj.userProfile.userID,
					toUserId: this.myUser.id
				}

				let message = this.chat.createCustomMessage({
					to: this.atuserObj.userProfile.userID,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					conversationType: 'C2C',
					payload: {
						data: JSON.stringify(dataobj),
						description: JSON.stringify(touser)
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送撤回消息
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						that.chatList.push(imResponse.data.message);
						that.scrollToBottom();
					})
					.catch(function(imError) {
						console.warn("sendMessage error:", imError);
					});
			},

			// 双向撤回
			remindShowBtn() {
				if (this.myUser.vipFlag == 1) {
					// 发送双向撤回功能
					if (this.shuangxiangchehui == 1) {
						this.$confirm('确定要撤回所有消息吗?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.deleteChattingUser()
							// 确定
						}).catch(() => {
							// 取消
						});
					} else {
						this.sendMsgNew(21);
					}
				} else {
					this.isMyorYou = false;
					this.remindShow = true;
					this.shuangxiangchehui = 0;
				}
			},

			pushSwitchChangedisturbGroup() {
				let that = this;
				let promise = this.chat.setMessageRemindType({
					groupID: that.groupContent.groupID,
					messageRemindType: that.TencentCloudChat.TYPES.MSG_REMIND_ACPT_NOT_NOTE,
				});
				promise
					.then(function(imResponse) {
						// 设置消息免打扰成功
					})
					.catch(function(imError) {
						// 设置消息免打扰失败
						console.warn("setMessageRemindType error:", imError);
					});
			},
			pushSwitchChangeGroup() {
				console.log(this.groupContent);
				let that = this;
				let conversation = {
					conversationID: this.groupContent.groupID,
					isPinned: this.disturbZhiDing == 1 ? true : false,
				};
				let newProm = that.chat.pinConversation({
					conversationID: "GROUP" + this.groupContent.groupID,
					isPinned: this.disturbZhiDing == 1 ? true : false,
				});
				console.log(newProm, "promise");
				// return
				newProm
					.then(function(data) {
						that.getConversationList();
						console.log("置顶成功", data);
					})
					.catch(function() {
						that.getConversationList();
						console.log("置顶失败");
					});
			},
			// 用户置顶
			pushSwitchChangeUser() {
				let that = this;
				let conversation = {
					conversationID: this.atuserObj.conversationID,
					isPinned: this.disturbZhiDing == 1 ? true : false,
				};
				let newProm = that.chat.pinConversation(conversation);
				console.log(newProm, "promise");
				// return
				newProm
					.then(function(data) {
						that.getConversationList();
						console.log("置顶成功", data);
					})
					.catch(function() {
						that.getConversationList();
						console.log("置顶失败");
					});
			},

			// 关闭or开启双向撤回
			pushSwitchChange(e) {
				if (e == 1) {
					if (this.myUser.vipFlag == 1) {
						this.sendMsgNew(21);
					} else {
						this.isMyorYou = false;
						this.remindShow = true;
						this.shuangxiangchehui = 0;
					}
				} else {
					this.chehuixiaoxicaozuo(2, this.pitchChatUser.id);
				}
			},

			// Ta的主页
			checkUser(item) {
				this.showChange("others", item.id);
				this.$emit("closeMessage");
				console.log(type, "type");
			},
			// 消息制定
			mianfeiChange(e) {
				this.siValue1 = e;
				this.siValue2 = !e;
				this.audioValue = "";
				this.shortVideoValue = "";
				this.videoValue = "";
			},
			mianfeiChange2(e) {
				this.siValue1 = !e;
				this.siValue2 = e;
			},
			timer() {
				this.ms = this.ms + 50;
				if (this.ms >= 1000) {
					this.ms = 0;
					this.s = this.s + 1; //秒
				}
				if (this.s >= 60) {
					this.s = 0;
					this.m = this.m + 1; //分钟
				}
				if (this.m >= 60) {
					this.m = 0;
					this.h = this.h + 1; //小时
				}
				// courentTime
				this.courentTime =
					this.toDub(this.h) +
					":" +
					this.toDub(this.m) +
					":" +
					this.toDub(this.s) +
					"" /*+this.toDubms(this.ms)+"毫秒"*/ ;
			},
			toDub(n) {
				//补0操作
				if (n < 10) {
					return "0" + n;
				} else {
					return "" + n;
				}
			},
			// 打电话唤起
			listenttuiCall() {
				let that = this;
				let handleOnCallReceived = function(event) {
					if (event.callMediaType == 1) {
						that.isBackCall = true;
						that.isCall = true;
					}
					if (event.callMediaType == 2) {
						that.isShopIng = true;
						that.isflagJie = true;
						that.isShopingBei = event.userData;
					}
					let data = {
						userProfile: {
							userID: event.calleeIdList[0],
						},
					};
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.ON_CALL_RECEIVED,
					handleOnCallReceived
				);
			},
			//
			listenttuiCallUser() {
				let that = this;
				let handleOnCallReceived = function(event) {
					this.timeClear = setInterval(that.timer, 50);
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.USER_ACCEPT,
					handleOnCallReceived
				);
			},
			// 打电话拒绝监听
			listenttuiCallNo() {
				let that = this;
				let handleInviteeReject = function(event) {
					// 拒绝视频
					that.isShopIng = false;
					// 拒绝音频
					that.isCall = false;
					that.isBackCall = false;
					that.guaduanIsSHow = false;
				};
				this.tuiCallEngine.on(this.TUICallEvent.REJECT, handleInviteeReject);
			},
			listenttuiBusy() {
				let handleLineBusy = function(event) {
					this.$message("对方正忙");
					that.isShopIng = false;
					// 拒绝音频
					that.isCall = false;
					that.isBackCall = false;
					that.guaduanIsSHow = false;
				};
				this.tuiCallEngine.on(this.TUICallEvent.LINE_BUSY, handleLineBusy);
				// LINE_BUSY;
			},

			// 监听挂断
			listenttuiCallgua() {
				let that = this;
				let handleInviteeReject = function(event) {
					if (event.callMediaType == 2) {
						that.isShopIng = false;
					}
					if (event.callMediaType == 1) {
						that.isCall = false;
						that.isBackCall = false;
						that.guaduanIsSHow = false;
					}
					that.stopTimer();
					that.isShopIng = false;
					that.isCall = false;
				};
				that.tuiCallEngine.on(that.TUICallEvent.USER_LEAVE, handleInviteeReject);
			},
			listenttuiCallUser() {
				let that = this;
				let handleOnCallReceived = function(event) {
					this.timeClear = setInterval(that.timer, 50);
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.USER_ACCEPT,
					handleOnCallReceived
				);
			},
			friendBtn(type) {
				this.tongMassage = type;
				if (type == 0) {
					this.groupShowContent = false;
					this.tongList();
				}
				if (type == 1) {
					this.groupShowContent = false;
					if (this.tabType == 3) {
						this.getMyFriendsData();
					}
				}
				// friendsArr
				if (type == 2) {
					this.getGroupList();
				}
			},
			groupList() {
				this.$api.groupListGroup({}).then((res) => {
					if (res.code == 1) {
						this.groupArr = res.data.rows;
						// console.log(res.data, "resres");
					} else {
						this.$message.info(res.msg);
					}
				});
			},

			// 好友去重
			getUniqueArray(array1, array2) {
				const userIdSet = new Set();
				const result = [];

				// 遍历第一个数组
				array1.forEach(item1 => {
					// 检查第二个数组中是否存在相同的 userId
					const existsInArray2 = array2.some(item2 => item2.userId === item1.userId);

					// 如果不存在于第二个数组中，并且 userId 还没有被添加到集合中
					if (!existsInArray2 && !userIdSet.has(item1.userId)) {
						userIdSet.add(item1.userId);
						result.push(item1);
					}
					// 如果 userId 存在于第二个数组中，则忽略该元素
				});

				// 注意：我们不需要处理 array2 中剩余的元素，因为我们只关心 array1 的去重结果

				return result;
			},

			// 获取我的好友，需要校验是否在群
			tongListtow() {
				this.$api.bookEntity({
					keywords: this.tongModerlqun
				}).then((res) => {
					if (res.code == 1) {
						res.data.forEach((item) => {
							item.state = 1;
							item.shareState = 1;
						});
						this.tongxuArrtow = this.getUniqueArray(res.data, this.userListGroup)
					} else {
						this.$message.info(res.msg);
					}
				});
			},

			tongList() {
				this.$api.bookEntity({
					keywords: this.tongModerl
				}).then((res) => {
					if (res.code == 1) {
						res.data.forEach((item) => {
							item.state = 1;
							item.shareState = 1;
						});
						this.tongxuArr = res.data;
					} else {
						this.$message.info(res.msg);
					}
				});
			},
			shareState(state, item) {
				console.log(item, "itemiutem");
				if (item.shareState == 1) {
					item.shareState = 2;
					this.shareArr.push(item);
					return;
				}
				if (item.shareState == 2) {
					item.shareState = 1;
					this.shareArr.forEach((item1, index) => {
						if (item.userId == item1.userId) {
							this.shareArr.splice(index, 1);
						}
					});
					return;
				}
				this.$forceUpdate();
			},

			// 设置好友进入群聊
			setRoomUserStateChangetow(state, item) {
				if (state == "allSpecialEffects") {
					this.selectAlltow = !this.selectAlltow;
					if (this.selectAlltow) {
						this.tongxuArrtow.forEach((item) => {
							item.state = 1;
						});
						this.newArrListtow = [];
					} else {
						this.tongxuArrtow.forEach((item) => {
							item.state = 2;
						});
						this.newArrListtow = this.tongxuArr;
					}
				} else {
					if (item.state == 1) {
						item.state = 2;
						this.newArrListtow.push(item);
						return;
					}
					if (item.state == 2) {
						item.state = 1;
						this.newArrListtow.forEach((item1, index) => {
							if (item.userId == item1.userId) {
								this.newArrListtow.splice(index, 1);
							}
						});
						return;
					}
				}
				this.$forceUpdate();
			},

			setRoomUserStateChange(state, item) {
				if (state == "allSpecialEffects") {
					this.selectAll = !this.selectAll;
					if (this.selectAll) {
						this.tongxuArr.forEach((item) => {
							item.state = 1;
						});
						this.newArrList = [];
					} else {
						this.tongxuArr.forEach((item) => {
							item.state = 2;
						});
						this.newArrList = this.tongxuArr;
					}
				} else {
					if (item.state == 1) {
						item.state = 2;
						this.newArrList.push(item);
						return;
					}
					if (item.state == 2) {
						item.state = 1;
						this.newArrList.forEach((item1, index) => {
							if (item.userId == item1.userId) {
								this.newArrList.splice(index, 1);
							}
						});
						return;
					}
				}
				this.$forceUpdate();
			},
			
			// 确定清空群自己发的消息
			async qingkongallUsermsg() {
				this.$api.qingkongmsg({
					groupId:this.groupContent.groupID
				})
			},
			
			// 群员撤回聊天记录
			qunyuanchehui(id){
				// window.location.reload();
				let promises = this.chat.logout();
				promises
				  .then((res) => {
					this.getUserGroupList();
				  })
				  .catch((err) => {
				    console.warn('login error:',err)
				  });
			},
			
			
			// 重新登录
			getUserGroupList(){
				let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
				let that = this;
				let promise = this.chat.login({
				  userID: this.myUser.id,
				  userSig:userTIMInfo.userSig
				});
				promise
				  .then((res) => {
					that.dialogueList.forEach(item =>{
						if(item.conversationID == ('GROUP' + that.groupContent.groupID)){
							setTimeout(() =>{
								item.lastMessage.isRevoked = true;
								that.selectUserChat(item)
							},150)
						}
					})
				  })
				  .catch((errs) => {
				    console.warn('login error:', errs)
				  });
			},
			

			// 确定清空群所有消息
			async qingkongallmsg() {
				let that = this;
				let toinfo = {
					data: {
						groupId: this.pitchChatGroup.groupId,
						userId: this.myUser.id,
						businessID: 'clear_group_msg',
						userType: 1,
						msg: '清空群聊历史消息',
						cmd: 'clear_group_msg',
					},
					businessID: 'clear_group_msg',
					cmd: 'CustomCmdMsg'
				}

				let sendMessage = {
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					to: this.pitchChatGroup.groupId,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						data: JSON.stringify(toinfo),
					}
				}
				let message = this.chat.createCustomMessage(sendMessage);
				let promise = this.chat.sendMessage(message);
				promise.then(function(imResponse) {
					that.chatText = "";
					that.chat.clearHistoryMessage('GROUP' + that.pitchChatGroup.groupId);
					that.chatListGroup = [];
					that.$message.success("撤回群聊消息成功");
				}).catch((error) => {
					console.warn("startRemoteView error:", error);
				});

			},
			
			// 群员主动撤回
			qunyuanchehuidianji() {
				if (this.myUser.vipFlag == 1) {
					this.$confirm('确定要撤回群聊内自己发的消息吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.qingkongallUsermsg()
					}).catch(() => {
						// 取消
					});
				} else {
					this.remindShow = true;
				}
			},

			// 群主撤回
			qunzhucheui() {
				if (this.myUser.vipFlag == 1) {
					this.$confirm('确定要撤回群聊所有消息吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.qingkongallmsg()
						// 确定
					}).catch(() => {
						// 取消
					});
				} else {
					this.remindShow = true;
				}
			},

			// myFriends
			goBtnMessage(data) {
				console.log(data, "dadassbb");
				this.$api.userMainInfo({
					otherUserId: data.userId
				}).then((res) => {
					if (res.code == 1) {
						this.contentData = res.data;
						this.contentData.type = "C2C";
						this.contentData.userID = data.userId;
						this.userChatTypeChild = false;
						this.groupChatChild = false;
						this.userChatType = false;
						this.groupChatChild = false;
						this.groupShowContent = false;
						this.tousuShow = true;
						// if(re)
						// shuangxiangchehui
					} else {

					}
				});
			},
			getGroupList() {
				this.$api.getGroupList().then((res) => {
					if (res.code == 1) {
						this.groupArrList = res.data.rows;
						console.log(res.data, "resres");
					} else {
						this.$message.info(res.msg);
					}
				});
			},
			grounpClick(item) {
				console.log(item, "itemitem");
				this.haoyouid = 'GROUP' + item.id;
				this.userChatType = false;
				this.userChatTypeChild = false;
				this.groupChatChild = false;
				this.type3Data = item;
				this.groupShowContent = true;
				this.tousuShow = false;
			},
			
			// 群聊分享名片
			shareBtnUserGrop(){
				this.shareArr = [];
				this.tongxuArr.forEach(item => {
					item.shareState = 1;
				})
				
				this.selectFrendstow = true;
			},
			
			shareBtnUser() {
				this.shareArr = [];
				this.tongxuArr.forEach(item => {
					item.shareState = 1;
				})
				
				this.selectFrends = true;
			},
			captureScreen() {
				this.capTureImg = false
				const targetElement = this.$refs.screenshotTarget;
				console.log('aaabb', targetElement)
				let img = ''
				html2canvas(targetElement).then(canvas => {
					console.log(canvas, 'canvas')
					document.body.appendChild(canvas);
					const imgData = canvas.toDataURL('image/png');
					console.log(imgData, 'imgData')

					// this.$api.fileUploadAli()
					// const link = document.createElement('a');
					// link.download = 'screenshot.png';
					// link.href = imgData;
					// link.click()
				})
			},

			getMyFriendsData() {
				this.friendsArr = [];

				this.$api.myFriends().then((res) => {
					if (res.code == 1) {
						// console.log(res,)
						res.data.forEach((item) => {
							item.nickname = item.groupingName;
						});
						this.$api.followAddressBookList().then((res1) => {
							this.bookData = res1.data;
							res.data.forEach((item) => {
								item.type = "C2C";
								item.children = [];
								this.bookData.forEach((item1) => {
									if (item.id == item1.groupId) {
										item1.groupingName = item1.nickName;
										item.children.push(item1);
									}
								});
								this.friendsArr.push(item);
								console.log(this.friendsArr, "friendsArr");
							});
						});
					}
				});
			},

			// bookEntity

			// 打电话拒绝监听
			// listenttuiCallNo() {
			//   let that = this;
			//   let handleInviteeReject = function (event) {
			//     console.log(this.friendsArr, "friendsArr");

			//     // 拒绝视频
			//     that.isShopIng = false;
			//     // 拒绝音频
			//     that.isCall = false;
			//     that.isBackCall = false;
			//     that.guaduanIsSHow = false;
			//   };
			//   this.tuiCallEngine.on(this.TUICallEvent.REJECT, handleInviteeReject);
			// },
			// 监听挂断
			// listenttuiCallgua() {
			//   let that = this;
			//   let handleInviteeReject = function (event) {
			//     if (event.callMediaType == 2) {
			//       that.isShopIng = false;
			//     }
			//     if (event.callMediaType == 1) {
			//       that.isCall = false;
			//       that.isBackCall = false;
			//       that.guaduanIsSHow = false;
			//     }
			//     that.stopTimer();
			//   };
			//   this.tuiCallEngine.on(
			//     this.TUICallEvent.ON_CALL_CANCELED,
			//     handleInviteeReject
			//   );
			// },
			// 通话中挂断
			// listenttuiCallgua() {
			//   let that = this;
			//   let handleInviteeReject = function (event) {
			//     if (event.callMediaType == 2) {
			//       that.isShopIng = false;
			//     }
			//     if (event.callMediaType == 1) {
			//       that.isCall = false;
			//       that.isBackCall = false;
			//       that.guaduanIsSHow = false;
			//     }
			//     let data = {
			//       userProfile: {
			//         userID: event.userID,
			//       },
			//     };
			//     that.stopTimer();
			//   };
			//   this.tuiCallEngine.on(this.TUICallEvent.CALLING_END, handleInviteeReject);
			// },
			groupUserArr(groupId) {
				this.groupUserVip = true;
				this.frendsDetail = false;

				this.$api
					.managerList({
						groupId: this.pitchChatGroup.groupId,
						nickname: this.nickname
					})
					.then((res) => {
						if (res.code == 1) {
							this.userGroupData = res.data.groupLeaderUser
							this.managerArr = res.data.managerList;
						} else {}
					});
			},

			// 获取群管理
			getqunguanli() {
				this.$api
					.managerList({
						groupId: this.pitchChatGroup.groupId,
						nickname: this.nickname
					})
					.then((res) => {
						if (res.code == 1) {
							this.managerArr = res.data.managerList;
						}
					});
			},


			// 拒绝
			DownBtn(type) {
				let that = this;
				this.tuiCallEngine
					.reject()
					.then((res) => {
						if (type == 1) {
							this.guaduanIsSHow = false;
							this.isBackCall = false;
							this.isCall = false;
						}
						if (type == 2) {
							this.isflagJie = true;
							this.isShopIng = false;
						}
					})
					.catch((error) => {
						console.warn("reject error:", error);
					});
			},
			// 接听
			DownOn(type) {
				console.log('点击了语音或电话')
				this.time = {
					hours: 0,
					minutes: 0,
					seconds: 0
				}
				this.startTimer();
				this.tuiCallEngine
					.accept()
					.then((res) => {
						if (type == 1) {
							this.isBackCall = false;
							this.guaduanIsSHow = true;
						}
						if (type == 2) {
							this.isflagJie = false;
						}
						this.timeClear = setInterval(this.timer, 50);
						this.isAudio = res.audio;
					})
					.catch((error) => {
						console.log('????????????????')
						if (error == 'TUICallEngineError: TUICallEngine.call failed. error: NotFoundError: Requested device not found. 没有麦克风设备 <NOT_MICROPHONE_LIST: 60003>') {
							this.$message.error('没有麦克风设备');
						} else {
							this.$message.error(error);
						}
					});
			},
			// 静音 
			closeMicrophone() {
				this.tuiCallEngine
					.closeMicrophone()
					.then((res) => {
						this.isAudio = false;
						console.log(
							res,
							"console.log(res);console.log(res);console.log(res);"
						);
						// success
					})
					.catch((error) => {
						console.warn("closeMicrophone error:", error);
					});
			},
			// 关闭摄像头
			closeSHExiangtou() {
				this.tuiCallEngine
					.closeCamera()
					.then((res) => {
						this.shexiangtouis = false;
						console.log(
							res,
							"console.log(res);console.log(res);console.log(res);"
						);
						// success
					})
					.catch((error) => {
						console.warn("closeMicrophone error:", error);
					});
			},

			// 开启麦克风
			openMicrophone() {
				this.tuiCallEngine
					.openMicrophone()
					.then((res) => {
						// success
						this.isAudio = true;
						console.log(res, "console.log(res);console.log(res);");
					})
					.catch((error) => {
						console.warn("openMicrophone error:", error);
					});
			},
			// 结束通话
			overCall(type) {
				let that = this;
				this.tuiCallEngine
					.hangup()
					.then(() => {
						if (type == 1) {
							this.guaduanIsSHow = false;
							this.isBackCall = false;
							this.isCall = false;
						} else {
							this.isflagJie = true;
							this.isShopIng = false;
						}
						that.isjieting = false;
						that.stopTimer();
					})
					.catch((error) => {
						console.warn("hangup error:", error);
					});
			},
			// 打电话和视频
			callTIM(type) {
				this.time = {
					hours: 0,
					minutes: 0,
					seconds: 0
				}
				this.stopTimer();
				// 获取是否收费
				this.$api.getUserPrice({
					othUserId: this.atuserObj.userProfile.userID,
					type
				}).then((res) => {
					if (res.code == 1) {
						console.log(type)
						if (res.data.callPrice > 0) {
							this.$confirm('拨打' + (type == 1 ? '语音通话' : '视频通话') + '需要支付' + res.data.callPrice + '元宝, 计时扣费以后台统计时长为准 ,确认拨打吗?', '提示', {
								confirmButtonText: '同意',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								if (this.myUser.diamondCost < res.data.callPrice) {
									return this.$message.error('元宝数量不足')
								}
								const min = 1;
								const max = 2147483647;
								let idsNum = Math.floor(Math.random() * (max - min + 1)) + min;
								let types = type == 1 ? 'yp' : 'sp';
								console.log(idsNum + types)
								this.isShopingBei.nickname = this.atuserObj.userProfile.nick;
								this.isShopingBei.avatar = this.atuserObj.userProfile.avatar;
								let promise = this.tuiCallEngine.call({
									userID: this.atuserObj.userProfile.userID,
									type: type,
									userData: this.myUser,
									// roomID:0,
									strRoomID: idsNum + types
								});
								promise
									.then(() => {
										if (type == 1) {
											this.guaduanIsSHow = true;
											this.isCall = true;
										} else {
											this.isflagJie = false;
											this.isShopIng = true;
										}
										this.kaiqishexiangtou();
									})
									.catch((error) => {
										console.warn(error, '?????????');
										if (error ==
											'TUICallEngineError: TUICallEngine.call failed. error: NotFoundError: Requested device not found. 没有麦克风设备 <NOT_MICROPHONE_LIST: 60003>'
										) {
											this.$message.error('没有麦克风设备');
										} else {
											this.$message.error(error);
										}
									});
								// 确定
							})
						} else {
							this.isShopingBei.nickname = this.atuserObj.userProfile.nick;
							this.isShopingBei.avatar = this.atuserObj.userProfile.avatar;
							let promise = this.tuiCallEngine.call({
								userID: this.atuserObj.userProfile.userID,
								type: type,
								userData: this.myUser,
							});
							promise
								.then(() => {
									if (type == 1) {
										this.guaduanIsSHow = true;
										this.isCall = true;
									} else {
										this.isflagJie = false;
										this.isShopIng = true;
									}
									this.kaiqishexiangtou();
								})
								.catch((error) => {
									console.warn(error, '?????????');
									if (error ==
										'TUICallEngineError: TUICallEngine.call failed. error: NotFoundError: Requested device not found. 没有麦克风设备 <NOT_MICROPHONE_LIST: 60003>'
									) {
										this.$message.error('没有麦克风设备');
									} else {
										this.$message.error(error);
									}
								});
						}
					} else {
						this.$message.info(res.msg);
					}
				});
			},
			// 大神续约
			setGreatGodRenewal(messageId, type) {
				/**
				 * messageId 	消息id
				 * type 是否续约(1续约，0不续约)
				 */
				this.$api.greatGodRenewal({
					messageId,
					type
				}).then((res) => {
					if (res.code == 1) {
						this.queryAllList(this.pitchMessageType, 2);
						this.renewalShow = false;
					} else {
						this.$message.info(res.msg);
					}
				});
			},

			checkOtherPagequn(userId) {
				console.log(userId)
				this.manitoId = userId;
				this.othersShow = true;
			},

			checkOtherPage(item) {
				this.manitoId = item.id;
				this.othersShow = true;
				// this.$emit('closeNowOpenOthers', item.id)
			},

			// 点击名片
			checkOtherPagetow(item) {
				console.log(item)
				this.manitoId = item.userId;
				this.othersShow = true;
			},

			copyUserAddrees(id) {
				// console.log(id,'ididid')
				navigator.clipboard
					.writeText(id)
					.then(() => {
						alert("复制成功!");
					})
					.catch((err) => {
						console.error("复制失败: ", err);
					});
			},
			getFriendGroupList() {
				this.$api.friendGroupList().then((res) => {
					res.data.forEach((item) => {
						item.label = item.groupingName;
						item.value = item.id;
					});
					this.goodsFen = res.data;
				});
			},

			checkFrends() {
				this.tongList();
			},

			// 获取消息未读数
			getUnreadCount() {
				this.$api.unreadCount().then((res) => {
					// console.log("消息未读数", res);
					if (res.code == 1) {
						res.data.forEach((item) => {
							//1平台公告 2系统通知 3订单消息 4派单消息 5互动消息
							switch (item.type) {
								case 1: //1平台公告
									this.platformNum = item.unReadCount;
									break;
								case 2: //2系统通知
									this.systemNum = item.unReadCount;
									break;
								case 3: //3订单消息
									this.orderNumUnread = item.unReadCount;
									break;
								case 4: //4派单消息
									this.sendOrdersNum = item.unReadCount;
									break;
								case 5: //5互动消息
									this.interactionNum = item.unReadCount;
									break;
								default:
									break;
							}
						});
					}
				});
			},

			// 同意或拒绝撤回
			chehuixiaoxicaozuo(status, toUserId) {
				this.$api.tongyihuochehui({
					status,
					toUserId
				}).then((res) => {
					if (res.code == 1) {}
				});
			},

			complainList(i) {
				// 判断聊天室是否存在
				this.$api.complainList({
					type: 1
				}).then((res) => {
					if (res.code == 1) {
						this.gradeList = res.data;
						console.log(this.gradeList, "resresres");
					} else {
						this.$message.info(res.msg);
					}
				});
			},
			//派单跳单
			skipOrder(i) {
				// 判断聊天室是否存在
				this.$api.enterRoom({
					roomId: i.roomId
				}).then((res) => {
					if (res.code == 1) {
						let data = {
							liveStreamId: res.data.liveStreamingId,
							targetPositionId: "",
							upMicroType: 1,
						};
						// 大神排麦
						this.$api.upMicrophone(data).then((opn) => {
							if (opn.code == 1) {
								this.$router.push({
									path: "/someSingingHall",
									query: {
										roomId: res.data.id,
										liveStreamId: res.data.liveStreamingId,
									},
								});
							} else {
								this.$message.info(opn.msg);
							}
						});
					} else {
						this.$message.info(res.msg);
					}
				});
			},
			//滚动条保持最底部方法
			scrollToBottom() {
				this.$nextTick(() => {
					var container = this.$el.querySelector(".userChat_content");
					container.scrollTop = container.scrollHeight;
					console.log('aaabb')
				});
			},
			// 订单数修改
			orderAddition(type) {
				if (this.orderNum == 100) return;
				if (type == 1 && this.orderNum != 1) {
					this.orderNum--;
				} else if (type == 2) {
					this.orderNum++;
				}
			},
			// 收费价格
			getChargesListShi() {
				this.$api.chargesList({
					type: "audioCall"
				}).then((res) => {
					res.data.forEach((item) => {
						item.label = item.text;
					});
					this.audioArr = res.data;
				});
				this.$api.chargesList({
					type: "videoCall"
				}).then((res) => {
					res.data.forEach((item) => {
						item.label = item.text;
					});
					this.videoArr = res.data;
				});
				this.$api.chargesList({
					type: "shortVideoCall"
				}).then((res) => {
					res.data.forEach((item) => {
						item.label = item.text;
					});
					this.shortVideoArr = res.data;
				});
			},
			// 下单跳转
			placeSkipChange() {
				this.$emit("closeMessage");
				this.$emit("placeSkip", this.pitchChatUser.id);
			},
			// 技能切换
			skillCut(type) {
				let skillRank = 0;
				this.pitchChatUser.userSkillVos.forEach((item, index) => {
					// console.log(item);
					if (this.manitoSkillData.skillId == item.skillId) {
						// console.log(this.manitoSkillData.skillId);
						return (skillRank = index);
					}
				});
				if (type == 1 && skillRank == 0) return "";
				if (type == 2 && skillRank == this.pitchChatUser.userSkillVos.length - 1)
					return "";
				if (type == 1) {
					this.manitoSkillData = this.pitchChatUser.userSkillVos[skillRank - 1];
				} else {
					this.manitoSkillData = this.pitchChatUser.userSkillVos[skillRank + 1];
				}
			},
			// 举报
			addReport() {
				// tabType 1和2时是关注和粉丝id是pitchChatUser中的id ，3通讯录的时候id是contentData中的id（群聊tongMassage=2 type3Data群聊信息）
				let reportid = this.tabType == 3 ? this.tongMassage = 2 ? this.type3Data.id : this.contentData.id : this.pitchChatUser.id
				let data = {
					content: this.reportTextarea, //	举报内容
					image: this.reportImgs.toString(), //	举报图片（多图逗号分隔）
					reason: this.reportValue, //	举报原因值
					type: 1, //	举报类型（1-用户 2-房间）
					sourceId: reportid, //	被举报id
				};
				this.$api.report(data).then((res) => {
					if (res.code == 1) {
						this.reportShow = false;
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			// 图片删除
			imgDeleChange(index) {
				this.reportImgs.splice(index, 1);
			},
			handleChange(file, files) {
				this.files1 = file.raw;
			},
			// 举报图片上传
			reportImgSuccess(res) {
				if (res.code == 1) {
					this.reportImgs.push(res.data.url);
				} else {
					this.$message.error(res.msg)
				}
			},
			// 举报原因
			queryReportReason() {
				this.$api.queryReportReason().then((res) => {
					if (res.code == 1) {
						this.reportOptions = res.data;
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			// 用户拉黑
			async addUserBlack(data) {
				let arr = [];
				let that = this;
				// tabType 1和2时是关注和粉丝id是data中的id ，3通讯录的时候id是contentData中的id（群聊tongMassage=2 type3Data群聊信息）
				let blackid = this.tabType == 3 ? this.tongMassage = 2 ? this.type3Data.id : this.contentData.id : data.id
				console.log(blackid)
				if (data.inBlack == 0) {
					await this.$api.addBlack({
						blackUserId: blackid
					}).then((res) => {
						if (res.code == 1) {
							arr.push(blackid);
							this.chat.addToBlacklist({
								userIDList: arr
							});
							this.blockShow = false;
							this.$message("移入成功");
						} else {
							this.$message.error(res.msg)
						}
					});
				} else {
					await this.$api.removeBlack({
						blackUserId: blackid
					}).then((res) => {
						if (res.code == 1) {
							arr.push(blackid);
							this.chat.removeFromBlacklist({
								userIDList: arr
							});
							this.blockShow = false;
							this.$message("移出成功");
						} else {
							this.$message.error(res.msg)
						}
					});
				}
				if (this.tabType == 3) return //通讯录用户拉黑不用请求
				await that.$api.userMainInfo({
					otherUserId: data.id
				}).then((res) => {
					that.pitchChatUser = res.data;
					this.$forceUpdate();
				});
			},
			async complaintBtn() {
				if (!this.complainData.complainId) return this.$message.error("请选择投诉原因");
				if (!this.complainData.content) return this.$message.error("请填写详细投诉原因");
				if (this.complainArr.length == 0) return this.$message.error("请上传相关内容截图");
				let complainData = {
					content: this.complainData.content,
					name: this.complainData.name,
					type: this.complainData.type,
					userId: this.myUser.id,
					nickName: this.myUser.nickname,
					complainId: this.complainData.complainId,
					complainObjectId: this.complainData.complainObjectId,
					imageEvidence: this.complainArr.toString(),
				};
				await this.$api.complainAdd(complainData).then((res) => {
					if (res.code == 1) {
						this.$message("投诉成功");
						this.complaintShow = false;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 删除会话
			deleteDialogue() {
				// const conversationType = this.$RongIMLib.ConversationType.PRIVATE;
				// const targetId = this.pitchChatUser.id;
				// this.$RongIMLib
				//   .removeConversation({
				//     conversationType,
				//     targetId: targetId,
				//   })
				//   .then((res) => {
				//     // 删除指定会话成功
				//     if (res.code === 0) {
				//       // console.log(res.code);
				//     } else {
				//       // console.log(res.code, res.msg);
				//     }
				//   });
			},
			// 拒绝
			refunOrder(item) {
				let data = {
					messageId: item.id,
					status: 2,
				};
				this.$api.operateGroup(data).then((res) => {
					if (res.code == 1) {
						this.$message("拒绝转让");
						this.queryAllList("", 2);
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			// 同意
			addreesBtn(item) {
				let data = {
					messageId: item.id,
					status: 1,
				};
				this.$api.operateGroup(data).then((res) => {
					if (res.code == 1) {
						this.$message("同意转让");
						this.queryAllList("", 2);
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			//

			// 获取会话列表
			getConversationList() {
				let promise = this.chat.getConversationList();
				let that = this
				promise
					.then((res) => {
						setTimeout(() => {
							that.$nextTick(() => {
								that.dialogueList = res.data.conversationList;
								if(that.groupIds){
									that.dialogueList.forEach(item =>{
										if(item.conversationID == ('GROUP' + that.groupIds)){
											that.selectUserChat(item)
											setTimeout(() =>{
												that.groupIds = '';
											},100)
										}
									})
								}
							})
						}, 200);
						console.log(this.dialogueList)
					})
					.catch((error) => {
						console.warn("call error:", error);
					});
			},
			getChargeSetting() {
				this.$api.getChargeSettings({}).then((res) => {
					if (res.code == 1) {
						this.audioValue = res.data.audioCall;
						this.siValue1 = res.data.freeFlag == 1 ? true : false;
						this.siValue2 = res.data.freeFlag == 1 ? false : true;
						this.shortVideoValue = res.data.shortVideoCall;
						this.videoValue = res.data.videoCall;
					}
				});
			},
			BtnSubmit1() {
				this.$api
					.setChargeSettings({
						audioCall: this.audioValue,
						freeFlag: this.siValue1 ? 1 : 0,
						shortVideoCall: this.shortVideoValue,
						videoCall: this.videoValue,
					})
					.then((res) => {
						if (res.code == 1) {
							this.isSet = false;
							this.$message("私信设置成功");
						} else {
							this.$message.info(res.msg)
						}
					});
			},
			// 关注
			// 关注
			followUser() {
				this.isGuan = true;
			},
			shareBtnPop(item) {
				this.getEmojiImageUrl()
				this.atuserObj = item
				this.atuserObj.nick = item.nickname
				this.shareBtnUser(item)
			},
			getEmojiImageUrl(index, data) {
				// 这里应该是一个实际的请求，根据index和data从服务器获取表情图片URL
				return `https://example.com/emojis/${index}_${data}.png`;
			},
			sendMassage(data, index, id) {
				this.userChatType = true;
				this.isTabs = true;
				this.userChatTypeChild = true;
				this.groupChatChild = false;
				data.userProfile = {
					userID: data.id,
				};
				this.selectUserChat(data, index, id);
			},
			dissolveBtnPop() {},
			// 发送名片
			shareBtnFrend() {
				let that = this;
				this.shareArr.forEach((item) => {
					(item.businessID = "userCard_message"), (item.userName = item.nickName);
					item.userId = item.userId;
					item.userShowId = item.userShowId || item.groupId;
					let message = this.chat.createCustomMessage({
						to: this.atuserObj.userProfile.userID,
						conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
						cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
						// 消息优先级，用于群聊
						payload: {
							data: JSON.stringify(item), // 用于标识该消息是骰子类型消息
							description: JSON.stringify(item), // 获取骰子点数
							extension: JSON.stringify(item),
						},
					});
					let promise = this.chat.sendMessage(message);
					promise
						.then(function(imResponse) {
							// 发送成功
							that.selectFrends = false;
							that.chatList.push(imResponse.data.message);
							that.scrollToBottom();
						})
						.catch(function(imError) {
							// 发送失败
							console.warn("sendMessage error:", imError);
						});
				});
			},
			
			// 发送名片
			shareBtnFrendGroud(){
				let that = this;
				that.shareArr.forEach((item) => {
					(item.businessID = "userCard_message"), (item.userName = item.nickName);
					item.userId = item.userId;
					item.userShowId = item.userShowId || item.groupId;
					
					let message = that.chat.createCustomMessage({
						to: this.pitchChatGroup.groupId,
						conversationType: that.TencentCloudChat.TYPES.CONV_GROUP,
						cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
						// 消息优先级，用于群聊
						payload: {
							data: JSON.stringify(item), // 用于标识该消息是骰子类型消息
							description: JSON.stringify(item), // 获取骰子点数
							extension: JSON.stringify(item),
						},
					});
					let promise = that.chat.sendMessage(message);
					promise
						.then(function(imResponse) {
							// 发送成功
							that.selectFrendstow = false;
							that.chatListGroup.push(imResponse.data.message);
							that.scrollToBottom();
						})
						.catch(function(imError) {
							// 发送失败
							console.warn("sendMessage error:", imError);
						});
				});
			},
			
			sendGroupMassage(data) {
				this.userChatType = true;
				this.isTabs = true;
				this.userChatTypeChild = false;
				this.groupChatChild = true;
				data.type = "GROUP";
				data.groupProfile = {
					groupID: data.id,
				};
				data.conversationID = "GROUP" + data.id;
				console.log(data, "dadasss");

				this.selectUserChat(data);
			},
			// 显示
			showChange(type, id) {
				if (!localStorage.getItem("user-token")) {
					this.$router.push({
						path: "/login"
					});
					this.$message.info("请先登录哦~");
					return;
				}
				this.getUserInfo();
				switch (type) {
					case "messageTopup": // 他人主页显示
						this.myMessageShow = true;
						break;
					case "others": // 他人主页显示
						this.manitoId = id;
						this.othersShow = !this.othersShow;
						break;
					case "ranking": // 排行榜显示
						this.ranking = !this.ranking;
						break;
					case "personal": //个人中心
						this.personalShow = !this.personalShow;
						break;
					case "helpindex": // 帮助中心
						this.helpindexShow = !this.helpindexShow;
						break;
					case "blindBox": // 盲盒
						this.blindBoxShow = !this.blindBoxShow;
						break;
					case "first": //首充
						this.firstShow = !this.firstShow;
						// this.walletTopupShow = !this.walletTopupShow;
						break;
					case "enter": //公会入驻
						this.enterShow = !this.enterShow;
						break;
					case "message": //首充
						this.myMessageShow = !this.myMessageShow;
						break;
					case "manito": //成为大神
						this.manitoShow = !this.manitoShow;
						break;
					default:
						break;
				}
			},
			BtnSubmit() {
				this.$api
					.followUser({
						followUserIds: this.pitchChatUser.id,
						groupId: this.FenValue,
					})
					.then((res) => {
						if (res.code == 1) {
							this.isGuan = false;
							this.$set(this.pitchChatUser, "isFollow", 1);
							this.selectUserChat();
						} else {
							this.$message.info(res.msg)
						}
					});
			},
			// 取消关注
			stopFollowUser() {
				this.$api
					.stopFollowUser({
						followUserId: this.pitchChatUser.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.$set(this.pitchChatUser, "isFollow", 0);
							this.selectUserChat();
						} else {
							this.$message.info(res.msg)
						}
					});
			},
			
			// 保存图片
	async proveImgs(wxImg) {
		try {
			// 1. 使用 fetch 获取图片的 Blob 数据
			const response = await fetch(wxImg);
			if (!response.ok) {
				throw new Error('网络响应不正常');
			}

			const blob = await response.blob();

			// 2. 创建一个虚拟的 <a> 标签
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob); // 将 Blob 转换为 URL
			link.download = 'my-image.png'; // 设置下载的文件名

			// 3. 触发点击事件以下载图片
			link.click();

			// 4. 释放 URL 对象
			URL.revokeObjectURL(link.href);
		} catch (error) {
			console.error('下载图片失败:', error);
		}

	},

			// 群主撤回聊天记录
			qunzhuchehui(id) {
				console.log(id)
				this.chat.clearHistoryMessage(id);
				this.chatListGroup = [];
			},

			// 撤回聊天记录发送消息
			deleteChattingUser(e) {
				this.chat.clearHistoryMessage(this.atuserObj.conversationID);
				this.clearTheChatShow = false;
				this.chatList = [];
				if (!e) {
					let that = this;
					let dataobj = {
						businessID: "C2C_revocationMsgList",
						fromUserId: this.myUser.id,
						msgType: "C2C_revocationMsgList",
						toUserId: this.atuserObj.userProfile.userID,
						version: 0
					}

					let message = this.chat.createCustomMessage({
						to: this.atuserObj.userProfile.userID,
						cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
						conversationType: 'C2C',
						payload: {
							data: JSON.stringify(dataobj),
						},
						onProgress: function(event) {
							console.log("file uploading:", event);
						},
					});
					// 2. 发送撤回消息
					let promise = this.chat.sendMessage(message);
					promise
						.then(function(imResponse) {
							that.chatList.push(imResponse.data.message);
							that.scrollToBottom();
						})
						.catch(function(imError) {
							console.warn("sendMessage error:", imError);
						});
				}
			},
			clearTheChatShowGroupBtn() {
				console.log(
					this.atuserObj.conversationID,
					"this.atuserObj.conversationID"
				);

				this.chat.clearHistoryMessage("GROUP" + this.groupContent.groupID);
				this.clearTheChatShowGroup = false;
				this.chatListGroup = [];
			},
			clearTheChatShowBtn() {
				this.chat.clearHistoryMessage(this.atuserObj.conversationID);
				this.clearTheChatShowUser = false;
				this.chatList = [];
			},
			//获取用户信息
			getUserInfo() {
				this.$api.queryUser().then((res) => {
					// console.log("用户信息", res);
					if (res.code == 1) {
						this.myUser = res.data;
						console.log(this.myUser, "sssbb");
						if (res.data.userGuildstatus == 1) {
							this.informList[5] = {
								imgUrl: require("../../image/message/7.png"),
								name: '公会通知'
							}
						}
					} else {
						this.$message.info(res.msg)
					}
				});
			},
			// 发送自定义消息
			sendCreateCustomMessage(data) {
				let message = this.chat.createCustomMessage({
					to: data.userProfile.userID,
					conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					// 消息优先级，用于群聊
					payload: {
						data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
						description: JSON.stringify(data), // 获取骰子点数
						extension: JSON.stringify(data),
					},
				});
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						this.chatList.push();
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},

			// 发送群创建消息
			async sendTextChangeGrouptow() {

				let that = this;
				let touser = {
					businessID: "group_create",
					content: "创建了群聊",
					opUser: that.myUser.id,
					version: 4
				}


				let sendMessage = {
					to: this.groupData.groupId,
					conversationType: that.TencentCloudChat.TYPES.CONV_GROUP,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						data: JSON.stringify(touser)
					}
				}

				let message = this.chat.createCustomMessage(sendMessage);
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// that.chatListGroup.push(imResponse.data.message);
						that.chatText = "";
						that.scrollToBottom();
						that.getConversationList();
					})
					.catch(function(imError) {
						// 发送失败
						if (imError.code == 20007) {
							that.$message.error("您已被对方拉黑");
						}
					});
			},

			// 发送群消息
			async sendTextChangeGroup() {
				let that = this;
				let arr2 = [];
				let options = {
					groupID: this.groupContent.groupID,
					groupCustomFieldFilter: "",
				};
				that.chat
					.getGroupProfile({
						groupID: this.groupContent.groupID,
					})
					.then((res) => {
						console.log(res, "resres");
						if (res.data.group.muteAllMembers) {
							this.$message.info("该群群主已设置禁言");
							return;
						} else {
							event.preventDefault();
							if (!this.chatText) return this.$message.info("请输入内容");
							if (this.chatText.split(" ").join("").length == 0)
								return this.$message.info("请输入内容");
							let sendMessage = {
								to: this.pitchChatGroup.groupId,
								conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
								cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
								payload: {
									text: this.chatText,
								},
							};
							let message = this.chat.createTextMessage(sendMessage);
							let promise = this.chat.sendMessage(message);
							promise
								.then(function(imResponse) {
									that.chatListGroup.push(imResponse.data.message);
									that.chatText = "";
									that.scrollToBottom();
								})
								.catch(function(imError) {
									// 发送失败

									if (imError.code == 20007) {
										that.$message.error("您已被对方拉黑");
									}

									if (imError.code == 10010) {
										that.$message.error("群聊已解散!");
										that.chat.deleteConversation('GROUP' + that.pitchChatGroup.groupId);
										that.groupChatChild = false;
										that.tabTypeChange(0)
									}
									console.log(imError,'???????')
									if (imError.code == 10007) {
										that.$message.error("无操作权限");
									}
									
									that.getConversationList();
								});
						}
					}).catch(function(imError) {
						// 发送失败

						if (imError.code == 20007) {
							that.$message.error("您已被对方拉黑");
						}

						if (imError.code == 10010) {
							that.$message.error("群聊已解散!");
							that.groupChatChild = false;
							that.tabTypeChange(0)
							that.chat.deleteConversation('GROUP' + that.pitchChatGroup.groupId);
						}
						if (imError.code == 10007) {
							that.$message.error("无操作权限");
						}
						
						that.getConversationList();
					});
			},

			// 发送消息
			async sendTextChange(event) {
				let that = this;
				let arr2 = [];
				event.preventDefault();
				// this.test();
				// event.preventDefault();
				await this.chat
					.getBlacklist()
					.then(function(imResponse) {
						imResponse.data.forEach((item) => {
							if (item == that.atuserObj.userProfile.userID) {
								arr2.push(item);
							}
						});
					})
					.catch(function(imError) {
						console.warn("getBlacklist error:", imError); // 获取黑名单列表失败的相关信息
					});

				if (!this.chatText) return this.$message.info("请输入内容");
				if (this.chatText.split(" ").join("").length == 0)
					return this.$message.info("请输入内容");
				let sendMessage = {
					to: this.atuserObj.userProfile.userID,
					conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						text: this.chatText,
					},
				};
				let message = this.chat.createTextMessage(sendMessage);
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						that.chatList.push(imResponse.data.message);
						that.chatText = "";
						that.scrollToBottom();
					})
					.catch(function(imError) {
						// 发送失败
						console.log(imError, "imError");
						if (imError.code == 20007) {
							that.$message.error("您已被对方拉黑");
						}
					});
				// }
			},
			// jianting

			// 调起选择文件
			submitUpload() {
				this.$refs.upload.clearFiles();
			},
			// 上传成功
			handleAvatarSuccess(res) {
				let type = res.data.url.slice(-3);
				if(type == 'mp4'){
					this.sendShiping(res.data.url);
				}else{
					this.sendimg(res.data.url);
				}
			},
			handleGrouppSuccess(res) {
				let type = res.data.url.slice(-3);
				if(type == 'mp4'){
					this.sendvideoGroup(res.data.url);
				}else{
					this.sendimgGroup(res.data.url);
				}
			},
			handleGrouppAct() {
				let that = this;
				let message = this.chat.createFileMessage({
					to: this.groupContent.groupID,
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatListGroup.push(imResponse.data.message);
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			handlePictureCardPreview(e) {
				console.log(e, "eeeee");
				this.dialogImageUrl = e.data.url;
				this.dialogVisible = true;
			},
			handleC2CAct() {
				let that = this;
				let message = this.chat.createFileMessage({
					to: this.atuserObj.userProfile.userID,
					conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatList.push(imResponse.data.message);
						that.scrollToBottom();
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			handleAvatarSuccess1(res) {
				console.log(res, "rsesrse");
				this.sendShiping(res.data.url);
				// if (res.code == 1) {
				//   this.fullscreenLoading = false;
				//   this.reportImgList.push(res.data.url);
				//   this.isSHowTu = false;
				// }
			},
			
			// 群聊发视频
			sendvideoGroup(url) {
				let that = this;
				let message = this.chat.createVideoMessage({
					to: this.groupContent.groupID,
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatListGroup.push(imResponse.data.message);
						that.scrollToBottom();
						console.log(imResponse, "发送文件");
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			
			// 群聊发图片
			sendimgGroup(url) {
				let that = this;
				let message = this.chat.createImageMessage({
					to: this.groupContent.groupID,
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatListGroup.push(imResponse.data.message);
						that.scrollToBottom();
						console.log(imResponse, "发送文件");
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			
			// 发送视频
			sendShiping(url) {
				let that = this;
				let message = that.chat.createVideoMessage({
					to: that.atuserObj.userProfile.userID,
					conversationType: that.TencentCloudChat.TYPES.CONV_C2C,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: that.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatList.push(imResponse.data.message);
						that.scrollToBottom();
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			vipSkipChange() {
				this.$emit("vipSkip");
			},
			// 发送图片
			sendimg(url, dom) {
				let that = this;
				let message = this.chat.createImageMessage({
					to: this.atuserObj.userProfile.userID,
					conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: dom || this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatList.push(imResponse.data.message);
						that.scrollToBottom();
						console.log(imResponse, "发送图片");
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},

			sendimgGroup(url, dom) {
				let that = this;
				let message = this.chat.createImageMessage({
					to: this.groupContent.groupID,
					conversationType: this.TencentCloudChat.TYPES.CONV_GROUP,
					cloudCustomData:JSON.stringify({vipFlag:this.myUser.vipFlag}),
					payload: {
						file: dom || this.files1,
					},
					onProgress: function(event) {
						console.log("file uploading:", event);
					},
				});
				// 2. 发送图片
				let promise = this.chat.sendMessage(message);
				promise
					.then(function(imResponse) {
						// 发送成功
						that.chatListGroup.push(imResponse.data.message);
						that.scrollToBottom();
						console.log(imResponse, "发送文件");
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},

			photoWallSuccess(res) {
				console.log(res.data.url, "打首饰");
				// if (!this.loadType) return this.$message.info("上传中");

				if (res.code == 1) {
					// this.loadType = false;
					this.complainArr.push(res.data.url);
					//   this.$api
					//     .userImgAdd({
					//       imgPath: res.data.url,
					//     })
					//     .then((res) => {
					//       if (res.code == 1) {
					//         this.getInfo();
					//       } else {
					//         this.loadType = true;
					//       }
					//     });
				}
			},
			// 联系大神
			selectChat(i, index, id) {
				console.log(i, "issbsbs");
				let that = this;
				this.atuserObj = i;
				this.userChatType = true;
				this.userChatTypeChild = true;
				// groupChatChild:false,
				this.$api
					.sendShortVideos({
						othUserId: id,
					})
					.then((res) => {
						this.isSendShiping = res.data.status == 1 ? true : false;
					});
				this.$api.userMainInfo({
					otherUserId: id
				}).then((res) => {
					if (res.code == 1) {
						this.pitchChatUser = res.data;
						this.value = this.pitchChatUser.groupId;
						this.manitoSkillData = res.data.userSkillVos[0];
						let options = {
							conversationID: "C2C" + id,
							direction: 0,
						};
						this.chat.setMessageRead(options);
						let promise = this.chat.getMessageListHopping({
							conversationID: "C2C" + id,
						});
						promise
							.then(function(imResponse) {
								// 获取成功
								imResponse.data.messageList.forEach((item) => {
									if (item.type == "TIMCustomElem") {
										let data = JSON.parse(item.payload.data).data;
										if (data) {
											if (JSON.parse(data).call_end) {
												item.call_end = JSON.parse(data).call_end;
											}
											
											console.log(item);
										}
									}
								});
								that.chatList = imResponse.data.messageList;
								this.scrollToBottom()
								console.log(that.chatList, "bsbsb");
							})
							.catch(function(imError) {
								console.warn("getConversationProfile error:", imError); // 获取会话资料失败的相关信息
							});
						if (index) {} else {
							this.getConversationList();
						}
					}
				});
			},
			goSelect(i, index, id) {
				console.log(i, index, id, "ssssssbbbb");
				i.userProfile = {
					userID: id,
				};
				this.isHide = false;
				this.listid = id;
				this.userChatType = true;
				this.tabUserImg = false;
				this.groupDetail = false;
				this.frendsDetail = false;
				this.pitchChatGroup = '';
				i.type = "C2C";
				this.selectChat(i, "", id);
				// this.myMessageShow = true;
			},
			// 下载文件
			downFile(item) {
				let url = item.fileUrl; // public 目录下的文件的 URL
				let link = document.createElement("a"); // 创建一个 <a> 元素
				link.href = url; // 设置链接的 href 属性为文件的 URL
				link.download = item.fileName; // 设置下载的文件名
				link.style.display = "none"; // 隐藏链接
				document.body.appendChild(link); // 将链接添加到文档中
				link.click(); // 模拟点击链接以触发下载
				document.body.removeChild(link); // 下载完成后移除链接
			},
			// 选中用户聊天
			selectUserChat(i, index, flag) {

				
				let that = this;
				if(i.userProfile && i.userProfile.userID){
					this.listid = i.userProfile.userID;
				}else{
					this.listid = i.conversationID;
				}
				this.disturbZhiDing = i.isPinned ? 1 : 0;
				this.isHide = false;
				this.groupChatChild = false;
				// messageRemindType;
				this.disturbUser = i.messageRemindType == "AcceptNotNotify" ? 1 : 0;
				this.disturbGroup = i.messageRemindType == "AcceptNotNotify" ? 1 : 0;
				this.groupDetail = false;
				this.frendsDetail = false;
				this.groupUserVip = false;
				this.groupShow3 = false;

				if (i.type == "C2C") {
					this.complainData.complainObjectId = i.userProfile.userID;
					this.complainData.type = 1;
					this.$api
						.sendShortVideos({
							othUserId: i.userProfile.userID ? i.userProfile.userID : i.id,
						})
						.then((res) => {
							this.isSendShiping = res.data.data == 1 ? true : false;
						});
					if (flag) {
						this.dialogueList.forEach((item) => {
							console.log(item, "itemitem");
							if (item.type == "C2C") {
								if (item.userProfile.userID == i.userProfile.userID) {
									i.conversationID = item.conversationID;
								}
							}
						});
					}
					let otherUserId = "";
					this.atuserObj = i;
					if (index) {
						otherUserId = i.id;
					} else {
						otherUserId = i.userProfile.userID;
					}
					this.atPresent = index - 1;
					this.userChatType = true;
					this.userChatTypeChild = true;
					this.groupChatChild = false;
					this.groupType = false;
					this.$api.userMainInfo({
						otherUserId: otherUserId
					}).then((res) => {
						if (res.code == 1) {
							this.pitchChatUser = res.data;
							this.value = this.pitchChatUser.groupId;
							this.manitoSkillData = res.data.userSkillVos[0];
							if (this.pitchChatUser.withdrawStatus != 1) {
								this.shuangxiangchehui = 0
							} else {
								this.shuangxiangchehui = 1
							}

							let options = {
								conversationID: index ? "C2C" + i.id : i.conversationID,
							};
							this.chat.setMessageRead(options);

							let promise = this.chat.getMessageListHopping({
								conversationID: index ? "C2C" + i.id : i.conversationID,
								direction: 0,
							});
							promise
								.then(function(imResponse) {
									// 获取成功ss
									imResponse.data.messageList.forEach((item) => {
										if (item.type == "TIMCustomElem") {
											let data = JSON.parse(item.payload.data).data;
											if (data) {
												if (JSON.parse(data).call_end) {
													item.call_end = JSON.parse(data).call_end;
												}
											}
										}
									});
									that.chatList = imResponse.data.messageList;
									console.log(that.chatList,'单聊聊天记录')
									that.scrollToBottom()
								})
								.catch(function(imError) {
									console.warn("getConversationProfile error:", imError); // 获取会话资料失败的相关信息
								});
							if (index) {} else {
								this.getConversationList();
							}
						}
					});
					
					return
				}

				if (i.type == "GROUP") {
					this.groupContent = i.groupProfile;
					this.complainData.complainObjectId = i.groupProfile.groupID;
					this.complainData.type = 2;
					this.$api
						.groupDetails({
							groupId: i.groupProfile.groupID
						})
						.then((res) => {
							if (res.code == 1) {
								this.userChatType = true;
								this.groupChatChild = true;
								this.userChatTypeChild = false;
								this.groupType = true;
								this.pitchChatGroup = res.data;
								this.qunName = this.pitchChatGroup.groupName;
								this.CutuserName = this.pitchChatGroup.userNickName;
								console.log(this.pitchChatGroup, "this.pitchChatGroup");
							} else {
								if (res.code == 0 && res.msg == '群组不存在') {
									this.$message.error('群聊已解散')
									this.groupChatChild = false;
									this.tabTypeChange(0)
									this.chat.deleteConversation('GROUP' + i.groupProfile.groupID.groupId);
									this.getConversationList();
								}
							}
						});
					let options = {
						conversationID: index ? i.conversationID : i.conversationID,
					};
					this.chat.setMessageRead(options);
					let promise = this.chat.getMessageList({
						conversationID: i.conversationID,
						direction: 0,
					});
					promise.then(function(imResponse) {
						// 获取成功ss
						imResponse.data.messageList.forEach((item) => {
							if (item.type == "TIMCustomElem") {
								let data = JSON.parse(item.payload.data).data;
								if (data) {
									// if (JSON.parse(data).call_end) {
									//   item.call_end = JSON.parse(data).call_end;
									// }
								}
							}
						});
						imResponse.data.messageList.forEach((item) => {
							// if(item.)
							if (item.cloudCustomData) {
								console.log(JSON.parse(item.cloudCustomData).address, "itemitem");
							}
						});
						that.chatListGroup = imResponse.data.messageList;
						console.log(that.chatListGroup, '群聊天记录111')
					});
				}
				this.$nextTick(() => {
					setTimeout(() => {
						let container = document.querySelector(".userChat_content"); //找到当前div
						// var container = document.getElementById("message-container");
						container.scrollTop = container.scrollHeight;
					}, 400);
				});

				this.$forceUpdate();
			},
			//关闭页面
			closeMessage() {
				this.$emit("closeMessage");
			},
			// 切换消息类型
			tabTypeChange(type) {
				this.userChatType = true;
				this.atPresent = "";
				this.tabType = type;
				this.groupUserVip = false;
				this.groupShow3 = false;
				this.frendsDetail = false;
				this.failChat = false;
				this.isHide = false;
				if (this.tabType == 0) {
					this.isTabs = true;
					this.queryLastMessage(); //最新消息
					this.getConversationList();
				} else if (this.tabType == 1) {
					this.isTabs = true;
					// 我的关注
					this.followOrFansList(1);
				} else if (this.tabType == 2) {
					this.isTabs = true;
					// 我的粉丝
					this.followOrFansList(0);
				} else if (this.tabType == 3) {
					this.isTabs = false;
					this.tongList();
					this.getMyFriendsData();
				}
			},
			//获取用户是否可以发视频
			getUserSendShiping() {
				this.$api.sendShortVideos().then((res) => {
					console.log(res);
				});
			},
			//关注&&粉丝列表
			followOrFansList(type) {
				let data = {
					pageNum: 1,
					pageSize: 999,
					onlineType: 0, //	状态 0-全部 1-在线
					type: type, //	类型0-粉丝 1-关注
				};
				this.$api.followOrFansList(data).then((res) => {
					if (res.code == 1) {
						this.attentionlist = res.data.rows;
					}
				});
			},
			// 各类型最新消息
			queryLastMessage() {
				this.$api.queryLastMessage().then((res) => {
					// console.log("最新消息", res);
					if (res.code == 1) {
						this.newestMessage = res.data;
					}
				});
			},
			// 消息列表
			queryAllList(i, type) {
				console.log(i, type, "sssbb");
				this.isTabs = true;
				this.groupChatChild = false;
				this.userChatTypeChild = false;
				this.userChatType = true;
				this.groupDetail = false;
				this.frendsDetail = false;
				this.groupUserVip = false;
				this.groupShow3 = false;

				this.pitchMessageType = i;
				this.atPresent = type;
				let data = {
					pageNum: 1,
					pageSize: 999,
					type: type, //消息类型: 1平台公告；2系统通知；3订单消息；4派单消息；5互动消息 6公会消息 7:群組消息
				};
				this.$api.queryAllList(data).then((res) => {
					if (res.code == 1) {
						this.messageList = res.data.rows;
						this.$api.readByType({
							type: type
						}).then((res) => {
							if (res.code == 1) {
								this.getUnreadCount();
							}
						});
					}
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	
	.nime-view{
		color:#999;
		font-size: 10px;
		position: absolute;
		top: -16px;
		left: 14px;
		width: 200px;
	}
	
	.xiazai{
		width:20px;
		height:20px;
		position: absolute;
		right:2px;
		bottom:2px
	}
	
	.new-bg{
		background: #f5f5f5;
		padding: 10px 0;
		margin-top: 6px;
		border-radius: 4px;
	}
	
	.new-bg-view{
		padding: 10px 0;
		margin-top: 6px;
	}
	.new-bg-view:hover{
		background: #f5f5f5;
		border-radius: 4px;
	}
	
	//下单弹窗样式
	.placingThePopup_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($color: #000000, $alpha: 0.6);

		.placingThePopup_box {
			width: 522px;
			background: #fff;
			border-radius: 4px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.title_content {
				>img {
					width: 13px;
					height: 13px;
				}
			}

			.llacingCont_box {
				padding: 0 20px 16px 20px;

				.topUp_box1 {
					width: 382px;
					margin: 48px auto;
				}

				.topUp_box1Btn {
					width: 256px;
					height: 40px;
					background: #ffdd00;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					margin: 0 auto 31px;
					font-size: 14px;
					font-family: PingFang SC-Medium, PingFang SC;
					font-weight: 500;
					color: #333333;
					text-align: center;
					line-height: 40px;
				}

				.discount_icon {
					height: 16px;
					padding: 0 4px;
					margin-left: 10px;
					color: #fff;
					font-size: 10px;
					background: linear-gradient(109deg, #e17616 0%, #ff9b41 100%);
					border-radius: 4px;
				}

				.llacingCont_title {
					color: #333;
					font-size: 14px;
					padding: 17px 0;
				}

				.llacingCont_table {
					width: 100%;
					font-size: 14px;
					color: #333;
					border-top: 1px solid #f5f5f5;
					border-left: 1px solid #f5f5f5;

					.llacingCont_tr {
						display: flex;
					}

					.llacingCont_heder {
						flex: 1;
						height: 36px;
						background: #f5f5f5;
						border-right: 1px solid #f5f5f5;
						border-bottom: 1px solid #f5f5f5;
					}

					.llacingCont_td {
						flex: 1;
						height: 80px;
						border-right: 1px solid #f5f5f5;
						border-bottom: 1px solid #f5f5f5;
					}

					.llacingCont_head {
						width: 48px;
						height: 48px;
						margin-right: 10px;
						border-radius: 8px;
					}
				}

				.llacingCont_remark {
					width: 430px;
					height: 36px;
					font-size: 14px;
					color: #333;
					padding-left: 10px;
					background-color: #fff;
					border: 1px solid #f5f5f5;
				}
			}

			.hint {
				font-size: 10px;
				color: #4177dd;
				margin-top: 32px;
			}

			.cutOffRule {
				width: 705px;
				margin: 10px 0 16px 0;
				border-top: 1px dashed #eee;
			}

			.topUp_box {
				width: 100%;
				justify-content: space-between;
				font-size: 12px;
				color: rgba(142, 146, 177, 1);

				.balanceNum {
					margin-right: 7px;
				}
			}

			.diamondNum {
				color: #f68229;
				font-size: 24px;
			}

			.topUpBtn {
				width: 48px;
				height: 20px;
				color: #333333;
				margin-left: 11px;
				background: #ffdd00;
				border-radius: 10px;
			}

			.addBtn {
				width: 88px;
				height: 30px;
				color: #333;
				font-size: 14px;
				font-weight: bold;
				margin-left: 24px;
				background: #ffdd00;
				border-radius: 44px;
			}
		}
	}

	.jinquanxiaoxi {
		width: 100%;
		text-align: center;
		font-size: 12px;
		color: #999
	}

	.my_content-weizhi-title {
		font-size: 12px;
		color: #fff;
		margin-bottom: 6px;
		font-weight: 500;
	}

	.my_content-weizhi-content {
		font-size: 10px;
		color: #999;
		margin-bottom: 4px;
		font-weight: 400;
	}

	.my_content-weizhi {
		background: #31364c;
		padding: 8px;
		border-radius: 6px 6px 0px 0px;
		max-width: 200px;
		min-width: 150px;
		z-index: 9999;
		cursor: pointer;
	}

	.cursor {
		cursor: pointer;
	}

	.userNum {
		margin-left: auto;
		color: #666;
		font-size: 12px;
	}

	.fifleCreate {

		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 10px;
		color: #999999;
		margin-top: 2px;
	}

	.fifleName {
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #333333;
		max-width: 120px;

	}

	.fileNameName {
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #666666;
		max-width: 120px;
	}

	.groupBoxName {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 16px 0 16px;
	}

	.userSeachVip {
		width: 280px;
		height: 36px;
		background-color: #f5f5f5;
		border-radius: 4px 4px 4px 4px;
		border: none;
		margin: 16px;
		border: solid 1px #999;
	}

	.userClickFlex {}

	.userInfoImgs {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		box-sizing: border-box;
		padding: 12px 18px;
	}

	.groupTextName {
		width: 44px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		white-space: nowrap;
		/* 确保文本在一行内显示 */
		overflow: hidden;
		/* 隐藏溢出的内容 */
		text-overflow: ellipsis;
		/* 使用省略号表示文字被截断 */
		text-align: center;
	}

	.Signature {
		width: 100px;
		white-space: nowrap;
		/* 确保文本在一行内显示 */
		overflow: hidden;
		/* 隐藏溢出的内容 */
		text-overflow: ellipsis;
		/* 使用省略号表示文字被截断 */
		text-align: center;
	}

	.userPeople {
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #333333;
	}

	.groupFlex {
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
	}

	.message_flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 24px;
	}

	.orderCancel {
		width: 92px;
		height: 40px;
		background: #f8f8f8;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.orderSuccess {
		width: 96px;
		height: 40px;
		background: #ffdd00;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		margin-right: 24px;
		margin-left: 12px;
	}

	.selectPeop {
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 12px;
		color: #999999;
	}

	.popLeft {}

	.popRight {
		width: 100%;
		height: 240px;
		overflow-y: auto;
	}

	.groupImgPosition {
		width: 16px;
		height: 16px;
	}

	.userMassageAva {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-left: 16px;
	}

	.addSuccessUser {
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #333333;
	}

	.groupImg {
		width: 32px;
		height: 32px;
		border-radius: 50%;
	}

	.groupName {
		color: #333;
		align-items: center;
	}

	.groupSuccess {
		width: 278px;
		height: 40px;
		background: #ffdd00;
		border-radius: 4px 4px 4px 4px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #212121;
		text-align: center;
		line-height: 40px;
		margin: 40px auto 0;
		cursor: pointer;
	}

	.messageInp {
		border: none;
		padding-left: 10px;
	}

	.searchInp {}

	.complaintText {
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #212121;
		margin-top: 32px;
	}

	.complainTextArea {
		width: 360px;
		height: 108px;
		padding: 8px 10px;
		background-color: rgba(248, 248, 248, 1);
		border: none;
		margin-top: 10px;
	}

	.popUp_message {
		width: 320px;
		height: 605px;
		background-color: #fff;
	}

	.popUp_header {
		width: 100%;
		height: 53px;
		background: #f8f8fa;
	}

	.sendMassage {
		width: 140px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #212121;
		background-color: rgba(255, 221, 0, 1);
		border: 1px solid rgba(255, 221, 0, 1);
		margin-left: 12px;
		cursor: pointer;
	}

	.shareBtn {
		width: 100px;
		height: 36px;
		background: #ffffff;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #1b85fb;
		text-align: center;
		line-height: 36px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #1b85fb;
		margin-left: 69px;
		cursor: pointer;
	}

	.arrdessData {
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
	}

	.massageBtn {
		width: 266px;
		height: 40px;
		background: #f2f2f2;
		border-radius: 4px 4px 4px 4px;
		color: rgba(33, 33, 33, 1);
		display: flex;
		margin: 0 auto;
		justify-content: space-between;
		padding: 4px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.messagePerson {
		background: rgba(133, 188, 254, 1);
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		font-size: 10px;
		color: #ffffff;
		border-radius: 15px 15px 15px 15px;
	}

	.massageBtnFlex {
		width: 86px;
		height: 32px;
		background: #212121;
		border-radius: 4px 4px 4px 4px;
		color: #fff;
		text-align: center;
		line-height: 32px;
	}

	.massageBtnFlexFriend {
		width: 86px;
		height: 32px;
		border-radius: 4px 4px 4px 4px;
		color: #333;
		text-align: center;
		line-height: 32px;
	}

	.allMsg_box {
		width: 100%;
		height: 650px;
		overflow-y: auto;
	}

	.img_style {
		width: 360px;
		margin-top: 12px;
		border-radius: 8px;
	}

	.renewalBtn {
		width: 120px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: #8458f4;
		border-radius: 8px;
		margin: 16px 16px;
		cursor: pointer;
	}

	.num_icon {
		width: 18px;
		height: 18px;
		color: #fff;
		position: absolute;
		border-radius: 50%;
		top: 10px;
		left: 45px;
		display: flex;
		font-size: 12px;
		text-align: center;
		align-items: center;
		justify-content: center;
		background-color: red;
	}

	.send_style {
		margin-top: 8px;
		color: #8e92b1;

		>p {
			line-height: 18px;
		}
	}

	//大神技能
	.manitoSkill {
		width: 603px;
		height: 85px;
		margin: 0 auto;
		padding: 0 15px;
		margin-top: 20px;
		background: rgba($color: #ffa700, $alpha: 0.3);
		border-radius: 14px;
		box-sizing: border-box;

		.order_btn {
			width: 88px;
			height: 36px;
			font-size: 14px;
			color: #212121;
			background: linear-gradient(102deg, #ffa700 0%, #ffdd00 100%);
			border-radius: 18px;
		}

		.discounts_icon {
			width: 45px;
			height: 20px;
			position: absolute;
			top: -18px;
			right: -40px;
			color: #fff;
			font-size: 12px;
			background: url(../../image/message/22.png) no-repeat;
		}
	}

	//举报弹窗
	.report_title {
		width: 679px;
		height: 36px;
		color: #ff7268;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 114, 104, 0.1);
	}

	.report_from {
		width: 320px;
		margin: 0 180px;

		.report_fromTitle {
			color: #000;
			font-size: 14px;
			margin: 20px 0 10px 0;
			text-align: left;
		}

		.report_btn {
			width: 320px;
			height: 40px;
			color: #333;
			margin: 30px 0 40px 0;
			background: rgba(255, 221, 0, 1);
			border-radius: 4px;
		}
	}

	.imgBox {
		width: 100px;
		height: 100px;
		border-radius: 8px;
		margin-right: 10px;
		position: relative;
		overflow: hidden;

		.imgDeleIcon {
			position: absolute;
			top: 8px;
			right: 8px;
		}
	}

	.popUp_box3 {
		width: 320px;
		height: 605px;
		position: absolute;
		z-index: 9999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
	}

	.popUp_box2 {
		width: 886px;
		height: 554px;
		position: absolute;
		z-index: 9999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-size: 100% 100%;
		background-image: url(../../static/to3.png);
	}

	.popUp_TitleBottom {
		width: 100%;
		height: 128px;
		background: rgba(57, 57, 57, 0.8);
		position: absolute;
		bottom: 0;
	}

	.chooseLiao {
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
	}

	.tongxunText {
		font-weight: 400;
		font-size: 10px;
		color: #999999;
		max-width: 200px;
	}

	.popUp_TitleBottomFlex {
		display: flex;
		width: calc(100% - 578px);
		margin: 24px auto;
		justify-content: space-between;
	}

	.popUp_Title {
		width: calc(100% - 48px);
		margin: 24px auto 0;
		display: flex;
		justify-content: space-between;
	}

	.popUp_TitleImg {
		width: 48px;
		height: 48px;
		border-radius: 50%;
	}

	.popUp_TitleFlex {
		display: flex;
		align-items: center;
		margin-left: 10px;
	}

	// 弹窗背景
	.popUp_box1 {
		width: 442px;
		height: 788px;
		position: absolute;
		z-index: 9999;
		background-size: 100% 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.popUp_box {
		width: 1920px;
		height: 1080px;
		background: rgba($color: #000000, $alpha: 0.8);
		position: absolute;
		top: 0;
		left: 0%;
		z-index: 1;

		.gruopRemid {
			background-image: url(../../static/user68.png);
			background-size: 100% auto;
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.popup_conent {
			width: 302px;
			height: 308px;
			position: relative;
			background: #fff;
			border-radius: 8px;
			margin: 0 auto;
		}

		.sendCond {
			width: 100%;
			text-align: center;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 12px;
			color: rgba(102, 102, 102, 1);
		}
		
		.proviceBtn-tow{
			width: 150px;
			height: 40px;
			background: rgb(73, 129, 250);
			color:#fff;
			border-radius: 4px 4px 4px 4px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 14px;
			text-align: center;
			line-height: 40px;
			margin: 24px auto 0;
			cursor: pointer;
			margin-left: 14px;
		}

		.proviceBtn {
			width: 150px;
			height: 40px;
			background: #fedd32;
			border-radius: 4px 4px 4px 4px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 14px;
			color: #212121;
			text-align: center;
			line-height: 40px;
			margin: 24px auto 0;
			cursor: pointer;
			margin-right: 0px;
		}

		.sendImgFlexTou {
			width: 64px;
			height: 64px;
			border-radius: 50%;
		}

		.sendTou {
			width: 64px;
			height: 64px;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.sendName {
			width: 100%;
			text-align: center;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 16px;
			color: #212121;
			margin-top: 44px;
		}

		.sendId {
			width: 100%;
			text-align: center;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 12px;
			color: #999999;
		}

		.sendImgFlex {
			width: 130px;
			height: 130px;
		}

		.remid_box {
			width: 522px;
			position: absolute;
			top: 40%;
			left: 50%;
			font-size: 14px;
			transform: translate(-50%, -50%);
			background: #fff;
			border-radius: 4px;
		}

		.contentSi1 {
			width: 334px;
			margin: 0px auto 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
		}

		.contentSi {
			width: 334px;
			margin: 0px auto 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #f5f5f5;
			padding: 12px 10px;
			box-sizing: border-box;
		}

		.BtnSubmit {
			width: 256px;
			height: 40px;
			background: #ffdd00;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			text-align: center;
			line-height: 40px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			color: #333333;
			margin: 0 auto;
		}

		.remind_btn {
			width: 176px;
			height: 40px;
			background: rgba(255, 221, 0, 1);
			border-radius: 4px 4px 4px 4px;
			color: #333;
			cursor: pointer;
		}
	}

	//聊天内容
	.chat_box {
		color: #333333;
		font-size: 14px;
		margin-top: 18px;

		.my {
			align-items: flex-start;
			justify-content: flex-end;
		}

		.other {
			align-items: flex-start;
			justify-content: flex-start;
		}

		.userHead {
			width: 45px;
			height: 45px;
			margin: 0 10px;
			border-radius: 50%;
			// box-shadow: 0 -1px 0 1px #eee;
			border: solid #eee 1px;
		}

		.my_content {
			max-width: 500px;
			position: relative;
			padding: 10px 13px 10px 13px;
			background: #FFDD00;
			border-radius: 8px 8px 8px 8px;
			color: #212121;
			margin-right: 4px;
		}

		.my_content::before {
			content: "";
			display: inline-block;
			position: absolute;
			right: -7px;
			top: 6px;
			border-top: solid 7px transparent;
			border-left: solid 7px #fff;
			border-bottom: solid 7px transparent;
		}

		.other_content {
			max-width: 500px;
			padding: 10px 13px 10px 13px;
			background: #F5F5F5;
			border-radius: 8px 8px 8px 8px;
			position: relative;
			color: #333;
			min-width: 12px;
		}

		.other_content::after {
			content: "";
			display: inline-block;
			position: absolute;
			left: -7px;
			top: 6px;
			border-top: solid 7px transparent;
			border-right: solid 7px #fff;
			border-bottom: solid 7px transparent;
		}

		.chatImgUrl {
			max-width: 450px;
			height: auto;
		}
	}

	.userChat_age {
		width: 62px;
		height: 22px;
		margin: 0 13px;
		color: #333;
		font-size: 12px;
		border-radius: 11px;
	}

	//用户聊天
	.sendBtn {
		width: 68px;
		height: 32px;
		font-size: 14px;
		color: #212121;
		background: #ffdd00;
		border-radius: 7px;
		position: absolute;
		right: 40px;
		bottom: 40px;
		cursor: pointer;
	}

	.userChat_top {
		width: 100%;
		height: 78px;
		color: #333;
		align-items: center;
		padding: 16px 16px;
		box-sizing: border-box;
		border-bottom: 1px solid #eee;
		background: #f5f5f5;

		.pitchChatUserName {
			font-size: 18px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #212121;
		}

		.userChat_topIcon {
			height: 22px;
			padding: 0 8px;
			font-size: 12px;
			display: flex;
			align-items: center;
			background: rgba(0, 0, 0, 0.4);
			margin-right: 15px;
			border-radius: 11px 11px 11px 11px;
			color: #fff;
		}

		.userChat_attentionIcon {
			width: 70px;
			height: 28px;
			font-size: 16px;
			background: linear-gradient(91deg, #ffa700 0%, #ffdd00 100%);
			border-radius: 8px 2px 8px 2px;
		}

		.userChat_img {
			width: 23px;
			height: 23px;
			margin-left: 20px;
			cursor: pointer;
		}
	}

	.userChat_content {
		width: 100%;
		height: 490px;
		padding: 10px 0 20px 0;
		overflow-y: auto;
		box-sizing: border-box;
	}

	.userChat_inputBox {
		position: relative;

		padding: 0 0px 0px 0px;
		// border-top: 1px solid #666;

		.iconImg {
			width: 22px;
			height: 22px;
			margin: 12px 0;
			cursor: pointer;
		}
	}

	.iconFlex {
		display: flex;
		flex-wrap: wrap;
	}

	.iconFlex>div {
		margin-left: 6px;
	}

	.send_status {
		width: 40px;
		color: #e78034;
		font-size: 12px;
		position: absolute;
		top: 0;
	}

	.roomSkip {
		width: 121px;
		height: 31px;
		font-size: 14px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
		border-radius: 8px;
		position: absolute;
		bottom: 0;
		left: -80px;
		cursor: pointer;
	}

	.messageType_box {
		width: 660px;
		height: 730px;
		// margin: 13px 0 0 5px;
		background: rgba(255, 255, 255, 0.04);
		// border-radius: 10px;
		// padding-top: 10px;
		overflow: hidden;

		.messageTitle {
			font-size: 16px;
			padding: 18px 24px;
			color: #333;
		}

		.content_box {
			width: 500px;
			padding: 10px;
			color: #212121;
			background: #f5f5f5;
			border-radius: 8px;
		}
	}

	.page1 {
		z-index: 999;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 16px;
		color: #fff;
		background: rgba($color: #000000, $alpha: 0.8);
	}

	.message_box {
		width: 1106px;
		height: 798px;
		background: #f5f5f5;
		border-radius: 8px 8px 0px 0px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.left_tab {
			width: 121px;
			height: 752px;
			background: #faf8ec;
			display: flex;
			flex-direction: column;
			align-items: center;

			.head {
				width: 63px;
				height: 63px;
				border-radius: 50%;
				margin: 24px 0 0 0;
			}

			.tab_item {
				width: 90px;
				height: 90px;
				color: #333;
				font-size: 12px;
				margin: 0 auto;
				margin-top: 24px;
				background: #fff;
				border-radius: 8px;
				cursor: pointer;

				>img {
					margin-bottom: 8px;
				}
			}

			.tab_paht {
				background: linear-gradient(138deg, #ffa700 0%, #ffdd00 100%);
			}
		}

		.message_list {
			width: 300px;
			height: 725px;
			margin: 0px 10px 0 12px;
			background: #F5F5F5;
			box-sizing: border-box;
			overflow-y: auto;
			overflow-x: hidden;
			
			.message_item-active{
				width: 291px;
				height: 64px;
				padding: 0 16px;
				color: #333;
				margin-bottom: 10px;
				background: #fff;
				align-items: center;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;
				cursor: pointer;
				// border-bottom: solid 1px #eee;
				// padding-bottom: 10px;

				.message_head {
					width: 40px;
					height: 40px;
					margin-right: 10px;
					border-radius: 50%;
					border: solid 1px #eee;
				}

				.message_icon {
					width: 20px;
					height: 20px;
				}
			}

			.message_item:hover{
				width: 291px;
				height: 64px;
				padding: 0 16px;
				color: #333;
				margin-bottom: 10px;
				background: #fff;
				align-items: center;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;
				cursor: pointer;
			}
			
			.message_item {
				width: 291px;
				height: 64px;
				padding: 0 16px;
				color: #333;
				margin-bottom: 10px;
				background: #F5F5F5;
				align-items: center;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;
				cursor: pointer;
				// border-bottom: solid 1px #eee;
				// padding-bottom: 10px;

				.message_head {
					width: 40px;
					height: 40px;
					margin-right: 10px;
					border-radius: 50%;
					border: solid 1px #eee;
				}

				.message_icon {
					width: 20px;
					height: 20px;
				}
			}

			.message_pitch {
				background: rgba($color: #fff, $alpha: 0.1);
			}

			.unreadNum {
				width: 18px;
				height: 18px;
				font-size: 12px;
				background-color: red;
				text-align: center;
				border-radius: 50%;
				position: absolute;
				left: 16%;
			}
		}

		.message_content {
			width: 660px;
			height: 752px;
			position: relative;
			background: #fff;

			.vacancy {
				width: 211px;
				height: 211px;
				position: absolute;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.messageContent {
			width: 360px;
			height: 282px;
			background: #f4f4f4;
			border-radius: 8px 8px 8px 8px;
			margin: 81px auto 0;
		}

		.touTitle {
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 12px;
			color: #1b85fb;
			padding-left: 16px;
			cursor: pointer;
		}

		.touTitleRight {
			width: 85px;
			height: 26px;
			background: url(../../static/user28.png) no-repeat;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #666666;
			text-align: center;
			line-height: 26px;
			cursor: pointer;
		}

		.titleDur {
			width: 100%;
			text-align: center;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #999999;
			max-width: 320px;
		}

		.messageLine {
			width: 616px;
			height: 1px;
			background-color: rgba(238, 238, 238, 1);
			margin: 24px auto 0;
		}
	}

	.pageSex1 {
		width: 34px;
		height: 16px;
		background: rgb(255, 128, 124);
		border-radius: 15px 15px 15px 15px;
		opacity: 1;
		text-align: center;
		line-height: 16px;
		font-size: 10px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		display: flex;
		align-items: center;
	}

	.photowall_box {
		width: 350px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;
	}

	.pageSex {
		width: 34px;
		height: 16px;
		background: #85bcfe;
		border-radius: 15px 15px 15px 15px;
		opacity: 1;
		text-align: center;
		line-height: 16px;
		font-size: 10px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		display: flex;
		align-items: center;
	}

	.inpMassage {
		width: 232px;
		height: 36px;
		background: #f2f2f2;
		border-radius: 18px 18px 18px 18px;
		padding-left: 32px;
		border: none;
	}

	.messageGroup {
		position: absolute;
		top: 78px;
		z-index: 9999999;
		color: #333;
		width: 320px;
		height: 631px;
		right: 0;
		background-color: #fff;
		overflow-y: auto;
	}

	.groupHeader {
		align-items: center;
		justify-content: space-between;
		padding: 16px 26px;
		box-sizing: border-box;
	}

	.groupCent {
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.groupLine {
		width: 320px;
		height: 10px;
		background: #f8f8fa;
		border-radius: 0px 0px 0px 0px;
	}

	.shareGroup {
		width: 64px;
		height: 32px;
		background: #f7f7f7;
		border-radius: 4px 4px 4px 4px;
		cursor: pointer;
	}

	.groupIds {
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
	}

	.groupLineNew {
		width: 320px;
		height: 1px;
		background: #f8f8fa;
		border-radius: 0px 0px 0px 0px;
	}

	.otherArgeen {
		width: 240px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 10px;
		color: #999999;
		padding: 0 0 0 16px;
	}

	.messageText {
		cursor: pointer;
	}

	.selectRight {
		color: #333;
	}

	.canclBtn {
		width: 92px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: #f8f8f8;
		border-radius: 4px 4px 4px 4px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #666666;
		cursor: pointer;
	}

	.canclShare {
		width: 92px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: rgba(255, 221, 0, 1);
		border-radius: 4px 4px 4px 4px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #212121;
		margin-left: 12px;
		cursor: pointer;
	}

	.shareCart {
		display: flex;
	}

	body {
		-webkit-user-select: text;
		/* Chrome, Safari, Opera */
		-moz-user-select: text;
		/* Firefox */
		-ms-user-select: text;
		/* Internet Explorer/Edge */
		user-select: text !important;
	}


	.shareCartBack {
		width: 280px;
		height: 107px;
		background: #f7f7f7;
		padding: 16px 11px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.userNameShare {
		font-family: PingFang SC, PingFang SC;
		font-weight: bold;
		font-size: 16px;
		color: #212121;
	}

	.userNameShareId {
		font-family: PingFang SC, PingFang SC;
		font-weight: bold;
		font-size: 14px;
		color: #999999;
		margin-top: 4px;
	}

	.userNameShareLine {
		width: 100%;
		height: 1px;
		border-top: 1px soild #eee;
	}

	.personShare {
		font-family: PingFang SC, PingFang SC;
		font-weight: bold;
		font-size: 12px;
		color: #999999;
	}

	.selectLeft {
		color: #333;
	}

	.confirmBtn {
		width: 240px;
		height: 40px;
		background: #ffdd00;
		border-radius: 4px 4px 4px 4px;
		line-height: 40px;
		text-align: center;
		color: #333;
		cursor: pointer;
	}

	.photowall_item {
		width: 80px;
		height: 80px;
		border-radius: 8px;
		margin: 0 10px 10px 0;
	}

	/deep/.el-textarea__inner {
		border: 0;
		color: #333;
		background-color: #fff!important;
		padding: 0 17px;
		padding-top: 17px;
	}

	.textarea /deep/.el-textarea {
		background: transparent !important;
	}

	.textarea /deep/.el-input__count {
		background: transparent !important;
	}

	.textarea /deep/.el-textarea__inner {
		background: #f5f5f5 !important;
	}

	/deep/.el-input__inner {
		color: #333;
		background: #f5f5f5 !important;
	}

	/deep/.el-input__inner::placeholder {
		color: #333 !important;
	}

	// 上传文件样式
	/deep/.el-upload--picture-card {
		width: 0;
		height: 90px;
		background-color: transparent;
		border: 0;
	}

	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.2);
	}

	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.2);
	}
</style>