let emoij = [
	'[龇牙]', '[鼓掌]', '[麻将]', '[麦克风]', '[骷髅]', '[香蕉]', '[饥饿]', '[飞机]', '[飞吻]', '[风车]',
	'[鞭炮]', '[面条]', '[青蛙]', '[雨伞]', '[难过]', '[阴险]', '[闹钟]', '[闭嘴]', '[闪电]', '[钻戒]',
	'[钞票]', '[酷]', '[鄙视]', '[转圈]', '[车厢]', '[跳跳]', '[跳绳]', '[象棋]', '[调皮]', '[西瓜]',
	'[衰]', '[街舞]', '[蜡烛]', '[蛋糕]', '[菜刀]', '[药]', '[色]', '[胜利]', '[纸巾]', '[红灯笼]',
	'[红双喜]', '[糗大了]', '[米饭]', '[篮球]', '[礼物]', '[礼品袋]', '[示爱]', '[磕头]', '[睡觉]', '[皮球]',
	'[白眼]', '[疑问]', '[瓢虫]', '[玫瑰]', '[献吻]', '[猫咪]', '[猪头]', '[爱情]', '[爱心]', '[爱你]',
	'[爆筋]', '[熊猫]', '[炸弹]', '[灯泡]', '[激动]', '[流泪]', '[流汗]', '[沙发]', '[汽车]', '[棒棒糖]',
	'[月亮]', '[晕]', '[敲打]', '[擦汗]', '[撇嘴]', '[握手]', '[挥手]', '[拳头]', '[抱拳]', '[抱抱]',
	'[抠鼻]', '[折磨]', '[抓狂]', '[打哈欠]', '[手枪]', '[憨笑]', '[惊讶]', '[惊恐]', '[怒]', '[怄火]',
	'[快哭了]', '[心碎了]', '[微笑]', '[得意]', '[彩球]', '[彩带]', '[强]', '[弱]', '[差劲]', '[左车头]',
	'[再见]', '[偷笑]', '[大哭]',  '[嘘]', '[委屈]', '[便便]', '[可爱]', '[害羞]', '[吐]', '[尴尬]', '[冷汗]',
	'[傲慢]', '[困]', '[么么哒]', '[奋斗]', '[发呆]', '[右哼哼]', '[坏笑]', '[大兵]', '[凋谢]', '[啤酒]', '[勾引]',
	'[OK]', '[咖啡]', '[刀]', '[发抖]', '[太阳]', '[咒骂]', '[左哼哼]', '[吓]', '[乒乓]', '[NO]', '[回头]', '[左太极]',
	'[右太极]', '[信封]', '[奶瓶]', '[右车头]', '[多云]', '[下雨]', '[可怜]'


];
export default emoij;