<template>
  <div class="enter_page">
    <!-- 公会入驻 -->
    <div class="enter_box">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">公会入驻</div>
        <img src="../../static/quit.png" alt="" @click="enterClose()" />
      </div>
      <!-- 头部end -->
      <div class="enter_applyFor flex-dir" v-if="enterType == 0">
        <img src="../../static/other24.png" alt="" @click="enterType++" />
        <!-- <div class="enter_applyForBtn flex-content" @click="enterType++">
          申请入驻
        </div> -->
      </div>
	  <div v-if='enterType == 1&&reason' style="color:#333;padding:16px 10px;background: rgba(245, 0, 57, .1);">
	  	驳回原因：{{reason}}
	  </div>
      <div class="enter_form" v-if="enterType == 1">
        <el-form ref="form" :model="guildForm" label-width="130px">
          <el-form-item label="姓名:">
            <el-input v-model="guildForm.name" placeholder="请输入姓名"></el-input>
            <!-- <span style="color:#FF2A3E;margin-left:40px">请填写真实姓名</span> -->
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input v-model="guildForm.mobile" placeholder="请输入手机号" :maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="微信号:">
            <el-input v-model="guildForm.wxNumber" placeholder="请输入微信号"></el-input>
          </el-form-item>
          <el-form-item label="目前所在平台:">
            <el-input v-model="guildForm.currentPlatformName" placeholder="请输入目前所在平台名称"></el-input>
          </el-form-item>
          <el-form-item label="申请入驻的品类:">
            <el-checkbox-group v-model="guildForm.applyLabelIds">
              <el-checkbox v-for="item in applyLabelList" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="是否具备公司资质:">
            <el-radio-group v-model="guildForm.isCompany">
              <el-radio label="1">具备</el-radio>
              <el-radio label="0">不具备</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="其他补充信息:">
            <el-input v-model="guildForm.remark" placeholder="请输入其他补充信息"></el-input>
          </el-form-item>
          <el-form-item label="截图信息:">
            <img v-if="guildForm.streamImg" :src="guildForm.streamImg" alt="" class="upload_img" />
            <!-- action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" -->
            <el-upload action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :limit="1"
              :headers="headers" :show-file-list="false" list-type="picture-card" :on-success="streamImgSuccess"
              accept=".png,.jpg.jpeg" v-else>
              <!-- :action="uploadData.host"
              :data="uploadData"
              :before-upload="beforeUploadFun" -->
              <img src="../../static/183.png" alt="" />
            </el-upload>
            <div class="font-12" style="color: #8e92b1; margin-top: 10px">
              目前所在平台最新三个月后台流水截图
            </div>
            <img v-if="guildForm.wxGroupImg" :src="guildForm.wxGroupImg" alt="" class="upload_img" />
            <el-upload action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :limit="1"
              :show-file-list="false" list-type="picture-card" :on-success="wxGroupImgSuccess" accept=".png,.jpg.jpeg"
              v-else>
              <img src="../../static/183.png" alt="" />
            </el-upload>
            <div class="font-12" style="color: #8e92b1; margin-top: 10px">
              请提供公会后台团队人数截图以及团队的微信群截图
            </div>
            <img v-if="guildForm.collectionImg" :src="guildForm.collectionImg" alt="" class="upload_img" />
            <el-upload action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" :limit="1"
              :show-file-list="false" list-type="picture-card" :on-success="collectionImgSuccess" accept=".png,.jpg.jpeg"
              v-else>
              <img src="../../static/183.png" alt="" />
            </el-upload>
            <div class="font-12" style="color: #8e92b1; margin-top: 10px">
              请提供上个月平台给您公会打款的收款截图
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-aling font-12">
              <img src="../../static/107.png" alt="" class="radio_icon" v-if="agreementInfo"
                @click="agreementInfo = false" />
              <img src="../../static/106.png" alt="" class="radio_icon" v-else @click="agreementInfo = true" />
              <span style="color: #8e92b1">勾选并提交资料即代表你已阅读并同意</span>
              <span style="color: #4177DD" @click.stop="agreementChange()">《公会入驻协议》</span>
            </div>
            <el-button type="primary" @click="addGuild" class="form_btn">提交认证</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 公会入驻协议弹窗 -->
    <div class="popUp_box" v-if="agreementShow">
      <div class="enter_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">公会入驻协议</div>
          <img src="../../static/quit.png" alt="" @click="agreementShow = false" />
        </div>
        <!-- 头部end -->
        <div v-html="agreementText" class="textBox" style="width:700px"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      enterType: 0,
      guildForm: {
        name: "",
        mobile: "", //手机号
        wxNumber: "", //微信号
        currentPlatformName: "", //	目前所在平台名称
        applyLabelIds: [], //	申请入驻的品类id--对应interest_label一级分类，多个逗号隔开
        isCompany: '1', //是否具备公司资质（可开信息服务费发票）
        remark: "", //	其他补充信息
        streamImg: "", //目前所在平台最新三个月后台流水截图
        wxGroupImg: "", //	公会后台团队人数截图以及团队的微信群截图
        collectionImg: "", //上个月平台给您公会打款的收款截图
      },
      upImg: [], //上传后所有的图片
      checkList: [],
      agreementInfo: false, //协议
      agreementShow: false,
      agreementText: '',
      applyLabelList: [],
      uploadData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },
      showConText: {},
    };
  },
  props:{
	reason:{
		type:String,
		default:'',
	}  
  },
  computed: {
    headers() {
      return {
        "security-token": this.showConText.SecurityToken,
      };
    },
  },
  created() {
    //根据分组查询系统配置
    this.getOssConfig();
  },
  mounted() {
    this.interestLabelList();
  },
  methods: {
    // 获取认证协议
    agreementChange() {
      this.$api.getAgreement({ configName: 'guildInExplain' }).then(res => {
        if (res.code == 1) {
          this.agreementText = res.data
          this.agreementShow = true
        }
      })
    },
    // oss上传参数
    getOssConfig() {
      this.$api.getOssConfig().then((res) => {
        console.log("oss配置", res);
        if (res.code == 1) {
          this.showConText = res.data;
        }
      });
      // this.$api.queryByConfigGroup({ configGroup: "oss" }).then((res) => {
      //   console.log("oss配置", res);
      //   if (res.code == 1) {
      //     this.uploadData.host = res.data.resultUrl;
      //     this.uploadData.OSSAccessKeyId = res.data.accessKeyId;
      //     // this.uploadData.policy = res.policy; //不确定
      //     this.uploadData.Signature = res.data.accessKeySecret; //不确定
      //   }
      // });
    },
    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      // if (this.upImg.length) {
      //   // 用户已经不是第一次上传了  判断一定要是图片才可以
      //   // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
      //   var fileType = file.type.split("/")[0];
      //   // let res = imglist.some((item) => {
      //   //   return item == fileType;
      //   // });
      //   // console.log(res, "结果");
      //   if (fileType != "image") {
      //     // 上传的不是图片
      //     // console.log("结果是图片");
      //     this.$message.info("请继续上传图片");
      //     this.fileList.pop();
      //     return false;
      //   }
      // }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$api.getOssConfig().then((res) => {
          if (res.code == 1) {
            console.log("getOssConfig", res);
            this.uploadData.host =
              "https://ser-t9cp.oss-cn-guangzhou.aliyuncs.com";
            this.uploadData.OSSAccessKeyId = res.data.AccessKeyId;
            this.uploadData.policy =
              "eyJleHBpcmF0aW9uIjoiMjAyMi0xMS0xNlQwNzowNTowMS40NDhaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMjE0NzQ4MzY0OF1dfQ=="; //不确定
            this.uploadData.Signature = res.data.AccessKeySecret; //不确定
            this.uploadData.key = "2022/11/1042440158852685824.png"; //不确定
            // this.uploadData.success_action_status = 200;
            // this.uploadData.host = res.data.resultUrl;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            // var filename = this.showConText.fileName + "." + suffix;
            // this.uploadData.key = filename; //不确定
            console.log("suffix", suffix);

            //this.uploadData.success_action_status = "200";
            //this.uploadData.dir = this.showConText.fileName;
            var videaUrl = this.uploadData.host;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }
            console.log(this.upImg);
            if (this.upImg.length == 1) {
              this.showAddSource = false;
            }
            console.log("this.upImg", this.upImg);
            rs(true);
          }
        });
      });
    },
    // 目前所在平台最新三个月后台流水截图
    streamImgSuccess(res) {
      console.log(res);
      if (res.code == 1) {
        this.guildForm.streamImg = res.data.url;
      }
    },
    // 公会后台团队人数截图以及团队的微信群截图
    wxGroupImgSuccess(res) {
      console.log(res);
      if (res.code == 1) {
        this.guildForm.wxGroupImg = res.data.url;
      }
    },
    // 上个月平台给您公会打款的收款截图
    collectionImgSuccess(res) {
      console.log(res);
      if (res.code == 1) {
        this.guildForm.collectionImg = res.data.url;
      }
    },
    // 申请入驻类型
    interestLabelList() {
      let data = { pageNum: 1, pageSize: 999, type: 0 };
      this.$api.interestLabelList(data).then((res) => {
        console.log("申请入驻类型", res);
        if (res.code == 1) {
          this.applyLabelList = res.data;
          
        }
      });
    },
    enterClose() {
      this.$emit("setenterClose");
    },
    // 公会入驻
    addGuild() {
      if (!this.guildForm.name) return this.$message.info("请输入姓名");
      if (!this.guildForm.mobile) return this.$message.info("请输入手机号");
      if (!this.guildForm.wxNumber) return this.$message.info("请输入微信号");
      if (!this.guildForm.currentPlatformName)
        return this.$message.info("请输入目前所在平台名称");
      if (this.guildForm.applyLabelIds.length == 0)
        return this.$message.info("请选择申请入驻的品类");
      if (!this.guildForm.isCompany)
        return this.$message.info("请选择是否具备公司资质");
      if (!this.guildForm.remark)
        return this.$message.info("请输入其他补充信息");
      if (!this.guildForm.streamImg)
        return this.$message.info("请上传最新三个月后台流水截图");
      if (!this.guildForm.wxGroupImg)
        return this.$message.info("请上传公会后台团队人数截图");
      if (!this.guildForm.collectionImg)
        return this.$message.info("请上传公会打款的收款截图");
      if (!this.agreementInfo) return this.$message.info("请先阅读协议");
      this.guildForm.applyLabelIds = this.guildForm.applyLabelIds.toString();
	  let posturl = this.reason?'editGuild':'addGuild'
	  console.log(posturl)
      this.$api[posturl](this.guildForm).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.$emit("setenterClose");
        } else {
          this.$message.error(res.msg)
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
	/* // radio checkbox根据你使用组件，自行更换 */
	/deep/ .el-checkbox__original {
	  display: none !important;
	}
	
	/deep/.el-checkbox:focus:not(.is-focus):not(:active):not(.is-disabled) .el-checkbox__inner {
	    box-shadow: none !important;
	}
.textBox {
  width: 500px;
  height: 400px;
  padding: 12px;
  color: #333;
  box-sizing: border-box;
  overflow-y: scroll;
}

.enter_box {
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.upload_img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.enter_page {
  width: 1920px;
  height: 1080px;
  color: #fff;
  font-size: 14px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.enter_box {
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.enter_applyFor {
  padding: 30px 20px;

  .enter_applyForBtn {
    width: 176px;
    height: 40px;
    margin-top: 22px;
    color:#333;
    background: #FFDD00;
    border-radius: 8px 8px 8px 8px;
  }
}

.enter_form {
  width: 460px;
  max-height: 800px;
  color: #fff;
  padding: 0 200px;
  margin-top: 20px;
  overflow-y: scroll;
}

.form_btn {
  width: 318px;
  height: 40px;
  background: #FFDD00;
  border-radius: 4px;
  border: 0;
  color: #333;
}

.radio_icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}

/deep/.el-form-item__label {
  color: #d3d4dc;
}

/deep/.el-input__inner {
  color: #333;
  background-color: #F5F5F5 !important;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  background: RGBA(255, 221, 0, 1);
  border-color: RGBA(255, 221, 0, 1);
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
  background: RGBA(255, 221, 0, 1);
  border-color: RGBA(255, 221, 0, 1);
}

/deep/.el-textarea__inner {
  border: 0;
  background-color: #363659;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #333;
}

/deep/.el-input__inner::placeholder {
  color: #8E92B1 !important;
}
/deep/ .el-form-item__label{
  color: #333 !important;
}
/deep/ .el-checkbox__inner::after{
  box-sizing: content-box;
    content: "";
    border: 1px solid #333;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
}
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #000;
}
</style>