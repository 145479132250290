<template>
  <div class="pageSize">
    <div></div>
    <el-dialog
      :modal-append-to-body="false"
      title="提示"
      :visible.sync="isSHowQuan"
      width="30%"
      :before-close="handleClose"
    >
      <div class="sendgong" v-if="isSHowQuan">
        <div class="sendGuangTop">
          <div class="qunBanck" @click="qunBanck">返回</div>
          <div class="sendGuangTopTitle">权限设置</div>
          <div class="qunBtn" @click="quanForumAdd">完成</div>
        </div>
        <div class="quanSecond" style="background: #fff">
          <div>可见范围</div>
        </div>
        <div class="quanThree" style="margin-top: 20px; background: #fff">
          <div
            class="quanThreeFlex"
            v-for="item in effectArr"
            :key="item.effect"
            @click="goBtnEffect(item)"
          >
            <div>
              <div class="quanThreeFlexTitle">{{ item.name }}</div>
              <div class="quanThreeFlexContent">{{ item.content }}</div>
            </div>
            <img
              v-if="item.flag"
              class="quanThreeFlexContent"
              style="width: 20px; height: 20px"
              src="../../static/219.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :modal-append-to-body="false"
      :visible.sync="isShowTip"
      width="522px"
      :before-close="handleClose"
    >
      <div class="sendGuang" style="margin-top: 200px">
        <div class="sendGuangTop">
          <img
            @click="isShowTip = false"
            style="width: 16px; height: 16px"
            src="../../static/169.png"
            alt=""
          />
          <div class="sendGuangTopTitle">话题列表</div>
          <div></div>
        </div>
        <div class="sendSecond">
          <div
            class="sendSecondFlex"
            v-for="item in dataTopice"
            :key="item.id"
            @click="chooseTop(item)"
          >
            <img style="margin-right: 8px" src="../../static/221.png" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      width="522px"
      :modal-append-to-body="false"
      :show-close="false"
      top="50px"
      left="12px"
    >
      <div style="z-index: 99999">
        <div class="sendGuangTop">
          <div style="width: 80px">
            <img
              @click="isFalseForum"
              style="width: 16px; height: 16px"
              src="../../static/169.png"
              alt=""
            />
          </div>

          <div class="sendGuangTopTitle">发布动态</div>
          <div class="fabuBtn" @click="forumAdd()">发布</div>
        </div>
        <div class="sendContent">
          <textarea
            placeholder="记录一下此刻的心情~"
            v-model="guangContent"
            class="sendContentTextarea"
          ></textarea>
        </div>
        <div class="sendBottom">
          <div class="sendBottomLeft">
            <form id="fomrData">
              <el-upload
                class="avatar-uploader"
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :limit="9"
                :on-progress="handleAvatarIng"
                accept=".jpg, .png"
                v-if="isSHowTu"
              >
                <img
                  v-loading.fullscreen.lock="fullscreenLoading"
                  element-loading-text="拼命加载中"
                  element-loading-background="rgba(0, 0, 0, 0.2)"
                  style="margin-right: 13px; width: 22px; height: 22px"
                  src="../../static/222.png"
                  alt=""
                />
              </el-upload>
            </form>
            <form id="fomrData1">
              <el-upload
                class="avatar-uploader"
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                :limit="1"
                :on-progress="handleAvatarIng"
                v-if="isSHowPings"
                accept=".mp4,"
              >
                <img
                  style="margin-right: 13px; width: 22px; height: 22px"
                  src="../../static/220.png"
                  alt=""
                  v-loading.fullscreen.lock="fullscreenLoading"
                  element-loading-text="拼命加载中"
                  element-loading-background="rgba(0, 0, 0, 0.2)"
                />
              </el-upload>
            </form>
            <img
              @click="isShowTipBtn"
              style="
                margin-right: 13px;
                width: 22px;
                height: 22px;
                margin-top: -5px;
              "
              src="../../static/221.png"
              alt=""
            />
          </div>
          <div @click="isSHowQuan = true">
            <span>{{ effectName }}</span>
            <img
              src="../../static/oth6.png"
              style="width: 8px; height: 12px; margin-left: 13px"
              alt=""
            />
          </div>
        </div>
      </div>

      <div style="margin-top: 5px" v-if="reportImgList1.length == 0">
        <img
          v-for="item in reportImgList"
          style="width: 90px; height: 90px; margin-right: 5px"
          :key="item"
          :src="item"
          alt=""
        />
      </div>
      <div style="margin-top: 5px" v-else>
        <video
          controls
          v-for="item in reportImgList1"
          style="width: 90px; height: 90px; margin-right: 5px"
          :key="item"
          :src="item.url"
          alt=""
        />
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="jubaoShow"
      :show-close="false"
      :append-to-body="true"
      width="522px"
      :before-close="handleClose"
      style="padding: 0; z-index: 9999999"
    >
      <div style="width: 522px; padding: 0">
        <div class="wheat_remind" style="height: 410px">
          <!-- 头部start -->
          <div class="titleIcon"></div>
          <div class="title_content">
            <div class="font-14">举报动态</div>
            <img
              src="../../static/quit.png"
              alt=""
              @click.stop="jubaoShow = false"
            />
          </div>
          <!-- 头部end -->
          <div
            class="flex"
            style="
              width: 100%;
              height: 52px;
              background: rgba(255, 114, 104, 0.1);
              color: #ff7268;
              text-align: center;
              align-items: center;
            "
          >
            <div style="width: 466px; margin: 0 auto">
              举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
            </div>
          </div>
          <div style="margin-top: 24px; margin-left: 29px">举报原因</div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
            "
          >
            <div
              style="display: flex; padding: 0 24px;cursor: pointer;"
              v-for="item in personData"
              :key="item.id"
              @click="personBtn(item)"
            >
              <div v-if="item.isFlag">
                <img
                  style="margin-right: 5px"
                  src="../../static/219.png"
                  alt=""
                />
              </div>
              <div v-else>
                <img
                  style="margin-right: 5px;width: 20px;height: 20px;"
                  src="../../static/242.png"
                  alt=""
                />
              </div>
              {{ item.title }}
            </div>
          </div>
          <div class="noGuangchangTitle">
            <div>同时不看TA的广场信息</div>
            <div>
              <el-switch v-model="jubaoValue"> </el-switch>
            </div>
          </div>
          <div class="corfinBtn" @click="jubaoSubmit">确认提交</div>
        </div>
      </div>
    </el-dialog>
    <!-- guangIsSHow -->
    <div v-if="guangIsSHow && !showTopic" style="width: 100%;">
      <div class="guangTop">
        <div></div>
        <div style="text-indent:90px;font-size:26px;font-weight: 900;">发 好 动 态 ，交 好 朋 友</div>
		<el-tooltip class="item" effect="dark" content="发布动态" placement="left">
		    <div class="flex cursor" style="align-items: center;margin-right:24px;">
		    	<img
		    	  style="width: 16px; height: 16px;"
		    	  src="../../static/211.png"
		    	  alt=""
		    	  class="cursor"
		    	  @click="dialogVisible = true"
		    	/>
		    	<div style="margin-left:8px;">发布</div>
		    </div>
		</el-tooltip>
      </div>

      <div
        class="contentData"
        style="
          width: calc(100% - 48px);
          margin-left: 32px;
          padding-top: 20px;
          box-sizing: border-box;
          overflow: auto;
        "
      >
        <div
          class="content"
          style="display: flex;position: relative;background:#fff;padding:20px;border-radius:8px;margin-bottom:12px;padding-bottom:0px;"
          v-for="item in guangData"
          :key="item.id"
        >
          <div style="position: absolute;right: 0;top: 0;cursor: pointer;" class="showMore">
            <div class="flex justify-end" style="justify-content: flex-end;height: 40px;align-items: center;">
              <span style="font-weight: 600;width: 40px;text-align: center;line-height: 40rpx;">···</span>
            </div>
            <div class="btns" v-if="item.isMine == 1"
              style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
              <div class="flex align-center" @click.stop="delateLun(item)">
                <img src="@/static/238.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>删除</span>
              </div>
            </div>
            <div class="btns" v-else
              style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
              <!-- <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="collectForum(items)">
                <img src="@/static/239.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>取消收藏</span>
              </div> -->
              <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="noSee(item)">
                <img src="@/static/240.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>不看TA</span>
              </div>
              <div class="flex align-center" @click.stop="jubao(item)">
                <img src="@/static/241.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>举报</span>
              </div>
            </div>
          </div>
          <img class="flex-sub"
            style="
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-top: 24px;
            "
            :src="item.avatar"
            alt=""
            @click="otherDetai(item)"
          />
          <div style="width:calc(100% - 40px); padding-bottom: 24px">
            <div class="contentFlex">
              <div class="contentFlex">
                <div class="contentName" @click.stop="goGuangDetail(item)">
                  <div :class="{'vipred':item.vipFlag==1}" class="flex flex-aling">{{ item.nickname }}
                    <img src="../../static/vip.png"  v-if='item.vipFlag==1'
                      style="width: auto;height:13px;" />
                    <span v-if="item.wheatFollow != 0" style="border: 1px solid #FF7268;color: #FF7268;background-color: rgba(255, 114, 104, 0.10);font-size: 12px;padding: 2px 8px;font-weight: 600;border-radius: 4px;margin-left: 8px;">关注</span>
                  </div>
                  <div class="contentTime">{{ item.createTimeDesc }}</div>
                </div>
                <!-- <div>
                  <el-popover
                    style="backgorund: #333"
                    placement="bottom"
                    width="100"
                    trigger="click"
                  >
                    <div v-if="item.userId != userInfo.id">
                      <div style="display: flex" @click="noSee(item)">
                        <img src="../../static/234.png" alt="" />
                        <div style="margin-left: 5px">不看TA</div>
                      </div>
                      <div
                        style="display: flex; margin-top: 5px"
                        @click="jubao(item)"
                      >
                        <img src="../../static/235.png" alt="" />
                        <div style="margin-left: 5px">举报</div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="display: flex" @click="delateLun(item)">
                        <img src="../../static/234.png" alt="" />
                        <div style="margin-left: 5px">删除动态</div>
                      </div>
                    </div>

                    <img
                      slot="reference"
                      style="width: 19px; height: 16px"
                      src="../../static/212.png"
                      alt=""
                    />
                  </el-popover>
                </div> -->
              </div>
            </div>
            <div class="contentText" @click.stop="goGuangDetail(item)">
              <span style="color: rgba(65, 119, 221, 1)" v-if="item.topicName"
                >#{{ item.topicName }}</span
              >
              {{ item.title }}
            </div>
            <div
              v-if="item.newImages && item.newImages.length > 1"
              style="
                width: 470px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;
              "
            >
              <div v-for="(item1, index1) in item.newImages" :index="index1">
                <el-image
                  style="
                    width: 150px;
                    height: 150px;
                    margin-top: 4px;
                    margin-left: 4px;
                    object-fit: cover;
                  "
                  fit="cover"
                  :src="item1"
                  :preview-src-list="item.newImages"
                >
                </el-image>
              </div>
            </div>
            <div v-if="item.newImages && item.newImages.length > 0">
              <div v-for="(item1, index1) in item.newImages" :index="index1">
                <el-image
                  v-if="item.newImages.length == 1"
                  fit="contain"
                  :preview-src-list="item.newImages"
                  style="width: 200px; margin-top: 24px; object-fit: cover"
                  :src="item1"
                  alt=""
                />
              </div>
            </div>
            <video
              v-if="item.video"
              width="600"
              height="300"
              controls
              :src="item.video"
            ></video>
            <div class="priceBtn">
              <div class="priceBtnFlex cursor">
                <img
                  class="priceBtnFlexImg"
                  src="../../static/213.png"
                  alt=""
                />
                <div @click="item.shareShow = !item.shareShow">分享</div>
              </div>
              <div class="share_popup cursor" v-if="item.shareShow">
                <div class="ipAddress">
                  <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                  <div
                    class="copyBtn"
                    style="color: #333; width: 68px; height: 32px"
                    @click.stop="
                      copyUserId(
                        'https://ser.t9cp.com/pch5/#/pages/newDownload'
                      )
                    "
                  >
                    复制
                  </div>
                </div>
              </div>
              <div class="priceBtnFlex cursor" @click="zanBtn(1, item)">
                <img
                  v-if="item.isLike"
                  class="priceBtnFlexImg"
                  src="../../static/218.png"
                  alt=""
                />
                <img
                  v-else
                  class="priceBtnFlexImg"
                  src="../../static/214.png"
                  alt=""
                />

                <div :style="item.isLike ? 'color:rgba(255, 167, 0, 1)' : ''">
                  {{ item.likeNumber == 0 ? "首赞" : item.likeNumber }}
                </div>
              </div>
              <div class="priceBtnFlex cursor" style="margin-right:48px">
                <img
                  class="priceBtnFlexImg"
                  src="../../static/215.png"
                  alt=""
                />
                <div @click.stop="goGuangDetail(item)">评论</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex" v-if="DataTotal > 0" style="align-items: center;justify-content: flex-end;padding: 12px 0;background-color: #fff;">
          <el-pagination
            layout="prev, pager, next"
            :page-size="15"
            @current-change="handleCurrentChange"
            :total="DataTotal">
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-if="showTopic && !guangIsSHow" class="pageSizeAb">
      <div class="guangTopDetail">
        <img
          style="margin-left: 24px; width: 8px; height: 16px; cursor: pointer"
          src="../../static/to11.png"
          alt=""
          @click="showTopic = false, guangIsSHow = true"
        />
        
      </div>
      <div
        class="contentData"
        style="
          background: #fff;
          width: calc(100% - 48px);
          margin-left: 24px;
          padding-top: 20px;
          padding-left: 20px;
          box-sizing: border-box;
          overflow: auto;
        "
      >
        <div
          class="content"
          style="display: flex;position: relative;"
          v-for="item in topicDataList"
          :key="item.id"
        >
          <div style="position: absolute;right: 0;top: 0;cursor: pointer;" class="showMore">
            <div class="flex justify-end" style="justify-content: flex-end;height: 40px;align-items: center;">
              <span style="font-weight: 600;width: 40px;text-align: center;line-height: 40rpx;">···</span>
            </div>
            <div class="btns" v-if="item.isMine == 1"
              style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
              <div class="flex align-center" @click.stop="delateLun(item)">
                <img src="@/static/238.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>删除</span>
              </div>
            </div>
            <div class="btns" v-else
              style="background-color: rgba(0,0,0,0.5);padding: 12px;border-radius: 4px;color: #fff;font-size: 14px;">
              <!-- <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="collectForum(items)">
                <img src="@/static/239.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>取消收藏</span>
              </div> -->
              <div class="flex align-center" style="margin-bottom: 16px;" @click.stop="noSee(item)">
                <img src="@/static/240.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>不看TA</span>
              </div>
              <div class="flex align-center" @click.stop="jubao(item)">
                <img src="@/static/241.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;">
                <span>举报</span>
              </div>
            </div>
          </div>
          <img
            style="
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-top: 24px;
            "
            :src="item.avatar"
            alt=""
            @click="otherDetai(item)"
          />
          <div style="width: 100%; padding-bottom: 24px">
            <div class="contentFlex">
              <div class="contentFlex">
                <div class="contentName" @click.stop="goGuangDetail(item)">
                  <div :class="{'vipred':item.vipFlag==1}" class="flex flex-aling">{{ item.nickname }}
					<img src="../../static/vip.png"  v-if='item.vipFlag==1'
				  	style="width: auto;height:13px;" />
            <span v-if="item.wheatFollow != 0" style="border: 1px solid #FF7268;color: #FF7268;background-color: rgba(255, 114, 104, 0.10);font-size: 12px;padding: 2px 8px;font-weight: 600;border-radius: 4px;margin-left: 8px;">关注</span>
				  </div>
                  <div class="contentTime">{{ item.createTimeDesc }}</div>
                </div>
                <!-- <div>
                  <el-popover
                    style="backgorund: #333"
                    placement="bottom"
                    width="100"
                    trigger="click"
                  >
                    <div v-if="item.userId != userInfo.id">
                      <div style="display: flex" @click="noSee(item)">
                        <img src="../../static/234.png" alt="" />
                        <div style="margin-left: 5px">不看TA</div>
                      </div>
                      <div
                        style="display: flex; margin-top: 5px"
                        @click="jubao(item)"
                      >
                        <img src="../../static/235.png" alt="" />
                        <div style="margin-left: 5px">举报</div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="display: flex" @click="delateLun(item)">
                        <img src="../../static/234.png" alt="" />
                        <div style="margin-left: 5px">删除动态</div>
                      </div>
                    </div>

                    <img
                      slot="reference"
                      style="width: 19px; height: 16px"
                      src="../../static/212.png"
                      alt=""
                    />
                  </el-popover>
                </div> -->
              </div>
            </div>
            <div class="contentText" @click.stop="goGuangDetail(item)">
              <span style="color: rgba(65, 119, 221, 1)" v-if="item.topicName"
                >#{{ item.topicName }}</span
              >
              {{ item.title }}
            </div>
            <div
              v-if="item.newImages.length > 1"
              style="
                width: 470px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;
              "
            >
              <div v-for="(item1, index1) in item.newImages" :index="index1">
                <el-image
                  style="
                    width: 150px;
                    height: 150px;
                    margin-top: 4px;
                    margin-left: 4px;
                    object-fit: cover;
                  "
                  fit="cover"
                  :src="item1"
                  :preview-src-list="item.newImages"
                >
                </el-image>
              </div>
            </div>
            <div v-for="(item1, index1) in item.newImages" :index="index1">
              <el-image
                v-if="item.newImages.length == 1"
                fit="contain"
                :preview-src-list="item.newImages"
                style="width: 200px; margin-top: 24px; object-fit: cover"
                :src="item1"
                alt=""
              />
            </div>
            <video
              v-if="item.video"
              width="600"
              height="300"
              controls
              :src="item.video"
            ></video>
            <div class="priceBtn">
              <div class="priceBtnFlex">
                <img
                  class="priceBtnFlexImg"
                  src="../../static/213.png"
                  alt=""
                />
                <div @click="item.shareShow = !item.shareShow">分享</div>
              </div>
              <div class="share_popup" v-if="item.shareShow">
                <div class="ipAddress">
                  <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                  <div
                    class="copyBtn"
                    style="color: #333; width: 68px; height: 32px"
                    @click.stop="
                      copyUserId(
                        'https://ser.t9cp.com/pch5/#/pages/newDownload'
                      )
                    "
                  >
                    复制
                  </div>
                </div>
              </div>
              <div class="priceBtnFlex" @click="zanBtn(1, item)">
                <img
                  v-if="item.isLike"
                  class="priceBtnFlexImg"
                  src="../../static/218.png"
                  alt=""
                />
                <img
                  v-else
                  class="priceBtnFlexImg"
                  src="../../static/214.png"
                  alt=""
                />

                <div :style="item.isLike ? 'color:rgba(255, 167, 0, 1)' : ''">
                  {{ item.likeNumber == 0 ? "首赞" : item.likeNumber }}
                </div>
              </div>
              <div class="priceBtnFlex" style="margin-right: 24px">
                <img
                  class="priceBtnFlexImg"
                  src="../../static/215.png"
                  alt=""
                />
                <div @click.stop="goGuangDetail(item)">评论</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex" v-if="topicTotal > 0" style="align-items: center;justify-content: flex-end;padding: 12px 0;background-color: #fff;">
          <el-pagination
            layout="prev, pager, next"
            :page-size="15"
            @current-change="handleTopicCurrentChange"
            :total="topicTotal">
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-if="!showTopic && !guangIsSHow" class="pageSizeAb">
      <div class="guangTopDetail">
        <img
          style="margin-left: 24px; width: 8px; height: 16px; cursor: pointer"
          src="../../static/to11.png"
          alt=""
          @click="goShowList"
        />
        <el-popover
          style="backgorund: #333"
          placement="bottom"
          width="100"
          trigger="click"
        >
          <div v-if="guangDetailData.userId != userInfo.id">
            <div style="display: flex" @click="noSee(guangDetailData, 2)">
              <img src="../../static/234.png" alt="" />
              <div style="margin-left: 5px">不看TA</div>
            </div>
            <div
              style="display: flex; margin-top: 5px"
              @click="jubao(guangDetailData)"
            >
              <img src="../../static/235.png" alt="" />
              <div style="margin-left: 5px">举报</div>
            </div>
          </div>
          <div v-else>
            <div style="display: flex" @click="delateLun(guangDetailData, 2)">
              <img src="../../static/234.png" alt="" />
              <div style="margin-left: 5px">删除动态</div>
            </div>
          </div>
          <img
            slot="reference"
            style="width: 19px; height: 16px; margin-right: 14px"
            src="../../static/212.png"
            alt=""
          />
        </el-popover>
      </div>
      <div
        class="contentData"
        style="
          background: #fff;
          width: calc(100% - 48px);
          margin-left: 24px;
          padding-top: 20px;
          padding-left: 20px;
          box-sizing: border-box;
        "
      >
        <div class="content1" style="display: flex">
          <img
            style="
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-top: 24px;
            "
            :src="guangDetailData.avatar"
            alt=""
          />
          <div style="width: 100%; padding-bottom: 24px">
            <div class="contentFlex">
              <div class="contentFlex">
                <div
                  class="contentName"
                  @click.stop="goGuangDetail(guangDetailData)"
                >
                  <div :class="{'vipred':guangDetailData.vipFlag==1}" class="flex flex-aling">{{ guangDetailData.nickname }}
					<img src="../../static/vip.png"  v-if='guangDetailData.vipFlag==1'
					 style="width: auto;height:13px;" />
           <span v-if="guangDetailData.wheatFollow != 0" style="border: 1px solid #FF7268;color: #FF7268;background-color: rgba(255, 114, 104, 0.10);font-size: 12px;padding: 2px 8px;font-weight: 600;border-radius: 4px;margin-left: 8px;">关注</span>
				  </div>
                  <div class="contentTime">
                    {{ guangDetailData.createTime }}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div
              class="contentTextText"
              @click="openTopic(guangDetailData)"
            >
              <span
                style="color: rgba(65, 119, 221, 1)"
                v-if="guangDetailData.topicName"
                >#{{ guangDetailData.topicName }}</span
              >
              {{ guangDetailData.title }}
            </div>

            <div
              v-if="guangDetailData.newImages.length > 1"
              style="
                width: 470px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;
                cursor: pointer;
              "
            >
              <div
                v-for="(item1, index1) in guangDetailData.newImages"
                :index="index1"
              >
                <el-image
                  style="
                    width: 150px;
                    height: 150px;
                    margin-top: 4px;
                    margin-left: 4px;
                    object-fit: cover;
                  "
                  :src="item1"
                  :preview-src-list="guangDetailData.newImages"
                >
                </el-image>
              </div>
            </div>
            <div
              v-if="guangDetailData.newImages.length == 1"
              v-for="(item1, index1) in guangDetailData.newImages"
              :index="index1"
            >
              <el-image
                style="
                  width: 150px;
                  height: 150px;
                  margin-top: 4px;
                  margin-left: 4px;
                  object-fit: cover;
                "
                :src="item1"
                :preview-src-list="guangDetailData.newImages"
                fit="cover"
              >
              </el-image>
            </div>
            <video
              v-if="guangDetailData.video"
              width="600"
              height="300"
              controls
              :src="guangDetailData.video"
            ></video>
          </div>
        </div>
        <div class="contentDetailFlex">
          评价
          <span
            style="
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            "
            >{{ guangDetailData.commentNumber }}</span
          >
        </div>
        <div class="commentOvlea">
          <div v-for="item in commite" :key="item.id">
            <div class="commentFlex" style="display: flex">
              <div>
                <img
                  :src="item.avatar"
                  style="
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    margin-left: 20px;
                    margin-top: 16px;
                  "
                  alt=""
                />
              </div>
			  <div class="commentName flex flex-aling" :class="{'vipred':item.vipFlag==1}">{{ item.nickname }}
				<img src="../../static/vip.png"  v-if='item.vipFlag==1'
				 style="width: auto;height:13px;" />
			  </div>
            </div>
            <div class="commentConent" @click="goPingLun(item)">
              {{ item.content }}
            </div>
            <div style="margin-left: 60px; margin-top: 6px; display: flex">
              <div class="commentTime">{{ item.createTimeDesc }}</div>
              <div
                @click="zanBtn(2, item)"
                style="display: flex; align-items: center; margin-left: 16px"
              >
                <img
                  v-if="item.isLike == 1"
                  src="../../static/217.png"
                  alt=""
                />
                <img v-else src="../../static/214.png" alt="" />
                <span
                  v-if="item.isLike == 1"
                  style="
                    padding-left: 4px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffa700;
                    margin-top: 2px;
                  "
                  >{{ item.likeNumber }}</span
                >
                <span
                  v-else
                  style="
                    padding-left: 4px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333;
                    margin-top: 2px;
                  "
                  >{{ item.likeNumber }}</span
                >
              </div>
            </div>
            <div style="margin-left: 60px">
              <div v-for="(item1, index) in item.secondList" :key="index">
                <div class="secondFlex">
                  <img
                    style="width: 16px; height: 16px; border-radius: 50%"
                    :src="item1.avatar"
                    alt=""
                  />
                  <div
                    class="commentName"
                    style="
                      fontsize: 12px;
                      margin-left: -4px;
                      padding-bottom: 10px;
                      padding-top: 5px;
                    "
                  >
                    {{ item1.nickname }}
                    <span v-if="item1.otherUserId">回复</span>
                    {{ item1.otherUserId ? item1.otherNickname : "" }}
                  </div>
                </div>
                <div
                  class="commentConent"
                  style="fontsize: 12px; margin-left: 28px; margin-top: 0"
                >
                  {{ item1.content }}
                </div>
              </div>
              <div
                class="openMore"
                v-if="
                  item.secondCount > item.secondList.length &&
                  item.secondCount > 2
                "
                @click="openMore(item)"
              >
                展开更多
              </div>
              <div
                class="openMore"
                v-if="
                  item.secondCount == item.secondList.length &&
                  item.secondCount > 2
                "
                @click="getForumCommentList(guangDetailData)"
              >
                收起
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pageSizeBottom" v-if="isShowBtn">
        <div
          style="
            width: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
          "
        >
          <input
            style="
              margin-top: 30px;
              height: 48px;
              margin-left: 40px;
              width: 100%;
              background: #f8f8f8;
              border: none;
              padding-left: 24px;
              border-radius: 8px 8px 8px 8px;
            "
            :placeholder="
              isShowPing ? '请输入评论' : '回复' + goDataPing.nickname
            "
            v-model="content"
            @keyup.enter="pingLunSend()"
          />
          <div
            v-if="!isShowPing"
            @click="goPing"
            class="sendPingBtn"
            style="right: 100px"
          >
            X
          </div>
          <div class="sendPingBtn" @click="pingLunSend()">发送</div>
        </div>
        <div style="display:flex;justify-content: space-between;width:20%;padding-right:24px">
          <div
            v-if="guangDetailData.isCollect == 0"
            class="pageSizeBottomIcon"
            style="margin-left: 33px"
            @click="collectForum(guangDetailData)"
          >
            <img
              src="../../static/to12.png"
              style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                margin-top: 8px;
              "
              alt=""
            />
            <div>收藏</div>
          </div>
          <div
            v-if="guangDetailData.isCollect == 1"
            class="pageSizeBottomIcon"
            style="margin-left: 33px"
            @click="collectForum(guangDetailData)"
          >
            <img
              src="../../static/other16.png"
              style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                margin-top: 8px;
              "
              alt=""
            />
            <div>收藏</div>
          </div>
          <div
            class="pageSizeBottomIcon"
            style="margin-left: 20px"
            @click="zanBtn(1, guangDetailData)"
            v-if="guangDetailData.isLike == 1"
          >
            <img
              src="../../static/217.png"
              style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                margin-top: 8px;
              "
              alt=""
            />
            <div style="width: 32px; text-align: center; color: #ffa700">
              {{ guangDetailData.likeNumber }}
            </div>
          </div>
          <div
            class="pageSizeBottomIcon"
            style="margin-left: 20px"
            @click="zanBtn(1, guangDetailData)"
            v-else
          >
            <img
              src="../../static/214.png"
              style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                margin-top: 8px;
              "
              alt=""
            />
            <div style="width: 32px; text-align: center">
              {{ guangDetailData.likeNumber }}
            </div>
          </div>
          <div
            class="pageSizeBottomIcon"
            style="margin-left: 20px; position: relative"
            @click="guangDetailData.shareShow = !guangDetailData.shareShow"
          >
            <img
              src="../../static/213.png"
              style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                margin-top: 8px;
              "
              alt=""
            />
            <div>分享</div>
            <div
              class="share_popup"
              style="left: -20px"
              v-if="guangDetailData.shareShow"
            >
              <div class="ipAddress">
                <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
                <div
                  class="copyBtn"
                  style="color: #333; width: 68px; height: 32px"
                  @click.stop="
                    copyUserId('https://ser.t9cp.com/pch5/#/pages/newDownload')
                  "
                >
                  复制
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: "",
  },
  data() {
    return {
      topicDataList: [],
      showTopic: false, //是否显示话题
      loading: false,
      guangData: [],
      guangIsSHow: true,
      guangDetailData: {},
      pageNum: 1,
      commite: [],
      content: "",
      shareShow: false,
      isShowFa: false,
      dataTopice: [],
      isShowTip: false,
      tipData: {},
      guangContent: "",
      isFaBu: true,
      isSHowQuan: false,
      effect: "",
      reportImgList: [],
      reportImgList1: [],
      isSHowPings: true,
      isSHowTu: true,
      fullscreenLoading: false,
      effectArr: [
        {
          name: "公开",
          content: "不限制，所有人可见",
          effect: 0,
          flag: true,
        },
        {
          name: "关注",
          content: "仅我关注的人可见",
          effect: 1,
          flag: false,
        },
        {
          name: "好友",
          content: "仅互相关注的好友可见",
          effect: 2,
          flag: false,
        },
        {
          name: "陌生人",
          content: "仅陌生人可见",
          effect: 3,
          flag: false,
        },
      ],
      effectName: "公开",
      dataEffect: {},
      pingData: {},
      isShowBtn: false,
      ziPingLunData: {},
      visible: false,
      goDataPing: {},
      isShowPing: true,
      detailId: "",
      dialogVisible: false,
      DataTotal: 0,
      jubaoShow: false,
      personData: [],
      jubaoValue: false,
      reasonData: "",
      jubaoUserId: "",
      flageTime: true,
      topicDetail: {}
    };
  },
  created() {
    this.getQueryReportReason();
    this.getForumList();
    this.getTopicUnderWayList();
  },
  methods: {
    handleCurrentChange(e) {
      this.getForumList(e);
    },
    handleTopicCurrentChange(e) {
      this.getTopicGuangList({topicId: this.topicDetail.id}, e);
    },
    // 打开话题页面
    openTopic(item) {
      this.$api
        .getTopicDetail({ topicId: item.topicId })
        .then((res) => {
          console.log(res)
          if (res.code == 1) {
            this.topicDetail = res.data;
            this.guangIsSHow = false;
            this.showTopic = true;
            this.getTopicGuangList(item)
          } else {

          }
        });
    },
    getTopicGuangList(item, pageNum = 1) {
      this.$api.getTopicHotList({ topicId: item.topicId, pageSize: 15, pageNum }).then((res) => {
        if (res.data.code == 1) {
          res.data.rows.forEach((item) => {
            item.shareShow = false;
            if (item.images) {
              item.newImages = item.images.split(",");
            } else {
              item.newImages = [];
            }
          });
          this.topicTotal = res?.data?.total || 0;
          this.topicDataList = res?.data?.rows || [];
        }
      });
    },
    noMore() {
      return this.guangData.length >= this.DataTotal;
    },
    handleClose() {},
    // 展开更多
    openMore(item) {
      // console.log(item.id,'itemitem')
      this.$api
        .secondListAll({ firstPid: item.id, pageNum: 1, pageSize: 15 })
        .then((res) => {
          this.commite.forEach((item1) => {
            if (item1.id == item.id) {
              item1.secondList = res.data.rows;
            }
          });
        });
    },
    goPingLun(item) {
      this.isShowPing = false;
      this.goDataPing = item;
    },
    getForumList(pageNum = 1) {
      this.$api.forumList({ pageNum: pageNum, pageSize: 15 }).then((res) => {
        if (res.data.code == 1) {
          res.data.rows.forEach((item) => {
            item.shareShow = false;
            if (item.images) {
              item.newImages = item.images.split(",");
            } else {
              item.newImages = [];
            }
          });
          this.DataTotal = res?.data?.total || 0;
          this.guangData = res?.data?.rows || [];
        }
      });
    },
    goGuangDetail(item) {
      this.isShowPing = true;
      this.goDataPing = "";
      this.detailId = item.id;
      this.guangIsSHow = false;
      this.showTopic = false;
      this.isShowBtn = true;
      this.$api.forumQueryById({ id: item.id }).then((res) => {
        if (res.data.images) {
          res.data.newImages = res.data.images.split(",");
        } else {
          res.data.newImages = [];
        }
        //  console.log(res.data,'sssssssss')
        res.data.shareShow = false;
        this.guangDetailData = res.data;
      });
      this.getForumCommentList(item.id);
    },
    zanBtn(type, item) {
      // if(type==2){
      //   this.$api.forumQueryById({ id: item.id }).then((res) => {
      //   if (res.data.images) {
      //     res.data.newImages = res.data.images.split(",");
      //   } else {
      //     res.data.newImages = [];
      //   }
      //   this.guangDetailData = res.data;
      // });

      this.$api.forumClickAdd({ sourceId: item.id, type: type }).then((res) => {
        if (res.code == 1) {
          if (type == 2) {
            this.getForumCommentList(item.forumId);
          }
          if (type == 1) {
            this.$api.forumQueryById({ id: item.id }).then((res) => {
              if (res.data.images) {
                res.data.newImages = res.data.images.split(",");
              } else {
                res.data.newImages = [];
              }
              this.guangDetailData = res.data;
            });
            this.getForumList();
            this.getTopicGuangList(item);
          }

          this.$forceUpdate();
        }
      });
    },
    otherDetai(item) {
      // console.log(item,'itemitem')
      this.$emit("otherClick", item);
    },
    // 点击复制
    copyUserId(id) {
      var input = document.createElement("input"); // 创建input对象
      input.value = id; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    goShowList() {
      this.guangIsSHow = true;
      this.isShowBtn = false;
    },
    // 不看ta
    noSee(item, type) {
      this.$api.forumLimitAdd({ otherUserId: item.userId }).then((res) => {
        if (res.code == 1) {
          if (type == 2) {
            this.goShowList();
          }
          this.getForumList();
          this.getTopicGuangList(item);
          this.$forceUpdate();
        }
      });
    },
    delateLun(item, type) {
      this.$api.forumLimitDelete({ id: item.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getForumList();
          this.$forceUpdate();
          if (type == 2) {
            this.goShowList();
          }
        }
      });
    },
    getForumCommentList(id) {
      this.$api
        .forumCommentList({
          forumId: id,
          pageNum: this.pageNum,
          pageSize: 15,
        })
        .then((res) => {
          this.commite = res.data.rows;
        });
    },
    goBtnTolck(item) {
      this.pingData = item;
      this.isShowFa = true;
    },
    getForumCommentAdd(type, id, content) {
      let that = this;
      this.$api
        .forumCommentAdd({
          content: content,
          sourceId: id,
          type: type,
        })
        .then((res) => {
          this.content = "";
          this.goGuangDetail({ id: that.detailId });
          this.getForumCommentList(that.detailId);
        });
    },
    goPing() {
      this.isShowPing = true;
      this.goDataPing = "";
    },
    pingLunSend() {
      if (this.content.length) {
      } else {
        this.$message("请填写回复内容");
        return;
      }
      if (this.flageTime) {
        this.flageTime = false;
        if (this.isShowPing) {
          this.getForumCommentAdd(1, this.guangDetailData.id, this.content);
        } else {
          this.getForumCommentAdd(2, this.goDataPing.id, this.content);
        }
        let time = setTimeout(() => {
          this.flageTime = true;
          clearTimeout(time);
        }, 1500);
      }

      //
    },
    // 发布广场
    forumAdd(flag) {
      if (this.isFaBu) {
        this.isFaBu = false;
        if (!this.guangContent) {
          this.$message("请填写文章内容");
          return;
        }
        this.$api
          .forumAdd({
            title: this.guangContent,
            effect: this.dataEffect.effect,
            topicId: this.tipData.id ? this.tipData.id : "",
            topicName: this.tipData.name ? this.tipData.name : "",
            video: this.reportImgList1.length ? this.reportImgList1[0].url : "",
            videoPicture: this.reportImgList1.length
              ? this.reportImgList1[0].coverImg
              : "",
            images: this.reportImgList + "",
          })
          .then((res) => {
            this.dialogVisible = false;
            this.guangContent = "";
            this.dataEffect.effect = "";
            this.effectName = "公开";
            this.tipData.id = "";
            this.tipData.name = "";
            this.isSHowPings = true;
            this.isSHowTu = true;
            this.reportImgList = [];
            this.reportImgList1 = [];
            document.getElementById("fomrData").reset();
            document.getElementById("fomrData1").reset();
            this.getForumList();
            this.getTopicUnderWayList();
            this.isFaBu = true;
          });
      }
    },
    // 话题列表
    getTopicHotList() {
      this.$api
        .topicHotList({ pageNum: 1, pageSize: 15, topicId: "" })
        .then((res) => {
          console.log(res, "rrrrrrr");
        });
    },
    isFalseForum() {
      this.guangContent = "";
      this.dataEffect.effect = "";
      this.tipData.id = "";
      this.tipData.name = "";
      this.dialogVisible = false;
    },
    collectForum(item) {
      this.$api.collectForum({ forumId: item.id }).then((res) => {
        // this.dataTopice = res.data.rows;
        this.goGuangDetail(item);
      });
    },
    getTopicUnderWayList() {
      this.$api.topicUnderWayList().then((res) => {
        this.dataTopice = res.data.rows;
      });
    },
    chooseTop(item) {
      this.isShowTip = false;
      this.isShowFa = true;
      this.tipData = item;
    },
    isShowTipBtn() {
      this.isShowTip = true;
    },
    qunBanck() {
      this.isSHowQuan = false;
      this.isShowFa = true;
    },
    quanForumAdd() {
      this.dataEffect = {
        effect: this.effect,
        effectName: this.effectName,
      };
      this.isSHowQuan = false;
    },
    handleAvatarSuccess(res) {
      if (res.code == 1) {
        this.isSHowPings = false;
        this.fullscreenLoading = false;
        this.reportImgList.push(res.data.url);
      }
    },
    handleAvatarSuccess1(res) {
      if (res.code == 1) {
        this.fullscreenLoading = false;
        console.log(res, "resresres");
        this.reportImgList1.push(res.data);
        this.isSHowTu = false;
      }
    },
    getQueryReportReason() {
      this.$api.queryReportReason().then((res) => {
        res.data.forEach((item) => {
          item.isFlag = false;
        });
        this.personData = res.data;
      });
    },
    jubaoSubmit() {
      // report/report
      let data = {
        noSee: this.jubaoValue ? 1 : 0,
        type: 3,
        reason: this.reasonData,
        sourceId: this.jubaoUserId,
      };
      this.$api.report(data).then((res) => {
        // console.log()
        this.getForumList();
        if (res.code == 1) {
          this.jubaoShow = false;
          this.$message({
            message: "举报成功",
            type: "success",
          });
          this.reasonData = "";
        } else {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    jubao(item) {
      this.jubaoUserId = item.id;
      this.jubaoShow = true;
    },
    personBtn(item) {
      for (var i = 0; i < this.personData.length; i++) {
        this.personData[i].isFlag = false;
        if (item.id == this.personData[i].id) {
          item.isFlag = true;
          this.reasonData = item.id;
        }
      }
    },
    handleAvatarIng() {
      this.fullscreenLoading = true;
    },
    goBtnEffect(item) {
      this.effect = item.effect;
      this.effectName = item.name;
      this.effectArr.forEach((item1) => {
        if (item.effect == item1.effect) {
          item1.flag = true;
        } else {
          item1.flag = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    width: 12px;
	border-radius: 8px;
}

::-webkit-scrollbar-thumb {
	cursor: pointer;
}
	
.content{
	box-shadow: 0 1px 0 1px #eee;
}
	
/deep/ .el-dialog__header {
  display: none;
}
.openMore {
  color: rgba(51, 112, 251, 1);
  font-size: 16px;
}
.pageSize {
  width: 100%;
  position: relative;
}
.pageSizeAb {
  position: relative;
  width: 100%;
}
.guangTop {
  width: calc(100% - 48px);
  margin-left: 21px!important;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border-bottom: 1px solid #eeeeee;
  line-height: 62px;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
}
.noGuangchangTitle {
  width: 466px;
  height: 44px;
  line-height: 44px;

  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 4px 4px;
  margin: 40px auto 20px;
  box-sizing: border-box;
  padding: 12px 10px;
}
.corfinBtn {
  width: 176px;
  height: 40px;
  background: #ffdd00;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #212121;
  cursor: pointer;
  margin: 0 auto;
}
.guangTopDetail {
  width: calc(100% - 48px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border-bottom: 1px solid #eeeeee;
  line-height: 62px;
  align-items: center;
  background-color: #fff;
}
.contentName {
  margin-left: 10px;
}
.contentFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.sendGuang {
  width: 522px;
  height: 450px;
  background: #bcbcbc;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sendgong {
  width: 500px;
  height: 352px;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: absolute;
  z-index: 99999999;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -15%);
}
.sendGuangTop {
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background-color: #f5f5f5;
}
.quanSecond {
  width: 100%;
  height: 36px;
  background: #b1afa9;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 10px 20px;
}
.quanThreeFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 12px;
}
.quanThreeFlexTitle {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
}
.quanThreeFlexContent {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.sendSecond {
  width: 100%;
  height: 394px;
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.sendSecondFlex {
  display: flex;
  margin-bottom: 20px;
}
.fabuBtn {
  width: 80px;
  height: 32px;
  background: #d8d8d8;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 32px;
}
.qunBtn {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffa700;
}
.qunBanck {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #b1afa9;
}
.sendContent {
  width: 100%;
  height: 331px;
}
.sendBottom {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.sendBottomLeft {
  display: flex;
  align-items: center;
}
.sendContentTextarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 14px 20px;
  border: none;
  background-color: #fff;
  resize: none;
}
.sendGuangTopTitle {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
}
.content1 {
  border-bottom: 1px solid #eeeeee;
}
.contentTime {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 8px;
}
.contentText {
  width: 916px;
  padding-top: 12px;
  word-break: break-all; //在恰当的断字点进行换行
  overflow: hidden; //文字超出的进行隐藏
  text-overflow: ellipsis; //超出的文字用省略号表示
  display: -webkit-box; //将元素设为盒子伸缩模型显示
  text-overflow: ellipsis; //利用盒子模型
  -webkit-box-orient: vertical; //伸缩方向设为垂直方向
  -webkit-line-clamp: 2; //设定一共3行，超出的部分隐藏，并用省略号来表示
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #666;
}
.contentTextText {
  width: 916px;
  padding-top: 12px;
  word-break: break-all; //在恰当的断字点进行换行
  overflow: hidden; //文字超出的进行隐藏
  display: -webkit-box; //将元素设为盒子伸缩模型显示
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}
.priceBtn {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.priceBtnFlex {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.priceBtnFlexImg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.contentData {
  width: 100%;
  height: 75vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
.contentDetailFlex {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  margin-top: 16px;
  margin-left: 20px;
}
.commentOvlea {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 24px;
  padding-bottom: 140px;
  box-sizing: border-box;
}
.commentFlex {
  display: flex;
  align-content: center;
}
.commentName {
  padding: 10px;
}
.commentConent {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 64px;
}
.secondFlex {
  display: flex;
  align-items: center;
}
.commentTime {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}
.sendPingBtn {
  position: absolute;
  right: 10px;
  bottom: 12px;
  transform: perspective(-50%, -50%);
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
}
.pageSizeBottom {
  width: calc(100% - 48px);
  height: 88px;
  margin-left: 24px;
  z-index: 999;
  background-color: #fff;
  box-sizing: border-box;

  position: absolute;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px -3px 10px 1px rgba(0, 0, 0, 0.06);
  display: flex;
}
.pageSizeBottomIcon {
  margin-top: 26px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.share_popup {
  width: 435px;
  height: 64px;
  color: #212121;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  top: 60px;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
  align-items: center;
  .copyBtn {
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    background-color: rgba(255, 221, 0, 1);
  }
  .ipAddress {
    margin-top: 0px;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    background: #ececec;
    border-radius: 4px;
    align-items: center;
  }
}

.share_popup::after {
  content: "";
  width: 0px;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 30px;
}
/deep/ .el-dialog__body {
  padding: 0 0;
}

.showMore {
  .btns {
    display: none;
  }

  &:hover {
    .btns {
      display: block;
    }
  }
}
</style>