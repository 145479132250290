<template>
	<div class="bg">
		<!-- 
      }" -->

		<PromptBox />
		<div class="popUp_box1" :style="'background-image:url(' + phoneUser.avatar + ')'" v-if="isCall">
			<!--  -->
			<div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);margin-top: -10px;">
				<div style="
            display: flex;
            justify-content: space-between;
            margin: 10px 20px 0;
            overflow: hidden;
          ">
					<!-- <div></div> -->
					<!-- <div style="margin-top: 16px;font-size:16px;color:#fff" @click="isCall = false">×</div> -->
				</div>
				<div style="
            width: 150px;
            height: 150px;
            border-radius: 16px;
            margin: 124px auto 20px;
          ">
					<img style="width: 100%; height: 100%;border-radius: 16px;" :src="phoneUser.avatar" alt="" />
				</div>
				<div style="text-align: center">
					<div class="username">{{ phoneUser.nick }}</div>
					<div v-if="1" style="margin-top: 16px;font-size:16px;color:#fff">邀请你进行语音通话</div>
					<div v-if="0">等待对方接受邀请...</div>
				</div>
				<!-- <div
          style="
            width: 230px;
            height: 40px;
            background: rgba(0, 0, 0, 0.7);
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 204px auto 0;
          "
        >
          对方已挂断，通话结束
        </div> -->
				<div v-if="guaduanIsSHow" style="
            width: 230px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 150px auto 0;
          ">
					<div style="width:100%;color: #fff;font-size:20px;margin-top:12px;">{{ formattedTime }}</div>
				</div>
				<div style="display: flex; justify-content: space-between" :style="
            guaduanIsSHow ? 'margin: 20px 113px;' : 'margin: 200px 113px;'
          ">
					<div v-if="guaduanIsSHow && isAudio" @click="closeMicrophone">
						<img style="width: 48px; height: 48px" src="./static/230.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">麦克风</div>
					</div>
					<div v-if="guaduanIsSHow && !isAudio" @click="openMicrophone">
						<img style="width: 48px; height: 48px" src="./static/231.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">麦克风</div>
					</div>

					<div @click="DownOn(1)" v-if="isBackCall">
						<img style="width: 48px; height: 48px" src="./static/229.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">接听</div>
					</div>

					<div @click="overCall(1)" v-if="guaduanIsSHow">
						<img style="width: 48px; height: 48px" src="./static/228.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">挂断</div>
					</div>
					<div @click="DownBtn(1)" v-if="isBackCall">
						<img style="width: 48px; height: 48px" src="./static/228.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">拒绝</div>
					</div>
				</div>
			</div>

		</div>
		<div class="popUp_box2" :style="'background-image:url(' + phoneUser.avatar + ')'" id="video_2" v-if="isShopIng">
			<div class="popUp_Title">
				<div class="popUp_TitleFlex">
					<div class="flex align-center">
						<img class="popUp_TitleImg" :src="phoneUser.avatar" alt="" />
					</div>
					<div style="margin-left: 10px">
						<div class="username" style="color: #333;">{{ phoneUser.nick }}</div>
						<div style="font-size:16px;color:#333">邀请你加入视频通话</div>
					</div>
				</div>
				<!-- <div>
          <img src="./static/169.png" alt="" />
        </div> -->

			</div>
			<div style="width:800px;height:285px;position: absolute;top:80px;right:40px;" id="video_1"></div>
			<!-- <div><img style="width:236px;height:148px;position: absolute;top:210px;right:40px;object-fit: cover;border-radius:16px"
        src="./static/to3.png"
        alt=""
      /> </div> -->
			<div class="popUp_TitleBottom">
				<div class="popUp_TitleBottomFlex">
					<div v-if="!isflagJie && isAudio" @click="closeMicrophone">
						<img style="width: 48px; height: 48px" src="./static/230.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">麦克风</div>
					</div>
					<div v-if="!isflagJie && !isAudio" @click="openMicrophone">
						<img style="width: 48px; height: 48px" src="./static/231.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">麦克风</div>
					</div>

					<div v-if="!isflagJie && shexiangtouis" @click="closeSHExiangtou">
						<img style="width: 48px; height: 48px" src="./static/245.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">摄像头</div>
					</div>

					<div v-if="!isflagJie && !shexiangtouis" @click="kaiqishexiangtou">
						<img style="width: 48px; height: 48px" src="./static/246.png" alt="" />
						<div style="width: 48px; text-align: center;font-size:16px;color:#fff">摄像头</div>
					</div>

					<div v-if="isflagJie" @click="DownOn(2)">
						<img style="width: 48px; height: 48px" src="./static/229.png" alt="" />
						<div style="width: 48px; text-align: center; font-size: 14px;color:#fff">
							接听
						</div>
					</div>
					<div @click="overCall(2)" v-if="!isflagJie">
						<img style="width: 48px; height: 48px" src="./static/228.png" alt="" />
						<div style="width: 48px; text-align: center; font-size: 14px;color:#fff">
							挂断
						</div>
					</div>
					<div @click="DownBtn(2)" v-else>
						<img style="width: 48px; height: 48px" src="./static/228.png" alt="" />
						<div style="width: 48px; text-align: center; font-size: 14px;color:#fff">
							拒绝
						</div>
					</div>
				</div>
			</div>
		</div>

		<router-view></router-view>

		<div class="beian">
			<div class="flex" style="text-align: center">
				<div style="border-right: 1px solid #fff">
					<a href="http://yc.yinsuwangluokj.com/zc.html" target="_blank" class="aStyle" style="margin-right: 18px">用户协议</a>
				</div>
				<div style="border-right: 1px solid #fff; margin-left: 25px">
					<a href="http://yc.yinsuwangluokj.com/ys.html" target="_blank" class="aStyle" style="margin-right: 18px">隐私政策</a>
				</div>

				<div style="margin-left: 25px">版权所有© 狗书</div>
			</div>
			<div style="margin-top: 10px">
				备案号：
				<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="aStyle">鲁ICP备2022024546号-2
				</a>
				<span>公司地址：中国（山东）自由贸易试验区济南片区银荷大厦D座217室</span>
			</div>
		</div>
	</div>
	</div>
</template>
<script>
	// 禁止打开终端
	// 方法1
	// window.oncontextmenu=function(){return false;}
	//     window.onkeydown = window.onkeyup = window.onkeypress = function () {
	//     window.event.returnValue = false;
	//         return false;
	//     }
	//     var h = window.innerHeight,w=window.innerWidth;
	//     window.onresize = function () {
	//         if (h!= window.innerHeight||w!=window.innerWidth){
	//             window.close();
	//             window.location = "about:blank";
	//         }
	//     }
	// 方法2
	// import forbid from "@/utils/forbid.js" //禁止打开终端

	import PromptBox from "./components/PromptBox.vue";
	import request from "./request/index";
	import config from "./config";
	export default {
		name: "App",
		data() {
			return {
				changeUserCountKey: true,
				// eslint-disable-next-line no-undef
				admin_Img: require("./assets/roomicon/Manage.png"),
				// eslint-disable-next-line no-undef
				ower_Img: require("./assets/roomicon/administrators.png"),

				// eslint-disable-next-line no-undef
				voice_Img: require("./assets/live/icon-voice.png"),
				cooldown: 0,
				url: window.location.href,
				dw: 600,
				dh: 600,
				isRouterAlive: true,
				guaduanIsSHow: false,
				courentTime: 0,
				isCall: false,
				isAudio: false,
				atuserObj: {},
				isShopIng: false,
				isflagJie: false,
				isFlageImg: false,
				userTIMInfo: {},
				ssssss: {},
				phoneUser: {},
				timer: null,
				time: {
					hours: 0,
					minutes: 0,
					seconds: 0
				},
				shexiangtouis: true,
				isjieting: false
			};
		},

		components: {
			PromptBox
		},
		
		provide() {
			return {
				reload: this.reload,
			};
		},

		computed: {
			formattedTime() {
				const hoursStr = String(this.time.hours).padStart(2, '0');
				const minutesStr = String(this.time.minutes).padStart(2, '0');
				const secondsStr = String(this.time.seconds).padStart(2, '0');
				return `${hoursStr}:${minutesStr}:${secondsStr}`;
			}
		},

		created() {
			let TencentCloudChat = this.TencentCloudChat;
			let tuiCallEngine = this.tuiCallEngine;
			let TUICallEvent = this.TUICallEvent;
			let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
			this.userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
			let promise1 = tuiCallEngine.login({
				userID: userTIMInfo.id,
				userSig: userTIMInfo.userSig,
			});
			promise1
				.then(() => {})
				.catch((error) => {
					console.warn("login error:", error);
				});
			let onSdkReady = function(event) {
				console.log(event, "eventeventevent");
			};
			this.listenttuiCall(); //监听来电
			this.listenttuiCallNo(); //监听拒接
			this.listenttuiCallgua(); //监听挂断
			this.listenttuiCallUser(); //用户接通监听
			this.getShiping(); //监听用户是否开启摄像头
			this.getTonghua() //监听通话失败的原因
			// // RongRTCLib 初始化,请务必保证此过程只被执⾏⼀次
			// const rtcClient = RongIMLib.installPlugin(RongRTCLib.installer,{});
			// RCVoiceRoomLib.init({
			//   RongIMLib: RongIMLib,
			//   RongRTCLib: rtcClient,
			//   });
			// window.rc = this.$RCVoiceRoomLib;online
			// this.$RCVoiceRoomLib.init({ AppKey: config.appKey.online });
			// this.chat.on(this.TencentCloudChat.EVENT.SDK_READY, this.onReadyStateUpdate, this);
			// // // 收到新消息提示音
			// this.chat.on(this.TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
			// // // 会话列表更新
			// this.chat.on(this.TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, (event) => {
			//   this.$store.commit("updateConversationList", event.data);
			// });
			// this.$RongIMLib.init({
			//   appkey: config.appKey.online,
			// });
			// const rtcClient = this.$RongIMLib.installPlugin(
			//   this.$RongRTCLib.installer,
			//   {}
			// );
			// this.$RCVoiceRoomLib.init({
			//   RongIMLib: this.$RongIMLib,
			//   RongRTCLib: rtcClient,
			// });
			// this.$RCLiveRoomLib.init(config.appKey.online);
			// window.rc = this.$RCLiveRoomLib;
		},
		mounted() {
			setInterval(() => {
				console.clear();
			}, 10000000);
			var browser = {
				versions: (function() {
					var u = navigator.userAgent;
					return {
						//移动终端浏览器版本信息
						trident: u.indexOf("Trident") > -1, //IE内核
						presto: u.indexOf("Presto") > -1, //opera内核
						webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
						gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
						iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf("iPad") > -1, //是否iPad
						webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
					};
				})(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase(),
			};

			if (browser.versions.mobile) {
				//手机初始化
				if (screen.height * 0.5625 > screen.width) {
					this.$data.dw = screen.width;
				} else {
					this.$data.dw = screen.height * 0.5625;
				}
				this.$data.dh = screen.height;
			} else {
				//pc初始化
				this.$data.dw = 375;
				this.$data.dh = window.innerHeight;
			}
			window.onresize = () => {
				var deviceWidth =
					document.documentElement.clientWidth || window.innerWidth;
				if (deviceWidth >= 375) {
					deviceWidth = 375;
				}
				if (deviceWidth <= 320) {
					deviceWidth = 320;
				}
				document.documentElement.style.fontSize = deviceWidth / 3.75 + "px";

				if (browser.versions.mobile) {
					//手机端不给重置页面 防止输入法 弹出某些页面会乱
				} else {
					this.$data.dw = 375;
					this.$data.dh = window.innerHeight;
					// this.$data.dw = window.innerHeight * 0.5625;
					// this.$data.dh = window.innerHeight;
				}
			};
			//语聊房 rtc 断开连接(待机断线问题)
			// this.$RCVoiceRoomLib.on("RTCPeerConnectionCloseByException", async () => {
			//   await this.$RCVoiceRoomLib.leaveRoom(this.$RCVoiceRoomLib._roomidcli);
			//   this.$router.replace("/home");
			//   location.reload();
			// });
			// //语聊房账号被顶掉
			// this.$RCVoiceRoomLib.on("ConnectioBreakOff", () => {
			//   //业务代码
			//   this.$message.error("账号已在其他地方登录");
			//   localStorage.removeItem("user-token");
			//   this.skipHome();
			// });
		},

		methods: {
			// 开启摄像头
			kaiqishexiangtou() {
				this.tuiCallEngine
					.openCamera('localView')
					.then((res) => {
						// success
						this.shexiangtouis = true;
						// console.log(res, "console.log(res);console.log(res);");
					})
					.catch((error) => {
						console.warn("openCamera error:", error);
					});
			},

			// 关闭摄像头
			closeSHExiangtou() {
				this.tuiCallEngine
					.closeCamera()
					.then((res) => {
						this.shexiangtouis = false;
						console.log(
							res,
							"console.log(res);console.log(res);console.log(res);"
						);
						// success
					})
					.catch((error) => {
						console.warn("closeMicrophone error:", error);
					});
			},

			startTimer() {
				this.timer = setInterval(() => {
					this.incrementTime();
				}, 1000);
			},
			stopTimer() {
				if (this.timer) {
					clearInterval(this.timer);
					this.timer = null;
				}
			},
			incrementTime() {
				this.time.seconds++;
				if (this.time.seconds >= 60) {
					this.time.seconds = 0;
					this.time.minutes++;
					if (this.time.minutes >= 60) {
						this.time.minutes = 0;
						this.time.hours++;
					}
				}
			},
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				});
			},
			openMicrophone() {},
			// 打电话唤起
			listenttuiCall() {
				let that = this;
				let handleOnCallReceived = function(event) {
					// console.log(event,'sssssssssssssss')
					// 在聊天中存入本地，现在取出
					that.phoneUser = JSON.parse(localStorage.getItem('phoneUserinfo'));
					console.log(that.phoneUser, '这是用户信息ssssssssssssss')
					if (event.callMediaType == 1) {
						that.isBackCall = true;
						that.isCall = true;
					}
					if (event.callMediaType == 2) {
						that.isShopIng = true;
						that.isflagJie = true;
					}
					let data = {
						userProfile: {
							userID: event.calleeIdList[0],
						},
					};
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.ON_CALL_RECEIVED,
					handleOnCallReceived
				);
			},
			listenttuiCallUser() {
				let that = this;
				let handleOnCallReceived = function(event) {};
				this.tuiCallEngine.on(
					this.TUICallEvent.USER_ACCEPT,
					handleOnCallReceived
				);
			},
			// 通话中挂断
			listenttuiCallgua() {
				let that = this;
				let handleInviteeReject = function(event) {
					if (event.callMediaType == 2) {
						that.isShopIng = false;
					}
					if (event.callMediaType == 1) {
						that.isCall = false;
						that.isBackCall = false;
						that.guaduanIsSHow = false;
					}
					let data = {
						userProfile: {
							userID: event.userID,
						},
					};
					that.stopTimer();
					that.isjieting = false;
					// that.selectUserChat(data, "", true);
				};
				this.tuiCallEngine.on(this.TUICallEvent.CALLING_END, handleInviteeReject);
			},
			// 打电话拒绝监听
			listenttuiCallNo() {
				let that = this;
				let handleInviteeReject = function(event) {
					// 拒绝视频
					that.isShopIng = false;
					// 拒绝音频
					that.isCall = false;
					that.isBackCall = false;
					that.guaduanIsSHow = false;
					that.isjieting = false;
				};
				this.tuiCallEngine.on(this.TUICallEvent.REJECT, handleInviteeReject);
			},
			// 拒绝
			DownBtn(type) {
				let that = this;
				this.tuiCallEngine
					.reject()
					.then((res) => {
						if (type == 1) {
							this.guaduanIsSHow = false;
							this.isBackCall = false;
							this.isCall = false;
						}
						if (type == 2) {
							this.isflagJie = true;
							this.isShopIng = false;
						}
						that.isjieting = false;
					})
					.catch((error) => {
						console.warn("reject error:", error);
					});
			},
			// 接听
			DownOn(type) {
				this.tuiCallEngine
					.accept()
					.then((res) => {
						this.time = {
							hours: 0,
							minutes: 0,
							seconds: 0
						}
						this.startTimer();
						if (type == 1) {
							this.isBackCall = false;
							this.guaduanIsSHow = true;
						}
						if (type == 2) {
							this.isflagJie = false;
							this.xuanranBen(this.userTIMInfo.id);
						}
						this.isAudio = true;
						this.isjieting = true;
						this.kaiqishexiangtou();
					})
					.catch((error) => {
						if (error == 'TUICallEngineError: TUICallEngine.accept failed. error: NotFoundError: Requested device not found. 没有麦克风设备 <NOT_MICROPHONE_LIST: 60003>') {
							this.$message.error('没有麦克风设备');
						} else {
							this.$message.error(error);
						}
					});
			},
			// 静音
			closeMicrophone() {
				this.tuiCallEngine
					.closeMicrophone()
					.then((res) => {
						this.isAudio = false;
						// success
					})
					.catch((error) => {
						console.warn("closeMicrophone error:", error);
					});
			},
			// 打开麦克风
			openMicrophone() {
				this.tuiCallEngine
					.openMicrophone()
					.then((res) => {
						// success
						this.isAudio = true;
						// console.log(res, "console.log(res);console.log(res);");
					})
					.catch((error) => {
						console.warn("openMicrophone error:", error);
					});
			},
			// 结束通话
			overCall(type) {
				let that = this;
				this.tuiCallEngine
					.hangup()
					.then((res) => {
						if (type == 1) {
							this.guaduanIsSHow = false;
							this.isBackCall = false;
							this.isCall = false;
						} else {
							this.isflagJie = true;
							this.isShopIng = false;
						}
						that.stopTimer();
						that.isjieting = false;
					})
					.catch((error) => {
						console.warn("hangup error:", error);
					});
			},

			// 取消通话
			getTonghua() {
				let that = this
				let handleCallingCancel = function(event) {
					if (event.callMediaType == 1) {
						that.guaduanIsSHow = false;
						that.isBackCall = false;
						that.isCall = false;
					}
					if (event.callMediaType == 2) {
						that.isflagJie = true;
						that.isShopIng = false;
					}
				};
				that.stopTimer();
				that.isjieting = false;
				this.tuiCallEngine.on(this.TUICallEvent.CALLING_CANCEL, handleCallingCancel);
			},
			// 远端用户是否开启视频
			getShiping() {
				let that = this;
				let handleUserVideoChange = function(event) {
					if (event.isVideoAvailable) {
						console.log(event, "开启了摄像头");
						that.isFlageImg = true;
						that.xuanran(event.userID);
					} else {
						that.isFlageImg = false;
						console.log(event, "没开摄像头");
					}
				};
				this.tuiCallEngine.on(
					this.TUICallEvent.USER_VIDEO_AVAILABLE,
					handleUserVideoChange
				);
			},
			// 渲染视频
			xuanran(user1) {
				let options = {
					objectFit: "contain",
				};
				let promise = this.tuiCallEngine.startRemoteView({
					userID: user1,
					videoViewDomID: "video_1",
					options,
				});
				promise
					.then(() => {
						// success
						console.log("ssssssssss");
					})
					.catch((error) => {
						console.warn("startRemoteView error:", error);
					});
			},
			// 渲染视频
			xuanranBen(user1) {
				let options = {
					objectFit: "contain",
				};
				let promise = this.tuiCallEngine.startLocalView({
					userID: user1,
					videoViewDomID: "video_2",
					options,
				});
				promise
					.then(() => {
						// success
						console.log("ssssssssss");
					})
					.catch((error) => {
						console.warn("startRemoteView error:", error);
					});
			},
		},
	};
</script>

<style>
	.cursor {
		cursor: pointer;
	}
	
	.flex-sub{
		flex:1;
	}
</style>

<style lang="scss" scoped>
	
	
	.username {
		color: #fff;
		font-size: 18px;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		height: 100%;
		width: 56.25vh;

		overflow: hidden;
		margin: auto;
		background: #fff;
		min-width: none;
		min-height: 625px;
	}

	.beian {
		width: 100%;
		height: 60px;
		color: #fff;
		font-size: 13px;
		z-index: 10;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: fixed;
		left: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.aStyle {
		color: #fff;
		text-decoration: none;
	}

	.customClass-Control {
		width: 3rem !important;
	}

	.bg {
		display: flex;
		justify-content: center;
		background: url(./static/bg_color.png);
		height: 100vh;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		/* myq */
	}

	.el-tabs__content::-webkit-scrollbar {
		width: 12px !important;
	}

	.el-drawer__wrapper {
		position: absolute !important;
		/* max-width: 375px !important;
  left: calc(50vw - 187.5px) !important; */
	}

	.el-dialog__wrapper {
		position: absolute !important;
		/* max-width: 375px !important;
  left: calc(50vw - 187.5px) !important; */
	}

	.v-modal {
		position: absolute;
		/* width: 375px !important;
  left: calc(50vw - 187.5px) !important; */
	}

	.line1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.line2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.line3 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.cur_head {
		cursor: pointer;
	}

	/* 定义淡入淡出动画 */
	.fade-in {
		animation: fade-in 0.2s ease-in;
	}

	.fade-out {
		animation: fade-out 0.2s ease-out;
		opacity: 0;
	}

	.popUp_box2 {
		width: 886px;
		height: 554px;
		position: absolute;
		z-index: 9999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		background-size: 100% 100%;
		object-fit: cover;
		background-repeat: no-repeat;
	}

	.popUp_TitleBottom {
		width: 860px;
		background: rgba(57, 57, 57, 0.8);
		position: absolute;
		bottom: 15px;
		left: 15px;
		border-radius: 0 0 24px 24px;
	}

	.popUp_TitleBottomFlex {
		display: flex;
		width: calc(100% - 578px);
		margin: 0 auto 24px;
		justify-content: space-between;
	}

	.popUp_Title {
		width: calc(100% - 48px);
		margin: 24px auto 0;
		display: flex;
		justify-content: space-between;
	}

	.popUp_TitleImg {
		width: 48px;
		height: 48px;
		border-radius: 50%;
	}

	.popUp_TitleFlex {
		display: flex;
		align-items: center;
		margin-left: 10px;
	}

	.popUp_box1 {
		width: 442px;
		height: 768px;
		position: absolute;
		z-index: 9999;
		background-size: 100% 100%;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
		// background-image: url("./static/to3.png");
	}

	

	.popUp_box {
		position: absolute;
		top: 0;
		left: 0%;
		z-index: 1;
		font-size: 16px;
		color: #fff;

		.remid_box {
			width: 522px;
			position: absolute;
			top: 40%;
			left: 50%;
			font-size: 14px;
			transform: translate(-50%, -50%);
			background: #fff;
			border-radius: 4px;
		}

		.contentSi1 {
			width: 334px;
			margin: 0px auto 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
		}

		.contentSi {
			width: 334px;
			margin: 0px auto 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #f5f5f5;
			padding: 12px 10px;
			box-sizing: border-box;
		}

		.BtnSubmit {
			width: 256px;
			height: 40px;
			background: #ffdd00;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			text-align: center;
			line-height: 40px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			color: #333333;
			margin: 0 auto;
		}

		.remind_btn {
			width: 176px;
			height: 40px;
			background: rgba(255, 221, 0, 1);
			border-radius: 4px 4px 4px 4px;
			color: #333;
		}
	}

	@keyframes fade-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes fade-out {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	.el-popover {
		background: rgba(0, 0, 0, 0.5) !important;
		border-color: rgba(0, 0, 0, 0.5) !important;
		border-radius: 10px;
		color: #fff !important;
	}

	.el-popper[x-placement^="bottom"] .popper__arrow::after {
		border-bottom-color: rgba(0, 0, 0, 0.5) !important;
	}

	.el-popper[x-placement^="bottom"] .popper__arrow {
		border-bottom-color: rgba(0, 0, 0, 0.5) !important;
	}
</style>