<template>
  <div class="roomPage">
    <!-- 个人中心---房间管理 -->
    <div class="title">语聊厅</div>
    <div class="roomList_box">
      <div class="room_item" v-for="(item, index) in chatRoom" :key="index">
        <div class="flex" @click.stop="skipChatRoom(item)">
          <img :src="item.logo" alt="" class="roomItem_head" />
          <div class="flex" style="justify-content: space-between;">
            <div style="width:320px">
              <div class="flex" style="color: #1d223b;width:350px">
                {{ item.name }}
                <div v-if="item.userRole == 0" class="room_role">房主</div>
              </div>
              <p class="font-12" style="color: #8e92b1">ID：{{ item.roomId }}</p>
              <div class="flex-aling" style="margin-top: 6px">
                <img :src="item.avatar" alt="" class="roomItem_userHead" />
                <p style="color: #8e92b1">{{ item.nickname }}</p>
              </div>
            </div>
            <div class="tingSet" style="margin-right:14px">
              <img src="../../image/room/room1.png" alt="" v-if="
                item.labelName == '游戏派单厅' || item.labelName == '声优派单厅'
              " class="topLeftIcon" />

              <img src="../../image/room/room2.png" alt="" class="topLeftIcon" style="width:50px;height:20px"
                v-else-if="item.labelName == '点唱'" />
              <div class="topLeftIconPei" v-else-if="item.labelName == '游戏陪玩'">
                <img src="../../image/room/room7.png" alt="" style="width:18px;height:18px" />
                <span class="topicNameColor">{{ item.topicName }}</span>
              </div>

              <img src="../../image/room/room5.png" alt="" class="topLeftIcon" v-else-if="item.labelName == '扩列交友'" />
              <img src="../../image/room/room5.png" alt="" class="topLeftIcon" v-else-if="item.labelName == '交友'" />
              <img src="../../image/room/room6.png" alt="" class="topLeftIcon" v-else-if="item.labelName == '男声'" /><img
                src="../../image/room/room1.png" alt="" class="topLeftIcon" v-else-if="item.labelName == '娱乐派单厅'" />
              <img src="../../image/room/room8.png" alt="" class="topLeftIcon" v-else-if="item.labelName == '娱乐'" />
              <img src="../../image/room/room3.png" alt="" class="topLeftIcon" v-else />
              <!-- <img :src="item.logo" alt="" class="serachChatRoom_img" /> -->
              <div v-if="item.userRole == 0" @click.stop="getRoomManger(item)">厅设置</div>
            </div>
          </div>
        </div>
        <!-- <img src="../../static/114.png" alt="" class="roomItem_icon" /> -->
      </div>
      <div v-loading='loading' style="width: 100%;">
        <el-empty description=" " v-if="!chatRoom.length"></el-empty>
      </div>
    </div>
    <!-- 开黑房 -->
    <div class="title">开黑房</div>
    <div class="roomList_box">
      <div class="room_item" v-for="(item, index) in darkRoomList" :key="index" >
        <div class="flex">
          <img :src="item.logo" alt="" class="roomItem_head" />
          <div class="flex" style="justify-content: space-between;" >
            <div style="width:320px" @click.stop="skipChatRoom(item)">
              <div class="flex" style="color: #1d223b">
                {{ item.name }}
                <div v-if="item.roleName" class="room_role">
                  {{ item.roleName }}
                </div>
              </div>
              <p class="font-12" style="color: #8e92b1">ID：{{ item.roomId }}</p>
              <div class="flex-aling" style="margin-top: 6px">
                <img :src="item.avatar" alt="" class="roomItem_userHead" />
                <p style="color: #8e92b1">{{ item.nickname }}</p>
              </div>
            </div>
            <div class="tingSet">
              <div></div>
              <div v-if="item.userRole == 0" @click.stop="getRoomManger(item)">厅设置</div>
            </div>
          </div>
        </div>
        <!-- <img src="../../static/114.png" alt="" class="roomItem_icon" /> -->
      </div>
    </div>
    <!-- 创建开黑房 -->
    <div class="establish_btn flex-content" @click="establishRoomShow = true" v-if="roomValue == 1">
      创建开黑房
    </div>
    <div v-loading='loading' style="width: 100%;">
      <el-empty description=" " v-if="roomValue == 0 && !darkRoomList.length"></el-empty>
    </div>
    <!-- 创建房间 -->
    <div class="popup_box" v-if="establishRoomShow" style="z-index:99999999999">
      <div class="establishRoom_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14  flex-aling">创建开黑房</div>
          <img src="../../static/quit.png" alt="" @click="establishRoomShow = false" />
        </div>
        <!-- 头部end -->
        <div class="establish_box">
          <div class="flex-aling">
            <div class="establishTab_item flex-content" :class="labelType == 1 ? 'establishTab_puth' : ''"
              @click="labelChange(1)">
              游戏开黑
            </div>
            <div class="establishTab_item flex-content" :class="labelType == 2 ? 'establishTab_puth' : ''"
              @click="labelChange(2)">
              娱乐
            </div>
          </div>
          <!-- 二级分类标签 -->
          <div class="skill_box">
            <div class="skill_item flex-dir" v-for="(item, index) in labelType == 1
            ? gameLabels
            : amusementLabels" :key="index" :class="twolabel.id == item.id ? 'skill_pitch' : ''"
              @click="secondLevelChange(item)">
              <img :src="item.logo" alt="" class="label_img" />
              <div class="itemText">{{ item.name }}</div>
            </div>
          </div>
          <!-- 二级分类标签 -->

          <div class="establish_formBox" :style="{ height: labelType == 2 ? '200px' : '' }">
            <el-form ref="form" :model="roomform" label-width="130px">
              <el-form-item label="房间封面:">
                <img :src="defaultImg" alt="" class="cover_img" />
                <div class="font-12" style="
                    color: #8586a9;
                    height: 15px;
                    transform: translateY(-15px);
                  ">
                  默认头像，不可更换
                </div>
              </el-form-item>
              <el-form-item label="房间名称:">
                <el-input v-model="roomform.name" maxlength="10" placeholder="请输入房间名称 (10字内)"
                  style="width: 320px"></el-input>
              </el-form-item>
              <template v-if="labelType == 1">
                <el-form-item label="游戏区服:">
                  <el-select v-model="roomform.districtId" placeholder="选择区服">
                    <el-option v-for="item in gameDistricts" :key="item.value" :label="item.text" :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="游戏段位:">
                  <el-select v-model="roomform.levelId" placeholder="选择段位">
                    <el-option v-for="item in gameLevels" :key="item.value" :label="item.text" :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="对局模式:">
                  <el-select v-model="roomform.modeId" placeholder="选择对局模式">
                    <el-option v-for="item in gameMode" :key="item.value" :label="item.text" :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开黑人数:">
                  <el-select v-model="roomform.numLimit" placeholder="选择人数">
                    <el-option v-for="item in gamePersonNum" :key="item.value" :label="item.text" :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-form>
          </div>
          <div class="establish_formbtn flex-content" @click="createOpenBlackRoom()">
            立即创建
          </div>
        </div>
      </div>
    </div>

    <div class="popUp_box" v-if="roomShow">
      <div class="wheat_remind" style="height: 346px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">房间管理</div>
          <img src="../../static/quit.png" alt="" @click="roomShow = false" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <div class="room_titleBox flex">
            <img class="room_titleBoxHead" :src="roomInformation.logo" alt="" />
            <div>
              <div class="flex-aling">
                <div class="titleBoxIcon" style="color: #fff">
                  {{ roomInformation.topic }}
                </div>
                <div class="font-14 padding_r10 titleBoxName" style="color: #fff">
                  {{ roomInformation.name }}
                </div>
              </div>
              <div class="room_titleBoxText" style="color: #fff">
                ID：{{ roomInformation.roomNo }}
              </div>
              <div class="room_titleBoxText" style="color: #fff">
                本次已开启：{{ roomInformation.timeLength }}
              </div>
            </div>
          </div>
          <div class="flex-spacebetween" style="width: 403px; color: #333">
            <div class="roomAdmin_bg flex-aling">
              <img src="../../static/other17.png" alt="" class="roomAdmin_icon" />
              <img src="../../static/62.png" alt="" class="roomAdmin_page" @click.stop="visitorListShow = true;roomShow=false" />
              <div style="color: #333">
                <div class="font-14">访客</div>
                <div class="font-10">{{ this.visitorNum }}人</div>
              </div>
            </div>
            <div class="roomAdmin_bg flex-aling" @click="darkRoomAdminChange()" style="color: #333">
              <img src="../../static/other18.png" alt="" class="roomAdmin_icon" />
              <img src="../../static/62.png" alt="" class="roomAdmin_page" />
              <div>
                <div class="font-14">管理员</div>
                <div class="font-10" style="color: #8e92b1">
                  {{ this.adminNum }}人
                </div>
              </div>
            </div>
            <div class="roomAdmin_bg flex-aling" style="color: #333" @click="associationHouseOperate(9)">
              <img src="../../static/other19.png" alt="" class="roomAdmin_icon" />
              <img src="../../static/62.png" alt="" class="roomAdmin_page" />
              <div>
                <div class="font-14">背景图</div>
              </div>
            </div>
          </div>
          <div class="flex-spacebetween" style="width: 403px; margin-top: 10px; color: #333">
            <div class="roomAdmin_bg flex-aling" style="width: 198px; justify-content: space-between">
              <div style="display: flex">
                <div>
                  <div class="font-14">用户上麦申请</div>
                </div>
              </div>
              <div>
                <el-switch v-model="roomInformation.userApplySwitch" :active-value="1" :inactive-value="0"
                  @change="setUpMicroSwitch('all')">
                </el-switch>
              </div>
            </div>
            <div class="roomAdmin_bg flex-aling" style="width: 198px; justify-content: space-between"
              v-if="roomDetail.resultCode == 6">
              <div style="display: flex">
                <img src="../../static/other21.png" alt="" class="roomAdmin_icon" />
                <div>
                  <div class="font-14">对讲机模式</div>
                </div>
              </div>
              <div>
                <el-switch v-model="roomDetail.isIntercom" :active-value="1" :inactive-value="0"
                  @change="setRoomUserSet('all')">
                </el-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popUp_box" v-if="backgroundImageShow" style="z-index: 99999999">
      <div class="bgImgBox">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">背景图选择</div>
          <img src="../../static/quit.png" alt="" @click="backgroundImageShow = false; this.societyEditShow = true" />
        </div>
        <!-- 头部end -->
        <div class="bgImg_scroll">
          <div v-for="(item, index) in bgImgList" :key="index" class="bgImg_item"
            @dblclick.stop="selectBackGround(item)">
            <img :src="item.imageurl" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="popUp_box" v-if="societyEditShow" style="z-index: 9999999">
      <div class="editRoom" style="width:600px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑房间信息</div>
          <img src="../../static/quit.png" alt="" @click="societyEditShow = false" />
        </div>
        <!-- 头部end -->
        <div class="editRoom_box">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="房间ID:" class="color_fff" style="color: #333">
              <span style="color: #d3d4dc">{{ roomInformation.roomNo }}</span>
            </el-form-item>
            <el-form-item label="房间封面:" class="color_fff" style="color: #333">
              <img :src="roomInformation.logo" alt="" style="width: 80px; height: 80px; border-radius: 4px" />
            </el-form-item>
            <el-form-item label="房间类型:" class="color_fff" style="color: #333">
              <el-input v-model="roomInformation.topic" placeholder="请输入类型" style="width: 200px"
                :disabled="true"></el-input>
            </el-form-item>
            <!-- <el-form-item label="房间类别:" class="color_fff">
              <el-input
                v-model="form.name"
                placeholder="请输入房间类别"
                style="width: 200px"
                :disabled="true"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="房间名称:" class="color_fff" style="color: #333">
              <el-input v-model="roomInformation.name" placeholder="请输入名称" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="房间背景:" class="color_fff" style="color: #333">
              <div style="position: relative; width: 220px; height: 160px" v-if="roomBack"
                @click.stop="roomDetail.theme=='开黑厅'?'':backgroundImageShow = true; societyEditShow = false">
                <img :src="roomBack" alt="" style="width: 100%; height: 100%; border-radius: 4px" />
                <img src="../../image/my/19.png" alt="" style="position: absolute; right: 6px; top: 6px"
                  @click.stop=" bgImgChangeDel()" />
              </div>
              <img v-else src="../../static/183.png" alt=""
                @click.stop="roomDetail.theme=='开黑厅'?'':(backgroundImageShow = true,societyEditShow = false)" />

              <!-- <el-upload
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                :limit="1"
                :show-file-list="false"
                list-type="picture-card"
                :on-success="streamImgSuccess"
                v-else
              >
                <img src="../../static/183.png" alt="" />
              </el-upload> -->
            </el-form-item>
          </el-form>
        </div>
        <div class="editRoom_btn flex-content" @click.stop="saveRoomChange()">
          保存
        </div>
      </div>
    </div>
    <darkRoomAdmin style="top:-10%;left:-20%" :roomId="roomDetail.roomId" v-if="darkRoomAdminShow" @darkAdminListChange="darkRoomAdminChange()">
    </darkRoomAdmin>
    <visitorList style="top:-10%;left:-20%" :liveStreamId="roomDetail.liveStreamId" v-if="visitorListShow" @visitorListChange="visitorListShow = false;roomShow=true">
		</visitorList>
  </div>
</template>

<script>
// ./components/darkRoomAdmin.vue
import visitorList from "../chatRoom/components/visitorList.vue"; //访客列表
import darkRoomAdmin from "../chatRoom/components/darkRoomAdmin.vue"; // 开黑管理员列表
export default {
  name: "",
  components: { darkRoomAdmin ,visitorList},
  data() {
    return {
      darkRoomAdminShow:false,
      visitorListShow:false,
      backgroundImageShow: false,
      societyEditShow: false,
      roomDetail: {},
      roomShow: false,
      roomInformation: {},
      adminNum: 0,
      visitorNum: 0,
      houseOwnerOperate: 0,
      loading: true,
      establishRoomShow: false, //创建房间
      roomform: {
        name: "",
        districtId: "", //游戏区服id
        levelId: "", //游戏段位Id
        modeId: "", //游戏模式id
        numLimit: "", //开黑人数限制
        theme: "", //聊天室主题
      },
      labelType: 1, //房间类型 1游戏 2娱乐
      twolabel: {}, //二级标签
      defaultImg: "", //房间默认封面
      gameLabels: [], //游戏标签
      amusementLabels: [], //娱乐标签
      gameDistricts: [], //游戏区服列表
      gameLevels: [], //游戏段位列表
      gameMode: [], //	游戏模式列表
      gamePersonNum: [], //		游戏人数列表
      chatRoom: [], //娱乐房列表
      darkRoomList: [], //开黑房列表
      userInfo: {},
      roomBack: '',
      roomValue: 0, //是否有开黑房权限 1有 0没有
    };
  },
  destroyed() {
    this.roomDetail = {}
  },
  mounted() {
    this.getAllSecondLabel();
    this.languageToChatRoom();
    this.playTogetherRoom();
    this.userInfo = this.$store.state.userInfo;
    this.getIsRoomwhitelist();
    // console.log("用户数据", this.userInfo);
  },
  methods: {
    bgImgChangeDel() {
      this.roomBack = "";
    },
    editRoomChange() {
      // this.editRoomShow = true;
      this.establishRoomShow=true
      this.getAllSecondLabel();
    },
    associationHouseOperate(type) {
      if (type == 1) {
        //语聊模式
        this.roomModeSwitch(type);
      } else {
        this.societyEditShow = true;
        this.roomShow = false
        this.$api.selRoomImage().then((res) => {
          if (res.code == 1) {
            this.bgImgList = res.data;
          }
        });
        this.$api.roomInfo({
          liveStreamId: this.roomDetail.liveStreamId
        }).then((res) => {
          if (res.code == 1) {
            this.roomInformation = res.data;
          }
        });
      }
      this.roomFunctionShow = false;
    },
    saveRoomChange() {
      // 保存背景图片
      this.$api
        .saveRoomBack({
          roomBack: this.roomBack,
          roomId: this.roomDetail.roomId
        })
        .then((res) => {
          if (res.code == 1) {
            this.societyEditShow = false;
            // this.$RCVoiceRoomLib.notifyVoiceRoom("img_back", this.roomBack);
            // this.enterRoom();
          }
        });
    },
    darkRoomAdminChange() {
      this.darkRoomAdminShow = !this.darkRoomAdminShow;
      this.roomShow=false
    },
    selectBackGround(i) {
      this.roomBack = i.imageurl;
      this.backgroundImageShow = false;
      this.societyEditShow = true
    },
    setUpMicroSwitch() {
      this.$api.setUpMicroSwitch({
        roomId: this.roomDetail.roomId
      }).then((res) => { });
    },
    setRoomUserSet() {
      this.$api.setRoomIntercom({
        roomId: this.roomDetail.roomId
      }).then((res) => { });
    },
    // 是否开黑房权限
    getIsRoomwhitelist() {
      this.$api.isRoomwhitelist({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.roomValue = res.data.value;
        }
      });
    },
    // 跳转聊天室
    skipChatRoom(i) {
      // return console.log(i);
      this.$router.push({
        path: "/someSingingHall",
        query: { roomId: i.roomId, liveStreamId: i.liveStreamingId },
      });
    },

    // 房间管理
    getRoomManger(item) {
      //房间管理
      //管理员
      this.roomDetail = item
      this.roomShow = true;
      console.log(this.houseOwnerOperate, 'itemitem')
      this.$api.roomInfo({
        liveStreamId: item.liveStreamingId
      }).then((res) => {
        if (res.code == 1) {
          this.roomInformation = res.data;
        }
      });
      this.$api.roomManagersInfo({
        roomId: item.roomId
      }).then((res) => {
        if (res.code == 1) {
          this.adminNum = res.data.rows.length;
        }
      });
      // 访客
      this.$api
        .roomVisitorInfo({
          liveStreamId: item.liveStreamingId
        })
        .then((res) => {
          if (res.code == 1) {
            this.visitorNum = res.data.rows.length;
          }
        });
    },

    // 语聊房列表
    languageToChatRoom() {
      this.$api
        .languageToChatRoom({ pageNum: 1, pageSize: 999 })
        .then((res) => {
          this.loading = false
          if (res.code == 1) {
            this.chatRoom = res.data.rows;
          } else {
            this.$message.error(res.msg)
          }
        });
    },
    // 开黑厅列表
    playTogetherRoom() {
      this.$api.playTogetherRoom({ pageNum: 1, pageSize: 999 }).then((res) => {
        this.loading = false
        if (res.code == 1) {
          this.darkRoomList = res.data.rows;
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 创建开黑房
    createOpenBlackRoom() {
      this.roomform.theme = this.twolabel.id;
      if (!this.roomform.name) return this.$message.info("请输入房间名称");
      if (this.labelType == 1) {

        if (!this.roomform.districtId) return this.$message.info('请选择游戏区服')
        if (!this.roomform.levelId) return this.$message.info('请选择游戏段位')
        if (!this.roomform.modeId) return this.$message.info('请选择游戏模式')
        if (!this.roomform.numLimit) return this.$message.info('请选择开黑人数')
      }
      this.$api.createOpenBlackRoom(this.roomform).then((res) => {
        if (res.code == 1) {
          // this.$message.info(res.msg)
          //配置房间信息
          const rcVoiceRoomInfo = {};
          // 设置房间名称
          rcVoiceRoomInfo.roomName = this.roomform.name;
          // 设置麦位数量
          rcVoiceRoomInfo.seatCount = 10;
          let data = res.data;
          this.$router.push({
            path: "/someSingingHall",
            query: { roomId: data.roomId, },
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 二级分类选择
    secondLevelChange(i) {
      this.twolabel = i;
      this.getCreateGameRoomOptions(this.twolabel.id);
    },
    // 类型选择
    labelChange(type) {
      this.labelType = type;
      if (type == 1) {
        this.twolabel = this.gameLabels[0];
        this.roomform = {
          name: "",
          districtId: "", //游戏区服id
          levelId: "", //游戏段位Id
          modeId: "", //游戏模式id
          numLimit: "", //开黑人数限制
          theme: "", //聊天室主题
        };
      } else {
        this.twolabel = this.amusementLabels[0];
        this.roomform = { name: "" };
      }
      this.getCreateGameRoomOptions(this.twolabel.id);
    },
    // 获取创建游戏聊天室选项列表
    getCreateGameRoomOptions(id) {
      this.$api.getCreateGameRoomOptions({ gameLabelId: id }).then((res) => {
        if (res.code == 1) {
          this.defaultImg = res.data.defaultImg;
          this.gameDistricts = res.data.gameDistricts; //游戏区服列表
          this.gameLevels = res.data.gameLevels; //游戏段位列表
          this.gameMode = res.data.gameMode; //	游戏模式列表
          this.gamePersonNum = res.data.gamePersonNum; //		游戏人数列表
        }
      });
    },
    // 热门-游戏-娱乐，三个分类下所有分类
    getAllSecondLabel() {
      this.$api.getAllSecondLabel().then((res) => {
        if (res.code == 1) {
          this.amusementLabels = res.data.amusementLabels;
          this.gameLabels = res.data.gameLabels;
          this.twolabel = this.gameLabels[0];
          this.getCreateGameRoomOptions(this.gameLabels[0].id);
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
body {
  position: relative;
}

.bgImgBox {
  width: 700px;
  height: 600px;
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;

  .bgImg_scroll {
    display: flex;
    height: 500px;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-y: scroll;

    .bgImg_item {
      width: 45%;
      height: 125px;
      margin-top: 18px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}

.room_role {
  color: #333;
  font-size: 10px;
  text-align: center;
  line-height: 19px;
  background: linear-gradient(90deg, #FFA700 0%, #FFDD00 100%);
  margin: 0 8px;
  border-radius: 12px;
  width: 70px;
  height: 25px;
  line-height: 25px;
}

.popup_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
}

.establishRoom_box {
  width: 860px;
  height: 760px;
  color: #333;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);

  .establish_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .establishTab_item {
    width: 102px;
    height: 32px;
    margin-right: 10px;
    background: #f5f5f5;
    border-radius: 4px 4px 0px 0px;
  }

  .establishTab_puth {
    background-color: rgba(255, 221, 0, 1);
  }

  .skill_box {
    width: 820px;
    height: 110px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: rgba(255, 221, 0, 1);
    border-radius: 0px 4px 4px 4px;
    box-sizing: border-box;
    overflow-x: scroll;

    .skill_item {
      width: 90px;
      height: 90px;
      color: #8586a9;
      text-align: center;
    }

    .itemText {
      width: 90px;
    }

    .skill_pitch {
      width: 90px;
      height: 90px;
      color: #fff;
      background: RGBA(255, 205, 0, 1);
      border-radius: 8px;
    }
  }

  .establish_formBox {
    width: 820px;
    height: 392px;
    margin-top: 20px;
    padding: 20px 0;
    background: rgba($color: #363659, $alpha: 0.2);
    border-radius: 0px 4px 4px 4px;
  }

  .establish_formbtn {
    width: 320px;
    height: 40px;
    color: #333;
    margin: auto;
    margin-top: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }

  .label_img {
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
    border-radius: 8px;
  }

  .cover_img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
}

.roomPage {
  color: #000;
  font-size: 14px;
}

.title {
  margin: 16px 0;
}

.title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 10px;
  margin-right: 4px;
  background: linear-gradient(180deg, #FFA700 0%, #FFDD00 100%);
  border-radius: 0px 2px 2px 0px;
}

//房间列表
.roomList_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.room_item {
  width: 49.5%;
  height: 90px;
  padding: 8px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;

  .roomItem_head {
    width: 74px;
    height: 74px;
    margin-right: 15px;
    border-radius: 8px;
  }

  .roomItem_userHead {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .roomItem_icon {
    width: 78px;
    height: 20px;
  }
}

.room_titleBox {
  width: 403px;
  height: 80px;
  padding: 16px 0 0 17px;
  margin: 40px 0 18px 0;
  background: url(../../image/room/46.png) no-repeat;
  box-sizing: border-box;

  .titleBoxIcon {
    width: 48px;
    height: 18px;
    font-size: 8px;
    background: #1d2030;
    border-radius: 3px;
    text-align: center;
    line-height: 18px;
  }

  .titleBoxName {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .room_titleBoxHead {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .room_titleBoxText {
    font-size: 10px;
    color: #8e92b1;
    margin-top: 4px;
  }
}


.establish_btn {
  width: 260px;
  height: 48px;
  color: #ffffff;
  background: #1d223b;
  border-radius: 26px;
  margin: auto;
  margin-top: 100px;
  z-index: 9999999999;
}

.tingSet {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #4177DD;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topLeftIcon {
  width: 50px;
  height: 20px;
  object-fit: cover;
}

.wheat_remind {
  width: 522px;
  height: 285px;
  position: absolute;
  top: 35%;
  left: 35%;
  color: #333;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;

  .fankuiType {
    background: #f5f5f5;
    border-radius: 4px 4px 4px 4px;
    padding: 10px 20px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 14px;
  }

  .fankuiBtn {
    width: 176px;
    height: 40px;
    background: #ffdd00;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    margin: 0 auto;
    color: #333333;
  }

  .wheatRemind_img {
    width: 80px;
    height: 80px;
    margin-top: 40px;
    border-radius: 50%;
  }

  .wheatRemind_apply {
    width: 176px;
    height: 40px;
    background: #ffdd00;
    margin-top: 40px;
    border-radius: 4px;
  }

  .btn2 {
    color: #f68229;
    margin-right: 20px;
    background: rgba(246, 130, 41, 0.2);
  }
}

.roomAdmin_bg {
  padding: 14px;
  width: 127px;
  height: 56px;
  background: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;

  .roomAdmin_page {
    width: 6px;
    height: 9px;
    position: absolute;
    top: 16px;
    right: 10px;
  }

  .roomAdmin_icon {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }
}

.editRoom {
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  color: #333;
}

.editRoom_btn {
  width: 320px;
  height: 40px;
  margin: 20px auto;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
}

/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}

/deep/.el-form-item__label {
  color: #333;
}

/deep/.el-input__inner {
  color: #333;
  background-color: #f5f5f5 !important;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #7e53f0;
  background: #7e53f0;
}

/deep/.el-textarea__inner {
  border: 0;
  background-color: #f5f5f5;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #333;
}

/deep/.el-input__inner::placeholder {
  color: #6a6a94 !important;
}

/deep/ textarea::-webkit-input-placeholder {
  -webkit-text-fill-color: #6a6a94;
}
</style>