<template>
  <!-- 提醒弹窗 -->
  <div class="popUp_box" v-if="isShow">
    <div class="wheat_remind" style="height: 320px">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">{{ text.title }}</div>
        <img src="@/static/quit.png" alt="" @click="isShow = false" />
      </div>
      <!-- 头部end -->
      <div class="flex-dir">
        <img src="@/static/129.png" v-if="text.btn.iconType == 'del'" alt="" style="margin-top: 54px" />
        <img src="@/static/188.png" v-else alt="" style="margin-top: 54px" />
        <div style="margin-top: 15px;color:#5A4112">{{ text.msg }}</div>
        <div class="flex">
          <div
            class="wheatRemind_apply flex-content"
            style="background: #fde4d1;color:#F68229"
            @click="cancel()"
          >
            {{ text.btn.cancelVal }}
          </div>
          <div
            class="wheatRemind_apply flex-content"
            @click="confirm()"
            style="margin-left: 20px"
          >
            {{ text.btn.confirmVal }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "remindPopUp",
  props: {},
  components: {},
  data() {
    return {
      isShow: true,
      text: {
        title: "提示",
        msg: "描述信息！",
        iconType: "success",
        cancelBtn: true,
        confirmBtn: true,
        btn: {
          confirmVal: "确定",
          cancelVal: "取消",
        },
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    //取消
    cancel() {
      this.isShow = false;
    },
    //确认
    confirm() {
      this.isShow = false;
    },
  },
  created() {},
  mounted() {},
};
</script> 

<style scoped lang="scss">
.popUp_box {
  font-size: 12px;
  color: #fff;
  z-index: 999;
}
.wheat_remind {
  width: 522px;
  height: 285px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  .wheatRemind_img {
    width: 80px;
    height: 80px;
    margin-top: 40px;
    border-radius: 50%;
  }
  .wheatRemind_apply {
    width: 176px;
    height: 40px;
    color: #363659;
    background: #ffdd00;
    margin-top: 40px;
    border-radius: 4px;
  }
}
</style>