<template>
  <!-- 他人主页 -->
  <div class="page">
    <div class="box" @click="closeTitle">
      <!-- 头部 -->
      <div class="head flex flex-aling">
        <!-- 头像 -->
        <!-- <img :src="userData.avatar" alt="" class="headPortrait" /> -->
        <el-avatar shape="square" :size="80" fit="cover" :src="userData.avatar"></el-avatar>
        <!-- 用户信息 -->
        <div class="head_text">
          <div class="flex flex-aling" style="padding-bottom: 7px">
            <p :class="{ 'vipred': userData.vipFlag == 1 }">{{ userData.nickname }} </p>
            <img src="../../static/vip.png" v-if='userData.vipFlag == 1'
              style="width:auto;height:12px;margin-left: 3px;" />
            <img :src="userData.wealthLevelLogo" alt="" class="userIcon" />
            <!-- <img src="../../static/user2.png" alt="" class="userIcon" /> -->
          </div>
          <div class="flex" style="color: #fff">
            <div class="onLine flex-content">
              <div class="flex-aling" v-if="userData.onlineStatus == 0">
                <div class="onLineItem" style="background: red"></div>
                离线
              </div>
              <div class="flex-aling" v-if="userData.onlineStatus == 1">
                <div class="onLineItem"></div>
                在线
              </div>
              <div class="flex-aling" v-if="userData.onlineStatus == 2">
                <div class="onLineItem"></div>
                聊天中
              </div>
            </div>
            <div class="age flex-content" :style="userData.sex == 1 ? 'background:rgba(255, 120, 120, 1)' : ''">
              <!-- <img src="../../static/25.png" alt="" class="ageImg" /> -->
              <img src="../../static/134.png" alt="" v-if="userData.sex == 1" />
              <img src="../../static/25.png" alt="" v-else style="width: 12px; height: 12px" />
              <span style="margin-left: 4px">{{ userData.age }}</span>
            </div>
          </div>
          <div class="userInfo flex">
            <p @click="copytext(userData.userNo)">ID：{{ userData.userNo }}</p>
            <p style="padding: 0 15px 0 19px">粉丝数：{{ userData.fansNum }}</p>
            <div style="position: relative;">
              <div class="flex flex-aling">
                <p>IP归属地：{{ userData.city }}</p>
                <img src="../../static/41.png" alt="" class="helpIcon" @click.stop="explainShow = !explainShow" />
              </div>
              <div class="ip_box" v-if="explainShow">
                <div class="font-14">IP归属地说明</div>
                <div>
                  为了促进互联网信息服务健康发展、维护网络空间良好生态，根据网络运营商数据展示用户IP归属地信息。
                </div>
              </div>
            </div>


          </div>
        </div>
        <!-- 语音签名 -->
        <div class="voiceChat flex-content" v-if="userData.personalSignature">
          <img src="../../static/45.png" alt="" />
          {{ userData.personalSignature }}
        </div>
        <div class="functionBtn flex">
          <div
            class="functionBtn_item flex-content cursor"
            @click="left=30;shareShow = !shareShow"
          >
            <img src="../../static/44.png" alt="" class="functionImg" />
            分享
          </div>
		  <div v-if='userData.isSelf!=1'>
			  <div
				class="functionBtn_item flex-content cursor"
				@click="stopFollowUser()"
				v-if="userData.isFollow == 1"
			  >
				取消关注
			  </div>
			  <div class="functionBtn_item flex-content cursor" @click="guanzhuShow=true" v-else>
				<img src="../../static/43.png" alt="" class="functionImg" />
				关注大神
			  </div>
          </div>
          <div class="functionBtn_item flex-content cursor" @click="left=300;shareShow=!shareShow">
            <img src="../../static/42.png" alt="" class="functionImg" />
            下载APP
          </div>
          <div class="share_popup" :class="[left == 30 ? 'left30' : 'left300']" v-if="shareShow">
            <div class="ipAddress">
              <div>https://ser.t9cp.com/pch5/#/pages/newDownload</div>
              <div
              style="color: #333;width:68px;height:32px;" class="copyBtn cursor"
                @click.stop="copyUserId('https://ser.t9cp.com/pch5/#/pages/newDownload')"
              >
                复制
              </div>
            </div>
          </div>
        </div>
        <img src="../../static/quit.png" alt="" class="quitStyle" @click="closeOthers()" />
      </div>
      <!-- 内容 -->
      <div class="content">
        <div class="prompt_top flex" v-if="userData.onlineStatus == 2 && skipShow" @click.stop="followRoom()">
          <div>
            我正在
            <b style="color: #fbcf23">{{ userData.roomName }}</b> 直播间语聊中
          </div>
          <div style="margin-left: 12px">点击进入></div>
        </div>
        <!-- 左侧内容 -->
        <div class="leftHead">
          <div class="leftTop">
            <!-- 内部页面切换 -->
            <div class="tabContent flex">
              <div class="tab_item" @click="tabSwitch(1)" :class="tab == 1 ? 'btn_pitch' : ''"
                v-if="userData.userSkillVos.length != 0">
                技能橱窗
              </div>
              <div class="tab_item" @click="tabSwitch(2)" :class="tab == 2 ? 'btn_pitch' : ''">
                礼物墙
              </div>
              <div class="tab_item" @click="tabSwitch(3)" :class="tab == 3 ? 'btn_pitch' : ''">
                勋章墙
              </div>
              <div class="tab_item" @click="tabSwitch(4)" :class="tab == 4 ? 'btn_pitch' : ''">
                动态
              </div>
            </div>
            <!-- 技能橱窗state -->
            <div v-if="tab == 1 && userData.userSkillVos.length != 0">
              <!-- 游戏类型切换 -->
              <div class="game_tab flex">
                <div class="game_item flex-content" v-for="(item, index) in userData.userSkillVos" :key="item.skillId"
                  :class="skillData.skillId == item.skillId ? 'game_pitch' : ''" @click="skillChange(item)">
                  <img :src="item.skillLogo" alt="" class="ganeImg" />
                  <div style="width: 50px" class="text-cut">
                    {{ item.skillName }}
                  </div>
                </div>
              </div>
              <!-- 下单 -->
              <div class="placeAnOrder_box flex">
                <div class="order_left flex">
                  <img :src="skillDetails.skillLogo" alt="" class="game_logo" />
                  <div>
                    <div class="flex">
                      <p class="mainTitle">{{ skillDetails.skillName }}</p>
                      <p class="subheading">{{ skillDetails.skillLevel }}</p>
                    </div>
                    <div class="alternative">
                      已接单：{{ skillDetails.orderTakeNum }}
                    </div>
                    <!-- 下单数量 -->
                    <div class="flex">
                      <img src="../../static/47.png" alt="" class="addAndImg" @click="reduceNumber" />
                      <div class="oneNumber">{{ number }}</div>
                      <img src="../../static/46.png" alt="" class="addAndImg" @click="addNumber" />
                    </div>
                  </div>
                </div>
                <div class="order_right">
                  <div class="dirllIcon" v-if="
                    skillDetails.discountType == 1 &&
                    skillDetails.buyXY.length != 0
                  ">
                    {{ `买${skillDetails.buyXY[0]}赠${skillDetails.buyXY[1]}` }}
                  </div>
                  <div class="dirllIcon" v-if="skillDetails.discountType == 0 && skillDetails.price">
                    {{ skillDetails.price + "元宝" }}
                  </div>
                  <div class="pice_text">
                    <b class="pice_text_blod">{{ skillDetails.price }}</b>{{ skillDetails.unit }}
                  </div>
                  <div class="flex" v-if='userData && userData.isSelf != 1'>
                    <div v-if="!types" class="private flex-content" @click="privateLetter(skillDetails)">
                      <img src="../../static/oth1.png" style="width: 14px; height: 14px; margin-right: 4px" alt="" />
                      私信
                    </div>
                    <div class="place_btn flex-content" @click="placingTheShowChange()">
                      立即下单
                    </div>
                  </div>
                </div>
              </div>
              <!-- 下单流程 -->
              <div class="orderFlow_box">
                <div class="flow_title">下单流程</div>
                <div class="flow_box flex">
                  <div class="flow_item">
                    <img src="../../static/30.png" alt="" class="flow_img" />
                    <b class="flow_stepName">立即下单</b>
                    <p class="flow_remark">下单前聊一下</p>
                  </div>
                  <img src="../../static/26.png" alt="" class="nextStep" />
                  <div class="flow_item">
                    <img src="../../static/31.png" alt="" class="flow_img" />
                    <b class="flow_stepName">大神接单</b>
                    <p class="flow_remark">等待大神接单</p>
                  </div>
                  <img src="../../static/26.png" alt="" class="nextStep" />
                  <div class="flow_item">
                    <img src="../../static/32.png" alt="" class="flow_img" />
                    <b class="flow_stepName">开始服务</b>
                    <p class="flow_remark">大神开始</p>
                  </div>
                  <img src="../../static/26.png" alt="" class="nextStep" />
                  <div class="flow_item">
                    <img src="../../static/33.png" alt="" class="flow_img" />
                    <b class="flow_stepName">确认完成</b>
                    <p class="flow_remark">玩家确认</p>
                  </div>
                  <img src="../../static/26.png" alt="" class="nextStep" />
                  <div class="flow_item">
                    <img src="../../static/34.png" alt="" class="flow_img" />
                    <b class="flow_stepName">评价</b>
                    <p class="flow_remark">您的评价很重要</p>
                  </div>
                </div>
              </div>
              <div class="cutOffRule"></div>
              <!-- 接单信息 -->
              <div class="orderInformation">
                <div class="flex">
                  <p class="orderInformation_le">接单时间</p>
                  <p>{{ skillDetails.ordersTime }}</p>
                </div>
                <div class="flex">
                  <p class="orderInformation_le">接单区服</p>
                  <p>{{ skillDetails.ordersDistrict }}</p>
                </div>
                <div class="flex">
                  <p class="orderInformation_le">擅长位置</p>
                  <p>{{ skillDetails.goodLocation }}</p>
                </div>
                <div class="flex">
                  <p class="orderInformation_le">接单说明</p>
                  <p style="max-width:500px">{{ skillDetails.ordersRemark }}</p>
                </div>
              </div>
              <div class="cutOffRule"></div>
              <img v-if="skillDetails.certificationImg" :src="skillDetails.certificationImg" alt=""
                class="illustration" />
            </div>
            <!-- 技能橱窗end -->
            <!-- 礼物墙state -->
            <div class="presentsWall" v-if="tab == 2" @scroll="playerGiftRoll($event)">
              <div v-for="(item, index) in presentsWall" :key="index + 'aq'">
                <div class="presentsWall_item flex-content" :class="item % 5 == 0 ? '' : 'margin_right'">
                  <div class="gift_number">x{{ item.giftNumber }}</div>
                  <img :src="item.giftLogo" alt="" class="gift_img" />
                  <div class="gift_name">{{ item.giftName }}</div>
                  <div class="gift_price">{{ item.giftValue }}元宝</div>
                </div>
              </div>
            </div>
            <!-- 礼物墙end -->

            <!-- 勋章墙state -->
            <div class="presentsWall" v-if="tab == 3">
              <div v-for="(item, index) in medalWall" :key="index + 'medal'" class="presentsWall_item flex-content"
                :class="item % 5 == 0 ? '' : 'margin_right'">
                <img :src="item.logo" alt="" class="gift_img" />
                <div class="gift_name">{{ item.name }}</div>
                <div class="gift_price">{{ item.getDateTime }}</div>
                <div>{{ item.signDays }}/{{ item.continuousSignDays }}</div>
              </div>
              <div class="font-14 flex-aling medalBtn" @click.stop="medalChange()">
                如何获取勋章
                <img style="margin-left: 4px" src="../../static/41.png" alt="" />
              </div>
            </div>
            <!-- 勋章墙end -->
            <!-- 用户动态state -->
            <div class="presentsWall" style="max-height: 760px; overflow: scroll" v-if="tab == 4">
              <div class="dynamicBox">
                <div class="dynamicItem flex" v-for="item in DongRows" :key="item.id">
                  <img :src="item.avatar" class="dynamicImg" alt="" />
                  <div style="margin-left: 10px">
                    <div class="font-16" style="font-weight: bold" :class="{ 'vipred': item.vipFlag == 1 }">{{ item.nickname }}
                    </div>
                    <div class="font-12" style="color: #999999; margin: 8px 0 12px 0">
                      {{ item.createTime }}
                    </div>
                    <div class="font-14" style="color: #666666">
                      <span style="color: #4177dd" v-if="item.topicName">#{{ item.topicName }}</span>
                      {{ item.title }}
                    </div>
                    <div v-if="item.videoPicture" style="position: relative" @click="playMusic(item)">
                      <div style="margin-top:10px">
                        <video width="320" height="140" controls>
                          <source :src="item.video">
                          </source>
                        </video>
                      </div>
                    </div>
                    <!-- 图片 -->
                    <template v-if="item.newImages.length > 0">
                      <div style="margin-top: 12px; width: 340px">
                        <img :src="itemImg" alt="" style="
                          width: 100px;
                          height: 100px;
                          border-radius: 4px;
                          margin: 0 8px 8px 0;
                        " v-for="(itemImg, indexImg) in item.newImages" :key="indexImg" />
                      </div>
                    </template>
                    <!-- 按钮 -->
                    <div class="flex" style="margin-top: 16px">
                      <!-- <div class="flex flex-content" style="margin-right: 40px">
                        <img
                          src="../../static/oth7.png"
                          alt=""
                          style="width: 16px; height: 16px; margin-right: 8px"
                        />
                        <span>分享</span>
                      </div> -->
                      <!-- <div class="flex flex-content" style="margin-right: 40px">
                        <img
                          src="../../static/oth8.png"
                          alt=""
                          style="width: 16px; height: 16px; margin-right: 8px"
                        />
                        <span>{{ item.isLike==0  &&item.likeNumber==0?'首赞':item.likeNumber }}</span>
                      </div> -->
                      <div class="flex flex-content" style="margin-right: 40px" v-if="item.isLike == 0"
                        @click="zanBtn(item)">
                        <img src="../../static/oth8.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                        <span>{{ item.isLike == 0 && item.likeNumber == 0 ? '首赞' : item.likeNumber }} </span>
                      </div>
                      <div class="flex flex-content" style="margin-right: 40px" v-if="item.isLike == 1"
                        @click="zanBtn(item)">
                        <img src="../../static/4.png" alt="" style="width: 16px; height: 16px; margin-right: 8px" />
                        <span style="color:rgba(255, 167, 0, 1)">{{
                          item.isLike == 0
                            && item.likeNumber == 0 ? '首赞' : item.likeNumber
                        }} </span>
                      </div>
                      <!-- <div class="flex flex-content">
                        <img
                          src="../../static/oth9.png"
                          alt=""
                          style="width: 16px; height: 16px; margin-right: 8px"
                        />
                        <span>评论</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 用户动态end -->
          </div>
          <!-- 评论信息 -->
          <div v-if="tab == 1" class="leftBottm">
            <div class="flex bottm_title">
              <div style="font-size: 16px">用户评价</div>
              <div class="bottm_subheading">
                共{{ evaluateData.evaluationNum }}条评论
              </div>
            </div>
            <!-- 好评率 -->
            <div class="favorable flex-aling">
              <div class="score">
                评分<b class="favorable_text">{{
                  evaluateData.evaluationScore
                }}</b>
              </div>
              <div>
                好评率<b class="favorable_text">{{
                  evaluateData.goodEvaluationPercent
                }}%</b>
              </div>
            </div>
            <!-- 评价内容 -->
            <div class="score_box flex" v-for="(item, index) in evaluateData.evaluationVoList" :key="index">
              <img :src="item.avatar" alt="" class="score_img" />
              <div class="score_content">
                <div>
                  <div class="flex" style="padding-bottom: 4px">
                    <b class="font-14" style="padding-right: 10px">{{
                      item.nickname
                    }}</b>
                    <p class="font-12" style="color: #d3d4dc">
                      {{ item.createTime }}
                    </p>
                  </div>
                  <div class="score_label">{{ item.evaluation }}</div>
                </div>
                <!-- 星级评级 -->
                <div>
                  <div class="starText" v-if="item.score == 5">非常满意</div>
                  <div class="starText" v-if="item.score == 4">很满意</div>
                  <div class="starText" v-if="item.score == 3">满意</div>
                  <div class="starText" v-if="item.score == 2">一般</div>
                  <div class="starText" v-if="item.score == 1">不满意</div>
                  <div class="flex flex-aling" style="margin-top: 2px;">
                    <div class="flex flex-aling">
                      <img src="../../static/35.png" alt="" class="starImg" v-for="i in item.score" :key="i" />
                    </div>
                    <div class="flex flex-aling" v-if="(5 - Number(item.score))">
                      <img src="../../static/36.png" alt="" class="starImg" v-for="index in (5 - Number(item.score))"
                        :key="index" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧内容 -->
        <div class="rightHead">
          <!-- 用户照片墙 -->
          <div class="rightTop flex">
            <!-- 照片主图 -->
            <div class="photoLord">
              <img :src="atPresentImg.img" v-if="atPresentImg" alt="" class="photoLord_img"
                @dblclick="previewChange()" />
            </div>
            <!-- 照片滚动内容 -->
            <div class="pictureScroll">
              <div class="scrollTop"></div>
              <div class="scrollContent" v-if="userData">
                <div v-for="(item, index) in userData.imgList" :key="index + 'dd'"
                  :class="atPresentImg.id == item.id ? 'scroll_imgPitch' : ''">
                  <img :src="item.img" alt="" class="scroll_img" @click="imgPitch(item)" />
                </div>
              </div>
              <div class="scrollBottom"></div>
            </div>
          </div>
          <!-- 用户资料 -->
          <div class="rightBottom">
            <!-- 关于TA -->
            <div class="user_title flex-aling">
              <img src="../../static/oth2.png" alt="" />
              关于TA
            </div>
            <div class="font-14" style="color: #a1a1a1">
              <span v-if="userData.city">{{ `位于 ${userData.city} 的` }}</span>
              <span v-if="userData.constellation">{{
                userData.constellation
              }}</span>
            </div>
            <div class="tag" v-if="userData.personalSignature">
              {{ userData.personalSignature }}
            </div>
            <!-- 语音标签 -->
            <div class="voiceLabel" v-if="userData && userData.voiceFile && userData.voiceName"
              @click.stop="playAudio(userData.voiceFile)">
              <img src="../../static/193.png" alt="" />
              <span>{{ userData.voiceName }}</span>
            </div>
            <div class="laborUnion" v-if="userData.guildName">所属工会</div>
            <div class="font-14" style="color: #a1a1a1">
              {{ userData.guildName }}
            </div>
            <!-- 标签 -->
            <div class="user_title flex-aling">
              <img src="../../static/oth3.png" alt="" />
              标签
            </div>
            <div class="flex">
              <div class="label" v-for="item in userData.personalTagName" :key="item.id">
                {{ item.text }}
              </div>
            </div>
            <!-- 喜欢的内容 -->
            <div class="user_title flex-aling">
              <img src="../../static/oth4.png" alt="" />
              喜欢的内容
            </div>
            <div class="flex otherClass">
              <div class="label" style="margin-bottom:10px" v-for="item in userData.interestLabelName" :key="item.id">
                {{ item.name }}
              </div>
            </div>
            <!-- 喜欢的声音 -->
            <div class="user_title flex-aling">
              <img src="../../static/oth5.png" alt="" />
              喜欢的声音
            </div>
            <div class="flex" style="flex-wrap: wrap;">
              <div class="label" style="margin-bottom:14px" v-for="item in userData.likeVoiceName" :key="item.id">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关注分组 -->
    <div class="placingThePopup_bg" v-if="guanzhuShow">
      <div class="placingThePopup_box">
        <div class="titleIcon"></div>
        <div class="title_content">
          <div>设置分组</div>
          <img src="../../static/59.png" alt="" @click.stop="guanzhuShow = false" />
        </div>
        <!-- 内容 -->
        <div class="llacingCont_box">
          <!-- 充值 -->
          <div class="topUp_box1 flex-aling">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="topUp_box1Btn" @click="followUser(value)">确认</div>
        </div>
      </div>
    </div>
    <!-- 下单弹窗 -->
    <div class="placingThePopup_bg" v-if="placingTheShow && placeOrderData">
      <div class="placingThePopup_box">
        <div class="titleIcon"></div>
        <div class="title_content">
          <div>立即下单</div>
          <img src="../../static/59.png" alt="" @click.stop="placingTheShowChange()" />
        </div>
        <!-- 内容 -->
        <div class="llacingCont_box">
          <div class="llacingCont_title">确认订单</div>
          <div class="llacingCont_table">
            <!-- 头部表头 -->
            <div class="flex-aling llacingCont_tr">
              <div v-for="(hederItem, key0) in placingheader" :key="key0" class="llacingCont_heder flex-content">
                {{ hederItem }}
                <div class="discount_icon" v-if="key0 == placingheader.length - 1 && placeOrderData.buyXY[0]">
                  {{
                  `买${placeOrderData.buyXY[0]}赠${placeOrderData.buyXY[1]}`
                  }}
                </div>
              </div>
            </div>
            <!-- 下单内容 -->
            <div class="flex-aling llacingCont_tr">
              <div class="llacingCont_td flex-content">
                <img :src="placeOrderData.avatar" alt="" class="llacingCont_head" />
                {{ placeOrderData.nickname }}
              </div>
              <div class="llacingCont_td flex-content">
                {{ placeOrderData.skillName }}
              </div>
              <div class="llacingCont_td flex-content">
                {{ placeOrderData.price }}
              </div>
              <div class="llacingCont_td flex-content">
                {{ placeOrderData.price * placeOrderData.number }}
              </div>
              <div class="llacingCont_td flex-content">
                <img src="../../static/47.png" alt="" class="addAndImg" @click="reducePlacingNumber" />
                <div class="oneNumber" style="margin: 0 10px">
                  {{ placeOrderData.number }}
                </div>
                <img src="../../static/46.png" alt="" class="addAndImg" @click="addPlacingNumber" />
              </div>
            </div>
          </div>
          <!-- 备注 -->
          <div class="llacingCont_title">备注</div>
          <!-- <el-input
            v-model="remark"
            placeholder="选填，建议提前和大神沟通，最多50字"
            class="llacingCont_remark"
          ></el-input> -->
          <input v-model="remarkNew" placeholder="选填，建议提前和大神沟通，最多50字" class="llacingCont_remark" />
          <div class="hint">若取消订单或订单超时，元宝将退回至您的钱包余额</div>
          <div class="cutOffRule"></div>
          <!-- 充值 -->
          <div class="topUp_box flex-aling">
            <div class="flex-aling">
              <div class="balanceNum">
                余额：<img src="../../static/195.png" alt="" style="width: 16px; height: 16px" />
                {{ placeOrderData.balance }}
              </div>
              <div class="topUpBtn flex-content" @click="walletTopupShow = true">
                充值<img src="../../static/oth6.png" alt="" style="margin-left: 4px" />
              </div>
            </div>
            <div class="flex-aling">
              <div class="flex-aling">
                <b class="diamondNum">{{ placeOrderData.sum }}</b>元宝
              </div>
              <div class="addBtn flex-content" @click="belowOrder()">
                确认下单
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="placingThePopup_bg" v-if="showPaySuccess">
      <div class="placingThePopup_box" style="width: 400px;">
        <!-- <div class="titleIcon"></div> -->
        <div class="flex" style="align-items: center; padding-top: 30px;flex-direction: column;text-align: center;">
          <img src="@/static/user57.png" alt="" style="width: 72px;height: 72px;">
          <span style="color: #333;margin: 24px 0 12px;">支付成功</span>
          <span style="color: #4177dd;font-size: 12px;">快去找大神沟通吧</span>
          <div style="height: 24px;"></div>
        </div>
        <!-- 内容 -->
        <div class="llacingCont_box">
          <!-- 充值 -->
          <div class="topUp_box1Btn" @click="privateLetter(skillDetails), showPaySuccess = false">知道了</div>
        </div>
      </div>
    </div>
    <!-- 图片预览弹窗 -->
    <div class="placingThePopup_bg" v-if="previewShow" @click="previewShow = false">
      <div class="imgpopup_box">
        <img src="../../image/my/22.png" alt="" class="imgpopup_direction" @click.stop="pageTurning(1)" />
        <img :src="previewData.img" v-if="atPresentImg" alt="" class="imgpopup_cnt" />
        <img src="../../image/my/23.png" alt="" class="imgpopup_direction" @click.stop="pageTurning(2)" />
      </div>
    </div>
    <!-- 充值页面 -->
    <walletTopup v-if="walletTopupShow" ref="walletTopup" @walletQuitChange="walletTopupShow = false"></walletTopup>
    <myMessage ref="myMessage" v-show="myMessageShow" @closeMessage="myMessageShow = false"></myMessage>
  </div>
</template>

<script>
import walletTopup from "../personalCenterAll/walletTopup.vue";
export default {
  components: {
    walletTopup,
    myMessage: () => import('./messagePage.vue') //我的消息
  },
  //["userId",'userType']
  props: {
    userId: {
      type: String,
      require: true,
      default: "",
    },
    userType: {
      type: String,
      default: "",
    },
    types: {
      type: [String, Number],
      default: "",
    }
  },
  data() {
    return {
      remake: '',
      showPaySuccess: false,
      options: [],
      value: "",
      tab: 1,
      placingTheShow: false, //下单弹窗
      placingheader: ["大神信息", "陪玩类型", "单价", "总价", "下单数量"],
      remarkNew: "",
      id: this.userId,
      userData: {
        userSkillVos: [{
          skillId: ''
        }]
      }, //大神信息
      skillData: {}, //选中技能
      skillDetails: {}, //技能详情
      personList: [], //个人标签
      voiceList: [], //声音标签
      interestList: [], //兴趣标签
      explainShow: false, //ip说明
      evaluateData: {}, //评价信息
      shareShow: false, //分享弹窗
      presentsWall: [], //礼物墙
      medalWall: [], //勋章墙
      walletTopupShow: false, //充值弹窗
      number: 1, //下单数量
      placeOrderData: {}, //下单信息
      buyXYShow: false, //下单页面标签是否显示
      previewShow: false, //图片预览
      atPresentImg: {}, //选中图片
      previewData: {}, //预览图片
      myMessageShow: false, //聊天页面
      skipShow: true, //是否显示跳转聊天室
      giftPages: {
        pageNum: "1",
        pageSize: "25",
      },
      giftTotal: 0,
      guanzhuShow: false, //关注
      DongRows: [],
      left: 30
    };
  },
  created() {
    this.getUserData();
    this.homepageList();
    this.getPersonLabel();
    this.playerGiftAndMedalNum();
    this.getFriendGroupList();

  },
  mounted() {

    if (this.userType == "4") {
      setTimeout(() => {
        this.tabSwitch(3);
      }, 300);
      this.skipShow = false;
    }
  },
  methods: {
    copytext(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message.success('复制成功')
        })
        .catch((err) => {
          this.$message.info('复制失败')
        });
    },
    // 勋章说明
    medalChange() {
      this.$api.getAgreement({ configName: "honor_medal_rule" }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$alert(res.data, "勋章说明", {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "确认",
          });
        }
      });
    },
    homepageList() {
      this.$api
        .homepageList({ othUserId: this.id, pageNum: 1, pageSize: 15 })
        .then((res) => {
          res.data.rows.forEach((item) => {
            if (item.images) {
              item.newImages = item.images.split(",");
            } else {
              item.newImages = [];
            }
          });
          this.DongRows = res.data.rows;
        });
    },
    getFriendGroupList() {
      this.$api.friendGroupList().then((res) => {
        console.log(res, "resresrse1");
        res.data.forEach((item) => {
          item.label = item.groupingName;
          item.value = item.id;
          // console.log(item,'123123')
        });
        this.options = res.data;
      });
    },
    // 点击复制
    copyUserId(id) {
      var input = document.createElement("input"); // 创建input对象
      input.value = id; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    //跟随进入房间
    followRoom() {
      // 判断聊天室是否存在
      this.$api.enterRoom({ roomId: this.userData.roomId }).then((res) => {
        if (res.code == 1) {
          this.$router.push({
            path: "/someSingingHall",
            query: {
              roomId: res.data.id,
              liveStreamId: res.data.liveStreamingId,
            },
          });
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 播放声音
    playAudio(url) {
      this.audio = new Audio(url);
      this.audio.play();
    },
    pageTurning(type) {
      if (type == 1) {
        //上一页
        this.userData.imgList.forEach((item, index) => {
          if (this.previewData.id == item.id && index != 0) {
            this.previewData = this.userData.imgList[index - 1];
          }
        });
      } else {
        //下一页
        try {
          this.userData.imgList.forEach((item, index) => {
            if (
              this.previewData.id == item.id &&
              index != this.userData.imgList.length - 1
            ) {
              this.previewData = this.userData.imgList[index + 1];
              throw "";
            }
          });
        } catch (error) { }
      }
    },
    // 私信
    privateLetter(i) {
      i.userProfile = {
        userID: i.id
      }
      // this.$emit('closeOtherOpenMsg', i)
      this.myMessageShow = true;
      console.log(this.$refs.myMessage)

      this.$refs.myMessage.selectChat(i, "", i.id)
      // this.$refs.myMessage.selectChat(i, "", i.id);

    },
    // 双击预览
    previewChange() {
      this.previewShow = true;
      this.previewData = this.atPresentImg;
    },
    // 选择图片
    imgPitch(i) {
      this.atPresentImg = i;
    },
    // 关注
    followUser(value) {
      if (!value) {
        this.$message("请选择分组");
        return;
      }
      this.$api
        .followUser({ followUserIds: this.userData.id, groupId: value })
        .then((res) => {
          if (res.code == 1) {
            this.getUserData();
            this.$message.success("关注成功");
          }
        });
      this.value = "";
      this.guanzhuShow = false;
    },
    // 取消关注
    stopFollowUser() {
      this.$api
        .stopFollowUser({ followUserId: this.userData.id })
        .then((res) => {
          if (res.code == 1) {
            this.getUserData();
            this.$message.success("取消成功");
          }
        });
    },
    // 陪玩确认下单
    belowOrder() {
      this.$confirmMy({
        title: "提醒",
        msg: "确认要下单吗？",
        btn: {
          confirmVal: "确认支付",
          cancelVal: "再想想",
        },
      })
        .then((res) => {
          let data = {
            discountType: this.placeOrderData.discountType, //折扣类型（0-一口价 1-买x赠y
            isDiscount: this.placeOrderData.discountType, //是否折扣订单
            number: this.placeOrderData.number, //数量
            orderTakerId: this.userData.id, //接单人id
            paymentAmount: this.placeOrderData.sum, //付款金额
            remark: this.remarkNew, //备注
            skillId: this.skillData.skillId, //技能id
          };
          this.$api.belowOrder(data).then((res) => {
            // console.log("陪玩确认下单", res);
            if (res.code == 1) {
              this.placingTheShow = false;
              this.$message.success(res.msg);
              this.showPaySuccess = true;
            } else {
              if (res.code == 0) {
                this.$confirmMy({
                  title: "提醒",
                  msg: "金额不足是否跳转到充值",
                  btn: {
                    confirmVal: "确认",
                    cancelVal: "取消",
                  },
                }).then(res=>{
                  this.walletTopupShow=true
                })
                // this.$message.error(res.msg);
                // setTimeout(()=>{
                //   this.walletTopupShow=true
                // },700)

              }
            }
          });
        })
        .catch((err) => { });
    },
    //下单数量加
    addPlacingNumber() {
      this.number++;
      this.immediateOrder();
    },
    //下单数量减
    reducePlacingNumber() {
      if (this.number == 1) {
        return this.$message.info("最少下一单哦~");
      } else {
        this.number--;
        this.immediateOrder();
      }
    },
    // 立即下单页面信息
    immediateOrder() {
      this.$api
        .immediateOrder({
          number: this.number, //数量
          skillId: this.skillData.skillId, //	目标用户技能id
          targetUserId: this.userData.id, //目标用户id
        })
        .then((res) => {
          // console.log('下单页面信息',res);
          if (res.code == 1) {
            this.placeOrderData = res.data;
            this.placeOrderData.buyXY = this.placeOrderData.buyXY.split(",");
            if (this.placeOrderData.discountType == 1) {
              let totle =
                Number(this.placeOrderData.buyXY[0]) +
                Number(this.placeOrderData.buyXY[1]);
              if (this.placeOrderData.number > totle) {
                this.buyXYShow = true;
              } else {
                this.buyXYShow = false;
              }
            } else {
              this.buyXYShow = false;
            }
          }
        });
    },
    // 减少单数
    reduceNumber() {
      if (this.number == 1) {
        return this.$message.info("最少下一单哦~");
      } else {
        this.number--;
      }
    },
    // 添加单数
    addNumber() {
      this.number++;
    },
    // 滚动加载
    playerGiftRoll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        console.log(123);
        if (this.presentsWall.length < this.giftTotal) {
          this.giftPages.pageNum++;
          this.playerGiftWallList();
        }
      }
    },
    // 大神个人主页-礼物墙接口
    playerGiftWallList() {
      this.giftPages.targetUserId = this.id;
      this.$api.playerGiftWallList(this.giftPages).then((res) => {
        // console.log("礼物墙", res);
        if (res.code == 1) {
          // res.data.rows.forEach((item) => {
          //   this.presentsWall.push(item);
          // });
          this.presentsWall = res.data.rows || []
          this.giftTotal = res.data.total;
        }
      });
    },

    // 大神个人主页-勋章墙接口myq
    playerMedalWallList() {
      this.$api
        .playerMedalWallList({
          pageNum: "1",
          pageSize: "20",
          targetUserId: this.id,
        })
        .then((res) => {
          // console.log("勋章墙", res);
          if (res.code == 1) {
            this.medalWall = res.data.rows;

          }
        });
    },
    // 大神个人主页-礼物数和勋章数统计
    playerGiftAndMedalNum() {
      this.$api.playerGiftAndMedalNum({ targetUserId: this.id }).then((res) => {
        // console.log("礼物数和勋章数统计", res);
      });
    },
    // 大神技能
    playerSkillInfo() {
      if (this.skillData) {
        this.$api
          .playerSkillInfo({
            skillId: this.skillData.skillId,
            targetUserId: this.userData.id,
          })
          .then((res) => {
            if (res.code == 1) {
              this.skillDetails = res.data;
              this.number = 1;
              this.skillDetails.buyXY = this.skillDetails.buyXY.split(",");
              this.getplayerEvaluate();
            }
          });
      }


    },
    // 技能选择
    skillChange(i) {
      this.skillData = i;
      this.playerSkillInfo();
    },
    // 获取技能评价
    getplayerEvaluate() {
      let data = {
        pageNum: "1",
        pageSize: "15",
        skillId: this.skillData.skillId, //	大神技能id
        targetUserId: this.userData.id, //目标用户id
      };
      this.$api.playerEvaluationList(data).then((res) => {
        if (res.code == 1) {
          this.evaluateData = res.data;
        }
      });
    },
    closeTitle(){

      this.explainShow=false
    },
    zanBtn(item) {
      this.$api.forumClickAdd({ sourceId: item.id, type: 1 }).then((res) => {
        // if (this.indexTab == 0) {
        this.homepageList();
        // }
        // if (this.indexTab == 1) {
        this.homepageList();
        // }
        this.$forceUpdate();
      });
    },
    // 获取主页信息
    getUserData() {
      this.$api.userMainInfo({ otherUserId: this.id }).then((res) => {
        if (res.code == 1) {
          this.userData = res.data;

          //如果第一个不是技能橱窗 就是照片墙
          if (this.userData.userSkillVos.length == 0) {
            this.tab = 2;
            this.playerGiftWallList()//获取照片墙
          } else {
            this.tab = 1;
          }
          if (res.data.imgList.length == 0) {
            this.userData.imgList.push({ img: res.data.avatar });
            this.atPresentImg = { img: res.data.avatar };
          } else {
            this.atPresentImg = res.data.imgList[0];
          }
          this.skillData = res.data.userSkillVos[0];
          this.playerSkillInfo(); // 获取技能
          // 标签
          this.userData.personalTagIds =
            this.userData.personalTagIds.split(",");
          this.userData.likeVoiceIds = this.userData.likeVoiceIds.split(",");
          this.userData.interestLabelIds =
            this.userData.interestLabelIds.split(",");
          this.userData.interestLabelName = [];
          this.userData.likeVoiceName = [];
          this.userData.personalTagName = [];
          this.personList.forEach((i) => {
            this.userData.personalTagIds.forEach((t) => {
              if (t == i.id) {
                this.userData.personalTagName.push(i);
              }
            });
          });
          this.voiceList.forEach((i) => {
            this.userData.likeVoiceIds.forEach((t) => {
              if (t == i.id) {
                this.userData.likeVoiceName.push(i);
              }
            });
          });
          this.interestList.forEach((i) => {
            this.userData.interestLabelIds.forEach((t) => {
              if (t == i.id) {
                this.userData.interestLabelName.push(i);
              }
            });
          });
        }
      });
    },
    // 下单弹窗显示
    placingTheShowChange() {
      this.placingTheShow = !this.placingTheShow;
      if (this.placingTheShow) {
        this.immediateOrder();
      }
    },
    //tab切换
    tabSwitch(type) {
      this.tab = type;
      if (type == 2) {
        this.giftPages.pageNum = 1;
        this.presentsWall = [];
        this.playerGiftWallList();
      }
      if (type == 3) {
        this.playerMedalWallList();
      }
      if (type == 4) {
        this.homepageList();
      }
    },
    //关闭页面
    closeOthers() {
      this.$emit("closeOthers");
    },
    oneType() { },
    // 获取标签列表
    getPersonLabel() {
      this.$api.getUserPersonalTag().then((res) => {
        // console.log("个人标签列表", res);
        if (res.code == 1) {
          this.personList = res.data;
        }
      });
      this.$api.getUserVoiceTag().then((res) => {
        // console.log("声音标签列表", res);
        if (res.code == 1) {
          this.voiceList = res.data;
        }
      });
      this.$api.getInterestTags().then((res) => {
        // console.log("兴趣标签列表", res);
        if (res.code == 1) {
          this.interestList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.otherClass {
  display: flex;
  flex-wrap: wrap;
}

.dynamicBox {
  height: 562px;
  .dynamicItem {
    margin-bottom: 18px;
  }
}

.dynamicImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.medalBtn {
  position: absolute;
  left: 50%;
  bottom: 25%;
}

.prompt_top {
  width: 1010px;
  height: 25px;
  position: fixed;
  z-index: 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: linear-gradient(129deg, #8b39fb 0%, #ec51a3 100%);
}

//图片弹窗
.imgpopup_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .imgpopup_cnt {
    max-width: 700px;
    max-height: 700px;
  }

  .imgpopup_direction {
    width: 40px;
    height: 40px;
    margin: 0 60px;
  }
}

.page {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  font-size: 16px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.8);
}

.quitStyle {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 11px;
  cursor: pointer;
}

.box {
  position: relative;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.head {
  width: 1010px;
  // height: 120px;
  margin: auto;
  padding: 20px 20px 20px 20px;
  position: relative;
  background: linear-gradient(129deg, #fde4d6 0%, #fff 50%, #f9eab9 100%);
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;

  .headPortrait {
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }

  .onLine {
    height: 22px;
    font-size: 12px;
    padding: 0 8px;
    margin-right: 8px;
    background: rgba($color: #000000, $alpha: 0.4);
    border-radius: 11px 11px 11px 11px;

    .onLineItem {
      width: 6px;
      height: 6px;
      margin-right: 2px;
      background: #3ae51a;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .age {
    padding: 0 8px;
    height: 22px;
    font-size: 12px;
    background: #85bcfe;
    border-radius: 15px 15px 15px 15px;

    .ageImg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  .userInfo {
    font-size: 12px;
    padding-top: 15px;
    color: #8e92b1;
    position: relative;

    .ip_box {
      width: 317px;
      padding: 10px;
      color: #fff;
      font-size: 12px;
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-34%);
      background: rgba($color: #000000, $alpha: 0.85);
      border-radius: 8px;
      z-index: 99;
    }

    .ip_box::after {
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 7px solid rgba(0, 0, 0, 0.8);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      display: inline-block;
      position: absolute;
      top: -7px;
      right: 184px;
    }
  }

  .userIcon {
    width: 20px;
    height: 20px;
    margin: 0 4px;
  }

  .head_text {
    color: #333333;
    margin-left: 14px;
  }

  .helpIcon {
    width: 16px;
    height: 16px;
    padding-left: 3px;
  }

  .voiceChat {
    min-width: 60px;
    max-width: 235px;
    // height: 30px;
    font-size: 14px;
    margin: auto 0;
    background: linear-gradient(122deg,
        #fee338 0%,
        rgba(110, 255, 240, 0) 100%);
    border-radius: 100px;
    text-align: left;
    padding: 8px 6px;
    color: #333;
    word-break: break-word
  }

  .functionBtn {
    position: absolute;
    top: 30%;
    right: 30px;
    font-size: 14px;

    .share_popup {
      width: 435px;
      height: 64px;
      color: #212121;
      font-size: 12px;
      padding: 10px;
      position: absolute;
      top: 60px;
      left: 0px;
      background: #ffffff;
      box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      box-sizing: border-box;
      z-index: 2;
      align-items: center;

      .copyBtn {
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        background-color: rgba(255, 221, 0, 1);
      }

      .ipAddress {
        margin-top: 0px;
        padding: 6px 10px;
        display: flex;
        justify-content: space-between;
        background: #ececec;
        border-radius: 4px;
        align-items: center;
      }
    }

    .share_popup::after {
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 7px solid #fff;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      display: inline-block;
      position: absolute;
      top: -7px;
      left: 30px;
    }

    .left30::after {
      left: 30px;
    }

    .left300::after {
      left: 300px;
    }

    .functionBtn_item {
      width: 107px;
      height: 32px;
      color: #333333;
      margin-left: 10px;
      background: #ffdd00;
      border-radius: 16px 16px 16px 16px;
    }

    .functionImg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}

.content {
  width: 1010px;
  height: 650px;
  margin: auto;
  display: flex;
  overflow-y: scroll;
  background: #f7f7f9;
  border-radius: 0px 0px 8px 8px;
  position: relative;

  .leftHead {
    .leftTop {
      width: 530px;
      height: 794px;
      margin: 10px;
      padding: 16px;
      color: #252b4a;
      position: relative;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;

      .tabContent {
        padding-bottom: 27px;
        font-size: 14px;
        font-weight: 400;
        color: #252b4a;
        position: relative;

        .tab_item {
          margin-right: 40px;cursor: pointer;
        }

        .btn_pitch {
          // font-size: 16px;
          font-weight: bold;
          color: #252b4a;
          position: relative;
        }

        .btn_pitch:after {
          content: "";
          display: block;
          width: 20px;
          height: 4px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -50%;
          border-radius: 2px 2px 0px 0px;
          background: #ffdd00;
        }
      }

      .game_tab {
        font-size: 12px;
        width: 520px;
        overflow-x: scroll;
        padding-bottom: 10px;

        .ganeImg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        .game_item {
          height: 24px;
          padding: 0 4px;
          color: #363659;
          margin-right: 8px;
          border-radius: 12px 12px 12px 12px;
        }

        .game_pitch {
          color: #333333;
          background: linear-gradient(180deg, #ffeb72 0%, #fee338 100%);
        }
      }

      .game_tab::-webkit-scrollbar {
        width: 12px;
        height: 8px;
      }

      .game_tab::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.2);
      }

      .placeAnOrder_box {
        margin-top: 20px;
        justify-content: space-between;

        .order_left {
          .mainTitle {
            font-size: 22px;
            font-weight: bold;
            color: #252b4a;
          }

          .subheading {
            font-size: 12px;
            font-weight: bold;
            position: relative;
            top: 8px;
            left: 8px;
          }

          .alternative {
            font-size: 12px;
            font-weight: 400;
            color: #8e92b1;
            margin: 7px 0 8px 0;
          }

          .game_logo {
            width: 80px;
            height: 80px;
            margin-right: 16px;
          }

          .addAndImg {
            width: 17px;
            height: 17px;
          }

          .oneNumber {
            width: 28px;
            height: 17px;
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: #252b4a;
            text-align: center;
          }
        }

        .order_right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .dirllIcon {
            height: 18px;
            padding: 0 5px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            background: linear-gradient(115deg, #ff7878 0%, #ff41d9 100%);
            border-radius: 9px 9px 9px 0px;
          }

          .pice_text {
            font-size: 14px;
            font-weight: 400;
            color: #8e92b1;

            .pice_text_blod {
              font-size: 32px;
              font-weight: bold;
              color: #f68229;
            }
          }

          .private {
            width: 87px;
            height: 30px;
            color: #333;
            margin-right: 7px;
            background: #fff199;
            border-radius: 44px 44px 44px 44px;
          }

          .place_btn {
            width: 88px;
            height: 30px;
            color: #333;
            font-weight: bold;
            background: #ffdd00;
            border-radius: 44px 44px 44px 44px;
          }
        }
      }

      .orderFlow_box {
        margin-top: 29px;

        .flow_title {
          font-size: 12px;
          font-weight: 400;
          color: #252b4a;
          margin-bottom: 7px;
        }

        .flow_box {
          display: flex;
          align-items: center;
          background: linear-gradient(180deg,
              rgba(#ffde32, 0.2) 0%,
              rgba(#ffa200, 0.2) 100%);
          padding: 10px;
          justify-content: space-between;

          .flow_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .flow_img {
              width: 20px;
              height: 20px;
            }

            .flow_stepName {
              font-size: 12px;
              font-weight: bold;
              color: #66381e;
              margin: 8px 0 5px 0;
            }

            .flow_remark {
              font-size: 10px;
              font-weight: 400;
              color: #b7643d;
            }
          }

          .nextStep {
            width: 15px;
            height: 10px;
            margin: 0 10px;
          }
        }
      }

      .cutOffRule {
        width: 530px;
        margin: 16px 0 10px 0;
        border-top: 1px dashed #e9e9e9;
      }

      .orderInformation {
        >div {
          margin-bottom: 14px;
        }

        .orderInformation_le {
          color: #a1a1a1;
          margin-right: 44px;
        }
      }

      .illustration {
        width: 530px;
        height: 298px;
        border: 8px;
        object-fit: cover;
        object-position: 50% 20%;
      }

      //礼物墙样式
      .presentsWall {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: scroll;

        .margin_right {
          margin-right: 12px;
        }

        .presentsWall_item {
          width: 110px;
          height: 106px;
          margin-bottom: 16px;
          background: #f7f7f9;
          flex-direction: column;
          position: relative;
          border-radius: 8px 8px 8px 8px;

          .gift_img {
            width: 40px;
            height: 40px;
          }

          .gift_number {
            width: 18px;
            height: 12px;
            font-size: 10px;
            color: #f7f7f9;
            position: absolute;
            text-align: center;
            line-height: 12px;
            top: 4px;
            right: 4px;
            background: rgba($color: #212121, $alpha: 0.4);
            border-radius: 6px 6px 6px 6px;
          }

          .gift_name {
            font-size: 12px;
            color: #7c4ff0;
            margin: 8px 0;
          }

          .gift_price {
            font-size: 10px;
            color: #a1a1a1;
          }
        }

        .unfold {
          width: 92px;
          height: 28px;
          font-size: 12px;
          position: absolute;
          left: 50%;
          bottom: 20%;
          transform: translateX(-50%);
          color: #212121;
          background: #ffffff;
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #d5d5d5;

          >img {
            width: 8px;
            height: 8px;
            margin-left: 4px;
          }
        }
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.2);
      }
    }

    .leftBottm {
      width: 530px;
      min-height: 312px;
      margin: 10px;
      padding: 16px;
      color: #252b4a;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;

      .bottm_title {
        display: flex;
        align-items: flex-end;
        margin-bottom: 11px;
      }

      .bottm_subheading {
        font-size: 14px;
        color: #8e92b1;
        margin-left: 10px;
      }

      .favorable {
        width: 426px;
        height: 36px;
        font-size: 12px;
        color: #212121;
        background: linear-gradient(85deg,
            rgba(225, 118, 22, 0.2) 0%,
            #ffffff 100%);
        border-radius: 4px 4px 4px 4px;
        margin-bottom: 24px;
      }

      .score {
        margin: 0 16px 0 10px;
      }

      .favorable_text {
        font-size: 16px;
        font-weight: 800;
        color: #f68229;
      }

      .score_box {
        margin-top: 16px;

        .score_img {
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        .score_content {
          width: 424px;
          margin-left: 10px;

          display: flex;
          justify-content: space-between;

          .score_label {
            width: 384px;
            font-size: 12px;
            color: #a1a1a1;
            word-break: break-all;
          }

          .starText {
            font-size: 12px;
            color: #f68229;
            text-align: right;
          }

          .starImg {
            width: 14px;
            height: 14px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .rightHead {
    margin: 10px 10px 0 0;

    .rightTop {
      width: 410px;
      height: 420px;
      padding: 10px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 8px 8px 8px 8px;

      .photoLord {
        width: 400px;
        height: 400px;
        border-radius: 8px;
        overflow: hidden;

        .photoLord_img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          cursor: pointer;
        }
      }

      .pictureScroll {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .scrollContent {
          height: 90%;
          overflow-y: scroll;

          .scroll_img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 8px;
          }

          .scroll_imgPitch {
            padding: 1px;
            display: flex;
            border: 1px solid #56348d;
            border-radius: 8px;
            margin-bottom: 4px;
          }
        }

        .scrollTop {
          width: 80px;
          height: 16px;
          background: url(../../static/38.png) no-repeat;
        }

        .scrollBottom {
          width: 80px;
          height: 16px;
          background: url(../../static/39.png) no-repeat;
        }
      }
    }

    .rightBottom {
      width: 410px;
      height: 436px;
      margin-top: 10px;
      padding: 10px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 8px 8px 8px 8px;

      .user_title {
        font-size: 16px;
        color: #212121;
        margin: 10px 0;

        >img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }

      .tag {
        padding: 6px;
        font-size: 14px;
        color: #1d223b;
        text-align: center;
        margin: 10px 0;
        background: #e9e9e9;
        border-radius: 11px 11px 11px 11px;
        word-break: break-word
      }

      .voiceLabel {
        max-width: 120px;
        font-size: 14px;
        padding: 6px 0;
        display: flex;
        align-content: center;
        justify-content: center;
        background: linear-gradient(90deg, #5ac7ff 0%, #9374f4 100%);
        border-radius: 16px;
        margin-top: 12px;
      }

      .laborUnion {
        color: #212121;
        font-size: 14px;
        margin: 17px 0 8px 0;
      }

      .label {
        height: 22px;
        color: #7c4ff0;
        font-size: 12px;
        text-align: center;
        line-height: 22px;
        padding: 3px 8px;
        margin-right: 10px;
        background: rgba($color: #7c4ff0, $alpha: 0.1);
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}

//下单弹窗样式
.placingThePopup_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.6);

  .placingThePopup_box {
    width: 900px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .title_content {
      >img {
        width: 13px;
        height: 13px;
      }
    }

    .llacingCont_box {
      padding: 0 20px 16px 20px;

      .topUp_box1 {
        width: 382px;
        margin: 48px auto;
      }

      .topUp_box1Btn {
        width: 256px;
        height: 40px;
        background: #ffdd00;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin: 0 auto 31px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 40px;
      }

      .discount_icon {
        height: 16px;
        padding: 0 4px;
        margin-left: 10px;
        color: #fff;
        font-size: 10px;
        background: linear-gradient(109deg, #e17616 0%, #ff9b41 100%);
        border-radius: 4px;
      }

      .llacingCont_title {
        color: #333;
        font-size: 14px;
        padding: 17px 0;
      }

      .llacingCont_table {
        width: 100%;
        font-size: 14px;
        color: #333;
        border-top: 1px solid #f5f5f5;
        border-left: 1px solid #f5f5f5;

        .llacingCont_tr {
          display: flex;
        }

        .llacingCont_heder {
          flex: 1;
          height: 36px;
          background: #f5f5f5;
          border-right: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
        }

        .llacingCont_td {
          flex: 1;
          height: 80px;
          border-right: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
        }

        .llacingCont_head {
          width: 48px;
          height: 48px;
          margin-right: 10px;
          border-radius: 8px;
        }
      }

      .llacingCont_remark {
        width: 430px;
        height: 36px;
        font-size: 14px;
        color: #333;
        padding-left: 10px;
        background-color: #fff;
        border: 1px solid #f5f5f5;
      }
    }

    .hint {
      font-size: 10px;
      color: #4177dd;
      margin-top: 32px;
    }

    .cutOffRule {
      width: 705px;
      margin: 10px 0 16px 0;
      border-top: 1px dashed #eee;
    }

    .topUp_box {
      width: 100%;
      justify-content: space-between;
      font-size: 12px;
      color: rgba(142, 146, 177, 1);

      .balanceNum {
        margin-right: 7px;
      }
    }

    .diamondNum {
      color: #f68229;
      font-size: 24px;
    }

    .topUpBtn {
      width: 48px;
      height: 20px;
      color: #333333;
      margin-left: 11px;
      background: #ffdd00;
      border-radius: 10px;
    }

    .addBtn {
      width: 88px;
      height: 30px;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      margin-left: 24px;
      background: #ffdd00;
      border-radius: 44px;
    }
  }
}

/deep/.el-input__inner::placeholder {
  color: #333 !important;
}

/deep/.el-input__inner {
  color: #333 !important;
  border-radius: 0;
  width: 382px;
  background: #f5f5f5 !important;
}
</style>
